import {
  Poll,
  PollDetails,
  PollFilters,
  PollResults,
  Questionnaire,
  Recipient,
  RecipientCounts,
  RecipientGroup,
  Response,
  SynchronizedInstitution,
} from 'store/polls/polls.types';

export interface PollsState {
  list: {
    filters: PollFilters;
    items: Poll[];
    loading: boolean;
    error: any;
  };
  details: {
    poll: PollDetails;
    loading: boolean;
    error: any;
  };
  synchronizedInstitutions: {
    list: SynchronizedInstitution[];
    loading: boolean;
    error: any;
  };
  recipientsGroups: {
    list: RecipientGroup[];
    loading: boolean;
    error: any;
  };
  recipientsCounts: {
    [institutionId: string]: {
      counts: RecipientCounts | null;
      loading: any;
      error: any;
    };
  };
  results: {
    results: PollResults;
    loading: boolean;
    error: string;
  };
  responses: {
    [responseId: string]: {
      response: Response;
      loading: boolean;
      error: any;
    };
  };
  recipients: {
    list: Recipient[];
    loading: boolean;
    error: any;
  };
  questionnaire: {
    questionnaire: Questionnaire;
    loading: boolean;
    error: string;
  };
}

export const defaultState: PollsState = {
  list: {
    filters: {},
    items: [],
    loading: false,
    error: null,
  },
  details: {
    poll: null,
    loading: false,
    error: null,
  },
  synchronizedInstitutions: {
    list: [],
    loading: false,
    error: null,
  },
  recipientsGroups: {
    list: [],
    loading: false,
    error: null,
  },
  recipientsCounts: {},
  results: {
    results: null,
    loading: false,
    error: null,
  },
  responses: {},
  recipients: {
    list: [],
    loading: false,
    error: null,
  },
  questionnaire: {
    questionnaire: null,
    loading: false,
    error: null,
  },
};

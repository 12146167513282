import { limits } from 'config/pagination';
import { RSAA, RSAACreator } from 'redux-api-middleware';
import { FSA } from '@typings/redux';
import { toApiFormat } from 'utils/api';
import { fileToObjectFetch } from 'utils/redux/fileToObjectFetch';
import { clean } from 'utils/object/clean';

import { SchoolDistrictsActionType as ActionType } from './schoolDistricts.actionTypes';
import {
  AddressRuleFormModel,
  ChildrenAlertsTypes,
  DatabaseFormModel,
  SchoolListFilters,
} from 'store/schoolDistricts/schoolDistricts.types';
import { schoolListInitialFilters } from './schoolDistricts.state';

export const fetchSchoolDistrictsDatabases: RSAACreator<ActionType> = () => {
  return {
    [RSAA]: {
      endpoint: `/catchment-area-groups`,
      method: 'GET',
      types: [
        ActionType.FETCH_DATABASE_LIST_REQUEST,
        ActionType.FETCH_DATABASE_LIST_SUCCESS,
        ActionType.FETCH_DATABASE_LIST_FAILURE,
      ],
      params: {},
    },
  };
};

export const createSchoolDistrictsDatabase: RSAACreator<ActionType> = (payload: DatabaseFormModel) => {
  return {
    [RSAA]: {
      endpoint: `/catchment-area-groups`,
      method: 'POST',
      types: [
        {
          type: ActionType.CREATE_DATABASE_REQUEST,
          meta: { lockScreen: true },
        },
        ActionType.CREATE_DATABASE_SUCCESS,
        {
          type: ActionType.CREATE_DATABASE_FAILURE,
          meta: {
            ignoreNotFoundError: true,
          },
        },
      ],
      body: payload,
      params: {},
    },
  };
};

export const editSchoolDistrictsDatabase: RSAACreator<ActionType> = (id: string, name: string) => {
  return {
    [RSAA]: {
      endpoint: `/catchment-area-groups/${id}`,
      method: 'PUT',
      types: [
        {
          type: ActionType.EDIT_DATABASE_REQUEST,
          meta: { lockScreen: true },
        },
        ActionType.EDIT_DATABASE_SUCCESS,
        {
          type: ActionType.EDIT_DATABASE_FAILURE,
          meta: {
            ignoreNotFoundError: true,
          },
        },
      ],
      body: { name },
      params: {},
    },
  };
};

export const setCurrentSchoolDistrictsDatabase: RSAACreator<ActionType> = (id: string) => {
  return {
    [RSAA]: {
      endpoint: `/catchment-area-groups/${id}/activate`,
      method: 'PUT',
      types: [
        {
          type: ActionType.SET_CURRENT_DATABASE_REQUEST,
          meta: { lockScreen: true },
        },
        ActionType.SET_CURRENT_DATABASE_SUCCESS,
        ActionType.SET_CURRENT_DATABASE_FAILURE,
      ],
      params: {},
    },
  };
};

export const setArchiveSchoolDistrictsDatabase: RSAACreator<ActionType> = (id: string) => {
  return {
    [RSAA]: {
      endpoint: `/catchment-area-groups/${id}/archive`,
      method: 'PUT',
      types: [
        {
          type: ActionType.SET_CURRENT_DATABASE_REQUEST,
          meta: { lockScreen: true },
        },
        ActionType.SET_ARCHIVE_DATABASE_SUCCESS,
        ActionType.SET_ARCHIVE_DATABASE_FAILURE,
      ],
      params: {},
    },
  };
};

export const deleteSchoolDistrictsDatabase: RSAACreator<ActionType> = (id: string) => {
  return {
    [RSAA]: {
      endpoint: `/catchment-area-groups/${id}`,
      method: 'DELETE',
      types: [
        {
          type: ActionType.DELETE_DATABASE_REQUEST,
          meta: { lockScreen: true },
        },
        ActionType.DELETE_DATABASE_SUCCESS,
        {
          type: ActionType.DELETE_DATABASE_FAILURE,
          meta: {
            ignoreNotFoundError: true,
          },
        },
      ],
      params: {},
    },
  };
};

export const fetchSchoolList: RSAACreator<ActionType> = (groupId: string) => {
  return {
    [RSAA]: {
      endpoint: `/catchment-area-groups/${groupId}/schools`,
      method: 'GET',
      types: [
        ActionType.FETCH_SCHOOL_LIST_REQUEST,
        ActionType.FETCH_SCHOOL_LIST_SUCCESS,
        ActionType.FETCH_SCHOOL_LIST_FAILURE,
      ],
      params: {},
    },
  };
};

export const setSchoolListFilters = (filters: SchoolListFilters): FSA<ActionType> => {
  return {
    type: ActionType.SET_SCHOOL_LIST_FILTERS,
    payload: filters,
  };
};

export const clearSchoolListFilters = (): FSA<ActionType> => {
  return {
    type: ActionType.SET_SCHOOL_LIST_FILTERS,
    payload: schoolListInitialFilters,
  };
};

export const fetchAddressList: RSAACreator<ActionType> = (groupId: string, schoolId: string) => {
  return {
    [RSAA]: {
      endpoint: `/catchment-area-groups/${groupId}/schools/${schoolId}/address_rules`,
      method: 'GET',
      types: [
        ActionType.FETCH_ADDRESS_LIST_REQUEST,
        ActionType.FETCH_ADDRESS_LIST_SUCCESS,
        {
          type: ActionType.FETCH_ADDRESS_LIST_FAILURE,
          meta: {
            ignoreNotFoundError: true,
          },
        },
      ],
      params: {},
    },
  };
};

export const fetchAddressRule: RSAACreator<ActionType> = (groupId: string, schoolId: string, ruleId: string) => {
  return {
    [RSAA]: {
      endpoint: `/catchment-area-groups/${groupId}/schools/${schoolId}/address_rules/${ruleId}`,
      method: 'GET',
      types: [
        ActionType.FETCH_ADDRESS_RULE_REQUEST,
        ActionType.FETCH_ADDRESS_RULE_SUCCESS,
        ActionType.FETCH_ADDRESS_RULE_FAILURE,
      ],
      params: {},
    },
  };
};

export const addAddressRule: RSAACreator<ActionType> = (
  groupId: string,
  schoolId: string,
  values: AddressRuleFormModel,
) => {
  return {
    [RSAA]: {
      endpoint: `/catchment-area-groups/${groupId}/schools/${schoolId}/address_rules`,
      method: 'POST',
      types: [
        ActionType.CREATE_ADDRESS_RULE_REQUEST,
        ActionType.CREATE_ADDRESS_RULE_SUCCESS,
        ActionType.CREATE_ADDRESS_RULE_FAILURE,
      ],
      params: {},
      body: toApiFormat(values),
    },
  };
};

export const editAddressRule: RSAACreator<ActionType> = (
  groupId: string,
  schoolId: string,
  ruleId: string,
  values: AddressRuleFormModel,
) => {
  return {
    [RSAA]: {
      endpoint: `/catchment-area-groups/${groupId}/schools/${schoolId}/address_rules/${ruleId}`,
      method: 'PUT',
      types: [
        ActionType.EDIT_ADDRESS_RULE_REQUEST,
        ActionType.EDIT_ADDRESS_RULE_SUCCESS,
        ActionType.EDIT_ADDRESS_RULE_FAILURE,
      ],
      params: {},
      body: toApiFormat(values),
    },
  };
};

export const clearAddressList = (): FSA<ActionType> => {
  return {
    type: ActionType.CLEAR_ADDRESS_LIST,
  };
};

export const deleteAddressRule: RSAACreator<ActionType> = (databaseId: string, schoolId: string, addressId: string) => {
  return {
    [RSAA]: {
      endpoint: `/catchment-area-groups/${databaseId}/schools/${schoolId}/address_rules/${addressId}`,
      method: 'DELETE',
      types: [
        {
          type: ActionType.DELETE_ADDRESS_RULE_REQUEST,
        },
        ActionType.DELETE_ADDRESS_RULE_SUCCESS,
        {
          type: ActionType.DELETE_ADDRESS_RULE_FAILURE,
          meta: {
            ignoreNotFoundError: true,
          },
        },
      ],
      params: {},
    },
  };
};

export const createAssignment: RSAACreator<ActionType> = (catchemntAreaMap: Object, saveAsOfficial: boolean) => {
  return {
    [RSAA]: {
      endpoint: `/catchment-assignments`,
      method: 'POST',
      types: [
        ActionType.CREATE_ASSIGNMENT_REQUEST,
        ActionType.CREATE_ASSIGNMENT_SUCCESS,
        ActionType.CREATE_ASSIGNMENT_FAILURE,
      ],
      params: {},
      body: { map: catchemntAreaMap, saveAsOfficial },
    },
  };
};

export const clearAssignmentRequest = (): FSA<ActionType> => {
  return {
    type: ActionType.CLEAR_ASSIGNMENT_REQUEST,
  };
};

export const fetchAssignmentStatus: RSAACreator<ActionType> = (id: string) => {
  return {
    [RSAA]: {
      endpoint: `/catchment-assignments/${id}/status`,
      method: 'GET',
      types: [
        ActionType.FETCH_ASSIGNING_STATUS_REQUEST,
        ActionType.FETCH_ASSIGNING_STATUS_SUCCESS,
        ActionType.FETCH_ASSIGNING_STATUS_FAILURE,
      ],
      params: {},
    },
  };
};

export const fetchAssignmentOfficialMapping: RSAACreator<ActionType> = () => {
  return {
    [RSAA]: {
      endpoint: `/catchment-assignments/official-mapping`,
      method: 'GET',
      types: [
        ActionType.FETCH_ASSIGNING_STATUS_REQUEST,
        ActionType.FETCH_ASSIGNING_STATUS_SUCCESS,
        ActionType.FETCH_ASSIGNING_STATUS_FAILURE,
      ],
      params: {},
    },
  };
};

export const fetchAssignmentDetails: RSAACreator<ActionType> = (id: string, childrenWithAddressRules: number) => {
  return {
    [RSAA]: {
      endpoint: `/catchment-assignments/${id}`,
      method: 'GET',
      types: [
        ActionType.FETCH_ASSIGNING_DETAILS_REQUEST,
        ActionType.FETCH_ASSIGNING_DETAILS_SUCCESS,
        ActionType.FETCH_ASSIGNING_DETAILS_FAILURE,
      ],
      params: clean({
        with_rules: childrenWithAddressRules,
      }),
    },
  };
};

export const publishAssignment: RSAACreator<ActionType> = (id: string) => {
  return {
    [RSAA]: {
      endpoint: `/catchment-assignments/${id}/publish`,
      method: 'PUT',
      types: [
        {
          type: ActionType.PUBLISH_ASSIGNMENT_REQUEST,
          meta: { lockScreen: true },
        },
        ActionType.PUBLISH_ASSIGNMENT_SUCCESS,
        ActionType.PUBLISH_ASSIGNMENT_FAILURE,
      ],
      params: {},
    },
  };
};

export const fetchCatchmentAssignments: RSAACreator<ActionType> = () => {
  return {
    [RSAA]: {
      endpoint: `/catchment-assignments`,
      method: 'GET',
      types: [
        ActionType.FETCH_CATCHMENT_ASSIGNMENTS_REQUEST,
        ActionType.FETCH_CATCHMENT_ASSIGNMENTS_SUCCESS,
        ActionType.FETCH_CATCHMENT_ASSIGNMENTS_FAILURE,
      ],
      params: {},
    },
  };
};

export const deleteCatchmentAssignments: RSAACreator<ActionType> = (id: string) => {
  return {
    [RSAA]: {
      endpoint: `/catchment-assignments/${id}`,
      method: 'DELETE',
      types: [
        ActionType.DELETE_CATCHMENT_ASSIGNMENTS_REQUEST,
        ActionType.DELETE_CATCHMENT_ASSIGNMENTS_SUCCESS,
        ActionType.DELETE_CATCHMENT_ASSIGNMENTS_FAILURE,
      ],
      params: {},
    },
  };
};

export const fetchChildrenList: RSAACreator<ActionType> = (assignmentId: string, schoolId: string, page: number) => {
  return {
    [RSAA]: {
      endpoint: `/catchment-assignments/${assignmentId}/schools/${schoolId}`,
      method: 'GET',
      types: [
        ActionType.FETCH_CHILDREN_LIST_REQUEST,
        {
          type: ActionType.FETCH_CHILDREN_LIST_SUCCESS,
          meta: { page },
        },
        ActionType.FETCH_CHILDREN_LIST_FAILURE,
      ],
      params: {
        page,
        limit: limits.schoolDistrictsChildrenAssignmentList,
      },
    },
  };
};

export const downloadChildrenList: RSAACreator<ActionType> = (
  fileName: string,
  assignmentId: string,
  schoolId: string,
) => {
  return {
    [RSAA]: {
      endpoint: `/catchment-assignments/${assignmentId}/schools/${schoolId}`,
      method: 'GET',
      fetch: fileToObjectFetch('text/csv', `${fileName}.csv`),
      types: [
        ActionType.DOWNLOAD_CHILDREN_LIST_REQUEST,
        {
          type: ActionType.DOWNLOAD_CHILDREN_LIST_SUCCESS,
          meta: { downloadResponse: true },
        },
        ActionType.DOWNLOAD_CHILDREN_LIST_FAILURE,
      ],
      params: {
        filter: { isQualified: true },
      },
    },
  };
};

export const transferChildrenList: RSAACreator<ActionType> = (
  assignmentId: string,
  schoolId: string,
  lockScreen = true,
) => {
  return {
    [RSAA]: {
      endpoint: `/catchment-assignments/${assignmentId}/schools/${schoolId}/transfer`,
      method: 'PUT',
      types: [
        {
          type: ActionType.TRANSFER_CHILDREN_LIST_REQUEST,
          meta: { lockScreen },
        },
        ActionType.TRANSFER_CHILDREN_LIST_SUCCESS,
        ActionType.TRANSFER_CHILDREN_LIST_FAILURE,
      ],
      params: {},
    },
  };
};

export const fetchChildrenAlertList: RSAACreator<ActionType> = (
  assignmentId: string,
  alert: ChildrenAlertsTypes,
  page: number,
) => {
  return {
    [RSAA]: {
      endpoint: `/catchment-assignments/${assignmentId}/alerts/${alert}`,
      method: 'GET',
      types: [
        ActionType.FETCH_CHILDREN_ALERT_LIST_REQUEST,
        {
          type: ActionType.FETCH_CHILDREN_ALERT_LIST_SUCCESS,
          meta: { page },
        },
        ActionType.FETCH_CHILDREN_ALERT_LIST_FAILURE,
      ],
      params: {
        page,
        limit: limits.schoolDistrictsChildrenAssignmentList,
      },
    },
  };
};

export const downloadChildrenAlertList: RSAACreator<ActionType> = (
  fileName: string,
  assignmentId: string,
  alert: ChildrenAlertsTypes,
) => {
  return {
    [RSAA]: {
      endpoint: `/catchment-assignments/${assignmentId}/alerts/${alert}`,
      method: 'GET',
      fetch: fileToObjectFetch('text/csv', `${fileName}.csv`),
      types: [
        ActionType.FETCH_CHILDREN_ALERT_LIST_REQUEST,
        {
          type: ActionType.FETCH_CHILDREN_ALERT_LIST_SUCCESS,
          meta: {
            downloadResponse: true,
          },
        },
        ActionType.FETCH_CHILDREN_ALERT_LIST_FAILURE,
      ],
      params: {},
    },
  };
};

export const clearChildrenList = () => ({
  type: ActionType.CLEAR_CHILDREN_LIST,
});

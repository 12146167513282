import { ColumnsConfig } from 'components/shared/data/DataGrid/DataGrid.types';
import { PreviewSummary } from 'components/childRegister/Import/steps/Preview/Preview.types';
import { ChildImportModel } from 'store/childRegister';

export const previewColumns: ColumnsConfig<ChildImportModel, ChildImportModel> = (contextMapping: ChildImportModel) => [
  {
    field: 'identificationNumber',
    name: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.IDENTIFICATION_NUMBER',
    columnParams: {
      hide: !contextMapping.identificationNumber,
    },
  },
  {
    field: 'birthday',
    name: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.BIRTHDAY',
    columnParams: {
      hide: !contextMapping.birthday,
    },
  },
  {
    field: 'lastName',
    name: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.LAST_NAME',
    columnParams: {
      hide: !contextMapping.lastName,
    },
  },
  {
    field: 'firstName',
    name: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.FIRST_NAME',
    columnParams: {
      hide: !contextMapping.firstName,
    },
  },
  {
    field: 'secondName',
    name: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.SECOND_NAME',
    columnParams: {
      hide: !contextMapping.secondName,
    },
  },
  {
    field: 'sex',
    name: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.SEX',
    columnParams: {
      hide: !contextMapping.sex,
    },
  },
  {
    field: 'addressStatus',
    name: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.ADDRESS_STATUS',
    columnParams: {
      hide: !contextMapping.addressStatus,
    },
  },
  {
    field: 'birthCity',
    name: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.BIRTH_CITY',
    columnParams: {
      hide: !contextMapping.birthCity,
    },
  },
  {
    field: 'fatherFirstName',
    name: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.FATHER_FIRST_NAME',
    columnParams: {
      hide: !contextMapping.fatherFirstName,
    },
  },
  {
    field: 'fatherLastName',
    name: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.FATHER_LAST_NAME',
    columnParams: {
      hide: !contextMapping.fatherLastName,
    },
  },
  {
    field: 'motherFirstName',
    name: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.MOTHER_FIRST_NAME',
    columnParams: {
      hide: !contextMapping.motherFirstName,
    },
  },
  {
    field: 'motherLastName',
    name: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.MOTHER_LAST_NAME',
    columnParams: {
      hide: !contextMapping.motherLastName,
    },
  },
  {
    field: 'permanentResidenceVoivodeship',
    name: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.PERMANENT_RESIDENCE_VOIVODESHIP',
    columnParams: {
      hide: !contextMapping.permanentResidenceVoivodeship,
    },
  },
  {
    field: 'permanentResidenceCity',
    name: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.PERMANENT_RESIDENCE_CITY',
    columnParams: {
      hide: !contextMapping.permanentResidenceCity,
    },
  },
  {
    field: 'permanentResidenceCityTeryt',
    name: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.PERMANENT_RESIDENCE_CITY_TERYT',
    columnParams: {
      hide: !contextMapping.permanentResidenceCityTeryt,
    },
  },
  {
    field: 'permanentResidenceStreet',
    name: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.PERMANENT_RESIDENCE_STREET',
    columnParams: {
      hide: !contextMapping.permanentResidenceStreet,
    },
  },
  {
    field: 'permanentResidenceBuildingNumber',
    name: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.PERMANENT_RESIDENCE_BUILDING_NUMBER',
    columnParams: {
      hide: !contextMapping.permanentResidenceBuildingNumber,
    },
  },
  {
    field: 'permanentResidenceFlatNumber',
    name: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.PERMANENT_RESIDENCE_FLAT_NUMBER',
    columnParams: {
      hide: !contextMapping.permanentResidenceFlatNumber,
    },
  },
  {
    field: 'permanentResidencePostalCode',
    name: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.PERMANENT_RESIDENCE_POSTAL_CODE',
    columnParams: {
      hide: !contextMapping.permanentResidencePostalCode,
    },
  },
  {
    field: 'residenceVoivodeship',
    name: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.RESIDENCE_VOIVODESHIP',
    columnParams: {
      hide: !contextMapping.residenceVoivodeship,
    },
  },
  {
    field: 'residenceCity',
    name: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.RESIDENCE_CITY',
    columnParams: {
      hide: !contextMapping.residenceCity,
    },
  },
  {
    field: 'residenceCityTeryt',
    name: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.RESIDENCE_CITY_TERYT',
    columnParams: {
      hide: !contextMapping.residenceCityTeryt,
    },
  },
  {
    field: 'residenceStreet',
    name: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.RESIDENCE_STREET',
    columnParams: {
      hide: !contextMapping.residenceStreet,
    },
  },
  {
    field: 'residenceBuildingNumber',
    name: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.RESIDENCE_BUILDING_NUMBER',
    columnParams: {
      hide: !contextMapping.residenceBuildingNumber,
    },
  },
  {
    field: 'residenceFlatNumber',
    name: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.RESIDENCE_FLAT_NUMBER',
    columnParams: {
      hide: !contextMapping.residenceFlatNumber,
    },
  },
  {
    field: 'residencePostalCode',
    name: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.RESIDENCE_POSTAL_CODE',
    columnParams: {
      hide: !contextMapping.residencePostalCode,
    },
  },
];

export const summaryColumns: ColumnsConfig<PreviewSummary> = () => [
  {
    field: 'recordType',
    name: () => '',
    format: (data, trans) => {
      return trans(data.recordType);
    },
    cellTooltip: (data, trans) => {
      return data.tooltip ? trans(data.tooltip) : '';
    },
    renderer: 'textWithIcons',
    rendererParams: {
      text: (item: PreviewSummary) => item.recordType,
      icons: (item: PreviewSummary) => {
        if (item.tooltip) {
          return [
            {
              type: 'default',
              icon: 'info-circle',
              tooltip: (trans) => trans(item.tooltip),
            },
          ];
        }

        return [];
      },
    },
  },
  {
    field: 'numberOfRecords',
    name: () => '',
    cellTooltip: () => '',
  },
];

import {
  ForecastAction,
  ManipulatorConfig,
} from 'components/shared/data/DataGrid/renderers/cell/ForecastModificator/ForecastManipulator.types';
import { SubventionFactors } from 'store/reports/reports.types';

export interface SubventionForecastProps {
  isLoading: boolean;
  factors: SubventionFactors;
  structure: SubventionForecastRow[];
  year: number;
}

export enum SubventionForecastRowType {
  HEADER = 'header',
  FORM = 'form',
  RESULT = 'result',
}

export interface SubventionForecastFormRow {
  rowType: SubventionForecastRowType.FORM;
  factor: string;
  factorTooltip?: string;
  sourceValue: number;
  configField: keyof ForecastConfig;
  valueField: keyof ForecastValues;
  action?: ForecastAction;
  actionValue?: string;
  forecast: number;
}

export interface SubventionForecastHeaderRow {
  rowType: SubventionForecastRowType.HEADER;
  factor: string;
  factorTooltip?: string;
}

export interface SubventionForecastResultRow {
  rowType: SubventionForecastRowType.RESULT;
  factor: string;
  factorTooltip?: string;
  valueField: keyof ForecastValues;
  sourceValue: number;
  forecast: number;
}

export type SubventionForecastRow =
  | SubventionForecastFormRow
  | SubventionForecastHeaderRow
  | SubventionForecastResultRow;

export interface ForecastConfig {
  financialStandardA: ManipulatorConfig;
  diFactor: ManipulatorConfig;
  subventionA: ManipulatorConfig;
  subventionB: ManipulatorConfig;
  subventionC: ManipulatorConfig;
}

export interface ForecastValues {
  financialStandardA: string;
  diFactor: string;
  'subventionA.studentsTimesWeight': string;
  'subventionA.value': string;
  'subventionB.studentsTimesWeight': string;
  'subventionB.value': string;
  'subventionC.studentsTimesWeight': string;
  'subventionC.value': string;
  subvention: string;
}

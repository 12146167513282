import { RSAA, RSAACreator } from 'redux-api-middleware';
import { SystemSettingsActionType as ActionType } from './systemSettings.actionTypes';
import { DepartmentSettingsModel, PollsSettings, UserSettings } from './systemSettings.types';
import { toApiFormat } from 'utils/api';

export const fetchSystemSettings: RSAACreator<ActionType> = () => {
  return {
    [RSAA]: {
      endpoint: `/settings/system`,
      method: 'GET',
      types: [
        ActionType.FETCH_SYSTEM_SETTINGS_REQUEST,
        ActionType.FETCH_SYSTEM_SETTINGS_SUCCESS,
        ActionType.FETCH_SYSTEM_SETTINGS_FAILURE,
      ],
      params: {},
    },
  };
};

export const fetchPollsSettings: RSAACreator<ActionType> = () => {
  return {
    [RSAA]: {
      endpoint: `/settings/poll`,
      method: 'GET',
      types: [
        ActionType.FETCH_POLLS_SETTINGS_REQUEST,
        ActionType.FETCH_POLLS_SETTINGS_SUCCESS,
        ActionType.FETCH_POLLS_SETTINGS_FAILURE,
      ],
      params: {},
    },
  };
};

export const fetchUserSettings: RSAACreator<ActionType> = () => {
  return {
    [RSAA]: {
      endpoint: `/settings/official`,
      method: 'GET',
      types: [
        ActionType.FETCH_USER_SETTINGS_REQUEST,
        ActionType.FETCH_USER_SETTINGS_SUCCESS,
        ActionType.FETCH_USER_SETTINGS_FAILURE,
      ],
      params: {},
    },
  };
};

export const putDepartmentSettings: RSAACreator<ActionType> = (body: DepartmentSettingsModel) => {
  return {
    [RSAA]: {
      endpoint: `/settings/system`,
      method: 'PUT',
      types: [
        {
          type: ActionType.PUT_DEPARTMENT_SETTINGS_REQUEST,
          meta: {
            lockScreen: true,
          },
        },
        ActionType.PUT_DEPARTMENT_SETTINGS_SUCCESS,
        {
          type: ActionType.PUT_DEPARTMENT_SETTINGS_FAILURE,
          meta: {
            ignoreNotFoundError: true,
          },
        },
      ],
      body: toApiFormat(body),
      params: {},
    },
  };
};

export const putPollSettings: RSAACreator<ActionType> = (body: PollsSettings) => {
  return {
    [RSAA]: {
      endpoint: `/settings/poll`,
      method: 'PUT',
      types: [
        {
          type: ActionType.PUT_POLL_SETTINGS_REQUEST,
          meta: {
            lockScreen: true,
          },
        },
        ActionType.PUT_POLL_SETTINGS_SUCCESS,
        {
          type: ActionType.PUT_POLL_SETTINGS_FAILURE,
          meta: {
            ignoreNotFoundError: true,
          },
        },
      ],
      body: toApiFormat(body),
      params: {},
    },
  };
};

export const putUserSettings: RSAACreator<ActionType> = (body: UserSettings) => {
  return {
    [RSAA]: {
      endpoint: `/settings/official`,
      method: 'POST',
      types: [
        {
          type: ActionType.PUT_USER_SETTINGS_REQUEST,
          meta: {
            lockScreen: true,
          },
        },
        ActionType.PUT_USER_SETTINGS_SUCCESS,
        {
          type: ActionType.PUT_USER_SETTINGS_FAILURE,
          meta: {
            ignoreNotFoundError: true,
          },
        },
      ],
      body: toApiFormat(body),
      params: {},
    },
  };
};

import { Page } from 'components/layout/panel/Page/Page';
import { DatabaseBrowserContainer } from 'components/schoolDistricts/DatabaseBrowser/DatabaseBrowserContainer';
import { useTranslator } from 'components/shared/hooks';

import { Breadcrumb } from 'components/shared/navigation/Breadcrumbs/Breadcrumb';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import { replace } from 'connected-react-router';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteChildrenProps } from 'react-router';
import { DatabaseListViewState } from 'views/Panel/SchoolDistricts/DatabaseList/DatabaseList.types';

import { useStyles } from './DatabaseList.styles';

export const DatabaseListView: FC<RouteChildrenProps<{}, DatabaseListViewState>> = (props) => {
  const [action, setAction] = useState(null);
  const trans = useTranslator();
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.location.state && props.location.state.action) {
      setAction(props.location.state.action);
    }

    // replace state so the action will not be run after refreshing the page
    dispatch(replace(props.match.url));
  }, [props.location.state, dispatch, props.match.url]);

  return (
    <>
      <Breadcrumb label={trans('BREADCRUMB.SCHOOL-DISTRICTS.DATABASE')} static />

      <Page title={trans('SCHOOL_DISTRICTS.DATABASE.TITLE')}>
        <Paper innerSpacing={2} outerSpacing={[0, 2, 2]} className={classes.paper}>
          <DatabaseBrowserContainer initialAction={action} />
        </Paper>
      </Page>
    </>
  );
};

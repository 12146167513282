export const fileToObjectFetch =
  (contentType: string, fileName: string) =>
  async (input: RequestInfo, init?: RequestInit): Promise<Response> => {
    const response = await fetch(input, { ...init, headers: { ...init.headers, Accept: contentType } });

    if (response.ok && response.status === 200) {
      const content = await response.text();
      return new Response(JSON.stringify({ content, contentType, fileName }), {
        status: 200,
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }

    return response;
  };

import React, { useState } from 'react';
import { VictoryChart, VictoryBar, VictoryAxis, Bar, VictoryTooltip } from 'victory';
import { BarChartProps } from './BarChart.types';
import { Button } from 'components/shared/buttons/Button/Button';
import { Heading } from 'components/shared/typography/Heading/Heading';
import { colors } from 'styles/colors';
import { useStyles } from './BarChart.styles';
import { MaterialFlyout } from 'components/shared/charts/MaterialFlyout/MaterialFlyout';
import { NegativeAwareTickLabel } from 'components/shared/charts/NegativeAwareTickLabel/NegativeAwareTickLabel';
import { AxisLabel } from 'components/shared/charts/AxisLabel/AxisLabel';
import { RoundedTickLabel } from 'components/shared/charts/RoundedTickLabel/RoundedTickLabel';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslator } from 'components/shared/hooks';
import classNames from 'classnames';
import { transformValue } from '../helpers';

const CHART_WIDTH = 700;

export const BarChart = ({ data, statName }: BarChartProps) => {
  const classes = useStyles();
  const trans = useTranslator();
  const [chartData, setChartData] = useState(data);
  const [lastLevels, setLastLevels] = useState([]);
  const [levelSummary, setLevelSummary] = useState<string[]>([]);
  const [labelState, setLabelState] = useState(null);

  const handleNextLevelClick = (props) => {
    let nextLevel: any = {};
    if (props.ticks) {
      nextLevel = chartData.children?.find((child) => child.name === props.text);
    } else {
      nextLevel = chartData.children?.find((child) => child.name === props.datum.x);
    }

    if (nextLevel && nextLevel.data.length > 0) {
      setLevelSummary((prevState) => [...prevState, props.ticks ? props.text : props.datum.x]);
      setLastLevels((prevState) => [...prevState, chartData]);
      setChartData(nextLevel);
    }
  };

  const handleBackClick = () => {
    const prevLevels = lastLevels;
    const prevSummary = levelSummary;
    if (prevLevels.length > 0) {
      setChartData(prevLevels[prevLevels.length - 1]);
      prevLevels.pop();
      setLastLevels(prevLevels);
    }
    if (prevSummary.length > 0) {
      prevSummary.pop();
      setLevelSummary(prevSummary);
    }
  };

  const chartEvent = (target) => [
    {
      target,
      eventHandlers: {
        onClick: () => [
          {
            target,
            mutation: (props) => {
              setLabelState(null);
              handleNextLevelClick(props);
            },
          },
        ],
      },
    },
  ];

  const calculateBarWidth = () => CHART_WIDTH / chartData.data.length / 3;

  const calculateDomainPadding = () => {
    const barsWidth = chartData.data.length * calculateBarWidth();

    return (CHART_WIDTH - barsWidth) / chartData.data.length;
  };

  return (
    <div className="d-flex align-items-center flex-column h-100">
      <div className={classNames(classes.headingContainer, 'w-100 d-flex justify-content-between align-items-center')}>
        <Heading type="h6" className="mb-1 mt-1 text-center">
          {statName}
        </Heading>
        {lastLevels.length > 0 && (
          <Tooltip title={trans('REPORTS.SCHOOL_STAT_DETAILS.BACK_TOOLTIP')} placement="top">
            <Button variant="outlined" size="small" className="mt-2 mb-2" onClick={handleBackClick}>
              {trans('COMMON.BACK')}
            </Button>
          </Tooltip>
        )}
      </div>
      <Heading type="subtitle2" className="w-100 d-flex font-weight-normal mb-1">
        {`${levelSummary.length > 0 ? `${levelSummary.join(' › ')}` : ' '}`}
      </Heading>
      <div className="w-100 h-100 mt-4">
        <VictoryChart
          padding={{ top: 5, right: 40, bottom: 50, left: 40 }}
          width={CHART_WIDTH}
          height={215}
          domainPadding={{ x: calculateDomainPadding(), y: 50 }}
          domain={{ x: [1, chartData.data.length] }}
        >
          <VictoryAxis
            dependentAxis={true}
            style={{
              grid: { stroke: colors.neutralLight },
              tickLabels: { fontFamily: 'Roboto', fontSize: 8 },
            }}
            tickLabelComponent={<RoundedTickLabel dx={5} />}
            tickFormat={(tick) => transformValue(tick)}
          />
          <VictoryAxis
            tickLabelComponent={
              <AxisLabel
                textWidth={chartData.data.length > 8 ? 200 : 100}
                rotate={chartData.data.length > 8 ? 90 : 0}
                setLabelState={setLabelState}
              />
            }
            events={[
              {
                target: 'tickLabels',
                eventHandlers: {
                  onClick: () => [
                    {
                      target: 'tickLabels',
                      mutation: (props) => {
                        handleNextLevelClick(props);
                        setLabelState(null);
                      },
                    },
                  ],
                },
              },
            ]}
          />
          <VictoryBar
            barWidth={chartData.data.length <= 2 ? 100 : calculateBarWidth()}
            labels={({ datum }) => transformValue(datum.y)}
            labelComponent={<NegativeAwareTickLabel />}
            dataComponent={<Bar className={classes.bar} />}
            style={{
              data: {
                fill: 'rgb(15,72,127)',
              },
              labels: { fontFamily: 'Roboto', fontSize: 8 },
            }}
            data={chartData.data}
            events={chartEvent('data')}
          />
          {!!labelState && (
            <VictoryTooltip
              style={{
                fontSize: '6px',
              }}
              flyoutStyle={{ fill: '#fff' }}
              flyoutComponent={<MaterialFlyout />}
              flyoutPadding={8}
              active={true}
              x={labelState.x}
              y={labelState.y}
              text={labelState.text}
              dy={-10}
              constrainToVisibleArea
            />
          )}
        </VictoryChart>
      </div>
    </div>
  );
};

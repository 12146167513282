interface SchoolYearsFormatter {
  (years: (number | string)[]): string[];
}

export const format: SchoolYearsFormatter = (years) => {
  return years.map((year) => {
    const yearAsNumber = Number(year);
    return `${yearAsNumber}/${yearAsNumber + 1}`;
  });
};

import has from 'lodash/has';
import isObject from 'lodash/isObject';
import values from 'lodash/values';
import { ColumnsConfig } from 'components/shared/data/DataGrid/DataGrid.types';
import { SynchronizationLog, SynchronizationLogLevel } from 'store/reports/reports.types';

const hasInvalidValue = (item: SynchronizationLog): boolean => {
  if (item.details && isObject(item.details) && !Array.isArray(item.details)) {
    if (has(item, 'details.message') && has(item, 'details.error') && !has(item, 'details.subject')) {
      return false;
    }
    return true;
  }
  return false;
};

const prepareInvalidValueMessage = (item: SynchronizationLog, trans): string => {
  if (has(item, 'details.subject')) {
    return item.details.subject as string;
  }
  return trans('REPORTS.SYNCHRONIZATIONS.TOOLTIPS.INVALID_VALUE', { value: values(item.details).join(', ') });
};

export const synchronizationLogColumns: ColumnsConfig<SynchronizationLog, void> = () => [
  {
    field: 'createdAt',
    name: 'REPORTS.SYNCHRONIZATIONS.COLUMNS.CREATED_AT',
    columnParams: { minWidth: 130, maxWidth: 130 },
    format: (data, trans, formatter) => {
      return formatter.formatDateWithTime(data.createdAt);
    },
  },
  {
    field: 'source',
    name: 'REPORTS.SYNCHRONIZATIONS.COLUMNS.PROCESS',
    columnParams: { minWidth: 280, maxWidth: 340 },
    format: (data, trans) => {
      return trans(`REPORTS.SYNCHRONIZATIONS.PROCESS.${data.source.toUpperCase().replace(/ /g, '_')}`);
    },
  },
  {
    field: 'message',
    name: 'REPORTS.SYNCHRONIZATIONS.COLUMNS.MESSAGE',
    columnParams: { minWidth: 280, maxWidth: 500 },
  },
  {
    field: 'level',
    name: 'REPORTS.SYNCHRONIZATIONS.COLUMNS.LEVEL',
    columnParams: {
      minWidth: 100,
      maxWidth: 100,
      cellStyle: (params) => {
        switch (params.data && params.data.level) {
          case SynchronizationLogLevel.INFO:
            return { color: 'black' };
          case SynchronizationLogLevel.WARNING:
            return { color: 'orange' };
          case SynchronizationLogLevel.ERROR:
            return { color: 'red' };
        }
      },
    },
    format: (data, trans) => {
      return trans(`REPORTS.SYNCHRONIZATIONS.LEVEL.${data.level}`);
    },
  },
  {
    field: 'details',
    name: '',
    columnParams: {
      minWidth: 40,
      maxWidth: 40,
    },
    cellTooltip: () => '',
    renderer: 'iconsCell',
    rendererParams: {
      icons: (item: SynchronizationLog) => {
        const invalidValue = hasInvalidValue(item);
        if (invalidValue) {
          return [
            {
              type: 'default',
              icon: 'exclamation-circle',
              tooltip: (trans) => prepareInvalidValueMessage(item, trans),
            },
          ];
        }
        return [];
      },
    },
  },
  {
    field: 'school',
    name: 'REPORTS.SYNCHRONIZATIONS.COLUMNS.TARGET',
    columnParams: {
      minWidth: 140,
    },
    format: (data) => data.child || data.school || '-',
  },
];

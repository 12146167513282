import { SummaryProps } from 'components/shared/data/Summary/Summary.types';
import { muiStyles } from 'utils/design';

export const useStyles = muiStyles<SummaryProps>((theme) => ({
  container: {
    marginBottom: ({ linear }) => theme.spacing(linear ? 1 : 3),
    display: ({ linear }) => (linear ? 'flex' : 'block'),
  },
  row: {
    display: 'flex',
    marginTop: theme.spacing(1),
    marginRight: ({ linear }) => theme.spacing(linear ? 4 : 0),
  },
  label: {
    width: ({ linear }) => (linear ? 'auto' : 200),
  },
  value: {
    fontWeight: 'bolder',
  },
}));

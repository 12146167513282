import { useState } from 'react';
import { UseSubmenuStateHook } from './useSubmenuState.types';

export const useSubmenuState: UseSubmenuStateHook = (isFloating) => {
  const [collapsibleOpen, setCollapsibleOpen] = useState<boolean>(false);
  const [floatingOpen, setFloatingOpen] = useState<boolean>(false);

  const setOpen = (value: boolean) => {
    if (isFloating) {
      setFloatingOpen(value);
    } else {
      setCollapsibleOpen(value);
    }
  };

  const open: boolean = isFloating ? floatingOpen : collapsibleOpen;

  return [open, setOpen];
};

import React, { FC, Fragment } from 'react';
import { SearchContainer } from 'components/search/SearchContainer';
import { Page } from 'components/layout/panel/Page/Page';
import { useTranslator } from 'components/shared/hooks';
import { Breadcrumb } from 'components/shared/navigation/Breadcrumbs/Breadcrumb';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import { useStyles } from 'views/Panel/Search/SearchView.styles';

export const SearchView: FC = () => {
  const trans = useTranslator();
  const classes = useStyles();

  return (
    <Fragment>
      <Breadcrumb label={trans('BREADCRUMB.SEARCH')} static />
      <Page title={trans('SEARCH.TITLE')}>
        <Paper innerSpacing={2} outerSpacing={[0, 2, 2]} className={classes.paper}>
          <SearchContainer />
        </Paper>
      </Page>
    </Fragment>
  );
};

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { WindowInfo, windowInfoSelector } from 'store/general';

export const useResolutionChange = (effect: (window: WindowInfo) => void) => {
  const window: WindowInfo = useSelector(windowInfoSelector);

  useEffect(() => {
    effect(window);
  }, [window, effect]);
};

import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon.types';
import { QuestionType } from 'store/polls';

interface QuestionsIconsMapping {
  [type: string]: FontAwesomeIcon;
}

export const questionTypeIcon: QuestionsIconsMapping = {
  [QuestionType.TEXT]: 'font',
  [QuestionType.LONG_TEXT]: 'paragraph',
  [QuestionType.SINGLE_CHOICE]: 'dot-circle',
  [QuestionType.MULTIPLE_CHOICE]: 'check-square',
};

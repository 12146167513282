import { StatDetailsTable, SchoolStatType } from 'store/reports/reports.types';

export interface SchoolStatDetailsContainerProps {
  id: string;
}

export interface SchoolStatDetailsProps {
  name: string;
  updateDate: string;
  table: StatDetailsTable | null;
  isLoading: boolean;
  type: SchoolStatType;
}

export enum ReportSpecialValues {
  REPORT_ROOT = 'REPORT_ROOT',
  EMPTY_LEAF = 'NONE',
}

import { pathResolver } from 'components/shared/navigation/RoutedTabPanel/pathResolver';
import { useStyles } from 'components/shared/navigation/RoutedTabPanel/RoutedTabPanel.styles';
import { RoutedTabPanelProps, TabConfig } from 'components/shared/navigation/RoutedTabPanel/RoutedTabPanel.types';
import { push } from 'connected-react-router';
import { formatRoute } from 'react-router-named-routes';
import React, { FC } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { routerSelector } from 'store/router/router.selectors';

export const RoutedTabPanel: FC<RoutedTabPanelProps> = ({ tabs }) => {
  const classes = useStyles();
  const routing = useSelector(routerSelector);
  const dispatch = useDispatch();
  const tabValue = pathResolver(routing.pathname, tabs);

  function handleChange(event: React.ChangeEvent<{}>, path: string) {
    dispatch(push(path));
  }

  const singleTabRender = (tab: TabConfig, index: number) => {
    const path = formatRoute(tab.route, tab.routeParams);

    return <Tab label={tab.title} value={path} key={index} />;
  };

  return (
    tabValue && (
      <Tabs value={tabValue} onChange={handleChange} classes={classes}>
        {tabs.map(singleTabRender)}
      </Tabs>
    )
  );
};

import { muiStyles } from 'utils/design';

export const useStyles = muiStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
});

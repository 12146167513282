import { Tooltip } from '@material-ui/core';
import { AddressesListTitleProps } from 'components/schoolDistricts/DistrictsBrowser/AddressesList/AddressesList.types';
import Access from 'components/shared/access/access';
import { Button } from 'components/shared/buttons/Button/Button';
import { useTranslator } from 'components/shared/hooks';
import React, { FC } from 'react';
import { OfficialPermissionLevel, OfficialResourceGroup } from 'store/officials';
import { DatabaseStatus } from 'store/schoolDistricts';

export const AddressesListTitle: FC<AddressesListTitleProps> = (props) => {
  const trans = useTranslator();
  const isDatabaseArchival = props.databaseStatus === DatabaseStatus.ARCHIVAL;
  const tooltip = isDatabaseArchival
    ? trans('SCHOOL_DISTRICTS.DISTRICTS-LIST.ADDRESS-LIST.ADD-BUTTON-LOCKED-TOOLTIP')
    : '';

  return (
    <div className="d-flex align-items-center justify-content-between">
      <h3>{props.title}</h3>
      {props.onAdd && (
        <Access resourcesGroup={OfficialResourceGroup.childRegister} accessType={OfficialPermissionLevel.fullAccess}>
          <Tooltip title={tooltip} placement="top">
            <span>
              <Button
                variant="outlined"
                color="primary"
                disabled={isDatabaseArchival}
                onClick={props.onAdd}
                tabIndex={0}
              >
                {trans('SCHOOL_DISTRICTS.DISTRICTS-LIST.ADDRESS-LIST.ADD-BUTTON')}
              </Button>
            </span>
          </Tooltip>
        </Access>
      )}
    </div>
  );
};

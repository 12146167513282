import { ReportSpecialValues } from 'components/reports/SchoolStatDetails/SchoolStatDetails.types';
import { Translator } from 'context/locale/LocaleContext/LocaleContext.types';
import { cloneDeepWith, isObject, reduce, toNumber, uniqueId } from 'lodash';
import {
  AggregationConfig,
  AggregationMethod,
  AggregationResult,
  AggregationValue,
  isAggregationValueList,
  isRowGroup,
  StatDetailsColumn,
  StatDetailsRow,
  StatDetailsRowGroup,
  StatDetailsTable,
} from 'store/reports/reports.types';

export const transformValue = (value: string | number) => {
  let parsedValue = value;
  if (Number(value) === value && value % 1 !== 0) parsedValue = value.toFixed(2);
  if (String(value) === value && parseFloat(value) % 1 !== 0) parsedValue = parseFloat(value).toFixed(2);

  const parts = parsedValue.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.join(',');
};

const getSum = (values: AggregationValue[]): number => {
  return values.reduce((sum, current) => sum + Number(current.value), 0);
};

const getAverage = (values: AggregationValue[]): number => {
  return values.reduce((sum, current) => sum + Number(current.value), 0) / values.length;
};

const getGroupedLeafsCount = (values: AggregationValue[]) => {
  return values.reduce((sum, current) => {
    return sum + Number(current.extra.leafsCount);
  }, 0);
};

export const findDataForNode = (node, colId: string, groupData: StatDetailsRowGroup[]) => {
  let currentNode = node;
  let level = 0;

  // We are ascending through the ag-grid nodes tree to count
  // how many levels below the root we really are
  do {
    currentNode = currentNode.childrenAfterFilter[0];

    if (!currentNode) {
      return null;
    }

    level++;
  } while (currentNode.group);

  let rowIdPointer = currentNode.data._parentId;
  let row = currentNode;

  for (let i = 0; i < level; i++) {
    const rowId = rowIdPointer; // no-loop-func
    row = groupData.find((row) => row._id === rowId);
    rowIdPointer = row._parentId;
  }

  return row.data[colId];
};

const calculateAggregatedValue = (aggregation: AggregationConfig, values: AggregationValue[]): AggregationResult => {
  const items = values.filter((value) => !value.extra?.exclude);

  switch (aggregation.method) {
    case AggregationMethod.AVG:
      return { value: getAverage(items) };
    case AggregationMethod.SUM:
      return { value: getSum(items) };
    case AggregationMethod.COUNT:
      // When counting rows we have to do it in two ways:
      // - first when we're counting leafs we just take the number of rows,
      // - then when we're counting groups we need to sum the numbers of leafs
      //   in each group so we take the value hidden in `leafsCount` field.
      if (items.length && items[0].extra.leafsCount !== undefined) {
        const count = getGroupedLeafsCount(items);
        return {
          value: count,
          extra: { leafsCount: count },
        };
      } else {
        return {
          value: items.length.toString(),
          extra: { leafsCount: items.length },
        };
      }
    case AggregationMethod.NONE:
      return { value: '' };
    case AggregationMethod.FIXED:
    default:
      return { value: aggregation.value };
  }
};

export const getReportAggregator = (values: string[] | AggregationValue[]): AggregationValue => {
  if (isAggregationValueList(values) && values.length) {
    // We assume the aggregation method for this group is the same
    // in bot children, if not the first child will decide.
    const nextAggregationsPath = [...values[0].nextAggregationsPath];
    const aggregation = nextAggregationsPath.pop();

    if (!aggregation) {
      return null;
    }

    const { value, extra } = calculateAggregatedValue(aggregation, values);
    const suffix = aggregation.method === AggregationMethod.FIXED ? ' *' : '';

    return {
      value,
      extra,
      nextAggregationsPath,
      aggregation,
      toString: () => {
        return value !== null && value !== '' ? `${transformValue(value)}${suffix}` : `0${suffix}`;
      },
    };
  }
};

const createAggregationValue = (value: number | string, extra: any = {}) => ({
  value,
  extra,
  nextAggregationsPath: [],
  toString: () => (value === null || value === '' ? '0' : transformValue(value)),
});

export const getProcessedTableData = (
  rows: StatDetailsRow[],
  columns: StatDetailsColumn[],
  collectedGroupedData: StatDetailsRow = {},
) => {
  const headers: StatDetailsRow[] = [];
  const leafs: StatDetailsRow[] = [];

  const aggregatedColumns = columns.filter((column) => column.aggregationField !== null);
  const groupingColumns = columns.filter((column) => column.group);
  const valueColumns = columns.filter((column) => !column.group && column.aggregationField === null);

  rows.forEach((row) => {
    if (isRowGroup(row)) {
      let descentHeaders: StatDetailsRow[], descentLeafs: StatDetailsRow[];

      // When descending into the tree we collect the values from grouped
      // columns to create a fake leaf in case we need it at the bottom.
      groupingColumns.forEach(({ field }) => {
        if (row.data[field]) {
          collectedGroupedData = { ...collectedGroupedData, [field]: row.data[field] };
        }
      });

      if (row.children.length > 0) {
        const data = getProcessedTableData(row.children, columns, collectedGroupedData);
        descentHeaders = data.headers;
        descentLeafs = data.leafs;
      } else {
        // Sometimes there are no leafs no we need to create a fake leaf.
        const fakeLeaf: StatDetailsRow = { ...collectedGroupedData };

        // We need to push value in every aggregated column but
        // we can't allow it to be included in calculations
        aggregatedColumns.forEach(({ field }) => {
          fakeLeaf[field] = createAggregationValue('', { exclude: true });
        });

        // We need to inform the user why we're displaying an empty row
        if (valueColumns.length) {
          const lastValueField = valueColumns.reverse()[valueColumns.length - 1].field;
          fakeLeaf[lastValueField] = ReportSpecialValues.EMPTY_LEAF;
          fakeLeaf._isFake = true;
        }

        descentHeaders = [];
        descentLeafs = [fakeLeaf];
      }

      row._id = uniqueId('group-');

      descentLeafs.forEach((leaf) => {
        if (!leaf._parentId) {
          // set parentId only for the rows without it so we will
          // not overwrite it in the upper levels of recurrence.
          leaf._parentId = row._id;
        }
        aggregatedColumns.forEach(({ field, aggregationField }) => {
          if (aggregationField !== null) {
            leaf[field].nextAggregationsPath.unshift(row.data[aggregationField]);
          }
        });
      });

      descentHeaders.forEach((header) => {
        if (!header._parentId) {
          // see comment above
          header._parentId = row._id;
        }
      });

      headers.push({ ...row }, ...descentHeaders);
      leafs.push(...descentLeafs);
    } else {
      const newLeaf = {
        ...row,
        _id: uniqueId('leaf-'),
      };

      aggregatedColumns.forEach(({ field }) => {
        const value = newLeaf[field];
        newLeaf[field] = createAggregationValue(value);
      });

      leafs.push(newLeaf);
    }
  });

  return { headers, leafs };
};

export const getSpecialValueSlug = (key: ReportSpecialValues) => {
  return `REPORTS.SCHOOL_STAT_DETAILS.VALUES.${key}`;
};

export const formatSpecialValues = <T>(tableData: T, trans: Translator): T => {
  return cloneDeepWith(tableData, (value: any): string => {
    if (Object.values(ReportSpecialValues).includes(value)) {
      return trans(getSpecialValueSlug(value));
    } else {
      return undefined;
    }
  });
};

export const prepareAggregationTreeData = (table: StatDetailsTable) => {
  const columnsToParse = table.columns.filter((item) => item.aggregation).map((item) => item.field);
  return table.data.map((item) => {
    if (isObject(item)) {
      return reduce(
        item,
        (result, value, key) => {
          if (columnsToParse.includes(key)) {
            result[key] = toNumber(value);
          } else {
            result[key] = value;
          }
          return result;
        },
        {},
      );
    }
    return item;
  });
};

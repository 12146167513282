import { muiStyles } from 'utils/design';

export const useStyles = muiStyles((theme) => ({
  container: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
  },
  paper: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',

    '@media (max-height: 725px)': {
      overflowY: 'scroll',
    },
  },
}));

import { muiStyles } from 'utils/design';

export const useStyles = muiStyles(() => ({
  container: {
    display: 'flex',
    width: '300px',
    minWidth: '100%',
    maxWidth: '100%',
  },
  field: {
    width: 'calc(100% - 4px - 32px - 4px - 32px)',
  },
  input: {
    marginRight: '8px',
    '& input': {
      textAlign: 'right',
    },
  },
  controls: {
    width: 'calc(4px + 32px + 4px + 32px)',
  },
  button: {
    minWidth: '32px',
    height: '30px',
    marginLeft: '4px',
  },
}));

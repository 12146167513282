import { ColumnsConfig } from 'components/shared/data/DataGrid/DataGrid.types';
import { ChildModel } from 'store/schoolDistricts';

export const childrenColumns: ColumnsConfig<ChildModel> = () => [
  {
    field: 'firstName',
    name: 'SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.CHILDREN_LIST.COLUMNS.FIRST_NAME',
    cellClass: 'indent-cell',
    columnParams: { minWidth: 80 },
  },
  {
    field: 'secondName',
    name: 'SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.CHILDREN_LIST.COLUMNS.SECOND_NAME',
    cellClass: 'indent-cell',
    columnParams: { minWidth: 80 },
  },
  {
    field: 'lastName',
    name: 'SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.CHILDREN_LIST.COLUMNS.LAST_NAME',
    cellClass: 'indent-cell',
    columnParams: { minWidth: 100 },
  },
  {
    field: 'sex',
    name: 'SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.CHILDREN_LIST.COLUMNS.SEX',
    cellClass: 'indent-cell',
    columnParams: { minWidth: 50, width: 60 },
    format: (data, trans) => {
      if (data.sex) {
        return trans(`SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.CHILDREN_LIST.TYPES.${data.sex.toUpperCase()}`);
      } else {
        return '';
      }
    },
  },
  {
    field: 'identificationNumber',
    name: 'SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.CHILDREN_LIST.COLUMNS.IDENTIFICATION_NUMBER',
    cellClass: 'indent-cell',
    columnParams: { minWidth: 100 },
  },
  {
    field: 'birthDate',
    name: 'SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.CHILDREN_LIST.COLUMNS.BIRTH_DATE',
    cellClass: 'indent-cell',
    columnParams: { minWidth: 90 },
    format: (data, trans, format) => {
      if (data.birthDate) {
        return format.formatDate(data.birthDate);
      } else {
        return '';
      }
    },
  },
  {
    field: 'birthCity',
    name: 'SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.CHILDREN_LIST.COLUMNS.BIRTH_CITY',
    cellClass: 'indent-cell',
    columnParams: { minWidth: 90 },
  },
  {
    field: 'isQualified',
    name: 'SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.CHILDREN_LIST.COLUMNS.QUALIFIED_CHILD',
    cellClass: 'indent-cell',
    columnParams: { minWidth: 80 },
    format: (data, trans) => {
      return data.isQualified ? trans('COMMON.YES') : trans('COMMON.NO');
    },
  },
  {
    field: 'usedAddress',
    name: 'SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.CHILDREN_LIST.COLUMNS.USED_ADDRESS',
    cellClass: 'indent-cell',
    columnParams: { minWidth: 100 },
    format: (data, trans) => {
      return trans(`SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.CHILDREN_LIST.TYPES.${data.usedAddress.toUpperCase()}`);
    },
  },
  {
    field: 'residenceAddress',
    name: 'SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.CHILDREN_LIST.COLUMNS.RESIDENCE_ADDRESS',
    cellClass: 'indent-cell',
    columnParams: { minWidth: 100 },
  },
  {
    field: 'permanentAddress',
    name: 'SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.CHILDREN_LIST.COLUMNS.PERMANENT_ADDRESS',
    cellClass: 'indent-cell',
    columnParams: { minWidth: 100 },
  },
];

import { useDetailsGridsEvent, useGridEvent } from 'components/shared/data/DataGrid/hooks';
import { CountSelectedProps } from 'components/shared/data/DataGrid/StatusPanel/CountSelected/CountSelected.types';
import { getSelectedRowsForMasterNodes } from 'components/shared/data/DataGrid/utils/masterDetailsHelpers';
import { useTranslator } from 'components/shared/hooks';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Events } from 'ag-grid-community';

export const CountSelectedInDetails = ({ api, title, context }: CountSelectedProps): ReactElement => {
  const [count, setCount] = useState(0);
  const trans = useTranslator();
  const [detailsGridsCount, setDetailsGridsCount] = useState(0);

  const updateValue = useCallback(
    (api) => {
      setTimeout(() => {
        const rows = getSelectedRowsForMasterNodes(api.getSelectedNodes(), context.detailsMapping);
        setCount(rows.length);
      });
    },
    [context.detailsMapping],
  );

  useGridEvent(api, Events.EVENT_MODEL_UPDATED, ({ api }) => {
    setDetailsGridsCount(Object.keys(api.detailGridInfoMap).length);
    updateValue(api);
  });

  useGridEvent(api, Events.EVENT_PAGINATION_CHANGED, ({ api }) => {
    setDetailsGridsCount(Object.keys(api.detailGridInfoMap).length);
    updateValue(api);
  });

  useGridEvent(api, Events.EVENT_SELECTION_CHANGED, ({ api }) => {
    setDetailsGridsCount(Object.keys(api.detailGridInfoMap).length);
    updateValue(api);
  });

  useEffect(() => {
    updateValue(api);
  }, [api, detailsGridsCount, updateValue]);

  useDetailsGridsEvent(api, Events.EVENT_SELECTION_CHANGED, () => updateValue(api), [api, detailsGridsCount]);

  useDetailsGridsEvent(api, Events.EVENT_PAGINATION_CHANGED, () => updateValue(api), [api, detailsGridsCount]);

  useDetailsGridsEvent(api, Events.EVENT_MODEL_UPDATED, () => updateValue(api), [api, detailsGridsCount]);

  return (
    <div className="ag-name-value">{count === 0 ? '' : trans(title || 'COMMON.SELECTED_N_RESULTS', { count })}</div>
  );
};

import { omit, pickBy, identity } from 'lodash';
import { RSAA, RSAACreator } from 'redux-api-middleware';
import { institutionInitialFilters } from 'store/institutions/institutions.state';
import { FSA } from '@typings/redux';
import { toApiFormat } from 'utils/api';
import { clean } from 'utils/object/clean';
import { fileToObjectFetch } from 'utils/redux/fileToObjectFetch';
import { InstitutionsFilters, UnitPostModel, StudentImportModel } from './institutions.types';
import { InstitutionsActionType as ActionType } from './institutions.actionTypes';
import { SettingsForm } from 'components/institution/Unit/StudentsRegister/Import/steps/Settings/Settings.types';

export const fetchInstitutionsList: RSAACreator<ActionType> = (filters: InstitutionsFilters) => {
  return {
    [RSAA]: {
      endpoint: '/institutions',
      method: 'GET',
      types: [
        {
          type: ActionType.FETCH_INSTITUTIONS_LIST_REQUEST,
          meta: {
            takeLatest: true,
          },
        },
        ActionType.FETCH_INSTITUTIONS_LIST_SUCCESS,
        ActionType.FETCH_INSTITUTIONS_LIST_FAILURE,
      ],
      params: {
        search: filters.search,
        filter: {
          ...pickBy(omit(filters, ['search']), identity),
        },
      },
    },
  };
};

export const setInstitutionFilters = (filters: InstitutionsFilters): FSA<ActionType> => {
  return {
    type: ActionType.SET_INSTITUTION_FILTERS,
    payload: filters,
  };
};

export const clearInstitutionFilters = (): FSA<ActionType> => {
  return {
    type: ActionType.SET_INSTITUTION_FILTERS,
    payload: institutionInitialFilters,
  };
};

export const fetchInstitutionsDictionary: RSAACreator<ActionType> = () => {
  return {
    [RSAA]: {
      endpoint: '/institutions/dictionary',
      method: 'GET',
      types: [
        {
          type: ActionType.FETCH_INSTITUTIONS_DICTIONARY_REQUEST,
          meta: {
            takeLatest: true,
          },
        },
        ActionType.FETCH_INSTITUTIONS_DICTIONARY_SUCCESS,
        ActionType.FETCH_INSTITUTIONS_DICTIONARY_FAILURE,
      ],
      params: {},
    },
  };
};

export const putInstitution: RSAACreator<ActionType> = (payload: { name: string }, id: string) => {
  return {
    [RSAA]: {
      endpoint: `/institutions/${id}`,
      method: 'PUT',
      types: [
        { type: ActionType.PUT_INSTITUTION_LIST_REQUEST, meta: { lockScreen: true } },
        ActionType.PUT_INSTITUTION_LIST_SUCCESS,
        ActionType.PUT_INSTITUTION_LIST_FAILURE,
      ],
      body: payload,
      params: {},
    },
  };
};

export const fetchInstitution: RSAACreator<ActionType> = (id: string) => {
  return {
    [RSAA]: {
      endpoint: `/institutions/${id}`,
      method: 'GET',
      types: [
        ActionType.FETCH_INSTITUTION_REQUEST,
        ActionType.FETCH_INSTITUTION_SUCCESS,
        ActionType.FETCH_INSTITUTION_FAILURE,
      ],
      params: {},
    },
  };
};

export const removeInstitution: RSAACreator<ActionType> = (id: string) => {
  return {
    [RSAA]: {
      endpoint: `/institutions/${id}`,
      method: 'DELETE',
      types: [
        {
          type: ActionType.DELETE_INSTITUTION_REQUEST,
          meta: { lockScreen: true },
        },
        ActionType.DELETE_INSTITUTION_SUCCESS,
        ActionType.DELETE_INSTITUTION_FAILURE,
      ],
      params: {},
    },
  };
};

export const removeUnit: RSAACreator<ActionType> = (id: string) => {
  return {
    [RSAA]: {
      endpoint: `/units/${id}`,
      method: 'DELETE',
      types: [
        {
          type: ActionType.DELETE_UNIT_REQUEST,
          meta: { lockScreen: true },
        },
        {
          type: ActionType.DELETE_UNIT_SUCCESS,
          meta: { unitId: id },
        },
        ActionType.DELETE_UNIT_FAILURE,
      ],
      params: {},
    },
  };
};

export const postUnit: RSAACreator<ActionType> = (payload: UnitPostModel) => {
  return {
    [RSAA]: {
      endpoint: `/units`,
      method: 'POST',
      types: [
        {
          type: ActionType.POST_UNIT_REQUEST,
          meta: { lockScreen: true },
        },
        ActionType.POST_UNIT_SUCCESS,
        ActionType.POST_UNIT_FAILURE,
      ],
      params: {},
      body: payload,
    },
  };
};

export const fetchUnit: RSAACreator<ActionType> = (id: string) => {
  return {
    [RSAA]: {
      endpoint: `/units/${id}`,
      method: 'GET',
      types: [ActionType.FETCH_UNIT_REQUEST, ActionType.FETCH_UNIT_SUCCESS, ActionType.FETCH_UNIT_FAILURE],
      params: {},
    },
  };
};

export const clearUnit = (): FSA<ActionType> => {
  return {
    type: ActionType.CLEAR_UNIT,
  };
};

export const putUnit: RSAACreator<ActionType> = (payload: UnitPostModel, id: string) => {
  return {
    [RSAA]: {
      endpoint: `/units/${id}`,
      method: 'PUT',
      types: [
        { type: ActionType.PUT_UNIT_REQUEST, meta: { lockScreen: true } },
        ActionType.PUT_UNIT_SUCCESS,
        ActionType.PUT_UNIT_FAILURE,
      ],
      params: {},
      body: toApiFormat(payload),
    },
  };
};

export const fetchStudentRegisterList: RSAACreator<ActionType> = (unitId: string) => {
  return {
    [RSAA]: {
      endpoint: `/units/${unitId}/student-register`,
      method: 'GET',
      types: [
        ActionType.FETCH_STUDENT_REGISTER_LIST_REQUEST,
        ActionType.FETCH_STUDENT_REGISTER_LIST_SUCCESS,
        ActionType.FETCH_STUDENT_REGISTER_LIST_FAILURE,
      ],
      params: {},
    },
  };
};

export const fetchStudentRegisterSchoolYears: RSAACreator<ActionType> = () => {
  return {
    [RSAA]: {
      endpoint: `/student-register/school-years`,
      method: 'GET',
      types: [
        ActionType.FETCH_STUDENT_REGISTER_SCHOOL_YEARS_REQUEST,
        ActionType.FETCH_STUDENT_REGISTER_SCHOOL_YEARS_SUCCESS,
        ActionType.FETCH_STUDENT_REGISTER_SCHOOL_YEARS_FAILURE,
      ],
      params: {},
    },
  };
};

export const clearStudentsRegisterList = (): FSA<ActionType> => {
  return {
    type: ActionType.CLEAR_STUDENT_REGISTER_LIST,
  };
};

export const fetchStudentRegisterDetails: RSAACreator<ActionType> = (
  unitId: string,
  registerId: string,
  rollbackDate: string,
) => {
  return {
    [RSAA]: {
      endpoint: `/units/${unitId}/student-register/${registerId}`,
      method: 'GET',
      types: [
        ActionType.FETCH_STUDENT_REGISTER_REQUEST,
        ActionType.FETCH_STUDENT_REGISTER_SUCCESS,
        ActionType.FETCH_STUDENT_REGISTER_FAILURE,
      ],
      params: clean({
        date: rollbackDate,
      }),
    },
  };
};

export const downloadStudentRegisterDetails: RSAACreator<ActionType> = (
  unitId: string,
  registerId: string,
  rollbackDate: string,
  fileName: string,
) => {
  return {
    [RSAA]: {
      endpoint: `/units/${unitId}/student-register/${registerId}`,
      method: 'GET',
      fetch: fileToObjectFetch('text/csv', `${fileName}.csv`),
      types: [
        ActionType.DOWNLOAD_STUDENT_REGISTER_REQUEST,
        {
          type: ActionType.DOWNLOAD_STUDENT_REGISTER_SUCCESS,
          meta: { downloadResponse: true },
        },
        ActionType.DOWNLOAD_STUDENT_REGISTER_FAILURE,
      ],
      params: clean({
        date: rollbackDate,
      }),
    },
  };
};

export const deleteStudentsRegister: RSAACreator<ActionType> = (unitId: string, registerId: string) => {
  return {
    [RSAA]: {
      endpoint: `/units/${unitId}/student-register/${registerId}`,
      method: 'DELETE',
      types: [
        { type: ActionType.DELETE_STUDENT_REGISTER_REQUEST, meta: { lockScreen: true } },
        ActionType.DELETE_STUDENT_REGISTER_SUCCESS,
        ActionType.DELETE_STUDENT_REGISTER_FAILURE,
      ],
      params: {},
    },
  };
};

export const clearStudentsRegisterDetails = (): FSA<ActionType> => {
  return {
    type: ActionType.CLEAR_STUDENT_REGISTER,
  };
};

export const postStudentRegisterImport: RSAACreator<ActionType> = (file: string | File, unitId: string) => {
  const formData = new FormData();
  formData.append('import_file', file);

  return {
    [RSAA]: {
      endpoint: `/units/${unitId}/student-register/import`,
      method: 'POST',
      types: [
        {
          type: ActionType.POST_STUDENT_REGISTER_IMPORT_REQUEST,
          meta: {
            lockScreen: true,
          },
        },
        {
          type: ActionType.POST_STUDENT_REGISTER_IMPORT_SUCCESS,
          meta: {
            fileName: file instanceof File ? file.name : null,
          },
        },
        ActionType.POST_STUDENT_REGISTER_IMPORT_FAILURE,
      ],
      body: formData,
      params: {},
    },
  };
};

export const deleteStudentRegisterImport: RSAACreator<ActionType> = (token: string, unitId: string) => {
  return {
    [RSAA]: {
      endpoint: `/units/${unitId}/student-register/import/${token}`,
      method: 'DELETE',
      types: [
        ActionType.DELETE_STUDENT_REGISTER_IMPORT_REQUEST,
        ActionType.DELETE_STUDENT_REGISTER_IMPORT_SUCCESS,
        ActionType.DELETE_STUDENT_REGISTER_IMPORT_FAILURE,
      ],
      params: {},
    },
  };
};

export const fetchStudentRegisterImportColumns: RSAACreator<ActionType> = (token: string, unitId: string) => {
  return {
    [RSAA]: {
      endpoint: `/units/${unitId}/student-register/import/${token}/header`,
      method: 'GET',
      types: [
        ActionType.FETCH_STUDENT_REGISTER_IMPORT_COLUMNS_REQUEST,
        ActionType.FETCH_STUDENT_REGISTER_IMPORT_COLUMNS_SUCCESS,
        ActionType.FETCH_STUDENT_REGISTER_IMPORT_COLUMNS_FAILURE,
      ],
      params: {},
    },
  };
};

export const postStudentRegisterImportContext: RSAACreator<ActionType> = (
  token: string,
  unitId: string,
  values: SettingsForm,
) => {
  return {
    [RSAA]: {
      endpoint: `/units/${unitId}/student-register/import/${token}/context`,
      method: 'POST',
      types: [
        {
          type: ActionType.POST_STUDENT_REGISTER_IMPORT_CONTEXT_REQUEST,
          meta: {
            lockScreen: true,
          },
        },
        {
          type: ActionType.POST_STUDENT_REGISTER_IMPORT_CONTEXT_SUCCESS,
          meta: {
            context: values,
          },
        },
        ActionType.POST_STUDENT_REGISTER_IMPORT_CONTEXT_FAILURE,
      ],
      params: {},
      body: toApiFormat(values),
    },
  };
};

export const fetchStudentRegisterPreview: RSAACreator<ActionType> = (token: string, unitId: string) => {
  return {
    [RSAA]: {
      endpoint: `/units/${unitId}/student-register/import/${token}/preview`,
      method: 'GET',
      types: [
        ActionType.FETCH_STUDENT_REGISTER_PREVIEW_REQUEST,
        ActionType.FETCH_STUDENT_REGISTER_PREVIEW_SUCCESS,
        ActionType.FETCH_STUDENT_REGISTER_PREVIEW_FAILURE,
      ],
      params: {},
    },
  };
};

export const postStudentRegisterConfirm: RSAACreator<ActionType> = (token: string, unitId: string) => {
  return {
    [RSAA]: {
      endpoint: `/units/${unitId}/student-register/import/${token}/confirm`,
      method: 'POST',
      types: [
        { type: ActionType.POST_STUDENT_REGISTER_CONFIRM_REQUEST, meta: { lockScreen: true } },
        ActionType.POST_STUDENT_REGISTER_CONFIRM_SUCCESS,
        ActionType.POST_STUDENT_REGISTER_CONFIRM_FAILURE,
      ],
      params: {},
    },
  };
};

export const clearStudentRegisterInformation = (): FSA<ActionType> => {
  return {
    type: ActionType.CLEAR_STUDENT_REGISTER_INFORMATION,
  };
};

export const mapImportFields = (map: StudentImportModel) => ({
  type: ActionType.MAP_IMPORT_FIELDS,
  payload: map,
});

import { Theme } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { AppDispatch } from '@typings/redux';
import { useStyles } from 'components/institution/Browser/Toolbar/Toolbar.styles';
import { ToolbarProps } from 'components/institution/Browser/Toolbar/Toolbar.types';
import { Button } from 'components/shared/buttons/Button/Button';
import { Form, SelectBox } from 'components/shared/form';
import { Autocomplete } from 'components/shared/form/Autocomplete/Autocomplete';
import { FocusTrap } from 'components/shared/form/FocusTrap/FocusTrap';
import { SearchInput } from 'components/shared/form/SearchInput/SearchInput';
import { useTranslator } from 'components/shared/hooks/useTranslator/useTranslator';
import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon';
import { ROUTE_INSTITUTION_UNIT_ADD } from 'config/routes';
import { push } from 'connected-react-router';
import React, { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dictionariesSelector } from 'store/general';
import { ThemeProvider } from '@material-ui/styles';
import { filtersThemeFactory } from 'styles/themes/filtersTheme';
import { toOptions } from 'utils/api';
import { minLength } from 'utils/formValidators/minLength/minLength';
import { AutoSave } from 'components/shared/form/AutoSave/AutoSave';
import {
  DataSharingTypes,
  institutionsDictionarySelector,
  InstitutionsFilters,
  institutionsListFiltersSelector,
  setInstitutionFilters,
  studentRegisterSchoolYearsSelector,
} from 'store/institutions';
import { combineValidators, maxLength } from 'utils/formValidators';
import Access from 'components/shared/access/access';
import { OfficialPermissionLevel, OfficialResourceGroup } from 'store/officials';

const ToolbarComponent = (props: ToolbarProps, ref) => {
  const classes = useStyles();
  const dispatch = useDispatch() as AppDispatch;
  const trans = useTranslator();

  const filters = useSelector(institutionsListFiltersSelector);
  const institutionsDictionary = useSelector(institutionsDictionarySelector);
  const dictionaries = useSelector(dictionariesSelector);
  const schoolYears = useSelector(studentRegisterSchoolYearsSelector);

  const valuesChanged = (values: InstitutionsFilters) => {
    dispatch(setInstitutionFilters(values));
  };

  const addNewUnit = () => {
    dispatch(push(ROUTE_INSTITUTION_UNIT_ADD));
  };

  return (
    <section ref={ref}>
      <Access
        resourcesGroup={[OfficialResourceGroup.childRegister, OfficialResourceGroup.institutionalCoreGrant]}
        accessType={OfficialPermissionLevel.fullAccess}
      >
        <Button variant="contained" color="primary" className="float-right" onClick={addNewUnit} tabIndex={0}>
          <FontAwesomeIcon name={'plus-circle'} className={'mr-2'} />
          {trans('INSTITUTIONS.BROWSER.ADD-UNIT')}
        </Button>
      </Access>
      <Form<InstitutionsFilters> initialValues={filters} onSubmit={valuesChanged} className={classes.form}>
        {() => (
          <div className={classes.filters}>
            <ThemeProvider theme={(parentTheme: Theme) => filtersThemeFactory(parentTheme)}>
              <SearchInput
                name="search"
                labelText={trans('INSTITUTIONS.FILTERS.SEARCH.LABEL')}
                icon={<InfoOutlined fontSize="small" />}
                validate={combineValidators(
                  minLength(trans('COMMON.FORM.TOO_SHORT', { length: 3 }), 3),
                  maxLength(trans('COMMON.FORM.TOO_LONG', { length: 255 }), 255),
                )}
                hasIcon
                resettable
                tooltipText={trans('INSTITUTIONS.BROWSER.TOOLTIPS.SEARCH')}
              />
              <Autocomplete
                name="id"
                placeholder={trans('INSTITUTIONS.FILTERS.ALL')}
                labelText={trans('INSTITUTIONS.FILTERS.INSTITUTION.LABEL')}
                icon={<InfoOutlined fontSize="small" />}
                options={toOptions(institutionsDictionary.list)}
              />
              <SelectBox
                name="synchronization"
                displayEmpty
                resettable
                placeholderText={trans('INSTITUTIONS.FILTERS.ALL')}
                labelText={trans('INSTITUTIONS.FILTERS.SYNCHRONIZATION.LABEL')}
                options={[
                  { value: 'UNASSIGNED', label: trans('INSTITUTIONS.FILTERS.SYNCHRONIZATION.VALUES.UNASSIGNED') },
                  { value: 'SYNERGIA', label: trans('INSTITUTIONS.FILTERS.SYNCHRONIZATION.VALUES.SYNERGIA') },
                  { value: 'SDDS', label: trans('INSTITUTIONS.FILTERS.SYNCHRONIZATION.VALUES.SDDS') },
                ]}
              />
              <SelectBox
                name="dataSharing"
                displayEmpty
                resettable
                placeholderText={trans('INSTITUTIONS.FILTERS.ALL')}
                labelText={trans('INSTITUTIONS.FILTERS.DATA-SHARING.LABEL')}
                options={[
                  { value: DataSharingTypes.AGREEMENT, label: trans('INSTITUTIONS.FILTERS.DATA-SHARING.VALUES.YES') },
                  { value: DataSharingTypes.DISAGREEMENT, label: trans('INSTITUTIONS.FILTERS.DATA-SHARING.VALUES.NO') },
                ]}
              />
              <SelectBox
                name="accreditation"
                displayEmpty
                resettable
                placeholderText={trans('INSTITUTIONS.FILTERS.ALL')}
                labelText={trans('INSTITUTIONS.FILTERS.ACCREDITATION.LABEL')}
                options={toOptions(dictionaries.accreditation)}
              />
              <SelectBox
                name="educationStage"
                displayEmpty
                resettable
                placeholderText={trans('INSTITUTIONS.FILTERS.ALL')}
                labelText={trans('INSTITUTIONS.FILTERS.EDUCATION-LEVEL.LABEL')}
                options={toOptions(dictionaries.educationStage)}
              />
              <SelectBox
                name="studentRegister"
                displayEmpty
                resettable
                placeholderText={trans('INSTITUTIONS.FILTERS.ALL')}
                labelText={trans('INSTITUTIONS.FILTERS.STUDENT-REGISTER.LABEL')}
                options={toOptions(schoolYears.list)}
              />
              <FocusTrap handler={() => props.onGridFocus()} />
              <AutoSave debounced={['search']} />
            </ThemeProvider>
          </div>
        )}
      </Form>
    </section>
  );
};

ToolbarComponent.displayName = 'Toolbar';
export const Toolbar = forwardRef(ToolbarComponent);

import { QuestionType } from 'store/polls';
import { LongAnswer } from './LongAnswer';
import { MultipleChoice } from './MultipleChoice';
import { ShortAnswer } from './ShortAnswer';
import { SingleChoice } from './SingleChoice';

export const questionComponentMapping = {
  [QuestionType.TEXT]: ShortAnswer,
  [QuestionType.LONG_TEXT]: LongAnswer,
  [QuestionType.SINGLE_CHOICE]: SingleChoice,
  [QuestionType.MULTIPLE_CHOICE]: MultipleChoice,
};

import { Link } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { push } from 'connected-react-router';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { BreadcrumbsItemProps } from './BreadcrumbsItem.types';

export const BreadcrumbsItem: FC<BreadcrumbsItemProps> = (props) => {
  const dispatch = useDispatch();

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    dispatch(push(props.to));
  };

  const staticBreadcrumb = () => {
    return <Typography color="textPrimary">{props.children}</Typography>;
  };

  const linkingBreadcrumb = () => (
    <Link color="inherit" href={props.to} onClick={handleClick}>
      {props.children}
    </Link>
  );

  return props.static ? staticBreadcrumb() : linkingBreadcrumb();
};

export const BreadcrumbsLastItem: FC<BreadcrumbsItemProps> = (props) => {
  return <Typography color="textPrimary">{props.children}</Typography>;
};

import { muiStyles } from 'utils/design';
import { colors } from 'styles/colors';

export const useStyles = muiStyles({
  grid: {
    flexGrow: 1,
  },
  headerRow: {
    background: `${colors.neutralVeryLight} !important`,
    fontWeight: 'bolder',
    '.ag-theme-material &.ag-row-hover': {
      background: colors.neutralLight,
    },
  },
});

import { IconButton, Theme } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { ThemeProvider } from '@material-ui/styles';
import { AppDispatch } from '@typings/redux';
import { Documents } from 'components/childRegister/Browser/Documents/Documents';
import { ToolbarProps } from 'components/childRegister/Browser/Toolbar/Toolbar.types';
import { ActionButton } from 'components/shared/buttons/ActionButton/ActionButton';
import { Form, SelectBox } from 'components/shared/form';
import { AutoSave } from 'components/shared/form/AutoSave/AutoSave';
import { FocusTrap } from 'components/shared/form/FocusTrap/FocusTrap';
import { SearchInput } from 'components/shared/form/SearchInput/SearchInput';
import { useTranslator } from 'components/shared/hooks/useTranslator/useTranslator';
import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon';
import { ROUTE_CHILD_REGISTER_ADD } from 'config/routes';
import { push } from 'connected-react-router';
import React, { forwardRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AddressInUse,
  alertTypesSelector,
  birthYearsSelector,
  ChildRegisterFilters,
  childRegisterSelector,
} from 'store/childRegister';
import { setChildRegisterFilters } from 'store/childRegister/childRegister.actions';
import { filtersThemeFactory } from 'styles/themes/filtersTheme';
import { toOptions } from 'utils/api';
import { minLength } from 'utils/formValidators/minLength/minLength';
import { useStyles } from './Toolbar.styles';
import { ImportContainer } from 'components/childRegister/Import/ImportContainer';
import { compact } from 'lodash';
import Access from 'components/shared/access/access';
import { OfficialPermissionLevel, OfficialResourceGroup } from 'store/officials';

const ToolbarComponent = (props: ToolbarProps, ref) => {
  const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const trans = useTranslator();
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [documentsModalOpen, setDocumentsModalOpen] = useState(false);

  const { filters } = useSelector(childRegisterSelector);
  const { list: birthYears } = useSelector(birthYearsSelector);
  const { list: alerts } = useSelector(alertTypesSelector);

  const valuesChanged = (values: ChildRegisterFilters) => {
    dispatch(setChildRegisterFilters(values));
  };

  const addNewUnit = () => {
    dispatch(push(ROUTE_CHILD_REGISTER_ADD));
  };

  const alertsOptions = toOptions(alerts).map(({ value, label }) => {
    return { value, label: trans(label) };
  });

  return (
    <section ref={ref} className={classes.toolbar}>
      <Form<ChildRegisterFilters>
        initialValues={filters}
        enableReinitialize={true}
        onSubmit={valuesChanged}
        className={classes.form}
      >
        {({ setFieldValue }) => (
          <div className={classes.filters}>
            <ThemeProvider theme={(parentTheme: Theme) => filtersThemeFactory(parentTheme)}>
              <SearchInput
                name="search"
                labelText={trans('CHILD_REGISTER.FILTERS.SEARCH.LABEL')}
                icon={<InfoOutlined fontSize="small" />}
                validate={minLength(trans('COMMON.FORM.TOO_SHORT', { length: 3 }), 3)}
                hasIcon
                resettable
                tooltipText={trans('CHILD_REGISTER.BROWSER.TOOLTIPS.SEARCH')}
              />
              <SelectBox
                name="majority"
                displayEmpty
                resettable
                placeholderText={trans('CHILD_REGISTER.FILTERS.ALL')}
                labelText={trans('CHILD_REGISTER.FILTERS.MAJORITY.LABEL')}
                options={[
                  { value: 'minor', label: trans('CHILD_REGISTER.FILTERS.MAJORITY.VALUES.MINOR') },
                  { value: 'adult', label: trans('CHILD_REGISTER.FILTERS.MAJORITY.VALUES.ADULT') },
                ]}
                onChange={() => setFieldValue('birth_year', null)}
              />
              <SelectBox
                name="birthYear"
                displayEmpty
                resettable
                placeholderText={trans('CHILD_REGISTER.FILTERS.ALL')}
                labelText={trans('CHILD_REGISTER.FILTERS.BIRTH_YEAR.LABEL')}
                options={toOptions(birthYears)}
              />
              <SelectBox
                name="sex"
                displayEmpty
                resettable
                placeholderText={trans('CHILD_REGISTER.FILTERS.ALL')}
                labelText={trans('CHILD_REGISTER.FILTERS.SEX.LABEL')}
                options={[
                  { value: 'male', label: trans('CHILD_REGISTER.FILTERS.SEX.VALUES.MALE') },
                  { value: 'female', label: trans('CHILD_REGISTER.FILTERS.SEX.VALUES.FEMALE') },
                ]}
              />
              <SelectBox
                name="alert"
                displayEmpty
                resettable
                placeholderText={trans('CHILD_REGISTER.FILTERS.ALL')}
                labelText={trans('CHILD_REGISTER.FILTERS.ALERT.LABEL')}
                options={alertsOptions}
              />
              <SelectBox
                name="isCatchmentChild"
                displayEmpty
                resettable
                placeholderText={trans('CHILD_REGISTER.FILTERS.ALL')}
                labelText={trans('CHILD_REGISTER.FILTERS.CATCHMENT_CHILD.LABEL')}
                options={[
                  {
                    value: 'yes',
                    label: trans('CHILD_REGISTER.FILTERS.CATCHMENT_CHILD.VALUES.YES'),
                  },
                  {
                    value: 'no',
                    label: trans('CHILD_REGISTER.FILTERS.CATCHMENT_CHILD.VALUES.NO'),
                  },
                ]}
              />
              <SelectBox
                name="usedAddress"
                displayEmpty
                resettable
                placeholderText={trans('CHILD_REGISTER.FILTERS.ALL')}
                labelText={trans('CHILD_REGISTER.FILTERS.USED_ADDRESS.LABEL')}
                options={[
                  {
                    value: AddressInUse.RESIDENCE,
                    label: trans('CHILD_REGISTER.FILTERS.USED_ADDRESS.VALUES.RESIDENCE'),
                  },
                  {
                    value: AddressInUse.PERMANENT_RESIDENCE,
                    label: trans('CHILD_REGISTER.FILTERS.USED_ADDRESS.VALUES.PERMANENT_RESIDENCE'),
                  },
                  {
                    value: AddressInUse.NONE,
                    label: trans('CHILD_REGISTER.FILTERS.USED_ADDRESS.VALUES.NONE'),
                  },
                ]}
              />
              <FocusTrap handler={() => props.onGridFocus()} />
              <AutoSave debounced={['search']} />
            </ThemeProvider>
          </div>
        )}
      </Form>
      <ImportContainer open={importModalOpen} onClose={() => setImportModalOpen(false)} />
      <Documents open={documentsModalOpen} onClose={() => setDocumentsModalOpen(false)} filters={filters} />
      <Access resourcesGroup={OfficialResourceGroup.childRegister} accessType={OfficialPermissionLevel.fullAccess}>
        <ActionButton
          component={IconButton}
          disableCaret={true}
          variant="contained"
          color="primary"
          label={() => <FontAwesomeIcon name={'ellipsis-v'} className={'mx-2'} />}
          data-testid="KEDActionButton"
          actionList={compact([
            { action: () => setImportModalOpen(true), label: 'CHILD_REGISTER.BROWSER.IMPORT_REGISTER', icon: 'upload' },
            { action: addNewUnit, label: 'CHILD_REGISTER.BROWSER.ADD_ENTRY', icon: 'plus' },
            {
              action: () => setDocumentsModalOpen(true),
              label: 'CHILD_REGISTER.BROWSER.SEND_DOCUMENT',
              icon: 'envelope-open-text',
            },
          ])}
        />
      </Access>
    </section>
  );
};

ToolbarComponent.displayName = 'Toolbar';
export const Toolbar = forwardRef(ToolbarComponent);

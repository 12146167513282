import React from 'react';
import { FormControl, FormHelperText, Input, InputAdornment, InputLabel, Tooltip } from '@material-ui/core';
import { OptionType } from 'components/shared/form/Autocomplete/Autocomplete.types';
import { InputComponent } from 'components/shared/form/Autocomplete/components/Input';
import { ControlProps } from 'react-select';
import { get } from 'lodash';
import classNames from 'classnames';
import { InputBaseComponentProps } from '@material-ui/core/InputBase';

export function Control(props: ControlProps<OptionType, boolean>) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, controlParams },
  } = props;

  const {
    labelText,
    form,
    field,
    tooltipText,
    icon,
    hasIcon,
    InputLabelProps,
    disabled,
    required,
    name,
    helpMessage,
    error,
    errorField,
  } = controlParams;

  const isValid = !!(get(form.errors, field.name) && get(form.touched, field.name));
  const isError = error || isValid;
  return (
    <>
      <FormControl
        error={isError}
        classes={{ root: classes.formControl }}
        disabled={disabled}
        required={required}
        className={classNames({ hasValidationError: isError })}
      >
        {labelText && (
          <InputLabel classes={{ root: classes.label }} {...InputLabelProps} error={false}>
            {labelText}
          </InputLabel>
        )}
        <Input
          value={field.value || props.selectProps.inputValue}
          name={name}
          inputComponent={InputComponent as React.ElementType<InputBaseComponentProps>}
          endAdornment={
            hasIcon && (
              <InputAdornment position="end">
                <Tooltip title={tooltipText} placement="right">
                  {icon}
                </Tooltip>
              </InputAdornment>
            )
          }
          inputProps={{
            className: classes.input,
            ref: innerRef,
            children: children,
            'data-testid': `autocomplete-${field.name}`,
            ...innerProps,
          }}
        />
        {isError ? (
          <FormHelperText error data-testid={`autocomplete-${field.name}-control`}>
            {get(form.errors, errorField || field.name)}
          </FormHelperText>
        ) : (
          <FormHelperText error className={classes.helperText} data-testid={`autocomplete-${field.name}-control`}>
            {helpMessage}
          </FormHelperText>
        )}
      </FormControl>
    </>
  );
}

import { Page } from 'components/layout/panel/Page/Page';
import { useTranslator } from 'components/shared/hooks';
import { Breadcrumb } from 'components/shared/navigation/Breadcrumbs/Breadcrumb';
import React, { FC, Fragment } from 'react';
import { OtherCommuneContainer } from 'components/reports/OtherCommune/OtherCommuneContainer';

export const OtherCommuneView: FC = () => {
  const trans = useTranslator();

  return (
    <Fragment>
      <Breadcrumb label={trans('BREADCRUMB.REPORTS.OTHER_COMMUNE')} static />

      <Page title={trans('REPORTS.OTHER_COMMUNE.TITLE')}>
        <OtherCommuneContainer />
      </Page>
    </Fragment>
  );
};

import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@typings/redux';
import { YearsRegisterContainer } from 'components/schoolDistricts/AssignmentPreview/YearsRegister/YearsRegisterContainer';
import { Summary, SummaryItem } from 'components/shared/data/Summary/Summary';
import { Tooltip } from 'components/shared/messages/Tooltip/Tooltip';
import { AssignmentPreviewProps } from 'components/schoolDistricts/AssignmentPreview/AssignmentPreview.types';
import { TextLoader } from 'components/shared/loaders/TextLoader/TextLoader';
import { useTranslator } from 'components/shared/hooks';
import { TabPanel } from 'components/shared/surfaces/TabPanel/TabPanel';
import { useStyles } from 'components/schoolDistricts/AssignmentPreview/AssignmentPreview.styles';
import classNames from 'classnames';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import { Button } from 'components/shared/buttons/Button/Button';
import { SchoolRegister } from 'components/schoolDistricts/AssignmentPreview/SchoolRegister/SchoolRegister';
import { AssignmentStatus, deleteCatchmentAssignments } from 'store/schoolDistricts';

export const AssignmentPreview: FC<AssignmentPreviewProps> = ({
  isLoading,
  data,
  redirectToChildrenList,
  redirectToChildrenAlertList,
  redirectToDatabaseList,
  downloadChildrenList,
  downloadChildrenAlertList,
  transferChildrenList,
  refreshAssignment,
  saveAssignment,
}) => {
  const trans = useTranslator();
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();

  const renderLoader = () => <TextLoader name="spinner" text={trans('SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.LOADING')} />;

  const defaultTabs = [
    {
      title: trans('SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.SCHOOL_REGISTER.TITLE'),
      key: 'school_register',
      content: (
        <Paper innerSpacing={2} square={true} className={classes.paper}>
          <SchoolRegister
            data={data}
            redirectToChildrenList={redirectToChildrenList}
            redirectToChildrenAlertList={redirectToChildrenAlertList}
            downloadChildrenList={downloadChildrenList}
            downloadChildrenAlertList={downloadChildrenAlertList}
            transferChildrenList={transferChildrenList}
            refreshAssignment={refreshAssignment}
          />
        </Paper>
      ),
    },
    {
      title: trans('SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.YEARS.TITLE'),
      key: 'years',
      content: (
        <Paper innerSpacing={2} square={true} className={classes.paper}>
          <YearsRegisterContainer />
        </Paper>
      ),
    },
  ];

  const hasWarnings = data && data.alerts.length > 0;
  const isDraft = data && data.status === AssignmentStatus.DRAFT;

  const getTooltipTitle = () => {
    if (hasWarnings) {
      return trans('SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.SAVE.TOOLTIP');
    } else {
      return trans('SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.SAVE.STATUS-TOOLTIP');
    }
  };

  const handleBackButton = () => {
    if (data.status === AssignmentStatus.DRAFT) {
      dispatch(deleteCatchmentAssignments(data.id));
    }
    redirectToDatabaseList();
  };

  const renderContent = () => (
    <div className={classNames('container-fluid', classes.container)}>
      <Summary>
        <SummaryItem title={trans('SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.DATABASE')}>{data.assignmentName}</SummaryItem>
      </Summary>
      <TabPanel tabs={defaultTabs} defaultTab="school_register" />
      <div className="row mt-4 mb-2">
        <div className="col">
          <Button onClick={handleBackButton} color="secondary" variant="outlined">
            {trans('COMMON.BACK')}
          </Button>
          {isDraft && (
            <Tooltip title={getTooltipTitle()} disabled={!hasWarnings}>
              <Button
                onClick={saveAssignment}
                color="secondary"
                disabled={hasWarnings}
                variant="contained"
                className="mx-3"
              >
                {trans('SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.SAVE.BUTTON')}
              </Button>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
  return isLoading || !data ? renderLoader() : renderContent();
};

import { Page } from '@typings/common';
import { Loose } from '@typings/typescript';
import { AgGridReactProps } from 'ag-grid-react';
import { HeaderRenderers } from 'components/shared/data/DataGrid/renderers/header';
import { Formatter } from 'components/shared/hooks/useFormatter/useFormatter.types';
import { Translator } from 'context/locale/LocaleContext/LocaleContext.types';
import { FunctionComponent, PropsWithChildren } from 'react';
import { CellRenderers } from 'components/shared/data/DataGrid/renderers/cell';
import { statusPanels } from 'components/shared/data/DataGrid/StatusPanel';
import {
  ColDef,
  ColSpanParams,
  IServerSideGetRowsParams,
  ITooltipParams,
  RowNode,
  GridApi,
  FirstDataRenderedEvent,
} from 'ag-grid-community';

export interface AgGridReactFixedProp extends AgGridReactProps {
  reactNext: boolean;
  loadingOverlayComponentParams: {
    loadingMessage: string;
  };
  noRowsOverlayComponentParams: {
    noResultsMessage: string;
  };
}

export interface DataGridApi {
  focusCell: (row: number, column: number) => void;
  fitCellsToContent: () => void;
  agGridApi: any;
  agColumnApi: any;
  resetPagination: () => void;
}

export interface RowClassParams<T> {
  data: T;
  node: any;
  rowIndex: number;
  api: any;
  context: any;
}

export interface Fetcher<T> {
  (page: number | string, params: DataSourceParams<T>);
}

export interface Provider<T> {
  (page: number | string): Page<T>;
}

export interface DataSourceHandler<T> {
  (rows: Page<T>, lastRow: number): void;
}

export interface DataSourceParams<T = any> extends IServerSideGetRowsParams {
  successCallback: DataSourceHandler<T>;
}

export interface PageHandlersState<T> {
  [page: number]: (rows: Page<T>, lastRow: number) => void;
}

export type PageHandlersAction<T> =
  | {
      type: 'add';
      page: string;
      endRow: number;
      handler: DataSourceHandler<T>;
    }
  | {
      type: 'remove';
      page: string;
    }
  | {
      type: 'reset';
    };

export interface PageHandlersReducer<T> {
  (prevState: PageHandlersState<T>, action: PageHandlersAction<T>): any;
}

export interface Aggregator {
  (trans: Translator): (values: (number | string)[]) => string | number;
}

export type TableHeader<T> = GroupingHeader<T> | Column<T>;

export const isGroupingHeader = <T>(column: TableHeader<T>): column is GroupingHeader<T> => {
  return 'children' in column;
};

export interface GroupingHeader<T> {
  name: string | ((trans: Translator, formatter: Formatter) => string);
  children: (Column<T> | GroupingHeader<T>)[];
}

interface CellRendererFunction {
  (params: any, trans: Translator): HTMLElement | string;
}

export interface Column<T> {
  id?: string;
  name: string | ((trans: Translator, formatter: Formatter) => string);
  field: string;
  format?: (data: T, trans: Translator, formatter: Formatter, column: string) => any;
  minWidth?: number;
  cellClass?: string | string[] | ((params: RowClassParams<T>) => string | string[]);
  cellStyle?: Record<string, unknown> | ((params: RowClassParams<T>) => Record<string, unknown>);
  headerClass?: string | string[] | ((params: RowClassParams<T>) => string | string[]);
  columnSpan?: (data: T, params: ColSpanParams) => number;
  headerTooltip?: string;
  cellTooltip?: (data: T, trans: Translator, formatter: Formatter, params: ITooltipParams) => string;
  columnParams?: object;
  renderer?: keyof CellRenderers | string | CellRendererFunction;
  rendererParams?: CellRenderers[keyof CellRenderers] extends Partial<FunctionComponent<infer P>>
    ? Loose<Partial<P>>
    : any;
  headerRenderer?: keyof HeaderRenderers | string;
  headerRendererParams?: HeaderRenderers[keyof HeaderRenderers] extends Partial<FunctionComponent<infer P>>
    ? Loose<Partial<P>>
    : any;
  aggregation?: string | Aggregator;
}

export interface ColumnsConfig<Model, Params = any> {
  (parameters?: Params): TableHeader<Model>[];
}

export interface GroupingColumnConfig<Model, Params = any> {
  (parameters?: Params): Partial<Column<Model>>;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface DefaultColumnConfig<Model, Params = any> {
  (parameters?: Params): ColDef;
}

export interface StatusPanelDefinition {
  statusPanel: keyof typeof statusPanels;
  statusPanelParams?: object;
  align?: string;
}

interface RowHeightParams {
  data: any;
  node: RowNode;
  api: GridApi;
  // The context as provided on `gridOptions.context`
  context: any;
}

export interface DataGridOwnProps<T extends object, D extends object = any> {
  className?: string;

  data?: T[];
  pinnedBottomData?: T[];
  onDataProvide?: Provider<T>;
  onDataFetch?: Fetcher<T>;
  limit?: number;
  total?: number;
  fetchedItemsCount?: number;

  columns: TableHeader<T>[];
  defaultColumn?: ColDef;
  masterDetail?: boolean;
  detailsColumns?: TableHeader<D>[];
  detailsMapping?: (row: T) => D[];
  detailCellRenderer?: string;
  detailRowHeight?: number;
  getRowHeight?: (params: RowHeightParams) => number | undefined | null;
  isRowMaster?: (row: T) => boolean;

  /**
   * Eg.: (data) => data.parent ? [data.parent.type, data.type] : [data.type]
   * Example outputs: ['Vehicle'], ['Vehicle', 'Car'], ['Vehicle', 'Car', 'SUV']
   */
  treeHierarchy?: (data: T) => string[];
  groupingColumn?: Partial<Column<T>>;
  defaultExpand?: number;

  contextMenu?: boolean;
  alwaysExpanded?: true;
  rowClassName?: string;
  /**
   * Eg.: (params) => ['row-colored', `row-colored--${params.data.status}`]
   */
  getRowClass?: (params: RowClassParams<T>) => string | string[];
  /**
   * Eg.: { 'row-done': (params) => params.data.status === 'done' }
   */
  rowClassRules?: { [cssClassName: string]: (params: RowClassParams<T>) => boolean };
  /**
   * Eg.: [{ statusPanel: 'count', align: 'left' }, { statusPanel: 'deleteSelected', align: 'left' }]
   */
  statusPanels?: StatusPanelDefinition[];
  rowSelection?: 'single' | 'multiple';
  isLoading?: boolean;
  loadingMessage?: string;
  noResultsMessage?: string;
  onFocusBeforeGrid?: () => void;
  onFocusAfterGrid?: () => void;
  onGridReady?: (api: DataGridApi) => void;
  onFirstDataRendered?: (event: FirstDataRenderedEvent) => void;
  headerHeight?: number;
  disableAutoSize?: boolean;
  onSelectionChange?: (rows: T[], nodes: RowNode[], api) => void;
  onDetailsSelectionChange?: (rows: D[], nodes: RowNode[], api) => void;
  onDetailsCombinedSelectionChange?: (rows: D[], api) => void;
  suppressScrollOnNewData?: boolean;
  suppressAggFuncInHeader?: boolean;
  suppressColumnVirtualisation?: boolean;
  groupMultiAutoColumn?: boolean;
  groupSuppressAutoColumn?: boolean;
  context?: any;
  getRowNodeId?: (row: T | D) => string;
  isRowSelectable?: (row: T | null, node) => boolean;
  isDetailsRowSelectable?: (row: D | null, node) => boolean;
  domLayout?: 'normal' | 'print' | 'autoHeight';
}

export type DataGridProps<T extends object, D extends object = any> = PropsWithChildren<DataGridOwnProps<T, D>>;

export interface DataGridStyleProps {
  hasStatusPanel: boolean;
  visible: boolean;
  detailsSpacing: number;
  domLayout: 'normal' | 'print' | 'autoHeight';
}

import withStyles from '@material-ui/core/styles/withStyles';
import { styles } from 'components/shared/data/DataGrid/Tooltip/Tooltip.styles';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import React, { Component } from 'react';
import { decode } from 'html-entities';

class TooltipComponent extends Component<any> {
  public componentDidMount() {
    this.props.reactContainer.className = this.props.classes.container;
  }

  public render() {
    const { classes } = this.props;

    return (
      <Paper innerSpacing={2} className={classes.paper} shadow={4}>
        {decode(this.props.value.toString())}
      </Paper>
    );
  }
}

export const Tooltip = withStyles(styles)(TooltipComponent);

export enum OfficialResourceGroup {
  messages = 'messages',
  polls = 'polls',
  statistics = 'statistics',
  finances = 'finances',
  configurations = 'configuration',
  childRegister = 'child_register_system',
  activitiesRegister = 'activities_register',
  onlyAdmin = 'only_admin', // Special group only for administrator,
  institutionalCoreGrant = 'institutional_core_grant',
}

export enum OfficialPermissionLevel {
  fullAccess = 'full_access',
  noAccess = 'no_access',
  readOnly = 'read_only',
}

export interface OfficialPermissions {
  resourcesGroup: OfficialResourceGroup;
  accessType: OfficialPermissionLevel;
}
export interface Official {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: OfficialAccountType;
  permissions: OfficialPermissions[];
}

export interface BrowserColumnParams {
  delete: (data: Official) => void;
  edit: (data: Official) => void;
  resetPassword: (data: Official) => void;
  usePermanentResidence?: boolean;
  officialId: string;
  institutionalCoreGrantEnabled?: boolean;
}

export enum InstanceAddressType {
  PERMANENT_RESIDENCE = 'permanent',
  RESIDENCE = 'residence',
  RESIDENCE_FIRST = 'residence_first',
  PERMANENT_RESIDENCE_FIRST = 'permanent_first',
}

export enum OfficialAccountType {
  ADMIN = 'role_admin',
  OFFICIAL = 'role_user',
  APPLICANT = 'icg_admin',
}

export interface OfficialForm {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  permissions: object;
  units: string[];
}

export interface OfficialPostModel {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  permissions: OfficialPermissions[];
}

export interface ResetPasswordResponse {
  login: string;
  startPassword: string;
}

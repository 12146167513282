import { ApplicantPostModel } from './applicants.types';

export interface ApplicantsState {
  details: {
    official: ApplicantPostModel;
    loading: boolean;
    error: any;
  };
}

export const defaultState: ApplicantsState = {
  details: {
    official: null,
    loading: false,
    error: null,
  },
};

import { DataGrid } from 'components/shared/data/DataGrid/DataGrid';
import {
  defaultColumn,
  statisticDetailsColumns,
  statisticDetailsGroupingColumn,
} from 'components/reports/SchoolStatDetails/columns';
import { StatDetailsRow } from 'store/reports/reports.types';
import { useStyles } from 'components/reports/SchoolStatDetails/SchoolStatDetails.styles';
import { DataGridApi } from 'components/shared/data/DataGrid/DataGrid.types';
import React, { useMemo } from 'react';
import { formatSpecialValues, getProcessedTableData } from 'components/reports/SchoolStatDetails/helpers';
import { StatDetailsTable } from 'store/reports/reports.types';
import { useTranslator } from 'components/shared/hooks';
import { Paper } from 'components/shared/surfaces/Paper/Paper';

interface StatGridProps {
  isLoading: boolean;
  table: StatDetailsTable | null;
  setGridApi: React.Dispatch<any>;
}

export const StatGrid = ({ isLoading, table, setGridApi }: StatGridProps) => {
  const classes = useStyles();
  const trans = useTranslator();

  const onGridReady = ({ agGridApi }: DataGridApi) => {
    setGridApi(agGridApi);
  };

  const { gridItems, groupRows } = useMemo(() => {
    const { leafs, headers } = getProcessedTableData(table.data, table.columns);

    return {
      gridItems: formatSpecialValues(leafs, trans),
      groupRows: formatSpecialValues(headers, trans),
    };
  }, [table.data, table.columns, trans]);

  return (
    <Paper innerSpacing={2} square className={classes.paper}>
      <DataGrid<StatDetailsRow>
        columns={statisticDetailsColumns({ columns: table.columns, trans })}
        data={gridItems}
        groupingColumn={statisticDetailsGroupingColumn()}
        defaultColumn={defaultColumn()}
        onGridReady={onGridReady}
        suppressAggFuncInHeader={true}
        isLoading={isLoading}
        groupMultiAutoColumn={true}
        context={{ rowGroupValues: groupRows }}
        groupSuppressAutoColumn={true}
      />
      <div className="pt-3 text-muted text-right">{trans('REPORTS.SCHOOL_STAT_DETAILS.FIXED_VALUES_EXPLANATION')}</div>
    </Paper>
  );
};

export interface TextSize {
  width: number;
  height: number;
}

export const measureText = (text: string | number, maxWidth = 200): TextSize => {
  const tester = document.createElement('div');
  tester.style.visibility = 'hidden';
  tester.style.display = 'inline-block';
  tester.style.position = 'absolute';
  tester.style.top = '-99999px';
  tester.style.left = '-99999px';
  tester.style.maxWidth = `${maxWidth}px`;
  document.body.append(tester);

  tester.innerHTML = text.toString();
  const height = tester.clientHeight + 1;
  const width = tester.clientWidth + 1;

  document.body.removeChild(tester);

  return { height, width };
};

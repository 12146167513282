import { Page } from 'components/layout/panel/Page/Page';
import { useTranslator } from 'components/shared/hooks';
import { Breadcrumb } from 'components/shared/navigation/Breadcrumbs/Breadcrumb';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import React, { FC, Fragment } from 'react';
import { useStyles } from 'views/Panel/Reports/SchoolStat/SchoolStat.styles';
import { SchoolStatContainer } from 'components/reports/SchoolStat/SchoolStatContainer';

export const SchoolStatView: FC = () => {
  const trans = useTranslator();
  const classes = useStyles();

  return (
    <Fragment>
      <Breadcrumb label={trans('BREADCRUMB.REPORTS.SCHOOL_STAT')} static />

      <Page title={trans('REPORTS.SCHOOL_STAT.TITLE')}>
        <Paper innerSpacing={2} outerSpacing={[0, 2, 2]} className={classes.paper}>
          <SchoolStatContainer />
        </Paper>
      </Page>
    </Fragment>
  );
};

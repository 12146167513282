import { InvalidRecordsProps } from 'components/shared/dialogs/DocumentModal/DocumentModal.types';
import { useTranslator } from 'components/shared/hooks';
import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon';
import { Infobar, InfobarWrapper } from 'components/shared/messages/Infobar';
import { InfobarCheckBox } from 'components/shared/messages/Infobar/InfobarCheckBox';
import React, { FC } from 'react';
import { useStyles } from './DocumentModal.styles';
import classNames from 'classnames';

export const InvalidRecords: FC<InvalidRecordsProps> = (props) => {
  const trans = useTranslator();
  const classes = useStyles();

  if (props.count === 0) {
    return null;
  }

  return (
    <InfobarWrapper>
      <Infobar>
        <span>
          <FontAwesomeIcon name={'exclamation-triangle'} className={classNames('mr-2', classes.icon)} />
          {trans('TEMPLATES.MODAL.ALERT.INVALID_RECORDS', { count: props.count })}
        </span>
        <InfobarCheckBox
          name={'showInvalidOnly'}
          labelText={trans('TEMPLATES.MODAL.ALERT.SHOW')}
          onChange={(event, checked) => props.onUpdate(checked)}
        />
      </Infobar>
    </InfobarWrapper>
  );
};

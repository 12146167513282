import { formatSpecialValues } from 'components/reports/SchoolStatDetails/helpers';
import React, { useMemo } from 'react';
import { TextLoader } from 'components/shared/loaders/TextLoader/TextLoader';
import { useTranslator } from 'components/shared/hooks';
import { BarChartContainerProps } from './BarChart.types';
import { BarChart } from './BarChart';
import { convertToChartTree } from './BarChart.helpers';

export const BarChartContainer = ({ isLoading, table, statName }: BarChartContainerProps) => {
  const trans = useTranslator();

  const chartData = useMemo(() => {
    const aggregatedColumn = table.columns.filter((column) => column.aggregationField);
    const valueColumn = aggregatedColumn[aggregatedColumn.length - 1];

    const allFieldsNames = table.columns
      .filter((column) => column.field !== 'typ-jednostki')
      .map((column) => column.field);

    const chartTree = convertToChartTree(table.data, valueColumn, allFieldsNames);
    return formatSpecialValues(chartTree, trans);
  }, [table, trans]);

  const hasBlankLevel = chartData.data.some((level) => level.isBlank);

  if (isLoading) return <TextLoader text={trans('COMMON.LOADING_DATA')} />;

  return (
    <BarChart isLoading={isLoading} data={hasBlankLevel ? chartData.children[0] : chartData} statName={statName} />
  );
};

import React, { useCallback, FC } from 'react';
import { useDispatch } from 'react-redux';
import { FormikHelpers } from 'formik';
import format from 'date-fns/format';
import formatISO from 'date-fns/formatISO';
import parse from 'date-fns/parse';
import { Dialog, DialogTitle } from '@material-ui/core';
import { Editor } from 'components/childRegister/Editor/ObligationActions/Editor/Editor';
import { useTranslator, useFormatter, useNotification } from 'components/shared/hooks';
import { ObligationActionForm } from 'components/childRegister/Editor/ObligationActions/ObligationActions.types';
import { postObligationAction } from 'store/childRegister';

export const initialValues: ObligationActionForm = {
  date: format(new Date(), 'yyyy-MM-dd'),
  time: '',
  actionDescription: '',
  actionTitle: '',
  actionType: 'manual',
};

export const AddObligationActions: FC<{ open: boolean; onClose: () => void; childId: string | null }> = (props) => {
  const trans = useTranslator();
  const { formatApiErrors } = useFormatter();
  const { success } = useNotification();
  const dispatch: (...params: any) => Promise<any> = useDispatch();
  const { open, childId, onClose } = props;
  const handleSubmit = useCallback(
    (values: ObligationActionForm, formik: FormikHelpers<ObligationActionForm>) => {
      const date = parse(values.date, 'yyyy-MM-dd', new Date());
      const actionAt = formatISO(parse(values.time, 'HH:mm', date));
      if (childId) {
        return dispatch(postObligationAction({ ...values, actionAt }, childId)).then((result) => {
          if (result.error) {
            if (result.payload.response.errors) {
              formik.setErrors(formatApiErrors(result.payload.response.errors));
            }
            formik.setSubmitting(false);
          } else {
            success('CHILD_REGISTER.ADD_OBLIGATION_ACTIONS.SUCCESS');
            onClose();
          }
        });
      }
    },
    [dispatch, childId, formatApiErrors, onClose, success],
  );

  return (
    <Dialog open={open}>
      <DialogTitle>{trans('CHILD_REGISTER.ADD_OBLIGATION_ACTIONS.MODAL.TITLE')}</DialogTitle>
      <Editor initialValues={initialValues} onSubmit={handleSubmit} onClose={onClose} />
    </Dialog>
  );
};

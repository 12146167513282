import { muiStyles } from 'utils/design';

export const useStyles = muiStyles((theme) => ({
  townSummary: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tooltip: {
    wordBreak: 'break-all',
  },
  clearAllIcon: {
    cursor: 'pointer',
    fontSize: '0.8em',
    color: theme.palette.grey['600'],
    marginRight: '4px',
    lineHeight: '30px',
  },
}));

import { useEffect } from 'react';
import { Events } from 'ag-grid-community';

export const useGridEvent = (api, event: Events, listener: (event) => void) => {
  useEffect(() => {
    if (!api) {
      return;
    }
    api.addEventListener(event, listener);
    return () => api.removeEventListener(event, listener);
  }, [api, event, listener]);
};

export const useDetailsGridsEvent = (api, event: Events, listener: (event) => void, deps: any[] = []) => {
  useEffect(() => {
    if (!api) {
      return;
    }

    api.forEachDetailGridInfo((gridDetailsInfo) => {
      gridDetailsInfo.api.addEventListener(event as string, listener);
    });

    return () => {
      api.forEachDetailGridInfo((gridDetailsInfo) => {
        gridDetailsInfo.api.removeEventListener(event as string, listener);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, event, listener, ...deps]);
};

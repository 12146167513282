export enum TemplatesActionType {
  FETCH_TEMPLATES_LIST_REQUEST = 'templates/FETCH_TEMPLATES_LIST_REQUEST',
  FETCH_TEMPLATES_LIST_SUCCESS = 'templates/FETCH_TEMPLATES_LIST_SUCCESS',
  FETCH_TEMPLATES_LIST_FAILURE = 'templates/FETCH_TEMPLATES_LIST_FAILURE',

  FETCH_DOCUMENTS_PREVIEW_REQUEST = 'templates/FETCH_DOCUMENTS_PREVIEW_REQUEST',
  FETCH_DOCUMENTS_PREVIEW_SUCCESS = 'templates/FETCH_DOCUMENTS_PREVIEW_SUCCESS',
  FETCH_DOCUMENTS_PREVIEW_FAILURE = 'templates/FETCH_DOCUMENTS_PREVIEW_FAILURE',
  CLEAR_DOCUMENTS_PREVIEW_DATA = 'templates/CLEAR_DOCUMENTS_PREVIEW_DATA',

  CREATE_DOCUMENTS_REQUEST = 'templates/CREATE_DOCUMENTS_REQUEST',
  CREATE_DOCUMENTS_SUCCESS = 'templates/CREATE_DOCUMENTS_SUCCESS',
  CREATE_DOCUMENTS_FAILURE = 'templates/CREATE_DOCUMENTS_FAILURE',

  FETCH_DOCUMENTS_STATUS_REQUEST = 'templates/FETCH_DOCUMENTS_STATUS_REQUEST',
  FETCH_DOCUMENTS_STATUS_SUCCESS = 'templates/FETCH_DOCUMENTS_STATUS_SUCCESS',
  FETCH_DOCUMENTS_STATUS_FAILURE = 'templates/FETCH_DOCUMENTS_STATUS_FAILURE',

  CLEAR_DOCUMENT_REQUEST = 'templates/CLEAR_DOCUMENT_REQUEST',
}

import React from 'react';
import { VictoryLabelProps } from 'victory';

export interface WrapperLabelProps extends VictoryLabelProps {
  text?: string;
  height: number;
  width: number;
}

export const WrappedLabel = (props: WrapperLabelProps) => {
  const posX = props.x - props.width / 2;
  const posY = props.y - props.height / 2;

  return (
    <foreignObject x={posX} y={posY} width={props.width} height={props.height}>
      {props.text}
    </foreignObject>
  );
};

import { Chip } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import classNames from 'classnames';
import { OptionType } from 'components/shared/form/Autocomplete/Autocomplete.types';
import React from 'react';
import { MultiValueProps } from 'react-select';

export function MultiValue(props: MultiValueProps<OptionType>) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

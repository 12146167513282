import { useTranslator } from 'components/shared/hooks';
import React, { useEffect } from 'react';
import { Page } from 'components/layout/panel/Page/Page';
import { Breadcrumb } from 'components/shared/navigation/Breadcrumbs/Breadcrumb';
import { Route, Switch } from 'react-router';
import { RFC } from '@typings/extra-react-router';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch } from '@typings/redux';
import {
  ROUTE_SCHOOL_CATCHMENT_ASSIGNMENT_PREVIEW,
  ROUTE_SCHOOL_CHILDREN_ALERT_LIST,
  ROUTE_SCHOOL_CHILDREN_LIST,
  ROUTE_SCHOOL_DISTRICTS_CATCHMENT_ASSIGNMENT_LIST,
} from 'config/routes';
import { formatRoute } from 'react-router-named-routes';
import { SchoolCatchmentAssignmentPreviewRoute } from 'config/routes.types';
import { AssignmentPreviewContainer } from 'components/schoolDistricts/AssignmentPreview/AssignmentPreviewContainer';
import { ChildrenList } from 'views/Panel/SchoolDistricts/CatchmentAssignmentPreview/ChildrenList/ChildrenList.view';
import { ChildrenAlertList } from 'views/Panel/SchoolDistricts/CatchmentAssignmentPreview/ChildrenAlertList/ChildrenAlertList.view';
import { getCatchmentAssignments, fetchCatchmentAssignments } from 'store/schoolDistricts';
import { Loader } from 'components/shared/loaders/Loader/Loader';
import { Backdrop } from '@material-ui/core';

export const CatchmentAssignmentPreview: RFC<SchoolCatchmentAssignmentPreviewRoute> = (props) => {
  const trans = useTranslator();
  const dispatch: AppDispatch = useDispatch();
  const id = props.match.params.id;
  const { items, loading } = useSelector(getCatchmentAssignments);

  useEffect(() => {
    if (items.length === 0) {
      dispatch(fetchCatchmentAssignments());
    }
  }, [dispatch, items.length]);

  const chosenAssignment = items.filter((item) => item.id === id);

  const shouldUseWithRulesFilter = chosenAssignment.length > 0 ? chosenAssignment[0].withRules : 1;

  const renderList = () => (
    <Page title={trans('SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.TITLE')}>
      <AssignmentPreviewContainer id={id} withRules={shouldUseWithRulesFilter} />
    </Page>
  );

  return (
    <>
      <Backdrop style={{ color: '#fff', zIndex: 1 }} open={loading}>
        <Loader name={'spinner'} animation={'pulse'} size={'5x'} color={'inherit'} />
      </Backdrop>
      <Breadcrumb
        label={trans('BREADCRUMB.SCHOOL-DISTRICTS.CATCHMENT_ASSIGNMENT_LIST')}
        to={ROUTE_SCHOOL_DISTRICTS_CATCHMENT_ASSIGNMENT_LIST}
      />
      <Breadcrumb
        label={trans('BREADCRUMB.SCHOOL-DISTRICTS.ASSIGMENT_PREVIEW')}
        to={formatRoute(ROUTE_SCHOOL_CATCHMENT_ASSIGNMENT_PREVIEW, { id })}
      />

      <Switch>
        <Route path={ROUTE_SCHOOL_CHILDREN_LIST} component={ChildrenList} />
        <Route path={ROUTE_SCHOOL_CHILDREN_ALERT_LIST} component={ChildrenAlertList} />
        <Route path={ROUTE_SCHOOL_CATCHMENT_ASSIGNMENT_PREVIEW} component={renderList} />
      </Switch>
    </>
  );
};

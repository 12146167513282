import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { AddressRuleForm } from 'components/schoolDistricts/DistrictsBrowser/AddressRuleModal/AddressRuleForm';
import { useStyles } from 'components/schoolDistricts/DistrictsBrowser/AddressRuleModal/AddressRuleModal.styles';
import { AddressRuleModalProps } from 'components/schoolDistricts/DistrictsBrowser/AddressRuleModal/AddressRuleModal.types';
import { useFormatter, useNotification, useTranslator } from 'components/shared/hooks';
import { TextLoader } from 'components/shared/loaders/TextLoader/TextLoader';
import { FormikHelpers } from 'formik';
import React, { FC } from 'react';
import { AddressRuleFormModel } from 'store/schoolDistricts';

export const AddressRuleModal: FC<AddressRuleModalProps> = (props) => {
  const { formatApiErrors } = useFormatter();
  const { success } = useNotification();
  const classes = useStyles();
  const trans = useTranslator();

  const handleSubmit = (values: AddressRuleFormModel, formik: FormikHelpers<AddressRuleFormModel>) => {
    props.onSubmit(values).then((result) => {
      if (result.error) {
        formik.setErrors(formatApiErrors(result.payload.response.errors));
        formik.setSubmitting(false);
      } else {
        success('SCHOOL_DISTRICTS.DISTRICTS_MODAL.SUCCESS_MESSAGE');
        props.onSubmitSuccess();
        props.onClose();
      }
    });
  };

  return (
    <Dialog disableEscapeKeyDown open={props.open} maxWidth={'md'} classes={{ paper: classes.dialog }}>
      {props.isLoading ? (
        <>
          <DialogTitle id="confirm-title">
            {props.values
              ? trans('SCHOOL_DISTRICTS.DISTRICTS_MODAL.TITLE_EDIT')
              : trans('SCHOOL_DISTRICTS.DISTRICTS_MODAL.TITLE_ADD')}
          </DialogTitle>
          <DialogContent className={classes.content}>
            <TextLoader text={trans('COMMON.LOADING_DATA')} height={200} />
          </DialogContent>
        </>
      ) : (
        <AddressRuleForm onSubmit={handleSubmit} onClose={props.onClose} values={props.values} />
      )}
    </Dialog>
  );
};

import React from 'react';
import { SelectBox } from 'components/shared/form';
import { useTranslator } from 'components/shared/hooks';
import { useSelector } from 'react-redux';
import { departmentSettingsSelector, officialSettingsSelector } from 'store/user';
import { CheckBox } from 'components/shared/form';
import { DCSType } from 'store/systemSettings/systemSettings.types';
import { Tooltip } from 'components/shared/messages/Tooltip/Tooltip';

interface DocumentSettingsProps {
  dcsExport: boolean;
}

export const DocumentSettings = ({ dcsExport }: DocumentSettingsProps) => {
  const trans = useTranslator();
  const { documentsCirculationSystem } = useSelector(departmentSettingsSelector);
  const { dcsLogin } = useSelector(officialSettingsSelector);

  return (
    <>
      {(documentsCirculationSystem === DCSType.MDOK || documentsCirculationSystem === DCSType.DUMMY) &&
        (dcsLogin || (documentsCirculationSystem === DCSType.DUMMY && !dcsLogin) ? (
          <CheckBox name={'dcsExport'} labelText={trans('TEMPLATES.MODAL.DCS_EXPORT')} />
        ) : (
          <Tooltip title={trans('TEMPLATES.MODAL.MDOK_LOGIN_EMPTY')} placement="top-start">
            <CheckBox name={'dcsExport'} labelText={trans('TEMPLATES.MODAL.DCS_EXPORT')} disabled={true} />
          </Tooltip>
        ))}
      {!dcsExport && (
        <div className="row mt-3">
          <div className="col-4">
            <SelectBox
              name={'config.mode'}
              labelText={trans('TEMPLATES.MODAL.GENERATION_MODE.LABEL')}
              placeholderText={trans('TEMPLATES.MODAL.GENERATION_MODE.LABEL')}
              options={[
                { value: 'single', label: trans('TEMPLATES.MODAL.GENERATION_MODE.SINGLE_DOCUMENT') },
                { value: 'multi', label: trans('TEMPLATES.MODAL.GENERATION_MODE.MULTIPLE_DOCUMENTS') },
              ]}
            />
          </div>
          <div className="col-4">
            <SelectBox
              name={'config.format'}
              labelText={trans('TEMPLATES.MODAL.FORMAT.LABEL')}
              placeholderText={trans('TEMPLATES.MODAL.FORMAT.LABEL')}
              options={[
                { value: 'pdf', label: trans('TEMPLATES.MODAL.FORMAT.PDF') },
                { value: 'docx', label: trans('TEMPLATES.MODAL.FORMAT.DOCX') },
              ]}
            />
          </div>
        </div>
      )}
    </>
  );
};

import { useStyles } from 'components/auth/SignIn/SignIn.styles';
import { Button } from 'components/shared/buttons/Button/Button';
import { useTranslator } from 'components/shared/hooks';
import { useEnvironment } from 'components/shared/hooks/useEnvironment/useEnvironment';
import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import { Heading } from 'components/shared/typography/Heading/Heading';
import React, { FC } from 'react';
import classNames from 'classnames';

export const SignIn: FC = () => {
  const classes = useStyles();
  const trans = useTranslator();
  const { oAuth } = useEnvironment();

  const signInHandler = () => {
    window.location.href = oAuth.loginUrl;
  };

  return (
    <Paper innerSpacing={[3, 2]} className={classes.box}>
      <span className={classNames('fa-stack fa-5x', classes.icon)}>
        <i className="fas fa-circle fa-stack-2x" />
        <i className="fas fa-user fa-stack-1x fa-inverse" />
      </span>
      <Heading type="h5" className="mb-3 mt-1 text-center font-weight-bold">
        {trans('AUTHORIZATION.TITLE')}
      </Heading>
      <Button
        onClick={signInHandler}
        size={'large'}
        color={'secondary'}
        variant={'contained'}
        data-testid="loginButton"
      >
        <FontAwesomeIcon name={'lock'} className={'mr-2'} />
        {trans('AUTHORIZATION.SIGN_IN')}
      </Button>
      <div className={classes.footer}>{trans('AUTHORIZATION.INSTRUCTION')}</div>
    </Paper>
  );
};

import React from 'react';
import { NavigationListProps } from './NavigationList.types';
import { NavigationItem } from './NavigationItem/NavigationItem';
import { useStyles } from './NavigationList.styles';

export const NavigationList: React.FC<NavigationListProps> = (props) => {
  const useFloatingMenu = props.useFloatingMenu || false;
  const classes = useStyles();

  return (
    <div className={classes.navigationList} data-testid="navigationList">
      {props.menu.map((item, index) => {
        return <NavigationItem key={index} item={item} useFloatingMenu={useFloatingMenu} />;
      })}
    </div>
  );
};

import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@typings/redux';
import { ObligationActions } from 'components/childRegister/Editor/ObligationActions/ObligationActions';
import { obligationActionsSelector } from 'store/childRegister';
import { clearObligationActions, fetchObligationActions } from 'store/childRegister/childRegister.actions';
import { ObligationActionsContainerProps } from 'components/childRegister/Editor/ObligationActions/ObligationActions.types';

export const ObligationActionsContainer: FC<ObligationActionsContainerProps> = ({ id }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { list, loading } = useSelector(obligationActionsSelector);

  useEffect(() => {
    dispatch(clearObligationActions());
    if (id) {
      dispatch(fetchObligationActions(id));
    }
  }, [dispatch, id]);

  return <ObligationActions list={list} loading={loading} childId={id} />;
};

import React from 'react';
import { Route, Switch } from 'react-router';
import { RFC } from '@typings/extra-react-router';
import { ROUTE_INSTITUTION_UNIT_EDIT, ROUTE_INSTITUTION_UNIT_ADD } from 'config/routes';
import { NotFoundView } from 'views/Errors/NotFound.view';
import { UnitAdd } from 'views/Panel/Institutions/Unit/UnitAdd/UnitAdd.view';
import { UnitEdit } from 'views/Panel/Institutions/Unit/UnitEdit/UniteEdit.view';

export const Unit: RFC<{ id?: string }> = () => {
  return (
    <Switch>
      <Route exact path={ROUTE_INSTITUTION_UNIT_ADD} component={UnitAdd} />
      <Route path={ROUTE_INSTITUTION_UNIT_EDIT} component={UnitEdit} />
      <Route component={NotFoundView} />
    </Switch>
  );
};

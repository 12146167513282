import { createAuthMiddleware } from '@tshio/redux-api-auth-middleware';
import contentMiddleware from '@tshio/redux-api-content-middleware';
import createEndpointMiddleware from '@tshio/redux-api-endpoint-middleware';
import paramsMiddleware from '@tshio/redux-api-params-middleware';
import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, Middleware } from 'redux';
import { apiMiddleware } from 'redux-api-middleware';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import { refreshAccessToken, appLogout } from 'store/auth/auth.action';
import { fixParamsMiddleware } from 'store/middlewares/fixParams.middleware';
import { getAppConfig } from 'utils/config/appConfig';
import { history } from './history';
import {
  apiErrorsMiddleware,
  camelCaseMiddleware,
  numberApiCallsMiddleware,
  screenLockMarkerMiddleware,
  screenLockMiddleware,
  takeLatestMiddleware,
  fileDownloadMiddleware,
  normalizeAuthResponse,
} from './middlewares';

const endpointMiddleware = () =>
  createEndpointMiddleware({
    apis: {
      default: {
        apiUrl: getAppConfig('OSIN20_API_URL'),
      },
    },
  });

const authMiddleware = createAuthMiddleware({
  authConfig: {
    header: 'Authorization',
    type: 'Bearer',
  },
  refreshConfig: {
    endpoint: '/token/refresh',
    failedAction: appLogout(),
    actionDefinition: ({ refreshToken, endpoint }) => refreshAccessToken(refreshToken, endpoint),
  },
});

export const sagaMiddleware = createSagaMiddleware();

export const getMiddlewares = () =>
  applyMiddleware(
    fixParamsMiddleware as Middleware,
    numberApiCallsMiddleware as unknown as Middleware,
    screenLockMarkerMiddleware as unknown as Middleware,
    authMiddleware,
    camelCaseMiddleware as unknown as Middleware,
    endpointMiddleware(),
    paramsMiddleware,
    contentMiddleware,
    apiMiddleware,
    normalizeAuthResponse as Middleware,
    takeLatestMiddleware as unknown as Middleware,
    fileDownloadMiddleware as unknown as Middleware,
    apiErrorsMiddleware,
    screenLockMiddleware as unknown as Middleware,
    routerMiddleware(history),
    thunk,
    sagaMiddleware,
  );

import { muiStyles } from 'utils/design';

interface StylesProps {
  hasCaret: boolean;
}

export const useStyles = muiStyles<StylesProps>((theme) => ({
  button: {
    '&[class*="MuiButton-sizeSmall"]': {
      paddingRight: ({ hasCaret }) => (hasCaret ? '5px' : 'inherit'),
    },
  },
  menuList: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
  },
  menuButton: {
    justifyContent: 'start',
    padding: theme.spacing(1, 2),
    width: '100%',
  },
  menuButtonIcon: {
    fontSize: '1rem',
  },
  popper: {
    zIndex: 10000,
  },
  caret: {
    height: '20px',
  },
}));

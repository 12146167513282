import { isRSAA, RSAA, RSAAction } from 'redux-api-middleware';
import { AppAction, AppMiddleware, FSA } from '@typings/redux';
import { isObject } from 'lodash';

interface APIRequestsCounter {
  [actioType: string]: string;
}

export const apiRequestsJournal: APIRequestsCounter = {};

let id = 1;

const getUniqueId = () => {
  return (id++).toString();
};

export const resetUniqueId = () => {
  id = 1;
};

export const createJournalId = (action: string, page = 0) => {
  return `${action}@${page}`;
};

export const resetApiRequestsJournal = () => {
  for (const property in apiRequestsJournal) {
    if (Object.prototype.hasOwnProperty.call(apiRequestsJournal, property)) {
      delete apiRequestsJournal[property];
    }
  }
};

const shouldHandleAction = (action: RSAAction<any>) => {
  return isObject(action[RSAA].types[0].meta) && action[RSAA].types[0].meta.takeLatest;
};

const addTypeMeta = (type: FSA | string, uid: string, request: FSA | string) => ({
  type: isObject(type) ? type.type : type,
  meta: {
    ...(isObject(type) ? type.meta : {}),
    uid,
    request,
    takeLatest: true,
  },
});

const extendAction = ({ [RSAA]: apiCall }: RSAAction<any>, uid: string) => ({
  [RSAA]: {
    ...apiCall,
    types: [
      {
        ...apiCall.types[0],
        meta: {
          ...apiCall.types[0].meta,
          takeLatest: undefined,
          uid,
        },
      },
      addTypeMeta(apiCall.types[1], uid, apiCall.types[0].type),
      addTypeMeta(apiCall.types[2], uid, apiCall.types[0].type),
    ] as [FSA, FSA, FSA],
  },
});

const getPageNumber = (type: FSA) => {
  return type.meta && type.meta.page ? type.meta.page : 0;
};

export const numberApiCallsMiddleware: AppMiddleware = () => (next) => (action: AppAction) => {
  if (isRSAA(action) && shouldHandleAction(action)) {
    const uid = getUniqueId();
    const actionType = action[RSAA].types[0].type;
    const page = getPageNumber(action[RSAA].types[1]);

    const journalId = createJournalId(actionType, page);
    apiRequestsJournal[journalId] = uid;

    return next(extendAction(action, uid));
  }

  return next(action);
};

import { emphasize, Theme } from '@material-ui/core/styles';
import { CSSProperties } from 'react';
import { muiStyles } from 'utils/design';

export const useStyles = muiStyles((theme) => ({
  container: {
    width: 300,
    minWidth: '100%',
    maxWidth: '100%',
  },
  formControl: {
    width: '100%',
    pointerEvents: 'all',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginLeft: 5,
    },
  },
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto',
  },
  valueContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 16,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '100%',
    overflow: 'hidden',
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    bottom: 4,
    '.Mui-focused &': {
      color: theme.palette.grey[500],
    },
  },
  paper: {
    position: 'absolute',
    zIndex: 10,
    marginTop: theme.spacing(-2),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
  option: {
    whiteSpace: 'normal',
  },
  clearIndicator: {
    fontSize: '0.8em',
    color: theme.palette.grey['600'],
    marginRight: '4px',
    lineHeight: '30px',
  },
  helperText: {
    color: 'rgba(0, 0, 0, 0.54) !important',
  },
}));

export const selectStyles = (theme: Theme) => ({
  input: (base: CSSProperties) => ({
    ...base,
    color: theme.palette.text.primary,
    '& input': {
      font: 'inherit',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (base: CSSProperties) => ({
    ...base,
    cursor: 'pointer',
    '& > div': {
      padding: '4px 0 3px',
    },
    '& svg': {
      color: theme.palette.grey['600'],
    },
  }),
});

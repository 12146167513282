import { CheckBox, CheckBoxOutlineBlank, IndeterminateCheckBox } from '@material-ui/icons';
import { SelectBox } from 'components/shared/form';
import { useStyles } from 'components/shared/form/TreeSelectBox/TreeSelectBox.styles';
import { usePrevious } from 'components/shared/hooks';
import { remove, difference } from 'lodash';
import {
  TreeItem,
  TreeItemView,
  TreeSelectBoxComponentProps,
  TreeSelectBoxProps,
  TreeSelectRef,
} from 'components/shared/form/TreeSelectBox/TreeSelectBox.types';
import { connect, FormikProps } from 'formik';
import React, {
  ComponentType,
  forwardRef,
  ReactElement,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import classNames from 'classnames';
import { compact } from 'lodash';

const flattenOptions = (options: TreeItem[], level = 0, parent = null): TreeItemView[] => {
  return options.reduce((list, option) => {
    if (option.children) {
      return [...list, { ...option, level, parent }, ...flattenOptions(option.children, level + 1, option.value)];
    } else {
      return [...list, { ...option, level, parent }];
    }
  }, []);
};

const TreeSelectBoxComponent: ComponentType<TreeSelectBoxComponentProps> = connect(
  ({ forwardedRef, formatValue = null, options, name, placeholderText, ...selectProps }): ReactElement => {
    const [values, setValues] = useState<string[]>(selectProps.formik.initialValues[name]);
    const previousValues = usePrevious(values);
    const flatOptions = useMemo(() => flattenOptions(options), [options]);
    const formik: FormikProps<{}> = selectProps.formik;
    const classes = useStyles({ markLevelZero: true });

    useImperativeHandle(forwardedRef, () => ({
      resetTree: () => setValues([]),
    }));

    const findOptionByValue = (value: string) => {
      return flatOptions.find((item) => item.value === value);
    };

    const isSelected = (option: TreeItemView) => {
      return values.includes(option.value);
    };

    const addItem = (item: string) => {
      !values.includes(item) && values.push(item);
    };

    const removeItem = (item: string) => {
      remove(values, (value) => item === value);
    };

    const hasSelectedChildren = (option: TreeItemView) => {
      if (option.children) {
        return option.children.some((item) => isSelected(item));
      } else {
        return false;
      }
    };

    const hasAllChildrenSelected = (option: TreeItemView) => {
      if (option.children) {
        return option.children.every((item) => isSelected(item));
      } else {
        return true;
      }
    };

    const updateParentOptions = (option: TreeItemView) => {
      if (option && option.parent) {
        const parent = findOptionByValue(option.parent);

        if (isSelected(option)) {
          addItem(parent.value);
        } else if (!hasSelectedChildren(parent)) {
          removeItem(parent.value);
        }

        updateParentOptions(parent);
      }
    };

    const updateChildOptions = (option: TreeItemView) => {
      if (!option || (option && !option.children)) {
        return;
      } else if (isSelected(option)) {
        option.children.forEach((child) => {
          addItem(child.value);
          updateChildOptions(child);
        });
      } else {
        option.children.forEach((child) => {
          removeItem(child.value);
          updateChildOptions(child);
        });
      }
    };

    useEffect(() => {
      const added = difference(values, previousValues);

      if (added.length) {
        const newItem = findOptionByValue(added[0]);
        updateChildOptions(newItem);
        updateParentOptions(newItem);
      }

      const removed = difference(previousValues, values);

      if (removed.length) {
        const deletedItem = findOptionByValue(removed[0]);
        updateChildOptions(deletedItem);
        updateParentOptions(deletedItem);
      }

      formik.setFieldValue(name, values);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    useEffect(() => {
      const availableValues = flatOptions.map((option) => option.value);
      const removedValues = difference(values, availableValues);

      removedValues.forEach((value) => removeItem(value));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flatOptions]);

    const handleChange = (event) => {
      const filtered = event.target.value.filter((e) => e.toString().length);
      if (filtered.length < event.target.value.length) {
        setValues([]);
      } else {
        setValues(event.target.value);
      }

      selectProps.onChange && selectProps.onChange(event);
    };

    const renderItem = (option: TreeItemView) => {
      const fullSelection = isSelected(option) && hasAllChildrenSelected(option);
      const partialSelection = !fullSelection && isSelected(option);
      const noSelection = !fullSelection && !partialSelection;

      return (
        <div className={classNames(classes.item, `level-${option.level}`)}>
          <span className={classes.checkbox}>
            {fullSelection && <CheckBox />}
            {partialSelection && <IndeterminateCheckBox />}
            {noSelection && <CheckBoxOutlineBlank />}
          </span>
          {option.label}
        </div>
      );
    };

    const renderValue = (values = []) => {
      const options = compact<TreeItemView>(values.map((value) => findOptionByValue(value))).filter(
        (option) => !option.hideParentValue,
      );

      if (options.length > 0) {
        const formattedOptions = formatValue
          ? options.map((option) => formatValue(option.label))
          : options.map((option) => option.label);
        return formattedOptions.join(', ');
      } else {
        return [placeholderText];
      }
    };

    return (
      <SelectBox
        {...selectProps}
        name={name}
        placeholderText={placeholderText}
        options={flatOptions}
        itemComponent={renderItem}
        valueComponent={renderValue}
        onChange={handleChange}
        multiple={true}
        itemClassName={classNames(classes.itemWrapper, selectProps.itemClassName)}
        onValueReset={() => {
          setValues([]);
          formik.setFieldValue(name, []);
        }}
        MenuProps={{ variant: 'menu', getContentAnchorEl: null }}
      />
    );
  },
);

export const TreeSelectBox = forwardRef<TreeSelectRef, TreeSelectBoxProps>((props, ref) => {
  return <TreeSelectBoxComponent {...props} forwardedRef={ref} />;
});
TreeSelectBox.displayName = 'TreeSelectBox';

import React from 'react';
import { IconButton as MuiIconButton } from '@material-ui/core';
import { IconButtonProps as MuiIconButtonProps } from '@material-ui/core/IconButton';
import { muiStyles } from 'utils/design';
import classNames from 'classnames';

const useStyles = muiStyles({
  iconButton: {
    fontSize: '0.9rem',
  },
});

const IconButtonComponent = ({ children, ...props }: MuiIconButtonProps, ref) => {
  const classes = useStyles();

  return (
    <MuiIconButton ref={ref} {...props} className={classNames(props.className, classes.iconButton)}>
      {children}
    </MuiIconButton>
  );
};

IconButtonComponent.displayName = 'IconButton';
export const IconButton = React.forwardRef(IconButtonComponent);

import { Action } from 'react-fetching-library';
import { Store } from 'redux';
import { accessTokenSelector } from 'store/auth';
import { AppState } from 'store/store';

export const requestAuth =
  (store: Store<AppState>) =>
  () =>
  async (action: Action): Promise<Action> => {
    const accessToken = accessTokenSelector(store.getState());

    if (accessToken) {
      return {
        ...action,
        headers: {
          ...action.headers,
          Authorization: `Bearer ${accessToken}`,
        },
      };
    } else {
      return action;
    }
  };

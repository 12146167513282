import { isUndefined } from 'lodash';

let appConfigStore: AppConfigStore | undefined;

export interface AppConfigStore {
  OSIN20_API_URL: string;
  OSIN20_AG_GRID_LICENSE: string;
}

function throwFetchError() {
  throw Error('Can\'t fetch or parse application configuration from "/config.json"');
}

export function setAppConfig(config: Partial<AppConfigStore>) {
  appConfigStore = { ...appConfigStore, ...config };
}

export function loadAppConfiguration(filename: string, force = false): Promise<any> {
  if (appConfigStore && !force) {
    return Promise.resolve();
  }

  return fetch(filename)
    .then((response) => (response.ok ? response.json() : throwFetchError()))
    .then(
      (config) => setAppConfig(config),
      () => throwFetchError(),
    );
}

export function getAppConfig(variable: keyof AppConfigStore): string {
  if (!appConfigStore) {
    throw Error(`Application configuration not set!`);
  }

  if (isUndefined(appConfigStore[variable])) {
    throw Error(`Application configuration variable "${variable}" not provided!`);
  }

  return appConfigStore[variable];
}

export interface DateGenerator {
  (): Date;
}

let dateGenerator: DateGenerator = () => new Date();

export const setCurrentDateGenerator = (generator: DateGenerator) => {
  dateGenerator = generator;
};

export const getCurrentDate = () => {
  return dateGenerator();
};

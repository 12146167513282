import {
  DatabaseAction,
  DatabaseContainerProps,
} from 'components/schoolDistricts/DatabaseBrowser/DatabaseBrowser.types';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@typings/redux';
import { push } from 'connected-react-router';
import { makePath } from 'utils/navigation/makePath';
import { ROUTE_SCHOOL_CATCHMENT_ASSIGNMENT_PREVIEW } from 'config/routes';

import {
  createSchoolDistrictsDatabase,
  DatabaseModel,
  DatabaseModelBrowserRow,
  DatabasePostModel,
  DatabaseStatus,
  deleteSchoolDistrictsDatabase,
  editSchoolDistrictsDatabase,
  fetchSchoolDistrictsDatabases,
  getDatabaseListSelector,
  isDatabaseListLoadingSelector,
  setCurrentSchoolDistrictsDatabase,
  setArchiveSchoolDistrictsDatabase,
} from 'store/schoolDistricts';

import { DatabaseBrowser } from './DatabaseBrowser';
import { ROUTE_SCHOOL_DISTRICTS_LIST } from 'config/routes';

export const DatabaseBrowserContainer: FC<DatabaseContainerProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(fetchSchoolDistrictsDatabases());
  }, [dispatch]);

  const addItem = (payload: DatabasePostModel) => dispatch(createSchoolDistrictsDatabase(payload));
  const editItem = (id: string, name: string) => dispatch(editSchoolDistrictsDatabase(id, name));
  const deleteItem = (id: string) => dispatch(deleteSchoolDistrictsDatabase(id));
  const selectItem = (id: string) => dispatch(push(ROUTE_SCHOOL_DISTRICTS_LIST, { databaseId: id }));
  const setCurrentItem = (id: string) => dispatch(setCurrentSchoolDistrictsDatabase(id));
  const setArchiveItem = (id: string) => dispatch(setArchiveSchoolDistrictsDatabase(id));

  const fetchSchoolDistrictDatabase = () => dispatch(fetchSchoolDistrictsDatabases());

  const databasesLoading = useSelector(isDatabaseListLoadingSelector);
  const databases = useSelector(getDatabaseListSelector);

  const addHeaders = (databases: DatabaseModel[]): DatabaseModelBrowserRow[] => {
    const rows: DatabaseModelBrowserRow[] = [...databases];
    const plannedNumber = databases.filter((database) => database.status === DatabaseStatus.PLANNED).length;
    const activeNumber = databases.filter((database) => database.status === DatabaseStatus.ACTIVE).length;
    const offset = plannedNumber > 0 ? 1 : 0;

    if (rows.length > 0 && plannedNumber) {
      rows.unshift({
        title: 'SCHOOL_DISTRICTS.DATABASE.SECTIONS.PLANNED',
      });
    }

    if (rows.length > plannedNumber + offset) {
      rows.splice(plannedNumber + offset, 0, {
        title: 'SCHOOL_DISTRICTS.DATABASE.SECTIONS.ACTUAL',
      });
    }

    if (rows.length > plannedNumber + offset + 2) {
      rows.splice(plannedNumber + offset + activeNumber + 1, 0, {
        title: 'SCHOOL_DISTRICTS.DATABASE.SECTIONS.ARCHIVAL',
      });
    }

    return rows;
  };

  const handleOpenAssignment = (id: string) => {
    return dispatch(push(makePath(ROUTE_SCHOOL_CATCHMENT_ASSIGNMENT_PREVIEW, { id })));
  };

  return (
    <DatabaseBrowser
      databases={databases}
      resultsLoading={databasesLoading}
      addItem={addItem}
      editItem={editItem}
      deleteItem={deleteItem}
      selectItem={selectItem}
      setCurrentItem={setCurrentItem}
      setArchiveItem={setArchiveItem}
      fetchSchoolDistrictDatabase={fetchSchoolDistrictDatabase}
      withOpenAddModal={props.initialAction === DatabaseAction.ADD}
      databasesGrid={addHeaders(databases)}
      onOpenAssignment={handleOpenAssignment}
      selectedDatabaseId={''}
    />
  );
};

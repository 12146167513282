import {
  ROUTE_INSTITUTION_EDIT,
  ROUTE_INSTITUTION_UNIT,
  ROUTE_INSTITUTIONS,
  ROUTE_KINDERGARTENS_BROWSER,
} from 'config/routes';
import React, { FC, useContext, Fragment } from 'react';
import { Route, Switch } from 'react-router';
import { NotFoundView } from 'views/Errors/NotFound.view';
import { InstitutionEdit } from './InstitutionEdit/InstitutionEdit.view';
import { InstitutionsList } from './InstitutionsList/InstitutionsList.view';
import { Unit } from 'views/Panel/Institutions/Unit/Unit.view';
import { Breadcrumb } from 'components/shared/navigation/Breadcrumbs/Breadcrumb';
import { LocaleContext } from 'context/locale';

export const InstitutionsView: FC = () => {
  const { trans } = useContext(LocaleContext);

  return (
    <Fragment>
      <Breadcrumb label={trans('BREADCRUMB.UNITS.INSTITUTIONS')} static />
      <Breadcrumb label={trans('BREADCRUMB.UNITS.INSTITUTIONS-LIST')} to={ROUTE_INSTITUTIONS} />

      <Switch>
        <Route exact path={ROUTE_INSTITUTIONS} component={InstitutionsList} />
        <Route exact path={ROUTE_INSTITUTION_EDIT} component={InstitutionEdit} />
        <Route path={ROUTE_INSTITUTION_UNIT} component={Unit} />
        <Route exact path={ROUTE_KINDERGARTENS_BROWSER} component={() => <span>kindergartens</span>} />
        <Route component={NotFoundView} />
      </Switch>
    </Fragment>
  );
};

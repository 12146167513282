import { muiStyles } from 'utils/design';

export const useStyles = muiStyles({
  container: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  grid: {
    flexGrow: 1,
  },
});

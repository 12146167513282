import { useTranslator } from 'components/shared/hooks';
import { Breadcrumb } from 'components/shared/navigation/Breadcrumbs/Breadcrumb';
import {
  ROUTE_POLLS,
  ROUTE_POLLS_CREATE,
  ROUTE_POLLS_EDIT,
  ROUTE_POLLS_LIST,
  ROUTE_POLLS_PUBLISH,
  ROUTE_POLLS_RESULTS,
} from 'config/routes';
import React, { FC, Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { NotFoundView } from 'views/Errors/NotFound.view';
import { AddPollView } from 'views/Panel/Polls/PollsEditor/AddPoll.view';
import { EditPollView } from 'views/Panel/Polls/PollsEditor/EditPoll.view';
import { PollsListView } from 'views/Panel/Polls/PollsList/PollsList.view';
import { PublishView } from 'views/Panel/Polls/Publish/Publish.view';
import { ResultsView } from 'views/Panel/Polls/Results/Results.view';

export const PollsView: FC = () => {
  const trans = useTranslator();

  return (
    <Fragment>
      <Breadcrumb label={trans('BREADCRUMB.POLLS.POLLS')} static />

      <Switch>
        <Redirect path={ROUTE_POLLS} exact to={ROUTE_POLLS_LIST} />
        <Route exact path={ROUTE_POLLS_LIST} component={PollsListView} />
        <Route exact path={ROUTE_POLLS_CREATE} component={AddPollView} />
        <Route exact path={ROUTE_POLLS_EDIT} component={EditPollView} />
        <Route exact path={ROUTE_POLLS_PUBLISH} component={PublishView} />
        <Route path={ROUTE_POLLS_RESULTS} component={ResultsView} />
        <Route component={NotFoundView} />
      </Switch>
    </Fragment>
  );
};

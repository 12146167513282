import { useStyles } from 'components/layout/panel/Sidebar/Sidebar.styles';
import React from 'react';
import { Drawer } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { isSidebarOpenSelector } from 'store/general';
import { NavigationList } from 'components/shared/lists/NavigationList/NavigationList';
import { navigation } from 'components/layout/panel/Sidebar/navigation/navigation';
import classNames from 'classnames';

export const Sidebar = () => {
  const classes = useStyles();
  const isSidebarOpen = useSelector(isSidebarOpenSelector);

  return (
    <Drawer
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpen,
        [classes.drawerClose]: !isSidebarOpen,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpen,
          [classes.drawerClose]: !isSidebarOpen,
        }),
      }}
      variant="permanent"
      open={isSidebarOpen}
    >
      <div className={classes.toolbar} />
      <NavigationList menu={navigation} useFloatingMenu={!isSidebarOpen} />
    </Drawer>
  );
};

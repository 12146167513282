import { useFormatter } from 'components/shared/hooks';
import { DateFormat } from 'components/shared/hooks/useFormatter/useFormatter.types';
import { ActionHandlers, RollbackActionHandlers } from './Browser.types';
import { BrowserProps } from './Browser.types';
import { Confirm } from 'components/shared/dialogs/Confirm/Confirm';
import { browserColumns, rollbackColumns } from './columns.config';
import { useStyles } from './Browser.styles';
import { DataGrid } from 'components/shared/data/DataGrid/DataGrid';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import React, { FC, useState } from 'react';
import {
  StudentRegisterBrowserRow,
  isStudentRegisterHeader,
  RegisterRollbackRow,
  StudentRegisterModel,
} from 'store/institutions';
import { ImportContainer } from '../Import/ImportContainer';
import Access from 'components/shared/access/access';
import { OfficialPermissionLevel, OfficialResourceGroup } from 'store/officials';

export const Browser: FC<BrowserProps> = (props) => {
  const [itemToDelete, setItemToDelete] = useState<StudentRegisterModel>(null);
  const classes = useStyles();
  const { formatDateWithTemplate, formatDate } = useFormatter();

  const actionHandlers: ActionHandlers = {
    preview: (data) => props.previewRegister(data.id),
    download: (data) => props.downloadRegister(data.id),
    delete: (data) => setItemToDelete(data),
  };

  const rollbackActionHandlers: RollbackActionHandlers = {
    preview: (row) => {
      const date = formatDateWithTemplate(DateFormat.AMERICAN_DATE, new Date(row.from));
      return props.previewRegister(row.registerId, date);
    },
    download: (row) => {
      return props.downloadRegister(row.registerId, formatDate(row.from));
    },
  };

  const deleteConfirmHandler = () => {
    props.deleteRegister(itemToDelete.id).then(() => setItemToDelete(null));
  };

  const getRollbackRows = (row: StudentRegisterModel): RegisterRollbackRow[] => {
    return row.revisions.map((revision) => ({ registerId: row.id, ...revision })).reverse();
  };

  return (
    <Paper innerSpacing={2} square={true} className={classes.paper}>
      <Access resourcesGroup={OfficialResourceGroup.childRegister} accessType={OfficialPermissionLevel.fullAccess}>
        <ImportContainer />
      </Access>
      <DataGrid<StudentRegisterBrowserRow, RegisterRollbackRow>
        className={classes.grid}
        data={props.registries}
        isLoading={props.loading}
        columns={browserColumns(actionHandlers)}
        loadingMessage={'COMMON.LOADING_DATA'}
        noResultsMessage={'COMMON.NOTHING-FOUND'}
        getRowClass={(params) => {
          return isStudentRegisterHeader(params.data) ? classes.headerRow : '';
        }}
        detailsColumns={rollbackColumns(rollbackActionHandlers)}
        detailsMapping={(row) => {
          return isStudentRegisterHeader(row) ? [] : getRollbackRows(row);
        }}
        isRowMaster={(row) => !isStudentRegisterHeader(row)}
        defaultExpand={0}
      />
      <Confirm
        open={itemToDelete !== null}
        title={'INSTITUTIONS.STUDENTS_REGISTER.DELETE_REGISTER.TITLE'}
        message={'INSTITUTIONS.STUDENTS_REGISTER.DELETE_REGISTER.MESSAGE'}
        confirm={() => deleteConfirmHandler()}
        cancel={() => setItemToDelete(null)}
      />
    </Paper>
  );
};

import React, { FC, useCallback } from 'react';
import { Edit } from './Edit';
import { useSelector, useDispatch } from 'react-redux';
import { isInstitutionLoading, getInstitution, putInstitution, fetchInstitution } from 'store/institutions';

interface EditContainerProps {
  institutionId: string;
  goBack: () => void;
}

export const EditContainer: FC<EditContainerProps> = ({ goBack, institutionId }) => {
  const dispatch: (...params: any) => Promise<any> = useDispatch();

  const fetchInstitutionCallback = useCallback(
    () => dispatch(fetchInstitution(institutionId)),
    [dispatch, institutionId],
  );

  const changeInstitutionNameCallback = useCallback(
    (name) => dispatch(putInstitution(name, institutionId)),
    [dispatch, institutionId],
  );

  return (
    <Edit
      loading={useSelector(isInstitutionLoading)}
      institution={useSelector(getInstitution)}
      changeInstitutionName={changeInstitutionNameCallback}
      fetchInstitution={fetchInstitutionCallback}
      goBack={goBack}
    />
  );
};

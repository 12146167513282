import { AppDispatch } from '@typings/redux';
import { ProgressModal } from 'components/shared/dialogs/ProgressModal/ProgressModal';
import { useTranslator } from 'components/shared/hooks';
import { ROUTE_SCHOOL_OBLIGATION_REPORT_DETAILS_OVERVIEW } from 'config/routes';
import { push } from 'connected-react-router';
import React, { FC, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReportStatusModalProps } from 'components/schoolObligation/ReportStatusModal/ReportStatusModal.types';
import { clearReportStatus, fetchReportStatus, getReportRequestSelector, ReportStatus } from 'store/schoolObligations';
import { makePath } from 'utils/navigation/makePath';

export const ReportStatusModal: FC<ReportStatusModalProps> = ({ open, onClose }) => {
  const dispatch = useDispatch<AppDispatch>();
  const trans = useTranslator();
  const timer = useRef<number>();
  const { reportId, reportStatus, error } = useSelector(getReportRequestSelector);

  const isStatusDone = (status: ReportStatus) => {
    return [ReportStatus.PUBLISHED, ReportStatus.READY].includes(status);
  };

  useEffect(() => {
    if (open) {
      dispatch(clearReportStatus());
    }
  }, [dispatch, reportId, open]);

  useEffect(() => {
    const updateStatus = async () => {
      const { payload, error } = await dispatch(fetchReportStatus(reportId));
      const status: ReportStatus = payload.status;

      if (!isStatusDone(status) && open && !error) {
        timer.current = window.setTimeout(() => updateStatus(), 1000);
      }
    };

    if (reportId && open) {
      updateStatus();
    }

    return () => clearTimeout(timer.current);
  }, [reportId, dispatch, open]);

  const handleClose = () => {
    onClose();
  };

  const handleOpenReport = () => {
    handleClose();
    dispatch(push(makePath(ROUTE_SCHOOL_OBLIGATION_REPORT_DETAILS_OVERVIEW, { id: reportId })));
  };

  const getMessage = () => {
    if (error) {
      return 'SCHOOL_OBLIGATION.REPORT_STATUS_MODAL.STATUS.ERROR';
    }

    return {
      [ReportStatus.SCHEDULED]: 'SCHOOL_OBLIGATION.REPORT_STATUS_MODAL.STATUS.GENERATING',
      [ReportStatus.ASSIGNING_CHILDREN]: 'SCHOOL_OBLIGATION.REPORT_STATUS_MODAL.STATUS.GENERATING',
      [ReportStatus.ASSIGNING_STUDENT_REGISTER]: 'SCHOOL_OBLIGATION.REPORT_STATUS_MODAL.STATUS.GENERATING',
      [ReportStatus.READY]: 'SCHOOL_OBLIGATION.REPORT_STATUS_MODAL.STATUS.READY',
      [ReportStatus.PUBLISHED]: 'SCHOOL_OBLIGATION.REPORT_STATUS_MODAL.STATUS.READY',
    }[reportStatus.status];
  };

  return (
    <ProgressModal
      title={trans('SCHOOL_OBLIGATION.REPORT_STATUS_MODAL.TITLE')}
      open={open}
      description={trans(getMessage())}
      progress={reportStatus.progress}
      error={error}
      button={
        error
          ? {
              action: handleClose,
              label: trans('COMMON.CLOSE'),
            }
          : {
              action: handleOpenReport,
              label: trans('SCHOOL_OBLIGATION.REPORT_STATUS_MODAL.OPEN_REPORT'),
              disabled: !isStatusDone(reportStatus.status),
            }
      }
    />
  );
};

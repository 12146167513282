import { colors } from 'styles/colors';
import { muiStyles } from 'utils/design';

export const useStyles = muiStyles({
  item: {
    paddingLeft: '80px',
  },
  container: {
    background: colors.neutralVeryLight,
  },
});

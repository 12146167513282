import React, { FC, useState, useRef } from 'react';
import { Button, Collapse } from '@material-ui/core';
import { UploadFile } from 'components/shared/form/UploadFile/UploadFile';
import { StepActions, StepContent, BackButton, NextButton } from 'components/shared/dialogs/StepModal';
import { Form } from 'components/shared/form';
import { FileUploadForm, FileImportProps } from './FileImports.types';
import { useFormatter, useTranslator } from 'components/shared/hooks';
import isArray from 'lodash/isArray';
import { FormikHelpers } from 'formik';
import { useStyles } from './FileImport.styles';
import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

export const FileImport: FC<FileImportProps> = ({
  postStudentRegisterImport,
  deleteStudentRegisterImport,
  onClose,
  token,
  goNext,
  fileName,
}) => {
  const [isManualOpen, setManual] = useState(false);
  const form = useRef(null);
  const { formatApiErrors } = useFormatter();
  const trans = useTranslator();
  const classes = useStyles();

  const handlePostStudentRegisterImport = (response, formik: FormikHelpers<FileUploadForm>) => {
    if (response.error) {
      if (response.payload && response.payload.response && response.payload.response.errors) {
        const errors = response.payload.response.errors;
        if (isArray(errors)) {
          formik.setFieldError('importFile', trans(response.payload.response.errors[0]));
        } else {
          formik.setErrors(formatApiErrors(errors));
        }
      } else if (response.payload && response.payload.response && response.payload.response.error) {
        const error = response.payload.response.error;
        formik.setFieldError('importFile', trans(error));
      } else {
        // when file is very big error 413 is throw by ngnix and we need to handle it
        formik.setFieldError('importFile', trans('STUDENT_REGISTER.IMPORT.TOO_LARGE_FILE'));
      }
      formik.setSubmitting(false);
      return;
    }

    goNext();
  };

  const uploadFile = (values: FileUploadForm, formik: FormikHelpers<FileUploadForm>) => {
    if (values.importFile) {
      postStudentRegisterImport(values.importFile).then((response) =>
        handlePostStudentRegisterImport(response, formik),
      );
    } else {
      if (!token) {
        postStudentRegisterImport('').then((response) => handlePostStudentRegisterImport(response, formik));
      }
    }
  };

  const handleSubmit = (values: FileUploadForm, formik: FormikHelpers<FileUploadForm>) => {
    if (token) {
      if (!values.importFile) {
        goNext();
      } else {
        deleteStudentRegisterImport().then(() => uploadFile(values, formik));
      }
    } else {
      uploadFile(values, formik);
    }
  };

  return (
    <>
      <StepContent>
        <div className="container pb-1 pt-1 text-center">
          <div className="row justify-content-center mb-4">
            <Button
              onClick={() => setManual((isManualOpen) => !isManualOpen)}
              color="primary"
              variant="contained"
              className={classes.instructionBtn}
            >
              <FontAwesomeIcon name={'info-circle'} size={'lg'} className={'mr-2'} />
              {trans('INSTITUTIONS.STUDENTS_REGISTER.IMPORT_STEPS.FILE.MANUAL')}
            </Button>
          </div>
          <Collapse in={isManualOpen} unmountOnExit={true}>
            <Typography variant="body2" component="div">
              <div className="row d-flex flex-column text-left align-items-center">
                <div className="row col-5 mb-3">
                  {trans('INSTITUTIONS.STUDENTS_REGISTER.IMPORT_STEPS.FILE.MANUAL_PLACEHOLDER')}
                </div>
                <div className="row col-5 mb-1">
                  {trans('INSTITUTIONS.STUDENTS_REGISTER.IMPORT_STEPS.FILE.FILE_TEMPLATE')}
                </div>
                <div className="row col-5 d-flex flex-column">
                  <Link href={'/templates/KU-podstawowa.csv'} download="KU-podstawowa.csv">
                    {trans('INSTITUTIONS.STUDENTS_REGISTER.IMPORT_STEPS.FILE.KU_BASIC_TEMPLATE')}
                  </Link>
                  <Link href={'/templates/KU-rozszerzona.csv'} download="KU-rozszerzona.csv">
                    {trans('INSTITUTIONS.STUDENTS_REGISTER.IMPORT_STEPS.FILE.KU_FULL_TEMPLATE')}
                  </Link>
                </div>
              </div>
            </Typography>
          </Collapse>
          <div className="row justify-content-center mt-3">
            <Form<FileUploadForm> initialValues={{ importFile: null }} onSubmit={handleSubmit} innerRef={form}>
              {() => {
                return (
                  <UploadFile
                    label={trans('INSTITUTIONS.STUDENTS_REGISTER.IMPORT_STEPS.FILE.PLACEHOLDER')}
                    name="importFile"
                    required
                    defaultInputValue={fileName}
                    defaultLabelWidth={92}
                  />
                );
              }}
            </Form>
          </div>
        </div>
      </StepContent>
      <StepActions>
        <BackButton onClick={onClose}>{trans('COMMON.CANCEL')}</BackButton>
        <NextButton onClick={() => !form.current.isSubmitting && form.current.handleSubmit()}>
          {trans('COMMON.NEXT')}
        </NextButton>
      </StepActions>
    </>
  );
};

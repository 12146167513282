import { AppDispatch } from '@typings/redux';
import { EditContainerProps } from 'components/officials/Editor/Editor.types';
import { fetchApplicantDetails, updateApplicant } from 'store/applicants/applicants.action';
import { applicantSelector } from 'store/applicants/applicants.selectors';
import { OfficialForm } from 'store/officials';
import { accessRulesSelector } from 'store/accessRules';
import { useFormatter, useNotification } from 'components/shared/hooks';
import { useEnvironment } from 'components/shared/hooks/useEnvironment/useEnvironment';
import { mapFormApplicantValueToPayload, mapFormApplicantPayloadToValue } from './Editor.helpers';
import { Editor } from './Editor';
import { ROUTE_OFFICIALS_LIST } from 'config/routes';
import { push, replace } from 'connected-react-router';
import React, { FC, useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import { FormikHelpers } from 'formik';
import { fetchInstitutionsList, institutionsListSelector } from 'store/institutions';

export const EditApplicantContainer: FC<EditContainerProps> = ({ officialId }) => {
  const applicantDetails = useSelector(applicantSelector);
  const accessRules = useSelector(accessRulesSelector);
  const dispatch = useDispatch<AppDispatch>();
  const { formatApiErrors } = useFormatter();
  const { success, error } = useNotification();
  const [isSubmitted, setSubmitted] = useState(false);
  const applicants = useMemo(
    () => applicantDetails.official && mapFormApplicantPayloadToValue(applicantDetails.official),
    [applicantDetails.official],
  );
  const { origin } = useEnvironment();
  const institutions = useSelector(institutionsListSelector);

  useEffect(() => {
    batch(async () => {
      const result = await dispatch(fetchApplicantDetails(officialId));
      if (result.error && result.payload.status === 404) {
        dispatch(replace(ROUTE_OFFICIALS_LIST));
        return;
      }
    });
  }, [officialId, dispatch]);

  useEffect(() => {
    dispatch(fetchInstitutionsList({ search: '' }));
  }, [dispatch]);

  const onSave = async (data: OfficialForm, formik: FormikHelpers<OfficialForm>) => {
    const result = await dispatch(updateApplicant(officialId, mapFormApplicantValueToPayload(data), origin));
    if (result.error && result.payload.status === 404) {
      setSubmitted(true);
      dispatch(replace(ROUTE_OFFICIALS_LIST));
      return;
    }
    if (result.error) {
      if (result.payload.response.errors) {
        error('OFFICIALS.EDITOR.MESSAGE.OFFICIAL_SAVE_ERROR');
        formik.setErrors(formatApiErrors(result.payload.response.errors));
      }
      formik.setSubmitting(false);
    } else {
      success('OFFICIALS.EDITOR.MESSAGE.OFFICIAL_SAVE_SUCCESS');
      setSubmitted(true);
      dispatch(push(ROUTE_OFFICIALS_LIST));
    }
  };

  const onCancel = () => {
    dispatch(push(ROUTE_OFFICIALS_LIST));
  };

  return (
    <Editor
      initial={applicants}
      loading={applicantDetails.loading || accessRules.loading}
      disabledTypeAccount
      accessRules={accessRules.items}
      submitted={isSubmitted}
      onSubmit={onSave}
      onCancel={onCancel}
      units={institutions.items}
    />
  );
};

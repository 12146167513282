import React, { FC, useCallback, useEffect, useState } from 'react';
import { Preview } from './Preview';
import { useDispatch, useSelector } from 'react-redux';
import {
  importContextSelector,
  importLoadingSelector,
  importPreviewSelector,
  ImportPreviewStatus,
  importPreviewStatusSelector,
  importTokenSelector,
  importTypeSelector,
} from 'store/childRegister';
import {
  fetchImportPreview,
  fetchImportPreviewStatus,
  postImportConfirm,
  startImportPreview,
} from 'store/childRegister/childRegister.actions';
import { PreviecContainerProps, PreviewSummary } from 'components/childRegister/Import/steps/Preview/Preview.types';

export const PreviewContainer: FC<PreviecContainerProps> = (props) => {
  const dispatch: <T = any>(...params: any[]) => Promise<T> = useDispatch();
  const importToken = useSelector(importTokenSelector);
  const importPreview = useSelector(importPreviewSelector);
  const importType = useSelector(importTypeSelector);
  const isPreviewLoading = useSelector(importLoadingSelector);
  const previewStatus = useSelector(importPreviewStatusSelector);
  const contextMapping = useSelector(importContextSelector).map;
  const [isPreviewGenerated, setPreviewGenerated] = useState(false);
  const [statusCheckTimeout, setStatusCheckTimeout] = useState<NodeJS.Timeout>(null);

  const previewSummaryRecords: PreviewSummary[] = [
    {
      recordType: 'CHILD_REGISTER.IMPORT_STEPS.PREVIEW.SUMMARY.TOTAL',
      tooltip: null,
      numberOfRecords: importPreview.recordsToImport,
      distinguishRow: true,
    },
    {
      recordType: 'CHILD_REGISTER.IMPORT_STEPS.PREVIEW.SUMMARY.ADDED',
      tooltip: 'CHILD_REGISTER.IMPORT_STEPS.PREVIEW.SUMMARY.ADDED_TOOLTIP',
      numberOfRecords: importPreview.recordsToCreate,
    },
    {
      recordType: 'CHILD_REGISTER.IMPORT_STEPS.PREVIEW.SUMMARY.UPDATE',
      tooltip: 'CHILD_REGISTER.IMPORT_STEPS.PREVIEW.SUMMARY.UPDATE_TOOLTIP',
      numberOfRecords: importPreview.recordsToUpdate,
    },
    {
      recordType: 'CHILD_REGISTER.IMPORT_STEPS.PREVIEW.SUMMARY.NO_CHANGE',
      tooltip: 'CHILD_REGISTER.IMPORT_STEPS.PREVIEW.SUMMARY.NO_CHANGE_TOOLTIP',
      numberOfRecords: importPreview.recordsToImport - importPreview.recordsToCreate - importPreview.recordsToUpdate,
    },
  ];

  useEffect(() => {
    return () => clearTimeout(statusCheckTimeout);
  }, [statusCheckTimeout]);

  const postConfirm = useCallback(() => {
    return dispatch(postImportConfirm(importToken));
  }, [dispatch, importToken]);

  const fetchGeneratedPreview = useCallback(() => {
    setPreviewGenerated(true);
    dispatch(fetchImportPreview(importToken));
  }, [dispatch, importToken]);

  const fetchPreviewStatus = useCallback(async () => {
    const previewStatus = await dispatch(fetchImportPreviewStatus(importToken));

    if (!previewStatus.payload || previewStatus.payload?.response?.error) {
      props.onFailure(previewStatus.payload?.response?.error);
      return;
    }

    if (previewStatus.payload.status === ImportPreviewStatus.preparingPreview) {
      setStatusCheckTimeout(setTimeout(() => fetchPreviewStatus(), 1000));
    } else {
      setTimeout(() => fetchGeneratedPreview(), 1000);
    }
  }, [dispatch, fetchGeneratedPreview, importToken, props]);

  const startPreviewGeneration = useCallback(async () => {
    await dispatch(startImportPreview(importToken));
    await fetchPreviewStatus();
  }, [dispatch, importToken, fetchPreviewStatus]);

  useEffect(() => {
    startPreviewGeneration();
  }, [startPreviewGeneration]);

  return (
    <Preview
      goBack={props.goBack}
      previewRecords={importPreview.recordsPreview}
      isPreviewLoading={isPreviewLoading}
      isPreviewGenerating={!isPreviewGenerated}
      generationProgress={previewStatus.progress}
      postImportConfirm={postConfirm}
      summaryRecords={previewSummaryRecords}
      onConfirmImport={props.onConfirmImport}
      importType={importType}
      archiveRecords={importPreview.recordsToArchive}
      activeRecords={importPreview.currentRegisterSize}
      contextMapping={contextMapping}
    />
  );
};

import { ChildRegisterFormValues, GuardianPostModel } from 'components/childRegister/Editor/General/General.types';
import { emptyExemption } from 'components/childRegister/Editor/Obligation/parts/Exemptions/Exemptions.helpers';
import { NO_TERYT_ITEM_ID } from 'components/shared/teryt/TownPicker/TownPickerContainer';
import { ChildModel, ChildPostModel, DefaultGuardianTypes, IdentificationNumberType } from 'store/childRegister';
import { AddressModel, AddressPostModel, TerytComponentModel } from 'store/teryt';
import { nullableEmptyString, provideString } from 'utils/string/provideStrings';
import moment from 'moment';

const mapAddressModelToTerytModel = (address: AddressModel): TerytComponentModel => {
  return {
    city: {
      id: address.cityId || NO_TERYT_ITEM_ID,
      name: address.cityName,
      country: address.country,
    },
    street: {
      id: address.streetId || NO_TERYT_ITEM_ID,
      name: address.streetName,
    },
    buildingNumber: address.buildingNumber,
    apartmentNumber: address.apartmentNumber,
    postalCode: address.postalCode,
  };
};

const mapTerytModelToAddressModel = (teryt: TerytComponentModel): AddressPostModel => {
  const data: AddressPostModel = {
    apartmentNumber: provideString(teryt.apartmentNumber),
    buildingNumber: provideString(teryt.buildingNumber),
    city: { id: null, name: null, country: null },
    country: provideString(teryt.city.country),
    street: { id: null, name: null },
    postalCode: provideString(teryt.postalCode),
  };

  if (teryt.street.id && teryt.street.id !== NO_TERYT_ITEM_ID) {
    data.street.id = teryt.street.id;
  } else {
    data.street.name = provideString(teryt.street.name);
  }

  if (teryt.city.id && teryt.city.id !== NO_TERYT_ITEM_ID) {
    data.city.id = teryt.city.id;
  } else {
    data.city.name = provideString(teryt.city.name);
  }

  return data;
};

export const mapFormValueToPayload = (values: ChildRegisterFormValues, addIdToGuardian = true): ChildPostModel => {
  return {
    birthday: values.birthday && moment(values.birthday).format('YYYY-MM-DD'),
    birthCity: nullableEmptyString(values.birthCity),
    firstName: nullableEmptyString(values.firstName),
    identificationNumber:
      values.identificationNumberType === IdentificationNumberType.EMPTY
        ? null
        : nullableEmptyString(values.identificationNumber),
    identificationNumberType: nullableEmptyString(values.identificationNumberType),
    lastName: nullableEmptyString(values.lastName),
    secondName: nullableEmptyString(values.secondName),
    isCatchmentChild: values.isCatchmentChild || false,
    sex: nullableEmptyString(values.sex),
    residence: mapTerytModelToAddressModel(values.residence),
    permanentResidence: mapTerytModelToAddressModel(values.permanentResidence),
    isResident: values.isResident || false,
    isPermanentResident: values.isPermanentResident || false,
    guardians: values.guardians.map(({ id, ...guardian }) => {
      if (
        !guardian.firstName &&
        !guardian.lastName &&
        !guardian.address &&
        !guardian.contact &&
        (!guardian.type || guardian.type === DefaultGuardianTypes.DEFAULT)
      ) {
        return null;
      }

      return {
        ...guardian,
        address: mapTerytModelToAddressModel(guardian.address),
        ...(addIdToGuardian ? { id } : {}),
      };
    }),
    exemptions: values.exemptions.map((exemption) => {
      return exemption.description || exemption.schoolYear ? exemption : null;
    }),
    manualStudentRegisterEntries: values.manualStudentRegisterEntries
      .map((entry) => ({
        ...entry,
        level: entry.level ? Number(entry.level) : null,
      }))
      .filter((entry) => entry.date || entry.level || entry.unitId),
  };
};

export const mapChildModelToForm = (child: ChildModel): ChildRegisterFormValues => {
  return {
    ...child,
    residence: mapAddressModelToTerytModel(child.residence),
    permanentResidence: mapAddressModelToTerytModel(child.permanentResidence),
    guardians: child.guardians.map((guardian) => {
      return {
        ...guardian,
        address: mapAddressModelToTerytModel(guardian.address),
      };
    }),
    exemptions: child.exemptions.length ? child.exemptions : [emptyExemption()],
    manualStudentRegisterEntries: [],
  };
};

export const initialGuardian = (): GuardianPostModel => {
  return {
    id: null,
    firstName: '',
    lastName: '',
    type: DefaultGuardianTypes.DEFAULT,
    address: {
      city: {
        id: null,
        name: null,
        country: null,
      },
      street: {
        id: null,
        name: null,
      },
      buildingNumber: null,
      apartmentNumber: null,
      postalCode: null,
    },
    correspondenceGuardian: false,
    sameAddressAsChild: true,
    contact: {
      email: '',
      phone: '',
    },
  };
};

import React, { FC, useState, useRef } from 'react';
import { ROUTE_SCHOOL_OBLIGATION_REPORT_DETAILS } from 'config/routes';
import { push } from 'connected-react-router';
import {
  ReportBrowserHandlers,
  ReportsBrowserProps,
} from 'components/schoolObligation/ReportsBrowser/ReportsBrowser.types';
import { DataGrid } from 'components/shared/data/DataGrid/DataGrid';
import { ObligationReport, ReportBrowserFilter, ReportStatus } from 'store/schoolObligations';
import { reportColumns } from 'components/schoolObligation/ReportsBrowser/columns.config';
import { useStyles } from 'components/schoolObligation/ReportsBrowser/ReportsBrowser.styles';
import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon';
import { Button } from 'components/shared/buttons/Button/Button';
import { useTranslator } from 'components/shared/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { routerSelector } from 'store/router/router.selectors';
import { Splash } from 'components/shared/surfaces/Splash/Splash';
import { Confirm } from 'components/shared/dialogs/Confirm/Confirm';
import { AutoSave } from 'components/shared/form/AutoSave/AutoSave';
import { Form, SelectBox } from 'components/shared/form';
import { toOptions } from 'utils/api';
import { ReportModalContainer } from 'components/schoolObligation/ReportModal/ReportModalContainer';
import { ReportStatusModal } from 'components/schoolObligation/ReportStatusModal/ReportStatusModal';
import { makePath } from 'utils/navigation/makePath';
import Access from 'components/shared/access/access';
import { OfficialPermissionLevel, OfficialResourceGroup } from 'store/officials';

export const ReportsBrowser: FC<ReportsBrowserProps> = ({
  isLoading,
  reports,
  onDelete,
  fetchReportsList,
  filters,
  schoolYears,
  handleFilterSubmit,
}) => {
  const classes = useStyles();
  const trans = useTranslator();
  const dispatch = useDispatch();
  const { key: locationKey } = useSelector(routerSelector);
  const [reportToDelete, setReportToDelete] = useState(null);
  const [isReportModalOpen, setReportModalOpen] = useState(false);
  const [isReportStatusModalOpen, setReportStatusModalOpen] = useState(false);
  const form = useRef(null);

  const actionHandlers: ReportBrowserHandlers = {
    show: (id) => {
      dispatch(push(makePath(ROUTE_SCHOOL_OBLIGATION_REPORT_DETAILS, { id })));
    },
    delete: (report: ObligationReport) => {
      return setReportToDelete(report.id);
    },
  };

  const handleConfirmDelete = () => {
    onDelete(reportToDelete).then(() => {
      setReportToDelete(null);
      fetchReportsList();
      form.current.resetForm();
    });
  };

  const renderEmptyScreen = () => (
    <Splash>
      <div className="mb-3">{trans('SCHOOL_OBLIGATION.REPORTS.NO-DATA')}</div>
    </Splash>
  );

  const getFilteredReports = () => {
    return reports
      .filter((report) => report.status === ReportStatus.PUBLISHED)
      .filter((report) => (filters.schoolYear ? report.schoolYear === filters.schoolYear : true));
  };

  const renderGrid = () => (
    <DataGrid<ObligationReport>
      key={locationKey}
      className={classes.grid}
      data={getFilteredReports()}
      columns={reportColumns(actionHandlers)}
      isLoading={isLoading}
    />
  );

  const reportSubmitSuccess = () => {
    setReportModalOpen(false);
    setReportStatusModalOpen(true);
  };

  return (
    <>
      <section className={classes.toolbar}>
        <Form<ReportBrowserFilter>
          initialValues={filters}
          onSubmit={handleFilterSubmit}
          className={classes.form}
          innerRef={form}
        >
          {(formik) => {
            if (formik.values.schoolYear && !schoolYears.includes(formik.values.schoolYear)) {
              formik.setFieldValue('schoolYear', null);
            }

            return (
              <>
                <SelectBox
                  name="schoolYear"
                  displayEmpty
                  resettable
                  placeholderText={trans('SCHOOL_OBLIGATION.FILTERS.SCHOOL_YEAR.ALL')}
                  labelText={trans('SCHOOL_OBLIGATION.FILTERS.SCHOOL_YEAR.LABEL')}
                  options={toOptions(schoolYears)}
                />
                <AutoSave />
              </>
            );
          }}
        </Form>
        <Access resourcesGroup={OfficialResourceGroup.childRegister} accessType={OfficialPermissionLevel.fullAccess}>
          <Button
            variant="contained"
            color="primary"
            className="float-right"
            onClick={() => setReportModalOpen(true)}
            tabIndex={0}
          >
            <FontAwesomeIcon name={'plus-circle'} className={'mr-2'} />
            {trans('SCHOOL_OBLIGATION.REPORTS.ADD-REPORT')}
          </Button>
        </Access>
      </section>

      {!isLoading && reports.length === 0 ? renderEmptyScreen() : renderGrid()}
      <Confirm
        open={!!reportToDelete}
        title={'SCHOOL_OBLIGATION.REPORTS.DELETE.CONFIRM'}
        message={'SCHOOL_OBLIGATION.REPORTS.DELETE.CONFIRM-MESSAGE'}
        confirm={handleConfirmDelete}
        cancel={() => setReportToDelete(null)}
      />
      <ReportModalContainer
        open={isReportModalOpen}
        onClose={() => setReportModalOpen(false)}
        onSubmitSuccess={reportSubmitSuccess}
      />
      <ReportStatusModal open={isReportStatusModalOpen} onClose={() => setReportStatusModalOpen(false)} />
    </>
  );
};

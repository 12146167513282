import { useStyles } from 'components/layout/panel/PageHeader/PageHeader.styles';
import { Breadcrumbs } from 'components/shared/lists/Breadcrumbs/Breadcrumbs';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { Heading } from 'components/shared/typography/Heading/Heading';
import { PageHeaderProps } from 'components/layout/panel/PageHeader/PageHeader.types';
import classNames from 'classnames';

export const PageHeader: FC<PageHeaderProps> = (props) => {
  const { title } = props;
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
      </Helmet>
      <div className="row">
        <div className={classNames(classes.title, 'col')}>
          <Heading type={'h1'} className={classes.header}>
            {title}
          </Heading>
        </div>
        <div className="col">
          <Breadcrumbs />
        </div>
      </div>
    </div>
  );
};

import { pickBy } from 'lodash';

export const clean = (object) => {
  return pickBy(object, (value) => {
    if (value === undefined) {
      return false;
    }

    if (value === null) {
      return false;
    }

    if (value === '') {
      return false;
    }

    return true;
  });
};

import { PageCollection } from '@typings/common';
import { DocumentModel } from 'components/shared/dialogs/DocumentModal/DocumentModal.types';
import { AlertsInfo, Template } from 'store/templates/templates.types';

export interface DocumentRequestStatus {
  progress: number;
  status: DocumentStatus;
}

export enum DocumentStatus {
  PENDING = 'pending',
  GENERATING = 'generating',
  READY = 'ready',
  BROKEN = 'broken',
}

export interface TemplatesState {
  list: {
    items: Template[];
    loading: boolean;
    error: any;
  };
  preview: {
    items: PageCollection<DocumentModel>;
    total: number;
    fields: string[];
    alerts: AlertsInfo;
    loading: boolean;
    error: any;
  };
  documentRequest: {
    documentId: string;
    requestStatus: DocumentRequestStatus;
    error: boolean;
  };
}

export const defaultState: TemplatesState = {
  list: {
    items: [],
    loading: false,
    error: null,
  },
  preview: {
    items: [],
    total: 0,
    fields: [],
    alerts: {
      rowsAffected: 0,
      details: {},
    },
    loading: false,
    error: null,
  },
  documentRequest: {
    documentId: null,
    requestStatus: {
      progress: 0,
      status: DocumentStatus.PENDING,
    },
    error: false,
  },
};

import { useDepartmentSettings } from 'components/shared/hooks/useInstanceConfig/useDepartmentSettings';
import { NO_TERYT_ITEM_ID } from 'components/shared/teryt/TownPicker/TownPickerContainer';
import React, { FC, useState, useContext, useEffect } from 'react';
import { Button } from 'components/shared/buttons/Button/Button';
import { AutocompleteOptionValue, TownPickerProps } from './TownPicker.types';
import { LocationModalContainer } from './LocationModal/LocationModalContainer';
import { Autocomplete } from 'components/shared/form/Autocomplete/Autocomplete';
import { InputText } from 'components/shared/form';
import { debounce, get } from 'lodash';
import { formatTownSummary, townsListIncludes } from './TownPicker.helpers';
import { LocaleContext } from 'context/locale';
import { useStyles } from './TownPicker.styles';
import classNames from 'classnames';
import { Tooltip } from 'components/shared/messages/Tooltip/Tooltip';
import { InfoOutlined } from '@material-ui/icons';
import { isForeignTownItem, isLocalTownItem, isTerytTown, TownItem } from 'store/teryt';
import { isSingleSelection } from 'components/shared/form/Autocomplete/Autocomplete.types';
import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon';
import { formatTownPath } from './TownPicker.helpers';

export const TownPicker: FC<TownPickerProps> = ({
  fieldName,
  townsList,
  fetchTownsList,
  isTownsListLoading,
  initialSelectedTown,
  required,
  form,
  disabled,
  allowEmptyCountry = false,
  afterRender,
  clearTownList,
}) => {
  const classes = useStyles();
  const [isLocationModalOpened, setLocationModalState] = useState(false);
  const [isAutocompleteOpen, setAutocompleteOpen] = useState(false);
  const [selectedTown, setSelectedTown] = useState(initialSelectedTown);
  const [towns, setTowns] = useState(null);
  const { locale, trans } = useContext(LocaleContext);
  const townValue = get(form.values, fieldName);
  const settings = useDepartmentSettings();

  useEffect(() => {
    const newTownsList = [...townsList];

    if (!townsListIncludes(townsList, selectedTown)) {
      if (selectedTown && selectedTown.name && isTerytTown(selectedTown)) {
        newTownsList.push({
          value: selectedTown.id,
          label: formatTownPath(selectedTown, trans),
          selectedLabel: selectedTown.name,
          town: selectedTown,
        });
      }

      if (selectedTown && isLocalTownItem(selectedTown) && selectedTown.name) {
        newTownsList.push({
          value: selectedTown.id,
          label: selectedTown.name,
          selectedLabel: selectedTown.name,
          town: selectedTown,
        });
      }
    }

    setTowns(newTownsList);
  }, [townsList, selectedTown, trans]);

  const loadTowns = async (inputValue) => {
    if (inputValue.length >= 3) {
      const filters = {
        search: inputValue,
        priorityCountyForCommune: settings.osinCommune,
      };

      await fetchTownsList(filters);
    }
  };

  const handleLocationFormSubmit = (town: TownItem) => {
    setSelectedTown(town);
    if (isTerytTown(town)) {
      form.setFieldValue(fieldName, {
        id: town.id,
        name: town.name,
        country: 'PL',
      });
    } else {
      form.setFieldValue(fieldName, {
        id: null,
        ...town,
      });
    }
  };

  const townSummary = townValue?.name ? formatTownSummary(selectedTown, locale, trans) : trans('TERYT.TOWN.DEFAULT');

  const clearInput = () => {
    setSelectedTown(null);
    clearTownList();
    form.setFieldValue(fieldName, {
      id: NO_TERYT_ITEM_ID,
      name: null,
      country: allowEmptyCountry ? null : 'PL',
    });
    if (form.errors[fieldName.split('.')[0]]) {
      form.setFieldError(fieldName.split('.')[0], undefined);
    }
  };

  const getInputIcon = () => {
    if (disabled) return <InfoOutlined fontSize="small" />;
    else
      return (
        <FontAwesomeIcon
          name={'times'}
          className={classes.clearAllIcon}
          onClick={clearInput}
          dataTestId={'clearAllIcon'}
        />
      );
  };

  const getInputTooltip = () => {
    if (disabled) return trans('INSTITUTIONS.UNIT_DATA.GENERAL_TOOLTIPS.IMPORTED_FROM_SYNERGIA');
    else return trans('TERYT.TOWN.CLEAR_INPUT');
  };

  const handleAutocompleteOnChange = (value: AutocompleteOptionValue | readonly AutocompleteOptionValue[]) => {
    if (value && isSingleSelection(value)) {
      setSelectedTown(value.town);

      if (isTerytTown(value.town)) {
        form.setFieldValue(fieldName, {
          id: value.town.id,
          name: value.town.name,
          country: 'PL',
        });
      }
    } else {
      clearInput();
    }
  };

  const handleInputOnChange = (event) => {
    if (isForeignTownItem(selectedTown)) {
      setSelectedTown({
        id: NO_TERYT_ITEM_ID,
        country: selectedTown.country,
        name: event.target.value,
      });

      form.setFieldValue(`${fieldName}.name`, event.target.value);
    }
  };

  const formatOptionLabel = (value, labelMeta) => {
    return value.value === NO_TERYT_ITEM_ID && labelMeta.context === 'menu'
      ? `${value.label} (spoza TERYT)`
      : value.label;
  };

  return (
    <div className="row">
      <div className="col-3">
        {townValue && (townValue.country === 'PL' || !townValue.country) ? (
          <Autocomplete<AutocompleteOptionValue>
            name={`${fieldName}.id`}
            options={towns}
            placeholder={trans('TERYT.TOWN.PLACEHOLDER')}
            selectedLabel="selectedLabel"
            onInputChange={debounce((inputValue) => {
              loadTowns(inputValue);
            }, 500)}
            menuIsOpen={isAutocompleteOpen}
            onMenuOpen={() => {
              if (!townValue) return;
              if (townValue.name) {
                if (townValue.id === NO_TERYT_ITEM_ID || (townValue.id && get(form.errors, fieldName))) {
                  setAutocompleteOpen(true);
                  loadTowns(townValue.name);
                  return;
                }
              }
              if (!townValue.name && (townValue.id === NO_TERYT_ITEM_ID || !townValue.id)) {
                setAutocompleteOpen(true);
                return;
              }
            }}
            onMenuClose={() => setAutocompleteOpen(false)}
            isLoading={isTownsListLoading}
            alwaysShowPlaceholder={true}
            required={required}
            disabled={disabled}
            hasIcon={disabled}
            icon={<InfoOutlined fontSize="small" />}
            tooltipText={trans('INSTITUTIONS.UNIT_DATA.GENERAL_TOOLTIPS.IMPORTED_FROM_SYNERGIA')}
            onChange={handleAutocompleteOnChange}
            filterOption={() => true}
            helpMessage={townValue.name && townValue.id === NO_TERYT_ITEM_ID ? trans('TERYT.TOWN.NO_TERYT') : ''}
            showNoOptionsMessage={false}
            formatOptionLabel={formatOptionLabel}
            preventScrollToSelectedOption={true}
          />
        ) : (
          <InputText
            name={`${fieldName}.name`}
            labelText={trans('TERYT.TOWN.PLACEHOLDER')}
            disabled={disabled}
            hasIcon={true}
            icon={getInputIcon()}
            tooltipText={getInputTooltip()}
            onChange={handleInputOnChange}
            helpMessage={trans('TERYT.TOWN.NO_TERYT')}
          />
        )}
      </div>
      <div className="col-6">
        <Tooltip
          title={townSummary || ''}
          placement="top-start"
          disabled={false}
          interactive={true}
          classes={{ tooltip: classes.tooltip }}
        >
          <div className={classNames('mb-2', classes.townSummary)} data-testid="townSummary">
            {townSummary}
          </div>
        </Tooltip>
        <div>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => setLocationModalState(true)}
            data-testid="openLocationModal"
            disabled={disabled}
          >
            {trans('TERYT.TOWN.CHOOSE_LOCATION')}
          </Button>
        </div>
      </div>
      <div className="col-3">{afterRender?.()}</div>
      {isLocationModalOpened && (
        <LocationModalContainer
          open={isLocationModalOpened}
          onClose={() => setLocationModalState(false)}
          town={selectedTown}
          onSubmit={handleLocationFormSubmit}
          country={townValue.country}
          allowEmptyCountry={allowEmptyCountry}
        />
      )}
    </div>
  );
};

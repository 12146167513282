import { muiStyles } from 'utils/design';

export const useStyles = muiStyles(() => ({
  dialog: {
    overflowY: 'visible',
  },
  content: {
    width: '800px',
    overflowY: 'visible',
  },
  advancedPrefix: {
    marginBottom: '19px',
    textAlign: 'center',
  },
  advancedLabel: {
    lineHeight: '30px',
  },
  rangeInput: {
    '& input': {
      textAlign: 'center',
    },
    '& + .Mui-error': {
      whiteSpace: 'nowrap',
    },
  },
}));

import { AddressRule } from 'store/schoolDistricts';
import { Translator } from 'context/locale/LocaleContext/LocaleContext.types';
import { SchoolAddressRule } from 'components/schoolDistricts/AssignmentPreview/YearsRegister/YearsRegister.types';

const getRange = (rangeStart, rangeEnd, trans): string => {
  if (rangeStart && !rangeEnd) {
    return ` ${trans('SCHOOL_DISTRICTS.DISTRICTS-LIST.ADDRESS-LIST.VALUES.RANGE-FROM', { rangeStart })}`;
  }

  if (!rangeStart && rangeEnd) {
    return ` ${trans('SCHOOL_DISTRICTS.DISTRICTS-LIST.ADDRESS-LIST.VALUES.RANGE-TO', { rangeEnd })}`;
  }

  if (rangeStart && rangeEnd) {
    return ` ${rangeStart}-${rangeEnd}`;
  }

  return '';
};

export const generateAddressRuleDescription = (
  { rangeStart, rangeEnd, rangeSelection, included, excluded }: AddressRule,
  trans: Translator,
) => {
  const rangeSelectionMessage = trans(
    `SCHOOL_DISTRICTS.DISTRICTS-LIST.ADDRESS-LIST.VALUES.RANGE-${rangeSelection.toUpperCase()}`,
  );

  const range = getRange(rangeStart, rangeEnd, trans);

  const includedMessage = included
    ? ` ${trans('SCHOOL_DISTRICTS.DISTRICTS-LIST.ADDRESS-LIST.VALUES.INCLUDED', { included })}`
    : '';

  const excludedMessage = excluded
    ? ` ${trans('SCHOOL_DISTRICTS.DISTRICTS-LIST.ADDRESS-LIST.VALUES.EXCLUDED', { excluded })}`
    : '';

  return `${rangeSelectionMessage}${range}${includedMessage}${excludedMessage}`;
};

export const generateAddressRuleInYearRegisterDescription = (
  { rangeStart, rangeEnd, rangeSelection, included, excluded, streetName, cityName }: SchoolAddressRule,
  trans: Translator,
) => {
  const rangeSelectionMessage = trans(
    `SCHOOL_DISTRICTS.DISTRICTS-LIST.ADDRESS-LIST.VALUES.LOWER-CASE.RANGE-${rangeSelection.toUpperCase()}`,
  );

  const range = getRange(rangeStart, rangeEnd, trans);

  const includedMessage = included
    ? ` ${trans('SCHOOL_DISTRICTS.DISTRICTS-LIST.ADDRESS-LIST.VALUES.INCLUDED', { included })}`
    : '';

  const excludedMessage = excluded
    ? ` ${trans('SCHOOL_DISTRICTS.DISTRICTS-LIST.ADDRESS-LIST.VALUES.EXCLUDED', { excluded })}`
    : '';

  return `${cityName}, ${streetName}, ${trans(
    'SCHOOL_DISTRICTS.DISTRICTS-LIST.ADDRESS-LIST.VALUES.NUMBERS',
  )} ${rangeSelectionMessage}${range}${includedMessage}${excludedMessage}`;
};

import { Search } from 'store/search/search.types';

export interface SearchState {
  list: Search[];
  loading: boolean;
  error: any;
}

export const defaultState: SearchState = {
  list: [],
  loading: false,
  error: null,
};

import { FullScreenLoaderProps } from './FullScreenLoader.types';
import { Loader } from 'components/shared/loaders/Loader/Loader';
import React, { FC } from 'react';

export const FullScreenLoader: FC<FullScreenLoaderProps> = ({ color = 'primary', ...props }) => {
  return (
    <div className="d-flex vh-100 vw-100 align-items-center justify-content-center">
      <Loader name={'spinner'} animation={'pulse'} size={'5x'} color={color} {...props} />
    </div>
  );
};

import { GroupedOptionType, OptionType } from 'components/shared/form/Autocomplete/Autocomplete.types';
import { TreeItem } from 'components/shared/form/TreeSelectBox/TreeSelectBox.types';
import { get, isObject } from 'lodash';

interface ToOptionsMapper {
  (dictionary: object[], key?: string, value?: string): OptionType[];
  (dictionary: number[]): OptionType[];
  (dictionary: string[]): OptionType[];
}

interface ToGroupedOptionsMapper {
  (dictionary: object[], group: string, key?: string, value?: string): GroupedOptionType[];
}

interface ToTreeOptionsMapper {
  (dictionary: object[], key: string, value: string, children: string): TreeItem[];
}

export const toOptions: ToOptionsMapper = (dictionary = [], key = 'id', value = 'name') => {
  return dictionary.map((item) => {
    if (isObject(item)) {
      return { value: get(item, key), label: get(item, value) };
    } else {
      return { value: item, label: item.toString() };
    }
  });
};

export const toGroupedOptions: ToGroupedOptionsMapper = (
  dictionary = [],
  key = 'id',
  value = 'name',
  group = 'group',
) => {
  return dictionary.map((item) => {
    return { value: get(item, key), label: get(item, value), group: get(item, group) };
  });
};

export const toTreeOptions: ToTreeOptionsMapper = (
  dictionary = [],
  key = 'id',
  value = 'name',
  children = 'children',
) => {
  return dictionary.map((item) => {
    return {
      value: get(item, key),
      label: get(item, value),
      children: toTreeOptions(get(item, children), key, value, children),
    };
  });
};

import { InputTextProps } from 'components/shared/form/InputText/InputText.types';

export const isSingleSelection = (value: OptionType | readonly OptionType[]): value is OptionType => {
  return (value as OptionType).value !== undefined;
};

export interface GroupedOptionType<T = any> extends OptionType<T> {
  group: string;
}

export interface OptionType<T = any> {
  label: string;
  value: T;
  disabled?: boolean;
  __isNew__?: boolean;
  options?: OptionType[];
}

export const isOptionGrouped = <T extends OptionType<V>, V = any>(
  item: T | GroupedOptionType<V>,
): item is GroupedOptionType<V> => {
  return (item as GroupedOptionType<V>).group !== undefined;
};

export interface AutocompleteProps<T extends OptionType> extends Omit<InputTextProps, 'onChange' | 'labelText'> {
  options: T[];
  displayedOptions?: T[];
  onChange?: (selection: T) => void;
  selectedLabel?: string;
  onInputChange?: (value: string) => void;
  menuIsOpen?: boolean;
  isLoading?: boolean;
  labelText?: string;
  alwaysShowPlaceholder?: boolean;
  findOption?: (T, fieldValue: any) => boolean;
  required?: boolean;
  createable?: boolean;
  onMenuOpen?: (ref?: any) => void;
  onMenuClose?: (ref?: any) => void;
  filterOption?: (values: { label: string; value: string; data: object }, inputValue) => boolean;
  newOptionMaxLength?: number;
  noOptionsMessage?: string;
  isMulti?: boolean;
  helpMessage?: string;
  isNewOptionValid?: (value?: string) => boolean;
  showNoOptionsMessage?: boolean;
  formatOptionLabel?: (optionValue: { value: string; label: string }, labelMeta?: any) => string;
  preventScrollToSelectedOption?: boolean;
}

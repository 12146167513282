import { muiStyles } from 'utils/design';

export const useStyles = muiStyles({
  townPicker: {
    '& > .container-fluid > .row > .col-3:last-of-type': {
      flex: '0 0 75%',
      maxWidth: '75%',
    },
  },
  communesList: {
    margin: '8px 0',
  },
});

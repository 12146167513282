import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StudentsListModal } from 'components/reports/OtherCommune/StudentsListModal/StudentsListModal';
import { otherCommuneStudentsSelector } from 'store/reports/reports.selector';
import { fetchOtherCommunesStudents } from 'store/reports/reports.action';
import { StudentsListModalContainerProps } from 'components/reports/OtherCommune/StudentsListModal/StudentsListModal.types';

export const StudentsListModalContainer: FC<StudentsListModalContainerProps> = ({
  open,
  onClose,
  unitId,
  communeId,
  communeName,
}) => {
  const dispatch: (...params: any) => Promise<any> = useDispatch();
  const { list, loading } = useSelector(otherCommuneStudentsSelector);

  useEffect(() => {
    if (open) {
      if (communeId) {
        dispatch(fetchOtherCommunesStudents(unitId, communeId));
      } else {
        dispatch(fetchOtherCommunesStudents(unitId, communeName));
      }
    }
  }, [dispatch, open, unitId, communeId, communeName]);

  return <StudentsListModal open={open} onClose={onClose} data={list} loading={loading} />;
};

import React, { useCallback, FC } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions } from '@material-ui/core';
import { useTranslator, useNotification } from 'components/shared/hooks';
import { deleteObligationAction } from 'store/childRegister';

export const DeleteObligationActions: FC<{
  open: boolean;
  onClose: () => void;
  childId: string | null;
  id: string;
}> = (props) => {
  const trans = useTranslator();
  const { success } = useNotification();
  const dispatch: (...params: any) => Promise<any> = useDispatch();
  const { onClose, childId, open, id } = props;
  const handleSubmit = useCallback(() => {
    if (childId) {
      return dispatch(deleteObligationAction(childId, id)).then(() => {
        success('CHILD_REGISTER.DELETE_OBLIGATION_ACTIONS.SUCCESS');
        onClose();
      });
    }
  }, [dispatch, childId, id, onClose, success]);

  return (
    <Dialog open={open}>
      <DialogTitle>{trans('CHILD_REGISTER.DELETE_OBLIGATION_ACTIONS.MODAL.TITLE')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{trans('CHILD_REGISTER.DELETE_OBLIGATION_ACTIONS.MODAL.TEXT')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="outlined">
          {trans('COMMON.CLOSE')}
        </Button>
        <Button onClick={handleSubmit} color="secondary" variant="contained">
          {trans('COMMON.DELETE')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import { YearsStatistic } from '@typings/common';
import { SchoolRangeSelection } from 'store/schoolDistricts';

export enum ChildrenYearsGroup {
  YOUNG = 'young',
  SCHOOL = 'school',
}

export interface YearRegisterColumnsParams {
  columns: string[];
  markedColumns: string[];
  markedCellClass: string;
}

export interface YearRegisterRow {
  id: string;
  name: string;
  isAlert: boolean;
  years: YearsStatistic;
  addressRules?: SchoolAddressRule[];
}

export interface SchoolAddressRule {
  years: YearsStatistic;
  catchmentChildren: number;
  id: string;
  cityName: string;
  streetId: number;
  streetName: string;
  rangeStart: number;
  rangeEnd: number;
  rangeSelection: SchoolRangeSelection;
  included: string;
  excluded: string;
}

export interface YearRegisterTreeRow extends YearRegisterRow {
  path: string[];
}

export interface YearsRegisterProps {
  data: YearRegisterRow[];
  youngBirthYears: string[];
  schoolBirthYears: string[];
}

export interface YearsRegisterFilter {
  years: string[];
}

import { AppDispatch } from '@typings/redux';
import { Editor } from 'components/polls/Editor/Editor';
import { EditContainerProps } from 'components/polls/Editor/Editor.types';
import { useFormatter, useNotification } from 'components/shared/hooks';
import { ROUTE_POLLS_LIST } from 'config/routes';
import { push, replace } from 'connected-react-router';
import { FormikHelpers } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPollDetails, pollDetailsSelector, PollForm, PollStatus, updatePoll } from 'store/polls';

export const EditContainer: FC<EditContainerProps> = ({ pollId }) => {
  const pollDetails = useSelector(pollDetailsSelector);
  const dispatch = useDispatch<AppDispatch>();
  const { formatApiErrors } = useFormatter();
  const { success, error } = useNotification();
  const [isSubmitted, setSubmitted] = useState(false);

  useEffect(() => {
    dispatch(fetchPollDetails(pollId));
  }, [pollId, dispatch]);

  const onSave = async (data: PollForm, formik: FormikHelpers<PollForm>) => {
    const result = await dispatch(updatePoll(pollId, data));

    if (result.error) {
      if (result.payload.response.errors) {
        error('POLLS.EDITOR.MESSAGE.POLL_SAVE_ERROR');
        formik.setErrors(formatApiErrors(result.payload.response.errors));
      }
      formik.setSubmitting(false);
    } else {
      success('POLLS.EDITOR.MESSAGE.POLL_SAVE_SUCCESS');
      setSubmitted(true);
      dispatch(push(ROUTE_POLLS_LIST));
    }
  };

  const onCancel = () => {
    dispatch(push(ROUTE_POLLS_LIST));
  };

  if (pollDetails.poll && pollDetails.poll.status !== PollStatus.DRAFT) {
    dispatch(replace(ROUTE_POLLS_LIST));
    error('POLLS.PUBLICATION.MESSAGE.PUBLISHED');
  }

  return (
    <Editor
      initial={pollDetails.poll}
      loading={pollDetails.loading}
      submitted={isSubmitted}
      onSave={onSave}
      onCancel={onCancel}
    />
  );
};

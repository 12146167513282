import { Tooltip } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Button } from 'components/shared/buttons/Button/Button';
import { IconButton } from 'components/shared/buttons/Button/IconButton';
import { useStyles } from 'components/shared/data/DataGrid/renderers/cell/Buttons/Buttons.styles';
import { ButtonDefinition, ButtonsProps } from 'components/shared/data/DataGrid/renderers/cell/Buttons/Buttons.types';
import { useFocusManager } from 'components/shared/data/DataGrid/renderers/cell/Buttons/useFocusManager';
import { useFormatter, useTranslator } from 'components/shared/hooks';
import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon';
import React, { FC, MouseEvent } from 'react';
import { BaseActionButton } from 'components/shared/buttons/ActionButton/BaseActionButton';
import classNames from 'classnames';
import { userOfficialRole, userOfficialPermissions } from 'store/user';
import { helpersCheckAccess, helperCheckAccessSameType } from 'components/shared/hooks';

export const ButtonsCellRenderer: FC<ButtonsProps> = (props) => {
  const trans = useTranslator();
  const formatter = useFormatter();
  const role = useSelector(userOfficialRole);
  const permissions = useSelector(userOfficialPermissions);
  const classes = useStyles();
  const { collectNode, collectActions } = useFocusManager(props.eGridCell);

  const renderIcon = (button: ButtonDefinition, index: number) => {
    const buttonTooltipParams = button.tooltipParams ? button.tooltipParams(trans) : {};
    const tooltip = button.tooltip ? trans(button.tooltip, buttonTooltipParams) : '';

    return (
      <Tooltip
        title={button.customTooltip ? button.customTooltip(trans) : tooltip}
        placement="top"
        key={index}
        classes={{ tooltip: classes.tooltip }}
      >
        <span className={classes.icon}>
          <FontAwesomeIcon name={button.icon} fixedWidth={true} fixedHeight={true} />
        </span>
      </Tooltip>
    );
  };

  const renderIconButton = (button: ButtonDefinition, index: number) => {
    const tooltip = button.tooltip ? trans(button.tooltip) : '';

    return (
      <Tooltip title={tooltip} placement="top" key={index}>
        <span>
          <IconButton
            color={button.type}
            onClick={() => button.action(props.data)}
            tabIndex={index}
            action={(actions) => collectActions(actions, index)}
            buttonRef={(node) => collectNode(node as HTMLElement, index)}
            disabled={button.disabled}
          >
            {<FontAwesomeIcon name={button.icon} fixedWidth={true} fixedHeight={true} />}
          </IconButton>
        </span>
      </Tooltip>
    );
  };

  const clickHandler = (event: MouseEvent, button: ButtonDefinition) => {
    event.stopPropagation();
    button.action(props.data);
  };

  const renderTextButton = (button: ButtonDefinition, index: number) => {
    const tooltip = button.tooltip ? trans(button.tooltip) : '';

    return (
      <Tooltip title={tooltip} placement="top" key={index}>
        <Button
          color={button.type}
          variant={button.variant || 'text'}
          size={button.size || 'medium'}
          onClick={(event) => clickHandler(event, button)}
          action={(actions) => collectActions(actions, index)}
          buttonRef={(node) => collectNode(node as HTMLElement, index)}
          disabled={button.disabled}
          className={classNames(button.className, classes.textButton)}
        >
          {button.icon && (
            <FontAwesomeIcon
              name={button.icon}
              fixedWidth={true}
              fixedHeight={true}
              className={classNames('mr-1', classes.gridIcon)}
            />
          )}
          {trans(button.label)}
        </Button>
      </Tooltip>
    );
  };

  const renderActionButton = (button: ButtonDefinition, index: number) => {
    const tooltip = button.tooltip ? trans(button.tooltip) : '';

    return (
      <Tooltip title={tooltip} placement="top" key={index}>
        <BaseActionButton
          color={button.type}
          label={button.label}
          variant={button.variant || 'text'}
          size={button.size || 'medium'}
          actionList={button.actionList}
          startingIndex={index}
          collectNode={(node, elementIndex) => collectNode(node as HTMLElement, elementIndex)}
          collectActions={(actions, elementIndex) => collectActions(actions, elementIndex)}
          className={classes.textButton}
          wrapperClassName={button.className}
        />
      </Tooltip>
    );
  };

  const renderButton = (button: ButtonDefinition, index: number) => {
    if (button.actionList) {
      return renderActionButton(button, index);
    } else {
      return button.label ? renderTextButton(button, index) : renderIconButton(button, index);
    }
  };

  const filterShow = (button) => {
    if (!button.canShow) return true;
    if (button.canShow.only) {
      const isAccess = helperCheckAccessSameType(button.canShow.resourcesGroup, button.canShow.accessType, permissions);
      return isAccess;
    }
    let isAccess = false;
    if (Array.isArray(button.canShow.resourcesGroup)) {
      isAccess = button.canShow.resourcesGroup.some((item) =>
        helpersCheckAccess(item, button.canShow.accessType, role, permissions),
      );
    } else {
      isAccess = helpersCheckAccess(button.canShow.resourcesGroup, button.canShow.accessType, role, permissions);
    }

    return isAccess;
  };

  const buttons = props.buttons(props.data).filter(filterShow);
  return (
    <div className={classNames(props.className, classes.wrapper)}>
      {buttons.filter((button) => !button.action).map((button, index) => renderIcon(button, index))}
      {buttons
        .filter((button) => !!button.action || !!button.actionList)
        .map((button, index) => renderButton(button, index))}
      {props.description && (
        <div className={classes.description}>{props.description(props.data, trans, formatter)}</div>
      )}
    </div>
  );
};

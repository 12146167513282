import {
  VoivodeshipItem,
  CountyItem,
  TerytCommuneItem,
  CommuneItem,
  TownItem,
  TerytTownItem,
  StreetItem,
} from './teryt.types';

export interface TerytState {
  voivodeships: {
    items: VoivodeshipItem[];
    loading: boolean;
  };
  counties: {
    items: CountyItem[];
    loading: boolean;
  };
  communes: {
    items: TerytCommuneItem[];
    loading: boolean;
  };
  towns: {
    items: TerytTownItem[];
    loading: boolean;
  };
  streets: {
    items: StreetItem[];
    loading: boolean;
  };
  town: {
    data: TownItem;
    loading: boolean;
  };
  commune: {
    data: CommuneItem;
    loading: boolean;
  };
}

export const defaultState: TerytState = {
  voivodeships: {
    items: [],
    loading: false,
  },
  counties: {
    items: [],
    loading: false,
  },
  communes: {
    items: [],
    loading: false,
  },
  towns: {
    items: [],
    loading: false,
  },
  streets: {
    items: [],
    loading: false,
  },
  town: {
    data: null,
    loading: false,
  },
  commune: {
    data: null,
    loading: false,
  },
};

import { AppDispatch } from '@typings/redux';
import { Browser } from 'components/officials/Browser/Browser';
import { useNotification } from 'components/shared/hooks';
import { useEnvironment } from 'components/shared/hooks/useEnvironment/useEnvironment';
import { ROUTE_OFFICIALS_ADD, ROUTE_OFFICIALS_EDIT, ROUTE_APPLICANTS_EDIT } from 'config/routes';
import { fromApiFormat } from 'utils/api/converters';
import { officialSettingsSelector } from 'store/user';
import { push } from 'connected-react-router';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteOfficial, resetPasswordOfficial, Official } from 'store/officials';
import { fetchUserList, formatUserList } from 'store/userList';
import { deleteApplicant, resetPasswordApplicant } from 'store/applicants';
import { useTranslator } from 'components/shared/hooks';
import { makePath } from 'utils/navigation/makePath';

export const BrowserContainer: React.FC = () => {
  const trans = useTranslator();
  const userList = useSelector(formatUserList);
  const loggedUserInformations = useSelector(officialSettingsSelector);
  const userListItems = useMemo(
    () =>
      userList.items.map((item) => {
        const formatPermissions = item.formatPermissions
          .map((permission) => trans(permission))
          .sort(new Intl.Collator('pl').compare)
          .join(', ');
        return {
          ...item,
          formatPermissions,
        };
      }),
    [userList.items, trans],
  );
  const dispatch = useDispatch<AppDispatch>();
  const { success, error } = useNotification();
  const { origin } = useEnvironment();

  const handleOfficialAdd = () => {
    dispatch(push(ROUTE_OFFICIALS_ADD));
  };

  const handleOfficialEdit = (official: Official) => {
    dispatch(push(makePath(ROUTE_OFFICIALS_EDIT, { id: official.id })));
  };

  const handleApplicantEdit = (official: Official) => {
    dispatch(push(makePath(ROUTE_APPLICANTS_EDIT, { id: official.id })));
  };

  const handleOfficialDelete = async (official: Official) => {
    const result = await dispatch(deleteOfficial(official.id, origin));
    if (result.error && result.payload.status === 404) {
      dispatch(fetchUserList());
      return;
    }
    if (result.error) {
      error('OFFICIALS.DELETE.ERROR');
      return;
    }
    dispatch(fetchUserList());
    success('OFFICIALS.DELETE.SUCCESS');
  };

  const handleApplicantDelete = async (official: Official) => {
    const result = await dispatch(deleteApplicant(official.id, origin));
    if (result.error && result.payload.status === 404) {
      dispatch(fetchUserList());
      return;
    }
    if (result.error) {
      error('OFFICIALS.DELETE.ERROR');
      return;
    }
    dispatch(fetchUserList());
    success('OFFICIALS.DELETE.SUCCESS');
  };

  const handleResetPassword = async (official: Official) => {
    const result = await dispatch(resetPasswordOfficial(official.id, origin));
    if (result.error) {
      error('OFFICIALS.RESET_PASSWORD.ERROR');
      return;
    }
    success('OFFICIALS.RESET_PASSWORD.SUCCESS');
    return fromApiFormat(result.payload);
  };

  const handleApplicantResetPassword = async (applicant: Official) => {
    const result = await dispatch(resetPasswordApplicant(applicant.id, origin));
    if (result.error) {
      error('OFFICIALS.RESET_PASSWORD.ERROR');
      return;
    }
    success('OFFICIALS.RESET_PASSWORD.SUCCESS');
    return fromApiFormat(result.payload);
  };

  React.useEffect(() => {
    dispatch(fetchUserList());
  }, [dispatch]);
  return (
    <Browser
      userList={userListItems}
      isLoading={userList.loading}
      onOfficialAdd={handleOfficialAdd}
      onOfficialDelete={handleOfficialDelete}
      onApplicantDelete={handleApplicantDelete}
      onOfficialEdit={handleOfficialEdit}
      onApplicantEdit={handleApplicantEdit}
      onOfficialResetPassword={handleResetPassword}
      onApplicantResetPassword={handleApplicantResetPassword}
      loggedUserInformations={loggedUserInformations}
    />
  );
};

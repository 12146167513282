import { muiStyles } from 'utils/design';

export const useStyles = muiStyles((theme) => ({
  grid: {
    flexGrow: 1,
    marginTop: theme.spacing(1),

    '& .error': {
      color: theme.palette.error.main,
    },

    '@media (max-width: 1500px)': {
      '& .ag-header, & .ag-header-container, & .ag-header-cell-text': {
        whiteSpace: 'unset',
      },
    },
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

import React, { FC, useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getImportToken, deleteStudentRegisterImport } from 'store/institutions';
import { __RouterContext } from 'react-router';
import { Import } from './Import';

export const ImportContainer: FC = () => {
  const dispatch: (...params: any) => Promise<any> = useDispatch();
  const importToken = useSelector(getImportToken);
  const router = useContext(__RouterContext);
  const id = (router.match.params as { id: string }).id;

  const deleteStudentRegisterImportCallback = useCallback(() => {
    return dispatch(deleteStudentRegisterImport(importToken, id));
  }, [dispatch, importToken, id]);

  return <Import onClose={deleteStudentRegisterImportCallback} token={importToken} />;
};

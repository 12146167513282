import { Action } from 'redux';
import { fork, ForkEffect, put, PutEffect, select, SelectEffect, takeLatest, takeEvery } from 'redux-saga/effects';
import { FSA } from '@typings/redux';
import { AppState } from '../store';
import {
  fetchInstitutionsDictionary,
  fetchInstitutionsList,
  fetchStudentRegisterSchoolYears,
  setInstitutionFilters,
  mapImportFields,
} from './institutions.action';
import { InstitutionsActionType } from './institutions.actionTypes';
import { InstitutionModel, InstitutionsFilters } from './institutions.types';
import { institutionsListFiltersSelector } from 'store/institutions/institutions.selectors';

const findValue = (fields: string[], value: string): string => fields.find((item) => item === value) || '';

const institutionSelectorByUnitId =
  (unitId: string) =>
  (state: AppState): InstitutionModel => {
    return state.institutions.list.items.find((institution) => {
      return institution.units.some((unit) => unit.id === unitId);
    });
  };

const hasSingleUnit = (institution: InstitutionModel): boolean => {
  return institution.units.length === 1;
};

export function* institutionDeleted(): IterableIterator<PutEffect | SelectEffect> {
  const filters: InstitutionsFilters = yield select(institutionsListFiltersSelector);

  if (filters.id) {
    // don't need to fetch table because we're updating institutionsListFiltersSelector
    yield put(setInstitutionFilters({ ...filters, id: undefined }));
  } else {
    yield put(fetchInstitutionsList(filters) as unknown as Action);
  }

  // we need to update filters options
  yield put(fetchInstitutionsDictionary() as unknown as Action);
  yield put(fetchStudentRegisterSchoolYears() as unknown as Action);
}

export function* unitDeleted(action: FSA): IterableIterator<PutEffect | SelectEffect> {
  const filters: InstitutionsFilters = yield select(institutionsListFiltersSelector);
  const institution: InstitutionModel = yield select(institutionSelectorByUnitId(action.meta.unitId));

  if (hasSingleUnit(institution)) {
    // we will also remove institution so lets refresh filters options
    yield put(fetchInstitutionsDictionary() as unknown as Action);
    yield put(fetchStudentRegisterSchoolYears() as unknown as Action);
  }

  if (filters.id && hasSingleUnit(institution)) {
    // don't need to fetch table because we're updating institutionsListFiltersSelector
    yield put(setInstitutionFilters({ ...filters, id: undefined }));
  } else {
    yield put(fetchInstitutionsList(filters) as unknown as Action);
  }
}

function* mappingImportFields(action: FSA<any, { fields: string[] }>): IterableIterator<PutEffect> {
  const fields = action.payload.fields;
  yield put(
    mapImportFields({
      firstName: findValue(fields, 'Imie'),
      lastName: findValue(fields, 'Nazwisko'),
      identificationNumber: findValue(fields, 'Nr identyfikacyjny'),
      level: findValue(fields, 'Poziom'),
      voivodeship: findValue(fields, 'Wojewodztwo'),
      city: findValue(fields, 'Miejscowosc'),
      cityId: findValue(fields, 'Teryt miejscowosci'),
      street: findValue(fields, 'Ulica'),
      buildingNumber: findValue(fields, 'Nr budynku'),
      flatNumber: findValue(fields, 'Nr lokalu'),
    }) as unknown as Action,
  );
}

export function* institutionSaga(): Iterator<ForkEffect> {
  yield fork(function* () {
    yield takeLatest(InstitutionsActionType.DELETE_INSTITUTION_SUCCESS, institutionDeleted);
    yield takeLatest(InstitutionsActionType.DELETE_UNIT_SUCCESS, unitDeleted);
    yield takeEvery(InstitutionsActionType.FETCH_STUDENT_REGISTER_IMPORT_COLUMNS_SUCCESS, mappingImportFields);
  });
}

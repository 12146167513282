import { createStyles } from '@material-ui/core';

export const styles = createStyles({
  '@global': {
    '.ag-theme-material.ag-popup > div': {
      boxShadow: 'none',
    },
  },
  container: {
    position: 'absolute',
    display: 'inline-block',
  },
  paper: {
    display: 'inline-block',
  },
});

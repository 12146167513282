import { DataGridProps } from 'components/shared/data/DataGrid/DataGrid.types';
import { getSelectedRowsForMasterNodes } from 'components/shared/data/DataGrid/utils/masterDetailsHelpers';

export const onMasterSelectionChange =
  <T extends object>(props: DataGridProps<T>) =>
  ({ api }) => {
    const nodes = api.getSelectedNodes();
    const rows = nodes.map((node) => node.data);

    props.onSelectionChange && props.onSelectionChange(rows, nodes, api);

    // For master-details if master changed we may have situation where for some rows selections
    // can't be propagated from master to details because details are not created yet.
    // So after propagation done above we are calling details selection change callback
    // with all the details rows that would be normally selected

    if (props.detailsColumns && props.onDetailsCombinedSelectionChange) {
      setTimeout(() => {
        const selectedDetailsRows = getSelectedRowsForMasterNodes(nodes, props.detailsMapping);
        props.onDetailsCombinedSelectionChange(selectedDetailsRows, api);
      });
    }
  };

export const onDetailsSelectionChanged =
  <T extends object>(props: DataGridProps<T>, masterGridApi) =>
  (event) => {
    const nodes = event.api.getSelectedNodes();
    const rows = nodes.map((node) => node.data);

    props.onDetailsSelectionChange && props.onDetailsSelectionChange(rows, nodes, event.api);

    const selectedMasterNodes = masterGridApi.getSelectedNodes();

    //see comment from onMasterSelectionChange
    if (props.detailsColumns && props.onDetailsCombinedSelectionChange) {
      setTimeout(() => {
        const selectedDetailsRows = getSelectedRowsForMasterNodes(selectedMasterNodes, props.detailsMapping);
        props.onDetailsCombinedSelectionChange(selectedDetailsRows, masterGridApi);
      });
    }
  };

import { NO_TERYT_ITEM_ID } from 'components/shared/teryt/TownPicker/TownPickerContainer';

export interface TerytResponse<T> {
  items: T[];
  metadata: {
    total: number;
    count: number;
  };
}

export interface VoivodeshipItem {
  id: string;
  name: string;
}

export interface CountyItem {
  id: string;
  name: string;
  type: CountyType;
}

export interface TerytCommuneItem {
  id: string;
  name: string;
  type: CommuneType;
  county: {
    id: string;
    name: string;
    type: CountyType;
    voivodeship: {
      id: string;
      name: string;
    };
  };
}

export interface ForeignCommuneItem {
  country: string;
  name: string;
}

export function isTerytCommune(item: CommuneItem): item is TerytCommuneItem {
  return (item as TerytCommuneItem).id !== null && (item as TerytCommuneItem).id !== undefined;
}

export type CommuneItem = TerytCommuneItem | ForeignCommuneItem;

export interface TerytTownItem {
  id: string;
  name: string;
  type: CityType;
  commune: {
    id: string;
    name: string;
    type: CommuneType;
    county: {
      id: string;
      name: string;
      type: CountyType;
      voivodeship: {
        id: string;
        name: string;
      };
    };
  };
}

export interface ForeignTownItem {
  country: string;
  name: string;
}

export interface LocalTownItem {
  id: typeof NO_TERYT_ITEM_ID;
  name: string;
  country?: string;
}

export type TownItem = TerytTownItem | LocalTownItem | ForeignTownItem;

export interface StreetItem {
  id: string;
  name: string;
  name2: string;
  prefix: string;
  fullName: string;
  city: {
    id: string;
    name: string;
    type: CityType;
  };
}

export function isTerytTown(item: TownItem): item is TerytTownItem {
  return (
    (item as TerytTownItem).id !== null &&
    (item as TerytTownItem).id !== undefined &&
    (item as TerytTownItem).id !== NO_TERYT_ITEM_ID
  );
}

export function isLocalTownItem(item: TownItem): item is LocalTownItem {
  return (item as LocalTownItem).id === NO_TERYT_ITEM_ID;
}

export function isForeignTownItem(item: TownItem): item is ForeignTownItem {
  const foreignItem = item as ForeignTownItem;
  return foreignItem['id'] === undefined && !!foreignItem.country;
}

export interface TerytFilters {
  search?: string;
  page?: number;
  limit?: number;
}

export interface CountyFilters extends TerytFilters {
  voivodeship?: string;
}

export interface CommuneFilters extends TerytFilters {
  county?: string;
  id?: string[];
}

export interface TownFilters extends TerytFilters {
  commune?: string;
  priorityCountyForCommune?: string;
  id?: string[];
}

export interface StreetFilters extends TerytFilters {
  city?: string;
  commune?: string;
  search?: string;
  communes?: string[];
  cities?: string[];
}

export enum CityType {
  CZESC = 'CZESC',
  WIES = 'WIES',
  KOLONIA = 'KOLONIA',
  PRZYSIOLEK = 'PRZYSIOLEK',
  OSADA = 'OSADA',
  OSADA_LESNA = 'OSADA_LESNA',
  OSIEDLE = 'OSIEDLE',
  SCHRONISKO_TURYSTYCZNE = 'SCHRONISKO_TURYSTYCZNE',
  DZIELNICA = 'DZIELNICA',
  MIASTO = 'MIASTO',
  DELEGATURA = 'DELEGATURA',
  CZESC_MIASTA = 'CZESC_MIASTA',
}

export enum CommuneType {
  GMINA_MIEJSKA = 'GMINA_MIEJSKA',
  GMINA_WIEJSKA = 'GMINA_WIEJSKA',
  MIASTO = 'MIASTO',
  OBSZAR_WIEJSKI = 'OBSZAR_WIEJSKI',
  DZIELNICA = 'DZIELNICA',
  DELEGATURA = 'DELEGATURA',
}

export enum CountyType {
  MIASTO_STOLECZNE_NA_PRAWACH_POWIATU = 'MIASTO_STOLECZNE_NA_PRAWACH_POWIATU',
  MIASTO_NA_PRAWACH_POWIATU = 'MIASTO_NA_PRAWACH_POWIATU',
  POWIAT = 'POWIAT',
}

export interface AddressModel {
  cityId: string | null;
  cityName: string | null;
  country: string;
  streetId: string | null;
  streetName: string | null;
  buildingNumber: string;
  apartmentNumber: string;
  postalCode: string;
}

export interface TerytComponentModel {
  city: {
    id: string | null;
    name: string | null;
    country: string;
  };
  street: {
    id: string | null;
    name: string | null;
  };
  buildingNumber: string;
  apartmentNumber: string;
  postalCode: string;
}

export interface AddressPostModel {
  city: {
    id: string | null;
    name: string | null;
    country: string | null;
  };
  country: string;
  street: {
    id: string | null;
    name: string | null;
  };
  buildingNumber: string;
  apartmentNumber: string;
  postalCode: string;
}

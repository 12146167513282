import { MenuItem } from 'components/shared/lists/NavigationList/NavigationList.types';
import {
  ROUTE_CHILD_REGISTER,
  ROUTE_INSTITUTIONS,
  ROUTE_OTHER_COMMUNE,
  ROUTE_POLLS,
  ROUTE_REPORTS_AUDIT_LOG,
  ROUTE_SCHOOL_DISTRICTS_CATCHMENT_ASSIGNMENT_LIST,
  ROUTE_SCHOOL_DISTRICTS_DATABASE,
  ROUTE_SCHOOL_DISTRICTS_LIST,
  ROUTE_SCHOOL_OBLIGATION_REPORTS,
  ROUTE_STATISTIC,
  ROUTE_TEMPLATES,
  ROUTE_SETTINGS_SYSTEM,
  ROUTE_SUBVENTION_FORECAST,
  ROUTE_OFFICIALS,
  ROUTE_SYNCHRONIZATIONS_REPORT,
  ROUTE_SEARCH,
  ROUTE_UNIT_LIST_IN_INSTITUTIONAL_CORE_GRANT,
} from 'config/routes';
import { clearChildRegisterFilters } from 'store/childRegister/childRegister.actions';
import { clearInstitutionFilters } from 'store/institutions';
import { fetchMessagesLink } from 'store/user/user.action';
import { clearReportsFilters } from 'store/schoolObligations';
import { trySessionDestroy } from 'store/auth/auth.action';
import { successNotification } from 'store/general';
import { OfficialPermissionLevel, OfficialResourceGroup } from 'store/officials';

const isRedirect = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const redirectFrom = urlSearchParams.get('redirect');
  return redirectFrom === 'panel-institutional-core-grant';
};

export const navigation: MenuItem[] = [
  {
    label: 'NAVIGATION.UNITS.HEADER',
    icon: 'university',
    resourcesGroups: [OfficialResourceGroup.childRegister, OfficialResourceGroup.institutionalCoreGrant],
    accessType: OfficialPermissionLevel.readOnly,
    children: [
      {
        path: ROUTE_INSTITUTIONS,
        label: 'NAVIGATION.UNITS.INSTITUTIONS-LIST',
        resourcesGroups: [OfficialResourceGroup.childRegister, OfficialResourceGroup.institutionalCoreGrant],
        accessType: OfficialPermissionLevel.readOnly,
        callback: (dispatch) => {
          dispatch(clearInstitutionFilters());
        },
      },
    ],
  },
  {
    path: ROUTE_CHILD_REGISTER,
    label: 'NAVIGATION.RECORDS_BOOK',
    icon: 'users',
    resourcesGroups: [OfficialResourceGroup.childRegister],
    accessType: OfficialPermissionLevel.readOnly,
    callback: (dispatch) => {
      dispatch(clearChildRegisterFilters());
    },
  },
  {
    label: 'NAVIGATION.SCHOOL_OBLIGATION.HEADER',
    icon: 'user-check',
    resourcesGroups: [OfficialResourceGroup.childRegister],
    accessType: OfficialPermissionLevel.readOnly,
    children: [
      {
        path: ROUTE_SCHOOL_OBLIGATION_REPORTS,
        label: 'NAVIGATION.SCHOOL_OBLIGATION.REPORTS',
        resourcesGroups: [OfficialResourceGroup.childRegister],
        accessType: OfficialPermissionLevel.readOnly,
        callback: (dispatch) => {
          dispatch(clearReportsFilters());
        },
      },
    ],
  },
  {
    label: 'NAVIGATION.SCHOOL_DISTRICTS.HEADER',
    icon: 'vector-square',
    resourcesGroups: [OfficialResourceGroup.childRegister],
    accessType: OfficialPermissionLevel.readOnly,
    children: [
      {
        path: ROUTE_SCHOOL_DISTRICTS_DATABASE,
        label: 'NAVIGATION.SCHOOL_DISTRICTS.DATABASE',
        resourcesGroups: [OfficialResourceGroup.childRegister],
        accessType: OfficialPermissionLevel.readOnly,
      },
      {
        path: ROUTE_SCHOOL_DISTRICTS_LIST,
        label: 'NAVIGATION.SCHOOL_DISTRICTS.DISTRICTS',
        resourcesGroups: [OfficialResourceGroup.childRegister],
        accessType: OfficialPermissionLevel.readOnly,
      },
      {
        path: ROUTE_SCHOOL_DISTRICTS_CATCHMENT_ASSIGNMENT_LIST,
        label: 'NAVIGATION.SCHOOL_DISTRICTS.CHILD_DIVISION',
        resourcesGroups: [OfficialResourceGroup.childRegister],
        accessType: OfficialPermissionLevel.readOnly,
      },
    ],
  },
  {
    label: 'NAVIGATION.TEMPLATES.HEADER',
    icon: 'file-signature',
    path: ROUTE_TEMPLATES,
    resourcesGroups: [OfficialResourceGroup.childRegister],
    accessType: OfficialPermissionLevel.readOnly,
  },
  {
    label: 'NAVIGATION.POLLS',
    icon: 'poll-h',
    path: ROUTE_POLLS,
    resourcesGroups: [OfficialResourceGroup.polls],
    accessType: OfficialPermissionLevel.readOnly,
  },
  {
    label: 'NAVIGATION.REPORTS.HEADER',
    icon: 'chart-line',
    resourcesGroups: [
      OfficialResourceGroup.statistics,
      OfficialResourceGroup.activitiesRegister,
      OfficialResourceGroup.finances,
    ],
    accessType: OfficialPermissionLevel.readOnly,
    children: [
      {
        path: ROUTE_STATISTIC,
        label: 'NAVIGATION.REPORTS.SCHOOL_STAT',
        resourcesGroups: [OfficialResourceGroup.statistics],
        accessType: OfficialPermissionLevel.readOnly,
      },
      {
        path: ROUTE_OTHER_COMMUNE,
        label: 'NAVIGATION.REPORTS.OTHER_COMMUNE',
        resourcesGroups: [OfficialResourceGroup.finances],
        accessType: OfficialPermissionLevel.readOnly,
      },
      {
        path: ROUTE_SUBVENTION_FORECAST,
        label: 'NAVIGATION.REPORTS.SUBVENTION_FORECAST',
        resourcesGroups: [OfficialResourceGroup.finances],
        accessType: OfficialPermissionLevel.readOnly,
      },
      {
        path: ROUTE_REPORTS_AUDIT_LOG,
        label: 'NAVIGATION.REPORTS.AUDIT_LOG',
        resourcesGroups: [OfficialResourceGroup.activitiesRegister],
        accessType: OfficialPermissionLevel.readOnly,
      },
      {
        path: ROUTE_SYNCHRONIZATIONS_REPORT,
        label: 'NAVIGATION.REPORTS.SYNCHRONIZATIONS',
        resourcesGroups: [OfficialResourceGroup.statistics],
        accessType: OfficialPermissionLevel.readOnly,
      },
    ],
  },
  {
    label: 'NAVIGATION.SEARCH',
    icon: 'search',
    path: ROUTE_SEARCH,
    resourcesGroups: [OfficialResourceGroup.childRegister],
    accessType: OfficialPermissionLevel.readOnly,
  },
  {
    label: 'NAVIGATION.OFFICIALS',
    icon: 'users-cog',
    path: ROUTE_OFFICIALS,
    resourcesGroups: [OfficialResourceGroup.onlyAdmin],
    hidden: (state) => state.user.userSettings.isExternalUser,
    accessType: OfficialPermissionLevel.fullAccess,
  },
  {
    label: 'NAVIGATION.MESSAGES',
    icon: 'comments',
    resourcesGroups: [OfficialResourceGroup.messages],
    accessType: OfficialPermissionLevel.fullAccess,
    callback: async (dispatch, { origin }) => {
      const result = await dispatch(fetchMessagesLink(origin));
      if (result.error) {
        await dispatch(trySessionDestroy());
        dispatch(successNotification('AUTHORIZATION.NOTIFICATIONS.SIGN_OUT_SUCCESS'));
      } else {
        window.open(result.payload.messages_link, '_blank');
      }
    },
  },
  {
    label: 'NAVIGATION.INSTITUTIONAL_CORE_GRANT',
    icon: 'coins',
    hidden: (state) => !state.user.departmentSettings.institutionalCoreGrantEnabled,
    callback: async () => {
      if (isRedirect()) {
        window.location.href = ROUTE_UNIT_LIST_IN_INSTITUTIONAL_CORE_GRANT;
      } else {
        window.location.href = `${window.location.origin}/institutional-core-grant`;
      }
    },
    resourcesGroups: [OfficialResourceGroup.institutionalCoreGrant],
    accessType: OfficialPermissionLevel.fullAccess,
  },
  {
    label: 'NAVIGATION.SETTINGS.HEADER',
    icon: 'cog',
    path: ROUTE_SETTINGS_SYSTEM,
    resourcesGroups: [OfficialResourceGroup.configurations, OfficialResourceGroup.polls],
    accessType: OfficialPermissionLevel.readOnly,
  },
];

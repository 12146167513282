import { ListItem, ListItemText } from '@material-ui/core';
import { push } from 'connected-react-router';
import { useContext } from 'react';
import * as React from 'react';
import { LocaleContext } from 'context/locale';
import { useDispatch } from 'react-redux';
import { useStyles } from './NavigationSubItem.styles';
import { NavigationSubItemProps } from './NavigationSubitem.types';
import classNames from 'classnames';
import { useMultipeCheckAccess } from 'components/shared/hooks/useCheckAccess/useCheckAccess';

export const NavigationSubItem: React.FC<NavigationSubItemProps> = ({ item, className = '' }) => {
  const { trans } = useContext(LocaleContext);
  const classes = useStyles();
  const dispatch = useDispatch();
  const showElement = useMultipeCheckAccess(item.resourcesGroups, item.accessType);
  if (!showElement) return null;

  const clickHandler = () => {
    if (item.callback) {
      item.callback(dispatch);
    }
    dispatch(push(item.path));
  };

  return (
    <ListItem button className={classNames(classes.submenuItem, className)} onClick={clickHandler}>
      <ListItemText primary={trans(item.label)} />
    </ListItem>
  );
};

import { AppAction, AppMiddleware, FSA } from '@typings/redux';
import { apiRequestsJournal, createJournalId } from './numberApiCalls.middleware';

const DROP_ACTION_TYPE = '@@takeLatest/DROP_ACTION';

const shouldHandleAction = (action: FSA) => {
  return action.meta && action.meta.takeLatest === true;
};

const isLastActionOfType = (action: FSA) => {
  const journalId = createJournalId(action.meta.request, action.meta.page);
  return apiRequestsJournal[journalId] === action.meta.uid;
};

const createDropAction = (action: FSA) => {
  const journalId = `${action.meta.request}@${action.meta.page || 0}`;

  return {
    type: DROP_ACTION_TYPE,
    meta: {
      droppedAction: action,
      succeedingAction: apiRequestsJournal[journalId],
    },
  };
};

export const takeLatestMiddleware: AppMiddleware = () => (next) => (action: AppAction) => {
  if (shouldHandleAction(action as FSA) && !isLastActionOfType(action as FSA)) {
    return next(createDropAction(action as FSA));
  }

  return next(action);
};

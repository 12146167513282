import { Action } from 'react-fetching-library';
import { Store } from 'redux';
import { expireDateSelector, refreshTokenSelector } from 'store/auth';
import { AppState } from 'store/store';
import { AppDispatch } from '@typings/redux';
import { refreshAccessToken } from 'store/auth/auth.action';

const timestampNow = () => {
  return Math.floor(+new Date() / 1000);
};

const isTokenExpired = (expires: number): boolean => {
  return expires ? expires - timestampNow() < 0 : true;
};

export const requestTokenRefresh =
  (host: string, store: Store<AppState>) =>
  () =>
  async (action: Action): Promise<Action> => {
    const dispatch = store.dispatch as AppDispatch;
    const expires = expireDateSelector(store.getState());
    const refreshToken = refreshTokenSelector(store.getState());

    if (isTokenExpired(expires)) {
      const endpoint = `${host}/token/refresh`;
      await dispatch(refreshAccessToken(refreshToken, endpoint));
    }

    return action;
  };

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { EditorEditContainer } from 'components/childRegister/Editor/EditorEditContainer';
import { useStyles } from 'components/shared/dialogs/ChildrenEditModal/ChildrenEditModal.styles';
import React from 'react';

interface EditChildrenModalProps {
  title: string;
  open: boolean;
  childrenId: string;
  onClose: (updated: boolean) => void;
  scrollTo?: { tab: string; elementId: string };
}

export const ChildrenEditModal = (props: EditChildrenModalProps) => {
  const classes = useStyles();

  return (
    <Dialog fullWidth maxWidth={'xl'} disableEscapeKeyDown open={props.open} PaperProps={{ className: 'vh-100' }}>
      <DialogTitle id="confirm-title">{props.title}</DialogTitle>
      <DialogContent className={classes.container}>
        {props.childrenId && (
          <EditorEditContainer
            id={props.childrenId}
            goBack={props.onClose}
            showGuardiansHelp={true}
            scrollTo={props.scrollTo}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

import { isGroupingHeader, TableHeader } from 'components/shared/data/DataGrid/DataGrid.types';

export const getHeaderMaxLevel = <D>(columns: TableHeader<D>[]): number => {
  return columns
    .map((column) => {
      if (isGroupingHeader(column)) {
        return getHeaderMaxLevel(column.children) + 1;
      } else {
        return 1;
      }
    })
    .reduce((maxLevel, current) => {
      return Math.max(maxLevel, current);
    }, 0);
};

import { useTimeoutSwitch } from 'components/shared/hooks/useTimeout/useTimeoutSwitch';
import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import React, { FC } from 'react';

interface NoRowsOverlayProps {
  noResultsMessage: string;
  reactContainer: HTMLElement;
}

export const NoRowsOverlay: FC<NoRowsOverlayProps> = (props) => {
  const isVisible = useTimeoutSwitch(50);

  const messageRender = () => (
    <Paper innerSpacing={2} className={'no-rows-overlay'}>
      <FontAwesomeIcon name={'info-circle'} className={'mr-2'} />
      {props.noResultsMessage}
    </Paper>
  );

  return isVisible ? messageRender() : null;
};

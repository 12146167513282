import { FSA } from '@typings/redux';
import { limits } from 'config/pagination';
import { RSAA, RSAACreator } from 'redux-api-middleware';
import { ReportsActionType } from 'store/reports/reports.actionTypes';
import { SynchronizationLogFilters, SynchronizationLog } from 'store/reports/reports.types';

export const clearAuditLogData = (): FSA => ({
  type: ReportsActionType.CLEAR_AUDIT_LOG_DATA,
});

export const fetchAuditLog: RSAACreator<ReportsActionType> = (page: number | string) => {
  return {
    [RSAA]: {
      endpoint: 'audit-log',
      method: 'GET',
      types: [
        ReportsActionType.FETCH_AUDIT_LOG_REQUEST,
        {
          type: ReportsActionType.FETCH_AUDIT_LOG_SUCCESS,
          meta: { page },
        },
        ReportsActionType.FETCH_AUDIT_LOG_FAILURE,
      ],
      params: {
        page,
        limit: limits.auditLog,
      },
    },
  };
};

export const fetchSchoolStat: RSAACreator<ReportsActionType> = () => {
  return {
    [RSAA]: {
      endpoint: 'stats',
      method: 'GET',
      types: [
        ReportsActionType.FETCH_SCHOOL_STAT_REQUEST,
        ReportsActionType.FETCH_SCHOOL_STAT_SUCCESS,
        ReportsActionType.FETCH_SCHOOL_STAT_FAILURE,
      ],
    },
  };
};

export const fetchSchoolStatDetails: RSAACreator<ReportsActionType> = (id: string) => {
  return {
    [RSAA]: {
      endpoint: `/stats/${id}`,
      method: 'GET',
      types: [
        ReportsActionType.FETCH_SCHOOL_STAT_DETAILS_REQUEST,
        ReportsActionType.FETCH_SCHOOL_STAT_DETAILS_SUCCESS,
        {
          type: ReportsActionType.FETCH_SCHOOL_STAT_DETAILS_FAILURE,
          meta: {
            ignoreNotFoundError: true,
          },
        },
      ],
    },
  };
};

export const fetchOtherCommunesStats: RSAACreator<ReportsActionType> = () => {
  return {
    [RSAA]: {
      endpoint: `reports/external-students`,
      method: 'GET',
      types: [
        ReportsActionType.FETCH_OTHER_COMMUNES_STATS_REQUEST,
        ReportsActionType.FETCH_OTHER_COMMUNES_STATS_SUCCESS,
        ReportsActionType.FETCH_OTHER_COMMUNES_STATS_FAILURE,
      ],
    },
  };
};

export const fetchOtherCommunesStudents: RSAACreator<ReportsActionType> = (unitId: string, communeId: string) => {
  return {
    [RSAA]: {
      endpoint: `reports/external-students/${unitId}/${communeId}`,
      method: 'GET',
      types: [
        ReportsActionType.FETCH_OTHER_COMMUNES_STUDENTS_REQUEST,
        ReportsActionType.FETCH_OTHER_COMMUNES_STUDENTS_SUCCESS,
        ReportsActionType.FETCH_OTHER_COMMUNES_STUDENTS_FAILURE,
      ],
    },
  };
};

export const clearOtherCommunesStudentsList = () => {
  return {
    type: ReportsActionType.CLEAR_OTHER_COMMUNES_STUDENTS,
  };
};

export const fetchSubventionFactors: RSAACreator<ReportsActionType> = () => {
  return {
    [RSAA]: {
      endpoint: `subvention-factors`,
      method: 'GET',
      types: [
        ReportsActionType.FETCH_SUBVENTION_FACTORS_REQUEST,
        ReportsActionType.FETCH_SUBVENTION_FACTORS_SUCCESS,
        {
          type: ReportsActionType.FETCH_SUBVENTION_FACTORS_FAILURE,
          meta: { ignoreNotFoundError: true },
        },
      ],
    },
  };
};

export const fetchSynchronizationLog: RSAACreator<ReportsActionType> = (
  page: number,
  filters: SynchronizationLogFilters,
) => {
  return {
    [RSAA]: {
      endpoint: 'sync-log',
      method: 'GET',
      types: [
        {
          type: ReportsActionType.FETCH_SYNCHRONIZATION_LOG_REQUEST,
          meta: { page, takeLatest: true },
        },
        {
          type: ReportsActionType.FETCH_SYNCHRONIZATION_LOG_SUCCESS,
          meta: { page },
        },
        ReportsActionType.FETCH_SYNCHRONIZATION_LOG_FAILURE,
      ],
      params: {
        limit: limits.synchronizationsLog,
        ...filters,
      },
    },
  };
};

export const setSynchronizationLogFilters = (filters: SynchronizationLogFilters): FSA<ReportsActionType> => {
  return {
    type: ReportsActionType.SET_SYNCHRONIZATION_LOG_FILTERS,
    payload: filters,
  };
};

export const getSynchronizationLog = (page: number, filters: SynchronizationLogFilters): FSA<ReportsActionType> => {
  return {
    type: ReportsActionType.GET_SYNCHRONIZATION_LOG,
    payload: {
      page,
      filters,
    },
  };
};

export const setSynchronizationLogLastItem = (item: SynchronizationLog): FSA<ReportsActionType> => {
  return {
    type: ReportsActionType.SET_SYNCHRONIZATION_LOG_LAST_ITEM,
    payload: item,
  };
};

export const clearSynchronizationLogData = (): FSA => ({
  type: ReportsActionType.CLEAR_SYNCHRONIZATION_LOG_DATA,
});

import React from 'react';
import { VictoryLabel } from 'victory';

export const NegativeAwareTickLabel = (props) => {
  const { datum, y, scale, ...rest } = props;

  const isNegative = datum.y < 0;

  return (
    <VictoryLabel
      datum={datum}
      y={isNegative ? scale.y(0) : y}
      dy={isNegative ? 20 * Math.sign(datum.y) + 10 : -1}
      {...rest}
    />
  );
};

import { UnitPostModel, UnitModel } from 'store/institutions/institutions.types';
import { UnitFormValues } from 'components/institution/Unit/Editor/Editor.types';
import { provideString } from 'utils/string';
import { isSchoolDictionaryItem } from 'store/general';
import { NO_TERYT_ITEM_ID } from 'components/shared/teryt/TownPicker/TownPickerContainer';

export const mapFormValueToPayload = (values: UnitFormValues): UnitPostModel => {
  const regon = values.regon.toString();
  const payload: UnitPostModel = {
    email: values.email,
    phone: values.phone,
    fax: values.fax,
    www: values.www,
    name: values.name,
    institution: {},
    educationStage: values.educationStage,
    obligationType: values.obligationType,
    accreditation: values.accreditation,
    schoolCharacter: values.schoolCharacter,
    studentCategory: values.studentCategory,
    schoolType: values.schoolType,
    schoolSubtype: values.schoolSubtype,
    catchmentSchool: values.catchmentSchool,
    educationForm: values.educationForm,
    organizationalStructure: values.organizationalStructure,
    rspo: values.rspo,
    oke: values.oke,
    regon: regon.length === 0 ? null : regon,
    nip: values.nip.toString(),
    address: {
      city: { id: null, name: null, country: null },
      country: values.address.city.country ? values.address.city.country : 'PL',
      street: { id: null, name: null },
      buildingNumber: provideString(values.address.buildingNumber),
      apartmentNumber: provideString(values.address.apartmentNumber),
      postalCode: provideString(values.address.postalCode),
    },
  };

  if (values.chooseInstitution === 'chooseExisting') {
    payload.institution.id = values.institution.id;
  } else {
    payload.institution.name = values.institution.name;
  }

  if (values.address.city.id && values.address.city.id !== NO_TERYT_ITEM_ID) {
    payload.address.city.id = values.address.city.id;
  } else {
    payload.address.city.name = provideString(values.address.city.name);
  }

  if (values.address.street.id && values.address.street.id !== NO_TERYT_ITEM_ID) {
    payload.address.street.id = values.address.street.id;
  } else {
    payload.address.street.name = provideString(values.address.street.name);
  }
  return payload;
};

export const mapUnitModelToForm = (unit: UnitModel, schoolTypesList) => ({
  chooseInstitution: 'chooseExisting',
  institution: {
    id: unit.institution.id,
    name: '',
  },
  name: provideString(unit.name),
  email: provideString(unit.email),
  fax: provideString(unit.fax),
  phone: provideString(unit.phone),
  www: provideString(unit.www),
  educationStage: unit.educationStage.id,
  obligationType: unit.obligationType.id,
  accreditation: unit.accreditation && unit.accreditation.id,
  schoolCharacter: unit.schoolCharacter && unit.schoolCharacter.id,
  studentCategory: unit.studentCategory && unit.studentCategory.id,
  schoolSubtype: unit.schoolSubtype.id,
  educationForm: unit.educationForm?.id ? unit.educationForm.id : null,
  organizationalStructure: unit.organizationalStructure?.id ? unit.organizationalStructure.id : null,
  schoolType: schoolTypesList.find(
    (school) =>
      isSchoolDictionaryItem(school) &&
      school.schoolSubtype.findIndex((subtype) => subtype.id === unit.schoolSubtype.id) > -1,
  ).id,
  catchmentSchool: unit.catchmentSchool,
  rspo: provideString(unit.rspo),
  oke: provideString(unit.oke),
  regon: provideString(unit.regon),
  nip: provideString(unit.nip),
  address: {
    city: {
      id: unit.address.cityId || NO_TERYT_ITEM_ID,
      name: unit.address.cityName ? unit.address.cityName : null,
      country: unit.address.country ? unit.address.country : 'PL',
    },
    street: {
      id: unit.address.streetId || NO_TERYT_ITEM_ID,
      name: unit.address.streetName ? unit.address.streetName : null,
    },
    buildingNumber: unit.address.buildingNumber,
    apartmentNumber: unit.address.apartmentNumber,
    postalCode: unit.address.postalCode,
  },
});

import { muiStyles } from 'utils/design';

export const useStyles = muiStyles((theme) => ({
  formControl: {
    width: 300,
    minWidth: '100%',
    maxWidth: '100%',
    display: 'flex',
  },
  displayEmpty: {
    marginTop: theme.spacing(2),
  },
  reset: {
    position: 'absolute',
    right: '26px',
    fontSize: '0.8em',
    color: theme.palette.grey['600'],
    cursor: 'pointer',
    lineHeight: '32px',
  },
  tooltipIcon: {
    position: 'absolute',
    right: '26px',
  },
  label: {
    minWidth: 300,
  },
  groupedItem: {
    padding: theme.spacing(0, 2, 0, 4),
  },
  selectBox: {
    width: '60%',

    '&:last-of-type': {
      width: '35%',
    },
  },
  dayMonthPicker: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: theme.spacing(2),
  },
}));

import React, { useCallback, FC } from 'react';
import { useDispatch } from 'react-redux';
import { FormikHelpers } from 'formik';
import format from 'date-fns/format';
import formatISO from 'date-fns/formatISO';
import parse from 'date-fns/parse';
import { Dialog, DialogTitle } from '@material-ui/core';
import { useTranslator, useFormatter, useNotification } from 'components/shared/hooks';
import { Editor } from 'components/childRegister/Editor/ObligationActions/Editor/Editor';
import { ObligationActionForm } from 'components/childRegister/Editor/ObligationActions/ObligationActions.types';
import { putObligationAction, ObligationAction } from 'store/childRegister';

export const EditObligationActions: FC<{
  open: boolean;
  onClose: () => void;
  childId: string | null;
  values?: ObligationAction;
}> = (props) => {
  const trans = useTranslator();
  const { formatTime, formatApiErrors } = useFormatter();
  const { success } = useNotification();
  const dispatch: (...params: any) => Promise<any> = useDispatch();
  const { childId, open, values: obligationAction, onClose } = props;
  const handleSubmit = useCallback(
    (values: ObligationActionForm, formik: FormikHelpers<ObligationActionForm>) => {
      const date = parse(values.date, 'yyyy-MM-dd', new Date());
      const actionAt = formatISO(parse(values.time, 'HH:mm', date));
      if (childId) {
        return dispatch(putObligationAction({ ...obligationAction, ...values, actionAt }, childId)).then((result) => {
          if (result.error) {
            if (result.payload.response.errors) {
              formik.setErrors(formatApiErrors(result.payload.response.errors));
            }
            formik.setSubmitting(false);
          } else {
            success('CHILD_REGISTER.EDIT_OBLIGATION_ACTIONS.SUCCESS');
            onClose();
          }
        });
      }
    },
    [dispatch, childId, formatApiErrors, obligationAction, onClose, success],
  );
  const initialValues = obligationAction
    ? {
        ...obligationAction,
        date: format(new Date(obligationAction.actionAt), 'yyyy-MM-dd'),
        time: formatTime(obligationAction.actionAt),
      }
    : {};

  return (
    <Dialog open={open}>
      <DialogTitle>{trans('CHILD_REGISTER.EDIT_OBLIGATION_ACTIONS.MODAL.TITLE')}</DialogTitle>
      <Editor initialValues={initialValues as ObligationActionForm} onSubmit={handleSubmit} onClose={onClose} />
    </Dialog>
  );
};

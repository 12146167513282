export enum PollsActionType {
  SET_FILTERS = 'polls/SET_FILTERS',

  FETCH_POLLS_LIST_REQUEST = 'polls/FETCH_POLLS_LIST_REQUEST',
  FETCH_POLLS_LIST_SUCCESS = 'polls/FETCH_POLLS_LIST_SUCCESS',
  FETCH_POLLS_LIST_FAILURE = 'polls/FETCH_POLLS_LIST_FAILURE',

  FETCH_POLL_DETAILS_REQUEST = 'polls/FETCH_POLL_DETAILS_REQUEST',
  FETCH_POLL_DETAILS_SUCCESS = 'polls/FETCH_POLL_DETAILS_SUCCESS',
  FETCH_POLL_DETAILS_FAILURE = 'polls/FETCH_POLL_DETAILS_FAILURE',

  CREATE_POLL_DETAILS_REQUEST = 'polls/CREATE_POLL_DETAILS_REQUEST',
  CREATE_POLL_DETAILS_SUCCESS = 'polls/CREATE_POLL_DETAILS_SUCCESS',
  CREATE_POLL_DETAILS_FAILURE = 'polls/CREATE_POLL_DETAILS_FAILURE',

  CLONE_POLL_DETAILS_REQUEST = 'polls/CLONE_POLL_DETAILS_REQUEST',
  CLONE_POLL_DETAILS_SUCCESS = 'polls/CLONE_POLL_DETAILS_SUCCESS',
  CLONE_POLL_DETAILS_FAILURE = 'polls/CLONE_POLL_DETAILS_FAILURE',

  UPDATE_POLL_DETAILS_REQUEST = 'polls/UPDATE_POLL_DETAILS_REQUEST',
  UPDATE_POLL_DETAILS_SUCCESS = 'polls/UPDATE_POLL_DETAILS_SUCCESS',
  UPDATE_POLL_DETAILS_FAILURE = 'polls/UPDATE_POLL_DETAILS_FAILURE',

  CLOSE_POLL_REQUEST = 'polls/CLOSE_POLL_REQUEST',
  CLOSE_POLL_SUCCESS = 'polls/CLOSE_POLL_SUCCESS',
  CLOSE_POLL_FAILURE = 'polls/CLOSE_POLL_FAILURE',

  FETCH_SYNC_INSTITUTIONS_REQUEST = 'polls/FETCH_SYNC_INSTITUTIONS_REQUEST',
  FETCH_SYNC_INSTITUTIONS_SUCCESS = 'polls/FETCH_SYNC_INSTITUTIONS_SUCCESS',
  FETCH_SYNC_INSTITUTIONS_FAILURE = 'polls/FETCH_SYNC_INSTITUTIONS_FAILURE',

  FETCH_RECIPIENTS_GROUPS_REQUEST = 'polls/FETCH_RECIPIENTS_GROUPS_REQUEST',
  FETCH_RECIPIENTS_GROUPS_SUCCESS = 'polls/FETCH_RECIPIENTS_GROUPS_SUCCESS',
  FETCH_RECIPIENTS_GROUPS_FAILURE = 'polls/FETCH_RECIPIENTS_GROUPS_FAILURE',

  FETCH_RECIPIENTS_COUNTS_REQUEST = 'polls/FETCH_RECIPIENTS_COUNTS_REQUEST',
  FETCH_RECIPIENTS_COUNTS_SUCCESS = 'polls/FETCH_RECIPIENTS_COUNTS_SUCCESS',
  FETCH_RECIPIENTS_COUNTS_FAILURE = 'polls/FETCH_RECIPIENTS_COUNTS_FAILURE',

  CLEAR_RECIPIENTS_COUNTS = 'polls/CLEAR_RECIPIENTS_COUNTS',

  DELETE_POLL_REQUEST = 'polls/DELETE_POLL_REQUEST',
  DELETE_POLL_SUCCESS = 'polls/DELETE_POLL_SUCCESS',
  DELETE_POLL_FAILURE = 'polls/DELETE_POLL_FAILURE',

  PUBLISH_POLL_REQUEST = 'polls/PUBLISH_POLL_REQUEST',
  PUBLISH_POLL_SUCCESS = 'polls/PUBLISH_POLL_SUCCESS',
  PUBLISH_POLL_FAILURE = 'polls/PUBLISH_POLL_FAILURE',

  FETCH_POLL_RESULTS_REQUEST = 'polls/FETCH_POLL_RESULTS_REQUEST',
  FETCH_POLL_RESULTS_SUCCESS = 'polls/FETCH_POLL_RESULTS_SUCCESS',
  FETCH_POLL_RESULTS_FAILURE = 'polls/FETCH_POLL_RESULTS_FAILURE',

  FETCH_POLL_RESPONSE_REQUEST = 'polls/FETCH_POLL_RESPONSE_REQUEST',
  FETCH_POLL_RESPONSE_SUCCESS = 'polls/FETCH_POLL_RESPONSE_SUCCESS',
  FETCH_POLL_RESPONSE_FAILURE = 'polls/FETCH_POLL_RESPONSE_FAILURE',

  CLEAR_RESPONSES = 'polls/CLEAR_RESPONSES',

  FETCH_POLL_RECIPIENTS_REQUEST = 'polls/FETCH_POLL_RECIPIENTS_REQUEST',
  FETCH_POLL_RECIPIENTS_SUCCESS = 'polls/FETCH_POLL_RECIPIENTS_SUCCESS',
  FETCH_POLL_RECIPIENTS_FAILURE = 'polls/FETCH_POLL_RECIPIENTS_FAILURE',

  FETCH_QUESTIONNAIRE_REQUEST = 'polls/FETCH_QUESTIONNAIRE_REQUEST',
  FETCH_QUESTIONNAIRE_SUCCESS = 'polls/FETCH_QUESTIONNAIRE_SUCCESS',
  FETCH_QUESTIONNAIRE_FAILURE = 'polls/FETCH_QUESTIONNAIRE_FAILURE',

  SAVE_RESPONSE_REQUEST = 'polls/SAVE_RESPONSE_REQUEST',
  SAVE_RESPONSE_SUCCESS = 'polls/SAVE_RESPONSE_SUCCESS',
  SAVE_RESPONSE_FAILURE = 'polls/SAVE_RESPONSE_FAILURE',

  DOWNLOAD_POLL_REQUEST = 'polls/DOWNLOAD_POLL_REQUEST',
  DOWNLOAD_POLL_SUCCESS = 'polls/DOWNLOAD_POLL_SUCCESS',
  DOWNLOAD_POLL_FAILURE = 'polls/DOWNLOAD_POLL_FAILURE',
}

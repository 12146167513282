import React, { FC, useContext, useMemo, useCallback } from 'react';
import { LocationModal } from './LocationModal';
import { LocationModalContainerProps } from './LocationModal.types';
import countries from 'i18n-iso-countries';
import { LocaleContext } from 'context/locale';
import {
  fetchVoivodeshipsList,
  fetchCountiesList,
  fetchCommunesList,
  fetchTownsList,
  getVoivodeshipsList,
  getCountiesList,
  getCommunesList,
  getTownsList,
  isVoivodeshipsListLoadingSelector,
  isCountiesListLoadingSelector,
  isCommunesListLoadingSelector,
  isTownsListLoadingSelector,
  isTerytTown,
} from 'store/teryt';
import { useDispatch, useSelector } from 'react-redux';
import { toOptions } from 'utils/api';
import { formatCommuneName, formatTownName, formatCountyName } from '../TownPicker.helpers';

const initialValues = {
  country: null,
  voivodeship: null,
  county: null,
  commune: null,
  town: null,
  foreignTown: '',
};

export const LocationModalContainer: FC<LocationModalContainerProps> = ({
  open,
  onClose,
  onSubmit,
  town,
  country,
  allowEmptyCountry,
}) => {
  const { trans, locale } = useContext(LocaleContext);
  const dispatch: (...params: any) => Promise<any> = useDispatch();

  const fetchVoivodeshipsListCallback = useCallback((filters) => dispatch(fetchVoivodeshipsList(filters)), [dispatch]);
  const fetchCountiesListCallback = useCallback((filters) => dispatch(fetchCountiesList(filters)), [dispatch]);
  const fetchCommunesListCallback = useCallback((filters) => dispatch(fetchCommunesList(filters)), [dispatch]);
  const fetchTownsListCallback = useCallback((filters) => dispatch(fetchTownsList(filters)), [dispatch]);

  const isVoivodeshipsListLoading = useSelector(isVoivodeshipsListLoadingSelector);
  const isCountiesListLoading = useSelector(isCountiesListLoadingSelector);
  const isCommunesListLoading = useSelector(isCommunesListLoadingSelector);
  const isTownsListLoading = useSelector(isTownsListLoadingSelector);

  const voivodeshipsList = toOptions(useSelector(getVoivodeshipsList));

  const countiesList = useSelector(getCountiesList).map((county) => ({
    value: county.id,
    label: formatCountyName(county, trans),
  }));

  const communesList = useSelector(getCommunesList).map((commune) => ({
    value: commune.id,
    label: formatCommuneName(commune, trans),
  }));

  const townsList = useSelector(getTownsList).map((town) => ({
    value: town,
    label: formatTownName(town, trans),
  }));

  const countriesOptions = useMemo(() => {
    const countriesList = countries.getNames(locale);
    return Object.keys(countriesList)
      .map((value) => ({ label: countriesList[value], value: value }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [locale]);

  const getTownInitialValues = useMemo(() => {
    const defaultCountry = allowEmptyCountry ? null : 'PL';

    if (town && town.name) {
      if (isTerytTown(town)) {
        return {
          ...initialValues,
          voivodeship: town.commune.county.voivodeship.id,
          county: town.commune.county.id,
          commune: town.commune.id,
          town: town,
          country: country ? country : defaultCountry,
        };
      } else {
        return {
          ...initialValues,
          country: country ? country : defaultCountry,
          foreignTown: town.name,
        };
      }
    }

    return {
      ...initialValues,
      country: defaultCountry,
    };
  }, [allowEmptyCountry, country, town]);

  return (
    <LocationModal
      open={open}
      onClose={onClose}
      initialValues={getTownInitialValues}
      countriesList={countriesOptions}
      onSubmit={onSubmit}
      fetchVoivodeshipsList={fetchVoivodeshipsListCallback}
      fetchCountiesList={fetchCountiesListCallback}
      fetchCommunesList={fetchCommunesListCallback}
      fetchTownsList={fetchTownsListCallback}
      voivodeshipsList={voivodeshipsList}
      countiesList={countiesList}
      communesList={communesList}
      townsList={townsList}
      isVoivodeshipsListLoading={isVoivodeshipsListLoading}
      isCountiesListLoading={isCountiesListLoading}
      isCommunesListLoading={isCommunesListLoading}
      isTownsListLoading={isTownsListLoading}
      allowEmptyCountry={allowEmptyCountry}
    />
  );
};

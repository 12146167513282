import CssBaseline from '@material-ui/core/CssBaseline';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { AuthView } from 'views/Auth/Auth.view';
import { PanelView } from 'views/Panel/Panel.view';
import { ROUTE_AUTH, ROUTE_PANEL, ROUTE_QUESTIONNAIRES, ROUTE_ROOT } from 'config/routes';
import { QuestionnairesView } from 'views/Questionnaires/Questionnaires.view';
import { NotFoundView } from './Errors/NotFound.view';

export const MainView: React.FC = () => {
  return (
    <>
      <CssBaseline />
      <Switch>
        <Redirect path={ROUTE_ROOT} exact to={ROUTE_PANEL} />
        <Route path={ROUTE_QUESTIONNAIRES} component={QuestionnairesView} />
        <Route path={ROUTE_PANEL} component={PanelView} />
        <Route path={ROUTE_AUTH} component={AuthView} />
        <Route component={NotFoundView} />
      </Switch>
    </>
  );
};

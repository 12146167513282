import { important, muiStyles } from 'utils/design';

export const useStyles = muiStyles({
  input: {
    '&, &:hover, &:focus, &:active': {
      width: important(0),
      height: important(0),
      padding: important(0),
      margin: important(0),
      border: important('none'),
      outline: important('none'),
    },
  },
});

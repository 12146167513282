import { muiStyles } from 'utils/design';

export const useStyles = muiStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },

  grid: {
    '@media (max-width: 1500px)': {
      '& .ag-header, & .ag-header-container, & .ag-header-cell-text': {
        whiteSpace: 'unset',
      },
    },
  },
}));

import Popover from '@material-ui/core/Popover';
import { NavigationSubItem } from 'components/shared/lists/NavigationList/NavigationSubItem/NavigationSubItem';
import { FloatingSubmenuProps } from './FloatingSubmenu.types';
import { default as React, FC, useCallback } from 'react';

export const FloatingSubmenu: FC<FloatingSubmenuProps> = ({ items, open, anchor, onDismiss }) => {
  const keyDownHandler = useCallback(
    (event) => {
      if (['ArrowLeft', 'Escape'].includes(event.key)) {
        onDismiss && onDismiss();
      }
    },
    [onDismiss],
  );

  return (
    <Popover
      open={open}
      anchorEl={anchor}
      disableRestoreFocus
      elevation={3}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onKeyDown={keyDownHandler}
    >
      {items.map((item, index) => (
        <NavigationSubItem item={item} key={index} />
      ))}
    </Popover>
  );
};

import { ColumnsConfig } from 'components/shared/data/DataGrid/DataGrid.types';
import { SchoolStat } from 'store/reports/reports.types';
import { SchoolStatHandlers } from 'components/reports/SchoolStat/SchoolStat.types';

export const schoolStatColumns: ColumnsConfig<SchoolStat, SchoolStatHandlers> = (handlers) => [
  {
    field: 'name',
    name: 'REPORTS.SCHOOL_STAT.COLUMNS.NAME',
    columnParams: { minWidth: 300 },
  },
  {
    field: 'updateDate',
    name: 'REPORTS.SCHOOL_STAT.COLUMNS.DATE',
    columnParams: { minWidth: 300 },
    format: (data, trans, formatter) => {
      return data.updateDate ? formatter.formatDateWithTime(data.updateDate) : '-';
    },
  },
  {
    name: 'REPORTS.SCHOOL_STAT.COLUMNS.ACTION',
    cellClass: 'actions-cell',
    field: 'id',
    cellTooltip: () => '',
    renderer: 'buttonsCell',
    rendererParams: {
      buttons: (stat: SchoolStat) => {
        return [
          {
            type: 'default',
            size: 'small',
            variant: 'outlined',
            icon: 'eye',
            label: 'REPORTS.SCHOOL_STAT.ACTIONS.SHOW',
            tooltip: 'REPORTS.SCHOOL_STAT.ACTIONS.SHOW',
            action: () => handlers.show(stat.id),
          },
        ];
      },
    },
  },
];

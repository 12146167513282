/*eslint complexity: ["error", 14]*/
import { OptionType } from 'components/shared/form/Autocomplete/Autocomplete.types';
import { AutocompleteOptionValue } from 'components/shared/teryt/TownPicker/TownPicker.types';
import {
  TownItem,
  isTerytTown,
  TerytCommuneItem,
  TerytTownItem,
  CountyItem,
  isLocalTownItem,
  StreetItem,
} from 'store/teryt';
import countries from 'i18n-iso-countries';
import { Translator } from 'context/locale/LocaleContext/LocaleContext.types';

export const formatCountyName = (county: CountyItem, trans: Translator) =>
  `${county.name} (${trans(`TERYT.COUNTY.TYPE.${county.type}`)})`;

export const formatCommuneName = (commune: TerytCommuneItem, trans: Translator) =>
  `${commune.name} (${trans(`TERYT.COMMUNE.TYPE.${commune.type}`)})`;

export const formatTownName = (town: TerytTownItem, trans: Translator) =>
  `${town.name} (${trans(`TERYT.TOWN.TYPE.${town.type}`)})`;

export const formatTownSummary = (town: TownItem, locale: string, trans: Translator) => {
  if (isTerytTown(town)) {
    if (town.name && town.commune) {
      return `${countries.getName('PL', locale)} - ${town.commune.county.voivodeship.name} - ${formatCountyName(
        town.commune.county,
        trans,
      )} - ${formatCommuneName(town.commune, trans)} - ${formatTownName(town, trans)}`;
    } else if (town.name) {
      return town.name;
    } else {
      return trans('TERYT.TOWN.DEFAULT');
    }
  } else if (isLocalTownItem(town)) {
    return town.country === 'PL' || !town.country
      ? town.name
      : `${countries.getName(town.country, locale)} - ${town.name}`;
  } else {
    if (town.country && town.name) {
      return `${countries.getName(town.country, locale)} - ${town.name}`;
    } else if (town.country) {
      return countries.getName(town.country, locale);
    } else if (town.name) {
      return town.name;
    } else {
      return trans('TERYT.TOWN.DEFAULT');
    }
  }
};

export const formatTownPath = (town: TerytTownItem, trans: Translator) => {
  if (!town.commune) {
    return town.name;
  }

  return `${formatTownName(town, trans)} / ${formatCommuneName(town.commune, trans)} / ${formatCountyName(
    town.commune.county,
    trans,
  )} / ${town.commune.county.voivodeship.name}`;
};

export const townsListIncludes = (townsList: AutocompleteOptionValue[], town: TownItem): boolean => {
  return town && townsList.some((item) => item.value === town['id']);
};

export const streetListIncludes = (streetsList: OptionType[], street: Partial<StreetItem>): boolean => {
  return street && streetsList.some((item) => item.value === street['id']);
};

export const reverseFormatTownSummary = (town: TownItem, locale: string, trans: Translator) => {
  if (isTerytTown(town)) {
    if (town.name && town.commune) {
      return `${formatTownName(town, trans)} - ${formatCommuneName(town.commune, trans)} - ${formatCountyName(
        town.commune.county,
        trans,
      )} - ${town.commune.county.voivodeship.name} - ${countries.getName('PL', locale)}`;
    } else if (town.name) {
      return town.name;
    } else {
      return trans('TERYT.TOWN.DEFAULT');
    }
  }
};

import React, { FC, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { UnitFormValues } from 'components/institution/Unit/Editor/Editor.types';
import { fetchInstitutionsDictionary, postUnit } from 'store/institutions/institutions.action';
import { mapFormValueToPayload } from 'components/institution/Unit/Editor/editorHelpers';
import { getInstitutionsListWithoutSynchronized, isIstitutionsListLoading } from 'store/institutions';
import { Editor } from 'components/institution/Unit/Editor/Editor';

interface EditContainerProps {
  goBack: () => void;
}

const formInitialValues = {
  chooseInstitution: 'chooseExisting',
  institution: {
    id: '',
    name: '',
  },
  email: '',
  fax: '',
  name: '',
  phone: '',
  www: '',
  educationStage: null,
  obligationType: null,
  accreditation: null,
  schoolCharacter: null,
  studentCategory: null,
  schoolType: null,
  schoolSubtype: null,
  catchmentSchool: false,
  educationForm: null,
  organizationalStructure: null,
  rspo: '',
  oke: '',
  regon: '',
  nip: '',
  address: {
    city: {
      id: null,
      name: null,
      country: 'PL',
    },
    street: {
      id: null,
      name: null,
    },
    buildingNumber: null,
    apartmentNumber: null,
    postalCode: null,
  },
};

export const EditorAddContainer: FC<EditContainerProps> = ({ goBack }) => {
  const dispatch: (...params: any) => Promise<any> = useDispatch();

  const fetchInstitutionsDictionaryCallback = useCallback(() => dispatch(fetchInstitutionsDictionary()), [dispatch]);

  const handleSubmitCallback = useCallback(
    (values: UnitFormValues) => dispatch(postUnit(mapFormValueToPayload(values))),
    [dispatch],
  );

  return (
    <Editor
      goBack={goBack}
      institutionsDictionary={useSelector(getInstitutionsListWithoutSynchronized)}
      isSynchronized={false}
      successMessage="INSTITUTIONS.UNIT_DATA.CREATE.SUCCESS"
      formInitialValues={formInitialValues}
      loading={useSelector(isIstitutionsListLoading)}
      fetchInstitutionsDictionary={fetchInstitutionsDictionaryCallback}
      handleSubmit={handleSubmitCallback}
      oryginalName=""
      isEdited={false}
      initialTab="form"
    />
  );
};

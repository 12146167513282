export enum UserActionType {
  FETCH_USER_INFO_REQUEST = 'user/FETCH_USER_INFO_REQUEST',
  FETCH_USER_INFO_SUCCESS = 'user/FETCH_USER_INFO_SUCCESS',
  FETCH_USER_INFO_FAILURE = 'user/FETCH_USER_INFO_FAILURE',
  FETCH_MESSAGES_LINK_REQUEST = 'user/FETCH_MESSAGES_LINK_REQUEST',
  FETCH_MESSAGES_LINK_SUCCESS = 'user/FETCH_MESSAGES_LINK_SUCCESS',
  FETCH_MESSAGES_LINK_FAILURE = 'user/FETCH_MESSAGES_LINK_FAILURE',
  FETCH_USER_PERMISSION_SUCCESS = 'user/FETCH_USER_PERMISSION_SUCCESS',
  FETCH_USER_PERMISSION_FAILURE = 'user/FETCH_USER_PERMISSION_FAILURE',
}

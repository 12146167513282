import { Notifier } from 'components/shared/messages/Notifier/Notifier';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { getNotificationsSelector } from 'store/general';

export const NotifierContainer: FC = ({ children }) => {
  const awaitingNotifications = useSelector(getNotificationsSelector);

  return (
    <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={5000}>
      <Notifier notifications={awaitingNotifications}>{children}</Notifier>
    </SnackbarProvider>
  );
};

import { SelectBox } from 'components/shared/form';
import { useStyles } from 'components/shared/form/TreeSelectBox/TreeSelectBox.styles';
import { TreeItem, TreeItemView, TreeSelectBoxProps } from 'components/shared/form/TreeSelectBox/TreeSelectBox.types';
import { connect, FormikProps } from 'formik';
import React, { ComponentType, ReactElement } from 'react';
import classNames from 'classnames';

const flattenOptions = (options: TreeItem[], level = 0, parent = null): TreeItemView[] => {
  return options.reduce((list, option) => {
    if (option.children) {
      return [...list, { ...option, level, parent }, ...flattenOptions(option.children, level + 1, option.value)];
    } else {
      return [...list, { ...option, level, parent }];
    }
  }, []);
};

export const TreeSingleSelectBox: ComponentType<TreeSelectBoxProps> = connect(
  ({ formatValue = null, options, name, placeholderText, ...selectProps }): ReactElement => {
    const flatOptions = flattenOptions(options);
    const classes = useStyles({ markLevelZero: false });
    const formik: FormikProps<{}> = selectProps.formik;

    const findOptionByValue = (value: string) => {
      return flatOptions.find((item) => item.value === value);
    };

    const handleChange = (event) => {
      formik.setFieldValue(name, event.target.value);
      selectProps.onChange && selectProps.onChange(event);
    };

    const renderItem = ({ level, label }: TreeItemView) => {
      return (
        <div className={classNames(classes.item, `level-${level}`)}>{formatValue ? formatValue(label) : label}</div>
      );
    };

    const renderValue = (value) => {
      if (value) {
        const option = findOptionByValue(value);
        return formatValue ? formatValue(option.label) : option.label;
      } else {
        return placeholderText;
      }
    };

    return (
      <SelectBox
        {...selectProps}
        name={name}
        placeholderText={placeholderText}
        options={flatOptions}
        itemComponent={renderItem}
        valueComponent={renderValue}
        onChange={handleChange}
        multiple={false}
        itemClassName={classNames(classes.itemWrapper, selectProps.itemClassName)}
      />
    );
  },
);

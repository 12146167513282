import { ContextColumnsConfig } from 'components/shared/import/settings/settings.types';

export const getContextColumnsConfig = (): ContextColumnsConfig => {
  return [
    {
      title: 'INSTITUTIONS.STUDENTS_REGISTER.IMPORT_STEPS.SETTINGS.PERSONAL_DATA',
      fields: [
        {
          field: 'firstName',
          required: true,
          label: 'INSTITUTIONS.STUDENTS_REGISTER.IMPORT_STEPS.SETTINGS.FIRSTNAME',
        },
        {
          field: 'lastName',
          required: true,
          label: 'INSTITUTIONS.STUDENTS_REGISTER.IMPORT_STEPS.SETTINGS.LASTNAME',
        },
        {
          field: 'identificationNumber',
          required: true,
          label: 'INSTITUTIONS.STUDENTS_REGISTER.IMPORT_STEPS.SETTINGS.PESEL',
        },
        {
          field: 'level',
          required: false,
          label: 'INSTITUTIONS.STUDENTS_REGISTER.IMPORT_STEPS.SETTINGS.LEVEL',
        },
      ],
    },
    {
      title: 'INSTITUTIONS.STUDENTS_REGISTER.IMPORT_STEPS.SETTINGS.RESIDENCE',
      fields: [
        {
          field: 'voivodeship',
          required: false,
          label: 'INSTITUTIONS.STUDENTS_REGISTER.IMPORT_STEPS.SETTINGS.VOIVODESHIP',
        },
        {
          field: 'city',
          required: false,
          label: 'INSTITUTIONS.STUDENTS_REGISTER.IMPORT_STEPS.SETTINGS.CITY',
        },
        {
          field: 'cityId',
          required: false,
          label: 'INSTITUTIONS.STUDENTS_REGISTER.IMPORT_STEPS.SETTINGS.CITY_TERYT',
        },
        {
          field: 'street',
          required: false,
          label: 'INSTITUTIONS.STUDENTS_REGISTER.IMPORT_STEPS.SETTINGS.STREET',
        },
        {
          field: 'buildingNumber',
          required: false,
          label: 'INSTITUTIONS.STUDENTS_REGISTER.IMPORT_STEPS.SETTINGS.BUILDING_NUMBER',
        },
        {
          field: 'flatNumber',
          required: false,
          label: 'INSTITUTIONS.STUDENTS_REGISTER.IMPORT_STEPS.SETTINGS.FLAT_NUMBER',
        },
      ],
    },
  ];
};

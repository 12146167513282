import { ActionHandlers } from 'components/institution/Browser/Browser.types';
import { ColumnsConfig } from 'components/shared/data/DataGrid/DataGrid.types';
import { compact } from 'lodash';
import { InstitutionModel, isInstitution, SynchronizationTypes, UnitModel } from 'store/institutions';
import { OfficialPermissionLevel, OfficialResourceGroup } from 'store/officials';
import { provideString } from 'utils/string';

const canDelete = (item: InstitutionModel | UnitModel) => {
  if (isInstitution(item)) {
    return item.synchronization === SynchronizationTypes.NONE;
  } else {
    return item.institution.synchronization === SynchronizationTypes.NONE;
  }
};

export const browserColumns: ColumnsConfig<InstitutionModel | UnitModel, ActionHandlers> = (handlers) => [
  {
    field: 'name',
    name: 'INSTITUTIONS.BROWSER.COLUMNS.INSTITUTION',
    cellClass: 'indent-cell',
    columnParams: { minWidth: 160 },
  },
  {
    name: 'INSTITUTIONS.BROWSER.COLUMNS.TOWN',
    field: 'address.cityName',
    columnParams: { minWidth: 95 },
  },
  {
    name: 'INSTITUTIONS.BROWSER.COLUMNS.ADDRESS',
    field: 'address.street.name',
    format: (data) => {
      if (!isInstitution(data)) {
        return `${provideString(data.address.streetName)} ${provideString(data.address.buildingNumber)}`;
      }

      return '';
    },
    columnParams: { minWidth: 80 },
  },
  {
    name: 'INSTITUTIONS.BROWSER.COLUMNS.SYNCHRONIZATION',
    field: 'synchronization',
    format: (data, trans) => {
      const sync = isInstitution(data) ? data.synchronization : data.institution.synchronization;

      return trans(`INSTITUTIONS.BROWSER.VALUES.SYNCHRONIZATION.${sync}`);
    },
    columnParams: { minWidth: 60 },
  },
  {
    name: 'INSTITUTIONS.BROWSER.COLUMNS.DATA-SHARING',
    field: 'dataSharing',
    format: (data, trans) => {
      const sync = isInstitution(data) ? data.synchronization : data.institution.synchronization;
      const dataSharing = isInstitution(data) ? data.dataSharing : data.institution.dataSharing;

      if (dataSharing === null || sync === SynchronizationTypes.SDDS) {
        return '';
      }

      return trans(`INSTITUTIONS.BROWSER.VALUES.DATA-SHARING.${dataSharing ? 'YES' : 'NO'}`);
    },
    columnParams: { minWidth: 70 },
  },
  {
    name: 'INSTITUTIONS.BROWSER.COLUMNS.ACCREDITATION',
    field: 'accreditation.name',
    columnParams: { minWidth: 90 },
  },
  {
    name: 'INSTITUTIONS.BROWSER.COLUMNS.EDUCATION-LEVEL',
    field: 'educationStage.name',
    columnParams: { minWidth: 90 },
  },
  {
    name: 'INSTITUTIONS.BROWSER.COLUMNS.STUDENT-REGISTER',
    field: 'registerUpdatedAt',
    headerTooltip: 'INSTITUTIONS.BROWSER.TOOLTIPS.STUDENT-REGISTER',
    columnParams: { minWidth: 90 },
    cellClass: 'indent-cell',
    format: (data) => {
      if (!isInstitution(data)) {
        return data.studentRegisterYears.join(', ');
      }

      return '';
    },
  },
  {
    name: 'INSTITUTIONS.BROWSER.COLUMNS.ACTIONS',
    field: 'id',
    cellTooltip: () => '',
    renderer: 'buttonsCell',
    rendererParams: {
      buttons: (item: InstitutionModel | UnitModel) =>
        compact([
          {
            type: 'default',
            size: 'small',
            variant: 'outlined',
            canShow: {
              only: false,
              resourcesGroup: [OfficialResourceGroup.childRegister, OfficialResourceGroup.institutionalCoreGrant],
              accessType: OfficialPermissionLevel.fullAccess,
            },
            icon: 'pencil-alt',
            label: 'COMMON.EDIT',
            tooltip: 'COMMON.EDIT',
            action: handlers.edit,
          },
          {
            type: 'default',
            size: 'small',
            variant: 'outlined',
            canShow: {
              only: true,
              resourcesGroup: OfficialResourceGroup.childRegister,
              accessType: OfficialPermissionLevel.readOnly,
            },
            icon: 'eye',
            label: 'COMMON.SHOW',
            tooltip: 'COMMON.SHOW',
            action: handlers.edit,
          },
          canDelete(item) && {
            type: 'default',
            size: 'small',
            variant: 'outlined',
            canShow: {
              only: false,
              resourcesGroup: [OfficialResourceGroup.childRegister, OfficialResourceGroup.institutionalCoreGrant],
              accessType: OfficialPermissionLevel.fullAccess,
            },
            icon: 'trash',
            label: 'COMMON.DELETE',
            tooltip: 'COMMON.DELETE',
            action: handlers.delete,
          },
        ]),
    },
    columnParams: { minWidth: 210 },
  },
];

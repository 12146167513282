import { useStyles } from 'components/institution/Browser/Browser.styles';
import { ActionHandlers, BrowserProps } from 'components/institution/Browser/Browser.types';
import { browserColumns } from 'components/institution/Browser/columns.config';
import { institutionsTransformer } from 'components/institution/Browser/institutions.transformer';
import { Toolbar } from 'components/institution/Browser/Toolbar/Toolbar';
import { DataGrid } from 'components/shared/data/DataGrid/DataGrid';
import { DataGridApi } from 'components/shared/data/DataGrid/DataGrid.types';
import { useTranslator } from 'components/shared/hooks/useTranslator/useTranslator';
import { TextLoader } from 'components/shared/loaders/TextLoader/TextLoader';
import React, { FC, useCallback, useRef, useState } from 'react';
import { InstitutionModel, isInstitution, UnitModel } from 'store/institutions';
import { Confirm } from 'components/shared/dialogs/Confirm/Confirm';
import { focusField } from 'utils/form';

export const Browser: FC<BrowserProps> = (props) => {
  const [itemToDelete, setItemToDelete] = useState(null);
  const [gridApi, setGridApi] = useState<DataGridApi>(null);
  const classes = useStyles();
  const trans = useTranslator();
  const toolbarRef = useRef(null);

  const actionHandlers: ActionHandlers = {
    edit: (data) => props.editItem(data),
    delete: (data) => setItemToDelete(data),
  };

  const deleteConfirmHandler = () => {
    props.deleteItem(itemToDelete).then(() => setItemToDelete(null));
  };

  const onGridReadyHandler = useCallback(
    (grid: DataGridApi) => {
      setGridApi(grid);
    },
    [setGridApi],
  );

  const onFocusBeforeGridHandler = useCallback(() => {
    if (toolbarRef.current) {
      // using setTimeout to wait over the tab event that we can't prevent
      setTimeout(() => focusField(toolbarRef.current, 'studentRegister'));
    }
  }, [toolbarRef]);

  const onFocusAfterGridHandler = () => {
    setTimeout(() => document.getElementById('sidebar-button').focus());
  };

  const gridFocusHandler = () => {
    gridApi && gridApi.focusCell(0, 0);
  };

  if (props.dictionariesLoading) {
    return <TextLoader text={trans('INSTITUTIONS.BROWSER.DATA-LOADING')} />;
  }

  return (
    <>
      <Toolbar onGridFocus={gridFocusHandler} ref={toolbarRef} />
      <DataGrid<InstitutionModel | UnitModel>
        className={classes.grid}
        data={institutionsTransformer(props.institutions)}
        columns={browserColumns(actionHandlers)}
        isLoading={props.resultsLoading}
        loadingMessage={'INSTITUTIONS.BROWSER.RESULTS-LOADING'}
        noResultsMessage={'INSTITUTIONS.BROWSER.NOTHING-FOUND'}
        getRowClass={(params) => {
          return isInstitution(params.data) ? classes.institutionRow : classes.unitRow;
        }}
        key={classes.institutionRow}
        onGridReady={onGridReadyHandler}
        onFocusBeforeGrid={onFocusBeforeGridHandler}
        onFocusAfterGrid={onFocusAfterGridHandler}
      />
      <Confirm
        open={itemToDelete !== null}
        title={'INSTITUTIONS.BROWSER.REMOVE.TITLE'}
        message={
          itemToDelete && isInstitution(itemToDelete)
            ? 'INSTITUTIONS.BROWSER.REMOVE.MESSAGE-INSTITUTION'
            : 'INSTITUTIONS.BROWSER.REMOVE.MESSAGE-UNIT'
        }
        confirm={() => deleteConfirmHandler()}
        cancel={() => setItemToDelete(null)}
      />
    </>
  );
};

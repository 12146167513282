import { EditorProps } from 'components/childRegister/Editor/Editor.types';
import { EducationPathContainer } from 'components/childRegister/Editor/EducationPath/EducationPathContainer';
import { ChildRegisterFormValues } from 'components/childRegister/Editor/General/General.types';
import { GeneralContainer } from 'components/childRegister/Editor/General/GeneralContainer';
import { Obligation } from 'components/childRegister/Editor/Obligation/Obligation';
import { ObligationActionsContainer } from 'components/childRegister/Editor/ObligationActions/ObligationActionsContainer';
import { Button } from 'components/shared/buttons/Button/Button';
import { Form } from 'components/shared/form';
import { useTranslator } from 'components/shared/hooks';
import { TextLoader } from 'components/shared/loaders/TextLoader/TextLoader';
import { Splash } from 'components/shared/surfaces/Splash/Splash';
import { TabPanel } from 'components/shared/surfaces/TabPanel/TabPanel';
import { FormikProps } from 'formik';
import React, { FC, useEffect, useLayoutEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Prompt } from 'react-router';
import { clearEducationPath } from 'store/childRegister/childRegister.actions';
import Access from 'components/shared/access/access';
import { OfficialPermissionLevel, OfficialResourceGroup } from 'store/officials';

export const Editor: FC<EditorProps> = (props) => {
  const trans = useTranslator();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const alerts = props.child ? props.child.alerts : [];
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearEducationPath());
  }, [dispatch, props.initial]);

  useLayoutEffect(() => {
    if (props.scrollTo) {
      const element = document.querySelector(`#${props.scrollTo.elementId}`);
      if (element) {
        setTimeout(() => element.scrollIntoView({ behavior: 'smooth' }), 500);
      }
    }
  }, [props.scrollTo, props.loading]);

  const renderLoader = () => (
    <Splash>
      <TextLoader name="spinner" text={trans('CHILD_REGISTER.FORM.LOADING')} />
    </Splash>
  );

  const tabs = useMemo(() => {
    return [
      {
        title: trans('CHILD_REGISTER.EDIT.TITLE'),
        key: 'general',
        content: (
          <GeneralContainer
            id={props.child ? props.child.id : null}
            initial={props.initial}
            alerts={alerts}
            loading={props.loading}
            showGuardiansHelp={props.showGuardiansHelp}
          />
        ),
      },
      {
        title: trans('CHILD_REGISTER.OBLIGATION.TITLE'),
        key: 'obligation',
        content: <Obligation childId={props.child ? props.child.id : null} />,
      },
      {
        title: trans('CHILD_REGISTER.OBLIGATION_ACTIONS.TITLE'),
        key: 'obligation_actions',
        content: <ObligationActionsContainer id={props.child ? props.child.id : null} />,
      },
      {
        title: trans('CHILD_REGISTER.EDUCATION_PATH.TITLE'),
        key: 'educational_path',
        content: <EducationPathContainer />,
      },
    ];
  }, [trans, props.child, props.initial, props.loading, props.showGuardiansHelp, alerts]);

  const renderForm = () => (
    <Form<ChildRegisterFormValues> initialValues={props.initial} onSubmit={props.onSubmit}>
      {(form: FormikProps<ChildRegisterFormValues>) => {
        return (
          <>
            <Prompt when={form.dirty && !props.submitted} message={trans('COMMON.CONFIRM_EXIT')} />
            <TabPanel tabs={tabs} defaultTab={props.scrollTo ? props.scrollTo.tab : 'general'} />
            <div className="row my-4">
              <div className="col">
                <Button
                  onClick={() => props.goBack()}
                  disabled={form.isSubmitting}
                  color="secondary"
                  variant="outlined"
                >
                  {trans('COMMON.BACK')}
                </Button>
                <Access
                  resourcesGroup={OfficialResourceGroup.childRegister}
                  accessType={OfficialPermissionLevel.fullAccess}
                >
                  <Button
                    onClick={() => form.handleSubmit()}
                    disabled={form.isSubmitting}
                    color="secondary"
                    variant="contained"
                    className="ml-3"
                  >
                    {trans('COMMON.SAVE')}
                  </Button>
                </Access>
              </div>
            </div>
          </>
        );
      }}
    </Form>
  );

  return props.loading ? renderLoader() : renderForm();
};

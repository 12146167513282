import { RESET_STATE } from 'store/store';
import { TerytState, defaultState } from './teryt.state';
import { TerytActionType } from './teryt.actionTypes';
import { FSAReducer, Behaviours } from '@typings/redux';
import { fromApiFormat } from 'utils/api';

export const terytReducer: FSAReducer<TerytState, TerytActionType> = (state = defaultState, action) => {
  const behaviours: Behaviours<TerytState, TerytActionType> = {
    [RESET_STATE]: () => ({
      ...defaultState,
    }),
    [TerytActionType.FETCH_VOIVODESHIPS_REQUEST]: () => {
      return {
        ...state,
        voivodeships: {
          items: state.voivodeships.items,
          loading: true,
        },
      };
    },
    [TerytActionType.FETCH_VOIVODESHIPS_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        voivodeships: {
          items: fromApiFormat(payload.items),
          loading: false,
        },
      };
    },
    [TerytActionType.FETCH_VOIVODESHIPS_FAILURE]: () => {
      return {
        ...state,
        voivodeships: {
          items: [],
          loading: false,
        },
      };
    },
    [TerytActionType.FETCH_COUNTIES_REQUEST]: () => {
      return {
        ...state,
        counties: {
          items: state.counties.items,
          loading: true,
        },
      };
    },
    [TerytActionType.FETCH_COUNTIES_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        counties: {
          items: fromApiFormat(payload.items),
          loading: false,
        },
      };
    },
    [TerytActionType.FETCH_COUNTIES_FAILURE]: () => {
      return {
        ...state,
        counties: {
          items: [],
          loading: false,
        },
      };
    },
    [TerytActionType.FETCH_COMMUNES_REQUEST]: () => {
      return {
        ...state,
        communes: {
          items: state.communes.items,
          loading: true,
        },
      };
    },
    [TerytActionType.FETCH_COMMUNES_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        communes: {
          items: fromApiFormat(payload.items),
          loading: false,
        },
      };
    },
    [TerytActionType.FETCH_COMMUNES_FAILURE]: () => {
      return {
        ...state,
        communes: {
          items: [],
          loading: false,
        },
      };
    },
    [TerytActionType.FETCH_TOWNS_REQUEST]: () => {
      return {
        ...state,
        towns: {
          items: state.towns.items,
          loading: true,
        },
      };
    },
    [TerytActionType.FETCH_TOWNS_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        towns: {
          items: fromApiFormat(payload.items),
          loading: false,
        },
      };
    },
    [TerytActionType.FETCH_TOWNS_FAILURE]: () => {
      return {
        ...state,
        towns: {
          items: [],
          loading: false,
        },
      };
    },
    [TerytActionType.CLEAR_STREETS]: () => {
      return {
        ...state,
        streets: {
          ...state.streets,
          items: [],
        },
      };
    },
    [TerytActionType.CLEAR_TOWNS]: () => {
      return {
        ...state,
        towns: {
          ...state.towns,
          items: [],
        },
      };
    },
    [TerytActionType.FETCH_STREETS_REQUEST]: () => {
      return {
        ...state,
        streets: {
          items: state.streets.items,
          loading: true,
        },
      };
    },
    [TerytActionType.FETCH_STREETS_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        streets: {
          items: fromApiFormat(payload.items),
          loading: false,
        },
      };
    },
    [TerytActionType.FETCH_STREETS_FAILURE]: () => {
      return {
        ...state,
        streets: {
          items: [],
          loading: false,
        },
      };
    },
    [TerytActionType.FETCH_STREET_REQUEST]: () => {
      return {
        ...state,
        streets: {
          items: state.streets.items,
          loading: true,
        },
      };
    },
    [TerytActionType.FETCH_STREET_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        streets: {
          items: [fromApiFormat(payload)],
          loading: false,
        },
      };
    },
    [TerytActionType.FETCH_STREET_FAILURE]: () => {
      return {
        ...state,
        streets: {
          items: [],
          loading: false,
        },
      };
    },
    [TerytActionType.FETCH_TOWN_REQUEST]: () => {
      return {
        ...state,
        towns: {
          items: state.towns.items,
          loading: true,
        },
      };
    },
    [TerytActionType.FETCH_TOWN_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        towns: {
          items: [fromApiFormat(payload)],
          loading: false,
        },
      };
    },
    [TerytActionType.FETCH_TOWN_FAILURE]: () => {
      return {
        ...state,
        towns: {
          items: [],
          loading: false,
        },
      };
    },
    [TerytActionType.FETCH_COMMUNE_REQUEST]: () => {
      return {
        ...state,
        communes: {
          items: state.communes.items,
          loading: true,
        },
      };
    },
    [TerytActionType.FETCH_COMMUNE_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        communes: {
          items: [fromApiFormat(payload)],
          loading: false,
        },
      };
    },
    [TerytActionType.FETCH_COMMUNE_FAILURE]: () => {
      return {
        ...state,
        communes: {
          items: [],
          loading: false,
        },
      };
    },
  };

  return behaviours[action.type] ? behaviours[action.type](action) : state;
};

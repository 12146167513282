import { useStyles } from 'components/shared/data/DataGrid/DataGrid.styles';
import { useTimeoutSwitch } from 'components/shared/hooks/useTimeout/useTimeoutSwitch';
import { Loader } from 'components/shared/loaders/Loader/Loader';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import React, { FC } from 'react';
import classNames from 'classnames';

interface LoadingOverlayProps {
  loadingMessage: string;
  reactContainer: HTMLElement;
}

export const LoadingOverlay: FC<LoadingOverlayProps> = (props) => {
  const isVisible = useTimeoutSwitch(500);
  const classes = useStyles();

  return (
    <Paper innerSpacing={2} className={classNames(classes.overlay, { [classes.overlayVisible]: isVisible })}>
      <Loader className="mr-2" />
      {props.loadingMessage}
    </Paper>
  );
};

import { AppState } from 'store/store';
import { SynchronizationTypes } from './institutions.types';

export const getInstitution = (state: AppState) => state.institutions.institution.data;
export const isInstitutionLoading = (state: AppState) => state.institutions.institution.loading;
export const getInstitutionsList = (state: AppState) => state.institutions.dictionary.list;
export const getInstitutionsListWithoutSynchronized = (state: AppState) =>
  state.institutions.dictionary.list.filter((item) => item.synchronization !== SynchronizationTypes.SYNERGIA);
export const isIstitutionsListLoading = (state: AppState) =>
  !state.institutions.dictionary.ready || state.institutions.dictionary.loading;

export const getUnitData = (state: AppState) => state.institutions.unit.data;
export const isUnitLoading = (state: AppState) => state.institutions.unit.loading;
export const isUnitSynchronized = (state: AppState) =>
  state.institutions.unit.data &&
  (state.institutions.unit.data.institution.synchronization === SynchronizationTypes.SYNERGIA ||
    state.institutions.unit.data.institution.synchronization === SynchronizationTypes.SDDS);

export const institutionsListFiltersSelector = (state: AppState) => state.institutions.list.filters;
export const institutionsListSelector = (state: AppState) => state.institutions.list;
export const institutionsDictionarySelector = (state: AppState) => state.institutions.dictionary;
export const studentRegisterSchoolYearsSelector = (state: AppState) => state.institutions.studentRegisterSchoolYears;

export const studentRegisterListSelector = (state: AppState) => state.institutions.studentRegister.list.items;
export const isStudentRegisterListLoadingSelector = (state: AppState) =>
  state.institutions.studentRegister.list.loading;
export const isStudentRegisterListReadySelector = (state: AppState) => state.institutions.studentRegister.list.ready;

export const studentRegisterDetailsSelector = (state: AppState) => state.institutions.studentRegister.register.item;
export const isStudentRegisterDetailsLoadingSelector = (state: AppState) =>
  state.institutions.studentRegister.register.loading;
export const isStudentRegisterDetailsReadySelector = (state: AppState) =>
  state.institutions.studentRegister.register.ready;

export const getImportToken = (state: AppState) => state.institutions.studentRegister.import.token;
export const getImportContext = (state: AppState) => state.institutions.studentRegister.import.context;
export const getImportFileName = (state: AppState) => state.institutions.studentRegister.import.fileName;
export const getImportColumns = (state: AppState) => state.institutions.studentRegister.import.columns;
export const getImportColumnsError = (state: AppState) => state.institutions.studentRegister.import.error;

export const getImportPreview = (state: AppState) => state.institutions.studentRegister.import.preview;
export const isImportLoading = (state: AppState) => state.institutions.studentRegister.import.loading;

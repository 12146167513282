import { muiStyles } from 'utils/design';

export const useStyles = muiStyles((theme) => ({
  switcher: {
    display: 'flex',
    alignItems: 'center',
  },
  counter: {
    margin: theme.spacing(0, 2),
    fontWeight: 'bolder',
  },
}));

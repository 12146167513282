import { green } from '@material-ui/core/colors';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import yellow from '@material-ui/core/colors/yellow';
import blue from '@material-ui/core/colors/blue';
import orange from '@material-ui/core/colors/orange';

export const colors = {
  primary: '#034f84',
  secondary: '#e57300',

  info: blue[100],
  danger: '#e50000',

  neutralVeryLight: grey[100],
  neutralLight: grey[200],
  neutral: grey[300],
  neutralDark: grey[700],
  neutralVeryDark: grey[900],
  lightRed: red[200],
  red: red[500],
  yellowLight: yellow[200],
  yellowVeryLight: yellow[100],
  green: green[500],
  orange: orange[500],

  gridBorder: '#e2e2e2',
  gridDetailsBackground: '#f5f5f5',
  gridMarkedCell: '#f5f5f5',

  defaultIcon: 'rgba(0, 0, 0, 0.87)',
  defaultButton: 'rgba(0, 0, 0, 0.75)',
  defaultOutlinedButtonBorder: '#e2e2e2',
};

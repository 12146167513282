export enum ChildRegisterActionType {
  FETCH_ALERT_TYPES_REQUEST = 'childRegister/FETCH_ALERT_TYPES_REQUEST',
  FETCH_ALERT_TYPES_SUCCESS = 'childRegister/FETCH_ALERT_TYPES_SUCCESS',
  FETCH_ALERT_TYPES_FAILURE = 'childRegister/FETCH_ALERT_TYPES_FAILURE',
  FETCH_BIRTH_YEARS_REQUEST = 'childRegister/FETCH_BIRTH_YEARS_REQUEST',
  FETCH_BIRTH_YEARS_SUCCESS = 'childRegister/FETCH_BIRTH_YEARS_SUCCESS',
  FETCH_BIRTH_YEARS_FAILURE = 'childRegister/FETCH_BIRTH_YEARS_FAILURE',
  SET_CHILD_REGISTER_FILTERS = 'childRegister/SET_CHILD_REGISTER_FILTERS',
  CLEAR_CHILD_REGISTER_DATA = 'childRegister/CLEAR_CHILD_REGISTER_DATA',
  FETCH_CHILD_REGISTER_REQUEST = 'childRegister/FETCH_CHILD_REGISTER_REQUEST',
  FETCH_CHILD_REGISTER_SUCCESS = 'childRegister/FETCH_CHILD_REGISTER_SUCCESS',
  FETCH_CHILD_REGISTER_FAILURE = 'childRegister/FETCH_CHILD_REGISTER_FAILURE',
  DELETE_CHILDREN_REQUEST = 'childRegister/DELETE_CHILDREN_REQUEST',
  DELETE_CHILDREN_SUCCESS = 'childRegister/DELETE_CHILDREN_SUCCESS',
  DELETE_CHILDREN_FAILURE = 'childRegister/DELETE_CHILDREN_FAILURE',
  POST_CHILD_REQUEST = 'childRegister/POST_CHILD_REQUEST',
  POST_CHILD_SUCCESS = 'childRegister/POST_CHILD_SUCCESS',
  POST_CHILD_FAILURE = 'childRegister/POST_CHILD_FAILURE',
  PUT_CHILD_REQUEST = 'childRegister/PUT_CHILD_REQUEST',
  PUT_CHILD_SUCCESS = 'childRegister/PUT_CHILD_SUCCESS',
  PUT_CHILD_FAILURE = 'childRegister/PUT_CHILD_FAILURE',
  FETCH_CHILD_DETAILS_REQUEST = 'childRegister/FETCH_CHILD_DETAILS_REQUEST',
  FETCH_CHILD_DETAILS_SUCCESS = 'childRegister/FETCH_CHILD_DETAILS_SUCCESS',
  FETCH_CHILD_DETAILS_FAILURE = 'childRegister/FETCH_CHILD_DETAILS_FAILURE',
  CLEAR_CHILD_DETAILS = 'childRegister/CLEAR_CHILD_DETAILS',
  FETCH_GUARDIAN_TYPES_REQUEST = 'childRegister/FETCH_GUARDIAN_TYPES_REQUEST',
  FETCH_GUARDIAN_TYPES_SUCCESS = 'childRegister/FETCH_GUARDIAN_TYPES_SUCCESS',
  FETCH_GUARDIAN_TYPES_FAILURE = 'childRegister/FETCH_GUARDIAN_TYPES_FAILURE',
  SET_CUSTOM_GUARDIAN_TYPES = 'childRegister/SET_CUSTOM_GUARDIAN_TYPES',
  FETCH_ADDRESS_HISTORY_REQUEST = 'childRegister/FETCH_ADDRESS_HISTORY_REQUEST',
  FETCH_ADDRESS_HISTORY_SUCCESS = 'childRegister/FETCH_ADDRESS_HISTORY_SUCCESS',
  FETCH_ADDRESS_HISTORY_FAILURE = 'childRegister/FETCH_ADDRESS_HISTORY_FAILURE',
  CLEAR_EDUCATION_PATH = 'childRegister/CLEAR_EDUCATION_PATH',
  FETCH_OBLIGATION_REPORTS_REQUEST = 'childRegister/FETCH_OBLIGATION_REPORTS_REQUEST',
  FETCH_OBLIGATION_REPORTS_SUCCESS = 'childRegister/FETCH_OBLIGATION_REPORTS_SUCCESS',
  FETCH_OBLIGATION_REPORTS_FAILURE = 'childRegister/FETCH_OBLIGATION_REPORTS_FAILURE',
  FETCH_OBLIGATION_ACTIONS_REQUEST = 'childRegister/FETCH_OBLIGATION_ACTIONS_REQUEST',
  FETCH_OBLIGATION_ACTIONS_SUCCESS = 'childRegister/FETCH_OBLIGATION_ACTIONS_SUCCESS',
  FETCH_OBLIGATION_ACTIONS_FAILURE = 'childRegister/FETCH_OBLIGATION_ACTIONS_FAILURE',
  CLEAR_OBLIGATION_ACTIONS = 'childRegister/CLEAR_OBLIGATION_ACTIONS',
  POST_OBLIGATION_ACTION_REQUEST = 'childRegister/POST_OBLIGATION_ACTION_REQUEST',
  POST_OBLIGATION_ACTION_SUCCESS = 'childRegister/POST_OBLIGATION_ACTION_SUCCESS',
  POST_OBLIGATION_ACTION_FAILURE = 'childRegister/POST_OBLIGATION_ACTION_FAILURE',
  PUT_OBLIGATION_ACTION_REQUEST = 'childRegister/PUT_OBLIGATION_ACTION_REQUEST',
  PUT_OBLIGATION_ACTION_SUCCESS = 'childRegister/PUT_OBLIGATION_ACTION_SUCCESS',
  PUT_OBLIGATION_ACTION_FAILURE = 'childRegister/PUT_OBLIGATION_ACTION_FAILURE',
  DELETE_OBLIGATION_ACTION_REQUEST = 'childRegister/DELETE_OBLIGATION_ACTION_REQUEST',
  DELETE_OBLIGATION_ACTION_SUCCESS = 'childRegister/DELETE_OBLIGATION_ACTION_SUCCESS',
  DELETE_OBLIGATION_ACTION_FAILURE = 'childRegister/DELETE_OBLIGATION_ACTION_FAILURE',
  FETCH_EDUCATION_PATH_REQUEST = 'childRegister/FETCH_EDUCATION_PATH_REQUEST',
  FETCH_EDUCATION_PATH_SUCCESS = 'childRegister/FETCH_EDUCATION_PATH_SUCCESS',
  FETCH_EDUCATION_PATH_FAILURE = 'childRegister/FETCH_EDUCATION_PATH_FAILURE',
  POST_IMPORT_FILE_REQUEST = 'childRegister/POST_IMPORT_FILE_REQUEST',
  POST_IMPORT_FILE_SUCCESS = 'childRegister/POST_IMPORT_FILE_SUCCESS',
  POST_IMPORT_FILE_FAILURE = 'childRegister/POST_IMPORT_FILE_FAILURE',
  DELETE_IMPORT_REQUEST = 'childRegister/DELETE_IMPORT_REQUEST',
  DELETE_IMPORT_SUCCESS = 'childRegister/DELETE_IMPORT_SUCCESS',
  DELETE_IMPORT_FAILURE = 'childRegister/DELETE_IMPORT_FAILURE',
  FETCH_IMPORT_COLUMNS_REQUEST = 'childRegister/FETCH_IMPORT_COLUMNS_REQUEST',
  FETCH_IMPORT_COLUMNS_SUCCESS = 'childRegister/FETCH_IMPORT_COLUMNS_SUCCESS',
  FETCH_IMPORT_COLUMNS_FAILURE = 'childRegister/FETCH_IMPORT_COLUMNS_FAILURE',
  POST_IMPORT_CONTEXT_REQUEST = 'childRegister/POST_IMPORT_CONTEXT_REQUEST',
  POST_IMPORT_CONTEXT_SUCCESS = 'childRegister/POST_IMPORT_CONTEXT_SUCCESS',
  POST_IMPORT_CONTEXT_FAILURE = 'childRegister/POST_IMPORT_CONTEXT_FAILURE',
  FETCH_IMPORT_PREVIEW_START_REQUEST = 'childRegister/FETCH_IMPORT_PREVIEW_START_REQUEST',
  FETCH_IMPORT_PREVIEW_START_SUCCESS = 'childRegister/FETCH_IMPORT_PREVIEW_START_SUCCESS',
  FETCH_IMPORT_PREVIEW_START_FAILURE = 'childRegister/FETCH_IMPORT_PREVIEW_START_FAILURE',
  FETCH_IMPORT_PREVIEW_REQUEST = 'childRegister/FETCH_IMPORT_PREVIEW_REQUEST',
  FETCH_IMPORT_PREVIEW_SUCCESS = 'childRegister/FETCH_IMPORT_PREVIEW_SUCCESS',
  FETCH_IMPORT_PREVIEW_FAILURE = 'childRegister/FETCH_IMPORT_PREVIEW_FAILURE',
  FETCH_IMPORT_PREVIEW_STATUS_REQUEST = 'childRegister/FETCH_IMPORT_PREVIEW_STATUS_REQUEST',
  FETCH_IMPORT_PREVIEW_STATUS_SUCCESS = 'childRegister/FETCH_IMPORT_PREVIEW_STATUS_SUCCESS',
  FETCH_IMPORT_PREVIEW_STATUS_FAILURE = 'childRegister/FETCH_IMPORT_PREVIEW_STATUS_FAILURE',
  POST_IMPORT_CONFIRM_REQUEST = 'childRegister/POST_IMPORT_CONFIRM_REQUEST',
  POST_IMPORT_CONFIRM_SUCCESS = 'childRegister/POST_IMPORT_CONFIRM_SUCCESS',
  POST_IMPORT_CONFIRM_FAILURE = 'childRegister/POST_IMPORT_CONFIRM_FAILURE',
  CLEAR_IMPORT_INFORMATION = 'childRegister/CLEAR_IMPORT_INFORMATION',
  FETCH_IMPORT_STATUS_REQUEST = 'childRegister/FETCH_IMPORT_STATUS_REQUEST',
  FETCH_IMPORT_STATUS_SUCCESS = 'childRegister/FETCH_IMPORT_STATUS_SUCCESS',
  FETCH_IMPORT_STATUS_FAILURE = 'childRegister/FETCH_IMPORT_STATUS_FAILURE',
  MAP_IMPORT_FIELDS = 'childRegister/MAP_IMPORT_FIELDS',
}

export enum LeadingAdress {
  RESIDENCE = 'residence',
  PERMANENT = 'permanent',
  RESIDENCE_FIRST = 'residence_first',
  PERMANENT_FIRST = 'permanent_first',
}

export enum DCSType {
  MDOK = 'mdok',
  DUMMY = 'dummy',
}

export interface SystemSettings {
  departmentName: string;
  osinCommune: string;
  additionalCities: string[];
  additionalCommunes: string[];
  leadingAddress: LeadingAdress;
  belSynchronizationEnabled: boolean;
  schoolyearThreshold: string;
  documentsCirculationSystem: string | null;
  catchmentAssignmentTransferEnabled: boolean;
  studentAddressSynchronizationEnabled: boolean;
  institutionalCoreGrantEnabled: boolean;
}

export interface PollsSettings {
  topic: string;
  messageContent: string;
  messageSignature: string;
}

export interface UserSettings {
  dcsLogin: string;
}

export interface DepartmentSettingsModel {
  departmentName: string;
  schoolyearThreshold: string;
}

import { RFC } from '@typings/extra-react-router';
import { useEnvironment } from 'components/shared/hooks/useEnvironment/useEnvironment';
import { ROUTE_AUTH_LOGIN } from 'config/routes';
import { AuthSsoRoute } from 'config/routes.types';
import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useStyles } from 'views/Auth/Sso/Sso.styles';

export enum SsoAction {
  LOGIN = 'login',
  LOGOUT = 'logout',
}

export const SsoView: RFC<AuthSsoRoute> = ({ match }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { oAuth } = useEnvironment();

  const action = match.params.action as SsoAction;

  useEffect(() => {
    if (action === SsoAction.LOGIN) {
      window.location.href = oAuth.loginUrl;
    }
    if (action === SsoAction.LOGOUT) {
      if (oAuth.logoutUrl) {
        window.location.href = oAuth.logoutUrl;
      } else {
        dispatch(push(ROUTE_AUTH_LOGIN));
      }
    }
  });

  return <div className={classes.wrapper} />;
};

import { PieChartProps } from 'components/polls/Results/Combined/charts/charts.types';
import { PieChartDoubleLabel } from 'components/shared/charts/PieChartDoubleLabel/PieChartDoubleLabel';
import React from 'react';
import { VictoryPie } from 'victory';

export const PieChart = (props: PieChartProps) => {
  return (
    <VictoryPie
      width={600}
      padding={{ top: 80, bottom: 80 }}
      colorScale={[
        '#0f487f',
        '#115291',
        '#135ca2',
        '#1566b4',
        '#1770c5',
        '#197ad7',
        '#1f84e5',
        '#318de7',
        '#4297e9',
        '#54a1eb',
        '#65aaed',
        '#77b4ef',
        '#88bef1',
        '#9ac7f3',
        '#abd1f5',
        '#bddbf7',
        '#cfe4f9',
        '#e0eefb',
      ]}
      data={props.data}
      labelComponent={<PieChartDoubleLabel />}
      style={{
        data: {
          stroke: '#ffffff',
          strokeWidth: 1,
        },
        labels: {
          fontFamily: 'Roboto',
          fontSize: 14,
        },
      }}
    />
  );
};

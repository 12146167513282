import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@typings/redux';
import { Search } from 'components/search/Search';
import { searchSelector } from 'store/search/search.selector';
import { fetchSearch } from 'store/search/search.action';
import { SearchParams } from 'store/search/search.types';

export const SearchContainer: FC = () => {
  const { list, loading } = useSelector(searchSelector);
  const dispatch = useDispatch<AppDispatch>();

  const handleSubmit = useCallback(
    (filters: SearchParams) => {
      dispatch(fetchSearch(filters));
    },
    [dispatch],
  );

  return <Search searchList={list} isLoading={loading} onSubmit={handleSubmit} />;
};

export const fillGridBlankSpace = (api, columnApi) => {
  const availableWidth = api.gridPanel.eBodyViewport.clientWidth;
  const columns = columnApi.getAllDisplayedColumns();
  const usedWidth = columnApi.columnController.getWidthOfColsInList(columns);
  const remainingGridSpace = availableWidth - usedWidth;
  const lastColumn = columns[columns.length - 1];
  const minColumnWidth = lastColumn.getMinWidth();

  // Prevent from setting column width below its minWidth
  const newWidth: number =
    lastColumn.getActualWidth() + remainingGridSpace <= minColumnWidth
      ? minColumnWidth
      : lastColumn.getActualWidth() + remainingGridSpace;

  // Assign remaining blank space to last column width
  columnApi.setColumnWidth(lastColumn.getColId(), newWidth, false);
};

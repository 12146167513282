import React from 'react';
import { panelThemeFactory } from 'styles/themes/panelTheme';
import { useStyles } from 'components/shared/layout/Main/Main.styles';
import { ThemeProvider } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import classNames from 'classnames';

interface MainProps {
  className?: string;
}

export const Main: React.FC<MainProps> = (props) => {
  const classes = useStyles();

  return (
    <main className={classNames(classes.main, props.className)}>
      <ThemeProvider theme={(outerTheme: Theme) => panelThemeFactory(outerTheme)}>
        <div className={classes.toolbar} />
        {props.children}
      </ThemeProvider>
    </main>
  );
};

import { RESET_STATE } from 'store/store';
import { UserActionType } from 'store/user/user.actionTypes';
import { defaultState, UserState } from 'store/user/user.state';
import { FSAReducer, Behaviours } from '@typings/redux';
import { fromApiFormat } from 'utils/api';
import { OfficialAccountType } from 'store/officials';

export const userReducer: FSAReducer<UserState, UserActionType> = (state = defaultState, action) => {
  const behaviours: Behaviours<UserState, UserActionType> = {
    [RESET_STATE]: () => ({
      ...defaultState,
    }),
    [UserActionType.FETCH_USER_INFO_SUCCESS]: ({ payload }) => {
      if (payload.user_settings.role === OfficialAccountType.APPLICANT) {
        window.location.href = `${window.location.origin}/applicant`;
        return null;
      }

      return {
        ...state,
        departmentSettings: {
          ...state.departmentSettings,
          ...fromApiFormat(payload).departmentSettings,
        },
        userSettings: {
          ...state.userSettings,
          ...fromApiFormat(payload).userSettings,
        },
      };
    },
    [UserActionType.FETCH_USER_PERMISSION_SUCCESS]: ({ payload }) => {
      const camelPayload = fromApiFormat(payload);
      if (!payload.permissions || payload.permissions.length === 0) {
        return {
          ...state,
          userSettings: {
            ...state.userSettings,
            permissionsReady: true,
            isExternalUser: camelPayload.isExternalUser,
          },
        };
      }
      const formatPermissions = camelPayload.permissions.reduce((acc, cur) => {
        if (!cur.resourcesGroup) return acc;
        acc[cur.resourcesGroup] = cur.accessType;
        return acc;
      }, {});
      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          permissions: formatPermissions,
          permissionsReady: true,
          isExternalUser: camelPayload.isExternalUser,
        },
      };
    },
    [UserActionType.FETCH_USER_PERMISSION_FAILURE]: () => {
      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          permissionsReady: true,
          isExternalUser: true,
        },
      };
    },
  };

  return behaviours[action.type] ? behaviours[action.type](action) : state;
};

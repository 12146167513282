import { RSAA, RSAACreator } from 'redux-api-middleware';
import { ApplicantPostModel } from './applicants.types';
import { ApplicantsActionType } from './applicants.actionTypes';
import { toApiFormat } from 'utils/api';
import { OfficialForm } from 'store/officials';

export const postApplicant: RSAACreator<ApplicantsActionType> = (payload: ApplicantPostModel, baseUrl: string) => {
  return {
    [RSAA]: {
      endpoint: `/applicants`,
      method: 'POST',
      types: [
        {
          type: ApplicantsActionType.POST_APPLICANT_REQUEST,
          meta: { lockScreen: true },
        },
        ApplicantsActionType.POST_APPLICANT_SUCCESS,
        ApplicantsActionType.POST_APPLICANT_FAILURE,
      ],
      params: toApiFormat({ baseUrl }),
      body: payload,
    },
  };
};

export const fetchApplicantDetails: RSAACreator<ApplicantsActionType> = (applicantId: string) => {
  return {
    [RSAA]: {
      endpoint: `/applicants/${applicantId}`,
      method: 'GET',
      types: [
        ApplicantsActionType.FETCH_APPLICANT_REQUEST,
        ApplicantsActionType.FETCH_APPLICANT_SUCCESS,
        {
          type: ApplicantsActionType.FETCH_APLICANT_FAILURE,
          meta: {
            ignoreNotFoundError: true,
          },
        },
      ],
    },
  };
};

export const updateApplicant: RSAACreator<ApplicantsActionType> = (
  applicantId: string,
  data: OfficialForm,
  baseUrl: string,
) => {
  return {
    [RSAA]: {
      endpoint: `/applicants/${applicantId}`,
      method: 'PUT',
      params: toApiFormat({ baseUrl }),
      types: [
        {
          type: ApplicantsActionType.UPDATE_APPLICANT_REQUEST,
          meta: { lockScreen: true },
        },
        ApplicantsActionType.UPDATE_APPLICANT_SUCCESS,
        {
          type: ApplicantsActionType.UPDATE_APPLICANT_FAILURE,
          meta: {
            ignoreNotFoundError: true,
          },
        },
      ],
      body: toApiFormat(data),
    },
  };
};

export const deleteApplicant: RSAACreator<ApplicantsActionType> = (applicantId: string, baseUrl: string) => {
  return {
    [RSAA]: {
      endpoint: `/applicants/${applicantId}`,
      method: 'DELETE',
      params: toApiFormat({ baseUrl }),
      types: [
        {
          type: ApplicantsActionType.DELETE_APPLICANT_REQUEST,
          meta: { lockScreen: true },
        },
        ApplicantsActionType.DELETE_APPLICANT_SUCCESS,
        {
          type: ApplicantsActionType.UPDATE_APPLICANT_FAILURE,
          meta: {
            ignoreNotFoundError: true,
          },
        },
      ],
    },
  };
};

export const resetPasswordApplicant: RSAACreator<ApplicantsActionType> = (applicantId: string, baseUrl: string) => {
  return {
    [RSAA]: {
      endpoint: `/applicants/${applicantId}/reset-password`,
      method: 'PUT',
      params: toApiFormat({ baseUrl }),
      types: [
        {
          type: ApplicantsActionType.RESET_PASSWORD_REQUEST,
          meta: { lockScreen: true },
        },
        ApplicantsActionType.RESET_PASSWORD_SUCCESS,
        {
          type: ApplicantsActionType.RESET_PASSWORD_FAILURE,
          meta: {
            ignoreNotFoundError: true,
          },
        },
      ],
    },
  };
};

import { SystemSettings, PollsSettings, UserSettings, LeadingAdress } from './systemSettings.types';

export interface SettingsState {
  system: {
    data: SystemSettings;
    loading: boolean;
    error: boolean;
  };
  polls: {
    data: PollsSettings;
    loading: boolean;
    error: boolean;
  };
  user: {
    data: UserSettings;
    loading: boolean;
    error: boolean;
  };
}

export const defaultState: SettingsState = {
  system: {
    data: {
      departmentName: '',
      osinCommune: '',
      additionalCities: [],
      additionalCommunes: [],
      leadingAddress: LeadingAdress.PERMANENT,
      belSynchronizationEnabled: false,
      schoolyearThreshold: '',
      documentsCirculationSystem: '',
      catchmentAssignmentTransferEnabled: false,
      studentAddressSynchronizationEnabled: false,
      institutionalCoreGrantEnabled: false,
    },
    loading: false,
    error: false,
  },
  polls: {
    data: {
      topic: '',
      messageContent: '',
      messageSignature: '',
    },
    loading: false,
    error: false,
  },
  user: {
    data: {
      dcsLogin: '',
    },
    loading: false,
    error: false,
  },
};

import React from 'react';
import { Button as ButtonMui } from '@material-ui/core';
import { ButtonProps as ButtonMuiProps } from '@material-ui/core/Button';

export const ButtonComponent = ({ children, ...props }: ButtonMuiProps, ref) => {
  return (
    <ButtonMui {...props} ref={ref}>
      {children}
    </ButtonMui>
  );
};

ButtonComponent.displayName = 'ButtonComponent';
export const Button = React.forwardRef(ButtonComponent);

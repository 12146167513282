import { NO_TERYT_ITEM_ID } from 'components/shared/teryt/TownPicker/TownPickerContainer';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { AddressPicker as AddressPickerComponent } from './AddressPicker';
import { AddressPickerContainerProps } from './AddressPicker.types';
import { useDispatch } from 'react-redux';
import { fetchStreetsList, fetchStreet } from 'store/teryt';
import { connect, FormikProps } from 'formik';
import { get } from 'lodash';
import { TextLoader } from 'components/shared/loaders/TextLoader/TextLoader';
import { useTranslator } from 'components/shared/hooks';
import { fromApiFormat } from 'utils/api';

export const AddressPicker: FC<
  AddressPickerContainerProps & {
    formik: FormikProps<{}>;
  }
> = ({
  fullAddress = false,
  fieldName = 'street',
  townFieldName = 'town',
  addressFieldName = 'address',
  osinLocked = false,
  formik,
  disabled,
  inputWrapperClass,
  displayTownInfo = false,
  noOptionsMessage,
  isCreatable,
  showNoOptionsMessage,
}) => {
  const trans = useTranslator();
  const streetValue = get(formik.values, fieldName);
  const [isInitialStreetLoading, setInitialStreetLoading] = useState(false);
  const [streetList, setStreetList] = useState([]);
  const [isStreetsLoading, setStreetsLoading] = useState(false);

  const dispatch: (...params: any) => Promise<any> = useDispatch();

  // Fetch initial street, set field error if TERYT ID wasn't found in database
  useEffect(() => {
    if (streetValue && streetValue.id && streetValue.id !== NO_TERYT_ITEM_ID) {
      setInitialStreetLoading(true);
      dispatch(fetchStreet(streetValue.id)).then((result) => {
        if (result.error) {
          if (result.payload.response.error && result.payload.status === 404) {
            formik.setFieldError(fieldName, trans(result.payload.response.error));
          }
          setInitialStreetLoading(false);
        } else {
          setStreetList([fromApiFormat(result.payload)]);
          setInitialStreetLoading(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch street list callback, called on inputChange
  const fetchStreetsListCallback = useCallback(
    (filters) => {
      setStreetsLoading(true);
      return dispatch(fetchStreetsList(filters)).then((result) => {
        if (!result.error && result.payload) {
          setStreetList(fromApiFormat(result.payload.items || []));
          setStreetsLoading(false);
        }
      });
    },
    [dispatch],
  );

  // Map fetched payload into street object
  const streetsOptions = useMemo(() => {
    return streetList.map((street) => ({
      value: street.id,
      label: displayTownInfo ? `${street.fullName} (${street.city.name})` : street.fullName,
    }));
  }, [displayTownInfo, streetList]);

  const renderLoader = () => <TextLoader name="spinner" text={trans('COMMON.LOADING_DATA')} height={66} />;
  const renderForm = () => (
    <AddressPickerComponent
      fullAddress={fullAddress}
      fieldName={fieldName}
      streetList={streetsOptions}
      fetchStreetList={fetchStreetsListCallback}
      isStreetsListLoading={isStreetsLoading}
      townFieldName={townFieldName}
      form={formik}
      disabled={disabled}
      addressFieldName={addressFieldName}
      osinLocked={osinLocked}
      inputWrapperClass={inputWrapperClass}
      noOptionsMessage={noOptionsMessage}
      clearStreetList={() => setStreetList([])}
      isCreatable={isCreatable}
      showNoOptionsMessage={showNoOptionsMessage}
    />
  );

  return <div className="container-fluid">{isInitialStreetLoading ? renderLoader() : renderForm()}</div>;
};

export const AddressPickerContainer = connect<AddressPickerContainerProps>(AddressPicker);

import { muiStyles } from 'utils/design';

export const useStyles = muiStyles(() => ({
  form: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
  },
  paper: {
    flexGrow: 1,
    overflowY: 'scroll',
    height: 0,
    display: 'flex',
  },
  editor: {
    maxWidth: '1000px',
  },
}));

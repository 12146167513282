import { RowNode } from 'ag-grid-community';
import { setFastAllSelection } from 'components/shared/data/DataGrid/utils/serverSideModelHelpers';

export const findDetailsGrid = (node) => {
  return node.gridApi.detailGridInfoMap[`detail_${node.id}`];
};

export const getSelectedRowsForMasterNodes = <T, D>(nodes: RowNode[], detailsMapping: (row: T) => D[]) => {
  const selectedRows: D[] = [];

  nodes.forEach((node) => {
    const detailsGrid = findDetailsGrid(node);

    if (detailsGrid) {
      // if grid exists take the selection from it
      selectedRows.push(...detailsGrid.api.getSelectedRows());
    } else {
      // if it doesn't exists add all (user never modified anything)
      selectedRows.push(...detailsMapping(node.data));
    }
  });

  return selectedRows;
};

export const updateMasterSelectionFromDetails = async (node) => {
  if (!node.detailNode) {
    return;
  }

  const hasSelected = node.detailNode.detailGridInfo.api.getSelectedNodes().length > 0;
  node.setSelected(hasSelected);
};

export const updateDetailsSelectionFromMaster = (node) => {
  const isSelected = node.isSelected();
  const detailsGrid = findDetailsGrid(node);

  if (!detailsGrid) {
    return;
  }

  if (isSelected && detailsGrid.api.getSelectedNodes().length === 0) {
    detailsGrid.api.selectAll();
  }
  if (!isSelected) {
    detailsGrid.api.deselectAll();
  }
};

export const deselectMasterAndDetails = (api) => {
  setFastAllSelection(api, false);
  api.forEachDetailGridInfo(function (detailGridApi) {
    setFastAllSelection(detailGridApi.api, false);
  });
};

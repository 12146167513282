import { Tooltip, Typography } from '@material-ui/core';
import { questionComponentMapping } from 'components/polls/shared/Question/mapping';
import { QuestionnaireProps } from 'components/polls/Questionnaire/Questionnaire.types';
import { Button } from 'components/shared/buttons/Button/Button';
import { Confirm } from 'components/shared/dialogs/Confirm/Confirm';
import { Form } from 'components/shared/form';
import { FieldArray } from 'components/shared/form/FieldArray/FieldArray';
import { useTranslator } from 'components/shared/hooks';
import { FontAwesomeIcon as IconType } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon.types';
import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon';
import { TextLoader } from 'components/shared/loaders/TextLoader/TextLoader';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import { Splash } from 'components/shared/surfaces/Splash/Splash';
import { Heading } from 'components/shared/typography/Heading/Heading';
import React, { FC, useState } from 'react';
import { QuestionnaireQuestion, ResponseForm } from 'store/polls';

export const Questionnaire: FC<QuestionnaireProps> = (props) => {
  const trans = useTranslator();
  const [confirmOpen, setConfirmOpen] = useState(false);

  const renderQuestion = (question: QuestionnaireQuestion, index) => {
    const QuestionComponent = questionComponentMapping[question.type];
    return <QuestionComponent question={question} field={`answers.${index}`} key={index} />;
  };

  const renderMessage = (icon: IconType, message: string) => {
    return (
      <Paper outerSpacing={[2, 0, 0]} innerSpacing={2}>
        <Splash>
          <FontAwesomeIcon name={icon} size={'4x'} className={'mb-3'} />
          <Heading type={'h5'}>{message}</Heading>
        </Splash>
      </Paper>
    );
  };

  if (props.lockMessage) {
    return renderMessage('window-close', trans(props.lockMessage));
  }

  if (props.submitted) {
    return renderMessage('check-circle', trans('POLLS.QUESTIONNAIRE.SUBMIT_SUCCESS'));
  }

  if (!props.questionnaire || props.isLoading) {
    return (
      <Paper outerSpacing={[2, 0]}>
        <TextLoader text={trans('POLLS.QUESTIONNAIRE.LOADING')} name={'spinner'} />
      </Paper>
    );
  }

  return (
    <Form<ResponseForm> initialValues={props.initial} onSubmit={props.onSave}>
      {({ submitForm, dirty }) => {
        return (
          <>
            <Paper innerSpacing={[3, 2]} outerSpacing={[2, 0, 0]}>
              <Heading type="h1" className="mb-3">
                {props.questionnaire.name}
              </Heading>

              <Typography variant={'body1'} className="mb-4">
                {props.questionnaire.description}
              </Typography>

              <FieldArray name="answers">
                {() => {
                  return props.questionnaire.questions.map(renderQuestion);
                }}
              </FieldArray>
            </Paper>
            <Tooltip title={!dirty ? trans('POLLS.QUESTIONNAIRE.BLOCK_EMPTY') : ''} placement="top">
              <span>
                <Button
                  color="secondary"
                  variant="contained"
                  className="my-3"
                  onClick={() => setConfirmOpen(true)}
                  disabled={!dirty}
                >
                  {trans('COMMON.SEND')}
                </Button>
              </span>
            </Tooltip>
            <Confirm
              title={'POLLS.QUESTIONNAIRE.CONFIRM.TITLE'}
              message={'POLLS.QUESTIONNAIRE.CONFIRM.MESSAGE'}
              open={confirmOpen}
              confirm={() => {
                submitForm();
                setConfirmOpen(false);
              }}
              cancel={() => setConfirmOpen(false)}
            />
          </>
        );
      }}
    </Form>
  );
};

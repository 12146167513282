import { muiStyles } from 'utils/design';

export const useStyles = muiStyles({
  input: {
    width: 300,
    '& fieldset': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    '&.Mui-disabled': {
      color: 'unset',
    },
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginLeft: 5,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  fileInput: {
    display: 'none',
  },
  button: {
    boxShadow: 'none',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
});

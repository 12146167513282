import React, { FC, useState, useRef } from 'react';
import { Button, Collapse } from '@material-ui/core';
import { UploadFile } from 'components/shared/form/UploadFile/UploadFile';
import { StepActions, StepContent, BackButton, NextButton } from 'components/shared/dialogs/StepModal';
import { Form, RadioBox } from 'components/shared/form';
import { FileUploadForm, FileImportProps } from './FileImports.types';
import { useFormatter, useTranslator } from 'components/shared/hooks';
import { FormikHelpers } from 'formik';
import { FileImportTypes } from 'store/childRegister';
import { isArray } from 'lodash';
import { useStyles } from './FileImport.styles';
import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

export const FileImport: FC<FileImportProps> = ({
  postChildRegisterImport,
  deleteChildRegisterImport,
  onClose,
  token,
  goNext,
  file,
  importType,
}) => {
  const [isManualOpen, setManual] = useState(false);
  const form = useRef(null);
  const trans = useTranslator();
  const { formatApiErrors } = useFormatter();
  const classes = useStyles();

  const handlePostStudentRegisterImport = (response, formik: FormikHelpers<FileUploadForm>) => {
    if (response.error) {
      if (response.payload && response.payload.response && response.payload.response.errors) {
        const errors = response.payload.response.errors;
        if (isArray(errors)) {
          formik.setFieldError('importFile', trans(response.payload.response.errors[0]));
        } else {
          formik.setErrors(formatApiErrors(errors));
        }
      } else {
        // when file is very big error 413 is throw by ngnix and we need to handle it
        formik.setFieldError('importFile', trans('CHILD_REGISTER.IMPORT.TOO_LARGE_FILE'));
      }
      formik.setSubmitting(false);
      return;
    }

    goNext();
  };

  const uploadFile = (values: FileUploadForm, formik: FormikHelpers<FileUploadForm>) => {
    if (values.importFile) {
      postChildRegisterImport(values.importFile, values.type).then((response) =>
        handlePostStudentRegisterImport(response, formik),
      );
    } else {
      if (!token) {
        postChildRegisterImport('', values.type).then((response) => handlePostStudentRegisterImport(response, formik));
      }
    }
  };

  const handleSubmit = (values: FileUploadForm, formik: FormikHelpers<FileUploadForm>) => {
    if (token) {
      deleteChildRegisterImport().then(() => uploadFile(values, formik));
    } else {
      uploadFile(values, formik);
    }
  };

  return (
    <>
      <StepContent>
        <div className="container pb-1 pt-1 text-center">
          <div className="row justify-content-center mb-4">
            <Button
              onClick={() => setManual((isManualOpen) => !isManualOpen)}
              color="primary"
              variant="contained"
              className={classes.instructionBtn}
            >
              <FontAwesomeIcon name={'info-circle'} size={'lg'} className={'mr-2'} />
              {trans('CHILD_REGISTER.IMPORT_STEPS.FILE.MANUAL')}
            </Button>
          </div>
          <Collapse in={isManualOpen} unmountOnExit={true}>
            <Typography variant="body2" component="div">
              <div className="row d-flex flex-column text-left align-items-center">
                <div className="row col-6 mb-3">{trans('CHILD_REGISTER.IMPORT_STEPS.FILE.MANUAL_PLACEHOLDER')}</div>
                <div className="row col-6 mb-1">{trans('CHILD_REGISTER.IMPORT_STEPS.FILE.FILE_TEMPLATE')}</div>
                <div className="row col-6 d-flex flex-column mb-3">
                  <Link href={'/templates/KED-podstawowa.csv'} download="KED-podstawowa.csv">
                    {trans('CHILD_REGISTER.IMPORT_STEPS.FILE.KED_BASIC_TEMPLATE')}
                  </Link>
                  <Link href={'/templates/KED-rozszerzona.csv'} download="KED-rozszerzona.csv">
                    {trans('CHILD_REGISTER.IMPORT_STEPS.FILE.KED_FULL_TEMPLATE')}
                  </Link>
                </div>
                <div className="row col-6 mb-1">{trans('CHILD_REGISTER.IMPORT_STEPS.FILE.NOTICE')}</div>
              </div>
            </Typography>
          </Collapse>
          <div className="row justify-content-center mt-3">
            <Form<FileUploadForm>
              initialValues={{ importFile: file, type: importType }}
              onSubmit={handleSubmit}
              innerRef={form}
            >
              {() => {
                return (
                  <>
                    <div className="row mt-4">
                      <div className="col-6">
                        <RadioBox
                          name="type"
                          labelText={trans('CHILD_REGISTER.IMPORT_STEPS.FILE.TYPE.FULL')}
                          value={FileImportTypes.FULL}
                        />
                      </div>
                      <div className="col-6">
                        <RadioBox
                          name="type"
                          labelText={trans('CHILD_REGISTER.IMPORT_STEPS.FILE.TYPE.PARTIAL')}
                          value={FileImportTypes.PARTIAL}
                        />
                      </div>
                    </div>
                    <UploadFile
                      label={trans('CHILD_REGISTER.IMPORT_STEPS.FILE.UPLOAD')}
                      name="importFile"
                      required
                      defaultLabelWidth={92}
                    />
                  </>
                );
              }}
            </Form>
          </div>
        </div>
      </StepContent>
      <StepActions>
        <BackButton onClick={onClose}>{trans('COMMON.CANCEL')}</BackButton>
        <NextButton onClick={() => !form.current.isSubmitting && form.current.handleSubmit()}>
          {trans('COMMON.NEXT')}
        </NextButton>
      </StepActions>
    </>
  );
};

import { ColumnsConfig } from 'components/shared/data/DataGrid/DataGrid.types';
import { RecipientGroup, SynchronizedInstitution } from 'store/polls';
import { recipientGroupSlug } from 'utils/i18n/recipientGroup';

export const institutionsColumns: ColumnsConfig<SynchronizedInstitution, void> = () => {
  return [
    {
      field: 'name',
      name: () => '',
      columnParams: {
        headerCheckboxSelection: true,
        checkboxSelection: true,
        width: 50,
        maxWidth: 50,
      },
    },
    {
      field: 'name',
      name: 'POLLS.PUBLICATION.INSTITUTIONS.NAME_COLUMN',
    },
  ];
};

export const recipientsGroupsColumns: ColumnsConfig<RecipientGroup, void> = () => {
  return [
    {
      field: 'name',
      name: () => '',
      columnParams: {
        headerCheckboxSelection: true,
        checkboxSelection: true,
        width: 50,
        maxWidth: 50,
      },
    },
    {
      field: 'name',
      name: 'POLLS.PUBLICATION.RECIPIENT_GROUPS.NAME_COLUMN',
      format: (data, trans) => {
        return trans(recipientGroupSlug(data.name));
      },
    },
  ];
};

import { AppDispatch } from '@typings/redux';
import React, { FC, useRef } from 'react';
import { Form, InputText } from 'components/shared/form';
import { useTranslator } from 'components/shared/hooks/useTranslator/useTranslator';
import { AutoSave } from 'components/shared/form/AutoSave/AutoSave';
import { useDispatch, useSelector } from 'react-redux';
import { getDistrictsSchoolFiltersSelector, SchoolListFilters, setSchoolListFilters } from 'store/schoolDistricts';

export const SchoolsListForm: FC = () => {
  const form = useRef(null);
  const trans = useTranslator();
  const dispatch = useDispatch<AppDispatch>();
  const filters = useSelector(getDistrictsSchoolFiltersSelector);

  const valuesChanged = ({ search }) => {
    dispatch(setSchoolListFilters({ ...filters, search }));
  };

  return (
    <Form<SchoolListFilters> initialValues={filters} onSubmit={valuesChanged} innerRef={form}>
      {() => (
        <>
          <InputText
            name="search"
            placeholder={trans('SCHOOL_DISTRICTS.DISTRICTS-LIST.SCHOOL-LIST.PLACEHOLDER')}
            resettable
          />
          <AutoSave />
        </>
      )}
    </Form>
  );
};

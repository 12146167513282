import { ChildRegisterFormValues } from 'components/childRegister/Editor/General/General.types';
import { Exemptions } from 'components/childRegister/Editor/Obligation/parts/Exemptions/Exemptions';
import { useExemptionBirthYearsValidation } from 'components/childRegister/Editor/Obligation/useExemptionBirthYearsValidation';
import { useDepartmentSettings } from 'components/shared/hooks/useInstanceConfig/useDepartmentSettings';
import { connect } from 'formik';
import React, { ComponentType, useCallback, useMemo } from 'react';
import { getSchoolYears } from 'utils/date/getSchoolYears';

export const ExemptionsContainer: ComponentType = connect<{}, ChildRegisterFormValues>((props) => {
  const settings = useDepartmentSettings();
  const {
    setFieldValue,
    values: { exemptions, birthday },
  } = props.formik;

  const schoolYears = useMemo(() => {
    return birthday ? getSchoolYears(birthday, settings.schoolyearThreshold) : [];
  }, [birthday, settings.schoolyearThreshold]);

  useExemptionBirthYearsValidation(
    exemptions,
    birthday,
    schoolYears,
    useCallback(
      (nextExemptions) => {
        setFieldValue('exemptions', nextExemptions);
      },
      [setFieldValue],
    ),
  );

  return <Exemptions schoolYears={schoolYears} exemptions={exemptions} />;
});

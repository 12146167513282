import { EducationPathRow } from 'components/childRegister/Editor/EducationPath/EducationPath.types';
import { ColumnsConfig } from 'components/shared/data/DataGrid/DataGrid.types';

export const educationPathColumns: ColumnsConfig<EducationPathRow> = () => [
  {
    field: 'schoolYear',
    name: 'CHILD_REGISTER.EDUCATION_PATH.COLUMNS.SCHOOL_YEAR',
  },
  {
    field: 'from',
    name: 'CHILD_REGISTER.EDUCATION_PATH.COLUMNS.FROM',
    format: (data, trans, formatter) => {
      return formatter.formatDate(data.from);
    },
  },
  {
    field: 'to',
    name: 'CHILD_REGISTER.EDUCATION_PATH.COLUMNS.TO',
    format: (data, trans, formatter) => {
      return data.to ? formatter.formatDate(data.to) : '-';
    },
  },
  {
    field: 'educationStage.name',
    name: 'CHILD_REGISTER.EDUCATION_PATH.COLUMNS.EDUCATION_STAGE',
  },
  {
    field: 'institutionName',
    name: 'CHILD_REGISTER.EDUCATION_PATH.COLUMNS.INSTITUTION_NAME',
  },
  {
    field: 'unitName',
    name: 'CHILD_REGISTER.EDUCATION_PATH.COLUMNS.UNIT_NAME',
  },
  {
    field: 'level',
    name: 'CHILD_REGISTER.EDUCATION_PATH.COLUMNS.LEVEL',
  },
];

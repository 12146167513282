import {
  ChildrenListRow,
  FulfillmentUnitRow,
} from 'components/schoolObligation/ReportDetails/ReportOverview/ReportOverview.types';
import { ButtonDefinition } from 'components/shared/data/DataGrid/renderers/cell/Buttons/Buttons.types';
import { ColumnsConfig } from 'components/shared/data/DataGrid/DataGrid.types';

export const reportChildrenListColumns: ColumnsConfig<ChildrenListRow> = (parameters) => {
  return [
    {
      field: 'lastName',
      name: 'SCHOOL_OBLIGATION.REPORT_CHILDREN_LIST.COLUMNS.LAST_NAME',
      renderer: 'agGroupCellRenderer',
    },
    {
      field: 'firstName',
      name: 'SCHOOL_OBLIGATION.REPORT_CHILDREN_LIST.COLUMNS.FIRST_NAME',
    },
    {
      field: 'identificationNumber',
      name: 'SCHOOL_OBLIGATION.REPORT_CHILDREN_LIST.COLUMNS.IDENTIFICATION_NUMBER',
    },
    {
      field: 'birthYear',
      name: 'SCHOOL_OBLIGATION.REPORT_CHILDREN_LIST.COLUMNS.BIRTH_YEAR',
    },
    {
      field: 'fulfillment',
      name: 'SCHOOL_OBLIGATION.REPORT_CHILDREN_LIST.COLUMNS.OBLIGATION',
      format: (data, trans) => {
        return trans(`SCHOOL_OBLIGATION.REPORT_CHILDREN_LIST.VALUES.OBLIGATION.${data.obligation.toUpperCase()}`);
      },
    },
    {
      name: 'INSTITUTIONS.BROWSER.COLUMNS.ACTIONS',
      field: 'id',
      cellTooltip: () => '',
      renderer: 'buttonsCell',
      rendererParams: {
        buttons: (): ButtonDefinition[] => {
          return [
            {
              type: 'default',
              size: 'small',
              variant: 'outlined',
              icon: 'eye',
              label: 'COMMON.DISPLAY',
              tooltip: 'COMMON.DISPLAY',
              action: parameters.show,
            },
          ];
        },
      },
      columnParams: { minWidth: 200 },
    },
  ];
};

export const reportChildrenListUnitsColumns: ColumnsConfig<FulfillmentUnitRow> = () => {
  return [
    {
      field: 'studentRegisterDate',
      name: 'SCHOOL_OBLIGATION.REPORT_CHILDREN_LIST.COLUMNS.STUDENT_REGISTER_DATE',
      format: (data, trans, formatter) => {
        return formatter.formatDate(data.studentRegisterDate);
      },
    },
    {
      field: 'institutionName',
      name: 'SCHOOL_OBLIGATION.REPORT_CHILDREN_LIST.COLUMNS.INSTITUTION_NAME',
    },
    {
      field: 'unitName',
      name: 'SCHOOL_OBLIGATION.REPORT_CHILDREN_LIST.COLUMNS.UNIT_NAME',
    },
    {
      field: 'educationStage',
      name: 'SCHOOL_OBLIGATION.REPORT_CHILDREN_LIST.COLUMNS.EDUCATION_STAGE',
      format: (data) => data.educationStage.name,
    },
    {
      field: 'level',
      name: 'SCHOOL_OBLIGATION.REPORT_CHILDREN_LIST.COLUMNS.LEVEL',
    },
  ];
};

import { useUnmountableCallback } from 'components/shared/hooks';
import { useEffect, useRef, useState } from 'react';

export const useGridComposer = (gridApi, columnApi, disableAutoSize: boolean) => {
  const [gridVisible, setGridVisible] = useState(false);
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const composeGrid = useUnmountableCallback(() => {
    if (!disableAutoSize && gridApi && columnApi) {
      columnApi.autoSizeColumns();
      //wait for vertical scroll to get width of table
      setTimeout(() => {
        columnApi.sizeColumnsToFit(gridApi.getHorizontalPixelRange().right - 1);
        setTimeout(() => setGridVisible(true));
      }, 800);
    } else if (disableAutoSize && mounted.current) {
      setGridVisible(true);
    }
  }, [disableAutoSize, gridApi, columnApi]);

  return { composeGrid, gridVisible };
};

import { muiStyles } from 'utils/design';
import { colors } from 'styles/colors';

export const useStyles = muiStyles((theme) => ({
  error: {
    flexBasis: 0,
    flexGrow: 1,
    backgroundColor: colors.red,
  },
  warning: {
    flexBasis: 0,
    flexGrow: 1,
    backgroundColor: `${colors.orange} !important`,
  },
  infobarWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: theme.spacing(0, 0, 1, 0),

    '& > div': {
      marginRight: theme.spacing(1),
    },

    '& > :last-child': {
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(0),
    },
  },
  alertContent: {
    display: 'flex',
    alignItems: 'center',
  },
  alertIcon: {
    fontSize: '1rem',
  },
}));

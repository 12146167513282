import { muiStyles } from 'utils/design';

export const useStyles = muiStyles((theme) => ({
  paper: {
    overflow: 'scroll',
    flexGrow: 1,
    height: 0,
    minHeight: '500px',
    padding: theme.spacing(2),
  },
}));

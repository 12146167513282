import { TabToNextCellParams, CellPosition } from 'ag-grid-community';
import { GridSize } from 'components/shared/data/DataGrid/utils/getGridSize';

interface TabToNext {
  (params: TabToNextCellParams): CellPosition;
}

interface UseTabbingManagerHook {
  (gridSize: GridSize, focusOutBefore: Function, focusOutNext: Function): TabToNext;
}

/**
 * This helper currently helps only with tabbing outside the grid.
 * For the code responsible for focusing buttons inside grid see
 * components/shared/data/DataGrid/CellRenderer/Buttons/useFocusManager.ts
 */
export const getTabKeyHandler: UseTabbingManagerHook = ({ width, height }, focusOutBefore, focusOutNext) => {
  return (params) => {
    const { backwards, nextCellPosition, previousCellPosition } = params;

    const currentRow = previousCellPosition.rowIndex;
    const currentColumn = parseInt(previousCellPosition.column.getColId());

    if (backwards && currentRow === 0 && currentColumn === 0) {
      focusOutBefore && focusOutBefore();
      return null;
    }

    if (nextCellPosition === null || (!backwards && currentRow + 1 === height && currentColumn + 1 === width)) {
      focusOutNext && focusOutNext();
      return null;
    }

    return nextCellPosition;
  };
};

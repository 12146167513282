import { DialogActions, Typography } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useStyles } from 'components/schoolDistricts/DistrictsBrowser/AddressRuleModal/AddressRuleModal.styles';
import { AddressRuleFormProps } from 'components/schoolDistricts/DistrictsBrowser/AddressRuleModal/AddressRuleModal.types';
import { Button } from 'components/shared/buttons/Button/Button';
import { Form, InputText, RadioBox } from 'components/shared/form';
import { useTranslator } from 'components/shared/hooks';
import { ExpandablePanel } from 'components/shared/surfaces/ExpandablePanel/ExpandablePanel';
import { AddressPickerContainer } from 'components/shared/teryt/AddressPicker/AddressPickerContainer';
import React, { FC, useRef } from 'react';
import { AddressRuleFormModel, SchoolRangeSelection } from 'store/schoolDistricts';

export const AddressRuleForm: FC<AddressRuleFormProps> = (props) => {
  const trans = useTranslator();
  const classes = useStyles();
  const form = useRef(null);

  const defaultValues: AddressRuleFormModel = {
    street: { id: null },
    rangeStart: null,
    rangeEnd: null,
    rangeSelection: SchoolRangeSelection.ALL,
    included: null,
    excluded: null,
  };

  const isAdvancedExpanded = () => {
    return ['rangeStart', 'rangeEnd', 'included', 'excluded'].some((field) => {
      return props.values && !!props.values[field];
    });
  };

  return (
    <Form<AddressRuleFormModel> initialValues={props.values || defaultValues} onSubmit={props.onSubmit} innerRef={form}>
      {({ isSubmitting, handleSubmit }) => (
        <>
          <DialogTitle id="confirm-title">
            {props.values
              ? trans('SCHOOL_DISTRICTS.DISTRICTS_MODAL.TITLE_EDIT')
              : trans('SCHOOL_DISTRICTS.DISTRICTS_MODAL.TITLE_ADD')}
          </DialogTitle>
          <DialogContent className={classes.content}>
            <AddressPickerContainer
              inputWrapperClass={{ street: 'col-12' }}
              osinLocked={true}
              displayTownInfo={true}
              noOptionsMessage="SCHOOL_DISTRICTS.DISTRICTS_MODAL.NO_STREET"
              showNoOptionsMessage={true}
              isCreatable={false}
            />
            <div className="container-fluid">
              <div className="row">
                <div className="col-4">
                  <RadioBox
                    name="rangeSelection"
                    labelText={trans('SCHOOL_DISTRICTS.DISTRICTS_MODAL.RANGE_SELECTION.ALL')}
                    value="all"
                  />
                </div>
                <div className="col-4">
                  <RadioBox
                    name="rangeSelection"
                    labelText={trans('SCHOOL_DISTRICTS.DISTRICTS_MODAL.RANGE_SELECTION.EVEN')}
                    value="even"
                  />
                </div>
                <div className="col-4">
                  <RadioBox
                    name="rangeSelection"
                    labelText={trans('SCHOOL_DISTRICTS.DISTRICTS_MODAL.RANGE_SELECTION.ODD')}
                    value="odd"
                  />
                </div>
              </div>
            </div>
            <div className="m-2 mb-4">
              <ExpandablePanel
                title={trans('SCHOOL_DISTRICTS.DISTRICTS_MODAL.ADVANCED')}
                defaultExpanded={isAdvancedExpanded()}
              >
                <div className="container pt-3">
                  <div className="row">
                    <div className="col-3">
                      <Typography className={classes.advancedLabel}>
                        {trans('SCHOOL_DISTRICTS.DISTRICTS_MODAL.RANGE.LABEL')}
                      </Typography>
                    </div>
                    <div className="col-9">
                      <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-3">
                          <Typography className={classes.advancedPrefix}>
                            {trans('SCHOOL_DISTRICTS.DISTRICTS_MODAL.RANGE.FROM')}
                          </Typography>
                        </div>
                        <div className="col-3">
                          <InputText name="rangeStart" className={classes.rangeInput} />
                        </div>
                        <div className="col-3">
                          <Typography className={classes.advancedPrefix}>
                            {trans('SCHOOL_DISTRICTS.DISTRICTS_MODAL.RANGE.TO')}
                          </Typography>
                        </div>
                        <div className="col-3">
                          <InputText name="rangeEnd" className={classes.rangeInput} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-3">
                      <Typography className={classes.advancedLabel}>
                        {trans('SCHOOL_DISTRICTS.DISTRICTS_MODAL.EXCLUDED.LABEL')}
                      </Typography>
                    </div>
                    <div className="col-9">
                      <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-12">
                          <InputText
                            name="excluded"
                            placeholder={trans('SCHOOL_DISTRICTS.DISTRICTS_MODAL.EXCLUDED.PLACEHOLDER')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-3">
                      <Typography className={classes.advancedLabel}>
                        {trans('SCHOOL_DISTRICTS.DISTRICTS_MODAL.INCLUDED.LABEL')}
                      </Typography>
                    </div>
                    <div className="col-9">
                      <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-12">
                          <InputText
                            name="included"
                            placeholder={trans('SCHOOL_DISTRICTS.DISTRICTS_MODAL.INCLUDED.PLACEHOLDER')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ExpandablePanel>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.onClose} color="secondary" variant="outlined">
              {trans('COMMON.CANCEL')}
            </Button>
            <Button
              onClick={() => !isSubmitting && handleSubmit()}
              disabled={isSubmitting}
              color="secondary"
              variant="contained"
            >
              {trans('COMMON.SAVE')}
            </Button>
          </DialogActions>
        </>
      )}
    </Form>
  );
};

import React, { FC } from 'react';
import { PollsProps } from 'components/settings/Polls/Polls.types';
import { InputText } from 'components/shared/form';
import { useTranslator } from 'components/shared/hooks';
import { Heading } from 'components/shared/typography/Heading/Heading';

export const Polls: FC<PollsProps> = () => {
  const trans = useTranslator();

  return (
    <div className="container-fluid pb-4">
      <Heading type="h6" className="font-weight-normal mb-2">
        {trans('SETTINGS.POLL.TITLE')}
      </Heading>
      <div className="ml-3">
        <div className="row">
          <div className="col-5 mb-1">
            <InputText name="topic" labelText={trans('SETTINGS.POLL.FIELDS.POLL_TITLE')} required />
          </div>
        </div>
        <div className="row">
          <div className="col-5 mb-1">
            <InputText
              name="messageContent"
              labelText={trans('SETTINGS.POLL.FIELDS.POLL_MESSAGE')}
              multiline={true}
              rowsMax={8}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <InputText
              name="messageSignature"
              labelText={trans('SETTINGS.POLL.FIELDS.POLL_SIGNATURE')}
              multiline={true}
              rowsMax={8}
              required
            />
          </div>
        </div>
      </div>
    </div>
  );
};

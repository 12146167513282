import { Paper } from '@material-ui/core';
import { OptionType } from 'components/shared/form/Autocomplete/Autocomplete.types';
import React from 'react';
import { MenuProps } from 'react-select';

export function Menu(props: MenuProps<OptionType, boolean>) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

import { Pagination } from '@typings/common';
import {
  DataSourceParams,
  Fetcher,
  PageHandlersReducer,
  Provider,
} from 'components/shared/data/DataGrid/DataGrid.types';
import { useCallback, useEffect, useReducer } from 'react';
import { pickBy, identity, map, omit } from 'lodash';

const getTotal = (total: number, fetchedItemsCount?: number): number => {
  if (fetchedItemsCount) {
    return fetchedItemsCount === total ? total : -1;
  }
  return total;
};

export const usePagination = <T>(
  gridApi: any,
  fetcher: Fetcher<T>,
  provider: Provider<T>,
  { limit, total }: Pagination,
  fetchedItemsCount?: number,
) => {
  const [pageHandlers, setPageHandler] = useReducer<PageHandlersReducer<T>>((list, action) => {
    if (action.type === 'add') {
      return pickBy({ ...list, [action.page]: action.handler }, identity);
    } else if (action.type === 'remove') {
      return omit(list, action.page);
    } else if (action.type === 'reset') {
      return {};
    }
  }, {});

  useEffect(() => {
    const getRows = (params: DataSourceParams<T>) => {
      const page = params.request.startRow / limit + 1;
      fetcher(page, params);

      setPageHandler({
        type: 'add',
        page: page.toString(),
        endRow: params.request.endRow,
        handler: params.successCallback,
      });
    };

    if (gridApi && fetcher) {
      gridApi.setServerSideDatasource({ getRows });
    }
  }, [fetcher, gridApi, limit]);

  useEffect(() => {
    map(pageHandlers, (handler, page) => {
      const rows = provider(page);
      if (rows) {
        handler(rows, getTotal(total, fetchedItemsCount));
        setPageHandler({ type: 'remove', page });
      }
    });
  }, [pageHandlers, provider, total, fetchedItemsCount]);

  const resetPagination = useCallback(() => {
    setPageHandler({ type: 'reset' });
    gridApi.purgeServerSideCache();
  }, [gridApi]);

  return {
    resetPagination,
  };
};

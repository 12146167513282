import { ActionButton } from 'components/shared/buttons/ActionButton/ActionButton';
import { useStyles } from 'components/shared/data/DataGrid/renderers/cell/ForecastModificator/ForecastManipulator.styles';
import {
  ForecastAction,
  ForecastManipulatorProps,
  ManipulatorConfig,
} from 'components/shared/data/DataGrid/renderers/cell/ForecastModificator/ForecastManipulator.types';
import { PercentFormatter } from 'components/shared/data/DataGrid/renderers/cell/ForecastModificator/PercentFormatter/PercentFormatter';
import { Form } from 'components/shared/form';
import { AutoSave } from 'components/shared/form/AutoSave/AutoSave';
import { NumberField } from 'components/shared/form/NumberField/NumberField';
import { useTranslator } from 'components/shared/hooks';
import { Tooltip } from 'components/shared/messages/Tooltip/Tooltip';
import { FormikProps } from 'formik';
import React, { useEffect } from 'react';
import { realNumberRequired } from 'utils/formValidators/realNumberRequired/realNumberRequired';

export const ForecastManipulator = (props: ForecastManipulatorProps) => {
  const classes = useStyles();
  const trans = useTranslator();

  useEffect(() => {
    const classList = props.reactContainer.classList;
    if (!classList.contains(classes.container)) {
      props.reactContainer.classList.add(classes.container);
    }
    return () => props.reactContainer.classList.remove(classes.container);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (props.hide(props.data)) {
    return <span />;
  }

  const onChange = ({ action, actionValue }: ManipulatorConfig) => {
    const value = {
      action,
      actionValue: actionValue.toString().replace(',', '.'),
    };

    props.onChange(props.data, value, props.api);
    props.node.setDataValue('action', value);
  };

  const initial: ManipulatorConfig = {
    action: props.data.action,
    actionValue: props.data.actionValue,
  };

  return (
    <Form<ManipulatorConfig> initialValues={initial} onSubmit={onChange}>
      {({ values, errors, setFieldValue }: FormikProps<ManipulatorConfig>) => {
        return (
          <>
            <div className={classes.box}>
              <div className={classes.action}>
                <ActionButton
                  color="primary"
                  variant={'outlined'}
                  size={'small'}
                  label={`REPORTS.SUBVENTION_FORECAST.ACTIONS.${values.action.toUpperCase()}`}
                  actionList={[
                    {
                      action: () => setFieldValue('action', ForecastAction.COPY),
                      label: 'REPORTS.SUBVENTION_FORECAST.ACTIONS.COPY',
                      icon: 'arrow-right',
                    },
                    {
                      action: () => setFieldValue('action', ForecastAction.MODIFY),
                      label: 'REPORTS.SUBVENTION_FORECAST.ACTIONS.MODIFY',
                      icon: 'percent',
                    },
                    {
                      action: () => setFieldValue('action', ForecastAction.FORECAST),
                      label: 'REPORTS.SUBVENTION_FORECAST.ACTIONS.FORECAST',
                      icon: 'bolt',
                    },
                  ]}
                />
              </div>
              <div className={classes.actionValue}>
                {values.action === ForecastAction.MODIFY && (
                  <Tooltip title={errors.actionValue || ''} open={!!errors.actionValue} placement={'right'}>
                    <NumberField
                      name={'actionValue'}
                      validate={realNumberRequired(trans('REPORTS.SUBVENTION_FORECAST.INVALID_ACTION_VALUE'), {
                        allowPolishNotation: true,
                        allowSingleDash: true,
                      })}
                      placeholder={'0%'}
                      inputComponent={PercentFormatter as any}
                      step={0.1}
                    />
                  </Tooltip>
                )}
              </div>
            </div>
            <AutoSave />
          </>
        );
      }}
    </Form>
  );
};

import { AppState } from 'store/store';
import { AccessRule } from 'store/accessRules';
import {
  OfficialAccountType,
  OfficialPermissions,
  OfficialResourceGroup,
  OfficialPermissionLevel,
} from 'store/officials';
import { User, UserWithFormatPermision } from 'store/userList';
import find from 'lodash/find';

const formatUserPersmisions = (permissions: OfficialPermissions[], accessRules: AccessRule[]): string[] => {
  const formatOfficialsPermission = (permission: OfficialResourceGroup): string => {
    const accessRule = find(accessRules, { name: permission });
    return accessRule?.translationKey || 'OFFICIALS.PERMISSIONS.NOT_FOUND';
  };
  if (!permissions) {
    return [];
  }
  const usePermissions = permissions.filter((permission) => permission.accessType !== OfficialPermissionLevel.noAccess);
  if (usePermissions.length === 0) return [];

  const mapedPermisions = usePermissions.map((permission) => {
    return formatOfficialsPermission(permission.resourcesGroup);
  });

  return mapedPermisions;
};

export const formatUserList = (state: AppState) => {
  const { list } = state.userList;
  const accessRules = state.accessRules.items;
  const { items, loading, error } = list;
  const useItem: UserWithFormatPermision[] = items.map((user: User) => {
    if (user.role === OfficialAccountType.ADMIN) {
      return {
        ...user,
        formatPermissions: ['OFFICIALS.PERMISSIONS.ADMIN'],
      };
    } else if (user.role === OfficialAccountType.APPLICANT) {
      return {
        ...user,
        formatPermissions: ['OFFICIALS.PERMISSIONS.APPLICANT'],
      };
    }
    return {
      ...user,
      formatPermissions: formatUserPersmisions(user.permissions, accessRules),
    };
  });

  return {
    items: useItem,
    loading,
    error,
  };
};

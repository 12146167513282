import { parse, isAfter, isBefore } from 'date-fns';

export enum ThresholdComparison {
  BEFORE = -1,
  DURING = 0,
  AFTER = 1,
}

export const compareWithThreshold = (date: Date, threshold: string) => {
  date.setHours(0, 0, 0);

  const thresholdDate = parse(threshold, 'MMdd', date);

  if (isBefore(date, thresholdDate)) {
    return ThresholdComparison.BEFORE;
  }

  if (isAfter(date, thresholdDate)) {
    return ThresholdComparison.AFTER;
  }

  return ThresholdComparison.DURING;
};

import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@material-ui/core';
import classNames from 'classnames';
import { CheckBoxProps } from 'components/shared/form/CheckBox/CheckBox';
import { Field, FieldProps } from 'formik';
import React, { FC } from 'react';
import { get } from 'lodash';

export interface CheckBoxListOption {
  value: string;
  label: string;
}

export interface CheckBoxListProps {
  name: string;
  options: CheckBoxListOption[];
  checkboxProps?: CheckBoxProps;
  containerClassName: string;
  disabled?: boolean;
}

export const CheckBoxList: FC<CheckBoxListProps> = ({
  name,
  options,
  disabled,
  containerClassName,
  checkboxProps = {},
}) => {
  return (
    <div className={containerClassName}>
      <Field name={name}>
        {({ field, form }: FieldProps) => {
          const isError = !!(get(form.errors, field.name) && get(form.touched, field.name));
          return (
            <FormControl error={isError} className={classNames({ hasValidationError: isError })}>
              {options.map(({ label, value: optionValue }, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      onChange={(event, checked) => {
                        const value = [...field.value];

                        if (checked) {
                          value.push(optionValue);
                        } else {
                          value.splice(get(form.values, name).indexOf(optionValue), 1);
                        }

                        form.setFieldValue(field.name, value);
                      }}
                      checked={get(form.values, name, []).includes(optionValue)}
                      color="default"
                      disabled={disabled}
                      {...checkboxProps}
                    />
                  }
                  label={label}
                />
              ))}
              {isError && <FormHelperText error>{get(form.errors, field.name)}</FormHelperText>}
            </FormControl>
          );
        }}
      </Field>
    </div>
  );
};

import { useTranslator } from 'components/shared/hooks';
import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon';
import React, { Fragment, useState } from 'react';
import { Field, FieldProps, FormikProps } from 'formik';
import {
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  FormHelperText,
  InputAdornment,
  Tooltip,
} from '@material-ui/core';
import { useStyles } from './DayMonthPicker.styles';
import { SelectBoxItem, SelectBoxProps } from './DayMonthPicker.types';
import classNames from 'classnames';
import { get, flatMap, isEqual } from 'lodash';

const months = (trans) => [
  { index: '01', name: trans('COMMON.MONTHS.JANUARY'), days: 31 },
  { index: '02', name: trans('COMMON.MONTHS.FEBRUARY'), days: 28 },
  { index: '03', name: trans('COMMON.MONTHS.MARCH'), days: 31 },
  { index: '04', name: trans('COMMON.MONTHS.APRIL'), days: 30 },
  { index: '05', name: trans('COMMON.MONTHS.MAY'), days: 31 },
  { index: '06', name: trans('COMMON.MONTHS.JUNE'), days: 30 },
  { index: '07', name: trans('COMMON.MONTHS.JULY'), days: 31 },
  { index: '08', name: trans('COMMON.MONTHS.AUGUST'), days: 31 },
  { index: '09', name: trans('COMMON.MONTHS.SEPTEMBER'), days: 30 },
  { index: '10', name: trans('COMMON.MONTHS.OCTOBER'), days: 31 },
  { index: '11', name: trans('COMMON.MONTHS.NOVEMBER'), days: 30 },
  { index: '12', name: trans('COMMON.MONTHS.DECEMBER'), days: 31 },
];

const SelectLabel = ({ labelText, displayEmpty, labelTooltipText }) => {
  const classes = useStyles();

  return labelText ? (
    <Tooltip title={labelTooltipText} placement="top-start">
      <InputLabel shrink={displayEmpty} error={false} classes={{ root: classes.label }}>
        {labelText}
      </InputLabel>
    </Tooltip>
  ) : null;
};

export const DayMonthPicker = <T extends SelectBoxItem>({
  name,
  required,
  labelText,
  validate,
  onChange,
  displayEmpty,
  emptyValue = '',
  resettable,
  onValueReset,
  icon,
  hasIcon = false,
  tooltipText = '',
  itemComponent,
  itemClassName = '',
  valueComponent,
  labelTooltipText = '',
  initialMonth,
  ...rest
}: SelectBoxProps) => {
  const classes = useStyles();
  const currentGroup = null;
  const trans = useTranslator();
  const initialDays = months(trans).find((month) => month.index === initialMonth);
  const [monthDays, setMonthDays] = useState<number>(initialDays ? initialDays.days : 30);

  const shouldShowResetButton = (value) => {
    return resettable && value !== undefined && value !== null && !isEqual(value, emptyValue) && !rest.disabled;
  };

  const renderItem = (option: T) => (
    <MenuItem
      value={option.value}
      key={option.value}
      className={classNames(itemClassName, { [classes.groupedItem]: currentGroup !== null })}
      disabled={option.disabled === true}
    >
      {itemComponent ? itemComponent(option) : option.label}
    </MenuItem>
  );

  const renderOptions = (options: T[]) => {
    if (!options || options.length === 0) {
      return null;
    }

    return flatMap(options, (option) => renderItem(option));
  };

  const renderSelectComponent = (props: {
    field: any;
    form: FormikProps<any>;
    handleOnChange: (event: any) => void;
    placeholder: string;
    options;
    value;
    disabled: boolean;
  }) => (
    <Select
      disabled={props.disabled}
      value={props.value || ''}
      onChange={props.handleOnChange}
      onBlur={props.field.onBlur}
      name={props.field.name}
      input={<Input name={props.placeholder} placeholder={props.placeholder} />}
      className={classNames(classes.selectBox, {
        [classes.displayEmpty]: displayEmpty && labelText,
      })}
      placeholder={props.placeholder}
      displayEmpty={displayEmpty}
      renderValue={valueComponent}
      endAdornment={
        <Fragment>
          {hasIcon && (
            <InputAdornment position="end" className={classes.tooltipIcon}>
              <Tooltip title={tooltipText} placement="right">
                {icon}
              </Tooltip>
            </InputAdornment>
          )}
          {shouldShowResetButton(props.field.value) && (
            <FontAwesomeIcon
              name={'times'}
              className={classes.reset}
              onClick={() => {
                props.form.setFieldValue(props.field.name, emptyValue);
                onValueReset && onValueReset();
              }}
            />
          )}
        </Fragment>
      }
      {...rest}
    >
      {renderOptions(props.options)}
    </Select>
  );

  const dayOptions = () => {
    const options = [];
    for (let i = 1; i <= monthDays; i++) {
      options.push({ value: i.toString().length === 1 ? `0${i.toString()}` : i.toString(), label: i.toString() });
    }
    return options;
  };

  const monthOptions = () =>
    months(trans).map((month) => ({
      value: month.index,
      label: month.name,
      days: month.days,
    }));

  return (
    <Field name={name} validate={validate}>
      {({ field, form }: FieldProps) => {
        const isError = !!(get(form.errors, field.name) && get(form.touched, field.name));

        const handleDayChange = (event) => {
          field.onChange(event);
          form.setFieldValue(field.name, {
            day: event.target.value,
            month: form.values[field.name].month,
          });
          if (onChange) {
            onChange(event);
          }
        };

        const handleMonthChange = (event) => {
          const selectedMonth = months(trans).find((month) => month.index === event.target.value);
          setMonthDays(selectedMonth.days);

          field.onChange(event);
          if (form.values[field.name].day > selectedMonth.days) {
            form.setFieldValue(field.name, { day: selectedMonth.days, month: event.target.value });
          } else {
            form.setFieldValue(field.name, { day: form.values[field.name].day, month: event.target.value });
          }

          if (onChange) {
            onChange(event);
          }
        };

        return (
          <FormControl
            error={isError}
            classes={{ root: classes.formControl }}
            required={required}
            className={classNames({ hasValidationError: isError })}
          >
            <SelectLabel labelText={labelText} displayEmpty={displayEmpty} labelTooltipText={labelTooltipText} />
            <div className={classes.dayMonthPicker}>
              {renderSelectComponent({
                field,
                form,
                handleOnChange: handleMonthChange,
                placeholder: trans('COMMON.MONTH'),
                options: monthOptions(),
                value: field.value.month,
                disabled: false,
              })}
              {renderSelectComponent({
                field,
                form,
                handleOnChange: handleDayChange,
                placeholder: trans('COMMON.DAY'),
                options: dayOptions(),
                value: field.value.day,
                disabled: !form.values[field.name].month,
              })}
            </div>
            <FormHelperText error>{isError && get(form.errors, field.name)}</FormHelperText>
          </FormControl>
        );
      }}
    </Field>
  );
};

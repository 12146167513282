import { CheckBox, CheckBoxProps } from 'components/shared/form';
import { useStyles } from 'components/shared/messages/Infobar/Infobar.styles';
import React, { FC } from 'react';

export const InfobarCheckBox: FC<CheckBoxProps> = ({ labelText, ...props }) => {
  const classes = useStyles();

  const label = <span className={classes.sideCheckboxLabel}>{labelText}</span>;

  return <CheckBox classes={{ root: classes.checkbox }} labelText={label} {...props} />;
};

import { AppDispatch } from '@typings/redux';
import { useNotification, useTranslator } from 'components/shared/hooks';
import { BrowserContainerProps } from './Browser.types';
import { ROUTE_STUDENTS_REGISTER_DETAILS, ROUTE_STUDENTS_REGISTER_ROLLBACK } from 'config/routes';
import { makePath } from 'utils/navigation/makePath';
import { Browser } from './Browser';
import { push } from 'connected-react-router';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearStudentsRegisterList,
  deleteStudentsRegister,
  downloadStudentRegisterDetails,
  fetchStudentRegisterList,
  fetchUnit,
  getUnitData,
  isStudentRegisterListLoadingSelector,
  isStudentRegisterListReadySelector,
  StudentRegisterBrowserRow,
  studentRegisterListSelector,
  StudentRegisterModel,
} from 'store/institutions';

export const BrowserContainer: FC<BrowserContainerProps> = ({ unitId }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { success, error } = useNotification();
  const trans = useTranslator();

  const registers = useSelector(studentRegisterListSelector);
  const isLoading = useSelector(isStudentRegisterListLoadingSelector);
  const isReady = useSelector(isStudentRegisterListReadySelector);
  const unit = useSelector(getUnitData);

  useEffect(() => {
    if (!unit) {
      dispatch(fetchUnit(unitId));
    }
  }, [dispatch, unit, unitId]);

  const previewRegister = (registerId: string, date: string = null) => {
    let path = null;

    if (date) {
      path = makePath(ROUTE_STUDENTS_REGISTER_ROLLBACK, { id: unitId, registerId, date });
    } else {
      path = makePath(ROUTE_STUDENTS_REGISTER_DETAILS, { id: unitId, registerId });
    }

    return dispatch(push(path));
  };

  const downloadRegister = (registerId: string, date: string = null) => {
    const fileName = date
      ? trans('INSTITUTIONS.STUDENTS_REGISTER.DOWNLOAD.FILE_NAME_WITH_DATE', { unit: unit.name, date })
      : trans('INSTITUTIONS.STUDENTS_REGISTER.DOWNLOAD.FILE_NAME', { unit: unit.name });

    return dispatch(downloadStudentRegisterDetails(unitId, registerId, date, fileName));
  };

  const deleteRegister = (registerId: string) => {
    return dispatch(deleteStudentsRegister(unitId, registerId)).then(
      () => {
        success('INSTITUTIONS.STUDENTS_REGISTER.DELETE_REGISTER.SUCCESS');
        dispatch(fetchStudentRegisterList(unitId));
      },
      () => {
        error('INSTITUTIONS.STUDENTS_REGISTER.DELETE_REGISTER.FAILURE');
      },
    );
  };

  useEffect(() => {
    dispatch(clearStudentsRegisterList());
    dispatch(fetchStudentRegisterList(unitId));
  }, [dispatch, unitId]);

  /**
   * This function assume that registers are sorted from newest to oldest.
   */
  const addHeaders = (registers: StudentRegisterModel[]): StudentRegisterBrowserRow[] => {
    const rows: StudentRegisterBrowserRow[] = [...registers];

    if (rows.length > 0) {
      rows.unshift({
        title: 'INSTITUTIONS.STUDENTS_REGISTER.SECTIONS.CURRENT_REGISTER',
      });
    }

    if (rows.length > 2) {
      rows.splice(2, 0, {
        title: 'INSTITUTIONS.STUDENTS_REGISTER.SECTIONS.PREVIOUS_REGISTER',
      });
    }

    return rows;
  };

  return (
    <Browser
      loading={isLoading || !isReady}
      registries={addHeaders(registers)}
      previewRegister={previewRegister}
      downloadRegister={downloadRegister}
      deleteRegister={deleteRegister}
    />
  );
};

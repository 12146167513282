import { Ref } from 'react';
import { SearchParams } from 'store/search/search.types';

export interface ToolbarProps {
  onGridFocus: () => void;
  onSubmit: (filters: SearchParams) => void;
  onFilterChange: () => void;
  ref: Ref<HTMLElement>;
}

export enum Filters {
  SEARCH_BY_IDENTIFICATION_NUMBER = 'searchByIdentificationNumber',
  SEARCH_BY_NAME = 'searchByName',
}

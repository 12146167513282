import React, { FC } from 'react';
import { InstitutionPart } from 'components/institution/Unit/UnitForm/parts/InstitutionPart';
import { AddressPart } from 'components/institution/Unit/UnitForm/parts/AddressPart';
import { ContactPart } from 'components/institution/Unit/UnitForm/parts/ContactPart';
import { SettingsPartContainer } from 'components/institution/Unit/UnitForm/parts/SettingsPart/SettingsPartContainer';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import { UnitFormProps } from 'components/institution/Unit/UnitForm/UnitForm.types';
import { useStyles } from 'components/institution/Unit/UnitForm/UnitForm.styles';

export const UnitForm: FC<UnitFormProps> = ({
  formProps,
  institutionsDictionary,
  isSynchronized,
  isEdited,
  oryginalName,
}) => {
  const classes = useStyles();

  return (
    <Paper innerSpacing={[4, 2]} square={true} className={classes.paper}>
      <InstitutionPart
        props={formProps}
        institutions={institutionsDictionary}
        disableInputs={isSynchronized || isEdited}
        isSynchronized={isSynchronized}
      />
      <AddressPart isSynchronized={isSynchronized} oryginalName={oryginalName} />
      <ContactPart disableInputs={isSynchronized} />
      <SettingsPartContainer props={formProps} disableInputs={isSynchronized} />
    </Paper>
  );
};

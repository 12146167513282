import { setTokenAction } from '@tshio/redux-api-auth-middleware';
import { AppDispatch } from '@typings/redux';
import { useEnvironment } from 'components/shared/hooks/useEnvironment/useEnvironment';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchAccessToken } from 'utils/auth/fetchAccessToken';

export const useAuthCode = (authCode: string) => {
  const [isResolved, setResolved] = useState<boolean>(!authCode);
  const [isAuthorized, setAuthorized] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const dispatch = useDispatch<AppDispatch>();
  const { origin } = useEnvironment();

  const authorize = useCallback(async () => {
    const { result, error } = await fetchAccessToken(authCode, origin);

    if (error) {
      setError(error);
    } else {
      dispatch(setTokenAction(result));
      setAuthorized(true);
    }

    setResolved(true);
  }, [authCode, dispatch, origin]);

  useEffect(() => {
    if (authCode) {
      !isResolved && authorize();
    } else {
      setError('AUTHORIZATION.NOTIFICATIONS.SIGN_IN_ERROR');
    }
  }, [authCode, authorize, isResolved, origin]);

  return {
    resolved: isResolved,
    authorized: isAuthorized,
    error,
  };
};

import { useStyles } from 'components/shared/messages/Infobar/Infobar.styles';
import { InfobarProps } from 'components/shared/messages/Infobar/Infobar.types';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import React, { FC } from 'react';
import classNames from 'classnames';

export const Infobar: FC<InfobarProps> = ({ children, className }) => {
  const classes = useStyles();

  return (
    <Paper innerSpacing={[1, 2]} outerSpacing={[0, 0, 1, 0]} className={classNames(classes.infobar, className)}>
      {children}
    </Paper>
  );
};

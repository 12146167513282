import { Documents } from '../Documents/Documents';
import {
  reportChildrenListColumns,
  reportChildrenListUnitsColumns,
} from 'components/schoolObligation/ReportDetails/ReportChildrenList/columns.config';
import { useStyles } from 'components/schoolObligation/ReportDetails/ReportChildrenList/ReportChildrenList.styles';
import { ReportChildrenListProps } from 'components/schoolObligation/ReportDetails/ReportChildrenList/ReportChildrenList.types';
import {
  ChildrenListRow,
  FulfillmentUnitRow,
} from 'components/schoolObligation/ReportDetails/ReportOverview/ReportOverview.types';
import { DataGrid } from 'components/shared/data/DataGrid/DataGrid';
import React, { FC, useState } from 'react';
import { ToolbarContainer } from 'components/schoolObligation/ReportDetails/Toolbar/ToolbarContainer';
import { BrowserColumnParams } from 'components/schoolObligation/ReportDetails/ReportChildrenList/ReportChildrenList.types';
import { useSelector } from 'react-redux';
import { routerSelector } from 'store/router/router.selectors';

export const ReportChildrenList: FC<ReportChildrenListProps> = (props) => {
  const classes = useStyles();
  const locationKey = useSelector(routerSelector).key;
  const [documentsModalOpen, setDocumentsModalOpen] = useState(false);
  const actionHandlers: BrowserColumnParams = {
    show: props.showItem,
  };

  return (
    <div className={classes.container}>
      <ToolbarContainer
        reportId={props.reportId}
        onDocumentsCreate={() => setDocumentsModalOpen(true)}
        onCsvDownload={props.onCsvDownload}
      />
      <Documents
        open={documentsModalOpen}
        onClose={() => setDocumentsModalOpen(false)}
        filters={props.filters}
        reportId={props.reportId}
      />
      <DataGrid<ChildrenListRow, FulfillmentUnitRow>
        isLoading={false}
        key={locationKey}
        className={classes.grid}
        onDataFetch={props.onChildrenFetch}
        onDataProvide={props.childrenProvider}
        columns={reportChildrenListColumns(actionHandlers)}
        detailsColumns={reportChildrenListUnitsColumns()}
        detailsMapping={(child) => child.units}
        limit={props.limit}
        total={props.total}
      />
    </div>
  );
};

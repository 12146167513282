import { MessageValues } from '@typings/extra-react-intl';
import { VariantType, OptionsObject } from 'notistack';
import { DictionariesCollection } from './general.types';

export interface WindowInfo {
  width: number;
  height: number;
}

export interface Notification {
  id: string;
  message: string;
  params: MessageValues;
  type: VariantType;
  options: OptionsObject;
}

export interface Environment {
  origin: string;
  oAuth: {
    clientId: string;
    loginUrl: string;
    logoutUrl: string;
  };
  banner: {
    imageUrl: string;
    imageHeight: string;
    link: string;
  };
}

export interface GeneralState {
  sidebarOpening: boolean;
  screenLock: boolean;
  window: WindowInfo;
  notifications: Notification[];
  dictionaries: {
    list: DictionariesCollection;
    loading: boolean;
    ready: boolean;
  };
  environment: {
    data: Environment;
    loading: boolean;
    error: boolean;
  };
}

export const defaultState: GeneralState = {
  sidebarOpening: true,
  screenLock: false,
  window: {
    width: 1920,
    height: 1080,
  },
  environment: {
    data: null,
    loading: false,
    error: false,
  },
  notifications: [],
  dictionaries: {
    list: {},
    loading: false,
    ready: false,
  },
};

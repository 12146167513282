import { muiStyles } from 'utils/design';

export const useStyles = muiStyles({
  button: {
    fontSize: '0.9em',
    padding: '4px 8px 3px 8px',
    minWidth: '64px',
    lineHeight: 1.4,
    marginBottom: '1px',
  },
});

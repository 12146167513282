import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import { useStyles } from 'components/polls/Results/Results.styles';
import { ResultsProps } from 'components/polls/Results/Results.types';
import { Button } from 'components/shared/buttons/Button/Button';
import { useTranslator } from 'components/shared/hooks';
import { TextLoader } from 'components/shared/loaders/TextLoader/TextLoader';
import { RoutedTabPanel } from 'components/shared/navigation/RoutedTabPanel/RoutedTabPanel';
import { TabConfig } from 'components/shared/navigation/RoutedTabPanel/RoutedTabPanel.types';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import { Heading } from 'components/shared/typography/Heading/Heading';
import { ROUTE_POLLS_RESULTS, ROUTE_POLLS_RESULTS_RECIPIENTS, ROUTE_POLLS_RESULTS_RESPONSES } from 'config/routes';

export const Results: FC<ResultsProps> = (props) => {
  const trans = useTranslator();
  const classes = useStyles();

  if (props.loading || !props.results) {
    return <TextLoader text={trans('POLLS.RESULTS.LOADING_RESULTS')} />;
  }

  const tabs: TabConfig[] = [
    {
      title: trans('POLLS.RESULTS.COMBINED.TITLE'),
      route: ROUTE_POLLS_RESULTS,
      routeParams: { id: props.pollId },
    },
    {
      title: trans('POLLS.RESULTS.RESPONSES.TITLE'),
      route: ROUTE_POLLS_RESULTS_RESPONSES,
      routeParams: { id: props.pollId },
    },
    {
      title: trans('POLLS.RESULTS.RECIPIENTS.TITLE'),
      route: ROUTE_POLLS_RESULTS_RECIPIENTS,
      routeParams: { id: props.pollId },
    },
  ];

  return (
    <Box marginX={2} marginBottom={2} display="flex" flexDirection="column" flexGrow={1}>
      <Box marginBottom={4} display="flex" alignItems="flex-start">
        <Box>
          <Heading type={'h5'}>{props.results.name}</Heading>
          <div className="my-2">{props.results.description}</div>
          <div className="font-weight-bold">
            {trans('POLLS.RESULTS.RESPONSES_COUNT', {
              current: props.results.responses.length,
              expected: props.results.expectedResponsesCount,
            })}
          </div>
        </Box>
      </Box>

      <RoutedTabPanel tabs={tabs} />

      <Paper square className={classes.paper}>
        {props.children(props.pollId, props.results, props.recipients)}
      </Paper>

      <div>
        <Button color="secondary" variant="contained" className="mt-3" onClick={props.onBack}>
          {trans('COMMON.BACK')}
        </Button>
      </div>
    </Box>
  );
};

import { colors } from 'styles/colors';
import { important, muiStyles } from 'utils/design';

export const useStyles = muiStyles((theme) => ({
  wrapper: {
    '& > :last-child': {
      margin: theme.spacing(0, 0, 2, 0),
    },
  },
  infobar: {
    background: colors.red,
    color: 'white',
    fontWeight: 'bolder',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {
    margin: theme.spacing(0.5, 0, 0.5, 2),
    padding: theme.spacing(0.5, 1),
    color: 'white',
    border: `1px solid white`,
    borderRadius: '4px',
    background: 'transparent',
    font: 'inherit',
    outline: 'none',
    cursor: 'pointer',
    textTransform: 'uppercase',
    fontSize: '0.8em',
    transition: '0.5s',

    '&:hover': {
      color: colors.yellowVeryLight,
      border: `1px solid ${colors.yellowVeryLight}`,
    },
  },
  checkbox: {
    margin: 0,
    padding: theme.spacing(0.5),
    color: important('white'),
  },
  sideCheckboxLabel: {
    fontSize: '0.9em',
  },
}));

import { Page } from 'components/layout/panel/Page/Page';
import { useTranslator } from 'components/shared/hooks';
import { Breadcrumb } from 'components/shared/navigation/Breadcrumbs/Breadcrumb';
import React, { FC } from 'react';
import { RouteChildrenProps } from 'react-router';
import { SchoolChildrenListRoute } from 'config/routes.types';
import { ChildrenListContainer } from 'components/schoolDistricts/AssignmentPreview/ChildrenList/ChildrenListContainer';

export const ChildrenList: FC<RouteChildrenProps<SchoolChildrenListRoute>> = (props) => {
  const trans = useTranslator();
  const { id, schoolId } = props.match.params;

  return (
    <>
      <Breadcrumb label={trans('BREADCRUMB.SCHOOL-DISTRICTS.CHILDREN_LIST')} static />

      <Page title={trans('SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.CHILDREN_LIST.TITLE')}>
        <ChildrenListContainer id={id} schoolId={schoolId} />
      </Page>
    </>
  );
};

import { OptionType } from 'components/shared/form/Autocomplete/Autocomplete.types';
import { ExemptionModel } from 'store/childRegister';
import { SchoolYear } from 'store/schoolObligations';
import { toOptions } from 'utils/api';
import { difference } from 'lodash';

export const emptyExemption = (): ExemptionModel => {
  return {
    schoolYear: null,
    description: '',
  };
};

export const schoolYearOptions = (all: SchoolYear[], exemptions: ExemptionModel[], index: number): OptionType[] => {
  const allSelected = exemptions.map((exemption) => exemption.schoolYear);
  const hereSelected = exemptions[index].schoolYear;
  const otherSelected = difference(allSelected, [hereSelected]);

  return toOptions(difference(all, otherSelected));
};

import React, { FC, useState, useContext, useEffect, Fragment } from 'react';
import { FormikHelpers, FormikProps } from 'formik';
import { Prompt } from 'react-router';
import { ROUTE_UNIT_LIST_IN_INSTITUTIONAL_CORE_GRANT } from 'config/routes';
import { Button } from 'components/shared/buttons/Button/Button';
import { Form } from 'components/shared/form';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import { LocaleContext } from 'context/locale';
import { UnitFormProps, UnitFormValues } from 'components/institution/Unit/Editor/Editor.types';
import { TextLoader } from 'components/shared/loaders/TextLoader/TextLoader';
import { useNotification } from 'components/shared/hooks/useNotification/useNotification';
import { useFormatter } from 'components/shared/hooks/useFormatter/useFormatter';
import { useStyles } from 'components/institution/Unit/Editor/Editor.styles';
import { TabPanel } from 'components/shared/surfaces/TabPanel/TabPanel';
import { BrowserContainer } from 'components/institution/Unit/StudentsRegister/Browser/BrowserContainer';
import { compact } from 'lodash';
import { UnitForm } from 'components/institution/Unit/UnitForm/UnitForm';
import { Confirm } from 'components/shared/dialogs/Confirm/Confirm';
import Access from 'components/shared/access/access';
import { useCheckAccessSameType } from 'components/shared/hooks';
import { OfficialPermissionLevel, OfficialResourceGroup } from 'store/officials';

export const Editor: FC<UnitFormProps> = ({ fetchInstitutionsDictionary, fetchUnit, id, initialTab, ...props }) => {
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [redirectDialog, setRedirectDialog] = useState(false);
  const { success } = useNotification();
  const { trans } = useContext(LocaleContext);
  const { formatApiErrors } = useFormatter();
  const classes = useStyles();
  const userReadOnly = useCheckAccessSameType(OfficialResourceGroup.childRegister, OfficialPermissionLevel.readOnly);

  useEffect(() => {
    if (!userReadOnly) return;
    setSubmitSuccess(true);
  }, [userReadOnly]);

  useEffect(() => {
    fetchInstitutionsDictionary();
    if (fetchUnit) {
      fetchUnit();
    }
  }, [fetchInstitutionsDictionary, fetchUnit]);

  const isRedirect = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const redirectFrom = urlSearchParams.get('redirect');
    return redirectFrom === 'panel-institutional-core-grant';
  };

  const handleOnSubmit = (values: UnitFormValues, formik: FormikHelpers<UnitFormValues>) => {
    props.handleSubmit(values).then((result) => {
      if (result.error) {
        formik.setErrors(formatApiErrors(result.payload.response.errors));
        formik.setSubmitting(false);
      } else {
        success(props.successMessage);
        setSubmitSuccess(true);
        if (isRedirect()) {
          setRedirectDialog(true);
        } else {
          props.goBack();
        }
      }
    });
  };

  const renderLoader = () => (
    <Paper innerSpacing={[4, 2]} square={true} className={classes.loadingPaper}>
      <TextLoader name="spinner" text={trans('INSTITUTIONS.EDIT.LOADING')} />
    </Paper>
  );

  const getTabs = (formProps: FormikProps<UnitFormValues>) =>
    compact([
      {
        title: trans('INSTITUTIONS.UNIT_DATA.TITLE'),
        key: 'form',
        content: (
          <UnitForm
            formProps={formProps}
            isSynchronized={props.isSynchronized}
            isEdited={props.isEdited}
            institutionsDictionary={props.institutionsDictionary}
            oryginalName={props.oryginalName}
          />
        ),
      },
      id && {
        title: trans('INSTITUTIONS.STUDENTS_REGISTER.TITLE'),
        key: 'studentRegister',
        content: <BrowserContainer unitId={id} />,
      },
    ]);

  const renderForm = () => (
    <>
      <Confirm
        confirm={() => {
          window.location.href = ROUTE_UNIT_LIST_IN_INSTITUTIONAL_CORE_GRANT;
        }}
        message={'INSTITUTIONS.REDIRECT_DIALOG.MESSAGE'}
        open={redirectDialog}
        title={'INSTITUTIONS.REDIRECT_DIALOG.TITLE'}
        confirmLabel={trans('INSTITUTIONS.REDIRECT_DIALOG.ACCEPT_BUTTON')}
      />
      <Form<UnitFormValues> initialValues={props.formInitialValues} onSubmit={handleOnSubmit}>
        {(formProps: FormikProps<UnitFormValues>) => {
          return (
            <Fragment>
              <Prompt when={formProps.dirty && !submitSuccess} message={trans('COMMON.CONFIRM_EXIT')} />
              <TabPanel tabs={getTabs(formProps)} defaultTab={initialTab} />
              <div className="row my-4">
                <div className="col">
                  <Button
                    onClick={() => {
                      if (isRedirect()) {
                        setRedirectDialog(true);
                      } else {
                        props.goBack();
                      }
                    }}
                    disabled={formProps.isSubmitting}
                    color="secondary"
                    variant="outlined"
                  >
                    {trans('COMMON.BACK')}
                  </Button>
                  <Access
                    resourcesGroup={[OfficialResourceGroup.childRegister, OfficialResourceGroup.institutionalCoreGrant]}
                    accessType={OfficialPermissionLevel.fullAccess}
                  >
                    <Button
                      onClick={() => formProps.handleSubmit()}
                      disabled={formProps.isSubmitting}
                      color="secondary"
                      variant="contained"
                      className="ml-3"
                    >
                      {trans('COMMON.SAVE')}
                    </Button>
                  </Access>
                </div>
              </div>
            </Fragment>
          );
        }}
      </Form>
    </>
  );

  return props.loading ? renderLoader() : renderForm();
};

import { muiStyles } from 'utils/design';
import { colors } from 'styles/colors';

export const useStyles = muiStyles(() => ({
  form: {
    width: '300px',
  },
  markedCell: {
    background: '#f4f4f4',
  },
  alertRow: {
    color: `${colors.red} !important`,
  },
  grid: {
    '& .ag-header, & .ag-header-container, & .ag-header-cell-text': {
      whiteSpace: 'unset',
    },
  },
}));

import {
  ActionHandlers,
  RollbackActionHandlers,
} from 'components/institution/Unit/StudentsRegister/Browser/Browser.types';
import { ColumnsConfig } from 'components/shared/data/DataGrid/DataGrid.types';
import {
  isStudentRegisterHeader as isHeader,
  RegisterRollbackRow,
  StudentRegisterBrowserRow,
} from 'store/institutions';
import { ButtonDefinition } from 'components/shared/data/DataGrid/renderers/cell/Buttons/Buttons.types';
import { last } from 'lodash';
import { OfficialPermissionLevel, OfficialResourceGroup } from 'store/officials';

export const browserColumns: ColumnsConfig<StudentRegisterBrowserRow, ActionHandlers> = (handlers) => [
  {
    field: 'updateDate',
    name: 'INSTITUTIONS.STUDENTS_REGISTER.COLUMNS.LAST_UPDATE_DATE',
    cellClass: 'no-leaf-indent',
    renderer: 'agGroupCellRenderer',
    columnSpan: (data) => {
      return isHeader(data) ? 6 : 1;
    },
    format: (data, trans, formatter) => {
      return isHeader(data) ? trans(data.title) : formatter.formatDate(last(data.revisions).from);
    },
  },
  {
    field: 'schoolYear',
    name: 'INSTITUTIONS.STUDENTS_REGISTER.COLUMNS.SCHOOL_YEAR',
  },
  {
    field: 'mode',
    name: 'INSTITUTIONS.STUDENTS_REGISTER.COLUMNS.MODE',
    format: (data, trans) => {
      if (!isHeader(data)) {
        return trans(`INSTITUTIONS.STUDENTS_REGISTER.VALUES.MODE.${data.mode.toUpperCase()}`);
      }
    },
  },
  {
    field: 'dataSource',
    name: 'INSTITUTIONS.STUDENTS_REGISTER.COLUMNS.DATA_SOURCE',
  },
  {
    field: 'studentsCount',
    name: 'INSTITUTIONS.STUDENTS_REGISTER.COLUMNS.RECORDS_COUNT',
  },
  {
    name: 'COMMON.ACTIONS',
    field: 'id',
    cellTooltip: () => '',
    renderer: 'buttonsCell',
    rendererParams: {
      buttons: (data): ButtonDefinition[] => {
        if (isHeader(data)) {
          return [];
        } else {
          return [
            {
              type: 'default',
              size: 'small',
              variant: 'outlined',
              icon: 'eye',
              label: 'COMMON.DISPLAY',
              tooltip: 'COMMON.DISPLAY',
              action: handlers.preview,
            },
            {
              type: 'default',
              size: 'small',
              variant: 'outlined',
              icon: 'download',
              label: 'COMMON.DOWNLOAD',
              tooltip: 'COMMON.DOWNLOAD',
              action: handlers.download,
            },
            {
              type: 'default',
              size: 'small',
              variant: 'outlined',
              icon: 'trash',
              label: 'COMMON.DELETE',
              tooltip: 'COMMON.DELETE',
              canShow: {
                only: false,
                resourcesGroup: OfficialResourceGroup.childRegister,
                accessType: OfficialPermissionLevel.fullAccess,
              },
              action: handlers.delete,
            },
          ];
        }
      },
    },
    columnParams: { minWidth: 300 },
  },
];

export const rollbackColumns: ColumnsConfig<RegisterRollbackRow, RollbackActionHandlers> = (handlers) => [
  {
    field: 'from',
    name: 'INSTITUTIONS.STUDENTS_REGISTER.COLUMNS.ROLLBACK_FROM',
    format: (data, trans, formatter) => {
      return formatter.formatDate(data.from);
    },
  },
  {
    field: 'schoolYear',
    name: 'INSTITUTIONS.STUDENTS_REGISTER.COLUMNS.ROLLBACK_TO',
    format: (data, trans, formatter) => {
      return data.to ? formatter.formatDate(data.to) : '-';
    },
  },
  {
    field: 'dataSource',
    name: 'INSTITUTIONS.STUDENTS_REGISTER.COLUMNS.DATA_SOURCE',
    format: (data) => {
      return data.dataSource;
    },
  },
  {
    name: 'COMMON.ACTIONS',
    field: 'from',
    cellTooltip: () => '',
    renderer: 'buttonsCell',
    rendererParams: {
      buttons: (): ButtonDefinition[] => {
        return [
          {
            type: 'default',
            size: 'small',
            variant: 'outlined',
            icon: 'eye',
            label: 'COMMON.PREVIEW',
            tooltip: 'COMMON.PREVIEW',
            action: handlers.preview,
          },
          {
            type: 'default',
            size: 'small',
            variant: 'outlined',
            icon: 'download',
            label: 'COMMON.DOWNLOAD',
            tooltip: 'COMMON.DOWNLOAD',
            action: handlers.download,
          },
        ];
      },
    },
    columnParams: { minWidth: 150 },
  },
];

import { useCancellableCallback } from 'components/shared/hooks';
import { useCallback, useLayoutEffect } from 'react';

export const useUnmountableCallback = (callback, deps: any[] = []) => {
  const [handler, setHandler, cancelHandler] = useCancellableCallback();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const safeCallback = useCallback(callback, deps);

  useLayoutEffect(() => {
    setHandler(safeCallback);
    return cancelHandler;
  }, [setHandler, cancelHandler, safeCallback]);

  return handler;
};

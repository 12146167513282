import { OfficialAccountType } from 'store/officials';
import { OfficialResourceGroup, OfficialPermissionLevel } from 'store/officials';

export enum InstanceAddressType {
  PERMANENT_RESIDENCE = 'permanent',
  RESIDENCE = 'residence',
  RESIDENCE_FIRST = 'residence_first',
  PERMANENT_RESIDENCE_FIRST = 'permanent_first',
}

export interface OfficialSettings {
  dcsLogin: string;
  id: string;
  role: OfficialAccountType;
  permissions: Record<OfficialResourceGroup, OfficialPermissionLevel> | {};
  permissionsReady: boolean;
  isExternalUser: boolean;
}

import { useTranslator } from 'components/shared/hooks';
import React, { Fragment } from 'react';
import { Route, Switch, Redirect } from 'react-router';

import { NotFoundView } from 'views/Errors/NotFound.view';
import { Breadcrumb } from 'components/shared/navigation/Breadcrumbs/Breadcrumb';

import { ROUTE_SETTINGS, ROUTE_SETTINGS_SYSTEM } from 'config/routes';
import { SystemView } from 'views/Panel/Settings/System/System.view';

export const SettingsView = () => {
  const trans = useTranslator();

  return (
    <Fragment>
      <Breadcrumb label={trans('BREADCRUMB.SETTINGS.CONFIG')} static />

      <Switch>
        <Redirect path={ROUTE_SETTINGS} exact to={ROUTE_SETTINGS_SYSTEM} />
        <Route exact path={ROUTE_SETTINGS_SYSTEM} component={SystemView} />
        <Route component={NotFoundView} />
      </Switch>
    </Fragment>
  );
};

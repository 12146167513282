import React, { FC, useCallback, useContext } from 'react';
import { Settings } from './Settings';
import { SettingsStepProps } from 'components/shared/dialogs/StepModal/StepModal.types';
import {
  fetchStudentRegisterImportColumns,
  getImportColumns,
  getImportColumnsError,
  getImportContext,
  getImportToken,
  isImportLoading,
  postStudentRegisterImportContext,
} from 'store/institutions';
import { useDispatch, useSelector } from 'react-redux';
import { __RouterContext } from 'react-router';

export const SettingsContainer: FC<SettingsStepProps> = (props) => {
  const dispatch: (...params: any) => Promise<any> = useDispatch();
  const importToken = useSelector(getImportToken);
  const router = useContext(__RouterContext);
  const id = (router.match.params as { id: string }).id;

  const formInitialValues = useSelector(getImportContext);
  const isLoading = useSelector(isImportLoading);

  const fetchColumns = useCallback(
    () => dispatch(fetchStudentRegisterImportColumns(importToken, id)),
    [dispatch, importToken, id],
  );
  const saveContext = useCallback(
    (body) => {
      props.onColumnsSave(body.map);
      return dispatch(postStudentRegisterImportContext(importToken, id, body));
    },
    [props, dispatch, importToken, id],
  );

  const columnsError = useSelector(getImportColumnsError);

  if (columnsError !== null) {
    props.onFailure(columnsError);
  }

  const columns = useSelector(getImportColumns).map((value) => ({ label: value, value: value }));

  return (
    <Settings
      {...props}
      columns={columns}
      initialValues={formInitialValues}
      fetchColumns={fetchColumns}
      saveContext={saveContext}
      isLoading={isLoading}
    />
  );
};

import { isEmpty, omitBy } from 'lodash';
import { ReportsActionType } from 'store/reports/reports.actionTypes';
import { RESET_STATE } from 'store/store';
import { defaultState, ReportsState } from 'store/reports/reports.state';
import { Behaviours, FSAReducer } from '@typings/redux';
import { fromApiFormat } from 'utils/api';
import { insertPage } from 'utils/pagination/insertPage';
import { SynchronizationLogFilters } from './reports.types';

export const reportsReducer: FSAReducer<ReportsState, ReportsActionType> = (state = defaultState, action) => {
  const behaviours: Behaviours<ReportsState, ReportsActionType> = {
    [RESET_STATE]: () => ({
      ...defaultState,
    }),
    [ReportsActionType.CLEAR_AUDIT_LOG_DATA]: () => ({
      ...state,
      auditLog: {
        ...state.auditLog,
        list: [],
      },
    }),
    [ReportsActionType.FETCH_AUDIT_LOG_REQUEST]: () => ({
      ...state,
      auditLog: {
        ...state.auditLog,
        loading: true,
        error: null,
      },
    }),
    [ReportsActionType.FETCH_AUDIT_LOG_SUCCESS]: ({ payload, meta }) => ({
      ...state,
      auditLog: {
        ...state.auditLog,
        loading: false,
        list: insertPage(state.auditLog.list, fromApiFormat(payload.items), meta.page),
        total: payload.metadata.total,
      },
    }),
    [ReportsActionType.FETCH_AUDIT_LOG_FAILURE]: ({ payload }) => ({
      ...state,
      auditLog: {
        ...state.auditLog,
        loading: false,
        error: payload,
      },
    }),
    [ReportsActionType.FETCH_SCHOOL_STAT_REQUEST]: () => ({
      ...state,
      schoolStat: {
        ...state.schoolStat,
        list: [],
        loading: true,
        error: null,
      },
    }),
    [ReportsActionType.FETCH_SCHOOL_STAT_SUCCESS]: ({ payload }) => ({
      ...state,
      schoolStat: {
        ...state.schoolStat,
        loading: false,
        list: fromApiFormat(payload.items),
      },
    }),
    [ReportsActionType.FETCH_SCHOOL_STAT_FAILURE]: () => ({
      ...state,
      schoolStat: {
        ...state.schoolStat,
        loading: false,
        error: null,
        list: [],
      },
    }),
    [ReportsActionType.FETCH_SCHOOL_STAT_DETAILS_REQUEST]: () => ({
      ...state,
      schoolStat: {
        ...state.schoolStat,
        details: null,
        loading: true,
        error: null,
      },
    }),
    [ReportsActionType.FETCH_SCHOOL_STAT_DETAILS_SUCCESS]: ({ payload }) => ({
      ...state,
      schoolStat: {
        ...state.schoolStat,
        loading: false,
        details: {
          ...fromApiFormat(payload, { recursive: false }),
          table: payload.table
            ? {
                ...payload.table,
                columns: fromApiFormat(payload.table.columns),
              }
            : null,
        },
      },
    }),
    [ReportsActionType.FETCH_SCHOOL_STAT_DETAILS_FAILURE]: () => ({
      ...state,
      schoolStat: {
        ...state.schoolStat,
        loading: false,
        error: true,
        details: null,
      },
    }),
    [ReportsActionType.FETCH_OTHER_COMMUNES_STATS_REQUEST]: () => ({
      ...state,
      otherCommune: {
        ...state.otherCommune,
        list: [],
        loading: true,
        error: null,
      },
    }),
    [ReportsActionType.FETCH_OTHER_COMMUNES_STATS_SUCCESS]: ({ payload }) => ({
      ...state,
      otherCommune: {
        ...state.otherCommune,
        loading: false,
        list: fromApiFormat(payload.items),
      },
    }),
    [ReportsActionType.FETCH_OTHER_COMMUNES_STATS_FAILURE]: () => ({
      ...state,
      otherCommune: {
        ...state.otherCommune,
        loading: false,
        error: null,
      },
    }),
    [ReportsActionType.FETCH_OTHER_COMMUNES_STUDENTS_REQUEST]: () => ({
      ...state,
      otherCommune: {
        ...state.otherCommune,
        students: {
          ...state.otherCommune.students,
          list: [],
          loading: true,
          error: null,
        },
      },
    }),
    [ReportsActionType.FETCH_OTHER_COMMUNES_STUDENTS_SUCCESS]: ({ payload }) => ({
      ...state,
      otherCommune: {
        ...state.otherCommune,
        students: {
          ...state.otherCommune.students,
          list: fromApiFormat(payload.items),
          loading: false,
          error: null,
        },
      },
    }),
    [ReportsActionType.FETCH_OTHER_COMMUNES_STUDENTS_FAILURE]: () => ({
      ...state,
      otherCommune: {
        ...state.otherCommune,
        students: {
          ...state.otherCommune.students,
          loading: false,
          error: null,
        },
      },
    }),
    [ReportsActionType.CLEAR_OTHER_COMMUNES_STUDENTS]: () => ({
      ...state,
      otherCommune: {
        ...state.otherCommune,
        students: {
          ...state.otherCommune.students,
          list: [],
        },
      },
    }),
    [ReportsActionType.FETCH_SUBVENTION_FACTORS_REQUEST]: () => ({
      ...state,
      subventionFactors: {
        ...state.subventionFactors,
        loading: true,
        error: false,
      },
    }),
    [ReportsActionType.FETCH_SUBVENTION_FACTORS_SUCCESS]: ({ payload }) => {
      const { year, ...factors } = fromApiFormat(payload);

      return {
        ...state,
        subventionFactors: {
          ...state.subventionFactors,
          factors,
          year,
          loading: false,
        },
      };
    },
    [ReportsActionType.FETCH_SUBVENTION_FACTORS_FAILURE]: () => ({
      ...state,
      subventionFactors: {
        ...state.subventionFactors,
        factors: null,
        loading: false,
        error: true,
      },
    }),
    [ReportsActionType.FETCH_SYNCHRONIZATION_LOG_REQUEST]: () => ({
      ...state,
      syncLog: {
        ...state.syncLog,
        loading: true,
        error: null,
      },
    }),
    [ReportsActionType.FETCH_SYNCHRONIZATION_LOG_SUCCESS]: ({ payload, meta }) => {
      return {
        ...state,
        syncLog: {
          ...state.syncLog,
          loading: false,
          list: insertPage(state.syncLog.list, fromApiFormat(payload.items), meta.page),
          total: payload.metadata.total,
        },
      };
    },
    [ReportsActionType.FETCH_SYNCHRONIZATION_LOG_FAILURE]: ({ payload }) => ({
      ...state,
      syncLog: {
        ...state.syncLog,
        loading: false,
        error: payload,
      },
    }),
    [ReportsActionType.SET_SYNCHRONIZATION_LOG_FILTERS]: ({ payload }) => {
      return {
        ...state,
        syncLog: {
          ...state.syncLog,
          filters: omitBy(payload, isEmpty) as SynchronizationLogFilters,
        },
      };
    },
    [ReportsActionType.SET_SYNCHRONIZATION_LOG_LAST_ITEM]: ({ payload }) => {
      return {
        ...state,
        syncLog: {
          ...state.syncLog,
          lastFetchedItem: payload,
        },
      };
    },
    [ReportsActionType.CLEAR_SYNCHRONIZATION_LOG_DATA]: () => {
      return {
        ...state,
        syncLog: {
          ...state.syncLog,
          list: [],
          total: null,
          lastFetchedItem: undefined,
        },
      };
    },
  };

  return behaviours[action.type] ? behaviours[action.type](action) : state;
};

import { findDataForNode, getReportAggregator } from 'components/reports/SchoolStatDetails/helpers';
import {
  ColumnsConfig,
  DefaultColumnConfig,
  GroupingColumnConfig,
} from 'components/shared/data/DataGrid/DataGrid.types';
import { Translator } from 'context/locale/LocaleContext/LocaleContext.types';
import {
  AggregationMethod,
  AggregationValue,
  isAggregationValue,
  StatDetailsColumn,
  StatDetailsRow,
} from 'store/reports/reports.types';
import { isString, isNumber } from 'lodash';

interface Params {
  columns: StatDetailsColumn[];
  trans: Translator;
}

export const statisticDetailsColumns: ColumnsConfig<StatDetailsRow, Params> = (params) => {
  return params.columns.reduce((result, column) => {
    if (column.group) {
      return [
        ...result,
        {
          name: () => column.name || undefined,
          columnParams: {
            showRowGroup: column.field,
            cellRenderer: 'agGroupCellRenderer',
            filterValueGetter: (params) => {
              return params.data ? params.data[column.field] : null;
            },
            cellRendererParams: {
              suppressCount: true,
            },
          },
        },
        {
          id: column.field,
          columnParams: {
            field: column.field,
            rowGroup: true,
            hide: true,
          },
        },
      ];
    } else {
      return [
        ...result,
        {
          id: column.field,
          name: () => column.name || undefined,
          cellClass: (params) => {
            return params.data?._isFake ? 'dim-cell' : '';
          },
          cellTooltip: (data, trans, formatter, { node, value }) => {
            if (node.aggData?.[column.field] && isAggregationValue(node.aggData?.[column.field])) {
              const method = node.aggData[column.field].aggregation.method as AggregationMethod;

              if (method === AggregationMethod.NONE) {
                return null;
              } else {
                return trans(`REPORTS.AGGREGATION_METHOD_TOOLTIP.${method.toUpperCase()}`);
              }
            }
            return value;
          },
          columnParams: {
            valueGetter: ({ data, context, node }) => {
              if (data) {
                return data[column.field];
              } else {
                const value = findDataForNode(node, column.field, context.rowGroupValues);

                if (value) {
                  return value;
                } else {
                  return '';
                }
              }
            },
            filterValueGetter: (params) => {
              if (params.data[column.field]) {
                return params.data[column.field];
              } else {
                const groupData = params.context.rowGroupValues;

                let rowIdPointer = params.data._parentId;
                let row = null;

                do {
                  const rowId = rowIdPointer; // no-loop-func
                  row = groupData.find((row) => row._id === rowId);
                  rowIdPointer = row?._parentId;
                } while (!row.data?.[column.field]);

                return row.data?.[column.field] || '';
              }
            },
            aggFunc: column.aggregationField !== null ? getReportAggregator : null,
            comparator: (
              aggValueA: AggregationValue | string | number,
              aggValueB: AggregationValue | string | number,
            ) => {
              const valueA: string | number = isAggregationValue(aggValueA) ? aggValueA.value : aggValueA;
              const valueB: string | number = isAggregationValue(aggValueB) ? aggValueB.value : aggValueB;

              if (isString(valueA) && isString(valueB)) {
                return valueA.localeCompare(valueB);
              } else if (isNumber(valueA) && isNumber(valueB)) {
                return valueA - valueB;
              } else {
                return (valueA || '').toString().localeCompare((valueB || '').toString());
              }
            },
          },
        },
      ];
    }
  }, []);
};

export const statisticDetailsGroupingColumn: GroupingColumnConfig<any> = () => ({
  rendererParams: {
    suppressCount: true,
  },
});

export const defaultColumn: DefaultColumnConfig<any> = () => ({
  sortable: true,
  filter: true,
  suppressMenu: false,
  menuTabs: ['filterMenuTab'],
});

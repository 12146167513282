import { DataGrid } from 'components/shared/data/DataGrid/DataGrid';
import { getAllRows } from 'components/shared/data/DataGrid/utils/getAllRows';
import { useTranslator } from 'components/shared/hooks';
import { Heading } from 'components/shared/typography/Heading/Heading';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { routerSelector } from 'store/router/router.selectors';
import { FulfillmentState } from 'store/schoolObligations';
import { obligationReportsColumns } from './columns.config';
import { useStyles } from './Reports.styles';
import { ObligationReportRow, ReportsProps } from './Reports.types';

export const Reports: FC<ReportsProps> = (props) => {
  const classes = useStyles();
  const trans = useTranslator();
  const { key: locationKey } = useSelector(routerSelector);

  const isFulfilling = (obligation: FulfillmentState) => {
    return [FulfillmentState.EDUCATIONAL, FulfillmentState.SCHOOLING, FulfillmentState.FULFILLING].includes(obligation);
  };

  return (
    <div className={classes.container}>
      <Heading type="h5" className="mt-5 mb-4">
        {trans('CHILD_REGISTER.OBLIGATION.REPORTS.TITLE')}
      </Heading>
      <DataGrid<ObligationReportRow>
        key={locationKey}
        data={props.reports}
        isLoading={props.loading}
        columns={obligationReportsColumns()}
        loadingMessage={'CHILD_REGISTER.OBLIGATION.REPORTS.LOADING'}
        noResultsMessage={'CHILD_REGISTER.OBLIGATION.REPORTS.NO_RESULTS'}
        getRowClass={({ data, api }) => {
          const similarRows = getAllRows<ObligationReportRow>(api)
            .filter((row) => row.id === data.id)
            .filter((row) => isFulfilling(row.obligation));

          return similarRows.length > 1 && isFulfilling(data.obligation) ? classes.rowProblematic : '';
        }}
      />
    </div>
  );
};

import { MessageValues } from '@typings/extra-react-intl';
import { VariantType, OptionsObject } from 'notistack';
import { RSAA, RSAACreator } from 'redux-api-middleware';
import { FSA } from '@typings/redux';
import { Notification } from './general.state';
import { GeneralActionType } from './general.actionTypes';
import { uniqueId } from 'lodash';

export const setSidebarOpening = (value: boolean): FSA<GeneralActionType> => {
  return {
    type: GeneralActionType.SET_SIDEBAR_OPENING,
    payload: value,
  };
};

export const updateWindowInfo = (width: number, height: number): FSA<GeneralActionType> => {
  return {
    type: GeneralActionType.UPDATE_WINDOW_INFO,
    payload: { width, height },
  };
};

export const sendNotification = (
  type: VariantType,
  message: string,
  params: MessageValues = {},
  options: OptionsObject = {},
): FSA<GeneralActionType, Notification> => {
  return {
    type: GeneralActionType.SEND_NOTIFICATION,
    payload: {
      id: uniqueId(),
      message,
      params,
      type,
      options,
    },
  };
};

export const successNotification = (
  message: string,
  params: MessageValues = {},
  options: OptionsObject = {},
): FSA<GeneralActionType> => {
  return sendNotification('success', message, params, options);
};

export const errorNotification = (
  message: string,
  params: MessageValues = {},
  options: OptionsObject = {},
): FSA<GeneralActionType> => {
  return sendNotification('error', message, params, options);
};

export const infoNotification = (
  message: string,
  params: MessageValues = {},
  options: OptionsObject = {},
): FSA<GeneralActionType> => {
  return sendNotification('info', message, params, options);
};

export const removeNotification = (notificationId: string): FSA<GeneralActionType> => {
  return {
    type: GeneralActionType.REMOVE_NOTIFICATION,
    payload: notificationId,
  };
};

export const fetchDictionaries: RSAACreator<GeneralActionType> = () => {
  return {
    [RSAA]: {
      endpoint: '/dictionaries',
      method: 'GET',
      types: [
        GeneralActionType.FETCH_DICTIONARIES_REQUEST,
        GeneralActionType.FETCH_DICTIONARIES_SUCCESS,
        GeneralActionType.FETCH_DICTIONARIES_FAILURE,
      ],
      params: {},
    },
  };
};

export const fetchEnvironmentSettings: RSAACreator<GeneralActionType> = (origin: string) => {
  const safeOrigin = origin.replace(/\/$/, '');

  return {
    [RSAA]: {
      endpoint: '/environment',
      method: 'GET',
      types: [
        GeneralActionType.FETCH_ENVIRONMENT_REQUEST,
        {
          type: GeneralActionType.FETCH_ENVIRONMENT_SUCCESS,
          meta: { origin: safeOrigin },
        },
        {
          type: GeneralActionType.FETCH_ENVIRONMENT_FAILURE,
          meta: {
            ignoreNotFoundError: true,
          },
        },
      ],
      params: { baseUrl: safeOrigin },
    },
  };
};

export const lockScreen = (lock: boolean): FSA<GeneralActionType> => {
  return {
    type: GeneralActionType.SET_SCREEN_LOCK,
    payload: lock,
  };
};

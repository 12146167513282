export enum GeneralActionType {
  SET_SIDEBAR_OPENING = 'general/SET_SIDEBAR_OPENING',
  UPDATE_WINDOW_INFO = 'general/UPDATE_WINDOW_INFO',
  SET_SCREEN_LOCK = 'general/SET_SCREEN_LOCK',
  SEND_NOTIFICATION = 'general/SEND_NOTIFICATION',
  REMOVE_NOTIFICATION = 'general/REMOVE_NOTIFICATION',
  FETCH_DICTIONARIES_REQUEST = 'general/FETCH_DICTIONARIES_REQUEST',
  FETCH_DICTIONARIES_SUCCESS = 'general/FETCH_DICTIONARIES_SUCCESS',
  FETCH_DICTIONARIES_FAILURE = 'general/FETCH_DICTIONARIES_FAILURE',
  FETCH_ENVIRONMENT_REQUEST = 'general/FETCH_ENVIRONMENT_REQUEST',
  FETCH_ENVIRONMENT_SUCCESS = 'general/FETCH_ENVIRONMENT_SUCCESS',
  FETCH_ENVIRONMENT_FAILURE = 'general/FETCH_ENVIRONMENT_FAILURE',
}

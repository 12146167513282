import React, { useEffect, useMemo, useState } from 'react';
import { Theme } from '@material-ui/core';
import { Form } from 'components/shared/form';
import { useTranslator } from 'components/shared/hooks/useTranslator/useTranslator';
import { ThemeProvider } from '@material-ui/styles';
import { formPreviewThemeFactory } from 'styles/themes/formPreviewTheme';
import { TabPanel } from 'components/shared/surfaces/TabPanel/TabPanel';
import { SystemContainer } from 'components/settings/System/SystemContainer';
import { SettingsProps, SettingsFormValues } from 'components/settings/Settings.types';
import { Splash } from 'components/shared/surfaces/Splash/Splash';
import { TextLoader } from 'components/shared/loaders/TextLoader/TextLoader';
import { PollsContainer } from 'components/settings/Polls/PollsContainer';
import { UserContainer } from 'components/settings/User/UserContainer';
import { Button } from 'components/shared/buttons/Button/Button';
import { FormikProps } from 'formik';
import { Prompt } from 'react-router';
import { useCheckAccess, useNotification } from 'components/shared/hooks';
import { DCSType } from 'store/systemSettings/systemSettings.types';
import { OfficialPermissionLevel, OfficialResourceGroup } from 'store/officials';

export const Settings = ({
  initialValues,
  loading,
  handleFormSubmit,
  submitted,
  savedFormValues,
  sodIntegration,
}: SettingsProps) => {
  const trans = useTranslator();
  const { info } = useNotification();
  const minFullAccessConfigurations = useCheckAccess(
    OfficialResourceGroup.configurations,
    OfficialPermissionLevel.fullAccess,
  );
  const minReadOnlyAccessConfigurations = useCheckAccess(
    OfficialResourceGroup.configurations,
    OfficialPermissionLevel.readOnly,
  );
  const minFullAccessPools = useCheckAccess(OfficialResourceGroup.polls, OfficialPermissionLevel.fullAccess);
  const minReadOnlyAccessPools = useCheckAccess(OfficialResourceGroup.polls, OfficialPermissionLevel.readOnly);
  const [activeTab, setActiveTab] = useState(null);
  useEffect(() => {
    if (minReadOnlyAccessConfigurations) {
      setActiveTab('system');
      return;
    }
    if (minReadOnlyAccessPools) {
      setActiveTab('polls');
    }
  }, [minReadOnlyAccessPools, minReadOnlyAccessConfigurations]);
  const tabs = useMemo(() => {
    const primaryTabs = [
      {
        title: trans('SETTINGS.SYSTEM.TAB'),
        key: 'system',
        show: minReadOnlyAccessConfigurations,
        content: <SystemContainer loading={loading} />,
      },
      {
        title: trans('SETTINGS.POLL.TAB'),
        key: 'polls',
        show: minReadOnlyAccessPools,
        content: <PollsContainer loading={loading} />,
      },
    ];

    const userTab = [
      {
        title: trans('SETTINGS.OFFICIAL.TAB'),
        key: 'user',
        show: minReadOnlyAccessConfigurations,
        content: <UserContainer loading={loading} />,
      },
    ];
    const tabs = sodIntegration === DCSType.MDOK ? [...primaryTabs, ...userTab] : [...primaryTabs];
    return tabs.filter((tab) => tab.show);
  }, [trans, loading, sodIntegration, minReadOnlyAccessConfigurations, minReadOnlyAccessPools]);

  const handleSubmit = (form) => {
    form.handleSubmit();
  };

  const handleRevertingFormChanges = (form) => {
    if (submitted) {
      form.setValues(savedFormValues);
    } else {
      form.resetForm();
    }
    info('SETTINGS.FORM.UNDO_CHANGES');
  };

  const renderLoader = () => (
    <Splash>
      <TextLoader name="spinner" text={trans('COMMON.LOADING_DATA')} />
    </Splash>
  );
  const handleChangeTabs = (changeTab: string) => {
    setActiveTab(changeTab);
  };
  const fullAccessToSystem = minFullAccessConfigurations && (activeTab === 'system' || activeTab === 'user');
  const fullAccessToPolls = minFullAccessPools && activeTab === 'polls';
  const renderForm = () => (
    <Form<SettingsFormValues> initialValues={initialValues} onSubmit={handleFormSubmit}>
      {(form: FormikProps<SettingsFormValues>) => (
        <ThemeProvider theme={(parentTheme: Theme) => formPreviewThemeFactory(parentTheme)}>
          <Prompt
            when={(form.dirty && !submitted) || (savedFormValues && form.values !== savedFormValues)}
            message={trans('COMMON.CONFIRM_EXIT')}
          />
          <TabPanel tabs={tabs} defaultTab={activeTab} change={handleChangeTabs} />
          <div className="row my-4">
            <div className="col">
              <Button
                onClick={() => handleRevertingFormChanges(form)}
                disabled={form.isSubmitting || (!form.dirty && !savedFormValues) || form.values === savedFormValues}
                color="secondary"
                variant="outlined"
              >
                {trans('COMMON.UNDO_CHANGES')}
              </Button>
              {fullAccessToSystem || fullAccessToPolls ? (
                <Button
                  onClick={() => handleSubmit(form)}
                  disabled={form.isSubmitting}
                  color="secondary"
                  variant="contained"
                  className="ml-3"
                >
                  {trans('COMMON.SAVE')}
                </Button>
              ) : null}
            </div>
          </div>
        </ThemeProvider>
      )}
    </Form>
  );

  return loading || !activeTab ? renderLoader() : renderForm();
};

import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon';
import React, { FC, useState } from 'react';
import { StepModal } from 'components/shared/dialogs/StepModal';
import { Step } from 'components/shared/dialogs/StepModal/StepModal.types';
import { Button } from 'components/shared/buttons/Button/Button';
import { useTranslator } from 'components/shared/hooks';
import { SettingsContainer } from './steps/Settings/SettingsContainer';
import { ImportProps } from './Import.types';
import { PreviewContainer } from './steps/Preview/PreviewContainer';
import { FileImportContainer } from 'components/institution/Unit/StudentsRegister/Import/steps/FileImport/FileImportContainer';
import { useDispatch } from 'react-redux';
import { clearStudentRegisterInformation } from 'store/institutions';

export const Import: FC<ImportProps> = ({ onClose, token }) => {
  const dispatch: (...params: any) => Promise<any> = useDispatch();
  const trans = useTranslator();
  const [isModalOpen, setModal] = useState(false);
  const [columns, setColumns] = useState([]);

  const FileStep = (props) => <FileImportContainer {...props} />;
  const SettingsStep = (props) => <SettingsContainer {...props} onColumnsSave={setColumns} />;
  const PreviewStep = (props) => <PreviewContainer {...props} columns={columns} />;

  const handleOnClose = (silentClose = false) => {
    dispatch(clearStudentRegisterInformation());

    if (token && !silentClose) {
      onClose();
    }

    setModal(false);
  };

  const steps: Step[] = [
    {
      label: trans('INSTITUTIONS.STUDENTS_REGISTER.IMPORT_STEPS.FILE.TITLE'),
      content: FileStep,
    },
    {
      label: trans('INSTITUTIONS.STUDENTS_REGISTER.IMPORT_STEPS.SETTINGS.TITLE'),
      content: SettingsStep,
    },
    {
      label: trans('INSTITUTIONS.STUDENTS_REGISTER.IMPORT_STEPS.PREVIEW.TITLE'),
      content: PreviewStep,
    },
  ];

  return (
    <div className="container-fluid mb-3">
      <div className="row justify-content-end">
        <Button onClick={() => setModal(true)} color="secondary" variant="contained">
          <FontAwesomeIcon name={'upload'} className="mr-2" />
          {trans('INSTITUTIONS.STUDENTS_REGISTER.IMPORT.BUTTON')}
        </Button>
      </div>
      {isModalOpen && (
        <StepModal
          onClose={handleOnClose}
          open={isModalOpen}
          title={trans('INSTITUTIONS.STUDENTS_REGISTER.IMPORT.TITLE')}
          steps={steps}
        />
      )}
    </div>
  );
};

import { replace } from 'connected-react-router';
import { ThunkMiddleware } from 'redux-thunk';
import { appLogout } from 'store/auth/auth.action';
import { errorNotification } from 'store/general';

export const apiErrorsMiddleware: ThunkMiddleware = (store) => (next) => (action) => {
  if (!action.error || !(action.payload instanceof Error)) {
    return next(action);
  }

  const badRequestHandler = () => {
    return next(action);
  };

  const unauthorizedError = () => {
    if (action.meta && action.meta.ignoreUnauthorizedError) {
      // eslint-disable-next-line no-console
      console.warn('Ignored 401 error for', action.type);
      return next(action);
    } else {
      return next(appLogout());
    }
  };

  const notFoundHandler = () => {
    if (action.meta && action.meta.ignoreNotFoundError) {
      // eslint-disable-next-line no-console
      console.warn('Ignored 404 error for', action.type);
      return next(action);
    } else {
      store.dispatch(errorNotification('ERROR.NOT-FOUND'));
      return store.dispatch(replace('/not-found'));
    }
  };

  const forbiddenHandler = () => {
    return next(action);
  };

  const genericErrorHandler = () => {
    store.dispatch(errorNotification('ERROR.GENERAL-ERROR'));
    return next(action);
  };

  const errorHandlers = {
    400: badRequestHandler,
    401: unauthorizedError,
    403: forbiddenHandler,
    404: notFoundHandler,
  };

  if (errorHandlers[action.payload.status]) {
    return errorHandlers[action.payload.status]();
  } else {
    return genericErrorHandler();
  }
};

import { Splash } from 'components/shared/surfaces/Splash/Splash';
import React, { FC } from 'react';
import { Loader } from '../Loader/Loader';
import { LoaderProps } from '../Loader/Loader.types';

interface TextLoaderProps extends LoaderProps {
  text: string;
  height?: number | string;
}

export const TextLoader: FC<TextLoaderProps> = ({ text, height = 300, ...loaderProps }) => {
  return (
    <Splash height={height}>
      <div>
        <Loader name={'cog'} animation={'spin'} size={'normal'} color={'primary'} {...loaderProps} />
        <span className="ml-1 mt-1">{text}</span>
      </div>
    </Splash>
  );
};

import { muiStyles } from 'utils/design';

interface BoxedCellStyle {
  background: string;
  border: string;
}

export const useStyles = muiStyles<BoxedCellStyle>(() => ({
  box: {
    border: ({ border }) => border || 'none',
    background: ({ background }) => background || 'transparent',
    padding: '4px 8px',
    margin: '-5px -5px',
    borderRadius: '4px',
    height: '30px',
  },
}));

import { muiStyles } from 'utils/design';

export const useStyles = muiStyles((theme) => ({
  option: {
    display: 'flex',
    marginBottom: theme.spacing(1),
  },
  name: {
    marginRight: theme.spacing(1),
    flexGrow: 1,
    maxWidth: '500px',
  },
  remove: {
    paddingTop: '3px',
  },
  fakeInput: {
    paddingTop: '2px',
    paddingRight: theme.spacing(1),
    width: theme.spacing(8),
    textAlign: 'right',
  },
}));

import { RESET_STATE } from 'store/store';
import { UserListActionType } from 'store/userList/userList.actionTypes';
import { defaultState, UserListState } from 'store/userList/userList.state';
import { FSAReducer, Behaviours } from '@typings/redux';
import { fromApiFormat } from 'utils/api';

export const userListReducer: FSAReducer<UserListState, UserListActionType> = (state = defaultState, action) => {
  const behaviours: Behaviours<UserListState, UserListActionType> = {
    [RESET_STATE]: () => ({
      ...defaultState,
    }),
    [UserListActionType.FETCH_USER_LIST_REQUEST]: () => {
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      };
    },
    [UserListActionType.FETCH_USER_LIST_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        list: {
          ...state.list,
          items: fromApiFormat(payload.items),
          loading: false,
        },
      };
    },
  };

  return behaviours[action.type] ? behaviours[action.type](action) : state;
};

import { colors } from 'styles/colors';
import { muiStyles } from 'utils/design';

export const useStyles = muiStyles((theme) => ({
  guardian: {
    border: `1px solid ${colors.gridBorder}`,
    padding: theme.spacing(2, 3, 1),
    margin: theme.spacing(2, 0),
    borderRadius: '4px',
    position: 'relative',
  },
  actions: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  typeHeader: {
    height: '30px',
  },
  helpIcon: {
    marginTop: '-4px',
  },
}));

import React, { Fragment, useRef, useState } from 'react';
import { Route, Switch } from 'react-router';
import { useReactToPrint } from 'react-to-print';
import { RFC } from '@typings/extra-react-router';
import { Page } from 'components/layout/panel/Page/Page';
import { Combined } from 'components/polls/Results/Combined/Combined';
import { Recipients } from 'components/polls/Results/Recipients/Recipients';
import { ResponsesContainer } from 'components/polls/Results/Responses/ResponsesContainer';
import { ResultsContainer } from 'components/polls/Results/ResultsContainer';
import { useNotification, useTranslator } from 'components/shared/hooks';
import { Breadcrumb } from 'components/shared/navigation/Breadcrumbs/Breadcrumb';
import {
  ROUTE_POLLS_LIST,
  ROUTE_POLLS_RESULTS,
  ROUTE_POLLS_RESULTS_RECIPIENTS,
  ROUTE_POLLS_RESULTS_RESPONSES,
} from 'config/routes';
import { PollActionRoute } from 'config/routes.types';

export const ResultsView: RFC<PollActionRoute> = ({ match }) => {
  const ref = useRef<HTMLDivElement>();
  const trans = useTranslator();
  const { info } = useNotification();
  const [printMode, setPrintMode] = useState<boolean>(false);
  const pollId = match.params.id;

  const handlePrint = useReactToPrint({
    content: () => ref.current,
    pageStyle: 'margin-print',
    onBeforeGetContent() {
      info('POLLS.RESULTS.PRINTING', {}, { autoHideDuration: 2000 });
      setPrintMode(true);
      return new Promise((resolve) => {
        setTimeout(() => resolve(true), 2000);
      });
    },
    onAfterPrint() {
      setPrintMode(false);
    },
  });

  return (
    <Fragment>
      <Breadcrumb label={trans('BREADCRUMB.POLLS.POLLS_LIST')} to={ROUTE_POLLS_LIST} />
      <Breadcrumb label={trans('BREADCRUMB.POLLS.POLL_RESULTS')} static />

      <Page title={trans('POLLS.RESULTS.TITLE')}>
        <ResultsContainer pollId={pollId}>
          {(pollId, pollResults, recipients) => (
            <Switch>
              <Route
                exact
                path={ROUTE_POLLS_RESULTS}
                component={() => (
                  <Combined
                    pollId={pollId}
                    results={pollResults.results}
                    pollName={pollResults.name}
                    printMode={printMode}
                    setPrintMode={setPrintMode}
                    print={handlePrint}
                    ref={ref}
                  />
                )}
              />
              <Route
                exact
                path={ROUTE_POLLS_RESULTS_RESPONSES}
                component={() => <ResponsesContainer pollId={pollId} responsesIds={pollResults.responses} />}
              />
              <Route
                exact
                path={ROUTE_POLLS_RESULTS_RECIPIENTS}
                component={() => <Recipients recipients={recipients} />}
              />
            </Switch>
          )}
        </ResultsContainer>
      </Page>
    </Fragment>
  );
};

import { Page, BirthYearModel } from '@typings/common';
import {
  ObligationReport,
  ReportStatus,
  ReportStatusModel,
  ObligationReportDetails,
  ObligationReportInstitution,
  ObligationReportChildrenListFilters,
  ObligationReportChild,
  FulfillmentOption,
  ReportBrowserFilter,
} from 'store/schoolObligations/schoolObligations.types';

export interface SchoolObligationsState {
  birthYears: {
    list: BirthYearModel[];
    loading: boolean;
    error: any;
  };
  reports: {
    loading: boolean;
    items: ObligationReport[];
  };
  reportRequest: {
    reportId: string;
    reportStatus: ReportStatusModel;
    error: boolean;
  };
  reportDetails: {
    loading: boolean;
    report: ObligationReportDetails;
    error: any;
  };
  reportChildrenList: {
    loading: boolean;
    pages: Page<ObligationReportChild>[];
    filters: ObligationReportChildrenListFilters;
    total: number;
    error: any;
  };
  institutions: {
    loading: boolean;
    items: ObligationReportInstitution[];
  };
  fulfillmentOptions: {
    loading: boolean;
    items: FulfillmentOption[];
  };
  reportBrowserFilters: ReportBrowserFilter;
}

export const defaultState: SchoolObligationsState = {
  birthYears: {
    list: [],
    loading: false,
    error: null,
  },
  reports: {
    loading: false,
    items: [],
  },
  reportBrowserFilters: {
    schoolYear: null,
  },
  reportRequest: {
    reportId: null,
    reportStatus: {
      status: ReportStatus.SCHEDULED,
      progress: 0,
    },
    error: false,
  },
  reportDetails: {
    loading: false,
    report: null,
    error: null,
  },
  reportChildrenList: {
    loading: false,
    pages: [],
    filters: {},
    total: 0,
    error: null,
  },
  institutions: {
    loading: false,
    items: [],
  },
  fulfillmentOptions: {
    loading: false,
    items: [],
  },
};

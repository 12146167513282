import { muiStyles } from 'utils/design';

export const useStyles = muiStyles<boolean>({
  icon: {
    fontSize: '1.2rem',
  },
  iconWrapper: {
    minWidth: (floatingMenus) => (floatingMenus ? '40px' : 'inherit'),
  },
});

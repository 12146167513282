import { muiStyles } from 'utils/design';

export const useStyles = muiStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  title: {
    flexGrow: 1,
  },
}));

import { FSA } from '@typings/redux';
import { RSAA, RSAACreator } from 'redux-api-middleware';
import { PollFilters, PollForm, PollPublishForm, PollsActionType as ActionType, ResponseForm } from 'store/polls';
import { toApiFormat } from 'utils/api';
import { clean } from 'utils/object/clean';
import { fileToObjectFetch } from 'utils/redux/fileToObjectFetch';

export const fetchPolls: RSAACreator<ActionType> = (filters: PollFilters, quietUpdate = false) => {
  return {
    [RSAA]: {
      endpoint: `/polls`,
      method: 'GET',
      types: [
        {
          type: ActionType.FETCH_POLLS_LIST_REQUEST,
          meta: { quietUpdate },
        },
        ActionType.FETCH_POLLS_LIST_SUCCESS,
        ActionType.FETCH_POLLS_LIST_FAILURE,
      ],
      params: {
        filter: clean(filters),
      },
    },
  };
};

export const setPollFilters = (filters: PollFilters): FSA => ({
  type: ActionType.SET_FILTERS,
  payload: { filters },
});

export const fetchPollDetails: RSAACreator<ActionType> = (pollId: string) => {
  return {
    [RSAA]: {
      endpoint: `/polls/${pollId}`,
      method: 'GET',
      types: [
        ActionType.FETCH_POLL_DETAILS_REQUEST,
        ActionType.FETCH_POLL_DETAILS_SUCCESS,
        ActionType.FETCH_POLL_DETAILS_FAILURE,
      ],
    },
  };
};

export const createPoll: RSAACreator<ActionType> = (data: PollForm) => {
  return {
    [RSAA]: {
      endpoint: `/polls`,
      method: 'POST',
      types: [
        {
          type: ActionType.CREATE_POLL_DETAILS_REQUEST,
          meta: { lockScreen: true },
        },
        ActionType.CREATE_POLL_DETAILS_SUCCESS,
        ActionType.CREATE_POLL_DETAILS_FAILURE,
      ],
      body: toApiFormat(data),
    },
  };
};

export const updatePoll: RSAACreator<ActionType> = (pollId: string, data: PollForm) => {
  return {
    [RSAA]: {
      endpoint: `/polls/${pollId}`,
      method: 'PUT',
      types: [
        {
          type: ActionType.UPDATE_POLL_DETAILS_REQUEST,
          meta: { lockScreen: true },
        },
        ActionType.UPDATE_POLL_DETAILS_SUCCESS,
        ActionType.UPDATE_POLL_DETAILS_FAILURE,
      ],
      body: toApiFormat(data),
    },
  };
};

export const deletePoll: RSAACreator<ActionType> = (pollId: string) => {
  return {
    [RSAA]: {
      endpoint: `/polls/${pollId}`,
      method: 'DELETE',
      types: [
        {
          type: ActionType.DELETE_POLL_REQUEST,
          meta: { lockScreen: true },
        },
        ActionType.DELETE_POLL_SUCCESS,
        ActionType.DELETE_POLL_FAILURE,
      ],
    },
  };
};

export const closePoll: RSAACreator<ActionType> = (pollId: string) => {
  return {
    [RSAA]: {
      endpoint: `/polls/${pollId}/close`,
      method: 'PUT',
      types: [
        {
          type: ActionType.CLOSE_POLL_REQUEST,
          meta: { lockScreen: true },
        },
        ActionType.CLOSE_POLL_SUCCESS,
        ActionType.CLOSE_POLL_FAILURE,
      ],
    },
  };
};

export const clonePoll: RSAACreator<ActionType> = (pollId: string) => {
  return {
    [RSAA]: {
      endpoint: `/polls/${pollId}/clone`,
      method: 'POST',
      types: [
        {
          type: ActionType.CLONE_POLL_DETAILS_REQUEST,
          meta: { lockScreen: true },
        },
        ActionType.CLONE_POLL_DETAILS_SUCCESS,
        ActionType.CLONE_POLL_DETAILS_FAILURE,
      ],
    },
  };
};

export const fetchSyncInstitutions: RSAACreator<ActionType> = () => {
  return {
    [RSAA]: {
      endpoint: `/polls/institutions`,
      method: 'GET',
      types: [
        ActionType.FETCH_SYNC_INSTITUTIONS_REQUEST,
        ActionType.FETCH_SYNC_INSTITUTIONS_SUCCESS,
        ActionType.FETCH_SYNC_INSTITUTIONS_FAILURE,
      ],
    },
  };
};

export const fetchRecipientsGroups: RSAACreator<ActionType> = () => {
  return {
    [RSAA]: {
      endpoint: `/polls/recipient-groups`,
      method: 'GET',
      types: [
        ActionType.FETCH_RECIPIENTS_GROUPS_REQUEST,
        ActionType.FETCH_RECIPIENTS_GROUPS_SUCCESS,
        ActionType.FETCH_RECIPIENTS_GROUPS_FAILURE,
      ],
    },
  };
};

export const fetchRecipientCounts: RSAACreator<ActionType> = (institutionId: string) => {
  return {
    [RSAA]: {
      endpoint: `/polls/institutions/${institutionId}/recipients`,
      method: 'GET',
      types: [
        {
          type: ActionType.FETCH_RECIPIENTS_COUNTS_REQUEST,
          meta: { institutionId },
        },
        {
          type: ActionType.FETCH_RECIPIENTS_COUNTS_SUCCESS,
          meta: { institutionId },
        },
        {
          type: ActionType.FETCH_RECIPIENTS_COUNTS_FAILURE,
          meta: { institutionId },
        },
      ],
    },
  };
};

export const clearRecipientsCounts = (): FSA => {
  return {
    type: ActionType.CLEAR_RECIPIENTS_COUNTS,
  };
};

export const publishPoll: RSAACreator<ActionType> = (pollId: string, body: PollPublishForm, baseUrl: string) => {
  return {
    [RSAA]: {
      endpoint: `/polls/${pollId}/publish?base_url=${baseUrl}`,
      method: 'PUT',
      types: [
        {
          type: ActionType.PUBLISH_POLL_REQUEST,
          meta: { lockScreen: true },
        },
        ActionType.PUBLISH_POLL_SUCCESS,
        ActionType.PUBLISH_POLL_FAILURE,
      ],
      body: toApiFormat(body),
    },
  };
};

export const fetchPollResults: RSAACreator<ActionType> = (pollId: string) => {
  return {
    [RSAA]: {
      endpoint: `/polls/${pollId}/results`,
      method: 'GET',
      types: [
        ActionType.FETCH_POLL_RESULTS_REQUEST,
        ActionType.FETCH_POLL_RESULTS_SUCCESS,
        ActionType.FETCH_POLL_RESULTS_FAILURE,
      ],
    },
  };
};

export const fetchPollResponse: RSAACreator<ActionType> = (pollId: string, responseId: string) => {
  return {
    [RSAA]: {
      endpoint: `/polls/${pollId}/results/${responseId}`,
      method: 'GET',
      types: [
        {
          type: ActionType.FETCH_POLL_RESPONSE_REQUEST,
          meta: { responseId },
        },
        {
          type: ActionType.FETCH_POLL_RESPONSE_SUCCESS,
          meta: { responseId },
        },
        {
          type: ActionType.FETCH_POLL_RESPONSE_FAILURE,
          meta: { responseId },
        },
      ],
    },
  };
};

export const clearResponses = (): FSA => {
  return {
    type: ActionType.CLEAR_RESPONSES,
  };
};

export const fetchPollRecipients: RSAACreator<ActionType> = (pollId: string) => {
  return {
    [RSAA]: {
      endpoint: `/polls/${pollId}/recipients`,
      method: 'GET',
      types: [
        ActionType.FETCH_POLL_RECIPIENTS_REQUEST,
        ActionType.FETCH_POLL_RECIPIENTS_SUCCESS,
        ActionType.FETCH_POLL_RECIPIENTS_FAILURE,
      ],
    },
  };
};

export const fetchQuestionnaire: RSAACreator<ActionType> = (pollId: string, token: string) => {
  return {
    [RSAA]: {
      endpoint: `/public/polls/${pollId}/${token}`,
      method: 'GET',
      types: [
        ActionType.FETCH_QUESTIONNAIRE_REQUEST,
        ActionType.FETCH_QUESTIONNAIRE_SUCCESS,
        {
          type: ActionType.FETCH_QUESTIONNAIRE_FAILURE,
          meta: {
            ignoreNotFoundError: true,
            ignoreUnauthorizedError: true,
          },
        },
      ],
    },
  };
};

export const saveResponse: RSAACreator<ActionType> = (pollId: string, token: string, data: ResponseForm) => {
  return {
    [RSAA]: {
      endpoint: `/public/polls/${pollId}/${token}`,
      method: 'POST',
      types: [
        {
          type: ActionType.SAVE_RESPONSE_REQUEST,
          meta: { lockScreen: true },
        },
        ActionType.SAVE_RESPONSE_SUCCESS,
        {
          type: ActionType.SAVE_RESPONSE_FAILURE,
          meta: {
            ignoreNotFoundError: true,
            ignoreUnauthorizedError: true,
          },
        },
      ],
      body: toApiFormat(data),
    },
  };
};

export const downloadPoll: RSAACreator<ActionType> = (pollId: string, fileName: string) => {
  return {
    [RSAA]: {
      endpoint: `/polls/${pollId}/results`,
      method: 'GET',
      fetch: fileToObjectFetch('text/csv', `${fileName}.csv`),
      types: [
        ActionType.DOWNLOAD_POLL_REQUEST,
        {
          type: ActionType.DOWNLOAD_POLL_SUCCESS,
          meta: { downloadResponse: true },
        },
        ActionType.DOWNLOAD_POLL_FAILURE,
      ],
    },
  };
};

import { Tooltip } from '@material-ui/core';
import { IconDefinition, IconsProps } from './Icons.types';
import { useFormatter, useTranslator } from 'components/shared/hooks';
import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon';
import React, { FC } from 'react';
import { isFunction } from 'lodash';
import { useStyles } from './Icons.styles';

export const IconsCellRenderer: FC<IconsProps> = (props) => {
  const trans = useTranslator();
  const formatter = useFormatter();
  const classes = useStyles();

  const formatTooltip = (tooltip) => {
    if (isFunction(tooltip)) {
      return tooltip(trans, formatter);
    } else if (tooltip) {
      return trans(tooltip);
    } else {
      return '';
    }
  };

  const renderIcon = (iconDefinition: IconDefinition, index: number) => {
    return (
      <Tooltip title={formatTooltip(iconDefinition.tooltip)} placement="top" key={index}>
        <span>
          <FontAwesomeIcon
            name={iconDefinition.icon}
            fixedWidth={true}
            fixedHeight={true}
            color={props.icons(props.data)[index].color}
            className={classes.icon}
          />
        </span>
      </Tooltip>
    );
  };

  if (!props.data) {
    return null;
  }

  return <div>{props.icons(props.data).map((icon, index) => renderIcon(icon, index))}</div>;
};

import { every } from 'lodash';

export interface AuditLog {
  id: string;
  createdAt: string;
  userId: string;
  userName: string;
  commandName: string;
}

export enum SchoolStatType {
  TABLE = 'table',
  TREE = 'tree',
  AGG_TREE = 'agg-tree',
}

export interface SchoolStat {
  id: string;
  name: string;
  type: SchoolStatType;
  updateDate: string;
}

export interface StatDetailsColumn {
  name: string;
  field: string;
  aggregation?: AggregationMethod;
  aggregationField: string | null;
  group: boolean;
}

export const isRowGroup = (row: StatDetailsRow | StatDetailsRowGroup): row is StatDetailsRowGroup => {
  return 'children' in row;
};

export interface StatDetailsRowGroup {
  _id?: string;
  _parentId?: string;
  data: StatDetailsRow;
  children?: StatDetailsRowGroup[] | StatDetailsRow[];
}

export interface StatDetailsRow {
  _parentId?: string;
  _isFake?: boolean;
  [key: string]: any;
}

export interface AggregationValue {
  value: string | number;
  extra: { [key: string]: string | number };
  aggregation: AggregationConfig | null;
  nextAggregationsPath: AggregationConfig[];
  toString: () => string;
}

export const isAggregationValue = (value: string | number | AggregationValue): value is AggregationValue => {
  return typeof value === 'object' && 'nextAggregationsPath' in value;
};

export const isAggregationValueList = (value: string[] | AggregationValue[]): value is AggregationValue[] => {
  return every(value, (item: string | AggregationValue) => isAggregationValue(item));
};

export interface AggregationResult {
  value: string | number;
  extra?: { [key: string]: string | number };
}

export interface AggregationConfig {
  method: AggregationMethod;
  value?: any;
}

export enum AggregationMethod {
  SUM = 'sum',
  COUNT = 'count',
  AVG = 'avg',
  NONE = 'none',
  FIXED = 'fixed',
}

export interface StatDetailsTable {
  columns: StatDetailsColumn[];
  data: StatDetailsRow[] | StatDetailsRowGroup[];
}

export interface StatDetails {
  id: string;
  name: string;
  table: StatDetailsTable;
  updateDate: string;
  type: SchoolStatType;
}

export interface OtherCommuneStat {
  commune: {
    id?: string;
    name: string;
  };
  institution: {
    id: string;
    name: string;
  };
  unit: {
    id: string;
    name: string;
  };
  educationStage: {
    id: string;
    name: string;
  };
  accreditation: {
    id: string;
    name: string;
  };
  studentsCount: number;
}

export interface OtherCommuneStudentsStat {
  firstName: string;
  lastName: string;
  identificationNumber: string;
  level: number;
  city: string;
  street: string;
  buildingNumber: number;
  flatNumber: number;
}

export interface ComponentFactors {
  studentsTimesWeight: number;
}

export interface SubventionFactors {
  financialStandardA: number;
  diFactor: number;
  subventionA: ComponentFactors;
  subventionB: ComponentFactors;
  subventionC: ComponentFactors;
}

export enum SynchronizationLogLevel {
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export enum SynchronizationLogSource {
  BEL = 'BEL',
  GRANT_SYSTEM = 'GrantSystem',
  KU_GRANT_SYSTEM = 'KU GrantSystem',
  KU_IMPORT = 'KU IMPORT',
  KU_ZSE = 'KU ZSE',
  ZSE = 'ZSE',
  KU_DO_KED = 'KU do KED',
}
export interface SynchronizationLog {
  id: string;
  createdAt: string;
  child: string | null;
  details: Record<string, unknown>;
  iteration: string;
  level: SynchronizationLogLevel;
  message: string;
  school: string | null;
  source: SynchronizationLogLevel;
}

export interface SynchronizationLogFilters {
  level?: SynchronizationLogLevel;
  search?: string;
  from?: string;
  to?: string;
  source?: SynchronizationLogSource;
}

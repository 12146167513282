import { colors } from 'styles/colors';
import { muiStyles } from 'utils/design';

export const useStyles = muiStyles((theme) => ({
  form: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  paper: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    height: 0,
  },
  expirationDatePicker: {
    maxWidth: '300px',
    marginTop: theme.spacing(2),
  },
  lists: {
    flexGrow: 1,
    display: 'flex',
    minHeight: '400px',
  },
  listTitle: {
    margin: theme.spacing(1, 0),
    fontWeight: 'bolder',
  },
  institutionLists: {
    display: 'flex',
    flexDirection: 'column',
    width: `calc(60% - ${theme.spacing(4)}px)`,
    marginRight: theme.spacing(4),
  },
  recipientGroupsLists: {
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
  },
  responseMode: {
    flexDirection: 'row',
  },
  recipientsCount: {
    fontWeight: 'bolder',
    border: `1px solid ${colors.gridBorder}`,
    padding: theme.spacing(1, 2),
    marginTop: theme.spacing(2),
    borderRadius: '4px',
  },
}));

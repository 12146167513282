import { InfoOutlined } from '@material-ui/icons';
import React, { useContext, FC } from 'react';
import { InputText, RadioBox } from 'components/shared/form';
import { FormikProps } from 'formik';
import { InstitutionDictionaryItemModel } from 'store/institutions/institutions.types';
import { UnitFormValues } from 'components/institution/Unit/Editor/Editor.types';
import { LocaleContext } from 'context/locale';
import { Heading } from 'components/shared/typography/Heading/Heading';
import { Autocomplete } from 'components/shared/form/Autocomplete/Autocomplete';

interface InstitutionPart {
  props: FormikProps<UnitFormValues>;
  institutions: InstitutionDictionaryItemModel[];
  disableInputs: boolean;
  isSynchronized: boolean;
}

export const InstitutionPart: FC<InstitutionPart> = ({ props, institutions, disableInputs, isSynchronized }) => {
  const { trans } = useContext(LocaleContext);
  const checkIfOptionEnabled = (option: string) => props.values && props.values.chooseInstitution === option;

  const tooltipText = isSynchronized
    ? trans('INSTITUTIONS.UNIT_DATA.GENERAL_TOOLTIPS.IMPORTED_FROM_SYNERGIA')
    : trans('INSTITUTIONS.UNIT_DATA.INSTITUTION.BLOCKED');

  return (
    <div className="container-fluid pb-3">
      <Heading type="h5">{trans('INSTITUTIONS.UNIT_DATA.INSTITUTION.TITLE')}</Heading>
      <div className="row mt-4">
        <div className="col-3">
          <RadioBox
            name="chooseInstitution"
            labelText={trans('INSTITUTIONS.UNIT_DATA.INSTITUTION.EXISTING_RADIO')}
            value="chooseExisting"
            disabled={disableInputs}
            hasIcon={disableInputs}
            icon={<InfoOutlined fontSize="small" />}
            tooltipText={tooltipText}
          />
        </div>
        <div className="col-3">
          <RadioBox
            name="chooseInstitution"
            labelText={trans('INSTITUTIONS.UNIT_DATA.INSTITUTION.NEW_RADIO')}
            value="createNew"
            disabled={disableInputs}
            hasIcon={disableInputs}
            icon={<InfoOutlined fontSize="small" />}
            tooltipText={tooltipText}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-3">
          <Autocomplete
            name="institution.id"
            labelText={trans('INSTITUTIONS.UNIT_DATA.INSTITUTION.EXISTING_PLACEHOLDER')}
            placeholder={trans('INSTITUTIONS.UNIT_DATA.INSTITUTION.EXISTING_PLACEHOLDER')}
            options={institutions.map((item) => ({
              label: item.name,
              value: item.id,
            }))}
            required={checkIfOptionEnabled('chooseExisting') || disableInputs}
            disabled={!checkIfOptionEnabled('chooseExisting') || disableInputs}
            hasIcon={disableInputs}
            icon={<InfoOutlined fontSize="small" />}
            tooltipText={tooltipText}
          />
        </div>
        <div className="col-3">
          <InputText
            name="institution.name"
            labelText={trans('INSTITUTIONS.UNIT_DATA.INSTITUTION.NEW_RADIO_PLACEHOLDER')}
            required={checkIfOptionEnabled('createNew') || disableInputs}
            disabled={!checkIfOptionEnabled('createNew') || disableInputs}
            hasIcon={disableInputs}
            icon={<InfoOutlined fontSize="small" />}
            tooltipText={tooltipText}
          />
        </div>
      </div>
    </div>
  );
};

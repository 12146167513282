import React, { FC, useEffect } from 'react';
import { Toolbar } from 'components/schoolObligation/ReportDetails/Toolbar/Toolbar';
import { AppDispatch } from '@typings/redux';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchFulfillmentOptions,
  fetchSchoolObligationsReportInstitutionList,
  getFulfillmentOptions,
  getReportDetails,
  getReportInstitutions,
  setReportChildrenListFilters,
} from 'store/schoolObligations';
import { ToolbarContainerProps, ToolbarForm } from 'components/schoolObligation/ReportDetails/Toolbar/Toolbar.types';
import { toTreeOptions } from 'utils/api/toOptions';

export const ToolbarContainer: FC<ToolbarContainerProps> = ({ reportId, onDocumentsCreate, onCsvDownload }) => {
  const dispatch: AppDispatch = useDispatch();
  const { items: institutions } = useSelector(getReportInstitutions);
  const { items: fulfillment } = useSelector(getFulfillmentOptions);
  const report = useSelector(getReportDetails);

  const institutionOptions = toTreeOptions(institutions, 'id', 'name', 'units');
  const fulfillmentOptions = toTreeOptions(fulfillment, 'name', 'name', 'nodes');

  useEffect(() => {
    dispatch(fetchFulfillmentOptions());
    dispatch(fetchSchoolObligationsReportInstitutionList(reportId));
  }, [dispatch, reportId]);

  const initialValues: ToolbarForm = {
    units: [],
  };

  const handleFiltersUpdate = (filters) => dispatch(setReportChildrenListFilters(filters));

  return (
    <Toolbar
      birthYearsOptions={report.birthYears}
      reportStatus={report.status}
      unitOptions={institutionOptions}
      initialValues={initialValues}
      fulfillmentOptions={fulfillmentOptions}
      onFiltersUpdate={handleFiltersUpdate}
      onDocumentsCreate={onDocumentsCreate}
      onCsvDownload={onCsvDownload}
    />
  );
};

import classNames from 'classnames';
import { EditorAddContainer } from 'components/institution/Unit/Editor/EditorAddContainer';
import { useTranslator } from 'components/shared/hooks';
import React from 'react';
import { Page } from 'components/layout/panel/Page/Page';
import { Breadcrumb } from 'components/shared/navigation/Breadcrumbs/Breadcrumb';
import { ROUTE_INSTITUTION_UNIT_ADD } from 'config/routes';
import { RFC } from '@typings/extra-react-router';
import { useStyles } from 'views/Panel/Institutions/Unit/UnitEdit/UnitEdit.styles';

export const UnitAdd: RFC = (props) => {
  const trans = useTranslator();
  const classes = useStyles();

  return (
    <Page title={trans('INSTITUTIONS.UNIT.TITLE.ADD')}>
      <Breadcrumb label={trans('BREADCRUMB.UNITS.UNIT-ADD')} to={ROUTE_INSTITUTION_UNIT_ADD} />
      <div className={classNames('container-fluid', classes.container)}>
        <EditorAddContainer goBack={props.history.goBack} />
      </div>
    </Page>
  );
};

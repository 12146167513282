import { PageCollection } from '@typings/common';
import {
  AssignmentDetailsModel,
  DatabaseModel,
  DatabaseStatus,
  SchoolAddressModel,
  SchoolListFilters,
  SchoolModel,
  AssignmentModel,
  ChildModel,
  AssignmentStatus,
} from './schoolDistricts.types';

export interface SchoolDistrictsState {
  databases: {
    items: DatabaseModel[];
    loading: boolean;
    error: any;
  };
  schools: {
    items: SchoolModel[];
    filters: SchoolListFilters;
    loading: boolean;
    error: any;
  };
  addresses: {
    items: SchoolAddressModel[];
    loading: boolean;
    error: any;
  };
  address: {
    item: SchoolAddressModel;
    loading: boolean;
    error: any;
  };
  assignments: {
    items: AssignmentModel[];
    loading: boolean;
  };
  assignmentDetails: {
    data: AssignmentDetailsModel;
    loading: boolean;
  };
  assignmentRequest: {
    assignmentId: string;
    statusInfo: AssignmentRequestStatusInfo;
    loading: boolean;
    error: boolean;
  };
  children: {
    items: PageCollection<ChildModel>;
    loading: boolean;
    total: number;
  };
}

export interface AssignmentRequestStatusInfo {
  status: AssignmentStatus;
  progress: number;
}

export const schoolListInitialFilters: SchoolListFilters = {
  alert: null,
  search: null,
};

export const defaultState: SchoolDistrictsState = {
  databases: {
    items: [],
    loading: false,
    error: null,
  },
  schools: {
    items: [],
    filters: schoolListInitialFilters,
    loading: false,
    error: null,
  },
  addresses: {
    items: [],
    loading: false,
    error: null,
  },
  address: {
    item: null,
    loading: false,
    error: null,
  },
  assignments: {
    items: [],
    loading: false,
  },
  assignmentRequest: {
    assignmentId: null,
    statusInfo: {
      status: AssignmentStatus.NEW,
      progress: 0,
    },
    loading: false,
    error: false,
  },
  assignmentDetails: {
    data: null,
    loading: false,
  },
  children: {
    items: [],
    loading: false,
    total: 0,
  },
};

export const DatabaseStatusSortOrder = {
  [DatabaseStatus.PLANNED]: 0,
  [DatabaseStatus.ACTIVE]: 1,
  [DatabaseStatus.ARCHIVAL]: 2,
};

export const databasesListOrder = (a: DatabaseModel, b: DatabaseModel) => {
  return DatabaseStatusSortOrder[a.status] - DatabaseStatusSortOrder[b.status];
};

import { ChartTreeGenerator } from 'components/reports/SchoolStatDetails/BarChart/BarChart.types';
import {
  isRowGroup,
  StatDetailsRow,
  StatDetailsRowGroup,
  AggregationConfig,
  AggregationMethod,
} from 'store/reports/reports.types';

const calculateAggregatedValue = (aggregation: AggregationConfig, row, fieldName: string): number => {
  const values = row.find((el) => el.name === fieldName).data;

  switch (aggregation.method) {
    case AggregationMethod.SUM:
      return values.reduce((sum, child) => sum + Number(child.y), 0);
    case AggregationMethod.AVG:
      return values.reduce((sum, child) => sum + Number(child.y), 0) / values.length;
    case AggregationMethod.COUNT:
      return values.length;
    case AggregationMethod.NONE:
      return 0;
    case AggregationMethod.FIXED:
    default:
      return aggregation.value || 0;
  }
};

export const convertToChartTree: ChartTreeGenerator = (rows, column, allFieldsNames) => {
  const chartData: any = {
    data: [],
    children: [],
  };
  const aggregationField = column.aggregationField;
  const aggregationFieldName = allFieldsNames[allFieldsNames.length - 2];
  const aggregationFieldValue = allFieldsNames[allFieldsNames.length - 1];

  rows.forEach((row) => {
    if (isRowGroup(row)) {
      const tree = convertToChartTree(row.children, column, allFieldsNames);

      const currentFieldName = Object.keys(row.data).find((key) => allFieldsNames.includes(key));

      const children: StatDetailsRow | StatDetailsRowGroup = row.children;

      chartData.children.push({
        name: row.data[currentFieldName],
        data: children.map((child) => {
          if (child.data) {
            const childFieldName = Object.keys(child.data).find((key) => allFieldsNames.includes(key));
            return {
              x: child.data[childFieldName],
              y: tree.data.find((el) => el.x === child.data[childFieldName]).y,
            };
          } else {
            return {
              x: child[aggregationFieldName],
              y: child[aggregationFieldValue] || 0,
            };
          }
        }),
        ...(tree.children.length > 0 && { children: tree.children }),
      });

      const yValue = calculateAggregatedValue(
        row.data[aggregationField],
        chartData.children,
        row.data[currentFieldName],
      );

      chartData.data.push({
        x: row.data[currentFieldName],
        y: yValue,
        ...(row.data.blank && { isBlank: !!row.data.blank }),
      });
    } else {
      chartData.data.push({
        x: row[aggregationFieldName],
        y: row[aggregationFieldValue] || 0,
      });
    }
  });

  return chartData;
};

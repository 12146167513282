import { muiStyles } from 'utils/design';

export const useStyles = muiStyles((theme) => ({
  list: {
    margin: theme.spacing(2, 0, 3),
  },
  addButton: {
    marginLeft: theme.spacing(8),
  },
  error: {
    marginLeft: theme.spacing(8),
  },
}));

import { ColumnsConfig } from 'components/shared/data/DataGrid/DataGrid.types';
import { StudentRegisterEntryModel } from 'store/institutions';

export const browserColumns: ColumnsConfig<StudentRegisterEntryModel> = () => [
  {
    field: 'firstName',
    name: 'INSTITUTIONS.STUDENTS_REGISTER_DETAILS.COLUMNS.FIRST_NAME',
  },
  {
    field: 'lastName',
    name: 'INSTITUTIONS.STUDENTS_REGISTER_DETAILS.COLUMNS.LAST_NAME',
  },
  {
    field: 'identificationNumber',
    name: 'INSTITUTIONS.STUDENTS_REGISTER_DETAILS.COLUMNS.IDENTIFICATION_NUMBER',
  },
  {
    field: 'level',
    name: 'INSTITUTIONS.STUDENTS_REGISTER_DETAILS.COLUMNS.LEVEL',
  },
  {
    field: 'city',
    name: 'INSTITUTIONS.STUDENTS_REGISTER_DETAILS.COLUMNS.CITY',
  },
  {
    field: 'street',
    name: 'INSTITUTIONS.STUDENTS_REGISTER_DETAILS.COLUMNS.STREET',
  },
  {
    field: 'buildingNumber',
    name: 'INSTITUTIONS.STUDENTS_REGISTER_DETAILS.COLUMNS.BUILDING_NUMBER',
  },
  {
    field: 'flatNumber',
    name: 'INSTITUTIONS.STUDENTS_REGISTER_DETAILS.COLUMNS.FLAT_NUMBER',
  },
];

import React, { FC } from 'react';
import { Box, Button, DialogContent, DialogActions, Grid } from '@material-ui/core';
import { DatePicker, Form, InputText } from 'components/shared/form';
import { useTranslator } from 'components/shared/hooks';
import Access from 'components/shared/access/access';
import { ObligationActionForm } from 'components/childRegister/Editor/ObligationActions/ObligationActions.types';
import { EditorProps } from 'components/childRegister/Editor/ObligationActions/Editor/Editor.types';
import { OfficialPermissionLevel, OfficialResourceGroup } from 'store/officials';

export const Editor: FC<EditorProps> = (props) => {
  const trans = useTranslator();
  const { initialValues, onSubmit, onClose } = props;

  return (
    <Form<ObligationActionForm> initialValues={initialValues} onSubmit={onSubmit}>
      {() => {
        return (
          <>
            <DialogContent>
              <Box paddingBottom={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <DatePicker
                      name="date"
                      labelText={trans('CHILD_REGISTER.ADD_OBLIGATION_ACTIONS.DATE.LABEL')}
                      required
                      clearable={false}
                      disableKeyboard
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputText
                      name="time"
                      labelText={trans('CHILD_REGISTER.ADD_OBLIGATION_ACTIONS.TIME.LABEL')}
                      type="time"
                      required
                      shrink
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputText
                      name="actionTitle"
                      labelText={trans('CHILD_REGISTER.ADD_OBLIGATION_ACTIONS.TITLE.LABEL')}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputText
                      name="actionDescription"
                      labelText={trans('CHILD_REGISTER.ADD_OBLIGATION_ACTIONS.DESCRIPTION.LABEL')}
                      multiline
                    />
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="secondary" variant="outlined">
                {trans('COMMON.CLOSE')}
              </Button>
              <Access
                resourcesGroup={OfficialResourceGroup.childRegister}
                accessType={OfficialPermissionLevel.fullAccess}
              >
                <Button type="submit" color="secondary" variant="contained">
                  {trans('COMMON.SAVE')}
                </Button>
              </Access>
            </DialogActions>
          </>
        );
      }}
    </Form>
  );
};

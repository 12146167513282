import { Behaviours, FSAReducer } from '@typings/redux';
import { RESET_STATE } from 'store/store';
import { SearchState, defaultState } from 'store/search/search.state';
import { SearchActionType } from 'store/search/search.actionTypes';
import { fromApiFormat } from 'utils/api';

export const searchReducer: FSAReducer<SearchState, SearchActionType> = (state = defaultState, action) => {
  const behaviours: Behaviours<SearchState, SearchActionType> = {
    [RESET_STATE]: () => ({
      ...defaultState,
    }),
    [SearchActionType.FETCH_SEARCH_REQUEST]: () => ({
      list: [],
      loading: true,
      error: null,
    }),
    [SearchActionType.FETCH_SEARCH_SUCCESS]: ({ payload }) => ({
      list: fromApiFormat(payload.items),
      loading: false,
      error: null,
    }),
    [SearchActionType.FETCH_SEARCH_FAILURE]: () => ({
      list: [],
      loading: false,
      error: null,
    }),
  };
  return behaviours[action.type] ? behaviours[action.type](action) : state;
};

import React, { FC } from 'react';
import plLocale from 'date-fns/locale/pl';
import { Field, FieldProps } from 'formik';
import { get } from 'lodash';
import { DatePicker as MuiDatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { DatePickerProps } from './DatePicker.types';
import { useStyles } from './DatePicker.styles';
import { useTranslator } from 'components/shared/hooks';
import { format, isValid } from 'date-fns';
import { nullableEmptyString } from 'utils/string/provideStrings';
import classNames from 'classnames';
import moment from 'moment';
import { makeMaskFromFormat, maskedDateFormatter } from '@material-ui/pickers/_helpers/text-field-helper';

export const DatePickerFormat = 'dd.MM.yyyy';

export const DatePicker: FC<DatePickerProps> = ({
  name,
  labelText,
  required,
  onChange,
  outputFormat = '',
  className,
  clearable = true,
  disableKeyboard = false,
}) => {
  const classes = useStyles();
  const trans = useTranslator();

  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        const changeHandler = (date: any, value: string) => {
          const formattedDate = moment(date).format(outputFormat);

          if (isValid(date)) {
            form.setFieldValue(field.name, format(date, 'yyyy-MM-dd'));
            onChange && onChange(formattedDate, true);
          } else {
            form.setFieldValue(field.name, value);
            onChange && onChange(formattedDate, false);
          }
        };
        const changeDateHandler = (date: Date) => {
          const formattedDate = moment(date).format(outputFormat);
          form.setFieldValue(field.name, format(date, 'yyyy-MM-dd'));
          onChange && onChange(formattedDate, true);
        };

        const isError = !!(get(form.errors, field.name) && get(form.touched, field.name));
        const mask = makeMaskFromFormat(DatePickerFormat, '_');
        const formatter = maskedDateFormatter(mask, '_', /[^\d]+/gi);

        /*
          This changes are to keep wrong values when switching tabs - we need to use inputValue and make mask operation
          on code side. One issue it that we have different format for api request and UI. To change api format to display one
          I decided to use simple operations on string and array(split, reverse, join) instead of creating date and formating it to new format
        */
        const value = nullableEmptyString(field.value);
        const datePickerValue = value && value.split('-').reverse().join('.');

        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
            {disableKeyboard ? (
              <MuiDatePicker
                clearable={clearable}
                label={labelText}
                value={field.value}
                onChange={changeDateHandler}
                error={isError}
                format={DatePickerFormat}
                invalidDateMessage={get(form.errors, field.name)}
                placeholder={trans('COMMON.FORM.DATE_PICKER.PLACEHOLDER')}
                InputLabelProps={{
                  classes: {
                    error: classes.labelError,
                  },
                }}
                InputProps={{
                  classes: {
                    formControl: classes.formControl,
                  },
                  name: field.name,
                }}
                FormHelperTextProps={{
                  error: true,
                }}
                helperText={isError && <span>{get(form.errors, field.name)}</span>}
                cancelLabel={trans('COMMON.FORM.DATE_PICKER.CANCEL')}
                okLabel={trans('COMMON.FORM.DATE_PICKER.OK')}
                clearLabel={trans('COMMON.FORM.DATE_PICKER.CLEAR')}
                data-testid={`date-picker-${field.name}`}
                className={classNames({ hasValidationError: isError }, classes.root, className)}
                required={required}
              />
            ) : (
              <KeyboardDatePicker
                clearable={clearable}
                label={labelText}
                value={null}
                inputValue={value && formatter(datePickerValue)}
                onChange={changeHandler}
                error={isError}
                format={DatePickerFormat}
                invalidDateMessage={get(form.errors, field.name)}
                placeholder={trans('COMMON.FORM.DATE_PICKER.PLACEHOLDER')}
                InputLabelProps={{
                  classes: {
                    error: classes.labelError,
                  },
                }}
                InputProps={{
                  classes: {
                    formControl: classes.formControl,
                  },
                  name: field.name,
                }}
                FormHelperTextProps={{
                  error: true,
                }}
                helperText={<span>{isError && get(form.errors, field.name)}</span>}
                cancelLabel={trans('COMMON.FORM.DATE_PICKER.CANCEL')}
                okLabel={trans('COMMON.FORM.DATE_PICKER.OK')}
                clearLabel={trans('COMMON.FORM.DATE_PICKER.CLEAR')}
                data-testid={`date-picker-${field.name}`}
                className={classNames({ hasValidationError: isError }, classes.root, className)}
                required={required}
              />
            )}
          </MuiPickersUtilsProvider>
        );
      }}
    </Field>
  );
};

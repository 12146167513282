import React, { FC, useEffect, useState } from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon';
import { useSelector } from 'react-redux';

import { getDistrictsSchoolFiltersSelector, SchoolModel } from 'store/schoolDistricts';
import { SchoolsListPickerProps } from './SchoolsListPicker.types';

import { useTranslator } from 'components/shared/hooks/useTranslator/useTranslator';
import { useStyles } from './SchoolsListPicker.styles';

export const SchoolsListPicker: FC<SchoolsListPickerProps> = (props) => {
  const classes = useStyles();
  const trans = useTranslator();
  const [selectedId, setSelectedId] = useState(null);
  const filters = useSelector(getDistrictsSchoolFiltersSelector);

  const hasWarning = (item: SchoolModel) => item.alerts && item.alerts.length > 0;

  const handleItemClick = (item: SchoolModel) => {
    setSelectedId(item.id);
    props.onSelectSchool(item);
  };

  useEffect(() => {
    setSelectedId(null);
  }, [filters]);

  return props.items.length <= 0 ? (
    <p>{trans('SCHOOL_DISTRICTS.DISTRICTS-LIST.SCHOOL-LIST.EMPTY')}</p>
  ) : (
    <List className={classes.list}>
      {props.items.map((item: SchoolModel, index: number) => (
        <ListItem
          onClick={() => handleItemClick(item)}
          button
          key={item.id}
          selected={selectedId === item.id}
          data-testid={item.name}
        >
          <ListItemText primary={item.name} className={hasWarning(item) ? classes.warning : ''} />
          {hasWarning(item) && (
            <ListItemSecondaryAction>
              <Tooltip
                title={trans('SCHOOL_DISTRICTS.DISTRICTS-LIST.SCHOOL-LIST.CONFLICT')}
                placement="top"
                key={index}
              >
                <span>
                  <FontAwesomeIcon name="exclamation-triangle" />
                </span>
              </Tooltip>
            </ListItemSecondaryAction>
          )}
        </ListItem>
      ))}
    </List>
  );
};

import { ToolbarProps } from 'components/officials/Browser/Browser.types';
import { useStyles } from 'components/officials/Browser/Toolbar/Toolbar.styles';
import { Button } from 'components/shared/buttons/Button/Button';
import { useTranslator } from 'components/shared/hooks';
import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon';
import React, { FC } from 'react';

export const Toolbar: FC<ToolbarProps> = ({ onOfficialAdd }) => {
  const trans = useTranslator();
  const classes = useStyles();

  return (
    <div className={classes.toolbar}>
      <Button variant="contained" color="primary" onClick={() => onOfficialAdd()} tabIndex={0}>
        <FontAwesomeIcon name={'plus-circle'} className={'mr-2'} />
        {trans('OFFICIALS.BROWSER.ADD_USER')}
      </Button>
    </div>
  );
};

import { ADDRESS_RULE_CONFLICT_ALERT } from 'components/schoolDistricts/DistrictsBrowser/DistrictsBrowser.types';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { getDistrictsSchoolFiltersSelector } from 'store/schoolDistricts';

import { SchoolsListProps } from './SchoolsList.types';
import { SchoolsListForm } from './SchoolsListForm/SchoolsListForm';
import { SchoolsListPicker } from './SchoolsListPicker/SchoolsListPicker';

import { useTranslator } from 'components/shared/hooks/useTranslator/useTranslator';

export const SchoolsList: FC<SchoolsListProps> = (props) => {
  const trans = useTranslator();
  const filters = useSelector(getDistrictsSchoolFiltersSelector);

  const getFilteredSchoolList = () => {
    return props.list
      .filter((item) => {
        return filters.search ? item.name.toLocaleLowerCase().includes(filters.search.toLocaleLowerCase()) : true;
      })
      .filter((item) => {
        return filters.alert ? item.alerts.some((alert) => alert.name === ADDRESS_RULE_CONFLICT_ALERT) : true;
      });
  };

  return (
    <>
      <h3>{trans('SCHOOL_DISTRICTS.DISTRICTS-LIST.SCHOOL-LIST.LABEL')}</h3>
      <SchoolsListForm />
      {props.loading ? (
        <p>{trans('COMMON.LOADING_DATA')}</p>
      ) : (
        <SchoolsListPicker items={getFilteredSchoolList()} onSelectSchool={props.onSelectSchool} />
      )}
    </>
  );
};

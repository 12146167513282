import { SystemFormValues } from 'components/settings/System/System.types';
import { InputText } from 'components/shared/form';
import { useTranslator } from 'components/shared/hooks';
import { FormikProps } from 'formik';
import React from 'react';
import { DayMonthPicker } from 'components/shared/form/DayMonthPicker/DayMonthPicker';
import { Heading } from 'components/shared/typography/Heading/Heading';

interface GeneralProps {
  form: FormikProps<SystemFormValues>;
}

export const General = ({ form }: GeneralProps) => {
  const trans = useTranslator();

  return (
    <div className="container-fluid">
      <Heading type="h6" className="font-weight-normal mb-2">
        {trans('SETTINGS.SYSTEM.FIELDS.DEPARTMENT_SETTINGS')}
      </Heading>
      <div className="row ml-0">
        <div className="col-3">
          <InputText
            labelTooltipText={trans('SETTINGS.SYSTEM.TOOLTIPS.DEPARTMENT_NAME')}
            name="departmentName"
            labelText={trans('SETTINGS.SYSTEM.FIELDS.DEPARTMENT_NAME')}
            required
          />
        </div>
      </div>
      <div className="row ml-0">
        <div className="col-3">
          <DayMonthPicker
            initialMonth={form.initialValues.schoolyearThreshold.month}
            labelTooltipText={trans('SETTINGS.SYSTEM.TOOLTIPS.SCHOOL_YEAR_TRESHOLD')}
            name="schoolyearThreshold"
            labelText={trans('SETTINGS.SYSTEM.FIELDS.SCHOOL_YEAR_TRESHOLD')}
            required
          />
        </div>
      </div>
    </div>
  );
};

import { range } from 'lodash';
import { compareWithThreshold, ThresholdComparison } from 'utils/date/compareWithThreshold';
import { getCurrentDate } from 'utils/date/currentDate';
import { getSchoolYearStart } from 'utils/date/getSchoolYearStart';
import { format } from 'utils/date/schoolYears';

const SCHOOL_START_AGE = 7;
const SCHOOL_END_AGE = 18;

const shouldIncludeIncomingYear = (birthDate: string, threshold: string, currentSchoolYearStart: number) => {
  const birthYear = new Date(birthDate).getFullYear();
  const comparison = compareWithThreshold(new Date(birthDate), threshold);
  return comparison !== ThresholdComparison.BEFORE || birthYear + SCHOOL_END_AGE > currentSchoolYearStart;
};

export const getSchoolYears = (birthDate: string, schoolyearThreshold: string): string[] => {
  const birthYear = new Date(birthDate).getFullYear();
  const currentSchoolYearStart = getSchoolYearStart(getCurrentDate(), schoolyearThreshold);

  const rangeEnd = Math.min(birthYear + SCHOOL_END_AGE, currentSchoolYearStart);
  const rangeStart = birthYear + SCHOOL_START_AGE;

  // we should have one year less if someone ended education obligation before the threshold
  const includeNextYear = shouldIncludeIncomingYear(birthDate, schoolyearThreshold, currentSchoolYearStart);
  const thresholdFix = includeNextYear ? 1 : 0;

  if (rangeStart <= currentSchoolYearStart) {
    return format(range(rangeStart, rangeEnd + thresholdFix));
  } else {
    return [];
  }
};

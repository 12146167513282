import { Column, ColumnsConfig } from 'components/shared/data/DataGrid/DataGrid.types';
import { CombinedAnswer } from 'store/polls';

interface ColumnsParams {
  showValues: boolean;
  printMode: boolean;
}

export const combinedAnswerColumns: ColumnsConfig<CombinedAnswer, ColumnsParams> = (params) => {
  const columns: Column<CombinedAnswer>[] = [
    {
      field: 'option',
      name: 'POLLS.RESULTS.COMBINED.COLUMNS.OPTION',
      columnParams: {
        width: params.printMode ? 500 : undefined,
        cellStyle: params.printMode ? { 'white-space': 'normal' } : undefined,
      },
    },
  ];

  if (params.showValues) {
    columns.push({
      field: 'count',
      name: 'POLLS.RESULTS.COMBINED.COLUMNS.COUNT',
      columnParams: {
        width: 100,
      },
    });

    columns.push({
      field: 'percentage',
      name: 'POLLS.RESULTS.COMBINED.COLUMNS.PERCENTAGE',
      format: (data) => `${data.percentage}%`,
      columnParams: {
        width: 100,
      },
    });
  }

  return columns;
};

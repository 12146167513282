import { AppDispatch } from '@typings/redux';
import { Editor } from 'components/polls/Editor/Editor';
import { useFormatter, useNotification } from 'components/shared/hooks';
import { ROUTE_POLLS_LIST } from 'config/routes';
import { push } from 'connected-react-router';
import { FormikHelpers } from 'formik';
import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createPoll, PollForm } from 'store/polls';

export const AddContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { formatApiErrors } = useFormatter();
  const { success, error } = useNotification();
  const [isSubmitted, setSubmitted] = useState(false);

  const initialPoll: PollForm = {
    name: '',
    description: '',
    questions: [],
  };

  const onSave = async (data: PollForm, formik: FormikHelpers<PollForm>) => {
    const result = await dispatch(createPoll(data));

    if (result.error) {
      if (result.payload.response.errors) {
        error('POLLS.EDITOR.MESSAGE.POLL_CREATE_ERROR');
        formik.setErrors(formatApiErrors(result.payload.response.errors));
      }
      formik.setSubmitting(false);
    } else {
      success('POLLS.EDITOR.MESSAGE.POLL_CREATE_SUCCESS');
      setSubmitted(true);
      dispatch(push(ROUTE_POLLS_LIST));
    }
  };

  const onCancel = () => {
    dispatch(push(ROUTE_POLLS_LIST));
  };

  return <Editor initial={initialPoll} submitted={isSubmitted} onSave={onSave} onCancel={onCancel} />;
};

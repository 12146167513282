import React, { FC, useState, useEffect } from 'react';
import { SettingsContentProps } from 'components/shared/import/settings/settings.types';
import { useStyles } from 'components/shared/import/settings/SettingsContent.styles';
import { useTranslator } from 'components/shared/hooks';
import { SettingsRow } from 'components/childRegister/Import/steps/Settings/SettingsRow';
import { Autocomplete } from 'components/shared/form/Autocomplete/Autocomplete';

export const SettingsContent: FC<SettingsContentProps> = ({
  columnsConfig,
  columns,
  isLoading,
  currentUsedColumns,
}) => {
  const classes = useStyles();
  const trans = useTranslator();
  const [availableOptions, setAvailableOptions] = useState(columns);

  const scrollToList = (ref: any) => {
    setTimeout(() => {
      const menuRef = ref && ref.current && ref.current.select.menuListRef;

      menuRef &&
        menuRef.scrollIntoView &&
        menuRef.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }, 0);
  };

  useEffect(() => {
    const filteredOptions = columns.filter((column) => !currentUsedColumns.includes(column.value));
    setAvailableOptions(filteredOptions);
  }, [columns, currentUsedColumns]);

  const renderColumnField = (column) => (
    <SettingsRow label={column.label} key={column.field} required={column.required}>
      <Autocomplete
        name={`map.${column.field}`}
        options={columns}
        displayedOptions={availableOptions}
        labelText=""
        placeholder={trans('COMMON.AUTOCOMPLETE.SELECT')}
        required={column.required}
        isLoading={isLoading}
        onMenuOpen={scrollToList}
      />
    </SettingsRow>
  );

  return (
    <>
      {columnsConfig.map((group, index) => (
        <div className={classes.group} key={index}>
          <div className={classes.groupHeader}>{trans(group.title)}</div>
          {group.fields.map((column) => renderColumnField(column))}
        </div>
      ))}
    </>
  );
};

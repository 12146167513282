import { muiStyles } from 'utils/design';

export const useStyles = muiStyles(() => ({
  container: {
    minHeight: 400,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'flex-start',
  },
  grid: {
    flexGrow: 1,
  },
}));

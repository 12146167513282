import { muiStyles } from 'utils/design';

export const useStyles = muiStyles((theme) => ({
  wrapper: {
    position: 'absolute',
    top: '13px',
    left: 0,
    width: '100%',
    padding: '0 13px',
    margin: '-14px 0',
    height: '46px',
    display: 'flex',
    alignItems: 'center',
  },
  description: {},
  textButton: {
    marginRight: theme.spacing(1),
    '&[class*="MuiButton-sizeSmall"]': {
      padding: theme.spacing(0.625, 1.3, 0.5),
      lineHeight: '1.25rem',
    },
  },
  gridIcon: {
    fontSize: '0.8rem',
  },
  icon: {
    display: 'inline-flex',
    width: 42,
    height: 44,
    fontSize: '0.9rem',
    color: theme.palette.text.secondary,
    padding: 12,
    verticalAlign: 'middle',
  },
  tooltip: {
    '& ul': {
      paddingLeft: 20,
      marginTop: theme.spacing(1),
      '& li': {
        marginTop: theme.spacing(1),
      },
    },
  },
}));

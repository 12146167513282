import { SettingsFormValues } from 'components/settings/Settings.types';

export const mapFormDepartmentValueToPayload = (values: SettingsFormValues) => {
  return {
    departmentName: values.departmentName,
    schoolyearThreshold: values.schoolyearThreshold.month + values.schoolyearThreshold.day,
  };
};

export const mapFormPollValueToPayload = (values: SettingsFormValues) => {
  return {
    topic: values.topic,
    messageContent: values.messageContent,
    messageSignature: values.messageSignature,
  };
};

export const mapFormUserValueToPayload = (values: SettingsFormValues) => {
  return {
    dcsLogin: values.dcsLogin,
  };
};

import {
  OfficialForm,
  OfficialPostModel,
  OfficialPermissions,
  OfficialAccountType,
} from 'store/officials/officials.types';
import { ApplicantPostModel } from 'store/applicants/applicants.types';

export const mapFormValueToPayload = (values: OfficialForm): OfficialPostModel => {
  return {
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    role: values.role,
    permissions:
      values.role === OfficialAccountType.OFFICIAL
        ? (Object.entries(values.permissions).map((item) => ({
            resourcesGroup: item[0] as string,
            accessType: item[1] as string,
          })) as OfficialPermissions[])
        : [],
  };
};

export const mapFormApplicantValueToPayload = (values: OfficialForm): ApplicantPostModel => {
  return {
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    role: values.role,
    units: values.units,
  };
};

export const mapFormPayloadToValue = (values: OfficialPostModel): OfficialForm => {
  return {
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    role: values.role,
    permissions:
      values.role === OfficialAccountType.OFFICIAL
        ? values.permissions.reduce((acc, cur) => {
            if (!cur.resourcesGroup) return acc;
            acc[cur.resourcesGroup] = cur.accessType;
            return acc;
          }, {})
        : {},
    units: [],
  };
};

export const mapFormApplicantPayloadToValue = (values: ApplicantPostModel): OfficialForm => {
  return {
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    role: values.role,
    permissions: {},
    units: values.units,
  };
};

import { isYear } from '@typings/common';
import {
  ChildrenYearsGroup,
  YearRegisterColumnsParams as ColumnsParams,
  YearRegisterRow,
} from 'components/schoolDistricts/AssignmentPreview/YearsRegister/YearsRegister.types';
import { Column, ColumnsConfig } from 'components/shared/data/DataGrid/DataGrid.types';

export const yearsRegisterColumns: ColumnsConfig<YearRegisterRow, ColumnsParams> = (params) => {
  const isMarked = (column) => {
    return params.markedColumns.includes(column);
  };

  const list: Column<YearRegisterRow>[] = [];

  if (params.columns.includes(ChildrenYearsGroup.SCHOOL)) {
    list.push({
      field: 'id',
      name: 'SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.YEARS_REGISTER.YEARS_GROUP.SCHOOL',
      columnParams: { minWidth: 80, width: 80, maxWidth: 140 },
      format: (data) => {
        return params.columns.reduce((count, column) => {
          return !isMarked(column) && isYear(column) ? count + (data.years[column] || 0) : count;
        }, 0);
      },
    });
  }

  if (params.columns.includes(ChildrenYearsGroup.YOUNG)) {
    list.push({
      field: 'id',
      name: 'SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.YEARS_REGISTER.YEARS_GROUP.YOUNG',
      columnParams: { minWidth: 80, width: 80, maxWidth: 140 },
      headerClass: params.markedCellClass,
      cellClass: params.markedCellClass,
      format: (data) => {
        return params.columns.reduce((count, column) => {
          return isMarked(column) ? count + (data.years[column] || 0) : count;
        }, 0);
      },
    });
  }

  const firstYoungYear = params.markedColumns[0];
  const yearColumns = params.columns.filter((item) => !isNaN(Number(item))).sort();

  yearColumns.forEach((year) => {
    list.push({
      field: `years.${year.toString()}`,
      name: () => `${year.toString()}${year <= firstYoungYear ? ' *' : ''}`,
      columnParams: { minWidth: 60, width: 60 },
      headerClass: isMarked(year) ? params.markedCellClass : '',
      cellClass: isMarked(year) ? params.markedCellClass : '',
      format: (data) => {
        return data.years[year] || 0;
      },
    });
  });

  return list;
};

import { ForecastConfig, ForecastValues } from 'components/reports/SubventionForecast/SubventionForecast.types';
import {
  ForecastAction,
  ManipulatorConfig,
} from 'components/shared/data/DataGrid/renderers/cell/ForecastModificator/ForecastManipulator.types';
import { SubventionFactors } from 'store/reports/reports.types';

const FORECAST_MULTIPLIER = 1.0325;

export const configEquals = <A extends ManipulatorConfig, B extends ManipulatorConfig>(a: A, b: B) => {
  return a.action === b.action && a.actionValue === b.actionValue;
};

export const calculateForecast = (config: ManipulatorConfig, factor: number) => {
  if (config.action === ForecastAction.COPY) {
    return factor;
  }
  if (config.action === ForecastAction.FORECAST) {
    return factor * FORECAST_MULTIPLIER;
  }
  return factor * (1 + Number(config.actionValue) / 100);
};

const formatValue = (value: number): string => {
  return (Math.round(value * Math.pow(10, 10)) / Math.pow(10, 10)).toString();
};

export const getSubventionForecast = (factors: SubventionFactors, config: ForecastConfig): ForecastValues => {
  const { diFactor, financialStandardA, subventionA, subventionB, subventionC } = factors;

  const nextFinancialStandardA = calculateForecast(config.financialStandardA, financialStandardA);
  const nextDiFactor = calculateForecast(config.diFactor, diFactor);

  const multiplierA = calculateForecast(config.subventionA, subventionA.studentsTimesWeight);
  const valueA = multiplierA * nextDiFactor * nextFinancialStandardA;

  const multiplierB = calculateForecast(config.subventionB, subventionB.studentsTimesWeight);
  const valueB = multiplierB * nextDiFactor * nextFinancialStandardA;

  const multiplierC = calculateForecast(config.subventionC, subventionC.studentsTimesWeight);
  const valueC = multiplierC * nextDiFactor * nextFinancialStandardA;

  return {
    financialStandardA: formatValue(nextFinancialStandardA),
    diFactor: formatValue(nextDiFactor),
    'subventionA.studentsTimesWeight': formatValue(multiplierA),
    'subventionA.value': formatValue(valueA),
    'subventionB.studentsTimesWeight': formatValue(multiplierB),
    'subventionB.value': formatValue(valueB),
    'subventionC.studentsTimesWeight': formatValue(multiplierC),
    'subventionC.value': formatValue(valueC),
    subvention: formatValue(valueA + valueB + valueC),
  };
};

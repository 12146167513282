import React, { FC } from 'react';
import { Dialog, DialogContent, DialogTitle, DialogActions } from '@material-ui/core';
import { AddressHistoryProps } from 'components/childRegister/Editor/AddressHistory/AddressHistory.types';
import { DataGrid } from 'components/shared/data/DataGrid/DataGrid';
import classNames from 'classnames';
import { useStyles } from 'components/childRegister/Editor/AddressHistory/AddressHistory.styles';
import { addressHistoryColumns } from 'components/childRegister/Editor/AddressHistory/columns.config';
import { useTranslator } from 'components/shared/hooks';
import { Button } from 'components/shared/buttons/Button/Button';
import { AddressHistoryModel } from 'store/childRegister';

export const AddressHistory: FC<AddressHistoryProps> = ({ open, onClose, data, loading }) => {
  const trans = useTranslator();
  const classes = useStyles();

  return (
    <Dialog fullWidth maxWidth={'lg'} open={open} onClose={onClose} data-testid="addressHistoryModal">
      <DialogTitle>{trans('CHILD_REGISTER.ADDRESS_HISTORY.MODAL.TITLE')}</DialogTitle>
      <DialogContent>
        <div className={classNames('text-center', classes.container)}>
          <DataGrid<AddressHistoryModel>
            className={classes.grid}
            data={data}
            isLoading={loading}
            columns={addressHistoryColumns()}
            loadingMessage={'CHILD_REGISTER.ADDRESS_HISTORY.MODAL.RESULTS_LOADING'}
            noResultsMessage={'CHILD_REGISTER.ADDRESS_HISTORY.MODAL.NOTHING_FOUND'}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="contained">
          {trans('COMMON.BACK')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

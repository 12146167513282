import { useScrollToError } from 'components/shared/form/Form/useScrollToError';
import React, { useRef } from 'react';
import { Formik } from 'formik';
import { FormProps } from './Form.types';

export const Form = <T extends {}>({
  className,
  children,
  initialValues,
  enableReinitialize = false,
  onSubmit,
  innerRef,
  autoComplete,
}: FormProps<T>) => {
  const formRef = useRef<HTMLFormElement>(null);
  const requestScroll = useScrollToError(formRef);

  const setErrorsWrapper = (errorsSetter) => {
    return (errors) => {
      requestScroll();
      errorsSetter(errors);
    };
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={enableReinitialize}
      onSubmit={(values, form) => {
        onSubmit(values, { ...form, setErrors: setErrorsWrapper(form.setErrors) });
      }}
      validateOnBlur={false}
      validateOnChange={false}
      innerRef={innerRef}
    >
      {(props) => (
        <form className={className} onSubmit={props.handleSubmit} ref={formRef} autoComplete={autoComplete}>
          {children(props)}
        </form>
      )}
    </Formik>
  );
};

export const QuietForm = <T extends {}>({ children, initialValues, onSubmit }: FormProps<T>) => {
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {(props) => children(props)}
    </Formik>
  );
};

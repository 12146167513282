import React, { FC } from 'react';
import { AccessProps } from 'components/shared/access/access.type';
import { useCheckAccess } from 'components/shared/hooks/useCheckAccess/useCheckAccess';

const Access: FC<AccessProps> = ({ resourcesGroup, accessType, children }) => {
  let isAccess = false;
  if (Array.isArray(resourcesGroup)) {
    isAccess = resourcesGroup.some((item) => useCheckAccess(item, accessType));
  } else {
    isAccess = useCheckAccess(resourcesGroup, accessType);
  }

  if (!isAccess) return null;
  return <>{children}</>;
};

export default Access;

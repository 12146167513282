import React, { FC, useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getImportToken,
  postStudentRegisterImport,
  deleteStudentRegisterImport,
  getImportFileName,
} from 'store/institutions';
import { FileImport } from './FileImport';
import { StepProps } from 'components/shared/dialogs/StepModal/StepModal.types';
import { __RouterContext } from 'react-router';

export const FileImportContainer: FC<StepProps> = (props) => {
  const dispatch: (...params: any) => Promise<any> = useDispatch();
  const importToken = useSelector(getImportToken);
  const router = useContext(__RouterContext);
  const id = (router.match.params as { id: string }).id;
  const fileName = useSelector(getImportFileName);

  const deleteStudentRegisterImportCallback = useCallback(() => {
    return dispatch(deleteStudentRegisterImport(importToken, id));
  }, [dispatch, importToken, id]);

  const postStudentRegisterImportCallback = useCallback(
    (result) => {
      return dispatch(postStudentRegisterImport(result, id));
    },
    [id, dispatch],
  );

  return (
    <FileImport
      {...props}
      deleteStudentRegisterImport={deleteStudentRegisterImportCallback}
      postStudentRegisterImport={postStudentRegisterImportCallback}
      token={importToken}
      fileName={fileName}
    />
  );
};

import { AppBar, IconButton, Toolbar, Typography, Button } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { AppDispatch } from '@typings/redux';
import { useTranslator } from 'components/shared/hooks';
import { useEnvironment } from 'components/shared/hooks/useEnvironment/useEnvironment';
import { ROUTE_AUTH_SSO } from 'config/routes';
import { push } from 'connected-react-router';
import React from 'react';
import { useDispatch } from 'react-redux';
import { appLogout, trySessionDestroy } from 'store/auth/auth.action';
import { TitleBarProps } from 'components/layout/panel/TitleBar/TitleBar.types';
import { useStyles } from 'components/layout/panel/TitleBar/TitleBar.styles';
import { makePath } from 'utils/navigation/makePath';
import { SsoAction } from 'views/Auth/Sso/Sso.view';

export const TitleBar: React.FC<TitleBarProps> = (props) => {
  const classes = useStyles();
  const trans = useTranslator();
  const dispatch = useDispatch<AppDispatch>();
  const { origin } = useEnvironment();

  const switchSidebarStatus = () => {
    props.setSidebarStatus(!props.sidebarOpening);
  };

  const logout = () => {
    setTimeout(async () => {
      await dispatch(trySessionDestroy(origin));
      dispatch(appLogout());
      dispatch(push(makePath(ROUTE_AUTH_SSO, { action: SsoAction.LOGOUT })));
    });
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="Menu" onClick={switchSidebarStatus} id="sidebar-button">
          <MenuIcon style={{ fontSize: '1.5rem' }} />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          {trans('TOPBAR.TITLE')}
        </Typography>
        <Button color="inherit" onClick={() => logout()}>
          {trans('TOPBAR.LOGOUT')}
        </Button>
      </Toolbar>
    </AppBar>
  );
};

import { muiStyles } from 'utils/design';

export const useStyles = muiStyles((theme) => {
  return {
    main: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      width: 0,
    },
    toolbar: theme.mixins.toolbar,
  };
});

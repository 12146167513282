import React, { forwardRef, useState, useRef } from 'react';
import { Box, Button, MenuItem, TextField, Theme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import reduce from 'lodash/reduce';
import { useStyles } from 'components/search/Toolbar/Toolbar.styles';
import { ToolbarProps, Filters } from 'components/search/Toolbar/Toolbar.types';
import { Form } from 'components/shared/form';
import { FocusTrap } from 'components/shared/form/FocusTrap/FocusTrap';
import { InputText } from 'components/shared/form/InputText/InputText';
import { useTranslator } from 'components/shared/hooks/useTranslator/useTranslator';
import { filtersThemeFactory } from 'styles/themes/filtersTheme';
import { SearchParams } from 'store/search/search.types';
import { combineValidators, maxLength, required } from 'utils/formValidators';

const ToolbarComponent = (props: ToolbarProps, ref) => {
  const classes = useStyles();
  const trans = useTranslator();
  const form = useRef(null);
  const [selectedFilters, setSelectedFilter] = useState<Filters>(Filters.SEARCH_BY_IDENTIFICATION_NUMBER);

  const valuesChanged = (values: SearchParams) => {
    // filter empty values
    const compactedValues = reduce(
      values,
      (acc, curr, key) => {
        if (curr) {
          acc[key] = curr;
        }
        return acc;
      },
      {},
    );
    props.onSubmit(compactedValues);
  };

  return (
    <section ref={ref}>
      <ThemeProvider theme={(parentTheme: Theme) => filtersThemeFactory(parentTheme)}>
        <Box display="flex">
          <Box marginRight={2}>
            <TextField
              label={trans('SEARCH.SELECT_FILTERS.LABEL')}
              select
              value={selectedFilters}
              onChange={(event) => {
                form.current.resetForm();
                setSelectedFilter(event.target.value as Filters);
              }}
              className={classes.selectFilter}
            >
              <MenuItem value={Filters.SEARCH_BY_IDENTIFICATION_NUMBER}>
                {trans('SEARCH.SELECT_FILTERS.VALUES.SEARCH_BY_IDENTIFICATION_NUMBER')}
              </MenuItem>
              <MenuItem value={Filters.SEARCH_BY_NAME}>{trans('SEARCH.SELECT_FILTERS.VALUES.SEARCH_BY_NAME')}</MenuItem>
            </TextField>
          </Box>
          <Form<SearchParams>
            initialValues={{}}
            onSubmit={valuesChanged}
            className={classes.form}
            innerRef={form}
            autoComplete="off"
          >
            {() => (
              <div className={classes.filters}>
                {selectedFilters === Filters.SEARCH_BY_IDENTIFICATION_NUMBER ? (
                  <InputText
                    name="identifier"
                    labelText={trans('SEARCH.FILTERS.IDENTIFICATION_NUMBER.LABEL')}
                    validate={combineValidators(
                      required(trans('COMMON.FORM.REQUIRED')),
                      maxLength(trans('COMMON.FORM.TOO_LONG', { length: 255 }), 255),
                    )}
                    resettable
                    showErrorAfterTouched={false}
                  />
                ) : (
                  <>
                    <InputText
                      name="lastName"
                      labelText={trans('SEARCH.FILTERS.LAST_NAME.LABEL')}
                      validate={combineValidators(
                        required(trans('COMMON.FORM.REQUIRED')),
                        maxLength(trans('COMMON.FORM.TOO_LONG', { length: 255 }), 255),
                      )}
                      resettable
                      showErrorAfterTouched={false}
                    />
                    <InputText
                      name="firstName"
                      labelText={trans('SEARCH.FILTERS.FIRST_NAME.LABEL')}
                      validate={combineValidators(maxLength(trans('COMMON.FORM.TOO_LONG', { length: 255 }), 255))}
                      resettable
                    />
                  </>
                )}
                <Button type="submit" variant="contained" color="primary">
                  {trans('SEARCH.FILTERS.SUBMIT')}
                </Button>
                <FocusTrap handler={() => props.onGridFocus()} />
              </div>
            )}
          </Form>
        </Box>
      </ThemeProvider>
    </section>
  );
};

ToolbarComponent.displayName = 'Toolbar';
export const Toolbar = forwardRef(ToolbarComponent);

import { YearsRegister } from 'components/schoolDistricts/AssignmentPreview/YearsRegister/YearsRegister';
import { YearRegisterRow } from 'components/schoolDistricts/AssignmentPreview/YearsRegister/YearsRegister.types';
import React, { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { AssignmentAlert, getAssignmentDetailsSelector } from 'store/schoolDistricts';
import { birthYearsGenerator } from 'utils/date/birthYearsGenerator';
import { pick } from 'lodash';
import { useDepartmentSettings } from 'components/shared/hooks/useInstanceConfig/useDepartmentSettings';
import { compareWithThreshold } from 'utils/date/compareWithThreshold';

export const YearsRegisterContainer: FC = () => {
  const { schoolyearThreshold } = useDepartmentSettings();
  const assignment = useSelector(getAssignmentDetailsSelector);

  const createdBeforeNewSchoolYear =
    compareWithThreshold(new Date(assignment.data.createdAt), schoolyearThreshold) === -1;

  const youngBirthYears = createdBeforeNewSchoolYear ? birthYearsGenerator(0, 7) : birthYearsGenerator(0, 6);
  const schoolBirthYears = createdBeforeNewSchoolYear ? birthYearsGenerator(8, 19) : birthYearsGenerator(7, 18);

  const getSchoolData = useCallback(
    (school): YearRegisterRow => {
      return {
        id: school.id,
        name: school.name,
        isAlert: false,
        years: {
          ...pick(school.years, youngBirthYears),
          ...pick(school.years, schoolBirthYears),
        },
        addressRules: school.addressRules || [],
      };
    },
    [schoolBirthYears, youngBirthYears],
  );

  const getAlertsData = useCallback(
    (alert: AssignmentAlert): YearRegisterRow => {
      return {
        id: alert.name,
        name: alert.name,
        isAlert: true,
        years: {
          ...pick(alert.years, youngBirthYears),
          ...pick(alert.years, schoolBirthYears),
        },
      };
    },
    [schoolBirthYears, youngBirthYears],
  );

  const assignmentMatrix = useCallback((): YearRegisterRow[] => {
    return [...assignment.data.schools.map(getSchoolData), ...assignment.data.alerts.map(getAlertsData)];
  }, [assignment.data.alerts, assignment.data.schools, getAlertsData, getSchoolData]);

  return (
    <YearsRegister data={assignmentMatrix()} youngBirthYears={youngBirthYears} schoolBirthYears={schoolBirthYears} />
  );
};

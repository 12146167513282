import { AppDispatch } from '@typings/redux';
import { EducationPath } from 'components/childRegister/Editor/EducationPath/EducationPath';
import React, { ComponentType, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { educationPathSelector, isEducationPathLoadingSelector } from 'store/childRegister';
import { clearEducationPath, fetchEducationPath } from 'store/childRegister/childRegister.actions';
import { connect } from 'formik';
import { EducationPathContainerProps } from './EducationPath.types';

export const EducationPathContainer: ComponentType<EducationPathContainerProps> = connect(({ formik }) => {
  const dispatch = useDispatch<AppDispatch>();
  const list = useSelector(educationPathSelector);
  const loading = useSelector(isEducationPathLoadingSelector);

  useEffect(() => {
    if (formik.values.identificationNumber && formik.values.identificationNumberType) {
      dispatch(clearEducationPath());
      dispatch(fetchEducationPath(formik.values.identificationNumber, formik.values.identificationNumberType));
    }
  }, [dispatch, formik]);

  return <EducationPath show={!!formik.values.identificationNumber} list={list} loading={loading} />;
});

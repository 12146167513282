import { setTokenAction } from '@tshio/redux-api-auth-middleware';
import { AppDispatch } from '@typings/redux';
import { FullScreenLoader } from 'components/shared/loaders/FullScreenLoader/FullScreenLoader';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isAccessTokenSet } from 'store/auth';
import { getStoredToken } from 'utils/auth/tokenStore';

export const SessionRestore: FC = ({ children }) => {
  const [isResolved, setResolved] = useState(false);
  const isAuthorized = useSelector(isAccessTokenSet);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (getStoredToken() && !isResolved) {
      dispatch(setTokenAction(getStoredToken()));
    }
    setResolved(true);
  }, [dispatch, isAuthorized, isResolved]);

  if (!isResolved) {
    return <FullScreenLoader />;
  }

  return <>{children}</>;
};

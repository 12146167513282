import { ChildRegisterEditRoute } from 'config/routes.types';
import { push } from 'connected-react-router';
import React, { useContext } from 'react';
import { Page } from 'components/layout/panel/Page/Page';
import { LocaleContext } from 'context/locale';
import { RFC } from '@typings/extra-react-router';
import { EditorAddContainer } from 'components/childRegister/Editor/EditorAddContainer';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router';
import { useStyles } from 'views/Panel/ChildRegister/ChildRegisterAdd/ChildRegisterAdd.styles';
import { Breadcrumb } from 'components/shared/navigation/Breadcrumbs/Breadcrumb';
import { ROUTE_CHILD_REGISTER, ROUTE_CHILD_REGISTER_ADD, ROUTE_INSTITUTION_UNIT_ADD } from 'config/routes';
import classNames from 'classnames';

export const ChildRegisterAdd: RFC<ChildRegisterEditRoute> = () => {
  const { trans } = useContext(LocaleContext);
  const classes = useStyles();
  const dispatch = useDispatch();

  const goBack = () => dispatch(push(ROUTE_CHILD_REGISTER));

  return (
    <Page title={trans('CHILD_REGISTER.BROWSER.TITLE')}>
      <Breadcrumb label={trans('BREADCRUMB.CHILD-REGISTER.ADD')} to={ROUTE_INSTITUTION_UNIT_ADD} />
      <div className={classNames('container-fluid', classes.container)}>
        <Switch>
          <Route exact path={ROUTE_CHILD_REGISTER_ADD} component={() => <EditorAddContainer goBack={goBack} />} />
        </Switch>
      </div>
    </Page>
  );
};

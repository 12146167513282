import { muiStyles } from 'utils/design';

export const useStyles = muiStyles((theme) => ({
  row: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: '70px',
  },
  rowYear: {
    marginRight: theme.spacing(2),
  },
  rowDescription: {
    flexGrow: 1,
  },
  rowButton: {
    marginLeft: theme.spacing(2),
  },
}));

import React, { FC } from 'react';
import { Button } from 'components/shared/buttons/Button/Button';

export const BackButton: FC<{ onClick: () => void }> = ({ children, onClick }) => {
  return (
    <Button onClick={onClick} color="secondary" variant="outlined">
      {children}
    </Button>
  );
};

export interface DictionaryItem {
  id: string;
  name: string;
}

export interface SchoolDictionaryItem extends DictionaryItem {
  schoolSubtype: DictionaryItem[];
}

export type Dictionary = DictionaryItem[] | SchoolDictionaryItem[];

export interface DictionariesCollection {
  [name: string]: Dictionary;
}

export function isSchoolDictionaryItem(item: SchoolDictionaryItem | DictionaryItem): item is SchoolDictionaryItem {
  return (item as SchoolDictionaryItem).schoolSubtype !== undefined;
}

import { AppDispatch } from '@typings/redux';
import { useTranslator } from 'components/shared/hooks';
import { NotifierProps } from 'components/shared/messages/Notifier/Notifier.types';
import React, { FC, Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { removeNotification } from 'store/general';

export const Notifier: FC<NotifierProps> = ({ children, notifications }) => {
  const { enqueueSnackbar } = useSnackbar();
  const trans = useTranslator();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (notifications && notifications.length) {
      notifications.forEach((notification) => {
        enqueueSnackbar(trans(notification.message, notification.params), {
          ...notification.options,
          variant: notification.type,
        });
        dispatch(removeNotification(notification.id));
      });
    }
  }, [notifications, dispatch, enqueueSnackbar, trans]);

  return <Fragment>{children}</Fragment>;
};

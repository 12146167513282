import { Editor } from 'components/childRegister/Editor/Editor';
import { AddContainerProps } from 'components/childRegister/Editor/Editor.types';
import { useFormatter, useNotification } from 'components/shared/hooks';
import { FormikHelpers } from 'formik';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { ChildRegisterFormValues } from 'components/childRegister/Editor/General/General.types';
import { IdentificationNumberType } from 'store/childRegister';
import { useDispatch } from 'react-redux';
import { initialGuardian, mapFormValueToPayload } from 'components/childRegister/Editor/Editor.helpers';
import { fetchGuardianTypes, postChild } from 'store/childRegister/childRegister.actions';
import { emptyExemption } from './Obligation/parts/Exemptions/Exemptions.helpers';
import { emptyManualStudentRegisterEntry } from 'components/childRegister/Editor/Obligation/parts/ManualEntries/ManualEntries.helpers';
import { useCheckAccessSameType } from 'components/shared/hooks';
import { OfficialPermissionLevel, OfficialResourceGroup } from 'store/officials';

export const EditorAddContainer: FC<AddContainerProps> = ({ goBack }) => {
  const dispatch: (...params: any) => Promise<any> = useDispatch();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const { formatApiErrors } = useFormatter();
  const { success } = useNotification();
  const userReadOnly = useCheckAccessSameType(OfficialResourceGroup.childRegister, OfficialPermissionLevel.readOnly);

  useEffect(() => {
    if (!userReadOnly) return;
    setSubmitSuccess(true);
  }, [userReadOnly]);

  const formInitialValues: ChildRegisterFormValues = {
    birthday: '',
    birthCity: '',
    firstName: '',
    identificationNumber: '',
    identificationNumberType: IdentificationNumberType.PESEL,
    lastName: '',
    secondName: '',
    sex: null,
    isResident: true,
    isPermanentResident: true,
    isCatchmentChild: true,
    residence: {
      city: {
        id: null,
        name: null,
        country: null,
      },
      street: {
        id: null,
        name: null,
      },
      buildingNumber: null,
      apartmentNumber: null,
      postalCode: null,
    },
    permanentResidence: {
      city: {
        id: null,
        name: null,
        country: null,
      },
      street: {
        id: null,
        name: null,
      },
      buildingNumber: null,
      apartmentNumber: null,
      postalCode: null,
    },
    guardians: [initialGuardian()],
    exemptions: [emptyExemption()],
    manualStudentRegisterEntries: [emptyManualStudentRegisterEntry()],
  };

  useEffect(() => {
    dispatch(fetchGuardianTypes());
  }, [dispatch]);

  const handleSubmit = useCallback(
    (values: ChildRegisterFormValues) => {
      return dispatch(postChild(mapFormValueToPayload(values, false)));
    },
    [dispatch],
  );

  const handleOnSubmit = (values: ChildRegisterFormValues, formik: FormikHelpers<ChildRegisterFormValues>) => {
    handleSubmit(values).then((result) => {
      if (result.error) {
        if (result.payload.response.errors) {
          formik.setErrors(formatApiErrors(result.payload.response.errors));
        }
        formik.setSubmitting(false);
      } else {
        success('CHILD_REGISTER.FORM.SUCCESS');
        setSubmitSuccess(true);
        goBack(true);
      }
    });
  };

  return (
    <Editor
      goBack={() => goBack(false)}
      onSubmit={handleOnSubmit}
      submitted={submitSuccess}
      initial={formInitialValues}
      loading={false}
    />
  );
};

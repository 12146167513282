import { institutionsColumns, recipientsGroupsColumns } from 'components/polls/Publication/columns.config';
import { useStyles } from 'components/polls/Publication/Publication.styles';
import { PublicationProps } from 'components/polls/Publication/Publication.types';
import { Button } from 'components/shared/buttons/Button/Button';
import { DataGrid } from 'components/shared/data/DataGrid/DataGrid';
import { Confirm } from 'components/shared/dialogs/Confirm/Confirm';
import { Form } from 'components/shared/form';
import { AutoSave } from 'components/shared/form/AutoSave/AutoSave';
import { DatePicker } from 'components/shared/form/DatePicker/DatePicker';
import { RadioList } from 'components/shared/form/RadioBox/RadioList';
import { useTranslator } from 'components/shared/hooks';
import { Loader } from 'components/shared/loaders/Loader/Loader';
import { TextLoader } from 'components/shared/loaders/TextLoader/TextLoader';
import { Box } from 'components/shared/surfaces/Box/Box';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import { Heading } from 'components/shared/typography/Heading/Heading';
import React, { FC, useState } from 'react';
import { Prompt } from 'react-router';
import { PollPublishForm, PollResponseMode, RecipientGroup, SynchronizedInstitution } from 'store/polls';

export const Publication: FC<PublicationProps> = (props) => {
  const trans = useTranslator();
  const classes = useStyles();
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>();

  if (!props.poll || props.loading) {
    return <TextLoader text={trans('COMMON.LOADING_DATA')} />;
  }

  return (
    <Box margin={[0, 2]} grow>
      <Form<PollPublishForm>
        initialValues={props.initialForm}
        onSubmit={(values) => {
          props.onFormUpdate(values);
        }}
        className={classes.form}
      >
        {(formik) => (
          <>
            <Paper innerSpacing={2} className={classes.paper}>
              <Heading type="h4">{props.poll.name}</Heading>
              <DatePicker
                name="expirationDate"
                labelText={trans('POLLS.PUBLICATION.EXPIRATION_DATE')}
                className={classes.expirationDatePicker}
              />
              <Heading type="h5" className="mt-1">
                {trans('POLLS.PUBLICATION.RECIPIENTS')}
              </Heading>

              <div className={classes.lists}>
                <div className={classes.institutionLists}>
                  <div className={classes.listTitle}>{trans('POLLS.PUBLICATION.INSTITUTIONS.LIST_TITLE')}</div>
                  <DataGrid<SynchronizedInstitution>
                    columns={institutionsColumns()}
                    data={props.institutions}
                    rowSelection={'multiple'}
                    onSelectionChange={(institutions) => {
                      formik.setFieldValue(
                        'institutions',
                        institutions.map((institution) => institution.id),
                      );
                    }}
                    statusPanels={[
                      {
                        statusPanel: 'countSelected',
                        statusPanelParams: { title: 'POLLS.PUBLICATION.INSTITUTIONS.COUNT_STATUS' },
                        align: 'left',
                      },
                    ]}
                  />
                </div>
                <div className={classes.recipientGroupsLists}>
                  <div className={classes.listTitle}>{trans('POLLS.PUBLICATION.RECIPIENT_GROUPS.LIST_TITLE')}</div>
                  <DataGrid<RecipientGroup>
                    columns={recipientsGroupsColumns()}
                    data={props.groups}
                    rowSelection={'multiple'}
                    onSelectionChange={(groups) => {
                      formik.setFieldValue(
                        'recipientGroups',
                        groups.map((group) => group.name),
                      );
                    }}
                    statusPanels={[
                      {
                        statusPanel: 'countSelected',
                        statusPanelParams: { title: 'POLLS.PUBLICATION.RECIPIENT_GROUPS.COUNT_STATUS' },
                        align: 'left',
                      },
                    ]}
                  />
                </div>
              </div>

              <div className="mt-2">
                <Heading type="h5" className="mt-3">
                  {trans('POLLS.PUBLICATION.RESPONSE_MODE.LABEL')}
                </Heading>

                <RadioList
                  containerClassName={classes.responseMode}
                  name={'pollResponseMode'}
                  options={[
                    {
                      label: trans('POLLS.PUBLICATION.RESPONSE_MODE.INSTITUTION'),
                      value: PollResponseMode.INSTITUTION,
                    },
                    {
                      label: trans('POLLS.PUBLICATION.RESPONSE_MODE.RECIPIENT'),
                      value: PollResponseMode.RECIPIENT,
                    },
                  ]}
                />
              </div>

              {props.recipients.updating && (
                <div className={classes.recipientsCount}>
                  <Loader name={'sync-alt'} className="mr-2" />
                  {trans('POLLS.PUBLICATION.RECIPIENTS_COUNT_UPDATE')}
                </div>
              )}

              {!props.recipients.updating && (
                <div className={classes.recipientsCount}>
                  {trans('POLLS.PUBLICATION.RECIPIENTS_COUNT', {
                    responses: props.recipients.responsesCount,
                    recipients: props.recipients.recipientsCount,
                  })}
                </div>
              )}

              <Prompt when={formik.dirty && !props.submitted} message={trans('COMMON.CONFIRM_EXIT')} />
              <AutoSave debounced={['institutions']} debounceTime={300} />
            </Paper>

            <div className="my-4">
              <Button color="secondary" variant="outlined" onClick={() => props.onCancel()}>
                {trans('COMMON.BACK')}
              </Button>
              <Button
                color="secondary"
                variant="contained"
                className="ml-3"
                disabled={props.recipients.recipientsCount === 0 || props.recipients.updating}
                onClick={() => setConfirmModalOpen(true)}
              >
                {trans('COMMON.PUBLISH')}
              </Button>

              <Confirm
                title={'POLLS.PUBLICATION.CONFIRM.TITLE'}
                message={'POLLS.PUBLICATION.CONFIRM.MESSAGE'}
                messageValues={{ count: props.recipients.recipientsCount }}
                open={confirmModalOpen}
                confirm={() => {
                  setConfirmModalOpen(false);
                  props.onFormSubmit(formik.values, formik);
                }}
                cancel={() => setConfirmModalOpen(false)}
              />
            </div>
          </>
        )}
      </Form>
    </Box>
  );
};

import { Checkbox } from '@material-ui/core';
import { CheckboxStatus } from '@typings/common';
import { Events } from 'ag-grid-community';
import { useGridEvent } from 'components/shared/data/DataGrid/hooks';
import {
  areAllBlocksLoaded,
  getSelectionStatus,
  loadAllBlocks,
  setFastAllSelection,
} from 'components/shared/data/DataGrid/utils/serverSideModelHelpers';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { lockScreen } from 'store/general';
import { muiStyles } from 'utils/design';

const useStyles = muiStyles(() => ({
  root: {
    padding: '1px',
    color: '#333',
  },
}));

export const LoadAndSelectAll = ({ api }) => {
  const classes = useStyles();
  const [loadAllRequested, setLoadAllRequested] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [status, setStatus] = useState<CheckboxStatus>(CheckboxStatus.UNCHECKED);
  const dispatch = useDispatch();

  const syncCheckbox = (newApi) => {
    if (loadAllRequested) {
      return;
    }

    const { selectableRowsCount, selectedCount } = getSelectionStatus(newApi);
    setButtonDisabled(selectableRowsCount === 0);

    if (selectableRowsCount !== 0 && selectableRowsCount === selectedCount && status !== CheckboxStatus.CHECKED) {
      setStatus(CheckboxStatus.CHECKED);
      return;
    }

    if (selectableRowsCount > selectedCount && selectedCount > 0 && status !== CheckboxStatus.INDETERMINATE) {
      setStatus(CheckboxStatus.INDETERMINATE);
      return;
    }

    if (selectedCount === 0 && status !== CheckboxStatus.UNCHECKED) {
      setStatus(CheckboxStatus.UNCHECKED);
      return;
    }
  };

  const onCheckboxUpdate = (checked) => {
    const { selectableRowsCount, selectedCount } = getSelectionStatus(api);

    if (checked && !areAllBlocksLoaded(api)) {
      dispatch(lockScreen(true));
      loadAllBlocks(api);
      setLoadAllRequested(true);
    }
    if (checked && areAllBlocksLoaded(api)) {
      setFastAllSelection(api, true);
    }
    if (!checked && selectableRowsCount === selectedCount) {
      setFastAllSelection(api, false);
    }

    setStatus(checked ? CheckboxStatus.CHECKED : CheckboxStatus.UNCHECKED);
  };

  useGridEvent(api, Events.EVENT_PAGINATION_CHANGED, (event) => {
    if (areAllBlocksLoaded(event.api) && loadAllRequested) {
      setFastAllSelection(event.api, true);
      dispatch(lockScreen(false));
      setLoadAllRequested(false);
      setStatus(CheckboxStatus.CHECKED);
    }
  });

  useGridEvent(api, Events.EVENT_SELECTION_CHANGED, (event) => {
    syncCheckbox(event.api);
  });

  useGridEvent(api, Events.EVENT_MODEL_UPDATED, (event) => {
    syncCheckbox(event.api);
  });

  return (
    <div>
      <Checkbox
        color="primary"
        classes={classes}
        checked={status === CheckboxStatus.CHECKED}
        indeterminate={status === CheckboxStatus.INDETERMINATE}
        onChange={(event, value) => onCheckboxUpdate(value)}
        disableRipple={true}
        disabled={isButtonDisabled}
      />
    </div>
  );
};

import { Button } from 'components/shared/buttons/Button/Button';
import { InputText } from 'components/shared/form';
import { InputTextProps } from 'components/shared/form/InputText/InputText.types';
import { useFormatter, useTranslator } from 'components/shared/hooks';
import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon';
import { connect } from 'formik';
import React from 'react';
import { useStyles } from 'components/shared/form/NumberField/NumberField.styles';
import { get } from 'lodash';
import { clamp } from 'utils/number/clamp';
import { getSumDecimalsCount, roundDecimals } from 'utils/number/decimals';
import classNames from 'classnames';

interface NumberFieldProps extends InputTextProps {
  min?: number;
  max?: number;
  step?: number;
}

export const NumberField = connect<NumberFieldProps>(({ formik, ...props }) => {
  const classes = useStyles();
  const trans = useTranslator();
  const { formatNumber } = useFormatter();
  const { name, min, max, step = 1, className, ...fieldProps } = props;

  const updateValue = (change: number) => {
    const currentValue = Number(get(formik.values, name));

    if (isNaN(currentValue)) {
      formik.setFieldValue(name, NaN);
    } else {
      const decimalsCount = getSumDecimalsCount(currentValue, change);

      const summedNextValue = currentValue + change;
      const clampedNextValue = clamp(summedNextValue, max, min);
      const roundedNextValue = roundDecimals(clampedNextValue, decimalsCount);

      formik.setFieldValue(name, roundedNextValue);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.field}>
        <InputText name={name} className={classNames(classes.input, className)} {...fieldProps} />
      </div>
      <div className={classes.controls}>
        <Button
          size={'small'}
          variant={'contained'}
          disableElevation
          className={classes.button}
          onClick={() => updateValue(-step)}
          title={trans('COMMON.SUBTRACT_VALUE', { value: formatNumber(step) })}
        >
          <FontAwesomeIcon name={'chevron-down'} />
        </Button>
        <Button
          size={'small'}
          variant={'contained'}
          disableElevation
          className={classes.button}
          onClick={() => updateValue(step)}
          title={trans('COMMON.ADD_VALUE', { value: formatNumber(step) })}
        >
          <FontAwesomeIcon name={'chevron-up'} />
        </Button>
      </div>
    </div>
  );
});

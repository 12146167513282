import { RefObject, useCallback, useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { lockScreen } from 'store/general';
import { makeErrorsVisible } from 'utils/form/makeErrorsVisible';
import { delay } from 'utils/function/delay';

export const useScrollToError = (formRef: RefObject<HTMLFormElement>) => {
  const [scrollRequest, setScrollRequest] = useState(false);
  const dispatch = useDispatch();

  const findFirstErrorMessage = useCallback(() => {
    return document.getElementsByClassName('hasValidationError')[0];
  }, []);

  const scrollTo = (node: Element) => {
    if (!node) {
      // eslint-disable-next-line no-console
      console.log('[useScrollToError] Node to scroll not found');
      return;
    }

    node.scrollIntoView &&
      node.scrollIntoView({
        behavior: 'smooth',
      });
  };

  const handleSingleViewForm = useCallback(async () => {
    const errorNode = findFirstErrorMessage();
    await makeErrorsVisible();
    scrollTo(errorNode);
  }, [findFirstErrorMessage]);

  const getTabButtons = (formTabs) => {
    const activeButton = formTabs.querySelector('button[aria-selected="true"]');
    const otherButtons = formTabs.querySelectorAll('button[aria-selected="false"]');

    return [activeButton, ...Array.from(otherButtons)];
  };

  const handleTabbedForm = useCallback(
    async (formTabs: Element) => {
      dispatch(lockScreen(true));
      for (const button of getTabButtons(formTabs)) {
        button.click();

        const errorNode: Element = await delay(() => {
          return findFirstErrorMessage();
        }, 0);

        if (errorNode) {
          await makeErrorsVisible();
          scrollTo(errorNode);
          break;
        }
      }
      dispatch(lockScreen(false));
    },
    [dispatch, findFirstErrorMessage],
  );

  useLayoutEffect(() => {
    if (scrollRequest) {
      setScrollRequest(false);
      const formTabs = formRef.current.querySelector('[class^="MuiTabs-flexContainer"]');
      formTabs ? handleTabbedForm(formTabs) : handleSingleViewForm();
    }
  }, [dispatch, findFirstErrorMessage, formRef, handleSingleViewForm, handleTabbedForm, scrollRequest]);

  return () => setTimeout(() => setScrollRequest(true), 300);
};

import { Events } from 'ag-grid-community';

export const loadAllBlocks = (api) => {
  const total = api.serverSideRowModel.getRowCount();
  const perPage = api.serverSideRowModel.cacheParams.blockSize;
  let rowPointer = 0;

  while (rowPointer < total) {
    rowPointer += perPage;
    const rowToShow = Math.min(rowPointer, total);
    setTimeout(() => api.ensureIndexVisible(rowToShow - 1), 0);
  }

  setTimeout(() => api.ensureIndexVisible(0), 200);
};

export const areAllBlocksLoaded = (api) => {
  const blocks = api.serverSideRowModel.rowNodeBlockLoader.blocks;
  const totalRowsCount = api.serverSideRowModel.getRowCount();

  if (blocks.length === 0) {
    return false;
  }

  const lastRowIndex = blocks[blocks.length - 1].getDisplayIndexEnd();
  const isEveryBlockReady = blocks.every((block) => block.state === 'loaded');

  return lastRowIndex === totalRowsCount && isEveryBlockReady;
};

export const getLoadedNodes = (api) => {
  const selection = [];
  api.forEachNode((node) => selection.push(node));

  return selection;
};

export const getLoadedRows = <T>(api): T[] => {
  return getLoadedNodes(api).map((node) => node.data);
};

// The `selectAll` method don't work for server side model.
// On the other hand `node.setSelected` takes too many time
// because of special cases and events dispatching. That's why
// here we are selecting all nodes using low level interface
// and we dispatch the EVENT_SELECTION_CHANGED only once.
export const setFastAllSelection = (api, value) => {
  api.forEachNode((node) => {
    if (node.selectable) {
      node.selectThisNode(value);
    }
  });

  api.eventService.dispatchEvent({
    type: Events.EVENT_SELECTION_CHANGED,
    api,
    columnApi: api.columnController.columnApi,
  });
};

export const getSelectionStatus = (api) => {
  const selectableNodes = getLoadedNodes(api).filter((node) => node.selectable && !node.stub);

  const selectableRowsCount = selectableNodes.length;
  const selectedCount = api.getSelectedNodes().length;

  return { selectableRowsCount, selectedCount };
};

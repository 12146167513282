import { muiStyles } from 'utils/design';

export const useStyles = muiStyles((theme) => ({
  form: {
    width: 600,
  },
  sectionHeader: {
    fontWeight: 'bolder',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
    textAlign: 'left',
    textTransform: 'uppercase',
    fontSize: '1.1em',
  },
}));

import { Collapse, IconButton } from '@material-ui/core';
import { GuardianPostModel } from 'components/childRegister/Editor/General/General.types';
import { useStyles } from 'components/childRegister/Editor/General/parts/GuardianData.styles';
import { ActionButton } from 'components/shared/buttons/ActionButton/ActionButton';
import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import { AddressPickerContainer } from 'components/shared/teryt/AddressPicker/AddressPickerContainer';
import { TownPickerContainer } from 'components/shared/teryt/TownPicker/TownPickerContainer';
import React, { FC, Fragment, useState } from 'react';
import { Heading } from 'components/shared/typography/Heading/Heading';
import { useTranslator } from 'components/shared/hooks';
import { FieldArray } from 'components/shared/form/FieldArray/FieldArray';
import { CheckBox, InputText } from 'components/shared/form';
import { Button } from 'components/shared/buttons/Button/Button';
import { initialGuardian } from 'components/childRegister/Editor/Editor.helpers';
import { Autocomplete } from 'components/shared/form/Autocomplete/Autocomplete';
import { OptionType } from 'components/shared/form/Autocomplete/Autocomplete.types';
import { Help } from '@material-ui/icons';
import { colors } from 'styles/colors';

interface GuardianDataProps {
  guardianTypes: OptionType[];
  onAddNewGuardian: () => void;
  handleAutocompleteChange: (selection: any) => void;
  showHelpMessage?: boolean;
}

export const GuardianData: FC<GuardianDataProps> = ({
  guardianTypes,
  onAddNewGuardian,
  handleAutocompleteChange,
  showHelpMessage,
}) => {
  const trans = useTranslator();
  const classes = useStyles();
  const [helpMessageVisible, setHelpMessageVisible] = useState(showHelpMessage);

  const handleAddNewGuardian = (push: (guardian: any) => void) => {
    push(initialGuardian());
    //allow DOM to insert new element
    setTimeout(onAddNewGuardian, 0);
  };

  const helpIconRender = () => {
    return (
      <IconButton
        className={classes.helpIcon}
        onClick={() => setHelpMessageVisible((value) => !value)}
        color={helpMessageVisible ? 'primary' : 'default'}
      >
        <Help />
      </IconButton>
    );
  };

  return (
    <div className="container-fluid pt-2 mt-2 pb-3" id="guardians-form">
      <Heading type="h5">
        {trans('CHILD_REGISTER.FORM.GUARDIANS_TITLE')}
        {helpIconRender()}
      </Heading>
      <Collapse in={helpMessageVisible}>
        <Paper innerSpacing={3} background={colors.info} className={'mt-2'}>
          {trans('CHILD_REGISTER.FORM.GUARDIANS_HELP')}
        </Paper>
      </Collapse>
      <FieldArray name="guardians">
        {({ form, name, ...props }) => {
          return form.values && form.values[name] ? (
            <Fragment>
              {form.values[name].length === 0 && (
                <div className="row my-4">
                  <div className="col d-flex justify-content-center">
                    <Heading type="h6">{trans('CHILD_REGISTER.FORM.GUARDIANS_EMPTY')}</Heading>
                  </div>
                </div>
              )}
              {form.values[name].map((guardian: GuardianPostModel, index) => {
                return (
                  <div key={`guardian_${index}`} className={classes.guardian} id={`guardian-${guardian.id}`}>
                    <Heading type="h6" className={classes.typeHeader}>
                      {form.values[name][index]['type']}
                    </Heading>
                    <div className="row mt-2">
                      <div className="col-3">
                        <InputText
                          name={`guardians.${index}.firstName`}
                          labelText={trans('CHILD_REGISTER.FORM.FIRST_NAME.LABEL')}
                        />
                      </div>
                      <div className="col-3">
                        <InputText
                          name={`guardians.${index}.lastName`}
                          labelText={trans('CHILD_REGISTER.FORM.LAST_NAME.LABEL')}
                        />
                      </div>
                      <div className="col-3">
                        <Autocomplete
                          name={`guardians.${index}.type`}
                          placeholder={trans('CHILD_REGISTER.FORM.SEX.VALUES.EMPTY')}
                          labelText={trans('CHILD_REGISTER.FORM.GUARDIAN_TYPE.LABEL')}
                          options={guardianTypes}
                          createable
                          newOptionMaxLength={50}
                          onChange={handleAutocompleteChange}
                          isNewOptionValid={(value) => !guardianTypes.some((option) => option.label.includes(value))}
                        />
                      </div>
                      <div className="col-3">
                        <CheckBox
                          name={`guardians.${index}.correspondenceGuardian`}
                          labelText={trans('CHILD_REGISTER.FORM.CORRESPONDENCE_GUARD.LABEL')}
                        />
                      </div>
                    </div>
                    <div className="row  mt-2">
                      <div className="col-3">
                        <InputText
                          name={`guardians.${index}.contact.phone`}
                          labelText={trans('CHILD_REGISTER.FORM.PHONE.LABEL')}
                        />
                      </div>
                      <div className="col-3">
                        <InputText
                          name={`guardians.${index}.contact.email`}
                          labelText={trans('CHILD_REGISTER.FORM.EMAIL.LABEL')}
                        />
                      </div>
                      <div className="col-3">
                        <CheckBox
                          name={`guardians.${index}.sameAddressAsChild`}
                          labelText={trans('CHILD_REGISTER.FORM.WARD_ADDRESS.LABEL')}
                        />
                      </div>
                    </div>
                    {!form.values[name][index]['sameAddressAsChild'] && (
                      <>
                        <div className="row mt-4">
                          <TownPickerContainer fieldName={`guardians.${index}.address.city`} required />
                        </div>
                        <div className="row mt-2">
                          <AddressPickerContainer
                            fieldName={`guardians.${index}.address.street`}
                            townFieldName={`guardians.${index}.address.city`}
                            addressFieldName={`guardians.${index}.address`}
                            fullAddress
                          />
                        </div>
                      </>
                    )}
                    <div className={classes.actions}>
                      {index > -1 && (
                        <ActionButton
                          data-testid={`guardianActionButton-${index}`}
                          component={IconButton}
                          variant="contained"
                          disableCaret={true}
                          color="default"
                          label={() => <FontAwesomeIcon name={'ellipsis-v'} className={'mx-2'} />}
                          actionList={[
                            {
                              action: () => props.remove(index),
                              label: 'CHILD_REGISTER.FORM.REMOVE_GUARDIAN',
                              icon: 'trash',
                            },
                          ]}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
              <div className="row">
                <Button
                  onClick={() => handleAddNewGuardian(props.push)}
                  color="secondary"
                  variant="outlined"
                  className="ml-3"
                >
                  {trans('CHILD_REGISTER.FORM.ADD_GUARDIAN')}
                </Button>
              </div>
            </Fragment>
          ) : (
            <Fragment />
          );
        }}
      </FieldArray>
    </div>
  );
};

export interface GridSizeFunction {
  (gridApi: any): GridSize;
}

export interface GridSize {
  width: number;
  height: number;
  field: number;
}

export const getGridSize: GridSizeFunction = (gridApi: any) => {
  const rowsCount = gridApi ? gridApi.getModel().getRowCount() : 0;
  const columnsCount = gridApi ? gridApi.columnController.columnApi.getAllColumns().length : 0;

  return {
    width: columnsCount,
    height: rowsCount,
    field: columnsCount * rowsCount,
  };
};

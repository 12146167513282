import { FullScreenLoader } from 'components/shared/loaders/FullScreenLoader/FullScreenLoader';
import {
  ROUTE_AUTH_LOGIN,
  ROUTE_CHILD_REGISTER,
  ROUTE_INSTITUTIONS,
  ROUTE_POLLS,
  ROUTE_REPORTS,
  ROUTE_SCHOOL_DISTRICTS,
  ROUTE_SCHOOL_OBLIGATION,
  ROUTE_TEMPLATES,
  ROUTE_SETTINGS,
  ROUTE_OFFICIALS,
  ROUTE_PANEL,
  ROUTE_PANEL_EMPTY_PAGE,
  ROUTE_PANEL_MESSAGES,
  ROUTE_SEARCH,
} from 'config/routes';
import { push } from 'connected-react-router';
import React from 'react';
import { Sidebar } from 'components/layout/panel/Sidebar/Sidebar';
import { TitleBarContainer } from 'components/layout/panel/TitleBar/TitleBarContainer';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router';
import { isAccessTokenSet } from 'store/auth';
import { fetchDictionaries } from 'store/general';
import { areDictionariesReadySelector } from 'store/general/general.selectors';
import { Main } from 'components/shared/layout/Main/Main';
import { NotFoundView } from 'views/Errors/NotFound.view';
import { ChildRegisterView } from 'views/Panel/ChildRegister/ChildRegister.view';
import { useStyles } from 'views/Panel/Panel.styles';
import { Footer } from 'components/shared/layout/Footer/Footer';
import { PollsView } from 'views/Panel/Polls/Polls.view';
import { ReportsView } from 'views/Panel/Reports/Reports.view';
import { TemplatesView } from 'views/Panel/Templates/Templates.view';
import { InstitutionsView } from './Institutions/Institutions.view';
import { SchoolDistrictsView } from 'views/Panel/SchoolDistricts/SchoolDistricts.view';
import { SchoolObligationView } from 'views/Panel/SchoolObligation/SchoolObligation.view';
import { SettingsView } from 'views/Panel/Settings/Settings.view';
import { OfficialsView } from 'views/Panel/Officials/Officials.view';
import { navigation } from 'components/layout/panel/Sidebar/navigation/navigation';
import { helpersMultipeCheckAccess } from 'components/shared/hooks';
import { userOfficialRole, userOfficialPermissions, userOfficialPermissionsReady } from 'store/user';
import { EmptyView } from 'views/Panel/Empty/Empty.view';
import { MessagesView } from 'views/Panel/Messages/Messages.view';
import { SearchView } from 'views/Panel/Search/Search.view';

const handleFor = (role, permissions) => (navigation) => {
  for (const single of navigation) {
    const isAccess = helpersMultipeCheckAccess(single.resourcesGroups, single.accessType, role, permissions);
    if (!isAccess) continue;
    if (!single.children) return single;
    const permissionChildren = handleFor(role, permissions)(single.children);
    if (permissionChildren) return permissionChildren;
  }
};

const BaseRoute = () => {
  const role = useSelector(userOfficialRole);
  const permissions = useSelector(userOfficialPermissions);
  const permissionsReady = useSelector(userOfficialPermissionsReady);
  const dispatch = useDispatch();
  const activeElement = handleFor(role, permissions)(navigation);
  if (permissionsReady && !activeElement) {
    dispatch(push(ROUTE_PANEL_EMPTY_PAGE));
    return null;
  }
  if (!activeElement) return null;
  if (permissionsReady && !activeElement.path) {
    dispatch(push(ROUTE_PANEL_MESSAGES));
    return null;
  }
  return <Redirect path={ROUTE_PANEL} exact to={activeElement.path} />;
};

export const PanelView: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isAuthorized = useSelector(isAccessTokenSet);
  const dictionariesReady = useSelector(areDictionariesReadySelector);

  if (!isAuthorized) {
    dispatch(push(ROUTE_AUTH_LOGIN));
  } else if (!dictionariesReady) {
    dispatch(fetchDictionaries());
  }

  const renderMain = () => {
    return (
      <>
        <Sidebar />
        <Main>
          <Switch>
            <Route path={ROUTE_PANEL} exact component={BaseRoute} />
            <Route path={ROUTE_INSTITUTIONS} component={InstitutionsView} />
            <Route path={ROUTE_CHILD_REGISTER} component={ChildRegisterView} />
            <Route path={ROUTE_SCHOOL_DISTRICTS} component={SchoolDistrictsView} />
            <Route path={ROUTE_SCHOOL_OBLIGATION} component={SchoolObligationView} />
            <Route path={ROUTE_TEMPLATES} component={TemplatesView} />
            <Route path={ROUTE_POLLS} component={PollsView} />
            <Route path={ROUTE_REPORTS} component={ReportsView} />
            <Route path={ROUTE_SETTINGS} component={SettingsView} />
            <Route path={ROUTE_OFFICIALS} component={OfficialsView} />
            <Route path={ROUTE_PANEL_MESSAGES} component={MessagesView} />
            <Route path={ROUTE_PANEL_EMPTY_PAGE} component={EmptyView} />
            <Route path={ROUTE_SEARCH} component={SearchView} />
            <Route component={NotFoundView} />
          </Switch>
        </Main>
      </>
    );
  };

  return (
    <div className={classes.root}>
      <TitleBarContainer />
      <div className={classes.content}>{dictionariesReady ? renderMain() : <FullScreenLoader />}</div>
      <Footer />
    </div>
  );
};

import { Button } from 'components/shared/buttons/Button/Button';
import { useStyles } from 'components/shared/data/DataGrid/StatusPanel/DeleteSelected/DeleteSelected.styles';
import { useTranslator } from 'components/shared/hooks';
import { useInterval } from 'components/shared/hooks/useInterval/useInterval';
import React, { FC, useState } from 'react';
import classNames from 'classnames';

export const DeleteSelected: FC<{ api: any; onDelete: Function }> = ({ api, onDelete }) => {
  const [count, setCount] = useState(api.getSelectedRows().length);
  const trans = useTranslator();
  const classes = useStyles();

  /**
   * The implementation of Status Bar Components in React is broken.
   * The component is not updated when the rows change. This fix it.
   */
  useInterval(() => {
    const newValue = api.getSelectedRows().length;

    if (newValue !== count) {
      setCount(newValue);
    }
  }, 300);

  if (count === 0) {
    return <div className="ag-name-value"></div>;
  } else {
    return (
      <div className="ag-name-value">
        {trans('COMMON.SELECTED_N_RESULTS', { count })}
        <Button
          onClick={() => onDelete(api.getSelectedRows())}
          size="small"
          color="primary"
          variant="outlined"
          className={classNames('ml-2', classes.button)}
        >
          {trans('COMMON.DELETE')}
        </Button>
      </div>
    );
  }
};

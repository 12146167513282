import { muiStyles } from 'utils/design';

export const useStyles = muiStyles(() => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  grid: {
    flexGrow: 1,
  },
  form: {
    width: '300px',
  },
}));

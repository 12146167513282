import { Close } from '@material-ui/icons';
import { useStyles } from 'components/polls/Editor/Question/QuestionOption/QuestionOption.styles';
import { OptionProps } from 'components/polls/Editor/Editor.types';
import { IconButton } from 'components/shared/buttons/Button/IconButton';
import { InputText } from 'components/shared/form';
import React, { FC } from 'react';

export const QuestionOption: FC<OptionProps> = ({ field, onRemove, inputPreview }) => {
  const classes = useStyles();

  return (
    <div className={classes.option} data-testid="option">
      <div className={classes.fakeInput}>{inputPreview}</div>
      <div className={classes.name}>
        <InputText name={`${field}.value`} data-testid="optionInput" />
      </div>
      <div className={classes.remove}>
        <IconButton onClick={onRemove} size="small" data-testid="removeOptionButton">
          <Close fontSize="small" />
        </IconButton>
      </div>
    </div>
  );
};

import { AppState } from '../store';

export const getBirthYearsSelector = (state: AppState) => state.schoolObligations.birthYears;

export const getReportsSelector = (state: AppState) => state.schoolObligations.reports;
export const getReportRequestSelector = (state: AppState) => state.schoolObligations.reportRequest;

export const getReportDetails = (state: AppState) => state.schoolObligations.reportDetails.report;
export const isReportDetailsLoading = (state: AppState) => state.schoolObligations.reportDetails.loading;

export const getReportChildrenList = (state: AppState) => state.schoolObligations.reportChildrenList.pages;
export const getReportChildrenTotal = (state: AppState) => state.schoolObligations.reportChildrenList.total;
export const getReportChildrenListFilters = (state: AppState) => state.schoolObligations.reportChildrenList.filters;

export const getReportInstitutions = (state: AppState) => state.schoolObligations.institutions;
export const getFulfillmentOptions = (state: AppState) => state.schoolObligations.fulfillmentOptions;

export const getBrowserFilter = (state: AppState) => state.schoolObligations.reportBrowserFilters;

import { REFRESH_TOKEN_FAILURE, REFRESH_TOKEN_REQUEST, REFRESH_TOKEN_SUCCESS } from '@tshio/redux-api-auth-middleware';
import { FSA } from '@typings/redux';
import { RSAA, RSAACreator } from 'redux-api-middleware';
import { AuthActionType } from 'store/auth/auth.actionType';
import { toApiFormat } from 'utils/api';

export const refreshAccessToken: RSAACreator = (refreshToken, endpoint) => {
  return {
    [RSAA]: {
      endpoint,
      method: 'POST',
      types: [REFRESH_TOKEN_REQUEST, REFRESH_TOKEN_SUCCESS, REFRESH_TOKEN_FAILURE],
      body: toApiFormat({
        refreshToken,
      }),
      skipAuth: true,
      params: {},
    },
  };
};

export const trySessionDestroy: RSAACreator = (baseUrl: string) => {
  return {
    [RSAA]: {
      endpoint: '/logout',
      method: 'DELETE',
      params: toApiFormat({ baseUrl }),
      types: [
        {
          type: AuthActionType.SESSION_DESTROY_REQUEST,
          meta: {
            lockScreen: true,
          },
        },
        AuthActionType.SESSION_DESTROY_SUCCESS,
        {
          type: AuthActionType.SESSION_DESTROY_FAILURE,
          meta: {
            ignoreNotFoundError: true,
            ignoreUnauthorizedError: true,
          },
        },
      ],
    },
  };
};

export const appLogout = (): FSA => {
  return {
    type: AuthActionType.APP_LOGOUT,
    error: {},
    payload: {
      response: {},
    },
  };
};

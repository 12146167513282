import { Tooltip as MuiTooltip } from '@material-ui/core';
import { TooltipProps } from './Tooltip.types';
import { FC, default as React } from 'react';

export const Tooltip: FC<TooltipProps> = (props) => {
  const { disabled = false, title, ...restProps } = props;

  return (
    <MuiTooltip title={disabled ? '' : title} {...restProps}>
      <span>{props.children}</span>
    </MuiTooltip>
  );
};

import { MenuItem } from '@material-ui/core';
import { OptionType } from 'components/shared/form/Autocomplete/Autocomplete.types';
import React from 'react';
import { OptionProps } from 'react-select';

export function Option(props: OptionProps<OptionType, boolean>) {
  const { classes } = props.selectProps;

  return (
    <MenuItem
      className={classes.option}
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

import React, { FC } from 'react';

import { DatabaseForm } from 'components/schoolDistricts/DatabaseForm/DatabaseForm';
import { DatabaseEditContainerProps } from 'components/schoolDistricts/DatabaseForm/DatabaseEditContainer.types';
import { useTranslator } from 'components/shared/hooks/useTranslator/useTranslator';

export const DatabaseEdit: FC<DatabaseEditContainerProps> = (props) => {
  const trans = useTranslator();

  return (
    <DatabaseForm
      title={trans('SCHOOL_DISTRICTS.DATABASE-EDIT.TITLE')}
      successMessage="SCHOOL_DISTRICTS.DATABASE-EDIT.SUCCESS"
      errorMessage="SCHOOL_DISTRICTS.DATABASE-EDIT.FAIL"
      name={props.name}
      id={props.id}
      hideDatabaseSelector={true}
      open={props.open}
      onConfirm={props.onConfirm}
      onClose={props.onClose}
    />
  );
};

import { getOverviewData } from 'components/schoolObligation/ReportDetails/ReportOverview/overviewData.helper';
import { ReportOverview } from 'components/schoolObligation/ReportDetails/ReportOverview/ReportOverview';
import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getReportDetails } from 'store/schoolObligations';

export const ReportOverviewContainer: FC = () => {
  const report = useSelector(getReportDetails);
  const data = useMemo(() => getOverviewData(report), [report]);

  return <ReportOverview data={data} years={report.birthYears} />;
};

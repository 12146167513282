import React, { useContext, FC } from 'react';
import { InputText } from 'components/shared/form';
import { Heading } from 'components/shared/typography/Heading/Heading';
import { LocaleContext } from 'context/locale';
import { InfoOutlined } from '@material-ui/icons';
import { TownPickerContainer } from 'components/shared/teryt/TownPicker/TownPickerContainer';
import { AddressPickerContainer } from 'components/shared/teryt/AddressPicker/AddressPickerContainer';

export const AddressPart: FC<{ isSynchronized: boolean; oryginalName: string }> = ({
  isSynchronized,
  oryginalName,
}) => {
  const { trans } = useContext(LocaleContext);

  return (
    <div className="container-fluid mt-4 pb-3">
      <Heading type="h5">{trans('INSTITUTIONS.UNIT_DATA.ADDRESS.TITLE')}</Heading>
      <div className="row mt-4">
        <div className="col-3">
          <InputText
            name="name"
            labelText={trans('INSTITUTIONS.UNIT_DATA.ADDRESS.UNIT_PLACEHOLDER')}
            tooltipText={oryginalName}
            hasIcon={isSynchronized}
            icon={<InfoOutlined fontSize="small" />}
            required
          />
        </div>
      </div>
      <div className="row mt-2">
        <TownPickerContainer fieldName="address.city" required disabled={isSynchronized} />
      </div>
      <div className="row mt-2">
        <AddressPickerContainer
          fieldName="address.street"
          townFieldName="address.city"
          fullAddress
          disabled={isSynchronized}
          addressFieldName={'address'}
        />
      </div>
    </div>
  );
};

import { trimmValue } from '../helpers';

const NUMBER_REGEX = /^-?(0|[1-9]\d*)(\.|(\.\d+))?$/;
const POLISH_NUMBER_REGEX = /^-?(0|[1-9]\d*)([.,]|([.,]\d+))?$/;

type Config = {
  allowPolishNotation?: boolean;
  allowSingleDash?: boolean;
};

const defaultConfig = {
  allowPolishNotation: false,
  allowSingleDash: false,
};

export const realNumberRequired =
  (message: string, config: Config = {}) =>
  (value) => {
    const trimmedValue = trimmValue(value);
    const { allowPolishNotation, allowSingleDash } = { ...defaultConfig, ...config };

    if (allowSingleDash && trimmedValue === '-') {
      return undefined;
    }

    const regexp = allowPolishNotation ? POLISH_NUMBER_REGEX : NUMBER_REGEX;
    return trimmedValue && !regexp.test(trimmedValue) ? message : undefined;
  };

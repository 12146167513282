import React, { FC, Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import { HeadingProps } from './Heading.types';

export const Heading: FC<HeadingProps> = (props) => {
  const { type, children, ...params } = props;

  return (
    <Fragment>
      <Typography variant={type} {...params}>
        {children}
      </Typography>
    </Fragment>
  );
};

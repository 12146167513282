import React, { FC } from 'react';
import { PageProps } from 'components/layout/panel/Page/Page.types';
import { PageHeader } from 'components/layout/panel/PageHeader/PageHeader';
import { useStyles } from 'components/layout/panel/Page/Page.styles';

export const Page: FC<PageProps> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <PageHeader title={props.title} />
      {props.children}
    </div>
  );
};

import { CookieGetOptions, CookieSetOptions } from 'universal-cookie';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

const isDomainSecure = () => {
  return window && window.location.protocol === 'https:';
};

export const defaultSetOptions: CookieSetOptions = {
  sameSite: 'lax',
  secure: isDomainSecure(),
  path: '/',
};

export const getCookie = (name: string, options?: CookieGetOptions) => {
  return cookies.get(name, options);
};

export const setCookie = (name: string, value: any, options: CookieSetOptions = defaultSetOptions) => {
  return cookies.set(name, value, { ...defaultSetOptions, ...options });
};

export const removeCookie = (name: string, options: CookieSetOptions = defaultSetOptions) => {
  return cookies.remove(name, { ...defaultSetOptions, ...options });
};

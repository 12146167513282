import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Import } from './Import';
import { importTokenSelector } from 'store/childRegister';
import { clearImportInformation, deleteImport } from 'store/childRegister/childRegister.actions';
import { ImportContainerProps } from 'components/childRegister/Import/Import.types';
import { push } from 'connected-react-router';
import { ROUTE_CHILD_REGISTER } from 'config/routes';

export const ImportContainer: FC<ImportContainerProps> = (props) => {
  const dispatch: (...params: any) => Promise<any> = useDispatch();
  const importToken = useSelector(importTokenSelector);

  const deleteChildRegisterImportCallback = useCallback(() => {
    return dispatch(deleteImport(importToken));
  }, [dispatch, importToken]);

  const clearImportInformationCallback = useCallback(() => {
    return dispatch(clearImportInformation());
  }, [dispatch]);

  const redirectToListCallback = useCallback(() => {
    return dispatch(push(ROUTE_CHILD_REGISTER));
  }, [dispatch]);

  return (
    <Import
      open={props.open}
      onClose={props.onClose}
      onRemoveToken={deleteChildRegisterImportCallback}
      token={importToken}
      clearImportInformation={clearImportInformationCallback}
      redirectToList={redirectToListCallback}
    />
  );
};

import { useGridEvent } from 'components/shared/data/DataGrid/hooks';
import { CountSelectedProps } from 'components/shared/data/DataGrid/StatusPanel/CountSelected/CountSelected.types';
import { useTranslator } from 'components/shared/hooks';
import React, { ReactElement, useState } from 'react';
import { Events } from 'ag-grid-community';

export const CountSelected = ({ api, title }: CountSelectedProps): ReactElement => {
  const [count, setCount] = useState(api.getSelectedRows().length);
  const trans = useTranslator();

  useGridEvent(api, Events.EVENT_SELECTION_CHANGED, () => {
    setCount(api.getSelectedRows().length);
  });

  return (
    <div className="ag-name-value">{count === 0 ? '' : trans(title || 'COMMON.SELECTED_N_RESULTS', { count })}</div>
  );
};

import { limits } from 'config/pagination';
import React, { useCallback, useEffect } from 'react';
import { RFC } from '@typings/extra-react-router';
import { AppDispatch } from '@typings/redux';
import { ReportChildrenList } from 'components/schoolObligation/ReportDetails/ReportChildrenList/ReportChildrenList';
import { useTranslator } from 'components/shared/hooks';
import { SchoolObligationReportDetailsRoute } from 'config/routes.types';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { makePath } from 'utils/navigation/makePath';
import { ROUTE_CHILD_REGISTER_EDIT } from 'config/routes';
import {
  clearReportChildrenList,
  downloadCsvSchoolObligationsReportChildrenList,
  fetchSchoolObligationsReportChildrenList,
  getReportChildrenList,
  getReportChildrenListFilters,
  getReportChildrenTotal,
} from 'store/schoolObligations';

export const ReportChildrenListContainer: RFC<SchoolObligationReportDetailsRoute> = (props) => {
  const dispatch = useDispatch<AppDispatch>();
  const childrenPages = useSelector(getReportChildrenList);
  const filters = useSelector(getReportChildrenListFilters);
  const totalChildren = useSelector(getReportChildrenTotal);
  const trans = useTranslator();

  useEffect(() => {
    dispatch(clearReportChildrenList());
  }, [dispatch, filters]);

  const showChild = (data) => {
    dispatch(push(makePath(ROUTE_CHILD_REGISTER_EDIT, { id: data.id })));
  };

  const fetchChildrenHandler = useCallback(
    (page) => {
      return dispatch(fetchSchoolObligationsReportChildrenList(props.match.params.id, filters, page));
    },
    [dispatch, filters, props.match.params.id],
  );

  const downloadCsv = useCallback(() => {
    dispatch(
      downloadCsvSchoolObligationsReportChildrenList(
        'raport_obowiazkow_dane_korespondencyjne',
        props.match.params.id,
        filters,
      ),
    ).then((response: any) => {
      const { content, contentType, fileName } = response.payload;
      const data = content.split('\n').slice(0, -1);
      const header = data.shift();
      const preparedData = data
        .map((item) => item.split(/;(?=(?:[^"]*"[^"]*")*[^"]*$)/))
        .map((item) => {
          item[4] = trans(`SCHOOL_OBLIGATION.REPORT_CHILDREN_LIST.VALUES.OBLIGATION.${item[4].toUpperCase()}`);
          return item.join(';');
        });
      const url = window.URL.createObjectURL(
        new Blob(['\uFEFF' + [header, ...preparedData].join('\n')], { type: contentType }),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    });
  }, [dispatch, filters, props.match.params.id, trans]);

  return (
    <ReportChildrenList
      onChildrenFetch={fetchChildrenHandler}
      onCsvDownload={downloadCsv}
      childrenProvider={useCallback((page) => childrenPages[page], [childrenPages])}
      showItem={showChild}
      total={totalChildren}
      limit={limits.obligationReportChildrenList}
      reportId={props.match.params.id}
      filters={filters}
    />
  );
};

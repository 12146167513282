import { muiStyles } from 'utils/design';

export const useStyles = muiStyles({
  paper: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    height: 0,
    overflowY: 'hidden',
  },
});

import React, { FC, useEffect } from 'react';
import { SchoolStat } from 'components/reports/SchoolStat/SchoolStat';
import { useDispatch, useSelector } from 'react-redux';
import { schoolStatSelector } from 'store/reports/reports.selector';
import { AppDispatch } from '@typings/redux';
import { fetchSchoolStat } from 'store/reports/reports.action';

export const SchoolStatContainer: FC = () => {
  const { list, loading } = useSelector(schoolStatSelector);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(fetchSchoolStat());
  }, [dispatch]);

  return <SchoolStat schoolStats={list} isLoading={loading} />;
};

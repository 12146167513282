import { muiStyles } from 'utils/design';

export const useStyles = muiStyles<{ singleRow: boolean }>({
  tooltipIcon: {
    position: 'absolute',
    right: '-10px',
    top: '10px',
  },
  list: {
    flexDirection: ({ singleRow }) => (singleRow ? 'row' : 'column'),
  },
});

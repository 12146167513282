import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import { SessionRestore } from 'components/shared/auth/SessionRestore/SessionRestore';
import { NotifierContainer } from 'components/shared/messages/Notifier/NotifierContainer';
import { ConnectedRouter } from 'connected-react-router';
import { LocaleProvider } from 'context/locale';
import { getFetchClient } from 'fetch/client';
import React, { useEffect } from 'react';
import { ClientContextProvider } from 'react-fetching-library';
import { Provider } from 'react-redux';
import { ThroughProvider } from 'react-through';
import { updateWindowInfo } from 'store/general';
import { history } from 'store/history';
import { getConfiguredStore } from 'store/store';
import { osinTheme } from 'styles/themes/osinTheme';
import { getAppConfig } from 'utils/config/appConfig';
import { MainView } from 'views/Main.view';
import { LicenseManager } from 'ag-grid-enterprise';
import './App.scss';
import { debounce } from 'lodash';
import { InterfaceBlocker } from 'components/shared/loaders/InterfaceBlocker/InterfaceBlocker';
import { EnvironmentProvider } from 'components/shared/environment/EnvironmentProvider/EnvironmentProvider';

const App: React.FC = () => {
  LicenseManager.setLicenseKey(getAppConfig('OSIN20_AG_GRID_LICENSE'));

  const setViewportResolution = debounce(() => {
    getConfiguredStore().dispatch(updateWindowInfo(window.innerWidth, window.innerHeight));
  }, 500);

  useEffect(() => {
    if (window) {
      setViewportResolution();
      window.addEventListener('resize', setViewportResolution);
      return () => window.removeEventListener('resize', setViewportResolution);
    }
  }, [setViewportResolution]);

  const fetchClient = getFetchClient(getConfiguredStore());

  if (process.env.NODE_ENV === 'development') {
    const { worker } = require('./mocks/browser');
    worker.start();
  }

  return (
    <LocaleProvider locale="pl">
      <ThroughProvider>
        <Provider store={getConfiguredStore()}>
          <ClientContextProvider client={fetchClient}>
            <ConnectedRouter history={history}>
              <ThemeProvider theme={osinTheme}>
                <CssBaseline />
                <EnvironmentProvider>
                  <SessionRestore>
                    <NotifierContainer>
                      <InterfaceBlocker />
                      <MainView />
                    </NotifierContainer>
                  </SessionRestore>
                </EnvironmentProvider>
              </ThemeProvider>
            </ConnectedRouter>
          </ClientContextProvider>
        </Provider>
      </ThroughProvider>
    </LocaleProvider>
  );
};

export default App;

import { useStyles } from 'components/officials/Browser/Browser.styles';
import { BrowserProps } from 'components/officials/Browser/Browser.types';
import { officialsBrowserColumns } from 'components/officials/Browser/columns.config';
import { Toolbar } from 'components/officials/Browser/Toolbar/Toolbar';
import { DataGrid } from 'components/shared/data/DataGrid/DataGrid';
import { Confirm } from 'components/shared/dialogs/Confirm/Confirm';
import { NewPasswordModal } from 'components/shared/dialogs/NewPasswordModal/NewPasswordModal';
import { useDepartmentSettings } from 'components/shared/hooks/useInstanceConfig/useDepartmentSettings';
import React, { FC, useState } from 'react';
import { Official, BrowserColumnParams, OfficialAccountType } from 'store/officials';

export const Browser: FC<BrowserProps> = (props) => {
  const {
    onOfficialAdd,
    onOfficialEdit,
    onApplicantEdit,
    onOfficialDelete,
    onApplicantDelete,
    onOfficialResetPassword,
    onApplicantResetPassword,
    userList,
    isLoading,
    loggedUserInformations,
  } = props;
  const [officialToDelete, setOfficialToDelete] = useState<Official>(null);
  const [applicantToDelete, setApplicantToDelete] = useState<Official>(null);
  const [userToResetPassword, setUserToResetPassword] = useState<Official>(null);
  const [login, setLogin] = useState<string>('');
  const [startPassword, setStartPassword] = useState<string>('');
  const classes = useStyles();
  const settings = useDepartmentSettings();

  const isApplicant = (role) => role === OfficialAccountType.APPLICANT;

  const columnsParameters: BrowserColumnParams = {
    delete: (data: Official) => (isApplicant(data.role) ? setApplicantToDelete(data) : setOfficialToDelete(data)),
    edit: (data: Official) => (isApplicant(data.role) ? onApplicantEdit(data) : onOfficialEdit(data)),
    resetPassword: (data: Official) => setUserToResetPassword(data),
    institutionalCoreGrantEnabled: settings.institutionalCoreGrantEnabled,
    officialId: loggedUserInformations.id,
  };

  return (
    <div className={classes.container}>
      <Toolbar onOfficialAdd={onOfficialAdd} />
      <DataGrid
        columns={officialsBrowserColumns(columnsParameters)}
        data={userList}
        isLoading={isLoading}
        suppressScrollOnNewData={true}
        className={classes.grid}
      />
      <Confirm
        title={'OFFICIALS.DELETE.TITLE'}
        message={'OFFICIALS.DELETE.MESSAGE'}
        open={officialToDelete !== null}
        confirm={() => {
          onOfficialDelete(officialToDelete);
          setOfficialToDelete(null);
        }}
        cancel={() => setOfficialToDelete(null)}
      />
      <Confirm
        title={'OFFICIALS.DELETE.TITLE'}
        message={'OFFICIALS.DELETE.MESSAGE'}
        open={applicantToDelete !== null}
        confirm={() => {
          onApplicantDelete(applicantToDelete);
          setApplicantToDelete(null);
        }}
        cancel={() => setApplicantToDelete(null)}
      />
      <Confirm
        title={'OFFICIALS.RESET_PASSWORD.TITLE'}
        message={'OFFICIALS.RESET_PASSWORD.MESSAGE'}
        messageValues={
          userToResetPassword
            ? { firstName: userToResetPassword.firstName, lastName: userToResetPassword.lastName }
            : { firstName: '', lastName: '' }
        }
        open={userToResetPassword !== null}
        confirm={() => {
          const requestResetPassword =
            userToResetPassword.role === OfficialAccountType.APPLICANT
              ? onApplicantResetPassword
              : onOfficialResetPassword;
          requestResetPassword(userToResetPassword)
            .then((accessData) => {
              setLogin(accessData.login);
              setStartPassword(accessData.startPassword);
            })
            .catch(() => setUserToResetPassword(null));
        }}
        cancel={() => setUserToResetPassword(null)}
      />
      <NewPasswordModal
        title={'OFFICIALS.RESET_PASSWORD.TITLE'}
        message={'OFFICIALS.RESET_PASSWORD.MESSAGE_AFTER_RESET'}
        messageValues={
          userToResetPassword
            ? { firstName: userToResetPassword.firstName, lastName: userToResetPassword.lastName }
            : { firstName: '', lastName: '' }
        }
        login={login}
        startPassword={startPassword}
        open={login && !!startPassword}
        confirm={() => {
          setUserToResetPassword(null);
          setLogin('');
          setStartPassword('');
        }}
      />
    </div>
  );
};

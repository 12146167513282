import { AppState } from '../store';
import { DatabaseStatus } from './schoolDistricts.types';

export const isDatabaseListLoadingSelector = (state: AppState) => state.schoolDistricts.databases.loading;
export const isSchoolListLoadingSelector = (state: AppState) => state.schoolDistricts.schools.loading;
export const isAddressListLoadingSelector = (state: AppState) => state.schoolDistricts.addresses.loading;
export const isAddressRuleLoadingSelector = (state: AppState) => state.schoolDistricts.address.loading;

export const getDatabaseListSelector = (state: AppState) => state.schoolDistricts.databases.items;
export const getDistrictsSchoolListSelector = (state: AppState) => state.schoolDistricts.schools.items;
export const getDistrictsSchoolFiltersSelector = (state: AppState) => state.schoolDistricts.schools.filters;
export const getDistrictsAddressesListSelector = (state: AppState) => state.schoolDistricts.addresses.items;
export const getAddressRuleSelector = (state: AppState) => state.schoolDistricts.address.item;

export const getCurrentDatabaseNameSelector = (state: AppState) => {
  const currentDb = state.schoolDistricts.databases.items.find((item) => item.status === DatabaseStatus.ACTIVE);
  return currentDb ? currentDb.name : null;
};

export const getCurrentDatabaseIdSelector = (state: AppState) => {
  const currentDb = state.schoolDistricts.databases.items.find((item) => item.status === DatabaseStatus.ACTIVE);
  return currentDb ? currentDb.id : null;
};

export const getAssignmentDetailsSelector = (state: AppState) => state.schoolDistricts.assignmentDetails;

export const getCatchmentAssignments = (state: AppState) => state.schoolDistricts.assignments;

export const getChildrenSelector = (state: AppState) => state.schoolDistricts.children;

export const getAssignmentRequest = (state: AppState) => state.schoolDistricts.assignmentRequest;

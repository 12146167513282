import { YearsStatistic, BirthYearModel, SchoolYearModel } from '@typings/common';
import { DictionaryItem } from 'store/general';

export interface ObligationReport {
  id: string;
  createdAt: string;
  referenceDate: string;
  birthYears: BirthYearModel[];
  schoolYear: SchoolYearModel;
  totalObligated: number;
  totalFulfilling: number;
  status: ReportStatus;
}

export interface ObligationReportDetails {
  id: string;
  createdAt: string;
  birthYears: string[];
  counters: ReportCounter;
  date: string;
  schoolYear: string;
  status: ReportStatus;
  referenceDate: string;
}

export interface ReportCounter {
  name: ReportCounterName;
  nodes: ReportCounter[];
  years: YearsStatistic;
}

export interface FulfillmentOption {
  name: FulfillmentState;
  nodes: FulfillmentOption[];
}

export enum FulfillmentState {
  OBLIGATED = 'obligated',
  FULFILLING = 'fulfilling',
  SCHOOLING = 'schooling',
  EDUCATIONAL = 'educational',
  NOT_FULFILLING = 'not_fulfilling',
  MISSING_IDENTIFIER = 'missing_identifier',
  NOT_OBLIGATED = 'not_obligated',
}

export enum ReportCounterName {
  TOTAL = 'total',
  OBLIGATED = 'obligated',
  FULFILLING = 'fulfilling',
  SCHOOLING = 'schooling',
  EDUCATIONAL = 'educational',
  NOT_FULFILLING = 'not_fulfilling',
  MISSING_IDENTIFIER = 'missing_identifier',
  NOT_OBLIGATED = 'not_obligated',
}

export interface ObligationReportPostModel {
  referenceDate: string;
  birthYears: string[];
}
export type SchoolYear = string;

export interface ReportStatusModel {
  status: ReportStatus;
  progress: number;
}

export enum ReportStatus {
  SCHEDULED = 'scheduled',
  ASSIGNING_CHILDREN = 'assigning_children',
  ASSIGNING_STUDENT_REGISTER = 'assigning_student_register',
  READY = 'ready',
  PUBLISHED = 'published',
}

export interface ObligationReportUnit {
  id: string;
  name: string;
}

export interface ObligationReportInstitution {
  id: string;
  name: string;
  units: ObligationReportUnit[];
}

export interface ObligationReportChildrenListFilters {
  search?: string;
  birthYear?: BirthYearModel;
  obligated?: boolean;
  fullfilment?: string;
  units?: string[];
}

export interface ObligationReportChild {
  id: string;
  lastName: string;
  firstName: string;
  identificationNumber: string;
  birthYear: string;
  obligation: FulfillmentState;
  units: FulfillmentUnit[];
}

export interface FulfillmentUnit {
  id: string;
  studentRegisterDate: string;
  institutionName: string;
  unitName: string;
  educationStage: DictionaryItem;
  level: string;
}

export interface ReportBrowserFilter {
  schoolYear: string;
}

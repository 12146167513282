import { muiStyles } from 'utils/design';

export const useStyles = muiStyles((theme) => ({
  group: {
    marginBottom: theme.spacing(4),
  },
  groupHeader: {
    fontWeight: 'bolder',
    marginBottom: theme.spacing(1),
    textAlign: 'left',
    fontSize: '1.1em',
  },
}));

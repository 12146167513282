import React, { FC, useContext } from 'react';
import { InputText } from 'components/shared/form';
import { Heading } from 'components/shared/typography/Heading/Heading';
import { LocaleContext } from 'context/locale';
import { InfoOutlined } from '@material-ui/icons';

interface ContactPart {
  disableInputs: boolean;
}

export const ContactPart: FC<ContactPart> = ({ disableInputs }) => {
  const { trans } = useContext(LocaleContext);

  return (
    <div className="container-fluid mt-4 pb-3">
      <Heading type="h5">{trans('INSTITUTIONS.UNIT_DATA.CONTACT.TITLE')}</Heading>
      <div className="row mt-4">
        <div className="col-3">
          <InputText
            name="phone"
            labelText={trans('INSTITUTIONS.UNIT_DATA.CONTACT.PHONE')}
            disabled={disableInputs}
            hasIcon={disableInputs}
            icon={<InfoOutlined fontSize="small" />}
            tooltipText={trans('INSTITUTIONS.UNIT_DATA.GENERAL_TOOLTIPS.IMPORTED_FROM_SYNERGIA')}
          />
        </div>
        <div className="col-3">
          <InputText
            name="fax"
            labelText={trans('INSTITUTIONS.UNIT_DATA.CONTACT.FAX')}
            disabled={disableInputs}
            hasIcon={disableInputs}
            icon={<InfoOutlined fontSize="small" />}
            tooltipText={trans('INSTITUTIONS.UNIT_DATA.GENERAL_TOOLTIPS.IMPORTED_FROM_SYNERGIA')}
          />
        </div>
        <div className="col-3">
          <InputText
            name="email"
            labelText={trans('INSTITUTIONS.UNIT_DATA.CONTACT.EMAIL')}
            disabled={disableInputs}
            hasIcon={disableInputs}
            icon={<InfoOutlined fontSize="small" />}
            tooltipText={trans('INSTITUTIONS.UNIT_DATA.GENERAL_TOOLTIPS.IMPORTED_FROM_SYNERGIA')}
          />
        </div>
        <div className="col-3">
          <InputText
            name="www"
            labelText={trans('INSTITUTIONS.UNIT_DATA.CONTACT.WWW.LABEL')}
            disabled={disableInputs}
            tooltipText={
              disableInputs
                ? trans('INSTITUTIONS.UNIT_DATA.GENERAL_TOOLTIPS.IMPORTED_FROM_SYNERGIA')
                : trans('INSTITUTIONS.UNIT_DATA.CONTACT.WWW.TOOLTIP')
            }
            hasIcon={true}
            icon={<InfoOutlined fontSize="small" />}
          />
        </div>
      </div>
    </div>
  );
};

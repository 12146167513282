import { ROUTE_CHILD_REGISTER, ROUTE_CHILD_REGISTER_ADD, ROUTE_CHILD_REGISTER_EDIT } from 'config/routes';
import React, { FC, useContext, Fragment } from 'react';
import { Route, Switch } from 'react-router';
import { NotFoundView } from 'views/Errors/NotFound.view';
import { Breadcrumb } from 'components/shared/navigation/Breadcrumbs/Breadcrumb';
import { LocaleContext } from 'context/locale';
import { ChildRegisterList } from 'views/Panel/ChildRegister/ChildRegisterList/ChildRegisterList.view';
import { ChildRegisterAdd } from 'views/Panel/ChildRegister/ChildRegisterAdd/ChildRegisterAdd.view';
import { ChildRegisterEdit } from 'views/Panel/ChildRegister/ChildRegisterEdit/ChildRegisterEdit.view';

export const ChildRegisterView: FC = () => {
  const { trans } = useContext(LocaleContext);

  return (
    <Fragment>
      <Breadcrumb label={trans('BREADCRUMB.CHILD-REGISTER.CHILD-REGISTER')} to={ROUTE_CHILD_REGISTER} />

      <Switch>
        <Route exact path={ROUTE_CHILD_REGISTER_ADD} component={ChildRegisterAdd} />
        <Route exact path={ROUTE_CHILD_REGISTER_EDIT} component={ChildRegisterEdit} />
        <Route exact path={ROUTE_CHILD_REGISTER} component={ChildRegisterList} />
        <Route component={NotFoundView} />
      </Switch>
    </Fragment>
  );
};

import { Page } from 'components/layout/panel/Page/Page';
import { useTranslator } from 'components/shared/hooks';

import { Breadcrumb } from 'components/shared/navigation/Breadcrumbs/Breadcrumb';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import React, { FC } from 'react';
import { RouteChildrenProps } from 'react-router';

import { useStyles } from 'views/Panel/SchoolDistricts/CatchmentAssignmentList/CatchmentAssignmentListView.styles';
import { AssignmentBrowserContainer } from 'components/schoolDistricts/AssignmentBrowser/AssignmentBrowserContainer';

export const CatchmentAssignmentListView: FC<RouteChildrenProps<{}>> = () => {
  const trans = useTranslator();
  const classes = useStyles();

  return (
    <>
      <Breadcrumb label={trans('BREADCRUMB.SCHOOL-DISTRICTS.CATCHMENT_ASSIGNMENT_LIST')} static />

      <Page title={trans('SCHOOL_DISTRICTS.ASSIGMENT_BROWSER.TITLE')}>
        <Paper innerSpacing={2} outerSpacing={[0, 2, 2]} className={classes.paper}>
          <AssignmentBrowserContainer />
        </Paper>
      </Page>
    </>
  );
};

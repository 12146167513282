import classNames from 'classnames';
import { DetailsContainer } from 'components/institution/Unit/StudentsRegister/Details/DetailsContainer';
import { useTranslator } from 'components/shared/hooks';
import { InstitutionUnitEditRoute } from 'config/routes.types';
import React from 'react';
import { Page } from 'components/layout/panel/Page/Page';
import { Breadcrumb } from 'components/shared/navigation/Breadcrumbs/Breadcrumb';
import { Route, Switch } from 'react-router';
import { RFC } from '@typings/extra-react-router';
import { EditorEditContainer } from 'components/institution/Unit/Editor/EditorEditContainer';
import {
  ROUTE_INSTITUTION_UNIT_EDIT,
  ROUTE_INSTITUTIONS,
  ROUTE_STUDENTS_REGISTER_DETAILS,
  ROUTE_STUDENTS_REGISTER_ROLLBACK,
} from 'config/routes';
import { formatRoute } from 'react-router-named-routes';
import { useStyles } from 'views/Panel/Institutions/Unit/UnitEdit/UnitEdit.styles';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

interface State {
  fromDetails: any;
}

export const UnitEdit: RFC<InstitutionUnitEditRoute, {}, State> = (props) => {
  const trans = useTranslator();
  const dispatch = useDispatch();
  const id = props.match.params.id;
  const classes = useStyles();
  const state = props.location.state;

  const renderEditForm = () => {
    return (
      <EditorEditContainer
        id={id}
        goBack={() => dispatch(push(ROUTE_INSTITUTIONS))}
        fromStudentRegisterDetails={state && state.fromDetails}
      />
    );
  };

  const renderStudentsRegisterDetails = (props) => {
    const { id, registerId, date } = props.match.params;
    return (
      <>
        <Breadcrumb label={trans('BREADCRUMB.UNITS.STUDENT-REGISTER')} static />
        <DetailsContainer
          unitId={id}
          registerId={registerId}
          rollbackDate={date}
          onGoBack={() => dispatch(push(formatRoute(ROUTE_INSTITUTION_UNIT_EDIT, { id }), { fromDetails: true }))}
        />
      </>
    );
  };

  return (
    <Page title={trans('INSTITUTIONS.UNIT.TITLE.EDIT')}>
      <Breadcrumb label={trans('BREADCRUMB.UNITS.UNIT-EDIT')} to={formatRoute(ROUTE_INSTITUTION_UNIT_EDIT, { id })} />

      <div className={classNames('container-fluid', classes.container)}>
        <Switch>
          <Route exact path={ROUTE_INSTITUTION_UNIT_EDIT} component={renderEditForm} />
          <Route exact path={ROUTE_STUDENTS_REGISTER_DETAILS} component={renderStudentsRegisterDetails} />
          <Route exact path={ROUTE_STUDENTS_REGISTER_ROLLBACK} component={renderStudentsRegisterDetails} />
        </Switch>
      </div>
    </Page>
  );
};

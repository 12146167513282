import { ColumnsConfig, DefaultColumnConfig } from 'components/shared/data/DataGrid/DataGrid.types';
import { OtherCommuneStudentsStat } from 'store/reports/reports.types';

export const communeStudentsListColumns: ColumnsConfig<OtherCommuneStudentsStat> = () => [
  {
    field: 'firstName',
    name: 'REPORTS.COMMUNE_STUDENTS_LIST.COLUMNS.FIRST_NAME',
  },
  {
    field: 'lastName',
    name: 'REPORTS.COMMUNE_STUDENTS_LIST.COLUMNS.LAST_NAME',
  },
  {
    field: 'identificationNumber',
    name: 'REPORTS.COMMUNE_STUDENTS_LIST.COLUMNS.IDENTIFICATION_NUMBER',
  },
  {
    field: 'level',
    name: 'REPORTS.COMMUNE_STUDENTS_LIST.COLUMNS.LEVEL',
  },
  {
    field: 'city',
    name: 'REPORTS.COMMUNE_STUDENTS_LIST.COLUMNS.CITY',
  },
  {
    field: 'street',
    name: 'REPORTS.COMMUNE_STUDENTS_LIST.COLUMNS.STREET',
  },
  {
    field: 'buildingNumber',
    name: 'REPORTS.COMMUNE_STUDENTS_LIST.COLUMNS.BUILDING_NUMBER',
  },
  {
    field: 'flatNumber',
    name: 'REPORTS.COMMUNE_STUDENTS_LIST.COLUMNS.APARTMENT_NUMBER',
  },
];

export const defaultColumn: DefaultColumnConfig<any> = () => ({
  sortable: true,
  filter: true,
  suppressMenu: false,
  menuTabs: ['filterMenuTab'],
});

import { muiStyles } from 'utils/design';

interface SingleAnswerStylesProps {
  gridHeight: number;
  withChart: boolean;
}

export const useStyles = muiStyles<SingleAnswerStylesProps>((theme) => ({
  question: {
    margin: theme.spacing(0, 0, 1, 0),
  },
  row: {
    display: 'grid',
    gridTemplateAreas: ({ withChart }) => (withChart ? '"data chart"' : '"data"'),
    gridTemplateColumns: ({ withChart }) => (withChart ? 'auto 600px' : 'auto'),
    gridTemplateRows: '1fr',
    gridGap: '16px',
  },
  grid: {
    gridArea: 'data',
    height: ({ gridHeight }) => `${gridHeight}px !important`,
    '& .ag-overlay-wrapper': {
      paddingTop: '80px',
    },
  },
  chart: {
    gridArea: 'chart',
  },
}));

import { MiddlewareAPI } from 'redux';
import { isRSAA, RSAA, RSAAction } from 'redux-api-middleware';
import { AppAction, AppMiddleware, FSA } from '../../@typings/redux';
import { isObject } from 'lodash';
import { isFSA } from '../../utils/redux/isFSA';
import { lockScreen } from '../general/general.action';

const shouldMarkAction = (action: RSAAction<any>) => {
  return isObject(action[RSAA].types[0].meta) && action[RSAA].types[0].meta.lockScreen;
};

const isActionMarked = (action: FSA): action is FSA => {
  return action.meta && action.meta.lockScreen !== undefined;
};

const addTypeMeta = (type: FSA, lockScreen: boolean) => ({
  type: isObject(type) ? type.type : type,
  meta: {
    ...(isObject(type) ? type.meta : {}),
    lockScreen,
  },
});

const extendAction = ({ [RSAA]: apiCall }: RSAAction<any>) => ({
  [RSAA]: {
    ...apiCall,
    types: [
      addTypeMeta(apiCall.types[0], true),
      addTypeMeta(apiCall.types[1], false),
      addTypeMeta(apiCall.types[2], false),
    ] as [FSA, FSA, FSA],
  },
});

export const screenLockMarkerMiddleware: AppMiddleware = () => (next) => (action: AppAction) => {
  if (isRSAA(action) && shouldMarkAction(action)) {
    return next(extendAction(action));
  }

  return next(action);
};

export const screenLockMiddleware: AppMiddleware = (store: MiddlewareAPI) => (next) => (action: AppAction) => {
  if (isFSA(action) && isActionMarked(action)) {
    store.dispatch(lockScreen(action.meta.lockScreen));
  }

  return next(action);
};

import { Reports } from 'components/childRegister/Editor/Obligation/parts/Reports/Reports';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { obligationReportsSelector } from 'store/childRegister';
import { fetchObligationReports } from 'store/childRegister/childRegister.actions';
import { ReportsPropsContainer } from './Reports.types';

export const ReportsContainer: FC<ReportsPropsContainer> = (props) => {
  const dispatch = useDispatch();
  const { list, loading } = useSelector(obligationReportsSelector);

  useEffect(() => {
    if (props.childId) {
      dispatch(fetchObligationReports(props.childId));
    }
  }, [dispatch, props.childId]);

  return <Reports reports={list} loading={loading} />;
};

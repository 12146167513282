import { BirthYearModel, Majority, PageCollection } from '@typings/common';
import { AlertCount } from 'components/childRegister/Browser/Alerts/Alerts.types';
import {
  AddressHistoryModel,
  ChildModel,
  ChildRegisterFilters,
  EducationPathRecordModel,
  FileImportTypes,
  ImportContextModel,
  ImportPreviewModel,
  ImportPreviewStatus,
  ImportPreviewStatusModel,
  ObligationReportRecordModel,
  ObligationAction,
} from 'store/childRegister/childRegister.types';

export interface ChildRegisterState {
  list: {
    items: PageCollection<ChildModel>;
    total: number;
    alerts: AlertCount[];
    filters: ChildRegisterFilters;
    loading: boolean;
    error: any;
  };
  guardianTypes: {
    list: string[];
    customType: string[];
    loading: boolean;
    error: any;
  };
  alertTypes: {
    list: string[];
    loading: boolean;
    error: any;
  };
  birthYears: {
    list: BirthYearModel[];
    loading: boolean;
    error: any;
  };
  child: {
    loading: boolean;
    data: ChildModel | null;
  };
  addressHistory: {
    loading: boolean;
    list: AddressHistoryModel[];
  };
  educationPath: {
    loading: boolean;
    list: EducationPathRecordModel[];
  };
  obligationReports: {
    loading: boolean;
    list: ObligationReportRecordModel[];
  };
  obligationActions: {
    loading: boolean;
    list: ObligationAction[];
  };
  import: {
    file: File;
    importType: FileImportTypes;
    token: string;
    loading: boolean;
    columns: string[];
    context: ImportContextModel;
    preview: ImportPreviewModel;
    previewStatus: ImportPreviewStatusModel;
    error: string;
  };
}

export const childRegisterInitialFilters: ChildRegisterFilters = {
  search: '',
  majority: Majority.MINOR,
  isCatchmentChild: 'yes',
};

export const defaultState: ChildRegisterState = {
  list: {
    items: [],
    total: 0,
    alerts: [],
    filters: childRegisterInitialFilters,
    loading: false,
    error: null,
  },
  guardianTypes: {
    list: [],
    customType: [],
    loading: false,
    error: null,
  },
  alertTypes: {
    list: [],
    loading: false,
    error: null,
  },
  birthYears: {
    list: [],
    loading: false,
    error: null,
  },
  child: {
    loading: false,
    data: null,
  },
  addressHistory: {
    loading: false,
    list: [],
  },
  educationPath: {
    loading: false,
    list: [],
  },
  obligationReports: {
    loading: false,
    list: [],
  },
  obligationActions: {
    loading: false,
    list: [],
  },
  import: {
    file: null,
    importType: FileImportTypes.FULL,
    token: null,
    loading: false,
    columns: [],
    context: {
      dataSource: '',
      map: {
        firstName: '',
        secondName: '',
        lastName: '',
        sex: '',
        birthday: '',
        identificationNumber: '',
        addressStatus: '',
        birthCity: '',
        fatherFirstName: '',
        fatherLastName: '',
        motherFirstName: '',
        motherLastName: '',
        permanentResidenceVoivodeship: '',
        permanentResidenceCity: '',
        permanentResidenceCityTeryt: '',
        permanentResidenceStreet: '',
        permanentResidenceBuildingNumber: '',
        permanentResidenceFlatNumber: '',
        permanentResidencePostalCode: '',
        residenceVoivodeship: '',
        residenceCity: '',
        residenceCityTeryt: '',
        residenceStreet: '',
        residenceBuildingNumber: '',
        residenceFlatNumber: '',
        residencePostalCode: '',
      },
    },
    preview: {
      recordsPreview: [],
      recordsToImport: 0,
      recordsToCreate: 0,
      recordsToUpdate: 0,
      recordsToArchive: 0,
      currentRegisterSize: 0,
    },
    previewStatus: {
      progress: 0,
      status: ImportPreviewStatus.preparingPreview,
    },
    error: null,
  },
};

import { AppDispatch } from '@typings/redux';
import { useTranslator } from 'components/shared/hooks';
import { FullScreenLoader } from 'components/shared/loaders/FullScreenLoader/FullScreenLoader';
import { ErrorPage } from 'components/shared/surfaces/ErrorPage/ErrorPage';
import { ROUTE_AUTH_LOGIN } from 'config/routes';
import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEnvironmentSettings, getEnvironment } from 'store/general';
import { getCookie, removeCookie, setCookie } from 'utils/storage';
import { isPossibleOrigin, shouldSkipEnvironmentFetch } from './helpers';

const ENVIRONMENT_ORIGIN_COOKIE = 'osin20_environment_origin';

export const EnvironmentProvider = ({ children }) => {
  const { data } = useSelector(getEnvironment);
  const dispatch = useDispatch<AppDispatch>();
  const [isResolveSkipped, setResolveSkipped] = useState(false);
  const [isResolveDone, setResolveDone] = useState(false);
  const [isResolveFailed, setResolveFailed] = useState(false);
  const trans = useTranslator();

  const cookieEnvironmentOrigin = getCookie(ENVIRONMENT_ORIGIN_COOKIE);

  useEffect(() => {
    if (isResolveDone) {
      return;
    }

    if (shouldSkipEnvironmentFetch(window.location.pathname)) {
      setResolveSkipped(true);
      return;
    }

    (async () => {
      if (isPossibleOrigin(window.location.pathname)) {
        const hrefResponse = await dispatch(fetchEnvironmentSettings(window.location.href));

        if (!hrefResponse.error) {
          setCookie(ENVIRONMENT_ORIGIN_COOKIE, window.location.href);
          setResolveDone(true);
          dispatch(push(ROUTE_AUTH_LOGIN));
          return;
        }
      }

      if (!cookieEnvironmentOrigin) {
        setResolveDone(true);
        setResolveFailed(true);
        return;
      }

      const cookieResponse = await dispatch(fetchEnvironmentSettings(cookieEnvironmentOrigin));

      setResolveDone(true);

      if (cookieResponse.error) {
        setResolveFailed(false);
        removeCookie(ENVIRONMENT_ORIGIN_COOKIE);
      }
    })();
  }, [cookieEnvironmentOrigin, dispatch, isResolveDone]);

  if (isResolveDone && isResolveFailed) {
    return <ErrorPage>{trans('ERROR.NO_ENVIRONMENT')}</ErrorPage>;
  }

  if (!data && !isResolveSkipped) {
    return <FullScreenLoader />;
  }

  return children;
};

import React, { Component } from 'react';
import { LocaleContext } from 'context/locale/LocaleContext/LocaleContext';
import { LocaleContextProviderProps } from './LocaleContextProvider.types';

export class LocaleContextProvider extends Component<LocaleContextProviderProps> {
  public render() {
    const { children, intl, ...rest } = this.props;

    const value = {
      ...rest,
      formatMessage: intl.formatMessage,
      formatDate: intl.formatDate,
      formatNumber: intl.formatNumber,
      trans: (slug, values) => intl.formatMessage({ id: slug }, values),
    };

    return <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>;
  }
}

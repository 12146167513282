import React, { FC, useContext, Fragment } from 'react';
import { Route, Switch, Redirect } from 'react-router';

import { NotFoundView } from 'views/Errors/NotFound.view';
import { Breadcrumb } from 'components/shared/navigation/Breadcrumbs/Breadcrumb';

import {
  ROUTE_OTHER_COMMUNE,
  ROUTE_REPORTS,
  ROUTE_REPORTS_AUDIT_LOG,
  ROUTE_STATISTIC,
  ROUTE_STATISTIC_DETAILS,
  ROUTE_SUBVENTION_FORECAST,
  ROUTE_SYNCHRONIZATIONS_REPORT,
} from 'config/routes';

import { LocaleContext } from 'context/locale';
import { LogsView } from 'views/Panel/Reports/Logs/Logs.view';
import { SchoolStatView } from 'views/Panel/Reports/SchoolStat/SchoolStat';
import { SchoolStatDetailsView } from 'views/Panel/Reports/SchoolStatDetails/SchoolStatDetails';
import { OtherCommuneView } from 'views/Panel/Reports/OtherCommune/OtherCommune.view';
import { SubventionForecastView } from 'views/Panel/Reports/SubventionForecast/SubventionForecast.view';
import { SynchronizationsReportView } from 'views/Panel/Reports/Synchronizations/Synchronizations.view';

export const ReportsView: FC = () => {
  const { trans } = useContext(LocaleContext);

  return (
    <Fragment>
      <Breadcrumb label={trans('BREADCRUMB.REPORTS.REPORTS')} static />

      <Switch>
        <Redirect path={ROUTE_REPORTS} exact to={ROUTE_REPORTS_AUDIT_LOG} />
        <Route exact path={ROUTE_REPORTS_AUDIT_LOG} component={LogsView} />
        <Route exact path={ROUTE_STATISTIC} component={SchoolStatView} />
        <Route exact path={ROUTE_STATISTIC_DETAILS} component={SchoolStatDetailsView} />
        <Route exact path={ROUTE_OTHER_COMMUNE} component={OtherCommuneView} />
        <Route exact path={ROUTE_SUBVENTION_FORECAST} component={SubventionForecastView} />
        <Route exact path={ROUTE_SYNCHRONIZATIONS_REPORT} component={SynchronizationsReportView} />
        <Route component={NotFoundView} />
      </Switch>
    </Fragment>
  );
};

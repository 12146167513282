import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon.types';
import { AppDispatch } from '@typings/redux';
import { Environment } from 'store/general';
import { OfficialPermissionLevel, OfficialResourceGroup } from 'store/officials';
import { AppState } from 'store/store';

export interface NavigationListProps {
  menu: MenuItem[];
  useFloatingMenu?: boolean;
}

export interface SubmenuItem {
  path: string;
  label: string;
  resourcesGroups: OfficialResourceGroup[];
  accessType: OfficialPermissionLevel;
  callback?: (dispatch: AppDispatch) => void;
}

export interface MenuItem {
  path?: string;
  label: string;
  resourcesGroups: OfficialResourceGroup[];
  accessType: OfficialPermissionLevel;
  icon: FontAwesomeIcon;
  children?: SubmenuItem[];
  callback?: (dispatch: AppDispatch, environment: Environment) => void;
  hidden?: (state: AppState) => void;
}

export const isSubmenuItem = (item: MenuItem | SubmenuItem): item is SubmenuItem => {
  return !Object.prototype.hasOwnProperty.call(item, 'icon');
};

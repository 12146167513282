import { TextLoader } from 'components/shared/loaders/TextLoader/TextLoader';
import React, { useRef, FC, useEffect, useState } from 'react';
import { StepContent } from 'components/shared/dialogs/StepModal/components/StepContent';
import { Form, InputText } from 'components/shared/form';
import { StepActions, NextButton, BackButton } from 'components/shared/dialogs/StepModal';
import { DatePicker } from 'components/shared/form/DatePicker/DatePicker';
import { SettingsForm, SettingsProps } from './Settings.types';
import { useTranslator, useFormatter } from 'components/shared/hooks';
import { SettingsRow } from './SettingsRow';
import { FormikHelpers } from 'formik';
import { useStyles } from './Settings.styles';
import { Confirm } from 'components/shared/dialogs/Confirm/Confirm';
import { format } from 'date-fns';
import { SettingsContent } from 'components/shared/import/settings/SettingsContent';
import { getContextColumnsConfig } from 'components/institution/Unit/StudentsRegister/Import/steps/Settings/contex.config';

export const Settings: FC<SettingsProps> = ({
  initialValues,
  saveContext,
  fetchColumns,
  columns,
  goBack,
  goNext,
  isLoading,
}) => {
  const form = useRef(null);
  const trans = useTranslator();
  const { formatApiErrors } = useFormatter();
  const classes = useStyles();
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const [overrideDate, allowOverrideDate] = useState(false);
  const [collidingDate, setCollidingDate] = useState('');

  useEffect(() => {
    fetchColumns();
  }, [fetchColumns]);

  const handleSubmit = (values: SettingsForm, formik: FormikHelpers<SettingsForm>) => {
    saveContext({ ...values, allowOverwrite: overrideDate }).then((result) => {
      formik.setSubmitting(false);
      if (result.error) {
        const errors = result.payload.response.errors;
        const errorKeys = Object.values(errors);

        formik.setErrors(formatApiErrors(errors));

        if (errorKeys.length === 1 && errorKeys.includes('STUDENT_REGISTER.UPDATE_DATE.DATE_ALREADY_TAKEN')) {
          setCollidingDate(format(new Date(values.updateDate), 'dd-MM-yyyy'));
          setConfirmOpen(true);
        }
      } else {
        goNext();
      }
    });
  };

  const forceSubmit = () => {
    setConfirmOpen(false);
    allowOverrideDate(true);
    if (!form.current.isSubmitting) {
      form.current.handleSubmit();
    }
  };

  if (isLoading) {
    return <TextLoader text={trans('COMMON.LOADING_DATA')} />;
  }

  return (
    <>
      <StepContent>
        <div className="container pb-1 pt-1 text-center">
          <div className="row justify-content-center mt-3">
            <Form<SettingsForm>
              initialValues={initialValues}
              onSubmit={handleSubmit}
              innerRef={form}
              className={classes.form}
              enableReinitialize
            >
              {(props) => {
                const currentUsedColumns = Object.values(props.values.map);
                return (
                  <div className="container-fluid">
                    <SettingsRow label="INSTITUTIONS.STUDENTS_REGISTER.IMPORT_STEPS.SETTINGS.SOURCE">
                      <InputText name="dataSource" labelText="" placeholder={trans('COMMON.AUTOCOMPLETE.SELECT')} />
                    </SettingsRow>
                    <SettingsRow label="INSTITUTIONS.STUDENTS_REGISTER.IMPORT_STEPS.SETTINGS.DATE">
                      <DatePicker name="updateDate" labelText="" />
                    </SettingsRow>
                    <div className={classes.sectionHeader}>
                      {trans('INSTITUTIONS.STUDENTS_REGISTER.IMPORT_STEPS.SETTINGS.COLUMNS_IMPORT')}
                    </div>
                    <SettingsContent
                      columnsConfig={getContextColumnsConfig()}
                      columns={columns}
                      isLoading={isLoading}
                      currentUsedColumns={currentUsedColumns}
                    />
                  </div>
                );
              }}
            </Form>
          </div>
        </div>
      </StepContent>
      <StepActions>
        <BackButton onClick={goBack}>{trans('COMMON.PREVIOUS')}</BackButton>
        <NextButton onClick={() => !form.current.isSubmitting && form.current.handleSubmit()}>
          {trans('COMMON.NEXT')}
        </NextButton>
      </StepActions>

      <Confirm
        open={isConfirmOpen}
        cancel={() => setConfirmOpen(false)}
        title={'INSTITUTIONS.STUDENTS_REGISTER.IMPORT_STEPS.SETTINGS.DATE_OVERIDE_TITLE'}
        message={'INSTITUTIONS.STUDENTS_REGISTER.IMPORT_STEPS.SETTINGS.DATE_OVERIDE'}
        confirm={forceSubmit}
        messageValues={{ date: collidingDate }}
      />
    </>
  );
};

import { Authorization } from 'components/auth/Authorization/Authorization';
import { ROUTE_AUTH_LOGIN } from 'config/routes';
import { push } from 'connected-react-router';
import queryString from 'query-string';
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useStyles } from 'views/Auth/Authorize/Authorize.styles';

export const AuthorizeView: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const queryParams = queryString.parseUrl(window.location.href).query;
  const authCode = queryParams.code as string;

  useEffect(() => {
    if (authCode === undefined) {
      dispatch(push(ROUTE_AUTH_LOGIN));
    }
  }, [authCode, dispatch]);

  return <div className={classes.wrapper}>{authCode && <Authorization authCode={authCode} />}</div>;
};

import { RESET_STATE } from 'store/store';
import { FSAReducer, Behaviours } from '@typings/redux';
import { fromApiFormat } from 'utils/api';
import { ApplicantsState, defaultState } from './applicants.state';
import { ApplicantsActionType } from './applicants.actionTypes';

export const applicantsReducer: FSAReducer<ApplicantsState, ApplicantsActionType> = (state = defaultState, action) => {
  const behaviours: Behaviours<ApplicantsState, ApplicantsActionType> = {
    [RESET_STATE]: () => ({
      ...defaultState,
    }),
    [ApplicantsActionType.FETCH_APPLICANT_REQUEST]: () => ({
      ...state,
      details: {
        ...state.details,
        loading: true,
      },
    }),
    [ApplicantsActionType.FETCH_APPLICANT_SUCCESS]: ({ payload }) => ({
      ...state,
      details: {
        ...state.details,
        official: fromApiFormat(payload),
        loading: false,
      },
    }),
    [ApplicantsActionType.FETCH_APLICANT_FAILURE]: ({ error }) => ({
      ...state,
      details: {
        ...state.details,
        loading: false,
        error,
      },
    }),
  };

  return behaviours[action.type] ? behaviours[action.type](action) : state;
};

import { compact } from 'lodash';

import { ColumnsConfig } from 'components/shared/data/DataGrid/DataGrid.types';
import { ObligationReport } from 'store/schoolObligations';
import { ReportBrowserHandlers } from 'components/schoolObligation/ReportsBrowser/ReportsBrowser.types';
import { OfficialPermissionLevel, OfficialResourceGroup } from 'store/officials';

export const reportColumns: ColumnsConfig<ObligationReport, ReportBrowserHandlers> = (handlers) => [
  {
    field: 'referenceDate',
    name: 'SCHOOL_OBLIGATION.REPORTS.COLUMNS.DATE',
    columnParams: { minWidth: 100 },
    format: (data, trans, format) => {
      return format.formatDate(data.referenceDate);
    },
  },
  {
    field: 'schoolYear',
    name: 'SCHOOL_OBLIGATION.REPORTS.COLUMNS.SCHOOL_YEAR',
    columnParams: { minWidth: 100 },
  },
  {
    field: 'birthYears',
    name: 'SCHOOL_OBLIGATION.REPORTS.COLUMNS.BIRTH_YEARS',
    format: (data) => {
      const { birthYears } = data;
      return birthYears.join(', ');
    },
    columnParams: { minWidth: 100 },
  },
  {
    field: 'totalObligated',
    name: 'SCHOOL_OBLIGATION.REPORTS.COLUMNS.TOTAL_OBLIGATED',
    columnParams: { minWidth: 100 },
  },
  {
    field: 'totalFulfilling',
    name: 'SCHOOL_OBLIGATION.REPORTS.COLUMNS.TOTAL_FULLFILLING',
    columnParams: { minWidth: 100 },
  },
  {
    name: 'SCHOOL_OBLIGATION.REPORTS.COLUMNS.ACTIONS',
    cellClass: 'actions-cell',
    field: 'id',
    cellTooltip: () => '',
    renderer: 'buttonsCell',
    rendererParams: {
      buttons: (report: ObligationReport) => {
        return compact([
          {
            type: 'default',
            size: 'small',
            variant: 'outlined',
            icon: 'eye',
            tooltip: 'SCHOOL_OBLIGATION.REPORTS.ACTIONS.SELECT',
            label: 'SCHOOL_OBLIGATION.REPORTS.ACTIONS.SELECT',
            action: (data: ObligationReport) => handlers.show(data.id),
          },
          {
            type: 'default',
            size: 'small',
            variant: 'outlined',
            canShow: {
              only: false,
              resourcesGroup: OfficialResourceGroup.childRegister,
              accessType: OfficialPermissionLevel.fullAccess,
            },
            icon: 'trash',
            tooltip: 'SCHOOL_OBLIGATION.REPORTS.ACTIONS.DELETE',
            label: 'SCHOOL_OBLIGATION.REPORTS.ACTIONS.DELETE',
            action: () => handlers.delete(report),
          },
        ]);
      },
    },
    columnParams: { minWidth: 150 },
  },
];

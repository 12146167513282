import React, { FC, useEffect } from 'react';
import { AddressHistory } from 'components/childRegister/Editor/AddressHistory/AddressHistory';
import { AddressHistoryContainerProps } from 'components/childRegister/Editor/AddressHistory/AddressHistory.types';
import { fetchAddressHistory } from 'store/childRegister/childRegister.actions';
import { useDispatch, useSelector } from 'react-redux';
import { addressHistoryListSelector, addressHistoryLoadingSelector } from 'store/childRegister';

export const AddressHistoryContainer: FC<AddressHistoryContainerProps> = ({ open, onClose, id }) => {
  const dispatch: (...params: any) => Promise<any> = useDispatch();
  const addressHistory = useSelector(addressHistoryListSelector);
  const addressHistoryLoading = useSelector(addressHistoryLoadingSelector);

  useEffect(() => {
    if (open) {
      dispatch(fetchAddressHistory(id));
    }
  }, [dispatch, id, open]);

  return <AddressHistory open={open} onClose={onClose} data={addressHistory} loading={addressHistoryLoading} />;
};

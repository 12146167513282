export enum SystemSettingsActionType {
  FETCH_SYSTEM_SETTINGS_REQUEST = 'settings/FETCH_SYSTEM_SETTINGS_REQUEST',
  FETCH_SYSTEM_SETTINGS_SUCCESS = 'settings/FETCH_SYSTEM_SETTINGS_SUCCESS',
  FETCH_SYSTEM_SETTINGS_FAILURE = 'settings/FETCH_SYSTEM_SETTINGS_FAILURE',

  FETCH_POLLS_SETTINGS_REQUEST = 'settings/FETCH_POLLS_SETTINGS_REQUEST',
  FETCH_POLLS_SETTINGS_SUCCESS = 'settings/FETCH_POLLS_SETTINGS_SUCCESS',
  FETCH_POLLS_SETTINGS_FAILURE = 'settings/FETCH_POLLS_SETTINGS_FAILURE',

  FETCH_USER_SETTINGS_REQUEST = 'settings/FETCH_USER_SETTINGS_REQUEST',
  FETCH_USER_SETTINGS_SUCCESS = 'settings/FETCH_USER_SETTINGS_SUCCESS',
  FETCH_USER_SETTINGS_FAILURE = 'settings/FETCH_USER_SETTINGS_FAILURE',

  PUT_DEPARTMENT_SETTINGS_REQUEST = 'settings/PUT_DEPARTMENT_SETTINGS_REQUEST',
  PUT_DEPARTMENT_SETTINGS_SUCCESS = 'settings/PUT_DEPARTMENT_SETTINGS_SUCCESS',
  PUT_DEPARTMENT_SETTINGS_FAILURE = 'settings/PUT_DEPARTMENT_SETTINGS_FAILURE',

  PUT_POLL_SETTINGS_REQUEST = 'settings/PUT_POLL_SETTINGS_REQUEST',
  PUT_POLL_SETTINGS_SUCCESS = 'settings/PUT_POLL_SETTINGS_SUCCESS',
  PUT_POLL_SETTINGS_FAILURE = 'settings/PUT_POLL_SETTINGS_FAILURE',

  PUT_USER_SETTINGS_REQUEST = 'settings/PUT_USER_SETTINGS_REQUEST',
  PUT_USER_SETTINGS_SUCCESS = 'settings/PUT_USER_SETTINGS_SUCCESS',
  PUT_USER_SETTINGS_FAILURE = 'settings/PUT_USER_SETTINGS_FAILURE',
}

/*eslint complexity: ["error", 15]*/
import { isSingleSelection, OptionType } from 'components/shared/form/Autocomplete/Autocomplete.types';
import { useDepartmentSettings } from 'components/shared/hooks/useInstanceConfig/useDepartmentSettings';
import { streetListIncludes } from 'components/shared/teryt/TownPicker/TownPicker.helpers';
import { NO_TERYT_ITEM_ID } from 'components/shared/teryt/TownPicker/TownPickerContainer';
import React, { FC, Fragment, useContext, useState, useEffect } from 'react';
import { StreetFilters, StreetItem } from 'store/teryt';
import { AddressPickerProps } from './AddressPicker.types';
import { InputText } from 'components/shared/form';
import { LocaleContext } from 'context/locale';
import { Autocomplete } from 'components/shared/form/Autocomplete/Autocomplete';
import { Confirm } from 'components/shared/dialogs/Confirm/Confirm';
import { debounce, get } from 'lodash';
import { InfoOutlined } from '@material-ui/icons';
import { calculateSelectMode } from './AddressPicker.helpers';

export const AddressPicker: FC<AddressPickerProps> = ({
  fullAddress,
  fieldName,
  streetList,
  fetchStreetList,
  isStreetsListLoading,
  townFieldName,
  form,
  disabled,
  addressFieldName,
  osinLocked,
  inputWrapperClass = {},
  noOptionsMessage,
  clearStreetList,
  isCreatable = true,
  showNoOptionsMessage = false,
}) => {
  const [isAutocompleteOpen, setAutocompleteOpen] = useState(false);
  const [isNewStreetModalOpen, setNewStreetModal] = useState(false);
  const [newStreet, setNewStreet] = useState(null);
  const [streets, setStreets] = useState(null);
  const { trans } = useContext(LocaleContext);
  const { osinCommune, additionalCommunes, additionalCities } = useDepartmentSettings();
  const [isLoading, setIsLoading] = useState(isStreetsListLoading);
  const [hasFetched, setHasFetched] = useState(false);

  const { values, setFieldValue } = form;
  const townValue = get(values, townFieldName);
  const streetValue = get(values, fieldName, {});

  const [selectedStreet, setSelectedStreet] = useState<Partial<StreetItem>>(streetValue);

  useEffect(() => {
    const newStreets = [...streetList];

    if (!streetListIncludes(streetList, selectedStreet)) {
      if (selectedStreet && selectedStreet.name) {
        newStreets.push({
          value: selectedStreet.id,
          label: selectedStreet.name,
        });
      }
    }

    setStreets(newStreets);
  }, [selectedStreet, streetList, fieldName, form.errors]);

  const loadStreets = (inputValue, townId) => {
    setHasFetched(false);
    if (townId === null) {
      setHasFetched(true);
      return;
    }
    if (inputValue.length >= 3) {
      setIsLoading(true);
      const filters: StreetFilters = {
        search: inputValue,
        city: townId,
        cities: osinLocked ? additionalCities : undefined,
        communes: osinLocked ? [osinCommune, ...additionalCommunes] : undefined,
      };

      fetchStreetList(filters).then(() => {
        setIsLoading(false);
        setHasFetched(true);
      });
    }
  };

  const clearInput = () => {
    clearStreetList();
    setNewStreet(null);
    setSelectedStreet(null);
    form.setFieldValue(fieldName, {
      id: null,
      name: null,
    });
  };

  useEffect(() => {
    if (form.dirty && !townValue.name && townValue.id === NO_TERYT_ITEM_ID) {
      clearInput();
      setFieldValue(`${addressFieldName}.buildingNumber`, null);
      setFieldValue(`${addressFieldName}.apartmentNumber`, null);
      setFieldValue(`${addressFieldName}.postalCode`, null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [townValue]);

  const { addressPickerActive } = calculateSelectMode(townValue, townFieldName, disabled, osinLocked);

  const wrapperClassNames = {
    street: 'col-3',
    numbers: 'col-3',
    postalCode: 'col-3',
    ...inputWrapperClass,
  };

  const getTooltip = (disabled, active) => {
    if (disabled) return 'INSTITUTIONS.UNIT_DATA.GENERAL_TOOLTIPS.IMPORTED_FROM_SYNERGIA';
    if (!active) return 'INSTITUTIONS.UNIT_DATA.ADDRESS.DISABLED';
    return 'TERYT.STREET.CLEAR_INPUT';
  };

  const updateField = (id, name) => {
    setFieldValue(fieldName, {
      id,
      name,
    });
    setSelectedStreet({
      id,
      name,
    });
  };

  const handleDialogState = (id, name) => {
    updateField(id, name);
    setNewStreetModal(false);
  };

  const formatOptionLabel = (value, labelMeta) => {
    return value.value === NO_TERYT_ITEM_ID && labelMeta.context === 'menu'
      ? `${value.label} (spoza TERYT)`
      : value.label;
  };

  return (
    <div className="row">
      <Confirm
        open={isNewStreetModalOpen}
        cancel={() => handleDialogState(null, null)}
        confirm={() => handleDialogState(NO_TERYT_ITEM_ID, newStreet.label)}
        message="TERYT.STREET.NEW_STREET_MESSAGE"
      />
      <Fragment>
        <div className={wrapperClassNames.street}>
          <Autocomplete
            createable={isCreatable}
            newOptionMaxLength={30}
            name={`${fieldName}.id`}
            options={streets}
            placeholder={trans('TERYT.STREET.PLACEHOLDER')}
            selectedLabel="selectedLabel"
            onChange={(value: OptionType) => {
              if (value?.__isNew__) {
                setNewStreet(value);
                setNewStreetModal(true);
                return;
              }
              if (value && isSingleSelection(value)) {
                updateField(value.value, value.label);
              } else {
                clearInput();
              }
            }}
            onInputChange={debounce((value) => {
              loadStreets(value, townValue && townValue.id);
            }, 500)}
            menuIsOpen={isAutocompleteOpen}
            onMenuOpen={(ref) => {
              if (!streetValue) return;
              if (!isCreatable && ref.current.select.inputRef.value.length < 3) return;
              if (streetValue.name && isCreatable && (!townValue.country || townValue.country === 'PL')) {
                if (streetValue.id === NO_TERYT_ITEM_ID || (streetValue.id && get(form.errors, fieldName))) {
                  setAutocompleteOpen(true);
                  loadStreets(streetValue.name, townValue && townValue.id);
                  return;
                }
              }
              if (!streetValue.name && (streetValue.id === NO_TERYT_ITEM_ID || !streetValue.id)) {
                setAutocompleteOpen(true);
                return;
              }
            }}
            onMenuClose={() => setAutocompleteOpen(false)}
            isLoading={isLoading}
            alwaysShowPlaceholder={true}
            disabled={!addressPickerActive}
            hasIcon={!addressPickerActive}
            icon={<InfoOutlined fontSize="small" />}
            tooltipText={trans(getTooltip(disabled, addressPickerActive))}
            noOptionsMessage={noOptionsMessage}
            helpMessage={streetValue.name && streetValue.id === NO_TERYT_ITEM_ID ? trans('TERYT.STREET.NO_TERYT') : ''}
            isNewOptionValid={(value) => {
              return (
                streets &&
                hasFetched &&
                !isLoading &&
                (streets.length === 0 ||
                  !streets.some((street) => street.label.toLowerCase().includes(value.toLowerCase())))
              );
            }}
            showNoOptionsMessage={showNoOptionsMessage}
            formatOptionLabel={formatOptionLabel}
            preventScrollToSelectedOption={true}
          />
        </div>
        {fullAddress && (
          <Fragment>
            <div className={wrapperClassNames.numbers}>
              <InputText
                name={`${addressFieldName}.buildingNumber`}
                labelText={trans('TERYT.BUILDING.PLACEHOLDER')}
                disabled={!addressPickerActive}
                hasIcon={!addressPickerActive}
                icon={<InfoOutlined fontSize="small" />}
                tooltipText={trans(getTooltip(disabled, addressPickerActive))}
              />
            </div>
            <div className={wrapperClassNames.numbers}>
              <InputText
                name={`${addressFieldName}.apartmentNumber`}
                labelText={trans('TERYT.HOUSE.PLACEHOLDER')}
                disabled={!addressPickerActive}
                hasIcon={!addressPickerActive}
                icon={<InfoOutlined fontSize="small" />}
                tooltipText={trans(getTooltip(disabled, addressPickerActive))}
              />
            </div>
            <div className={wrapperClassNames.postalCode}>
              <InputText
                name={`${addressFieldName}.postalCode`}
                labelText={trans('TERYT.POSTAL_CODE.PLACEHOLDER')}
                disabled={!addressPickerActive}
                hasIcon={!addressPickerActive}
                icon={<InfoOutlined fontSize="small" />}
                tooltipText={trans(getTooltip(disabled, addressPickerActive))}
              />
            </div>
          </Fragment>
        )}
      </Fragment>
    </div>
  );
};

import { muiStyles } from 'utils/design';
import { colors } from 'styles/colors';

export const useStyles = muiStyles(() => ({
  form: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
  },
  paper: {
    flexGrow: 1,
    overflowY: 'scroll',
    height: 0,
    display: 'flex',
  },
  editor: {
    maxWidth: '1000px',
  },
  grid: {
    height: '300px',
    '@media (max-width: 1500px)': {
      '& .ag-header, & .ag-header-container, & .ag-header-cell-text': {
        whiteSpace: 'unset',
      },
    },
  },
  institutionRow: {
    background: `${colors.neutralVeryLight} !important`,
    fontWeight: 'bolder',
    '.ag-theme-material &.ag-row-hover': {
      background: colors.neutralLight,
    },
    '.ag-theme-material &.ag-checkbox': {
      display: 'none',
    },
  },
  unitRow: {
    '.ag-theme-material & > .ag-cell.indent-cell': {
      paddingLeft: '20px',
      paddingRight: 0,
    },
  },
}));

import { SystemFormValues } from 'components/settings/System/System.types';
import { useTranslator } from 'components/shared/hooks';
import { FormikProps } from 'formik';
import React from 'react';
import { useStyles } from 'components/settings/System/parts/General.styles';
import { Heading } from 'components/shared/typography/Heading/Heading';

interface GeneralProps {
  form: FormikProps<SystemFormValues>;
}

export const Communes = ({ form }: GeneralProps) => {
  const trans = useTranslator();
  const classes = useStyles();

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <Heading type="h6" className="font-weight-normal mb-1">
            {trans('SETTINGS.SYSTEM.FIELDS.DEPARTMENT_COMMUNES')}
          </Heading>
          <div className="ml-3">
            <Heading type="subtitle1" className="font-weight-bold">
              {trans('SETTINGS.SYSTEM.FIELDS.PRIMARY_COMMUNE')}
            </Heading>
            <ul className={classes.communesList}>
              <li>
                <Heading type="subtitle1">{form.initialValues.osinCommune}</Heading>
              </li>
            </ul>
            {form.initialValues.additionalCommunes.length > 0 && (
              <>
                <Heading type="subtitle1" className="font-weight-bold">
                  {trans('SETTINGS.SYSTEM.FIELDS.ADDITIONAL_COMMUNES')}
                </Heading>
                <ul className={classes.communesList}>
                  {form.initialValues.additionalCommunes.map((commune, index) => (
                    <li key={index}>
                      <Heading type="subtitle1">{commune}</Heading>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

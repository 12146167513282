import { AppDispatch } from '@typings/redux';
import { AssignmentBrowser } from 'components/schoolDistricts/AssignmentBrowser/AssignmentBrowser';
import { ROUTE_SCHOOL_CATCHMENT_ASSIGNMENT_PREVIEW } from 'config/routes';
import { push } from 'connected-react-router';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatRoute } from 'react-router-named-routes';
import {
  AssignmentStatus,
  deleteCatchmentAssignments,
  fetchCatchmentAssignments,
  getCatchmentAssignments,
  AssignmentModel,
} from 'store/schoolDistricts';
import { AssignmentModelBrowserRow } from './AssignmentBrowser.types';

export const AssignmentBrowserContainer: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const { items, loading } = useSelector(getCatchmentAssignments);

  useEffect(() => {
    dispatch(fetchCatchmentAssignments());
  }, [dispatch]);

  const onDelete = (id) => dispatch(deleteCatchmentAssignments(id));
  const fetchList = () => dispatch(fetchCatchmentAssignments());
  const redirectToPreview = (id) => dispatch(push(formatRoute(ROUTE_SCHOOL_CATCHMENT_ASSIGNMENT_PREVIEW, { id: id })));

  const publishedAssignments = () => {
    return items.filter(
      (item) =>
        item.status === AssignmentStatus.SAVED ||
        item.status === AssignmentStatus.OFFICIAL ||
        item.status === AssignmentStatus.ARCHIVAL,
    );
  };

  const addHeaders = (assignments: AssignmentModel[]): AssignmentModelBrowserRow[] => {
    const rows: AssignmentModelBrowserRow[] = [...assignments];
    const publishedNumber = rows.length;
    const officialNumber = assignments.filter((row) => row.status === AssignmentStatus.OFFICIAL).length;
    const offset = officialNumber > 0 ? 1 : 0;

    if (publishedNumber > 0 && officialNumber) {
      rows.unshift({
        title: 'ASSIGNMENT_BROWSER.SECTIONS.OFFICIAL',
      });
    }

    if (publishedNumber > officialNumber) {
      rows.splice(officialNumber + offset, 0, {
        title: 'ASSIGNMENT_BROWSER.SECTIONS.UNOFFICIAL',
      });
    }

    return rows;
  };

  const getSortedAssignments = () => {
    const publishedAssignmentsList = publishedAssignments();
    const sortedAssignmentsList = [];
    publishedAssignmentsList.forEach((assignment) => {
      assignment.status === AssignmentStatus.OFFICIAL
        ? sortedAssignmentsList.unshift(assignment)
        : sortedAssignmentsList.push(assignment);
    });
    return sortedAssignmentsList;
  };

  const publishedAssignmentsList = getSortedAssignments();

  return (
    <AssignmentBrowser
      assignments={addHeaders(publishedAssignmentsList)}
      dataLoading={loading}
      onDelete={onDelete}
      fetchList={fetchList}
      redirectToPreview={redirectToPreview}
    />
  );
};

import { Page } from 'components/layout/panel/Page/Page';
import { useTranslator } from 'components/shared/hooks';

import { Breadcrumb } from 'components/shared/navigation/Breadcrumbs/Breadcrumb';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import React, { FC } from 'react';
import { RouteChildrenProps } from 'react-router';

import { useStyles } from 'views/Panel/SchoolObligation/ReportList/ReportList.styles';
import { ReportsBrowserContainer } from 'components/schoolObligation/ReportsBrowser/ReportsBrowserContainer';

export const ReportListView: FC<RouteChildrenProps<{}>> = ({ location }) => {
  const trans = useTranslator();
  const classes = useStyles();

  return (
    <>
      <Breadcrumb label={trans('BREADCRUMB.SCHOOL_OBLIGATION.REPORTS')} static />

      <Page title={trans('SCHOOL_OBLIGATION.REPORTS.TITLE')}>
        <Paper innerSpacing={2} outerSpacing={[0, 2, 2]} className={classes.paper}>
          <ReportsBrowserContainer key={location.key} />
        </Paper>
      </Page>
    </>
  );
};

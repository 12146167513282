import { AppDispatch } from '@typings/redux';
import { AddressRuleModal } from 'components/schoolDistricts/DistrictsBrowser/AddressRuleModal/AddressRuleModal';
import { EditAddressRuleModalContainerProps } from 'components/schoolDistricts/DistrictsBrowser/AddressRuleModal/AddressRuleModal.types';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AddressRuleFormModel,
  fetchAddressRule,
  getAddressRuleSelector,
  isAddressRuleLoadingSelector,
  SchoolAddressModel,
} from 'store/schoolDistricts';

export const EditAddressRuleModalContainer: FC<EditAddressRuleModalContainerProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (props.open) {
      dispatch(fetchAddressRule(props.groupId, props.schoolId, props.ruleId));
    }
  }, [dispatch, props.groupId, props.ruleId, props.schoolId, props.open]);

  const isLoading = useSelector(isAddressRuleLoadingSelector);
  const address = useSelector(getAddressRuleSelector);

  const mapAddressToForm = (address: SchoolAddressModel): AddressRuleFormModel => ({
    rangeStart: address.rangeStart,
    rangeEnd: address.rangeEnd,
    rangeSelection: address.rangeSelection,
    included: address.included,
    excluded: address.excluded,
    street: { id: address.streetId },
  });

  return <AddressRuleModal {...props} values={address ? mapAddressToForm(address) : null} isLoading={isLoading} />;
};

import { useTranslator } from 'components/shared/hooks';
import { Breadcrumb } from 'components/shared/navigation/Breadcrumbs/Breadcrumb';
import { ROUTE_TEMPLATES, ROUTE_TEMPLATES_LIST } from 'config/routes';
import React, { FC, Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { NotFoundView } from 'views/Errors/NotFound.view';
import { TemplatesListView } from 'views/Panel/Templates/TemplatesList/TemplatesList.view';

export const TemplatesView: FC = () => {
  const trans = useTranslator();

  return (
    <Fragment>
      <Breadcrumb label={trans('BREADCRUMB.TEMPLATES.TEMPLATES')} static />

      <Switch>
        <Redirect path={ROUTE_TEMPLATES} exact to={ROUTE_TEMPLATES_LIST} />
        <Route exact path={ROUTE_TEMPLATES_LIST} component={TemplatesListView} />
        <Route component={NotFoundView} />
      </Switch>
    </Fragment>
  );
};

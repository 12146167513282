import { SchoolAddressRule } from 'components/schoolDistricts/AssignmentPreview/YearsRegister/YearsRegister.types';

export enum DatabaseStatus {
  PLANNED = 'planned',
  ACTIVE = 'active',
  ARCHIVAL = 'archival',
}

export enum SchoolRangeSelection {
  ALL = 'all',
  ODD = 'odd',
  EVEN = 'even',
}

export enum AssignmentStatus {
  NEW = 'new',
  ASSIGNING = 'assigning',
  DRAFT = 'draft',
  SAVED = 'published',
  ARCHIVAL = 'archival',
  OFFICIAL = 'official',
}

export interface DatabaseFormModel {
  name: string;
  isCloned: boolean;
  clone: string | null;
}

export interface DatabasePostModel {
  name: string;
  clone: string | null;
}

export interface DatabaseModel {
  id: string;
  name: string;
  status: DatabaseStatus;
}

export interface DatabaseModelHeaderModel {
  title: string;
}

export type DatabaseModelBrowserRow = DatabaseModel | DatabaseModelHeaderModel;

export function isDatabaseHeaderModel(item: DatabaseModelBrowserRow): item is DatabaseModelHeaderModel {
  return (item as DatabaseModelHeaderModel).title !== undefined;
}

export interface AddressRule {
  rangeStart?: number;
  rangeEnd?: number;
  rangeSelection: SchoolRangeSelection;
  included?: string;
  excluded?: string;
}

export interface AddressRuleConflict extends AddressRule {
  schoolId: string;
  schoolName: string;
}

export interface AddressRuleAlert {
  name: string;
  context: AddressRuleConflict[];
}

export interface SchoolAlert {
  name: string;
  school: BasicSchoolModel;
}

export interface BasicSchoolModel {
  id: string;
  name: string;
}

export interface SchoolModel extends BasicSchoolModel {
  alerts: SchoolAlert[];
}

export interface SchoolAddressModel extends AddressRule {
  id?: string;
  cityName: string;
  streetId: string;
  streetName: string;
  alerts: AddressRuleAlert[];
}

export interface AddressRuleFormModel extends AddressRule {
  street: {
    id?: string;
  };
}

export interface SchoolListFilters {
  alert: string;
  search: string;
}

export interface AssignmentSchoolModel {
  id: string;
  name: string;
  address: string;
  canTransfer: boolean;
  transferredAt: string | null;
  catchmentChildren: number;
  years: {
    [year: number]: number;
  };
  addressRules: SchoolAddressRule[];
}

export interface AssignmentAlert {
  name: ChildrenAlertsTypes;
  years: {
    [year: number]: number;
  };
}

export interface AssignmentModel {
  id: string;
  createdAt: string;
  catchmentAreaGroupName: string;
  assignmentName: string;
  catchmentAreaGroupStatus: DatabaseStatus;
  status: AssignmentStatus;
  withRules: number;
}

export interface AssignmentDetailsModel extends AssignmentModel {
  schools: AssignmentSchoolModel[];
  alerts: AssignmentAlert[];
}

export enum ChildrenAlertsTypes {
  MISSING_TERYT = 'ALERTS.CATCHMENT-ASSIGNMENT.CHILD.MISSING_TERYT',
  MISSING_ASSIGNMENT = 'ALERTS.CATCHMENT-ASSIGNMENT.CHILD.MISSING_SCHOOL',
}

export interface ChildModel {
  firstName: string;
  secondName: string;
  lastName: string;
  sex: 'male' | 'female';
  identificationNumber: string;
  birthDate: string;
  birthPlace: string;
  isQualified: boolean;
  usedAddress: 'permanent' | 'residence';
  permanentAddress: string;
  residenceAddress: string;
}

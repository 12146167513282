import { Theme } from '@material-ui/core';
import { muiStyles } from 'utils/design';

const OPEN_SIDEBAR_WIDTH = 300;

export const useStyles = muiStyles((theme: Theme) => ({
  drawer: {
    width: OPEN_SIDEBAR_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: OPEN_SIDEBAR_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
  },
  drawerPaper: {
    width: 300,
  },
  toolbar: {
    ...theme.mixins.toolbar,
    marginBottom: theme.spacing(1),
  },
}));

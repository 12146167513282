import { useTranslator } from 'components/shared/hooks';
import React, { FC, Fragment } from 'react';
import { Route, Switch, Redirect } from 'react-router';

import { NotFoundView } from 'views/Errors/NotFound.view';
import { Breadcrumb } from 'components/shared/navigation/Breadcrumbs/Breadcrumb';

import {
  ROUTE_SCHOOL_OBLIGATION,
  ROUTE_SCHOOL_OBLIGATION_REPORT_DETAILS,
  ROUTE_SCHOOL_OBLIGATION_REPORTS,
} from 'config/routes';
import { ReportDetailsView } from 'views/Panel/SchoolObligation/ReportDetails/ReportDetails.view';
import { ReportListView } from 'views/Panel/SchoolObligation/ReportList/ReportList.view';

export const SchoolObligationView: FC = () => {
  const trans = useTranslator();

  return (
    <Fragment>
      <Breadcrumb label={trans('BREADCRUMB.SCHOOL_OBLIGATION.OBLIGATION')} static />

      <Switch>
        <Redirect path={ROUTE_SCHOOL_OBLIGATION} exact to={ROUTE_SCHOOL_OBLIGATION_REPORTS} />
        <Route exact path={ROUTE_SCHOOL_OBLIGATION_REPORTS} component={ReportListView} />
        <Route path={ROUTE_SCHOOL_OBLIGATION_REPORT_DETAILS} component={ReportDetailsView} />
        <Route component={NotFoundView} />
      </Switch>
    </Fragment>
  );
};

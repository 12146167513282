import { connect } from 'formik';
import React, { ComponentType } from 'react';
import { DocumentModalForm } from 'components/shared/dialogs/DocumentModal/DocumentModal.types';
import { DocumentSettings } from './DocumentSettings';

export const DocumentSettingsContainer: ComponentType = connect<{}, DocumentModalForm>(({ formik }) => {
  const {
    values: { dcsExport },
  } = formik;

  return <DocumentSettings dcsExport={dcsExport} />;
});

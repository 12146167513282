import { AuthData } from '@typings/common';
import { getCookie, setCookie, removeCookie } from 'utils/storage';

export const AUTHORIZATION_TOKEN_COOKIE = 'osin20_sso_authorization';

export const getStoredToken = (): AuthData => {
  return getCookie(AUTHORIZATION_TOKEN_COOKIE);
};

export const storeToken = (token: AuthData) => {
  setCookie(AUTHORIZATION_TOKEN_COOKIE, JSON.stringify(token));
};

export const deleteStoredToken = () => {
  removeCookie(AUTHORIZATION_TOKEN_COOKIE);
};

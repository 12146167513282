import { FSA } from '@typings/redux';
import { limits } from 'config/pagination';
import { RSAA, RSAACreator } from 'redux-api-middleware';
import { ChildRegisterFilters } from 'store/childRegister';
import { ObligationReportChildrenListFilters } from 'store/schoolObligations';
import { TemplateOptions, TemplatesActionType as ActionType } from 'store/templates';
import { clean } from 'utils/object/clean';
import { omit, pickBy, identity } from 'lodash';

export const fetchTemplates: RSAACreator<ActionType> = () => {
  return {
    [RSAA]: {
      endpoint: `/templates`,
      method: 'GET',
      types: [
        ActionType.FETCH_TEMPLATES_LIST_REQUEST,
        ActionType.FETCH_TEMPLATES_LIST_SUCCESS,
        ActionType.FETCH_TEMPLATES_LIST_FAILURE,
      ],
      params: {},
    },
  };
};

export const fetchChildRegisterPreview: RSAACreator<ActionType> = (
  templateId: string,
  filters: ChildRegisterFilters,
  alert: string,
  page: number,
) => {
  return {
    [RSAA]: {
      endpoint: `/templates/child-register/${templateId}/preview`,
      method: 'GET',
      types: [
        ActionType.FETCH_DOCUMENTS_PREVIEW_REQUEST,
        {
          type: ActionType.FETCH_DOCUMENTS_PREVIEW_SUCCESS,
          meta: { page },
        },
        ActionType.FETCH_DOCUMENTS_PREVIEW_FAILURE,
      ],
      params: {
        search: filters.search,
        filter: {
          ...clean(omit(filters, ['search'])),
        },
        alert,
        page,
        limit: limits.childRegisterDocumentsPreview,
      },
    },
  };
};

export const fetchObligationReportPreview: RSAACreator<ActionType> = (
  templateId: string,
  alert: string,
  filters: ObligationReportChildrenListFilters,
  reportId: string,
  page: number,
) => {
  return {
    [RSAA]: {
      endpoint: `/templates/obligation-report/${templateId}/preview`,
      method: 'GET',
      types: [
        ActionType.FETCH_DOCUMENTS_PREVIEW_REQUEST,
        {
          type: ActionType.FETCH_DOCUMENTS_PREVIEW_SUCCESS,
          meta: { page },
        },
        ActionType.FETCH_DOCUMENTS_PREVIEW_FAILURE,
      ],
      params: {
        search: filters.search,
        filter: pickBy(omit(filters, 'search'), identity),
        obligationReportId: reportId,
        alert,
        page,
        limit: limits.childRegisterDocumentsPreview,
      },
    },
  };
};

export const clearDocumentsPreviewData = (clearAlerts = false): FSA<ActionType> => {
  return {
    type: ActionType.CLEAR_DOCUMENTS_PREVIEW_DATA,
    payload: { clearAlerts },
  };
};

export const createChildRegisterDocuments: RSAACreator<ActionType> = (templateId: string, options: TemplateOptions) => {
  return {
    [RSAA]: {
      endpoint: `/templates/child-register/${templateId}`,
      method: 'POST',
      types: [
        ActionType.CREATE_DOCUMENTS_REQUEST,
        ActionType.CREATE_DOCUMENTS_SUCCESS,
        ActionType.CREATE_DOCUMENTS_FAILURE,
      ],
      body: { ...options },
    },
  };
};

export const fetchDocumentsStatus: RSAACreator<ActionType> = (documentId: string) => {
  return {
    [RSAA]: {
      endpoint: `/templates/documents/${documentId}/status`,
      method: 'GET',
      types: [
        ActionType.FETCH_DOCUMENTS_STATUS_REQUEST,
        ActionType.FETCH_DOCUMENTS_STATUS_SUCCESS,
        ActionType.FETCH_DOCUMENTS_STATUS_FAILURE,
      ],
      params: {},
    },
  };
};

export const clearDocumentRequest = (): FSA<ActionType> => {
  return {
    type: ActionType.CLEAR_DOCUMENT_REQUEST,
  };
};

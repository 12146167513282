import { AuditLogProps } from 'components/reports/AuditLog/AudiLog.types';
import { auditLogColumns } from 'components/reports/AuditLog/columns';
import { DataGrid } from 'components/shared/data/DataGrid/DataGrid';
import { limits } from 'config/pagination';
import React, { FC } from 'react';
import { AuditLog as AuditLogModel } from 'store/reports/reports.types';

export const AuditLog: FC<AuditLogProps> = (props) => {
  return (
    <DataGrid<AuditLogModel>
      columns={auditLogColumns()}
      onDataProvide={props.onLogsProvide}
      onDataFetch={props.onLogsFetch}
      total={props.totalLogs}
      limit={limits.auditLog}
    />
  );
};

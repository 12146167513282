import { useCallback } from 'react';

export const useFocusCell = (gridApi) => {
  return useCallback(
    (row, column) => {
      const columnModel = gridApi.columnController.columnApi.getAllDisplayedColumns()[column];
      gridApi.ensureIndexVisible(row);
      gridApi.ensureColumnVisible(columnModel);
      gridApi.setFocusedCell(0, columnModel);
    },
    [gridApi],
  );
};

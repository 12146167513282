import React, { FC } from 'react';
import { Button } from 'components/shared/buttons/Button/Button';
import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon';
import { useTranslator } from 'components/shared/hooks/useTranslator/useTranslator';
import { ToolbarProps } from './Toolbar.types';
import { useStyles } from './Toolbar.styles';
import Access from 'components/shared/access/access';
import { OfficialPermissionLevel, OfficialResourceGroup } from 'store/officials';

export const Toolbar: FC<ToolbarProps> = (props) => {
  const trans = useTranslator();
  const classes = useStyles();

  return (
    <div className={classes.toolbar}>
      <Access resourcesGroup={OfficialResourceGroup.childRegister} accessType={OfficialPermissionLevel.fullAccess}>
        <Button variant="contained" color="primary" className="float-right" onClick={props.addItem} tabIndex={0}>
          <FontAwesomeIcon name={'plus-circle'} className={'mr-2'} />
          {trans('SCHOOL_DISTRICTS.DATABASE.ACTIONS.ADD')}
        </Button>
      </Access>
      <Access resourcesGroup={OfficialResourceGroup.childRegister} accessType={OfficialPermissionLevel.fullAccess}>
        <Button
          variant="contained"
          color="primary"
          onClick={props.onDivideChildren}
          disabled={props.emptyDatabaseList}
          tabIndex={0}
          className="float-right"
          style={{
            marginRight: '8px',
          }}
        >
          <FontAwesomeIcon name={'divide'} className="mr-2" />
          {trans('SCHOOL_DISTRICTS.DISTRICTS-LIST.DIVIDE-CHILDREN')}
        </Button>
      </Access>
    </div>
  );
};

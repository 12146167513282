import React, { useContext } from 'react';
import { Page } from 'components/layout/panel/Page/Page';
import { Breadcrumb } from 'components/shared/navigation/Breadcrumbs/Breadcrumb';
import { LocaleContext } from 'context/locale';
import { EditContainer } from 'components/institution/Edit/EditContainer';
import { RFC } from '@typings/extra-react-router';
import { formatRoute } from 'react-router-named-routes';
import { ROUTE_INSTITUTION_EDIT } from 'config/routes';

export const InstitutionEdit: RFC<{ id: string }> = (props) => {
  const { trans } = useContext(LocaleContext);

  const institutionId = props.match.params.id;

  return (
    <Page title={trans('INSTITUTIONS.EDIT.HEADER')}>
      <Breadcrumb
        label={trans('BREADCRUMB.UNITS.INSTITUTION-EDIT')}
        to={formatRoute(ROUTE_INSTITUTION_EDIT, { id: institutionId })}
      />
      <EditContainer institutionId={institutionId} goBack={props.history.goBack} />
    </Page>
  );
};

import { Action } from 'redux';
import { fork, ForkEffect, put, PutEffect, select, SelectEffect, takeEvery } from 'redux-saga/effects';
import { last } from 'lodash';
import { FSA } from '@typings/redux';
import { AppState } from '../store';
import { ReportsActionType } from 'store/reports/reports.actionTypes';
import { fetchSynchronizationLog, setSynchronizationLogLastItem } from 'store/reports/reports.action';
import { SynchronizationLog } from 'store/reports/reports.types';

const selectLastFetchedItem = (state: AppState) => state.reports.syncLog.lastFetchedItem;

function* fetchSyncLog(action): IterableIterator<SelectEffect | PutEffect> {
  const lastItem = selectLastFetchedItem(yield select());
  yield put(
    fetchSynchronizationLog(action.payload.page, {
      ...action.payload.filters,
      beforeId: lastItem?.id,
      iteration: lastItem?.iteration,
    }) as unknown as Action,
  );
}

function* setLastFetchedItem(action: FSA): IterableIterator<SelectEffect | PutEffect> {
  if (action.payload.items.length > 0) {
    const lastSyncLog = last(action.payload.items) as SynchronizationLog;
    yield put(setSynchronizationLogLastItem(lastSyncLog) as unknown as Action);
  }
}

export function* reportSaga(): Iterator<ForkEffect> {
  yield fork(function* () {
    yield takeEvery(ReportsActionType.GET_SYNCHRONIZATION_LOG, fetchSyncLog);
    yield takeEvery(ReportsActionType.FETCH_SYNCHRONIZATION_LOG_SUCCESS, setLastFetchedItem);
  });
}

import { useCallback, useState } from 'react';

interface HandlerState {
  callback: (...args: any[]) => any;
}

export const useCancellableCallback = () => {
  const [handler, setHandler] = useState<HandlerState>({ callback: null });

  const wrappedHandler = useCallback(
    (...args) => {
      return handler.callback ? handler.callback(...args) : null;
    },
    [handler],
  );

  const setHandlerToWrap = useCallback(
    (callback: HandlerState['callback']) => {
      setHandler({ callback });
    },
    [setHandler],
  );

  const cancelHandler = useCallback(() => {
    setHandler({ callback: null });
  }, [setHandler]);

  return [wrappedHandler, setHandlerToWrap, cancelHandler];
};

import React, { FC, useState } from 'react';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import { ExemptionsProps } from 'components/childRegister/Editor/Obligation/Obligation.types';
import {
  emptyExemption,
  schoolYearOptions,
} from 'components/childRegister/Editor/Obligation/parts/Exemptions/Exemptions.helpers';
import { useStyles } from 'components/childRegister/Editor/Obligation/parts/Exemptions/Exemptions.styles';
import { Button } from 'components/shared/buttons/Button/Button';
import { InputText, SelectBox } from 'components/shared/form';
import { FieldArray } from 'components/shared/form/FieldArray/FieldArray';
import { useTranslator } from 'components/shared/hooks';
import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import { Splash } from 'components/shared/surfaces/Splash/Splash';
import { Heading } from 'components/shared/typography/Heading/Heading';
import { ExemptionModel } from 'store/childRegister';

export const Exemptions: FC<ExemptionsProps> = (props) => {
  const trans = useTranslator();
  const classes = useStyles();
  const someFilledObligationExemption = props.exemptions.some((exemption) => exemption.schoolYear);
  const [showObligationExemptions, setShowObligationExpemptions] = useState(someFilledObligationExemption);
  return (
    <Box>
      <Box paddingBottom={2} display="flex" alignItems="center" justifyContent="space-between">
        <Heading type="h5">{trans('CHILD_REGISTER.OBLIGATION.EXEMPTIONS.TITLE')}</Heading>
        <Button
          onClick={() => {
            setShowObligationExpemptions((prevState) => !prevState);
          }}
          color="primary"
          variant="contained"
          className="mt-2"
        >
          {trans('CHILD_REGISTER.OBLIGATION.EXEMPTIONS.ADD_EXEMPTION')}
        </Button>
      </Box>
      <Collapse in={showObligationExemptions}>
        <FieldArray name="exemptions">
          {({ form, push, remove }) => {
            return (
              <>
                <div>
                  {props.schoolYears.length > 0 && form.values.exemptions.length === 0 && (
                    <Splash height={100}>
                      <Paper innerSpacing={2} className={'no-rows-overlay'}>
                        <FontAwesomeIcon name={'info-circle'} className="mr-2" />
                        {trans('CHILD_REGISTER.OBLIGATION.EXEMPTIONS.NO_EXEMPTIONS')}
                      </Paper>
                    </Splash>
                  )}

                  {props.schoolYears.length === 0 && form.values.exemptions.length === 0 && (
                    <Splash height={100}>
                      <Paper innerSpacing={2} className={'no-rows-overlay'}>
                        <FontAwesomeIcon name={'info-circle'} className="mr-2" />
                        {trans('CHILD_REGISTER.OBLIGATION.EXEMPTIONS.CANT_ADD_EXEMPTIONS')}
                      </Paper>
                    </Splash>
                  )}

                  {props.schoolYears.length === 0 && form.values.exemptions.length > 0 && (
                    <Splash height={100}>
                      <Paper innerSpacing={2} className={'no-rows-overlay'}>
                        <FontAwesomeIcon name={'info-circle'} className="mr-2" />
                        {trans('CHILD_REGISTER.OBLIGATION.EXEMPTIONS.REMOVE_INVALID_EXEMPTIONS')}
                      </Paper>
                    </Splash>
                  )}

                  {form.values.exemptions.map((exemption: ExemptionModel, index) => {
                    return (
                      <div key={`exemption_${index}`} className={classes.row}>
                        <div className={classes.rowYear}>
                          <SelectBox
                            name={`exemptions.${index}.schoolYear`}
                            placeholderText={trans('CHILD_REGISTER.OBLIGATION.EXEMPTIONS.FIELDS.SCHOOL_YEAR')}
                            labelText={trans('CHILD_REGISTER.OBLIGATION.EXEMPTIONS.FIELDS.SCHOOL_YEAR')}
                            options={schoolYearOptions(props.schoolYears, form.values.exemptions, index)}
                          />
                        </div>
                        <div className={classes.rowDescription}>
                          <InputText
                            name={`exemptions.${index}.description`}
                            labelText={trans('CHILD_REGISTER.OBLIGATION.EXEMPTIONS.FIELDS.DESCRIPTION')}
                          />
                        </div>
                        <div className={classes.rowButton}>
                          <Button onClick={() => remove(index)} color="primary" variant="outlined">
                            {trans('CHILD_REGISTER.OBLIGATION.EXEMPTIONS.REMOVE_EXEMPTION')}
                          </Button>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div>
                  <Button
                    onClick={() => push(emptyExemption())}
                    disabled={form.values.exemptions.length >= props.schoolYears.length}
                    color="primary"
                    variant="outlined"
                    className="mt-2"
                  >
                    {trans('CHILD_REGISTER.OBLIGATION.EXEMPTIONS.ADD_ANOTHER')}
                  </Button>
                </div>
              </>
            );
          }}
        </FieldArray>
      </Collapse>
    </Box>
  );
};

import { RFC } from '@typings/extra-react-router';
import { Page } from 'components/layout/panel/Page/Page';
import { EditContainer } from 'components/polls/Editor/EditContainer';
import { useTranslator } from 'components/shared/hooks';
import { Breadcrumb } from 'components/shared/navigation/Breadcrumbs/Breadcrumb';
import { Box } from 'components/shared/surfaces/Box/Box';
import { ROUTE_POLLS_LIST } from 'config/routes';
import { PollActionRoute } from 'config/routes.types';
import React, { Fragment } from 'react';
import { useStyles } from 'views/Panel/Polls/PollsEditor/PollEditor.styles';

export const EditPollView: RFC<PollActionRoute> = ({ match }) => {
  const trans = useTranslator();
  const classes = useStyles();

  return (
    <Fragment>
      <Breadcrumb label={trans('BREADCRUMB.POLLS.POLLS_LIST')} to={ROUTE_POLLS_LIST} />
      <Breadcrumb label={trans('BREADCRUMB.POLLS.POLL_EDIT')} static />

      <Page title={trans('POLLS.EDITOR.EDITOR_EDIT')}>
        <Box margin={[0, 2]} className={classes.box}>
          <EditContainer pollId={match.params.id} />
        </Box>
      </Page>
    </Fragment>
  );
};

import { ICellRendererParams } from 'ag-grid-community';
import { OptionType } from 'components/shared/form/Autocomplete/Autocomplete.types';

export enum ForecastAction {
  MODIFY = 'modify',
  COPY = 'copy',
  FORECAST = 'forecast',
}

export interface ForecastManipulatorProps extends ICellRendererParams {
  hide: (data: any) => boolean;
  options: OptionType[];
  onChange: (data: any, value: ManipulatorConfig, gridApi: any) => void;
  reactContainer: HTMLDivElement; //fix
}

export interface ManipulatorConfig {
  action: ForecastAction;
  actionValue: string;
}

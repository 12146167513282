import { useStyles } from 'components/shared/data/Summary/Summary.styles';
import {
  SummaryItemComponentProps,
  SummaryItemProps,
  SummaryProps,
} from 'components/shared/data/Summary/Summary.types';
import React, { FC } from 'react';

const { Provider, Consumer } = React.createContext<SummaryProps>({ linear: false });

const SummaryItemComponent: FC<SummaryItemComponentProps> = ({ title, linear, children }) => {
  const classes = useStyles({ linear });

  return (
    <div className={classes.row}>
      <div className={classes.label}>
        {title}
        {linear ? ':' : ''}&nbsp;
      </div>
      <div className={classes.value}>{children}</div>
    </div>
  );
};

export const SummaryItem: FC<SummaryItemProps> = (props) => {
  return <Consumer>{(params) => <SummaryItemComponent {...props} linear={params.linear} />}</Consumer>;
};

export const Summary: FC<SummaryProps> = ({ children, linear }) => {
  const classes = useStyles({ linear });

  return (
    <Provider value={{ linear }}>
      <div className={classes.container}>{children}</div>
    </Provider>
  );
};

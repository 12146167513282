import { LoadingRecordsProps } from 'components/shared/data/DataGrid/renderers/cell/LoadingRecords/LoadingRecords.types';
import { useTranslator } from 'components/shared/hooks';
import { Splash } from 'components/shared/surfaces/Splash/Splash';
import React, { FC } from 'react';

export const LoadingRecordsRenderer: FC<LoadingRecordsProps> = () => {
  const trans = useTranslator();

  return <Splash height={48}>{trans('COMMON.LOADING_DATA')}</Splash>;
};

import React from 'react';
import { SystemProps } from 'components/settings/System/System.types';
import { General } from 'components/settings/System/parts/General';
import { Communes } from 'components/settings/System/parts/Communes';
import { Integrations } from 'components/settings/System/parts/Integrations';

export const System = ({ form }: SystemProps) => (
  <>
    <General form={form} />
    <Communes form={form} />
    <Integrations form={form} />
  </>
);

import { Results } from 'components/polls/Results/Results';
import { ResultsContainerProps } from 'components/polls/Results/Results.types';
import { ROUTE_POLLS } from 'config/routes';
import { push } from 'connected-react-router';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPollRecipients, fetchPollResults, pollRecipientsSelector, pollResultsSelector } from 'store/polls';
import { makePath } from 'utils/navigation/makePath';

export const ResultsContainer: FC<ResultsContainerProps> = (props) => {
  const dispatch = useDispatch();
  const { results, loading: resultsLoading } = useSelector(pollResultsSelector);
  const { list: recipients, loading: recipientsLoading } = useSelector(pollRecipientsSelector);

  useEffect(() => {
    dispatch(fetchPollResults(props.pollId));
  }, [dispatch, props.pollId]);

  useEffect(() => {
    dispatch(fetchPollRecipients(props.pollId));
  }, [dispatch, props.pollId]);

  const onBack = () => {
    dispatch(push(makePath(ROUTE_POLLS)));
  };

  return (
    <Results
      pollId={props.pollId}
      results={results}
      recipients={recipients}
      loading={resultsLoading || recipientsLoading}
      onBack={onBack}
    >
      {props.children}
    </Results>
  );
};

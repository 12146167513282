import React, { FC, useCallback, useContext } from 'react';
import { Preview } from './Preview';
import { PreviewStepProps } from 'components/shared/dialogs/StepModal/StepModal.types';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearStudentRegisterInformation,
  fetchStudentRegisterList,
  fetchStudentRegisterPreview,
  getImportPreview,
  getImportToken,
  isImportLoading,
  postStudentRegisterConfirm,
} from 'store/institutions';
import { __RouterContext } from 'react-router';

export const PreviewContainer: FC<PreviewStepProps> = (props) => {
  const dispatch: (...params: any) => Promise<any> = useDispatch();
  const importToken = useSelector(getImportToken);
  const router = useContext(__RouterContext);
  const id = (router.match.params as { id: string }).id;
  const importPreview = useSelector(getImportPreview);
  const isPreviewLoading = useSelector(isImportLoading);

  const fetchPreview = useCallback(() => {
    return dispatch(fetchStudentRegisterPreview(importToken, id));
  }, [dispatch, importToken, id]);

  const postConfirm = useCallback(() => {
    return dispatch(postStudentRegisterConfirm(importToken, id));
  }, [dispatch, importToken, id]);

  const redirectToList = () => {
    dispatch(fetchStudentRegisterList(id));
    dispatch(clearStudentRegisterInformation());
    props.onClose(true);
  };

  return (
    <Preview
      goBack={props.goBack}
      previewRecords={importPreview.recordsPreview}
      loading={isPreviewLoading}
      totalNumber={importPreview.totalNumber}
      identificationNumberNotPesel={importPreview.identificationNumberNotPesel}
      fetchStudentRegisterPreview={fetchPreview}
      postStudentRegisterConfirm={postConfirm}
      redirectToList={redirectToList}
      columns={props.columns}
    />
  );
};

import { Page } from 'components/layout/panel/Page/Page';
import { BrowserContainer } from 'components/templates/Browser/BrowserContainer';
import { useTranslator } from 'components/shared/hooks';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import React, { FC, Fragment } from 'react';
import { useStyles } from 'views/Panel/Templates/TemplatesList/TemplatesList.styles';

export const TemplatesListView: FC = () => {
  const trans = useTranslator();
  const classes = useStyles();

  return (
    <Fragment>
      <Page title={trans('TEMPLATES.BROWSER.TITLE')}>
        <Paper innerSpacing={2} outerSpacing={[0, 2, 2]} className={classes.paper}>
          <BrowserContainer />
        </Paper>
      </Page>
    </Fragment>
  );
};

import { RFC } from '@typings/extra-react-router';
import { QuestionnaireContainer } from 'components/polls/Questionnaire/QuestionnaireContainer';
import { QuestionnaireFormRoute } from 'config/routes.types';
import React from 'react';
import { useStyles } from 'views/Questionnaires/Form/Form.styles';
import classNames from 'classnames';

export const FormView: RFC<QuestionnaireFormRoute> = ({ match }) => {
  const { pollId, token } = match.params;
  const classes = useStyles();

  return (
    <div className={classNames('container', classes.container)}>
      <QuestionnaireContainer pollId={pollId} token={token} />
    </div>
  );
};

import { RSAA, RSAACreator } from 'redux-api-middleware';
import { UserListActionType } from 'store/userList/userList.actionTypes';

export const fetchUserList: RSAACreator<UserListActionType> = () => {
  return {
    [RSAA]: {
      endpoint: `/users`,
      method: 'GET',
      types: [
        {
          type: UserListActionType.FETCH_USER_LIST_REQUEST,
          meta: {
            takeLatest: true,
          },
        },
        UserListActionType.FETCH_USER_LIST_SUCCESS,
        UserListActionType.FETCH_USER_LIST_FAILURE,
      ],
      params: {},
    },
  };
};

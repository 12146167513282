import { muiStyles } from 'utils/design';

export const useStyles = muiStyles((theme) => ({
  box: {
    width: '300px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  footer: {
    textAlign: 'center',
    margin: theme.spacing(2, 3, 0, 3),
  },
}));

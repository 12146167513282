import { limits } from 'config/pagination';
import React, { FC, useCallback, useEffect } from 'react';
import { ChildrenList } from 'components/schoolDistricts/AssignmentPreview/ChildrenList/ChildrenList';
import { ChildrenAlertListContainerProps } from 'components/schoolDistricts/AssignmentPreview/ChildrenList/ChildrenList.types';
import {
  ChildrenAlertsTypes,
  clearChildrenList,
  fetchAssignmentDetails,
  fetchChildrenAlertList,
  getAssignmentDetailsSelector,
  getChildrenSelector,
} from 'store/schoolDistricts';
import { AppDispatch } from '@typings/redux';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { formatRoute } from 'react-router-named-routes';
import { ROUTE_SCHOOL_CATCHMENT_ASSIGNMENT_PREVIEW } from 'config/routes';

export const ChildrenAlertListContainer: FC<ChildrenAlertListContainerProps> = ({ id, alert }) => {
  const dispatch: AppDispatch = useDispatch();
  const { loading: assignmentLoading, data: assignmentData } = useSelector(getAssignmentDetailsSelector);
  const { items: childrenData, total } = useSelector(getChildrenSelector);

  useEffect(() => {
    dispatch(clearChildrenList());
    dispatch(fetchAssignmentDetails(id));
  }, [alert, dispatch, id]);

  const redirectToPreview = () => dispatch(push(formatRoute(ROUTE_SCHOOL_CATCHMENT_ASSIGNMENT_PREVIEW, { id: id })));

  const getStatusCountTitle = () => {
    return {
      [ChildrenAlertsTypes.MISSING_ASSIGNMENT]:
        'SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.CHILDREN_ALERT_LIST.GRID_COUNT.MISSING_ASSIGNMENT',
      [ChildrenAlertsTypes.MISSING_TERYT]:
        'SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.CHILDREN_ALERT_LIST.GRID_COUNT.MISSING_TERYT',
    }[alert];
  };

  const childrenFetchHandler = useCallback(
    (page) => {
      dispatch(fetchChildrenAlertList(id, alert, page));
    },
    [alert, dispatch, id],
  );

  const childrenDataProvider = useCallback(
    (page) => {
      return childrenData[page];
    },
    [childrenData],
  );

  return (
    <ChildrenList
      assignmentData={assignmentData}
      onChildrenFetch={childrenFetchHandler}
      onChildrenProvide={childrenDataProvider}
      isAssignmentLoading={assignmentLoading}
      redirectToPreview={redirectToPreview}
      countStatusTitle={getStatusCountTitle()}
      limit={limits.schoolDistrictsChildrenAssignmentList}
      total={total}
    />
  );
};

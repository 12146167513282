import React, { useEffect, useCallback, useState, useContext } from 'react';
import { Settings } from 'components/settings/Settings';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@typings/redux';
import {
  fetchSystemSettings,
  fetchPollsSettings,
  fetchUserSettings,
  getSystemSettings,
  getPollsSettings,
  getUserSettings,
  putDepartmentSettings,
  putPollSettings,
  putUserSettings,
} from 'store/systemSettings';
import { FormikHelpers } from 'formik';
import { SettingsFormValues } from 'components/settings/Settings.types';
import { useFormatter, useNotification } from 'components/shared/hooks';
import {
  mapFormDepartmentValueToPayload,
  mapFormPollValueToPayload,
  mapFormUserValueToPayload,
} from './System.helpers';
import { formatCommuneSummary } from 'components/shared/teryt/CommunePicker/CommunePicker.helpers';
import { reverseFormatTownSummary } from 'components/shared/teryt/TownPicker/TownPicker.helpers';
import { fetchCommunesList, fetchTownsList } from 'store/teryt';
import { LocaleContext } from 'context/locale';
import { DCSType } from 'store/systemSettings/systemSettings.types';
import { fetchUserInfo } from 'store/user/user.action';
import { useCheckAccess, useCheckAccessSameType } from 'components/shared/hooks';
import { OfficialPermissionLevel, OfficialResourceGroup } from 'store/officials';

export const SettingsContainer = () => {
  const dispatch: AppDispatch = useDispatch();
  const { locale, trans } = useContext(LocaleContext);
  const { data: systemData } = useSelector(getSystemSettings);
  const { data: pollsData } = useSelector(getPollsSettings);
  const { data: userData } = useSelector(getUserSettings);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const { success } = useNotification();
  const { formatApiErrors } = useFormatter();
  const [savedFormValues, setSavedFormValues] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [primaryCommuneSummary, setPrimaryCommuneSummary] = useState<string>('');
  const [additionalCommunesSummary, setAdditionalCommunesSummary] = useState<string[]>([]);
  const [additionalCitiesSummary, setAdditionalCitiesSummary] = useState<string[]>([]);
  const minFullAccessConfigurations = useCheckAccess(
    OfficialResourceGroup.configurations,
    OfficialPermissionLevel.fullAccess,
  );
  const minReadOnlyAccessConfigurations = useCheckAccess(
    OfficialResourceGroup.configurations,
    OfficialPermissionLevel.readOnly,
  );
  const minFullAccessPools = useCheckAccess(OfficialResourceGroup.polls, OfficialPermissionLevel.fullAccess);
  const minReadOnlyPools = useCheckAccess(OfficialResourceGroup.polls, OfficialPermissionLevel.readOnly);
  const userReadOnly = useCheckAccessSameType(OfficialResourceGroup.childRegister, OfficialPermissionLevel.readOnly);

  useEffect(() => {
    if (!userReadOnly) return;
    setSubmitSuccess(true);
  }, [userReadOnly]);

  const handlefetchUserSettings = useCallback(
    async (documentsCirculationSystem) => {
      if (documentsCirculationSystem === DCSType.MDOK) {
        await dispatch(fetchUserSettings());
      }
    },
    [dispatch],
  );
  const handleFetchSystemSettings = useCallback(async () => {
    if (!minReadOnlyAccessConfigurations) return;
    const result = await dispatch(fetchSystemSettings());
    if (!result.error) {
      // Fetch additional communes
      if (result.payload.osin_commune || result.payload.additional_communes.length > 0) {
        const communesResult = await dispatch(
          fetchCommunesList({ id: [result.payload.osin_commune, ...result.payload.additional_communes] }),
        );
        if (!communesResult.error && communesResult.payload?.items) {
          const communesSummary = [];
          communesResult.payload.items.forEach((commune) => {
            if (commune.id.toString() === result.payload.osin_commune) {
              setPrimaryCommuneSummary(formatCommuneSummary(commune, locale, trans));
            } else {
              communesSummary.push(formatCommuneSummary(commune, locale, trans));
            }
          });
          setAdditionalCommunesSummary(communesSummary);
        }
      }

      // Fetch additional cities
      if (result.payload.additional_cities.length > 0) {
        const streetsResult = await dispatch(fetchTownsList({ id: [...result.payload.additional_cities] }));
        if (!streetsResult.error && streetsResult.payload?.items) {
          const streetsSummary = [];
          streetsResult.payload.items.forEach((street) => {
            streetsSummary.push(reverseFormatTownSummary(street, locale, trans));
          });
          setAdditionalCitiesSummary(streetsSummary);
        }
      }
    }
    await handlefetchUserSettings(result.payload.documents_circulation_system);
  }, [minReadOnlyAccessConfigurations, dispatch, locale, trans, handlefetchUserSettings]);

  const handleFetchPoolsSettings = useCallback(async () => {
    if (!minReadOnlyPools) return;
    await dispatch(fetchPollsSettings());
  }, [minReadOnlyPools, dispatch]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await handleFetchSystemSettings();
      await handleFetchPoolsSettings();
      setIsLoading(false);
    })();
  }, [handleFetchPoolsSettings, handleFetchSystemSettings]);

  const trimInputValues = (values: SettingsFormValues) => {
    const trimmedValues = { ...values };
    const fieldsToTrim = ['departmentName', 'dcsLogin', 'topic', 'messageContent', 'messageSignature'];

    fieldsToTrim.forEach((key) => (trimmedValues[key] ? (trimmedValues[key] = trimmedValues[key].trim()) : null));

    return trimmedValues;
  };

  const handleSubmit = useCallback(
    (values: SettingsFormValues) => {
      const sendData = [];
      if (minFullAccessPools) {
        sendData.push(dispatch(putPollSettings(mapFormPollValueToPayload(values))));
      }
      if (minFullAccessConfigurations) {
        sendData.push(dispatch(putDepartmentSettings(mapFormDepartmentValueToPayload(values))));
        if (systemData.documentsCirculationSystem === DCSType.MDOK) {
          sendData.push(dispatch(putUserSettings(mapFormUserValueToPayload(values))));
        }
      }
      return sendData;
    },
    [dispatch, systemData.documentsCirculationSystem, minFullAccessPools, minFullAccessConfigurations],
  );

  const handleFormSubmit = (values: SettingsFormValues, formik: FormikHelpers<SettingsFormValues>) => {
    const trimmedValues = trimInputValues(values);
    formik.setValues(trimmedValues);

    Promise.all([...handleSubmit(trimmedValues)]).then((result) => {
      const errors = [];
      result.forEach((single) => {
        if (single.error && single.payload.response) {
          errors.push(single.payload.response.errors);
        }
      });
      if (errors.length > 0) {
        errors.forEach((error) => {
          formik.setErrors({
            ...formatApiErrors(error),
          });
        });
        formik.setSubmitting(false);
      } else {
        setSavedFormValues(trimmedValues);
        setSubmitSuccess(true);
        success('SETTINGS.FORM.SUCCESS');
        formik.setSubmitting(false);
        dispatch(fetchUserInfo());
      }
    });
  };

  const formValues = {
    departmentName: systemData.departmentName,
    osinCommune: primaryCommuneSummary,
    schoolyearThreshold: {
      day: systemData.schoolyearThreshold.substr(2),
      month: systemData.schoolyearThreshold.substr(0, 2),
    },
    additionalCommunes: [...additionalCommunesSummary, ...additionalCitiesSummary],
    departmentIntegration: [
      systemData.belSynchronizationEnabled ? 'selIntegration' : null,
      systemData.documentsCirculationSystem === DCSType.MDOK || systemData.documentsCirculationSystem === DCSType.DUMMY
        ? 'sodIntegration'
        : null,
      systemData.catchmentAssignmentTransferEnabled ? 'assignmentTransfer' : null,
      systemData.studentAddressSynchronizationEnabled ? 'addressSynchronization' : null,
    ],
    topic: pollsData.topic,
    messageContent: pollsData.messageContent,
    messageSignature: pollsData.messageSignature,
    ...(systemData.documentsCirculationSystem === DCSType.MDOK && { dcsLogin: userData.dcsLogin }),
  };

  return (
    <Settings
      initialValues={formValues}
      loading={isLoading}
      handleFormSubmit={handleFormSubmit}
      submitted={submitSuccess}
      savedFormValues={savedFormValues}
      sodIntegration={systemData.documentsCirculationSystem}
    />
  );
};

import { Behaviours, FSAReducer } from '@typings/redux';
import { RESET_STATE } from 'store/store';
import { SystemSettingsActionType as ActionType } from './systemSettings.actionTypes';
import { defaultState, SettingsState as State } from './systemSettings.state';
import { fromApiFormat } from 'utils/api';

export const settingsReducer: FSAReducer<State, ActionType> = (state = defaultState, action) => {
  const behaviours: Behaviours<State, ActionType> = {
    [RESET_STATE]: () => ({
      ...defaultState,
    }),
    [ActionType.FETCH_SYSTEM_SETTINGS_REQUEST]: () => {
      return {
        ...state,
        system: {
          ...state.system,
          loading: true,
        },
      };
    },
    [ActionType.FETCH_SYSTEM_SETTINGS_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        system: {
          ...state.system,
          data: { ...fromApiFormat(payload) },
          loading: false,
        },
      };
    },
    [ActionType.FETCH_SYSTEM_SETTINGS_FAILURE]: ({ error }) => {
      return {
        ...state,
        system: {
          ...state.system,
          loading: false,
          error: error,
        },
      };
    },
    [ActionType.FETCH_POLLS_SETTINGS_REQUEST]: () => {
      return {
        ...state,
        polls: {
          ...state.polls,
          loading: true,
        },
      };
    },
    [ActionType.FETCH_POLLS_SETTINGS_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        polls: {
          ...state.polls,
          data: fromApiFormat(payload),
          loading: false,
        },
      };
    },
    [ActionType.FETCH_POLLS_SETTINGS_FAILURE]: ({ error }) => {
      return {
        ...state,
        polls: {
          ...state.polls,
          loading: false,
          error: error,
        },
      };
    },
    [ActionType.FETCH_USER_SETTINGS_REQUEST]: () => {
      return {
        ...state,
        user: {
          ...state.user,
          loading: true,
        },
      };
    },
    [ActionType.FETCH_USER_SETTINGS_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        user: {
          ...state.user,
          data: fromApiFormat(payload),
          loading: false,
        },
      };
    },
    [ActionType.FETCH_USER_SETTINGS_FAILURE]: ({ error }) => {
      return {
        ...state,
        user: {
          ...state.user,
          loading: false,
          error: error,
        },
      };
    },
  };

  return behaviours[action.type] ? behaviours[action.type](action) : state;
};

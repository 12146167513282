import { AppState } from 'store/store';

export const areDictionariesReadySelector = (state: AppState) => state.general.dictionaries.ready;
export const dictionariesSelector = (state: AppState) => state.general.dictionaries.list;
export const isSidebarOpenSelector = (state: AppState) => state.general.sidebarOpening;
export const getSchoolTypesList = (state: AppState) => state.general.dictionaries.list.schoolType;
export const windowInfoSelector = (state: AppState) => state.general.window;
export const isScreenLockSelector = (state: AppState) => state.general.screenLock;
export const getNotificationsSelector = (state: AppState) => state.general.notifications;
export const getEnvironment = (state: AppState) => state.general.environment;

import { useTranslator } from 'components/shared/hooks';
import React, { FC, useState } from 'react';

export const Count: FC<{ api: any; title?: string }> = ({ api, title }) => {
  const [count, setCount] = useState(0);
  const [display, setDisplay] = useState(false);
  const trans = useTranslator();

  api.addEventListener('gridLoading', () => {
    setDisplay(false);
  });

  api.addEventListener('gridNotLoading', () => {
    setDisplay(true);
    const firstRow = api.getDisplayedRowAtIndex(0);

    if (firstRow) {
      const isInitialInfinityLoading = firstRow.data === undefined;
      const countFix = isInitialInfinityLoading ? -1 : 0;
      setCount(api.getDisplayedRowCount() + countFix);
    }
  });

  return (
    display && (
      <div className="ag-name-value">
        <span>{trans(title ? title : 'COMMON.SHOWING_N_RESULTS', { count })}</span>
      </div>
    )
  );
};

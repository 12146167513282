import { SchoolYearModel } from '@typings/common';
import { DictionaryItem } from 'store/general';
import { AddressModel, AddressPostModel } from 'store/teryt';

export enum SynchronizationTypes {
  SYNERGIA = 'SYNERGIA',
  SDDS = 'SDDS',
  NONE = 'NONE',
}

export enum DataSharingTypes {
  AGREEMENT = 'AGREEMENT',
  DISAGREEMENT = 'DISAGREEMENT',
}

export interface InstitutionDictionaryItemModel {
  id: string;
  name: string;
  synchronization: SynchronizationTypes;
}

export function isInstitution(item: InstitutionModel | UnitModel): item is InstitutionModel {
  return (item as InstitutionModel).units !== undefined;
}

export interface InstitutionsFilters {
  search?: string;
  id?: string;
  synchronization?: number;
  dataSharing?: string;
  accreditation?: boolean;
  educationStage?: number | string;
  studentRegister?: SchoolYearModel;
}

export interface InstitutionModel {
  id: string;
  name: string;
  originalName: string;
  synchronization: SynchronizationTypes;
  dataSharing: DataSharingTypes | boolean | '';
  units: UnitModel[];
}

export interface UnitModel {
  originalName: string;
  isUnit: true;
  id: string;
  name: string;
  institution?: InstitutionModel;
  educationStage: DictionaryItem;
  obligationType: DictionaryItem;
  accreditation: DictionaryItem;
  schoolCharacter: DictionaryItem;
  studentCategory: DictionaryItem;
  schoolSubtype: DictionaryItem;
  educationForm: DictionaryItem;
  organizationalStructure: DictionaryItem;
  phone: string | null;
  email: string | null;
  fax: string | null;
  www: string | null;
  catchmentSchool: boolean;
  rspo: string;
  oke: string;
  regon: string;
  nip: string;
  address: AddressModel;
  registerUpdatedAt: string;
  studentRegisterYears: SchoolYearModel[];
}

export interface UnitPostModel {
  institution: {
    id?: string;
    name?: string;
  };
  name: string;
  educationStage: string;
  obligationType: string;
  accreditation: string;
  schoolCharacter: string;
  studentCategory: string;
  schoolType: string;
  schoolSubtype: string;
  organizationalStructure: string;
  educationForm: string;
  phone: string;
  fax: string;
  email: string;
  www: string;
  catchmentSchool: boolean;
  rspo: string;
  oke: string;
  regon: string;
  nip: string;
  address: AddressPostModel;
}

export interface Revision {
  from: string;
  to: string;
  dataSource: string;
}

export interface StudentRegisterModel {
  id: string;
  revisions: Revision[];
  schoolYear: string;
  mode: string;
  dataSource: string;
  studentsCount: number;
}

export interface StudentRegisterDetailsModel {
  id: string;
  from: string;
  to: string;
  schoolYear: string;
  mode: string;
  dataSource: string;
  entries: StudentRegisterEntryModel[];
  studentsCount: number;
}

export interface StudentRegisterHeaderModel {
  title: string;
}

export interface StudentRegisterEntryModel {
  firstName: string;
  lastName: string;
  identificationNumber: string;
  level: string;
}

export type StudentRegisterBrowserRow = StudentRegisterModel | StudentRegisterHeaderModel;

export function isStudentRegisterHeader(item: StudentRegisterBrowserRow): item is StudentRegisterHeaderModel {
  return (item as StudentRegisterHeaderModel).title !== undefined;
}

export interface RegisterRollbackRow {
  registerId: string;
  from: string;
  to: string;
  dataSource: string;
}

export interface ImportPreview {
  identificationNumberNotPesel: number;
  recordsPreview: PreviewRecordRow[];
  totalNumber: number;
}
export interface PreviewRecordRow {
  firstName: string;
  lastName: string;
  identificationNumber: string;
  identificationNumberType: 'PESEL' | 'OTHER';
  level?: string;
}

export interface StudentImportModel {
  firstName: string;
  lastName: string;
  identificationNumber: string;
  level: string | null;
  voivodeship: string | null;
  city: string | null;
  cityId: string | null;
  street: string | null;
  buildingNumber: string | null;
  flatNumber: string | null;
}

export interface ImportContext {
  dataSource: string;
  updateDate: string;
  map: StudentImportModel;
}

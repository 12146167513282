import { muiStyles } from 'utils/design';

export const useStyles = muiStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'top',
    alignItems: 'center',
  },
}));

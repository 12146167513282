import { Alerts } from 'components/childRegister/Browser/Alerts/Alerts';
import { useStyles } from './Browser.styles';
import { BrowserColumnParams, BrowserProps } from './Browser.types';
import { browserColumns } from './columns.config';
import { Toolbar } from './Toolbar/Toolbar';
import { DataGrid } from 'components/shared/data/DataGrid/DataGrid';
import { DataGridApi, StatusPanelDefinition } from 'components/shared/data/DataGrid/DataGrid.types';
import { useTranslator } from 'components/shared/hooks/useTranslator/useTranslator';
import { TextLoader } from 'components/shared/loaders/TextLoader/TextLoader';
import React, { FC, useCallback, useRef, useState } from 'react';
import { ChildModel } from 'store/childRegister/childRegister.types';
import { Confirm } from 'components/shared/dialogs/Confirm/Confirm';
import { focusField } from 'utils/form';
import { useCheckAccess } from 'components/shared/hooks';
import { OfficialPermissionLevel, OfficialResourceGroup } from 'store/officials';

export const Browser: FC<BrowserProps> = (props) => {
  const [itemsToDelete, setItemsToDelete] = useState<ChildModel[]>([]);
  const [gridApi, setGridApi] = useState<DataGridApi>(null);
  const minFullAccessChildRegister = useCheckAccess(
    OfficialResourceGroup.childRegister,
    OfficialPermissionLevel.fullAccess,
  );
  const classes = useStyles();
  const trans = useTranslator();
  const toolbarRef = useRef(null);

  const actionHandlers: BrowserColumnParams = {
    edit: (data) => props.editItem(data),
    delete: (data) => setItemsToDelete([data]),
  };

  const deleteConfirmHandler = () => {
    props.deleteItems(itemsToDelete).then(() => setItemsToDelete([]));
  };

  const onGridReadyHandler = useCallback(
    (grid: DataGridApi) => {
      setGridApi(grid);
    },
    [setGridApi],
  );

  const onFocusBeforeGridHandler = useCallback(() => {
    if (toolbarRef.current) {
      // using setTimeout to wait over the tab event that we can't prevent
      setTimeout(() => focusField(toolbarRef.current, 'studentRegister'));
    }
  }, [toolbarRef]);

  const onFocusAfterGridHandler = () => {
    setTimeout(() => document.getElementById('sidebar-button').focus());
  };

  const gridFocusHandler = () => {
    gridApi && gridApi.focusCell(0, 0);
  };

  const statusPanels: StatusPanelDefinition[] = minFullAccessChildRegister
    ? [
        {
          statusPanel: 'count',
          align: 'left',
        },
        {
          statusPanel: 'deleteSelected',
          statusPanelParams: { onDelete: (items) => setItemsToDelete(items) },
          align: 'left',
        },
      ]
    : [
        {
          statusPanel: 'count',
          align: 'left',
        },
      ];

  if (props.filtersLoading) {
    return <TextLoader text={trans('INSTITUTIONS.BROWSER.DATA-LOADING')} />;
  } else {
    return (
      <>
        <Toolbar onGridFocus={gridFocusHandler} ref={toolbarRef} />
        {!props.filters.alert && props.alerts.length > 0 && <Alerts counts={props.alerts} />}
        <DataGrid<ChildModel>
          className={classes.grid}
          columns={browserColumns(actionHandlers)}
          loadingMessage={'COMMON.LOADING_DATA'}
          noResultsMessage={'COMMON.NOTHING-FOUND'}
          onGridReady={onGridReadyHandler}
          statusPanels={statusPanels}
          rowSelection="multiple"
          onFocusBeforeGrid={onFocusBeforeGridHandler}
          onFocusAfterGrid={onFocusAfterGridHandler}
          onDataFetch={props.onChildrenFetch}
          onDataProvide={props.onChildrenProvide}
          limit={props.limit}
          total={props.total}
        />
        <Confirm
          open={itemsToDelete.length > 0}
          title={'CHILD_REGISTER.BROWSER.DELETE.TITLE'}
          message={
            itemsToDelete.length === 1
              ? 'CHILD_REGISTER.BROWSER.DELETE.MESSAGE-SINGLE'
              : 'CHILD_REGISTER.BROWSER.DELETE.MESSAGE-MULTIPLE'
          }
          messageValues={{ count: itemsToDelete.length }}
          confirm={() => deleteConfirmHandler()}
          cancel={() => setItemsToDelete([])}
        />
      </>
    );
  }
};

import { compact } from 'lodash';

import { ActionHandlers } from './DatabaseBrowser.types';
import { ColumnsConfig } from 'components/shared/data/DataGrid/DataGrid.types';
import {
  DatabaseModel,
  DatabaseModelBrowserRow,
  DatabaseStatus,
  isDatabaseHeaderModel as isHeader,
} from 'store/schoolDistricts';
import { OfficialPermissionLevel, OfficialResourceGroup } from 'store/officials';

export const databaseColumns: ColumnsConfig<DatabaseModelBrowserRow, ActionHandlers> = (handlers) => [
  {
    field: 'name',
    name: 'SCHOOL_DISTRICTS.DATABASE.COLUMNS.NAME',
    cellClass: 'indent-cell',
    columnParams: { minWidth: 300 },
    columnSpan: (data) => {
      return isHeader(data) ? 5 : 1;
    },
    format: (data, trans) => {
      return isHeader(data) ? trans(data.title) : data.name;
    },
  },
  {
    field: 'status',
    name: 'SCHOOL_DISTRICTS.DATABASE.COLUMNS.STATUS',
    cellClass: 'indent-cell',
    format: (data, trans) => {
      if (!isHeader(data)) {
        const { status } = data;
        return trans(`SCHOOL_DISTRICTS.DATABASE.VALUES.STATUS.${status.toUpperCase()}`);
      }
    },
    columnParams: { minWidth: 300 },
  },
  {
    name: 'SCHOOL_DISTRICTS.DATABASE.COLUMNS.ACTIONS',
    cellClass: 'actions-cell',
    field: 'id',
    cellTooltip: () => '',
    renderer: 'buttonsCell',
    rendererParams: {
      buttons: (item: DatabaseModel) => {
        if (isHeader(item)) {
          return [];
        } else {
          return compact([
            {
              type: 'default',
              size: 'small',
              variant: 'outlined',
              canShow: {
                only: false,
                resourcesGroup: OfficialResourceGroup.childRegister,
                accessType: OfficialPermissionLevel.readOnly,
              },
              icon: 'pen',
              tooltip: 'SCHOOL_DISTRICTS.DATABASE.ACTIONS.SELECT',
              label: 'SCHOOL_DISTRICTS.DATABASE.ACTIONS.SELECT',
              action: () => handlers.select(item),
            },
            {
              type: 'default',
              size: 'small',
              variant: 'outlined',
              canShow: {
                only: false,
                resourcesGroup: OfficialResourceGroup.childRegister,
                accessType: OfficialPermissionLevel.fullAccess,
              },
              tooltip: 'SCHOOL_DISTRICTS.DATABASE.ACTIONS.MORE',
              label: 'SCHOOL_DISTRICTS.DATABASE.ACTIONS.MORE',
              action: handlers.edit,
              actionList: compact([
                {
                  type: 'default',
                  size: 'small',
                  variant: 'outlined',
                  icon: 'edit',
                  label: 'SCHOOL_DISTRICTS.DATABASE.ACTIONS.EDIT',
                  action: () => handlers.edit(item),
                },
                ...(item.status === DatabaseStatus.PLANNED
                  ? [
                      {
                        type: 'default',
                        size: 'small',
                        variant: 'outlined',
                        icon: 'trash',
                        label: 'SCHOOL_DISTRICTS.DATABASE.ACTIONS.DELETE',
                        action: () => handlers.delete(item),
                      },
                      {
                        type: 'default',
                        size: 'small',
                        variant: 'outlined',
                        icon: 'star',
                        label: 'SCHOOL_DISTRICTS.DATABASE.ACTIONS.SET_CURRENT',
                        action: () => handlers.setCurrent(item),
                      },
                    ]
                  : []),
                ...(item.status === DatabaseStatus.ACTIVE
                  ? [
                      {
                        type: 'default',
                        size: 'small',
                        variant: 'outlined',
                        icon: 'box',
                        label: 'SCHOOL_DISTRICTS.DATABASE.ACTIONS.SET_ARCHIVE',
                        action: () => handlers.setArchive(item),
                      },
                    ]
                  : []),
              ]),
            },
          ]);
        }
      },
    },
  },
];

import { muiStyles } from 'utils/design';

export const useStyles = muiStyles((theme) => ({
  grid: {
    flexGrow: 1,
  },
  row: {
    '.ag-theme-material & > .ag-cell.actions-cell': {
      textAlign: 'right',
    },
  },
  warning: {
    color: theme.palette.error.main,
  },
  empty: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
}));

import { muiStyles } from 'utils/design';

export const useStyles = muiStyles((theme) => ({
  toolbar: {
    display: 'flex',
  },
  form: {
    flexGrow: 1,
  },
  filters: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > div': {
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1),
      maxWidth: 200,
      minWidth: 0,
    },
  },
}));

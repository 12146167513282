import { RFC } from '@typings/extra-react-router';
import { Page } from 'components/layout/panel/Page/Page';
import { EditContainer } from 'components/officials/Editor/EditContainer';
import { useTranslator } from 'components/shared/hooks';
import { Breadcrumb } from 'components/shared/navigation/Breadcrumbs/Breadcrumb';
import { Box } from 'components/shared/surfaces/Box/Box';
import { ROUTE_OFFICIALS_LIST } from 'config/routes';
import { OfficialsActionRoute } from 'config/routes.types';
import React, { Fragment } from 'react';
import { useStyles } from 'views/Panel/Officials/OfficialsEditor/OfficialsEditor.styles';

export const EditOfficialsView: RFC<OfficialsActionRoute> = ({ match }) => {
  const trans = useTranslator();
  const classes = useStyles();

  return (
    <Fragment>
      <Breadcrumb label={trans('BREADCRUMB.OFFICIALS.LIST')} to={ROUTE_OFFICIALS_LIST} />
      <Breadcrumb label={trans('BREADCRUMB.OFFICIALS.EDIT')} static />

      <Page title={trans('OFFICIALS.EDITOR.EDITOR_EDIT')}>
        <Box margin={[0, 2]} className={classes.box}>
          <EditContainer officialId={match.params.id} />
        </Box>
      </Page>
    </Fragment>
  );
};

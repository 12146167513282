import { compact } from 'lodash';

import { ColumnsConfig } from 'components/shared/data/DataGrid/DataGrid.types';
import { AssignmentModel, AssignmentStatus } from 'store/schoolDistricts';
import { AssignmentBrowserHandlers } from 'components/schoolDistricts/AssignmentBrowser/AssignmentBrowser.types';
import { OfficialPermissionLevel, OfficialResourceGroup } from 'store/officials';
import {
  AssignmentModelBrowserRow,
  isHeader,
} from 'components/schoolDistricts/AssignmentBrowser/AssignmentBrowser.types';

export const databaseColumns: ColumnsConfig<AssignmentModelBrowserRow, AssignmentBrowserHandlers> = (handlers) => [
  {
    field: 'createdAt',
    name: 'SCHOOL_DISTRICTS.ASSIGMENT_BROWSER.COLUMNS.CREATED_AT',
    cellClass: 'indent-cell',
    columnParams: { minWidth: 100 },
    columnSpan: (data) => {
      return isHeader(data) ? 6 : 1;
    },
    format: (data, trans, format) => {
      return isHeader(data) ? trans(data.title) : format.formatDate(data.createdAt);
    },
  },
  {
    field: 'assignmentName',
    name: 'SCHOOL_DISTRICTS.ASSIGMENT_BROWSER.COLUMNS.NAME',
    cellClass: 'indent-cell',
    columnParams: { minWidth: 100 },
  },
  {
    field: 'status',
    name: 'SCHOOL_DISTRICTS.ASSIGMENT_BROWSER.COLUMNS.STATUS',
    cellClass: 'indent-cell',
    format: (data, trans) => {
      if (!isHeader(data)) {
        const { status } = data;
        return trans(`SCHOOL_DISTRICTS.ASSIGMENT_BROWSER.VALUES.STATUS.${status.toUpperCase()}`);
      }
    },
    columnParams: { minWidth: 100 },
  },
  {
    name: 'SCHOOL_DISTRICTS.ASSIGMENT_BROWSER.COLUMNS.ACTIONS',
    cellClass: 'actions-cell',
    field: 'id',
    cellTooltip: () => '',
    renderer: 'buttonsCell',
    columnParams: { minWidth: 220 },
    rendererParams: {
      buttons: (item: AssignmentModel) => {
        return compact([
          {
            type: 'default',
            size: 'small',
            variant: 'outlined',
            label: 'COMMON.DISPLAY',
            icon: 'eye',
            tooltip: 'SCHOOL_DISTRICTS.ASSIGMENT_BROWSER.ACTIONS.SELECT',
            action: () => handlers.show(item),
          },
          {
            type: 'default',
            size: 'small',
            variant: 'outlined',
            canShow: {
              only: false,
              resourcesGroup: OfficialResourceGroup.childRegister,
              accessType: OfficialPermissionLevel.fullAccess,
            },
            label: 'COMMON.DELETE',
            icon: 'trash',
            tooltip: 'SCHOOL_DISTRICTS.ASSIGMENT_BROWSER.ACTIONS.DELETE',
            action: () => handlers.delete(item),
            disabled: item.status === AssignmentStatus.OFFICIAL,
          },
        ]);
      },
    },
  },
];

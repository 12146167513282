import { Button } from 'components/shared/buttons/Button/Button';
import { useTranslator } from 'components/shared/hooks';
import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon';
import { TextLoader } from 'components/shared/loaders/TextLoader/TextLoader';
import { Splash } from 'components/shared/surfaces/Splash/Splash';
import React, { FC } from 'react';

import { Toolbar } from './Toolbar/Toolbar';
import { SchoolsList } from './SchoolsList/SchoolsList';
import { AddressesList } from './AddressesList/AddressesList';
import { ADDRESS_RULE_CONFLICT_ALERT, DistrictsBrowserProps } from './DistrictsBrowser.types';
import { Alert } from './Alert/Alert';
import Access from 'components/shared/access/access';
import { OfficialPermissionLevel, OfficialResourceGroup } from 'store/officials';

export const DistrictsBrowser: FC<DistrictsBrowserProps> = (props) => {
  const trans = useTranslator();

  if (props.databasesLoading) {
    return <TextLoader text={trans('COMMON.LOADING_DATA')} />;
  }

  if (props.databasesList.length === 0) {
    return (
      <Splash>
        <div className="mb-3">{trans('SCHOOL_DISTRICTS.DISTRICTS-LIST.NO-DATABASE')}</div>
        <Access resourcesGroup={OfficialResourceGroup.childRegister} accessType={OfficialPermissionLevel.fullAccess}>
          <Button variant="contained" color="default" onClick={() => props.onAddDatabase()}>
            <FontAwesomeIcon name={'plus-circle'} className={'mr-2'} />
            {trans('SCHOOL_DISTRICTS.DATABASE.ACTIONS.ADD')}
          </Button>
        </Access>
      </Splash>
    );
  }

  const haveSchoolsWithWarning = props.schoolsList.some((school) => {
    return school.alerts.some((alert) => alert.name === ADDRESS_RULE_CONFLICT_ALERT);
  });
  return (
    <>
      <Toolbar
        databaseList={props.databasesList}
        initialDatabaseId={props.selectedDatabaseId}
        onSelectDatabase={props.onSelectDatabase}
      />
      {haveSchoolsWithWarning && <Alert />}
      <div className="row flex-grow-1">
        <div className="d-flex flex-column col-3 border-right">
          <SchoolsList loading={props.schoolsLoading} list={props.schoolsList} onSelectSchool={props.onSelectSchool} />
        </div>
        <div className="d-flex flex-column col-9">
          <AddressesList
            loading={props.addressesLoading}
            list={props.addressesList}
            databaseId={props.selectedDatabaseId}
            school={props.school}
            onAdd={props.onAddItem}
            onEdit={props.onEditItem}
            onSubmitSuccess={props.onSubmitSuccess}
            onDelete={props.onDeleteItem}
            selectedDatabase={props.selectedDatabase}
          />
        </div>
      </div>
    </>
  );
};

import React, { useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import { GridReadyEvent } from 'ag-grid-community';
import Box from '@material-ui/core/Box';
import { useStyles } from 'components/reports/SchoolStatDetails/SchoolStatDetails.styles';
import { StatDetailsTable } from 'store/reports/reports.types';
import { useTranslator } from 'components/shared/hooks';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import { prepareAggregationTreeData } from 'components/reports/SchoolStatDetails/helpers';
import { Tooltip } from 'components/shared/data/DataGrid/Tooltip/Tooltip';

interface StatTreeProps {
  table: StatDetailsTable | null;
  name: string;
  setGridApi: React.Dispatch<any>;
}

export const StatTree = ({ table, name, setGridApi }: StatTreeProps) => {
  const classes = useStyles();
  const trans = useTranslator();
  const data = useMemo(() => prepareAggregationTreeData(table), [table]);

  const onGridReady = (event: GridReadyEvent) => {
    setGridApi(event.api);
    event.columnApi.autoSizeAllColumns();
  };

  return (
    <Paper innerSpacing={2} square className={classes.paper}>
      <Box className="ag-theme-material" height={500}>
        <AgGridReact
          rowData={data}
          columnDefs={table.columns
            .filter((item) => !item.group)
            .map((item) => ({ ...item, aggFunc: item.aggregation, headerName: item.name }))}
          treeData
          animateRows
          autoGroupColumnDef={{
            headerName: name,
            suppressSizeToFit: true,
            maxWidth: 700,
            cellRendererParams: { suppressCount: true },
          }}
          defaultColDef={{
            filter: true,
            resizable: true,
            tooltipComponent: 'gridTooltip',
            tooltipValueGetter: (params) => {
              return params.valueFormatted ? params.valueFormatted : params.value;
            },
          }}
          getDataPath={(data) => data[table.columns.find((item) => item.group).field]}
          groupDefaultExpanded={-1}
          onGridReady={onGridReady}
          frameworkComponents={{
            gridTooltip: Tooltip,
          }}
          tooltipShowDelay={500}
          localeText={{
            selectAll: trans('COMMON.GRID.SELECT_ALL'),
            searchOoo: trans('COMMON.GRID.SEARCH'),
            blanks: trans('COMMON.GRID.BLANK'),
            noMatches: trans('COMMON.GRID.NO_MATCHES'),
            pinColumn: trans('COMMON.GRID.PIN_COLUMN'),
            pinLeft: trans('COMMON.GRID.PIN_LEFT'),
            pinRight: trans('COMMON.GRID.PIN_RIGHT'),
            noPin: trans('COMMON.GRID.NO_PIN'),
            autosizeThiscolumn: trans('COMMON.GRID.AUTOSIZE_THIS_COLUMN'),
            autosizeAllColumns: trans('COMMON.GRID.AUTOSIZE_ALL_COLUMNS'),
            resetColumns: trans('COMMON.GRID.RESET_COLUMNS'),
            expandAll: trans('COMMON.GRID.EXPAND_ALL'),
            collapseAll: trans('COMMON.GRID.COLLAPSE_ALL'),
          }}
        />
      </Box>
      <div className="pt-3 text-muted text-right">{trans('REPORTS.SCHOOL_STAT_DETAILS.FIXED_VALUES_EXPLANATION')}</div>
    </Paper>
  );
};

import { getCurrentDate } from 'utils/date/currentDate';
import { range } from 'lodash';

export const birthYearsGenerator = (startAge: number, endAge: number): string[] => {
  if (startAge > endAge) {
    throw Error('Wrong age order');
  }

  const currentYear = getCurrentDate().getFullYear();
  const startYear = currentYear - endAge;
  const endYear = currentYear - startAge;

  return range(startYear, endYear + 1).map((year) => year.toString());
};

import { formatRoute } from 'react-router-named-routes';
import { Params } from 'config/routes.types';

interface MakePathFunction {
  <T extends keyof Params>(route: T, params: Params[T]): string;
  (route: string): string;
}

/**
 * It works exactly like `formatRoute` but it allows
 * to get parameters suggestions from `routes.types.ts`
 */
export const makePath: MakePathFunction = (route, params = {}) => {
  return formatRoute(route, params);
};

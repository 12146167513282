import { Collapse } from '@material-ui/core';
import { useStyles } from 'components/shared/lists/NavigationList/CollapsibleSubmenu/CollapsibleSubmenu.styles';
import { CollapsibleSubmenuProps } from 'components/shared/lists/NavigationList/CollapsibleSubmenu/CollapsibleSubmenu.types';
import { NavigationSubItem } from 'components/shared/lists/NavigationList/NavigationSubItem/NavigationSubItem';
import React, { FC } from 'react';
import { pick } from 'lodash';

export const CollapsibleSubmenu: FC<CollapsibleSubmenuProps> = ({ items, open }) => {
  const classes = useStyles();

  return (
    // TODO
    <Collapse in={open} timeout="auto" unmountOnExit classes={pick(classes, ['container']) as any}>
      {items.map((item, index) => (
        <NavigationSubItem item={item} key={index} className={classes.item} />
      ))}
    </Collapse>
  );
};

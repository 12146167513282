import { snakeCase } from 'change-case';
import { Translator } from 'context/locale/LocaleContext/LocaleContext.types';

export const translateTemplateField = (field: string, trans: Translator) => {
  return trans(`TEMPLATES.FIELD.${snakeCase(field).toUpperCase()}`);
};

export const mapFieldsToTranslations = (fields: string[], trans: Translator): string[] => {
  return fields.map((field) => translateTemplateField(field, trans));
};

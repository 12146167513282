import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { AgGridReact } from 'ag-grid-react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useFormatter, useTranslator } from 'components/shared/hooks';
import { fetchAddressHistory, fetchEducationPath } from 'store/childRegister/childRegister.actions';
import { fromApiFormat } from 'utils/api/converters';
import { ROUTE_CHILD_REGISTER_EDIT } from 'config/routes';
import { Tooltip } from 'components/shared/data/DataGrid/Tooltip/Tooltip';

export const SearchDetailsRenderer = ({ data, node, api }) => {
  const rowId = node.id;
  const trans = useTranslator();
  const { formatDate, formatDateWithTime } = useFormatter();
  const dispatch: (...params: any) => Promise<any> = useDispatch();

  useEffect(() => {
    return () => {
      api.removeDetailGridInfo(rowId);
    };
  }, [api, rowId]);

  const addressHistoryColDefs = [
    {
      field: 'addressType',
      headerName: trans('CHILD_REGISTER.ADDRESS_HISTORY.COLUMNS.TYPE'),
      valueFormatter: (params) => {
        return trans(`CHILD_REGISTER.ADDRESS_HISTORY.VALUES.${params.value.toUpperCase()}`);
      },
      width: 120,
    },
    {
      field: 'address.cityName',
      headerName: trans('CHILD_REGISTER.ADDRESS_HISTORY.COLUMNS.CITY_NAME'),
    },
    {
      field: 'address.streetName',
      headerName: trans('CHILD_REGISTER.ADDRESS_HISTORY.COLUMNS.STREET_NAME'),
      flex: 1,
    },
    {
      field: 'address.postalCode',
      headerName: trans('CHILD_REGISTER.ADDRESS_HISTORY.COLUMNS.POSTAL_CODE'),
      width: 110,
    },
    {
      field: 'address.buildingNumber',
      headerName: trans('CHILD_REGISTER.ADDRESS_HISTORY.COLUMNS.BUILDING_NUMBER'),
      width: 110,
    },
    {
      field: 'address.apartmentNumber',
      headerName: trans('CHILD_REGISTER.ADDRESS_HISTORY.COLUMNS.APARTMENT_NUMBER'),
      width: 110,
    },
    {
      field: 'modificationDate',
      headerName: trans('CHILD_REGISTER.ADDRESS_HISTORY.COLUMNS.MODIFICATION_DATE'),
      valueFormatter: (params) => {
        return params.value ? formatDateWithTime(params.value) : '';
      },
      width: 160,
    },
  ];

  const educationPathColDefs = [
    {
      field: 'schoolYear',
      headerName: trans('CHILD_REGISTER.EDUCATION_PATH.COLUMNS.SCHOOL_YEAR'),
      width: 110,
    },
    {
      field: 'from',
      headerName: trans('CHILD_REGISTER.EDUCATION_PATH.COLUMNS.FROM'),
      valueFormatter: (params) => {
        return formatDate(params.value);
      },
      width: 100,
    },
    {
      field: 'to',
      headerName: trans('CHILD_REGISTER.EDUCATION_PATH.COLUMNS.TO'),
      valueFormatter: (params) => {
        return params.value ? formatDate(params.value) : '-';
      },
      width: 100,
    },
    {
      field: 'educationStage.name',
      headerName: trans('CHILD_REGISTER.EDUCATION_PATH.COLUMNS.EDUCATION_STAGE'),
      width: 160,
    },
    {
      field: 'institutionName',
      headerName: trans('CHILD_REGISTER.EDUCATION_PATH.COLUMNS.INSTITUTION_NAME'),
    },
    {
      field: 'unitName',
      headerName: trans('CHILD_REGISTER.EDUCATION_PATH.COLUMNS.UNIT_NAME'),
      flex: 1,
    },
    {
      field: 'level',
      headerName: trans('CHILD_REGISTER.EDUCATION_PATH.COLUMNS.LEVEL'),
      width: 140,
    },
  ];

  const addressHistoryServerSideDatasource = {
    getRows: (params) => {
      dispatch(fetchAddressHistory(data.id))
        .then(({ payload }) => {
          params.successCallback(
            payload.map((item) => fromApiFormat(item)),
            payload.length,
          );
        })
        .catch(() => params.failCallback());
    },
  };

  const educationPathServerSideDatasource = {
    getRows: (params) => {
      dispatch(fetchEducationPath(data.identificationNumber, data.identificationNumberType))
        .then(({ payload }) => {
          params.successCallback(
            payload.map((item) => fromApiFormat(item)),
            payload.length,
          );
        })
        .catch(() => params.failCallback());
    },
  };

  const onAddressHistoryGridReady = (params) => {
    params.api.setServerSideDatasource(addressHistoryServerSideDatasource);
  };

  const onEducationPathGridReady = (params) => {
    params.api.setServerSideDatasource(educationPathServerSideDatasource);
  };

  const defaultColDef = {
    resizable: true,
    tooltipComponent: 'gridTooltip',
    suppressMenu: true,
    suppressMovable: true,
    sortable: false,
    tooltipValueGetter: (params) => {
      return params.valueFormatted ? params.valueFormatted : params.value;
    },
  };

  const defaultGridProps = {
    defaultColDef,
    rowModelType: 'serverSide',
    localeText: {
      loadingOoo: trans('COMMON.LOADING_DATA'),
      noRowsToShow: trans('COMMON.NOTHING-FOUND'),
    },
    frameworkComponents: {
      gridTooltip: Tooltip,
    },
    tooltipShowDelay: 500,
  };

  return (
    <Box padding={2}>
      {data.inChildRegister && (
        <>
          <Box marginBottom={1} display="flex" alignItems="center">
            <Typography variant="h6" color="textSecondary">
              {trans('SEARCH.DATAGRID.DETAILS.ADDRESS_HISTORY')}
            </Typography>
            <Box marginLeft={2}>
              <Button
                variant="outlined"
                size="small"
                component={Link}
                to={ROUTE_CHILD_REGISTER_EDIT.replace(':id', data.id)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {trans('SEARCH.DATAGRID.DETAILS.OPEN_CHILD_REGISTER')}
              </Button>
            </Box>
          </Box>
          <Box className={classNames('ag-theme-material')} height={200}>
            <AgGridReact
              {...defaultGridProps}
              columnDefs={addressHistoryColDefs}
              onGridReady={onAddressHistoryGridReady}
              getRowStyle={(params) => {
                if (params.node.rowIndex <= 1) {
                  return { fontWeight: 500 };
                }
              }}
            />
          </Box>
        </>
      )}
      {data.inStudentRegister && (
        <>
          <Box marginBottom={1} marginTop={2}>
            <Typography variant="h6" color="textSecondary">
              {trans('SEARCH.DATAGRID.DETAILS.EDUCATION_PATH')}
            </Typography>
          </Box>
          <Box className={classNames('ag-theme-material')} height={200}>
            <AgGridReact
              {...defaultGridProps}
              columnDefs={educationPathColDefs}
              onGridReady={onEducationPathGridReady}
              getRowStyle={(params) => {
                if (params.node.rowIndex === 0) {
                  return { fontWeight: 500 };
                }
              }}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

import React, { FC } from 'react';
import { Breadcrumbs as DynamicBreadcrumbs } from 'react-breadcrumbs-dynamic';
import { Breadcrumbs as MuiBreadcrumbs } from '@material-ui/core';
import { useStyles } from './Breadcrumbs.styles';
import { BreadcrumbsItem, BreadcrumbsLastItem } from './BreadcrumbsItem/BreadcrumbsItem';

const renderContainer = (props) => {
  return (
    <MuiBreadcrumbs separator="›" aria-label="Breadcrumb">
      {props.children}
    </MuiBreadcrumbs>
  );
};

export const Breadcrumbs: FC<{}> = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <DynamicBreadcrumbs
        container={renderContainer}
        item={BreadcrumbsItem}
        finalItem={BreadcrumbsLastItem}
        {...{ compare: () => 0 }}
      />
    </div>
  );
};

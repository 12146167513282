export enum SchoolObligationsActionType {
  FETCH_BIRTH_YEARS_REQUEST = 'schoolObligation/FETCH_BIRTH_YEARS_REQUEST',
  FETCH_BIRTH_YEARS_SUCCESS = 'schoolObligation/FETCH_BIRTH_YEARS_SUCCESS',
  FETCH_BIRTH_YEARS_FAILURE = 'schoolObligation/FETCH_BIRTH_YEARS_FAILURE',

  FETCH_SCHOOL_OBLIGATIONS_REPORTS_REQUEST = 'schoolObligation/FETCH_SCHOOL_OBLIGATIONS_REPORTS_REQUEST',
  FETCH_SCHOOL_OBLIGATIONS_REPORTS_SUCCESS = 'schoolObligation/FETCH_SCHOOL_OBLIGATIONS_REPORTS_SUCCESS',
  FETCH_SCHOOL_OBLIGATIONS_REPORTS_FAILURE = 'schoolObligation/FETCH_SCHOOL_OBLIGATIONS_REPORTS_FAILURE',

  FETCH_SCHOOL_OBLIGATIONS_REPORT_DETAILS_REQUEST = 'schoolObligation/FETCH_SCHOOL_OBLIGATIONS_REPORT_DETAILS_REQUEST',
  FETCH_SCHOOL_OBLIGATIONS_REPORT_DETAILS_SUCCESS = 'schoolObligation/FETCH_SCHOOL_OBLIGATIONS_REPORT_DETAILS_SUCCESS',
  FETCH_SCHOOL_OBLIGATIONS_REPORT_DETAILS_FAILURE = 'schoolObligation/FETCH_SCHOOL_OBLIGATIONS_REPORT_DETAILS_FAILURE',

  PUBLISH_SCHOOL_OBLIGATIONS_REPORT_DETAILS_REQUEST = 'schoolObligation/PUBLISH_SCHOOL_OBLIGATIONS_REPORT_DETAILS_REQUEST',
  PUBLISH_SCHOOL_OBLIGATIONS_REPORT_DETAILS_SUCCESS = 'schoolObligation/PUBLISH_SCHOOL_OBLIGATIONS_REPORT_DETAILS_SUCCESS',
  PUBLISH_SCHOOL_OBLIGATIONS_REPORT_DETAILS_FAILURE = 'schoolObligation/PUBLISH_SCHOOL_OBLIGATIONS_REPORT_DETAILS_FAILURE',

  REMOVE_SCHOOL_OBLIGATION_REPORT_REQUEST = 'schoolObligation/REMOVE_SCHOOL_OBLIGATION_REPORT_REQUEST',
  REMOVE_SCHOOL_OBLIGATION_REPORT_SUCCESS = 'schoolObligation/REMOVE_SCHOOL_OBLIGATION_REPORT_SUCCESS',
  REMOVE_SCHOOL_OBLIGATION_REPORT_FAILURE = 'schoolObligation/REMOVE_SCHOOL_OBLIGATION_REPORT_FAILURE',

  FETCH_SCHOOL_YEARS_REQUEST = 'schoolObligation/FETCH_SCHOOL_YEARS_REQUEST',
  FETCH_SCHOOL_YEARS_SUCCESS = 'schoolObligation/FETCH_SCHOOL_YEARS_SUCCESS',
  FETCH_SCHOOL_YEARS_FAILURE = 'schoolObligation/FETCH_SCHOOL_YEARS_FAILURE',

  CREATE_SCHOOL_OBLIGATION_REPORT_REQUEST = 'schoolObligation/CREATE_SCHOOL_OBLIGATION_REPORT_REQUEST',
  CREATE_SCHOOL_OBLIGATION_REPORT_SUCCESS = 'schoolObligation/CREATE_SCHOOL_OBLIGATION_REPORT_SUCCESS',
  CREATE_SCHOOL_OBLIGATION_REPORT_FAILURE = 'schoolObligation/CREATE_SCHOOL_OBLIGATION_REPORT_FAILURE',

  CLEAR_REPORT_STATUS = 'schoolObligation/CLEAR_ASSIGNING_STATUS',

  FETCH_REPORT_STATUS_REQUEST = 'schoolObligation/FETCH_REPORT_STATUS_REQUEST',
  FETCH_REPORT_STATUS_SUCCESS = 'schoolObligation/FETCH_REPORT_STATUS_SUCCESS',
  FETCH_REPORT_STATUS_FAILURE = 'schoolObligation/FETCH_REPORT_STATUS_FAILURE',

  SET_SCHOOL_OBLIGATIONS_REPORT_CHILDREN_LIST_FILTERS = 'schoolObligation/SET_SCHOOL_OBLIGATIONS_REPORT_CHILDREN_LIST_FILTERS',

  CLEAR_SCHOOL_OBLIGATIONS_REPORT_CHILDREN_LIST = 'schoolObligation/CLEAR_SCHOOL_OBLIGATIONS_REPORT_CHILDREN_LIST',

  FETCH_SCHOOL_OBLIGATIONS_REPORT_CHILDREN_LIST_REQUEST = 'schoolObligation/FETCH_SCHOOL_OBLIGATIONS_REPORT_CHILDREN_LIST_REQUEST',
  FETCH_SCHOOL_OBLIGATIONS_REPORT_CHILDREN_LIST_SUCCESS = 'schoolObligation/FETCH_SCHOOL_OBLIGATIONS_REPORT_CHILDREN_LIST_SUCCESS',
  FETCH_SCHOOL_OBLIGATIONS_REPORT_CHILDREN_LIST_FAILURE = 'schoolObligation/FETCH_SCHOOL_OBLIGATIONS_REPORT_CHILDREN_LIST_FAILURE',

  FETCH_SCHOOL_OBLIGATIONS_REPORT_INSTITUTIONS_LIST_REQUEST = 'schoolObligation/FETCH_SCHOOL_OBLIGATIONS_REPORT_INSTITUTIONS_LIST_REQUEST',
  FETCH_SCHOOL_OBLIGATIONS_REPORT_INSTITUTIONS_LIST_SUCCESS = 'schoolObligation/FETCH_SCHOOL_OBLIGATIONS_REPORT_INSTITUTIONS_LIST_SUCCESS',
  FETCH_SCHOOL_OBLIGATIONS_REPORT_INSTITUTIONS_LIST_FAILURE = 'schoolObligation/FETCH_SCHOOL_OBLIGATIONS_REPORT_INSTITUTIONS_LIST_FAILURE',

  FETCH_FULFILLMENT_OPTIONS_REQUEST = 'schoolObligation/FETCH_FULFILLMENT_OPTIONS_REQUEST',
  FETCH_FULFILLMENT_OPTIONS_SUCCESS = 'schoolObligation/FETCH_FULFILLMENT_OPTIONS_SUCCESS',
  FETCH_FULFILLMENT_OPTIONS_FAILURE = 'schoolObligation/FETCH_FULFILLMENT_OPTIONS_FAILURE',

  SET_REPORTS_FILTERS = 'schoolObligation/SET_REPORTS_FILTERS',
  CLEAR_REPORTS_FILTERS = 'schoolObligation/CLEAR_REPORTS_FILTERS',

  DOWNLOAD_CSV_SCHOOL_OBLIGATIONS_REPORT_CHILDREN_LIST_REQUEST = 'schoolObligation/DOWNLOAD_CSV_SCHOOL_OBLIGATIONS_REPORT_CHILDREN_LIST_REQUEST',
  DOWNLOAD_CSV_SCHOOL_OBLIGATIONS_REPORT_CHILDREN_LIST_SUCCESS = 'schoolObligation/DOWNLOAD_CSV_SCHOOL_OBLIGATIONS_REPORT_CHILDREN_LIST_SUCCESS',
  DOWNLOAD_CSV_SCHOOL_OBLIGATIONS_REPORT_CHILDREN_LIST_FAILURE = 'schoolObligation/DOWNLOAD_CSV_SCHOOL_OBLIGATIONS_REPORT_CHILDREN_LIST_FAILURE',
}

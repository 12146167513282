import { Behaviours, FSAReducer } from '@typings/redux';
import { RESET_STATE } from 'store/store';
import { defaultState, PollsActionType as ActionType, PollsState as State } from 'store/polls';
import { fromApiFormat } from 'utils/api';

export const pollsReducer: FSAReducer<State, ActionType> = (state = defaultState, action) => {
  const behaviours: Behaviours<State, ActionType> = {
    [RESET_STATE]: () => ({
      ...defaultState,
    }),
    [ActionType.SET_FILTERS]: ({ payload }) => ({
      ...state,
      list: {
        ...state.list,
        filters: payload.filters,
      },
    }),
    [ActionType.FETCH_POLLS_LIST_REQUEST]: ({ meta }) => ({
      ...state,
      list: {
        ...state.list,
        loading: !meta.quietUpdate,
      },
    }),
    [ActionType.FETCH_POLLS_LIST_SUCCESS]: ({ payload }) => ({
      ...state,
      list: {
        ...state.list,
        items: fromApiFormat(payload.items),
        loading: false,
      },
    }),
    [ActionType.FETCH_POLLS_LIST_FAILURE]: ({ error }) => ({
      ...state,
      list: {
        ...state.list,
        loading: false,
        error,
      },
    }),
    [ActionType.FETCH_POLL_DETAILS_REQUEST]: () => ({
      ...state,
      details: {
        ...state.details,
        loading: true,
      },
    }),
    [ActionType.FETCH_POLL_DETAILS_SUCCESS]: ({ payload }) => ({
      ...state,
      details: {
        ...state.details,
        poll: fromApiFormat(payload),
        loading: false,
      },
    }),
    [ActionType.FETCH_POLL_DETAILS_FAILURE]: ({ error }) => ({
      ...state,
      details: {
        ...state.details,
        loading: false,
        error,
      },
    }),
    [ActionType.FETCH_SYNC_INSTITUTIONS_FAILURE]: () => ({
      ...state,
      synchronizedInstitutions: {
        ...state.synchronizedInstitutions,
        loading: true,
      },
    }),
    [ActionType.FETCH_SYNC_INSTITUTIONS_SUCCESS]: ({ payload }) => ({
      ...state,
      synchronizedInstitutions: {
        ...state.synchronizedInstitutions,
        list: fromApiFormat(payload.items),
        loading: false,
      },
    }),
    [ActionType.FETCH_SYNC_INSTITUTIONS_FAILURE]: ({ error }) => ({
      ...state,
      synchronizedInstitutions: {
        ...state.synchronizedInstitutions,
        loading: false,
        error,
      },
    }),
    [ActionType.FETCH_RECIPIENTS_GROUPS_REQUEST]: () => ({
      ...state,
      recipientsGroups: {
        ...state.recipientsGroups,
        loading: true,
      },
    }),
    [ActionType.FETCH_RECIPIENTS_GROUPS_SUCCESS]: ({ payload }) => ({
      ...state,
      recipientsGroups: {
        ...state.recipientsGroups,
        list: fromApiFormat(payload.items),
        loading: false,
      },
    }),
    [ActionType.FETCH_RECIPIENTS_GROUPS_FAILURE]: ({ error }) => ({
      ...state,
      recipientsGroups: {
        ...state.recipientsGroups,
        loading: false,
        error,
      },
    }),
    [ActionType.FETCH_RECIPIENTS_COUNTS_REQUEST]: ({ meta }) => ({
      ...state,
      recipientsCounts: {
        ...state.recipientsCounts,
        [meta.institutionId]: {
          loading: true,
          counts: {},
          error: null,
        },
      },
    }),
    [ActionType.FETCH_RECIPIENTS_COUNTS_SUCCESS]: ({ payload, meta }) => ({
      ...state,
      recipientsCounts: {
        ...state.recipientsCounts,
        [meta.institutionId]: {
          ...state.recipientsCounts[meta.institutionId],
          loading: false,
          counts: payload,
        },
      },
    }),
    [ActionType.FETCH_RECIPIENTS_COUNTS_FAILURE]: ({ error, meta }) => ({
      ...state,
      recipientsCounts: {
        ...state.recipientsCounts,
        [meta.institutionId]: {
          ...state.recipientsCounts[meta.institutionId],
          loading: false,
          error,
        },
      },
    }),
    [ActionType.CLEAR_RECIPIENTS_COUNTS]: () => ({
      ...state,
      recipientsCounts: {},
    }),
    [ActionType.FETCH_POLL_RESULTS_REQUEST]: () => ({
      ...state,
      results: {
        ...state.results,
        loading: true,
        error: null,
      },
    }),
    [ActionType.FETCH_POLL_RESULTS_SUCCESS]: ({ payload }) => ({
      ...state,
      results: {
        ...state.results,
        results: fromApiFormat(payload),
        loading: false,
      },
    }),
    [ActionType.FETCH_POLL_RESULTS_FAILURE]: ({ payload }) => ({
      ...state,
      results: {
        ...state.results,
        loading: false,
        error: payload.response.error,
      },
    }),
    [ActionType.FETCH_POLL_RESPONSE_REQUEST]: ({ meta }) => ({
      ...state,
      responses: {
        ...state.responses,
        [meta.responseId]: {
          loading: true,
          response: null,
          error: null,
        },
      },
    }),
    [ActionType.FETCH_POLL_RESPONSE_SUCCESS]: ({ payload, meta }) => ({
      ...state,
      responses: {
        ...state.responses,
        [meta.responseId]: {
          ...state.responses[meta.responseId],
          loading: false,
          response: fromApiFormat(payload),
        },
      },
    }),
    [ActionType.FETCH_POLL_RESPONSE_FAILURE]: ({ meta, error }) => ({
      ...state,
      responses: {
        ...state.responses,
        [meta.responseId]: {
          ...state.responses[meta.responseId],
          loading: false,
          error,
        },
      },
    }),
    [ActionType.CLEAR_RESPONSES]: () => ({
      ...state,
      responses: {},
    }),
    [ActionType.FETCH_QUESTIONNAIRE_REQUEST]: () => ({
      ...state,
      questionnaire: {
        ...state.questionnaire,
        loading: true,
        error: null,
      },
    }),
    [ActionType.FETCH_POLL_RECIPIENTS_REQUEST]: () => ({
      ...state,
      recipients: {
        ...state.recipients,
        loading: true,
        error: null,
      },
    }),
    [ActionType.FETCH_POLL_RECIPIENTS_SUCCESS]: ({ payload }) => ({
      ...state,
      recipients: {
        ...state.recipients,
        list: fromApiFormat(payload.items),
        loading: false,
        error: null,
      },
    }),
    [ActionType.FETCH_POLL_RECIPIENTS_FAILURE]: ({ error }) => ({
      ...state,
      recipients: {
        ...state.recipients,
        list: [],
        loading: false,
        error,
      },
    }),
    [ActionType.FETCH_QUESTIONNAIRE_SUCCESS]: ({ payload }) => ({
      ...state,
      questionnaire: {
        ...state.questionnaire,
        questionnaire: fromApiFormat(payload),
        loading: false,
      },
    }),
    [ActionType.FETCH_QUESTIONNAIRE_FAILURE]: ({ payload }) => ({
      ...state,
      questionnaire: {
        ...state.questionnaire,
        loading: false,
        error: payload.response.error,
      },
    }),
  };

  return behaviours[action.type] ? behaviours[action.type](action) : state;
};

import { AppDispatch } from '@typings/redux';
import { Questionnaire } from 'components/polls/Questionnaire/Questionnaire';
import { QuestionnaireContainerProps } from 'components/polls/Questionnaire/Questionnaire.types';
import { formatErrorKey, useNotification, useTranslator } from 'components/shared/hooks';
import { FormikHelpers } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchQuestionnaire, questionnaireSelector, QuestionType, ResponseForm, saveResponse } from 'store/polls';

const findNumberRegex = /\d+/;
export const QuestionnaireContainer: FC<QuestionnaireContainerProps> = ({ token, pollId }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { error: showError } = useNotification();
  const [isSubmitted, setSubmitted] = useState(false);
  const [lockMessage, setLockMessage] = useState<string>(null);
  const trans = useTranslator();

  const { questionnaire, loading, error } = useSelector(questionnaireSelector);

  useEffect(() => {
    dispatch(fetchQuestionnaire(pollId, token));
  }, [dispatch, pollId, token]);

  useEffect(() => {
    setLockMessage(error);
  }, [error]);

  const isTextQuestion = (type) => [QuestionType.TEXT, QuestionType.LONG_TEXT].includes(type);
  const initial = {
    answers: questionnaire
      ? questionnaire.questions.map((question) => ({
          index: question.index,
          result: isTextQuestion(question.type) ? '' : [],
        }))
      : [],
  };

  const formatResponseData = (data: ResponseForm): ResponseForm => {
    const newData: ResponseForm = { answers: [] };

    for (const answer of data.answers) {
      newData.answers.push({
        ...answer,
        result: Array.isArray(answer.result) ? answer.result : [answer.result],
      });
    }

    return newData;
  };

  const maxLength = {
    [QuestionType.TEXT]: 200,
    [QuestionType.LONG_TEXT]: 1000,
  };

  const formatApiErrors = (errors: Record<string, any>) => {
    return Object.keys(errors).reduce((mappedValues: Record<string, any>, key: string) => {
      let translationParams = {};
      const formattedKey = formatErrorKey(key);

      if (errors[key] === 'POLLS.ANSWERS.ANSWER.TOO_LONG') {
        const findNumber = formattedKey.match(findNumberRegex);
        if (findNumber) {
          const index = findNumber[0];
          translationParams = { length: maxLength[questionnaire.questions[index].type] };
        }
      }
      mappedValues[formatErrorKey(key)] = trans(errors[key], translationParams);
      return mappedValues;
    }, {});
  };

  const onSave = async (data: ResponseForm, formik: FormikHelpers<ResponseForm>) => {
    const result = await dispatch(saveResponse(pollId, token, formatResponseData(data)));

    if (result.error) {
      formik.setSubmitting(false);

      if (result.payload.response.error) {
        setSubmitted(true);
        setLockMessage(result.payload.response.error);
        showError('POLLS.QUESTIONNAIRE.CANT_SUBMIT', { reason: trans(result.payload.response.error) });
      } else if (result.payload.response.errors) {
        showError('POLLS.QUESTIONNAIRE.SUBMIT_ERROR');
        formik.setErrors(formatApiErrors(result.payload.response.errors));
      }
    } else {
      setSubmitted(true);
    }
  };

  return (
    <Questionnaire
      initial={initial}
      questionnaire={questionnaire}
      isLoading={loading}
      onSave={onSave}
      submitted={isSubmitted}
      lockMessage={lockMessage}
    />
  );
};

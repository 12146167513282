import { User } from 'components/settings/User/User';
import { UserFormValues, UserContainerProps } from 'components/settings/User/User.types';
import { connect } from 'formik';
import React, { ComponentType } from 'react';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import { useStyles } from 'components/settings/System/System.styles';

export const UserContainer: ComponentType<UserContainerProps> = connect<UserContainerProps, UserFormValues>(
  ({ formik }) => {
    const classes = useStyles();

    return (
      <Paper innerSpacing={[3, 2]} square={true} className={classes.paper}>
        <User form={formik} />
      </Paper>
    );
  },
);

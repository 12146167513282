import React, { FC, useCallback, useRef, useState } from 'react';
import { SynchronizationsProps } from 'components/reports/Synchronizations/Synchronizations.types';
import { synchronizationLogColumns } from 'components/reports/Synchronizations/columns';
import { Toolbar } from 'components/reports/Synchronizations/Toolbar/Toolbar';
import { DataGrid } from 'components/shared/data/DataGrid/DataGrid';
import { DataGridApi } from 'components/shared/data/DataGrid/DataGrid.types';
import { limits } from 'config/pagination';
import { SynchronizationLog as SynchronizationLogModel } from 'store/reports/reports.types';

export const SynchronizationLog: FC<SynchronizationsProps> = (props) => {
  const [gridApi, setGridApi] = useState<DataGridApi>(null);
  const toolbarRef = useRef(null);

  const onGridReadyHandler = useCallback(
    (grid: DataGridApi) => {
      setGridApi(grid);
    },
    [setGridApi],
  );

  const gridFocusHandler = () => {
    gridApi && gridApi.focusCell(0, 0);
  };

  return (
    <>
      <Toolbar onGridFocus={gridFocusHandler} ref={toolbarRef} />
      <DataGrid<SynchronizationLogModel>
        columns={synchronizationLogColumns()}
        onDataProvide={props.onLogsProvide}
        onDataFetch={props.onLogsFetch}
        fetchedItemsCount={props.fetchedItemsCount}
        total={props.totalLogs}
        limit={limits.synchronizationsLog}
        loadingMessage={'COMMON.LOADING_DATA'}
        noResultsMessage={'COMMON.NOTHING-FOUND'}
        onGridReady={onGridReadyHandler}
      />
    </>
  );
};

import { SchoolYearModel } from '@typings/common';
import {
  ImportContext,
  ImportPreview,
  InstitutionDictionaryItemModel,
  InstitutionModel,
  InstitutionsFilters,
  StudentRegisterDetailsModel,
  StudentRegisterModel,
  UnitModel,
} from './institutions.types';
import { format } from 'date-fns';

export interface InstitutionsState {
  list: {
    items: InstitutionModel[];
    filters: InstitutionsFilters;
    loading: boolean;
    ready: boolean;
    error: any;
  };
  dictionary: {
    list: InstitutionDictionaryItemModel[];
    loading: boolean;
    ready: boolean;
    error: any;
  };
  studentRegisterSchoolYears: {
    list: SchoolYearModel[];
    loading: boolean;
    ready: boolean;
    error: any;
  };
  institution: {
    data: InstitutionModel | null;
    loading: boolean;
    error: any;
  };
  unit: {
    data: UnitModel | null;
    loading: boolean;
    error: any;
  };
  studentRegister: {
    list: {
      items: StudentRegisterModel[];
      loading: boolean;
      ready: boolean;
    };
    register: {
      item: StudentRegisterDetailsModel;
      loading: boolean;
      ready: boolean;
    };
    import: {
      fileName: string;
      token: string;
      loading: boolean;
      columns: string[];
      preview: ImportPreview;
      context: ImportContext;
      error: string;
    };
  };
}

export const institutionInitialFilters: InstitutionsFilters = {
  search: '',
  id: null,
};

export const defaultState: InstitutionsState = {
  list: {
    items: [],
    loading: false,
    filters: institutionInitialFilters,
    ready: false,
    error: null,
  },
  dictionary: {
    list: [],
    loading: false,
    ready: false,
    error: null,
  },
  studentRegisterSchoolYears: {
    list: [],
    loading: false,
    ready: false,
    error: null,
  },
  institution: {
    loading: false,
    error: null,
    data: null,
  },
  unit: {
    loading: false,
    error: null,
    data: null,
  },
  studentRegister: {
    list: {
      items: [],
      loading: false,
      ready: false,
    },
    register: {
      item: null,
      loading: false,
      ready: false,
    },
    import: {
      fileName: '',
      token: null,
      loading: false,
      columns: [],
      context: {
        dataSource: '',
        updateDate: format(new Date(), 'yyyy-MM-dd'),
        map: {
          firstName: '',
          lastName: '',
          identificationNumber: '',
          level: '',
          voivodeship: '',
          city: '',
          cityId: '',
          street: '',
          buildingNumber: '',
          flatNumber: '',
        },
      },
      error: null,
      preview: {
        identificationNumberNotPesel: 0,
        recordsPreview: [],
        totalNumber: 0,
      },
    },
  },
};

import { TokenActionType } from '@tshio/redux-api-auth-middleware';
import { Store } from 'redux';
import { AppState } from 'store/store';

export type AuthData = TokenActionType;

export const isYear = (column: string): boolean => {
  return !isNaN(Number(column));
};

export interface YearsStatistic {
  [year: number]: number;
}

export interface Pagination {
  limit: number;
  total: number;
}

export type Page<T> = T[];

export type PageCollection<T> = Page<T>[];

export enum Majority {
  MINOR = 'minor',
  ADULT = 'adult',
}

export type BirthYearModel = string;
export type SchoolYearModel = string;

export enum CheckboxStatus {
  CHECKED = 'checked',
  INDETERMINATE = 'indeterminate',
  UNCHECKED = 'unchecked',
}

declare global {
  interface Window {
    getOsinStore: () => Store<AppState>;
    Cypress: any;
  }

  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}

import React, { FC, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, DialogActions } from '@material-ui/core';
import { DataGrid } from 'components/shared/data/DataGrid/DataGrid';
import { useTranslator } from 'components/shared/hooks';
import { Button } from 'components/shared/buttons/Button/Button';
import { StudentsListModalProps } from 'components/reports/OtherCommune/StudentsListModal/StudentsListModal.types';
import { OtherCommuneStudentsStat } from 'store/reports/reports.types';
import {
  communeStudentsListColumns,
  defaultColumn,
} from 'components/reports/OtherCommune/StudentsListModal/columns.config';
import { ActionButton } from 'components/shared/buttons/ActionButton/ActionButton';
import { DataGridApi } from 'components/shared/data/DataGrid/DataGrid.types';
import { useStyles } from 'components/reports/OtherCommune/StudentsListModal/StudentsListModal.styles';

export const StudentsListModal: FC<StudentsListModalProps> = ({ open, onClose, data, loading }) => {
  const trans = useTranslator();
  const classes = useStyles();
  const [gridApi, setGridApi] = useState(null);

  const onGridReady = ({ agGridApi }: DataGridApi) => {
    setGridApi(agGridApi);
  };

  const exportParams = {
    fileName: trans('REPORTS.OTHER_COMMUNE.EXPORT.TITLE'),
  };

  return (
    <Dialog
      fullWidth
      maxWidth={'lg'}
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      data-testid="addressHistoryModal"
    >
      <DialogTitle className={classes.dialogTitle}>{trans('REPORTS.COMMUNE_STUDENTS_LIST.TITLE')}</DialogTitle>
      <DialogContent>
        <div className={classes.container}>
          <div className="mb-2 d-flex">
            <ActionButton
              color="default"
              label="REPORTS.SCHOOL_STAT_DETAILS.EXPORT.TITLE"
              actionList={[
                {
                  action: () => gridApi && gridApi.exportDataAsCsv(exportParams),
                  label: 'REPORTS.OTHER_COMMUNE.EXPORT.CSV',
                  icon: 'file-csv',
                },
                {
                  action: () =>
                    gridApi &&
                    gridApi.exportDataAsExcel({
                      ...exportParams,
                      sheetName: `${data[0].city}`,
                    }),
                  label: 'REPORTS.OTHER_COMMUNE.EXPORT.EXCEL',
                  icon: 'file-excel',
                },
              ]}
            />
          </div>
          <DataGrid<OtherCommuneStudentsStat>
            data={data}
            isLoading={loading}
            columns={communeStudentsListColumns()}
            defaultColumn={defaultColumn()}
            onGridReady={onGridReady}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="contained">
          {trans('COMMON.BACK')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

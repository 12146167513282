import React, { FC } from 'react';
import { UserProps } from 'components/settings/User/User.types';
import { InputText } from 'components/shared/form';
import { useTranslator } from 'components/shared/hooks';
import { Heading } from 'components/shared/typography/Heading/Heading';

export const User: FC<UserProps> = () => {
  const trans = useTranslator();

  return (
    <div className="container-fluid">
      <Heading type="h6" className="font-weight-normal mb-2">
        {trans('SETTINGS.OFFICIAL.TITLE')}
      </Heading>
      <div className="ml-3">
        <div className="row">
          <div className="col-3">
            <InputText name="dcsLogin" labelText={trans('SETTINGS.OFFICIAL.FIELDS.MDOK_LOGIN')} required />
          </div>
        </div>
      </div>
    </div>
  );
};

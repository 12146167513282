import { Page } from 'components/layout/panel/Page/Page';
import { AuditLogContainer } from 'components/reports/AuditLog/AuditLogContainer';
import { useTranslator } from 'components/shared/hooks';
import { Breadcrumb } from 'components/shared/navigation/Breadcrumbs/Breadcrumb';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import React, { FC, Fragment } from 'react';
import { useStyles } from './Logs.styles';

export const LogsView: FC = () => {
  const trans = useTranslator();
  const classes = useStyles();

  return (
    <Fragment>
      <Breadcrumb label={trans('BREADCRUMB.REPORTS.AUDIT_LOG')} static />

      <Page title={trans('REPORTS.AUDIT_LOG.TITLE')}>
        <Paper innerSpacing={2} outerSpacing={[0, 2, 2]} className={classes.paper}>
          <AuditLogContainer />
        </Paper>
      </Page>
    </Fragment>
  );
};

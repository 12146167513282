import { useStyles } from 'components/shared/surfaces/Splash/Splash.styles';
import { SplashProps } from 'components/shared/surfaces/Splash/Splash.types';
import React, { FC } from 'react';
import classNames from 'classnames';

export const Splash: FC<SplashProps> = ({ children, className, height = 300 }) => {
  const classes = useStyles({ height });

  return <div className={classNames(classes.splash, className)}>{children}</div>;
};

import { compact } from 'lodash';
import { ColumnsConfig } from 'components/shared/data/DataGrid/DataGrid.types';
import { ButtonDefinition } from 'components/shared/data/DataGrid/renderers/cell/Buttons/Buttons.types';
import { ObligationAction } from 'store/childRegister/childRegister.types';
import { OfficialPermissionLevel, OfficialResourceGroup } from 'store/officials';

export const obligationActionsColumns: ColumnsConfig<ObligationAction> = (parameters) => [
  {
    field: 'actionAt',
    name: 'CHILD_REGISTER.OBLIGATION_ACTIONS.COLUMNS.ACTION_AT',
    format: (data, trans, formatter) => {
      return formatter.formatDateWithTime(data.actionAt);
    },
  },
  {
    field: 'officialId',
    name: 'CHILD_REGISTER.OBLIGATION_ACTIONS.COLUMNS.OFFICIAL',
    format: (data) => {
      return `${data.officialLastName || ''} ${data.officialFirstName || ''}`;
    },
  },
  {
    field: 'createdAt',
    name: 'CHILD_REGISTER.OBLIGATION_ACTIONS.COLUMNS.CREATED_AT',
    format: (data, trans, formatter) => {
      return formatter.formatDateWithTime(data.createdAt);
    },
  },
  {
    field: 'actionType',
    name: 'CHILD_REGISTER.OBLIGATION_ACTIONS.COLUMNS.ACTION_TYPE',
    format: (data) => {
      return data.actionType === 'manual' ? 'wpis własny' : 'automatyczny';
    },
  },
  {
    field: 'actionTitle',
    name: 'CHILD_REGISTER.OBLIGATION_ACTIONS.COLUMNS.ACTION_DESCRIPTION',
  },
  {
    name: 'CHILD_REGISTER.OBLIGATION_ACTIONS.COLUMNS.ACTIONS',
    field: 'id',
    cellTooltip: () => '',
    renderer: 'buttonsCell',
    rendererParams: {
      buttons: (item: ObligationAction): ButtonDefinition[] => {
        return compact([
          item.actionType === 'manual' && {
            type: 'default',
            size: 'small',
            variant: 'outlined',
            icon: 'pencil-alt',
            label: 'COMMON.EDIT',
            tooltip: 'COMMON.EDIT',
            action: parameters.edit,
            canShow: {
              only: false,
              resourcesGroup: OfficialResourceGroup.childRegister,
              accessType: OfficialPermissionLevel.fullAccess,
            },
          },
          item.actionType === 'manual' && {
            type: 'default',
            size: 'small',
            variant: 'outlined',
            icon: 'eye',
            label: 'COMMON.SHOW',
            tooltip: 'COMMON.SHOW',
            action: parameters.edit,
            canShow: {
              only: true,
              resourcesGroup: OfficialResourceGroup.childRegister,
              accessType: OfficialPermissionLevel.readOnly,
            },
          },
          {
            type: 'default',
            size: 'small',
            variant: 'outlined',
            icon: 'trash',
            label: 'COMMON.DELETE',
            tooltip: 'COMMON.DELETE',
            action: parameters.delete,
            canShow: {
              only: false,
              resourcesGroup: OfficialResourceGroup.childRegister,
              accessType: OfficialPermissionLevel.fullAccess,
            },
          },
        ]);
      },
    },
    columnParams: { minWidth: 200 },
  },
];

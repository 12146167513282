import { isArray, isFunction } from 'lodash';
import { VictoryTooltipProps, VictoryLabelProps } from 'victory';

export const resolveText = (
  text: Function | string | number | string[] | number[],
  props: VictoryTooltipProps | VictoryLabelProps,
) => {
  if (isFunction(text)) {
    return text({ ...(props as any) });
  } else if (isArray(text)) {
    const textArray: (number | string)[] = text;
    return textArray.map((item) => item.toString()).join(' ');
  } else {
    return text.toString();
  }
};

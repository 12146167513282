import { muiStyles } from 'utils/design';

export const useStyles = muiStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    minHeight: '500px',
  },
  rowProblematic: {
    color: 'red',
  },
}));

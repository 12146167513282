import { Polls } from 'components/settings/Polls/Polls';
import { PollsFormValues, PollsContainerProps } from 'components/settings/Polls/Polls.types';
import { connect } from 'formik';
import React, { ComponentType } from 'react';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import { useStyles } from 'components/settings/System/System.styles';

export const PollsContainer: ComponentType<PollsContainerProps> = connect<PollsContainerProps, PollsFormValues>(
  ({ formik }) => {
    const classes = useStyles();

    return (
      <Paper innerSpacing={[3, 2]} square={true} className={classes.paper}>
        <Polls form={formik} />
      </Paper>
    );
  },
);

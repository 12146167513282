import React, { FC, useContext, useCallback, useEffect, useState, useMemo } from 'react';
import { TownPicker as TownPickerComponent } from './TownPicker';
import { TownPickerContainerProps } from './TownPicker.types';
import { useDispatch } from 'react-redux';
import { fetchTownsList, fetchTown } from 'store/teryt';
import { TextLoader } from 'components/shared/loaders/TextLoader/TextLoader';
import { LocaleContext } from 'context/locale';
import { formatTownPath } from './TownPicker.helpers';
import { connect, FormikProps } from 'formik';
import { get } from 'lodash';

export const NO_TERYT_ITEM_ID = '__NO_TERYT_ITEM_ID__';

const TownPicker: FC<
  TownPickerContainerProps & {
    formik: FormikProps<{}>;
  }
> = ({ fieldName, formik, required, disabled, allowEmptyCountry, afterRender }) => {
  const { trans } = useContext(LocaleContext);
  const townValue = get(formik.values, fieldName);
  const [initialTown, setInitialTown] = useState(null);
  const [isInitialTownLoading, setInitialTownsLoading] = useState(false);
  const [townsList, setTownsList] = useState([]);
  const [isTownsLoading, setTownsLoading] = useState(false);

  const dispatch: (...params: any) => Promise<any> = useDispatch();

  // Fetch initial town, set field error if TERYT ID wasn't found in database
  useEffect(() => {
    if (townValue && townValue.id && townValue.id !== NO_TERYT_ITEM_ID) {
      setInitialTownsLoading(true);
      dispatch(fetchTown(townValue.id)).then((result) => {
        if (result.error) {
          if (result.payload.response.error && result.payload.status === 404) {
            formik.setFieldError(fieldName, trans(result.payload.response.error));
          }
          setInitialTown(null);
          setInitialTownsLoading(false);
        } else {
          setInitialTown(result.payload);
          setInitialTownsLoading(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch towns list callback, called on inputChange
  const fetchTownsListCallback = useCallback(
    (filters) => {
      setTownsLoading(true);
      return dispatch(fetchTownsList(filters)).then((result) => {
        if (!result.error && result.payload) {
          setTownsList(result.payload.items || []);
          setTownsLoading(false);
        }
      });
    },
    [dispatch],
  );

  // Map fetched payload into town object
  const townsOptionsList = useMemo(
    () =>
      townsList.map((town) => ({
        value: town.id,
        label: formatTownPath(town, trans),
        selectedLabel: town.name,
        town,
      })),
    [townsList, trans],
  );

  const renderLoader = () => <TextLoader name="spinner" text={trans('INSTITUTIONS.EDIT.LOADING')} height={50} />;

  const renderForm = () => (
    <TownPickerComponent
      fieldName={fieldName}
      townsList={townsOptionsList}
      fetchTownsList={fetchTownsListCallback}
      isTownsListLoading={isTownsLoading}
      initialSelectedTown={initialTown ? initialTown : townValue}
      form={formik}
      required={required}
      disabled={disabled}
      allowEmptyCountry={allowEmptyCountry}
      afterRender={afterRender}
      clearTownList={() => setTownsList([])}
    />
  );

  return <div className="container-fluid">{isInitialTownLoading ? renderLoader() : renderForm()}</div>;
};

export const TownPickerContainer = connect<TownPickerContainerProps>(TownPicker);

import { Page } from 'components/layout/panel/Page/Page';
import { useTranslator } from 'components/shared/hooks';
import classNames from 'classnames';
import React from 'react';

import { useStyles } from 'views/Panel/Settings/System/System.styles';
import { SettingsContainer } from 'components/settings/SettingsContainer';

export const SystemView = ({ location }) => {
  const trans = useTranslator();
  const classes = useStyles();

  return (
    <Page title={trans('SETTINGS.SYSTEM.TITLE')}>
      <div className={classNames('container-fluid pb-3', classes.container)}>
        <SettingsContainer key={location.key} />
      </div>
    </Page>
  );
};

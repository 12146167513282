import { DocumentsProps } from 'components/childRegister/Browser/Documents/Documents.types';
import { initialValues } from 'components/shared/dialogs/DocumentModal/DocumentModal';
import { DocumentModalForm, DocumentsCreator } from 'components/shared/dialogs/DocumentModal/DocumentModal.types';
import { DocumentModalContainer } from 'components/shared/dialogs/DocumentModal/DocumentModalContainer';
import { useTranslator, useNotification } from 'components/shared/hooks';
import { limits } from 'config/pagination';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearDocumentsPreviewData,
  createChildRegisterDocuments,
  fetchChildRegisterPreview,
  previewSelector,
} from 'store/templates';

export const Documents: FC<DocumentsProps> = (props) => {
  const { items: data, fields, total, alerts } = useSelector(previewSelector);
  const trans = useTranslator();
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState<DocumentModalForm>(initialValues);
  const { error } = useNotification();

  useEffect(() => {
    dispatch(clearDocumentsPreviewData());
  }, [dispatch, formValues.templateId, formValues.showInvalidOnly]);

  const onDataFetch = useCallback(
    async (page) => {
      const alert = formValues.showInvalidOnly ? true : null;
      const response: any = await dispatch(
        fetchChildRegisterPreview(formValues.templateId, props.filters, alert, page),
      );

      if (response.error) {
        error(response.payload.response.error);
      }
    },
    [dispatch, props.filters, formValues.templateId, error, formValues.showInvalidOnly],
  );

  const onDataProvide = useCallback((page) => data[page], [data]);

  const onDocumentsCreate: DocumentsCreator = (templateId, options) => {
    dispatch(createChildRegisterDocuments(templateId, options));
  };

  return (
    <>
      <DocumentModalContainer
        open={props.open}
        onClose={props.onClose}
        title={trans('CHILD_REGISTER.DOCUMENTS.DOCUMENT_CREATION')}
        target={'child_register'}
        fields={fields}
        alerts={alerts}
        onDataFetch={onDataFetch}
        onDataProvide={onDataProvide}
        onFormUpdate={setFormValues}
        onDocumentsCreate={onDocumentsCreate}
        limit={limits.childRegisterDocumentsPreview}
        total={total}
      />
    </>
  );
};

import { useStyles } from 'components/polls/shared/Question/Question.styles';
import { InputText } from 'components/shared/form';
import { useTranslator } from 'components/shared/hooks';
import { Heading } from 'components/shared/typography/Heading/Heading';
import { QuestionProps } from 'components/polls/Questionnaire/Questionnaire.types';
import React, { FC } from 'react';

export const ShortAnswer: FC<QuestionProps> = ({ field, question, disabled }) => {
  const classes = useStyles();
  const trans = useTranslator();

  const placeholder = disabled ? 'POLLS.QUESTIONNAIRE.NO_ANSWER' : 'POLLS.QUESTIONNAIRE.WRITE_ANSWER';

  return (
    <div className={classes.question}>
      <Heading type="subtitle1" className={classes.title}>
        {question.title}&nbsp;
        {question.required && !disabled && trans('POLLS.QUESTIONNAIRE.ANSWER_REQUIRED')}
      </Heading>
      <InputText name={`${field}.result`} disabled={disabled} placeholder={trans(placeholder)} />
    </div>
  );
};

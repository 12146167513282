import { EditorProps } from './Editor.types';
import { DataGrid } from 'components/shared/data/DataGrid/DataGrid';
import { TextLoader } from 'components/shared/loaders/TextLoader/TextLoader';
import { Form, InputText, SelectBox } from 'components/shared/form';
import { Button } from 'components/shared/buttons/Button/Button';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import { Heading } from 'components/shared/typography/Heading/Heading';
import { OfficialForm, OfficialAccountType } from 'store/officials/officials.types';
import { useDepartmentSettings } from 'components/shared/hooks/useInstanceConfig/useDepartmentSettings';
import { useTranslator } from 'components/shared/hooks';
import { useStyles } from './Editor.styles';
import React, { useState } from 'react';
import { Prompt } from 'react-router';
import { compact } from 'lodash';
import { institutionsTransformer } from 'components/institution/Browser/institutions.transformer';
import { isInstitution } from 'store/institutions';

export const Editor: React.FC<EditorProps> = (props) => {
  const trans = useTranslator();
  const classes = useStyles();
  const { accessRules, disabledTypeAccount, units } = props;
  const [select, setSelection] = useState([]);
  const settings = useDepartmentSettings();
  const sortedAccessRules = accessRules
    .map((item) => ({
      ...item,
      label: trans(item.translationKey),
    }))
    .sort((a, b) => new Intl.Collator('pl').compare(a.label, b.label));

  const handleRowSelection = (selectedUnits) => {
    const selectedUnitsIds = selectedUnits.map((unit) => unit.id);
    setSelection(selectedUnitsIds);
  };

  const submitOfficialForm = (values, formik) => {
    if (values.role === OfficialAccountType.APPLICANT) {
      values.units = select;
    }
    props.onSubmit(values, formik);
  };

  const getUnits = () => {
    const transformedUnits = institutionsTransformer(units);
    const unitList = transformedUnits.map((unit, index) => {
      return {
        id: unit.id,
        unitName: unit.name,
        isInstitution: isInstitution(unit),
      };
    });
    return unitList;
  };

  if (props.loading || !props.initial) {
    return <TextLoader text={trans('OFFICIALS.EDITOR.LOADING_OFFICIAL')} />;
  }
  return (
    <Form<OfficialForm>
      enableReinitialize
      initialValues={props.initial}
      onSubmit={submitOfficialForm}
      className={classes.form}
    >
      {({ submitForm, dirty, values }) => (
        <>
          <Prompt when={dirty && !props.submitted} message={trans('COMMON.CONFIRM_EXIT')} />
          <Paper innerSpacing={[3, 2]} className={classes.paper}>
            <div className="container-fluid pb-3">
              <Heading type={'h5'} className="mb-3">
                {trans('OFFICIALS.EDITOR.SECTIONS.PERSONAL_DATA')}
              </Heading>
              <div className="row mt-4 pb-3">
                <div className="col-3">
                  <InputText name="firstName" labelText={trans('OFFICIALS.EDITOR.FIELDS.FIRSTNAME')} required />
                </div>
                <div className="col-3">
                  <InputText name="lastName" labelText={trans('OFFICIALS.EDITOR.FIELDS.LASTNAME')} required />
                </div>
                <div className="col-3">
                  <InputText name="email" type="email" labelText={trans('OFFICIALS.EDITOR.FIELDS.EMAIL')} required />
                </div>
              </div>
              <Heading type={'h5'} className="mb-3 mt-4">
                {trans('OFFICIALS.EDITOR.SECTIONS.PERMISSIONS')}
              </Heading>
              <div className="row mt-4">
                <div className="col-3">
                  <SelectBox
                    name="role"
                    displayEmpty
                    resettable
                    required
                    disabled={disabledTypeAccount}
                    labelText={trans('OFFICIALS.EDITOR.FIELDS.ACCOUNT_TYPE_LABEL')}
                    placeholderText={trans('OFFICIALS.EDITOR.FIELDS.ACCOUNT_TYPE.EMPTY')}
                    options={compact([
                      {
                        value: OfficialAccountType.ADMIN,
                        label: trans('OFFICIALS.EDITOR.FIELDS.ACCOUNT_TYPE.ADMIN'),
                      },
                      {
                        value: OfficialAccountType.OFFICIAL,
                        label: trans('OFFICIALS.EDITOR.FIELDS.ACCOUNT_TYPE.OFFICIAL'),
                      },
                      settings.institutionalCoreGrantEnabled && {
                        value: OfficialAccountType.APPLICANT,
                        label: trans('OFFICIALS.EDITOR.FIELDS.ACCOUNT_TYPE.APPLICANT'),
                      },
                    ])}
                  />
                </div>
              </div>
              {values.role === OfficialAccountType.OFFICIAL ? (
                <div className="row mt-4 mb-4">
                  <div className="col-12">
                    <DataGrid
                      columns={[
                        {
                          field: 'label',
                          name: 'OFFICIALS.EDITOR.COLUMNS.MODULE_NAME',
                          cellTooltip: () => '',
                        },
                        {
                          field: 'allowedAccessTypes',
                          name: 'OFFICIALS.EDITOR.COLUMNS.RIGHTS',
                          renderer: 'selectCell',
                          rendererParams: {
                            name: 'permissions',
                            placeholderText: '',
                            itemComponent: (option) => {
                              return trans(option.label);
                            },
                          },
                          cellTooltip: () => '',
                        },
                      ]}
                      data={sortedAccessRules}
                      isLoading={false}
                      suppressScrollOnNewData={true}
                      className={classes.grid}
                    />
                  </div>
                </div>
              ) : null}
              {values.role === OfficialAccountType.APPLICANT ? (
                <div className="row mt-4 mb-4">
                  <DataGrid
                    columns={[
                      {
                        field: 'permission',
                        name: '',
                        columnParams: {
                          checkboxSelection: (params) => {
                            return !params.data.isInstitution;
                          },
                          width: 40,
                          minWidth: 40,
                          maxWidth: 40,
                        },
                      },
                      {
                        field: 'unitName',
                        name: 'OFFICIALS.EDITOR.COLUMNS.UNIT',
                        cellTooltip: () => '',
                      },
                    ]}
                    data={getUnits()}
                    isLoading={false}
                    suppressScrollOnNewData={true}
                    className={classes.grid}
                    rowSelection={'multiple'}
                    getRowClass={(params) => {
                      return params.data.isInstitution ? classes.institutionRow : classes.unitRow;
                    }}
                    onSelectionChange={handleRowSelection}
                    onFirstDataRendered={(params) => {
                      params.api.forEachNode((node) => {
                        node.setSelected(values.units.includes(node.data.id));
                      });
                    }}
                  />
                </div>
              ) : null}
            </div>
          </Paper>
          <div className="row my-4">
            <div className="col">
              <Button color="secondary" variant="outlined" onClick={() => props.onCancel()}>
                {trans('COMMON.BACK')}
              </Button>
              <Button
                color="secondary"
                variant="contained"
                className="ml-3"
                onClick={() => submitForm()}
                data-testid="saveButton"
              >
                {trans('COMMON.SAVE')}
              </Button>
            </div>
          </div>
        </>
      )}
    </Form>
  );
};

import { usePrevious } from 'components/shared/hooks/usePrevious/usePrevious';
import { omitBy } from 'lodash';

export const useDifference = (value: object) => {
  const previousValue = usePrevious(value);

  const difference = omitBy(value, (value, key) => {
    return previousValue && previousValue[key] === value;
  });

  return {
    changesValues: difference,
    changesKeys: Object.keys(difference),
  };
};

import { muiStyles } from 'utils/design';
import { Theme } from '@material-ui/core';

export const useStyles = muiStyles((theme: Theme) => ({
  indicator: {
    top: 0,
    bottom: 'unset',
    backgroundColor: theme.palette.secondary.main,
  },
  root: {
    '& button': {
      backgroundColor: '#ffffff',
    },
    boxShadow: theme.shadows[1],
    width: 'fit-content',
  },
}));

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Events } from 'ag-grid-community';
import { Button } from 'components/shared/buttons/Button/Button';
import { DataGrid } from 'components/shared/data/DataGrid/DataGrid';
import { StatusPanelDefinition } from 'components/shared/data/DataGrid/DataGrid.types';
import { useGridEvent } from 'components/shared/data/DataGrid/hooks';
import {
  deselectMasterAndDetails,
  updateDetailsSelectionFromMaster,
  updateMasterSelectionFromDetails,
} from 'components/shared/data/DataGrid/utils/masterDetailsHelpers';
import { getLoadedNodes } from 'components/shared/data/DataGrid/utils/serverSideModelHelpers';
import { ChildrenEditModal } from 'components/shared/dialogs/ChildrenEditModal/ChildrenEditModal';
import {
  childColumns,
  hasChildAlert,
  hasRecipientAlert,
  recipientColumns,
} from 'components/shared/dialogs/DocumentModal/columns.config';
import {
  DocumentModalForm,
  DocumentModalProps,
  DocumentModel,
  DocumentRecipientModel,
  DocumentsDispatchMode,
  DocumentsFormat,
  EditInfo,
  ChildHandlers,
  isMasterRow,
} from 'components/shared/dialogs/DocumentModal/DocumentModal.types';
import { InvalidRecords } from 'components/shared/dialogs/DocumentModal/InvalidRecords';
import { Form, SelectBox } from 'components/shared/form';
import { AutoSave } from 'components/shared/form/AutoSave/AutoSave';
import { useTranslator } from 'components/shared/hooks';
import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon';
import { TextLoader } from 'components/shared/loaders/TextLoader/TextLoader';
import { Tooltip } from 'components/shared/messages/Tooltip/Tooltip';
import { Splash } from 'components/shared/surfaces/Splash/Splash';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useStyles } from './DocumentModal.styles';
import { DocumentSettingsContainer } from './DocumentSettings/DocumentSettingsContainer';
import { uniqueId } from 'lodash';

export const initialValues: DocumentModalForm = {
  templateId: null,
  showInvalidOnly: false,
  config: {
    format: DocumentsFormat.PDF,
    mode: DocumentsDispatchMode.SINGLE_RECIPIENT,
  },
  dcsExport: false,
};

export const DocumentModal = (props: DocumentModalProps): ReactElement => {
  const trans = useTranslator();
  const classes = useStyles();
  const [ids, setIds] = useState<string[]>([]);
  const [columnApi, setColumnApi] = useState(null);
  const [gridApi, setGridApi] = useState(null);
  const [resetPagination, setPaginationResetHandler] = useState(null);
  const [editingItem, setItemToEdit] = useState<EditInfo>(null);
  const [isListEdited, setListEdited] = useState(false);
  const [listVersion, setListVersion] = useState(0);

  const childHandlers: ChildHandlers = {
    edit: (item: DocumentModel) => {
      setItemToEdit({ childId: item.child.id });
    },
  };

  const childColumnsConfig = childColumns(childHandlers);

  const recipientColumnsConfig = recipientColumns({
    fields: props.fields,
    fieldColumnHeaderClass: classes.fieldColumnCell,
    edit: (item: DocumentRecipientModel) => {
      setItemToEdit({
        childId: item.childId,
        guardianId: item.guardian && item.guardian.id,
      });
    },
  });

  useEffect(() => {
    if (props.open) {
      setIds([]);
    }
  }, [props.open]);

  useEffect(() => {
    if (props.fields.length > 0 && columnApi) {
      columnApi.autoSizeColumns();
    }
  }, [props.fields, columnApi]);

  const statusPanels: StatusPanelDefinition[] = [
    {
      statusPanel: 'countSelected',
      statusPanelParams: {
        title: 'TEMPLATES.MODAL.SELECTED_N_CHILDREN',
      },
      align: 'left',
    },
    {
      statusPanel: 'countSelectedInDetails',
      statusPanelParams: {
        title: 'TEMPLATES.MODAL.SELECTED_N_RECIPIENTS',
      },
      align: 'left',
    },
  ];

  const purgeGrid = () => {
    deselectMasterAndDetails(gridApi);
    props.onDataReset();
    resetPagination();
    setIds([]);
    setListVersion((version) => version + 1);
  };

  const onAlertsFilterUpdate = () => {
    deselectMasterAndDetails(gridApi);
    setIds([]);
  };

  const onDocumentsCreate = (values: DocumentModalForm) => {
    props.onDocumentsCreate(values.templateId, {
      items: ids,
      config: values.config,
      dcsExport: values.dcsExport,
    });
  };

  const onGridReady = useCallback(({ agColumnApi, agGridApi, resetPagination }) => {
    setPaginationResetHandler(() => resetPagination);
    setColumnApi(agColumnApi);
    setGridApi(agGridApi);
  }, []);

  useGridEvent(gridApi, Events.EVENT_MODEL_UPDATED, () => {
    for (const node of gridApi.getSelectedNodes()) {
      updateDetailsSelectionFromMaster(node);
    }
  });

  const modalContentRender = (values) => {
    return (
      <DialogContent>
        <div>
          <div className={classes.toolbar}>
            <div className={classes.templateSelect}>
              <SelectBox
                name="templateId"
                placeholderText={trans('TEMPLATES.MODAL.SELECT_TEMPLATE')}
                labelText={trans('TEMPLATES.MODAL.SELECT_TEMPLATE')}
                options={props.templates}
              />
            </div>
            <Button
              variant={'outlined'}
              color={'secondary'}
              onClick={() => {
                setListEdited(false);
                purgeGrid();
              }}
              disabled={!values.templateId}
            >
              <FontAwesomeIcon name={'sync-alt'} className="mr-2" />
              {trans('TEMPLATES.MODAL.REFRESH')}
            </Button>
          </div>

          <InvalidRecords count={props.alerts.rowsAffected} onUpdate={() => onAlertsFilterUpdate()} />

          {values.templateId && (
            <DataGrid<DocumentModel, DocumentRecipientModel>
              key={listVersion}
              rowSelection="multiple"
              className={classes.grid}
              columns={childColumnsConfig}
              onDataFetch={props.onDataFetch}
              onDataProvide={props.onDataProvide}
              limit={props.limit}
              total={props.total}
              onSelectionChange={async (rows, nodes, api) => {
                for (const node of getLoadedNodes(api)) {
                  await updateDetailsSelectionFromMaster(node);
                }
              }}
              onDetailsSelectionChange={() => {
                gridApi.forEachNode((node) => {
                  updateMasterSelectionFromDetails(node);
                });
              }}
              onDetailsCombinedSelectionChange={(rows) => {
                const guardiansIds = rows.map((row) => {
                  return row.guardian ? row.guardian.id : null;
                });
                setIds(guardiansIds);
              }}
              onGridReady={onGridReady}
              statusPanels={statusPanels}
              detailsColumns={recipientColumnsConfig}
              detailsMapping={(row) => row.recipients}
              isRowSelectable={(row) => (row ? !hasChildAlert(row, true) : false)}
              isDetailsRowSelectable={(row) => (row ? !hasRecipientAlert(row) : false)}
              getRowNodeId={(row) => {
                return isMasterRow(row) ? row.child.id : uniqueId();
              }}
            />
          )}

          {!values.templateId && (
            <Splash height={400} className={classes.splash}>
              {trans('TEMPLATES.MODAL.SELECT_TEMPLATE_TO_START')}
            </Splash>
          )}

          <DocumentSettingsContainer />

          <AutoSave />
        </div>
      </DialogContent>
    );
  };

  return (
    <>
      <Dialog disableEscapeKeyDown aria-labelledby="confirm-title" maxWidth={'xl'} fullWidth={true} open={props.open}>
        <Form<DocumentModalForm>
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={(values) => props.onFormUpdate(values)}
          className={classes.form}
        >
          {({ values }) => (
            <>
              {<DialogTitle id="confirm-title">{props.title}</DialogTitle>}

              {!props.loading && props.templates.length > 0 && modalContentRender(values)}

              {!props.loading && props.templates.length === 0 && (
                <Splash height={400}>{trans('TEMPLATES.MODAL.NO_TEMPLATES')}</Splash>
              )}

              {props.loading && <TextLoader height={400} text={trans('TEMPLATES.MODAL.LOADING_TEMPLATES')} />}

              <DialogActions>
                <Button onClick={props.onClose} color="secondary" variant="outlined">
                  {trans('COMMON.CLOSE')}
                </Button>
                <Tooltip
                  title={
                    isListEdited ? trans('TEMPLATES.MODAL.LIST_EDITED') : trans('TEMPLATES.MODAL.NOTHING_SELECTED')
                  }
                  placement="left"
                  disabled={ids.length > 0 && !isListEdited}
                >
                  <Button
                    onClick={() => onDocumentsCreate(values)}
                    disabled={!values.templateId || ids.length === 0 || isListEdited}
                    color="primary"
                    variant="contained"
                  >
                    {trans('COMMON.GENERATE')}
                  </Button>
                </Tooltip>
              </DialogActions>
            </>
          )}
        </Form>
      </Dialog>
      <ChildrenEditModal
        title={trans('CHILD_REGISTER.BROWSER.TITLE')}
        open={editingItem !== null}
        childrenId={editingItem && editingItem.childId}
        scrollTo={{
          tab: 'general',
          elementId: editingItem && editingItem.guardianId ? `guardian-${editingItem.guardianId}` : 'guardians-form',
        }}
        onClose={(updated) => {
          if (updated) {
            setListEdited(true);
          }
          setItemToEdit(null);
        }}
      />
    </>
  );
};

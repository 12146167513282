import { DatabaseModel } from 'store/schoolDistricts';

export interface AssignmentDialogProps {
  open: boolean;
  onClose: () => void;
  databases: DatabaseModel[];
  onOpenAssignment: (assignmentId: string) => void;
}

export const createAssignmentSelectedDatabaseDefault = 'no-mapping';

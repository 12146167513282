import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { flatten } from 'lodash';
import { AppDispatch } from '@typings/redux';
import { SynchronizationLog } from 'components/reports/Synchronizations/Synchronizations';
import { synchronizationLogSelector } from 'store/reports/reports.selector';
import { getSynchronizationLog, clearSynchronizationLogData } from 'store/reports/reports.action';

export const SynchronizationsContainer: FC = () => {
  const { list, total, filters } = useSelector(synchronizationLogSelector);
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(clearSynchronizationLogData());
  }, [dispatch, filters]);
  const onLogsFetch = useCallback((page) => dispatch(getSynchronizationLog(page, filters)), [dispatch, filters]);
  const onLogsProvide = useCallback((page) => list[page], [list]);
  const fetchedItemsCount = flatten(list).length - 1;

  return (
    <SynchronizationLog
      onLogsFetch={onLogsFetch}
      onLogsProvide={onLogsProvide}
      totalLogs={total}
      fetchedItemsCount={fetchedItemsCount}
    />
  );
};

import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import React from 'react';
import { components } from 'react-select';

export const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <ArrowDropDown />
      </components.DropdownIndicator>
    )
  );
};

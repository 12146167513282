import { FSA } from '@typings/redux';
import { TerytActionType } from './teryt.actionTypes';
import { RSAACreator, RSAA } from 'redux-api-middleware';
import { TerytFilters, CountyFilters, CommuneFilters, TownFilters, StreetFilters } from './teryt.types';

export const fetchVoivodeshipsList: RSAACreator<TerytActionType> = (filters: TerytFilters) => {
  return {
    [RSAA]: {
      endpoint: 'teryt/voivodeships',
      method: 'GET',
      types: [
        {
          type: TerytActionType.FETCH_VOIVODESHIPS_REQUEST,
          meta: {
            takeLatest: true,
          },
        },
        TerytActionType.FETCH_VOIVODESHIPS_SUCCESS,
        TerytActionType.FETCH_VOIVODESHIPS_FAILURE,
      ],
      params: filters,
    },
  };
};

export const fetchCountiesList: RSAACreator<TerytActionType> = (filters: CountyFilters) => {
  return {
    [RSAA]: {
      endpoint: 'teryt/counties',
      method: 'GET',
      types: [
        {
          type: TerytActionType.FETCH_COUNTIES_REQUEST,
          meta: {
            takeLatest: true,
          },
        },
        TerytActionType.FETCH_COUNTIES_SUCCESS,
        TerytActionType.FETCH_COUNTIES_FAILURE,
      ],
      params: filters,
    },
  };
};

export const fetchCommunesList: RSAACreator<TerytActionType> = (filters: CommuneFilters) => {
  return {
    [RSAA]: {
      endpoint: 'teryt/communes',
      method: 'GET',
      types: [
        {
          type: TerytActionType.FETCH_COMMUNES_REQUEST,
          meta: {
            takeLatest: true,
          },
        },
        TerytActionType.FETCH_COMMUNES_SUCCESS,
        TerytActionType.FETCH_COMMUNES_FAILURE,
      ],
      params: filters,
    },
  };
};

export const fetchTownsList: RSAACreator<TerytActionType> = (filters: TownFilters) => {
  return {
    [RSAA]: {
      endpoint: 'teryt/basic-cities',
      method: 'GET',
      types: [
        {
          type: TerytActionType.FETCH_TOWNS_REQUEST,
          meta: {
            takeLatest: true,
          },
        },
        TerytActionType.FETCH_TOWNS_SUCCESS,
        TerytActionType.FETCH_TOWNS_FAILURE,
      ],
      params: filters,
    },
  };
};

export const clearStreetList = (): FSA<TerytActionType> => {
  return {
    type: TerytActionType.CLEAR_STREETS,
  };
};

export const clearTownList = (): FSA<TerytActionType> => {
  return {
    type: TerytActionType.CLEAR_TOWNS,
  };
};

export const fetchStreetsList: RSAACreator<TerytActionType> = (filters: StreetFilters) => {
  return {
    [RSAA]: {
      endpoint: 'teryt/streets',
      method: 'GET',
      types: [
        {
          type: TerytActionType.FETCH_STREETS_REQUEST,
          meta: {
            takeLatest: true,
          },
        },
        TerytActionType.FETCH_STREETS_SUCCESS,
        TerytActionType.FETCH_STREETS_FAILURE,
      ],
      params: filters,
    },
  };
};

export const fetchTown: RSAACreator<TerytActionType> = (id: string) => {
  return {
    [RSAA]: {
      endpoint: `teryt/cities/${id}`,
      method: 'GET',
      types: [
        TerytActionType.FETCH_TOWN_REQUEST,
        TerytActionType.FETCH_TOWN_SUCCESS,
        {
          type: TerytActionType.FETCH_TOWN_FAILURE,
          meta: {
            ignoreNotFoundError: true,
          },
        },
      ],
      params: {},
    },
  };
};

export const fetchStreet: RSAACreator<TerytActionType> = (id: string) => {
  return {
    [RSAA]: {
      endpoint: `teryt/streets/${id}`,
      method: 'GET',
      types: [
        TerytActionType.FETCH_STREET_REQUEST,
        TerytActionType.FETCH_STREET_SUCCESS,
        {
          type: TerytActionType.FETCH_STREET_FAILURE,
          meta: {
            ignoreNotFoundError: true,
          },
        },
      ],
      params: {},
    },
  };
};

export const fetchCommune: RSAACreator<TerytActionType> = (id: string) => {
  return {
    [RSAA]: {
      endpoint: `teryt/communes/${id}`,
      method: 'GET',
      types: [
        TerytActionType.FETCH_COMMUNE_REQUEST,
        TerytActionType.FETCH_COMMUNE_SUCCESS,
        {
          type: TerytActionType.FETCH_COMMUNE_FAILURE,
          meta: {
            ignoreNotFoundError: true,
          },
        },
      ],
      params: {},
    },
  };
};

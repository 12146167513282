import { DatabaseModel, DatabaseModelBrowserRow, DatabasePostModel } from 'store/schoolDistricts';

export enum DatabaseAction {
  ADD = 'add',
}

export interface DatabaseContainerProps {
  initialAction?: DatabaseAction;
}

export interface DatabaseProps {
  resultsLoading: boolean;
  databases: DatabaseModel[];
  databasesGrid: DatabaseModelBrowserRow[];
  addItem: (payload: DatabasePostModel) => Promise<void>;
  editItem: (id: string, name: string) => Promise<void>;
  deleteItem: (id: string) => Promise<any>;
  selectItem: (id: string) => void;
  setCurrentItem: (id: string) => Promise<any>;
  setArchiveItem: (id: string) => Promise<any>;
  fetchSchoolDistrictDatabase: () => Promise<any>;
  withOpenAddModal: boolean;
  onOpenAssignment: (assignmentId: string) => void;
  selectedDatabaseId: string;
}

export interface ActionHandlers {
  edit: (data: DatabaseModel) => void;
  delete: (data: DatabaseModel) => void;
  select: (data: DatabaseModel) => void;
  setCurrent: (data: DatabaseModel) => void;
  setArchive: (data: DatabaseModel) => void;
}

export const ADDRESS_RULE_CONFLICT_ALERT = 'CATCHMENT_SCHOOLS.ADDRESS_RULES_CONFLICT';

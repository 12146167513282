import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { useEnvironment } from 'components/shared/hooks/useEnvironment/useEnvironment';
import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon';
import { Tooltip } from 'components/shared/messages/Tooltip/Tooltip';
import { CollapsibleSubmenu } from '../CollapsibleSubmenu/CollapsibleSubmenu';
import { FloatingSubmenu } from '../FloatingSubmenu/FloatingSubmenu';
import { useStyles } from './NavigationItem.styles';
import { useSubmenuState } from './useSubmenuState/useSubmenuState';
import { push } from 'connected-react-router';
import { LocaleContext } from 'context/locale';
import React from 'react';
import { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isSubmenuItem, MenuItem } from '../NavigationList.types';
import { NavigationItemProps } from './NavigationItem.types';
import { AppDispatch } from '@typings/redux';
import { useMultipeCheckAccess } from 'components/shared/hooks/useCheckAccess/useCheckAccess';

const renderExpander = (open: boolean) => {
  return open ? <ExpandLess /> : <ExpandMore />;
};

const hasSubItems = (item: MenuItem) => {
  return !isSubmenuItem(item) && item.children && item.children.length > 0;
};

export const NavigationItem: React.FC<NavigationItemProps> = ({ item, useFloatingMenu }) => {
  const { trans } = useContext(LocaleContext);
  const dispatch = useDispatch<AppDispatch>();
  const classes = useStyles(useFloatingMenu);
  const environment = useEnvironment();

  const [submenuOpen, setSubmenuOpen] = useSubmenuState(useFloatingMenu);
  const [clickedElement, setClickedElement] = useState<HTMLElement>(null);
  const showElement = useMultipeCheckAccess(item.resourcesGroups, item.accessType);
  const defaultHidden = () => false;
  const hidenSelector = item.hidden || defaultHidden;
  const isHidden = useSelector(hidenSelector);
  if (!showElement || isHidden) return null;
  const floatingSubmenuDismissedHandler = () => {
    setSubmenuOpen(false);
    setTimeout(() => clickedElement.focus());
  };

  const clickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    if (hasSubItems(item)) {
      setSubmenuOpen(!submenuOpen);
      setClickedElement(event.currentTarget);
    } else {
      if (item.callback) {
        item.callback(dispatch, environment);
      }
      if (item.path) {
        dispatch(push(item.path));
      }
    }
  };

  return (
    <>
      <ListItem button onClick={clickHandler}>
        <Tooltip title={trans(item.label)} placement="right" disabled={!useFloatingMenu}>
          <ListItemIcon className={classes.iconWrapper}>
            <FontAwesomeIcon name={item.icon} className={classes.icon} color={'default'} />
          </ListItemIcon>
        </Tooltip>

        <ListItemText primary={trans(item.label)} />

        {hasSubItems(item) && renderExpander(submenuOpen)}

        {hasSubItems(item) && useFloatingMenu && (
          <FloatingSubmenu
            items={item.children}
            open={submenuOpen}
            anchor={clickedElement}
            onDismiss={floatingSubmenuDismissedHandler}
          />
        )}
      </ListItem>

      {hasSubItems(item) && !useFloatingMenu && <CollapsibleSubmenu items={item.children} open={submenuOpen} />}
    </>
  );
};

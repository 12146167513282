import { Translator } from 'context/locale/LocaleContext/LocaleContext.types';
import { get, isArray, isEmpty } from 'lodash';

export const getError = (formikErrors: any, field: string, trans?: Translator): string => {
  const extractedValue = get(formikErrors, field, false);
  let error: string = null;

  if (isEmpty(extractedValue)) {
    return null;
  }

  if (isArray(extractedValue)) {
    error = extractedValue[0] as unknown as string;
  } else {
    error = extractedValue as unknown as string;
  }

  return trans ? trans(error) : error;
};

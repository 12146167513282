import { AppState } from 'store/store';

export const childRegisterSelector = (state: AppState) => state.childRegister.list;
export const alertTypesSelector = (state: AppState) => state.childRegister.alertTypes;
export const guardianTypesListSelector = (state: AppState) => state.childRegister.guardianTypes.list;
export const guardianCustomTypesListSelector = (state: AppState) => state.childRegister.guardianTypes.customType;
export const birthYearsSelector = (state: AppState) => state.childRegister.birthYears;

export const childSelector = (state: AppState) => state.childRegister.child.data;
export const childLoadingSelector = (state: AppState) => state.childRegister.child.loading;

export const addressHistoryLoadingSelector = (state: AppState) => state.childRegister.addressHistory.loading;
export const addressHistoryListSelector = (state: AppState) => state.childRegister.addressHistory.list;

export const educationPathSelector = (state: AppState) => state.childRegister.educationPath.list;
export const isEducationPathLoadingSelector = (state: AppState) => state.childRegister.educationPath.loading;

export const obligationReportsSelector = (state: AppState) => state.childRegister.obligationReports;
export const obligationActionsSelector = (state: AppState) => state.childRegister.obligationActions;

export const importFileSelector = (state: AppState) => state.childRegister.import.file;
export const importTypeSelector = (state: AppState) => state.childRegister.import.importType;
export const importTokenSelector = (state: AppState) => state.childRegister.import.token;
export const importContextSelector = (state: AppState) => state.childRegister.import.context;
export const importColumnsSelector = (state: AppState) => state.childRegister.import.columns;
export const importErrorSelector = (state: AppState) => state.childRegister.import.error;
export const importPreviewSelector = (state: AppState) => state.childRegister.import.preview;
export const importPreviewStatusSelector = (state: AppState) => state.childRegister.import.previewStatus;
export const importLoadingSelector = (state: AppState) => state.childRegister.import.loading;

import React, { FC, useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { otherCommuneStatSelector } from 'store/reports/reports.selector';
import { AppDispatch } from '@typings/redux';
import { fetchOtherCommunesStats } from 'store/reports/reports.action';
import { OtherCommune } from 'components/reports/OtherCommune/OtherCommune';
import { useDepartmentSettings } from 'components/shared/hooks/useInstanceConfig/useDepartmentSettings';
import { fetchCommune } from 'store/teryt';
import { formatCommuneSummary } from 'components/shared/teryt/CommunePicker/CommunePicker.helpers';
import { LocaleContext } from 'context/locale';

export const OtherCommuneContainer: FC = () => {
  const { list, loading } = useSelector(otherCommuneStatSelector);
  const dispatch = useDispatch<AppDispatch>();
  const { osinCommune } = useDepartmentSettings();
  const [communeSummary, setCommuneSummary] = useState('');
  const [hasFetched, setHasFetched] = useState(false);
  const { locale, trans } = useContext(LocaleContext);

  useEffect(() => {
    setHasFetched(false);
    dispatch(fetchOtherCommunesStats());
    dispatch(fetchCommune(osinCommune)).then((result) => {
      if (!result.error && result.payload) {
        setCommuneSummary(formatCommuneSummary(result.payload, locale, trans));
      }
      setHasFetched(true);
    });
  }, [dispatch, locale, trans, osinCommune]);

  return <OtherCommune list={list} isLoading={loading && !hasFetched} communeSummary={communeSummary} />;
};

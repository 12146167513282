import { useStyles } from 'components/childRegister/Editor/Obligation/Obligation.styles';
import { ObligationProps } from 'components/childRegister/Editor/Obligation/Obligation.types';
import { ExemptionsContainer } from 'components/childRegister/Editor/Obligation/parts/Exemptions/ExemptionsContainer';
import { ManualEntriesContainer } from 'components/childRegister/Editor/Obligation/parts/ManualEntries/ManualEntriesContainer';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import { ReportsContainer } from './parts/Reports/ReportsContainer';

export const Obligation: FC<ObligationProps> = (props) => {
  const classes = useStyles();

  return (
    <Paper innerSpacing={[4, 2, 2]} square={true} className={classes.container}>
      <Box marginBottom={4}>
        <ExemptionsContainer />
      </Box>
      <ManualEntriesContainer />
      <ReportsContainer childId={props.childId} />
    </Paper>
  );
};

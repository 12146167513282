import { AccessRule } from 'store/accessRules';

export interface AccessRulesState {
  items: AccessRule[];
  loading: boolean;
  error: any;
}

export const defaultState: AccessRulesState = {
  items: [],
  loading: false,
  error: null,
};

import React, { FC, useState } from 'react';
import { OtherCommuneStat } from 'store/reports/reports.types';
import { DataGrid } from 'components/shared/data/DataGrid/DataGrid';
import { OtherCommuneHandlers, OtherCommuneProps } from 'components/reports/OtherCommune/OtherCommune.types';
import { defaultColumn, otherCommuneColumns } from 'components/reports/OtherCommune/columns';
import { ActionButton } from 'components/shared/buttons/ActionButton/ActionButton';
import { DataGridApi } from 'components/shared/data/DataGrid/DataGrid.types';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import { Box } from 'components/shared/surfaces/Box/Box';
import { useStyles } from 'components/reports/OtherCommune/OtherCommune.styles';
import { StudentsListModalContainer } from 'components/reports/OtherCommune/StudentsListModal/StudentsListModalContainer';
import { AppDispatch } from '@typings/redux';
import { useDispatch } from 'react-redux';
import { clearOtherCommunesStudentsList } from 'store/reports/reports.action';
import { useTranslator } from 'components/shared/hooks';
import Typography from '@material-ui/core/Typography';
import { TextLoader } from 'components/shared/loaders/TextLoader/TextLoader';
import { Splash } from 'components/shared/surfaces/Splash/Splash';
import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon';
import { colors } from 'styles/colors';

export const OtherCommune: FC<OtherCommuneProps> = ({ list, isLoading, communeSummary }) => {
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();
  const [gridApi, setGridApi] = useState(null);
  const [isModalVisible, toggleModal] = useState(false);
  const [modalValues, setModalValues] = useState({ unitId: null, communeId: null, communeName: null });
  const trans = useTranslator();

  const onGridReady = ({ agGridApi }: DataGridApi) => {
    setGridApi(agGridApi);
  };

  const actionHandlers: OtherCommuneHandlers = {
    show: (unitId, communeId, communeName) => {
      setModalValues({ unitId, communeId, communeName });
      dispatch(clearOtherCommunesStudentsList());
      toggleModal(true);
    },
  };

  const exportParams = {
    fileName: trans('REPORTS.OTHER_COMMUNE.EXPORT.TITLE'),
    columnKeys: ['commune', 'institution', 'unit', 'educationStage', 'accreditation', 'studentsCount'],
  };

  const renderLoader = () => (
    <Splash>
      <TextLoader name="spinner" text={trans('CHILD_REGISTER.FORM.LOADING')} />
    </Splash>
  );

  const renderView = () => (
    <>
      <Box margin={[0, 2, 2]} grow>
        <div className="my-2 d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <FontAwesomeIcon name="info-circle" className="mr-1" color={colors.primary} size="lg" />
            <Typography variant="subtitle2" className="font-weight-normal">
              {trans('REPORTS.OTHER_COMMUNE.ADDRESS_INFO')}
              <Typography variant="subtitle2" component="span" className="font-weight-bold">
                {' '}
                {communeSummary}
              </Typography>
            </Typography>
          </div>
          <ActionButton
            color="default"
            label="REPORTS.SCHOOL_STAT_DETAILS.EXPORT.TITLE"
            actionList={[
              {
                action: () => gridApi && gridApi.exportDataAsCsv(exportParams),
                label: 'REPORTS.OTHER_COMMUNE.EXPORT.CSV',
                icon: 'file-csv',
              },
              {
                action: () =>
                  gridApi &&
                  gridApi.exportDataAsExcel({
                    ...exportParams,
                    sheetName: trans('REPORTS.OTHER_COMMUNE.EXPORT.TITLE'),
                  }),
                label: 'REPORTS.OTHER_COMMUNE.EXPORT.EXCEL',
                icon: 'file-excel',
              },
            ]}
          />
        </div>
        <Paper innerSpacing={2} className={classes.paper}>
          <DataGrid<OtherCommuneStat>
            columns={otherCommuneColumns(actionHandlers)}
            defaultColumn={defaultColumn()}
            data={list}
            isLoading={isLoading}
            onGridReady={onGridReady}
          />
        </Paper>
      </Box>
      <StudentsListModalContainer onClose={() => toggleModal(false)} open={isModalVisible} {...modalValues} />
    </>
  );

  return isLoading ? renderLoader() : renderView();
};

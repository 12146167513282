import { Action } from 'react-fetching-library';
import { ActionCreator } from 'redux';

export interface DocumentActionParams {
  documentId: string;
}

export const getDocumentFile: ActionCreator<Action> = ({ documentId }: DocumentActionParams) => ({
  method: 'GET',
  endpoint: `/templates/documents/${documentId}`,
  responseType: 'blob',
});

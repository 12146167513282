import { Behaviours, FSAReducer } from '@typings/redux';
import {
  defaultState,
  SchoolObligationsActionType as ActionType,
  SchoolObligationsState as State,
} from 'store/schoolObligations';
import { RESET_STATE } from 'store/store';
import { fromApiFormat } from 'utils/api';
import { insertPage } from 'utils/pagination/insertPage';

export const schoolObligationReducer: FSAReducer<State, ActionType> = (state = defaultState, action) => {
  const behaviours: Behaviours<State, ActionType> = {
    [RESET_STATE]: () => ({
      ...defaultState,
    }),
    [ActionType.FETCH_BIRTH_YEARS_REQUEST]: () => {
      return {
        ...state,
        birthYears: {
          ...state.birthYears,
          loading: true,
        },
      };
    },
    [ActionType.FETCH_BIRTH_YEARS_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        birthYears: {
          ...state.birthYears,
          list: fromApiFormat(payload).birthYears,
          loading: false,
        },
      };
    },
    [ActionType.FETCH_SCHOOL_OBLIGATIONS_REPORTS_REQUEST]: () => {
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: true,
        },
      };
    },
    [ActionType.FETCH_SCHOOL_OBLIGATIONS_REPORTS_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        reports: {
          items: fromApiFormat(payload).items,
          loading: false,
        },
      };
    },
    [ActionType.FETCH_SCHOOL_OBLIGATIONS_REPORTS_FAILURE]: ({ error }) => {
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: false,
          error,
        },
      };
    },
    [ActionType.FETCH_SCHOOL_OBLIGATIONS_REPORT_DETAILS_REQUEST]: () => {
      return {
        ...state,
        reportDetails: {
          ...state.reportDetails,
          loading: true,
          report: null,
        },
      };
    },
    [ActionType.FETCH_SCHOOL_OBLIGATIONS_REPORT_DETAILS_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        reportDetails: {
          ...state.reportDetails,
          loading: false,
          report: fromApiFormat(payload),
        },
      };
    },
    [ActionType.FETCH_SCHOOL_OBLIGATIONS_REPORT_DETAILS_FAILURE]: ({ error }) => {
      return {
        ...state,
        reportDetails: {
          ...state.reportDetails,
          loading: false,
          error: error,
        },
      };
    },
    [ActionType.CREATE_SCHOOL_OBLIGATION_REPORT_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        reportRequest: {
          ...state.reportRequest,
          reportId: fromApiFormat(payload).id,
        },
      };
    },
    [ActionType.CREATE_SCHOOL_OBLIGATION_REPORT_FAILURE]: ({ error }) => {
      return {
        ...state,
        reportRequest: {
          ...state.reportRequest,
          error,
        },
      };
    },
    [ActionType.CLEAR_REPORT_STATUS]: () => {
      return {
        ...state,
        reportRequest: {
          ...state.reportRequest,
          reportStatus: {
            ...defaultState.reportRequest.reportStatus,
          },
        },
      };
    },
    [ActionType.FETCH_REPORT_STATUS_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        reportRequest: {
          ...state.reportRequest,
          reportStatus: fromApiFormat(payload),
        },
      };
    },
    [ActionType.FETCH_REPORT_STATUS_FAILURE]: ({ error }) => {
      return {
        ...state,
        reportRequest: {
          ...state.reportRequest,
          error,
        },
      };
    },
    [ActionType.SET_SCHOOL_OBLIGATIONS_REPORT_CHILDREN_LIST_FILTERS]: ({ payload }) => {
      return {
        ...state,
        reportChildrenList: {
          ...state.reportChildrenList,
          filters: payload,
        },
      };
    },
    [ActionType.CLEAR_SCHOOL_OBLIGATIONS_REPORT_CHILDREN_LIST]: () => {
      return {
        ...state,
        reportChildrenList: {
          ...state.reportChildrenList,
          pages: [],
        },
      };
    },
    [ActionType.FETCH_SCHOOL_OBLIGATIONS_REPORT_CHILDREN_LIST_REQUEST]: () => {
      return {
        ...state,
        reportChildrenList: {
          ...state.reportChildrenList,
          loading: true,
        },
      };
    },
    [ActionType.FETCH_SCHOOL_OBLIGATIONS_REPORT_CHILDREN_LIST_SUCCESS]: ({ payload, meta }) => {
      return {
        ...state,
        reportChildrenList: {
          ...state.reportChildrenList,
          pages: insertPage(state.reportChildrenList.pages, fromApiFormat(payload.items), meta.page),
          total: payload.metadata.total,
          loading: false,
        },
      };
    },
    [ActionType.FETCH_SCHOOL_OBLIGATIONS_REPORT_INSTITUTIONS_LIST_REQUEST]: () => {
      return {
        ...state,
        institutions: {
          ...state.institutions,
          loading: true,
        },
      };
    },
    [ActionType.FETCH_SCHOOL_OBLIGATIONS_REPORT_INSTITUTIONS_LIST_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        institutions: {
          loading: false,
          items: fromApiFormat(payload).items,
        },
      };
    },
    [ActionType.FETCH_SCHOOL_OBLIGATIONS_REPORT_INSTITUTIONS_LIST_FAILURE]: ({ error }) => {
      return {
        ...state,
        institutions: {
          ...state.institutions,
          loading: false,
          error,
        },
      };
    },
    [ActionType.FETCH_FULFILLMENT_OPTIONS_REQUEST]: () => {
      return {
        ...state,
        fulfillmentOptions: {
          ...state.fulfillmentOptions,
          loading: true,
        },
      };
    },
    [ActionType.FETCH_FULFILLMENT_OPTIONS_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        fulfillmentOptions: {
          loading: false,
          items: fromApiFormat(payload).nodes,
        },
      };
    },
    [ActionType.SET_REPORTS_FILTERS]: ({ payload }) => {
      return {
        ...state,
        reportBrowserFilters: payload,
      };
    },
    [ActionType.CLEAR_REPORTS_FILTERS]: () => {
      return {
        ...state,
        reportBrowserFilters: defaultState.reportBrowserFilters,
      };
    },
  };

  return behaviours[action.type] ? behaviours[action.type](action) : state;
};

import { AppDispatch } from '@typings/redux';
import { Editor } from 'components/childRegister/Editor/Editor';
import { EditContainerProps } from 'components/childRegister/Editor/Editor.types';
import { useFormatter, useNotification } from 'components/shared/hooks';
import { FormikHelpers } from 'formik';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ChildRegisterFormValues } from 'components/childRegister/Editor/General/General.types';
import { useDispatch, useSelector } from 'react-redux';
import { mapChildModelToForm, mapFormValueToPayload } from 'components/childRegister/Editor/Editor.helpers';
import { childLoadingSelector, childSelector } from 'store/childRegister';
import {
  fetchChildDetails,
  fetchGuardianTypes,
  putChild,
  clearChildDetails,
} from 'store/childRegister/childRegister.actions';
import { useCheckAccessSameType } from 'components/shared/hooks';
import { OfficialPermissionLevel, OfficialResourceGroup } from 'store/officials';

export const EditorEditContainer: FC<EditContainerProps> = ({ id, goBack, showGuardiansHelp, scrollTo }) => {
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const { success } = useNotification();
  const { formatApiErrors } = useFormatter();

  const dispatch = useDispatch<AppDispatch>();
  const child = useSelector(childSelector);
  const loading = useSelector(childLoadingSelector);
  const userReadOnly = useCheckAccessSameType(OfficialResourceGroup.childRegister, OfficialPermissionLevel.readOnly);

  useEffect(() => {
    if (!userReadOnly) return;
    setSubmitSuccess(true);
  }, [userReadOnly]);

  useEffect(() => {
    dispatch(fetchChildDetails(id));
    dispatch(fetchGuardianTypes());

    return () => {
      dispatch(clearChildDetails());
    };
  }, [dispatch, id]);

  const formInitialValues = useMemo(() => child && mapChildModelToForm(child), [child]);

  const handleSubmit = useCallback(
    (values: ChildRegisterFormValues) => {
      return dispatch(putChild(mapFormValueToPayload(values), id));
    },
    [dispatch, id],
  );

  const handleOnSubmit = (values: ChildRegisterFormValues, formik: FormikHelpers<ChildRegisterFormValues>) => {
    handleSubmit(values).then((result) => {
      if (result.error) {
        if (result.payload.response.errors) {
          formik.setErrors(formatApiErrors(result.payload.response.errors));
        }
        formik.setSubmitting(false);
      } else {
        setSubmitSuccess(true);
        success('CHILD_REGISTER.FORM.SUCCESS');
        goBack(true);
      }
    });
  };

  return (
    <Editor
      goBack={() => goBack(false)}
      onSubmit={handleOnSubmit}
      submitted={submitSuccess}
      loading={loading}
      initial={formInitialValues}
      child={child}
      showGuardiansHelp={showGuardiansHelp}
      scrollTo={scrollTo}
    />
  );
};

import { Theme } from '@material-ui/core';

export const formPreviewThemeFactory = (parentTheme: Theme): Theme => {
  return {
    ...parentTheme,
    overrides: {
      MuiInputBase: {
        root: {
          '&.Mui-disabled': {
            color: parentTheme.palette.text.primary,
            WebkitTextFillColor: parentTheme.palette.text.primary,
            WebkitOpacity: 1,
          },
        },
      },
      MuiIconButton: {
        root: {
          '&.Mui-disabled': {
            color: parentTheme.palette.text.secondary,
          },
        },
      },
      MuiFormControlLabel: {
        label: {
          '&.Mui-disabled': {
            color: parentTheme.palette.text.primary,
          },
        },
      },
      MuiFormHelperText: {
        root: {
          marginTop: '8px',
          minHeight: '1em',
        },
      },
    },
  };
};

import { useNotification } from 'components/shared/hooks';
import React, { FC, useState } from 'react';
import { DialogTitle, Dialog, DialogContent, Stepper, Step, StepLabel } from '@material-ui/core';
import { useStyles } from 'components/shared/dialogs/StepModal/StepModal.styles';
import { StepModalProps } from 'components/shared/dialogs/StepModal/StepModal.types';
import { IconButton } from 'components/shared/buttons/Button/IconButton';
import { Close } from '@material-ui/icons';

export const StepModal: FC<StepModalProps> = ({ open, title, steps, onClose }) => {
  const [activeStep, setActiveStep] = useState(0);
  const classes = useStyles();
  const { error: showError } = useNotification();

  const goNext = () => {
    setActiveStep((activeStep) => activeStep + 1);
  };

  const goBack = () => {
    setActiveStep((activeStep) => activeStep - 1);
  };

  const onFailure = (error: string) => {
    setActiveStep(0);
    showError(error);
  };

  return (
    <Dialog
      fullWidth
      maxWidth={'md'}
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
    >
      <DialogTitle id="max-width-dialog-title">
        {title}
        {onClose ? (
          <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
            <Close />
          </IconButton>
        ) : null}
      </DialogTitle>

      {steps[activeStep].content({ goNext, goBack, activeStep, onClose, onFailure, open })}

      <DialogContent className={classes.stepper}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((step) => (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </DialogContent>
    </Dialog>
  );
};

import React, { FC, useRef } from 'react';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import { useStyles } from 'components/childRegister/Editor/General/General.styles';
import { GeneralFormProps } from 'components/childRegister/Editor/General/General.types';
import { PersonalData } from 'components/childRegister/Editor/General/parts/PersonalData';
import { Address } from 'components/childRegister/Editor/General/parts/Address';
import { GuardianData } from 'components/childRegister/Editor/General/parts/GuardianData';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@typings/redux';
import { setCustomGuardianTypes } from 'store/childRegister/childRegister.actions';
import { guardianCustomTypesListSelector } from 'store/childRegister';

export interface AutocompleteChangeValue {
  label: string;
  value: string;
  __isNew__?: boolean;
}

export const General: FC<GeneralFormProps> = (props) => {
  const classes = useStyles();
  const formContentRef = useRef<HTMLDivElement>();
  const dispatch = useDispatch<AppDispatch>();
  const customGuardianTypes = useSelector(guardianCustomTypesListSelector);

  const scrollToBottom = () => {
    if (formContentRef.current) {
      formContentRef.current.scrollTop = formContentRef.current.scrollHeight;
    }
  };

  const handleAutocompleteChange = (selection: AutocompleteChangeValue) => {
    if (selection && selection.__isNew__ && selection.value) {
      dispatch(setCustomGuardianTypes(selection.value));
    }
  };

  return (
    <Paper innerSpacing={[4, 2]} square={true} className={classes.paper} ref={formContentRef}>
      <PersonalData form={props.form} />
      <Address id={props.id} alerts={props.alerts} />
      <GuardianData
        guardianTypes={[...props.guardianTypes, ...customGuardianTypes.map((type) => ({ label: type, value: type }))]}
        onAddNewGuardian={scrollToBottom}
        handleAutocompleteChange={handleAutocompleteChange}
        showHelpMessage={props.showGuardiansHelp}
      />
    </Paper>
  );
};

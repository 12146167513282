import React, { FC, useContext, Fragment } from 'react';
import { Breadcrumb } from 'components/shared/navigation/Breadcrumbs/Breadcrumb';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import { Page } from 'components/layout/panel/Page/Page';
import { DistrictsBrowserContainer } from 'components/schoolDistricts/DistrictsBrowser/DistrictsBrowserContainer';

import { useStyles } from './DistrictsList.styles';
import { LocaleContext } from 'context/locale';
import { DistrictsListViewProps } from './DistrictsList.types';

export const DistrictsListView: FC<DistrictsListViewProps> = (props) => {
  const { trans } = useContext(LocaleContext);
  const classes = useStyles();
  const initialDatabaseId = props.location.state ? props.location.state.databaseId : null;

  return (
    <Fragment>
      <Breadcrumb label={trans('BREADCRUMB.SCHOOL-DISTRICTS.DISTRICTS')} static />
      <Breadcrumb label={trans('BREADCRUMB.SCHOOL-DISTRICTS.DISTRICTS-LIST')} static />

      <Page title={trans('SCHOOL_DISTRICTS.DISTRICTS-LIST.TITLE')}>
        <Paper innerSpacing={2} outerSpacing={[0, 2, 2]} className={classes.paper}>
          <DistrictsBrowserContainer initialDatabaseId={initialDatabaseId} />
        </Paper>
      </Page>
    </Fragment>
  );
};

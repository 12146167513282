import { LocationForm } from './LocationModal.types';

type Fields = keyof LocationForm;

export const fieldsBelow = (field: Fields): string[] => {
  return {
    country: ['voivodeship', 'county', 'commune', 'town', 'foreignTown'],
    voivodeship: ['county', 'commune', 'town'],
    county: ['commune', 'town'],
    commune: ['town'],
    town: null,
  }[field];
};

export const fieldsAbove = (field: Fields): string[] => {
  return {
    country: null,
    voivodeship: ['country'],
    county: ['country', 'voivodeship'],
    commune: ['country', 'voivodeship', 'county'],
    town: ['country', 'voivodeship', 'county', 'commune'],
  }[field];
};

export const isFieldEnabled = (field: Fields, values: LocationForm) => {
  const fields = fieldsAbove(field);

  return fields
    ? fields.reduce((isFilled, fieldValue) => {
        return isFilled && !!values[fieldValue];
      }, true)
    : true;
};

export const resetFieldsBelow = (field: Fields, setValue: (field: string, value: any) => void) => {
  const fields = fieldsBelow(field);
  return (
    fields &&
    fieldsBelow(field).map((fieldBelow) => {
      if (fieldBelow === 'foreignTown') {
        setValue(fieldBelow, '');
      } else {
        setValue(fieldBelow, null);
      }
      return true;
    })
  );
};

import { useStyles } from 'components/shared/messages/Infobar/Infobar.styles';
import React, { FC } from 'react';
import classNames from 'classnames';

interface InfobarWrapperProps {
  className?: string;
}

export const InfobarWrapper: FC<InfobarWrapperProps> = ({ children, className }) => {
  const classes = useStyles();

  return <div className={classNames(classes.wrapper, className)}>{children}</div>;
};

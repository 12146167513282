import { AuthorizationProps } from 'components/auth/Authorization/Authorization.types';
import { useAuthCode } from 'components/auth/Authorization/useAuthCode';
import { useNotification } from 'components/shared/hooks';
import { FullScreenLoader } from 'components/shared/loaders/FullScreenLoader/FullScreenLoader';
import { ROUTE_AUTH_LOGIN, ROUTE_ROOT } from 'config/routes';
import { push } from 'connected-react-router';
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const Authorization: FC<AuthorizationProps> = ({ authCode }) => {
  const { resolved, authorized, error } = useAuthCode(authCode);
  const dispatch = useDispatch();
  const { success, error: showError } = useNotification();

  useEffect(() => {
    if (resolved && authorized) {
      dispatch(push(ROUTE_ROOT));
      success('AUTHORIZATION.NOTIFICATIONS.SIGN_IN_SUCCESS');
    }
  }, [dispatch, resolved, authorized, success]);

  useEffect(() => {
    if (resolved && error) {
      dispatch(push(ROUTE_AUTH_LOGIN));
      showError(error);
    }
  }, [dispatch, resolved, showError, error, success]);

  return <FullScreenLoader />;
};

import React, { useCallback, FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { UnitFormValues } from 'components/institution/Unit/Editor/Editor.types';
import { fetchInstitutionsDictionary, fetchUnit, putUnit, clearUnit } from 'store/institutions/institutions.action';
import { mapFormValueToPayload, mapUnitModelToForm } from 'components/institution/Unit/Editor/editorHelpers';
import {
  getUnitData,
  isIstitutionsListLoading,
  isUnitLoading,
  isUnitSynchronized,
  getInstitutionsList,
  getInstitutionsListWithoutSynchronized,
} from 'store/institutions';
import { getSchoolTypesList } from 'store/general';
import { Editor } from 'components/institution/Unit/Editor/Editor';

interface EditContainerProps {
  id: string;
  goBack: () => void;
  fromStudentRegisterDetails: boolean;
}

export const EditorEditContainer: FC<EditContainerProps> = ({ goBack, id, fromStudentRegisterDetails }) => {
  const dispatch: (...params: any) => Promise<any> = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearUnit());
    };
  }, [dispatch]);

  const fetchInstitutionsDictionaryCallback = useCallback(() => dispatch(fetchInstitutionsDictionary()), [dispatch]);

  const handleSubmitCallback = useCallback(
    (values: UnitFormValues) => dispatch(putUnit(mapFormValueToPayload(values), id)),
    [dispatch, id],
  );

  const fetchUnitCallback = useCallback(() => dispatch(fetchUnit(id)), [dispatch, id]);

  const unitData = useSelector(getUnitData);
  const schoolTypesList = useSelector(getSchoolTypesList);

  const formInitialValues = unitData && mapUnitModelToForm(unitData, schoolTypesList);

  const isSynchronized = useSelector(isUnitSynchronized);
  const fullInstitutionsList = useSelector(getInstitutionsList);
  const filteredInstitutionsList = useSelector(getInstitutionsListWithoutSynchronized);

  const isInstitutionsListDataLoading = useSelector(isIstitutionsListLoading);
  const isUnitDataLoading = useSelector(isUnitLoading);

  const initialTab = fromStudentRegisterDetails ? 'studentRegister' : 'form';

  return (
    <Editor
      goBack={goBack}
      institutionsDictionary={isSynchronized ? fullInstitutionsList : filteredInstitutionsList}
      isSynchronized={isSynchronized}
      successMessage="INSTITUTIONS.UNIT_DATA.EDIT.SUCCESS"
      formInitialValues={formInitialValues}
      loading={isInstitutionsListDataLoading || isUnitDataLoading}
      fetchInstitutionsDictionary={fetchInstitutionsDictionaryCallback}
      handleSubmit={handleSubmitCallback}
      fetchUnit={fetchUnitCallback}
      oryginalName={unitData && unitData.originalName}
      isEdited={true}
      id={id}
      initialTab={initialTab}
    />
  );
};

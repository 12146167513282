import { User } from 'store/userList/userList.types';

export interface UserListState {
  list: {
    items: User[];
    loading: boolean;
    error: any;
  };
}

export const defaultState: UserListState = {
  list: {
    items: [],
    loading: false,
    error: null,
  },
};

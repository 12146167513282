import { RESET_STATE } from 'store/store';
import { AccessRulesActionType as ActionType, defaultState, AccessRulesState } from 'store/accessRules';
import { FSAReducer, Behaviours } from '@typings/redux';
import { fromApiFormat } from 'utils/api';

export const accessRulesReducer: FSAReducer<AccessRulesState, ActionType> = (state = defaultState, action) => {
  const behaviours: Behaviours<AccessRulesState, ActionType> = {
    [RESET_STATE]: () => ({
      ...defaultState,
    }),
    [ActionType.FETCH_ACCESS_RULES_REQUEST]: () => {
      return {
        ...state,
        loading: true,
      };
    },
    [ActionType.FETCH_ACCESS_RULES_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        items: fromApiFormat(payload.items).map((item) => ({
          ...item,
          translationKey: `MODULE.${item.name.toUpperCase()}`,
          allowedAccessTypes: item.allowedAccessTypes.map((item) => ({
            value: item.name,
            label: `ACCESS_TYPE.${item.name.toUpperCase()}`,
          })),
        })),
        loading: false,
      };
    },
  };

  return behaviours[action.type] ? behaviours[action.type](action) : state;
};

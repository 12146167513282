import { AppDispatch } from '@typings/redux';
import { Browser } from 'components/polls/Browser/Browser';
import { useNotification } from 'components/shared/hooks';
import { ROUTE_POLLS_CREATE, ROUTE_POLLS_EDIT, ROUTE_POLLS_PUBLISH, ROUTE_POLLS_RESULTS } from 'config/routes';
import { push } from 'connected-react-router';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clonePoll,
  closePoll,
  deletePoll,
  fetchPolls,
  Poll,
  PollFilters,
  pollsSelector,
  PollStatus,
  setPollFilters,
} from 'store/polls';
import { makePath } from 'utils/navigation/makePath';

export const BrowserContainer: FC = () => {
  const polls = useSelector(pollsSelector);
  const dispatch = useDispatch<AppDispatch>();
  const { success, error } = useNotification();

  const onFiltersUpdate = (filters: PollFilters) => {
    dispatch(setPollFilters(filters));
  };

  useEffect(() => {
    dispatch(fetchPolls(polls.filters));
  }, [dispatch, polls.filters]);

  useEffect(() => {
    const pollIsPublished = polls.items.some((item) => {
      return item.status === PollStatus.PUBLISHING;
    });

    if (pollIsPublished) {
      const timer = setTimeout(() => dispatch(fetchPolls(polls.filters, true)), 3000);
      return () => clearTimeout(timer);
    }
  }, [dispatch, polls.filters, polls.items]);

  const onPollCreate = () => {
    dispatch(push(ROUTE_POLLS_CREATE));
  };

  const onPollClone = async (poll: Poll) => {
    const result = await dispatch(clonePoll(poll.id));

    if (!result.error) {
      dispatch(push(makePath(ROUTE_POLLS_EDIT, { id: result.payload.id })));
      success('POLLS.CLONE.SUCCESS');
    } else {
      error('POLLS.CLONE.ERROR');
    }
  };

  const onPollEdit = (poll: Poll) => {
    dispatch(push(makePath(ROUTE_POLLS_EDIT, { id: poll.id })));
  };

  const onPollPublication = (poll: Poll) => {
    dispatch(push(makePath(ROUTE_POLLS_PUBLISH, { id: poll.id })));
  };

  const onPollResultsShow = (poll: Poll) => {
    dispatch(push(makePath(ROUTE_POLLS_RESULTS, { id: poll.id })));
  };

  const onPollDelete = async (poll: Poll) => {
    const result = await dispatch(deletePoll(poll.id));

    if (!result.error) {
      dispatch(fetchPolls(polls.filters));
      success('POLLS.DELETE.SUCCESS');
    } else {
      error('POLLS.DELETE.ERROR');
    }
  };

  const onPollClose = async (poll: Poll) => {
    const result = await dispatch(closePoll(poll.id));

    if (!result.error) {
      dispatch(fetchPolls(polls.filters));
      success('POLLS.CLOSE.SUCCESS');
    } else {
      error('POLLS.CLOSE.ERROR');
    }
  };

  return (
    <Browser
      initialFilters={polls.filters}
      polls={polls.items}
      isLoading={polls.loading}
      onFiltersUpdate={onFiltersUpdate}
      onPollCreate={onPollCreate}
      onPollClone={onPollClone}
      onPollClose={onPollClose}
      onPollEdit={onPollEdit}
      onPollPublication={onPollPublication}
      onPollResultsShow={onPollResultsShow}
      onPollDelete={onPollDelete}
    />
  );
};

import { Theme } from '@material-ui/core';
import { colors } from 'styles/colors';

export const filtersThemeFactory = (parentTheme: Theme): Theme => ({
  ...parentTheme,
  overrides: {
    ...parentTheme.overrides,
    MuiFormLabel: {
      ...parentTheme.overrides.MuiFormLabel,
      /**
       * Filled class is added only to the label so please note that
       * those styles are about the next element, not the label itself.
       */
      filled: {
        color: colors.primary,
        '& + div:after, &.Mui-focused + div:after': {
          transform: 'scaleX(1)',
        },
        '& + div .fa-times': {
          color: colors.primary,
        },
      },
    },
    MuiInput: {
      ...parentTheme.overrides.MuiInput,
      underline: {
        '&:before, &:hover:not(.Mui-disabled):before': {
          borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
        },
        '&:after': {
          borderBottom: `2px solid ${colors.primary}`,
        },
        '&.Mui-focused:after': {
          transform: 'scaleX(0)',
        },
      },
    },
    MuiFormHelperText: {
      root: {
        '&.Mui-error': {
          marginTop: '8px',
          minHeight: '1em',
        },
      },
    },
    MuiListItem: {
      root: {
        minHeight: '3em !important',
      },
    },
  },
});

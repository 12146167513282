import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon';
import { Tooltip } from 'components/shared/messages/Tooltip/Tooltip';
import React, { KeyboardEvent, ReactNode } from 'react';
import { Field, FieldProps } from 'formik';
import { Checkbox, FormHelperText, FormControl, FormControlLabel } from '@material-ui/core';
import { CheckboxProps } from '@material-ui/core/Checkbox';
import { get } from 'lodash';
import classNames from 'classnames';

export interface CheckBoxProps extends CheckboxProps {
  name: string;
  labelText: React.ReactNode;
  validate?: (value: any) => string | undefined;
  tooltip?: string;
}

const labelWithTooltip = (label: ReactNode, tooltip: string): ReactNode => {
  return (
    <>
      {label}{' '}
      <Tooltip title={tooltip}>
        <FontAwesomeIcon name={'info-circle'} />
      </Tooltip>
    </>
  );
};

export const CheckBox = ({
  name,
  labelText = '',
  validate = () => undefined,
  onChange,
  tooltip,
  ...rest
}: CheckBoxProps) => (
  <Field name={name} validate={validate}>
    {({ field, form }: FieldProps) => {
      const isError = !!(get(form.errors, field.name) && get(form.touched, field.name));
      return (
        <FormControl error={isError} className={classNames({ hasValidationError: isError })}>
          <FormControlLabel
            control={
              <Checkbox
                onBlur={field.onBlur}
                onChange={(event, checked: boolean) => {
                  form.handleChange(event);
                  onChange && onChange(event, checked);
                }}
                name={field.name}
                data-testid={`checkbox-${field.name}`}
                checked={field.value}
                color="default"
                onKeyDown={(event: KeyboardEvent<HTMLButtonElement>) => {
                  //Enter
                  if (event.keyCode === 13) {
                    form.setFieldValue(field.name, !field.value);
                  }
                }}
                {...rest}
              />
            }
            label={tooltip ? labelWithTooltip(labelText, tooltip) : labelText}
          />
          {isError && <FormHelperText error>{get(form.errors, field.name)}</FormHelperText>}
        </FormControl>
      );
    }}
  </Field>
);

import { CommuneItem, TerytCommuneItem, CountyItem, isTerytCommune } from 'store/teryt';
import countries from 'i18n-iso-countries';
import { Translator } from 'context/locale/LocaleContext/LocaleContext.types';

export const formatCountyName = (county: CountyItem, trans: Translator) =>
  `${county.name} (${trans(`TERYT.COUNTY.TYPE.${county.type}`)})`;

export const formatCommuneName = (commune: TerytCommuneItem, trans: Translator) =>
  `${commune.name} (${trans(`TERYT.COMMUNE.TYPE.${commune.type}`)})`;

export const formatCommuneSummary = (commune: CommuneItem, locale: string, trans: Translator) => {
  if (isTerytCommune(commune)) {
    if (commune.name) {
      return `${formatCommuneName(commune, trans)} - ${formatCountyName(commune.county, trans)} - ${
        commune.county.voivodeship.name
      } - ${countries.getName('PL', locale)}`;
    } else {
      return trans('TERYT.TOWN.DEFAULT');
    }
  } else {
    if (commune.country && commune.name) {
      return `${countries.getName(commune.country, locale)} - ${commune.name}`;
    } else if (commune.country) {
      return countries.getName(commune.country, locale);
    } else if (commune.name) {
      return commune.name;
    } else {
      return trans('TERYT.TOWN.DEFAULT');
    }
  }
};

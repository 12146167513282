import { ButtonDefinition } from 'components/shared/data/DataGrid/renderers/cell/Buttons/Buttons.types';
import { ColumnsConfig } from 'components/shared/data/DataGrid/DataGrid.types';
import { Translator } from 'context/locale/LocaleContext/LocaleContext.types';
import { AlertChildModel, ChildModel } from 'store/childRegister/childRegister.types';
import { BrowserColumnParams } from './Browser.types';
import { OfficialPermissionLevel, OfficialResourceGroup } from 'store/officials';

type Config = ColumnsConfig<ChildModel, BrowserColumnParams>;

const alertTooltip = (alerts: AlertChildModel[], trans: Translator) => {
  return alerts
    .reverse()
    .map((alert) => trans(alert.name))
    .join(',\n');
};

export const browserColumns: Config = (parameters) => [
  {
    field: 'id',
    name: () => '',
    columnParams: { checkboxSelection: true, width: 50, minWidth: 50 },
    format: () => '',
    cellTooltip: () => '',
    cellClass: 'checkboxGrid',
  },
  {
    field: 'alerts',
    name: 'CHILD_REGISTER.BROWSER.COLUMNS.ALERTS',
    columnParams: { minWidth: 55 },
    cellTooltip: () => '',
    renderer: 'iconsCell',
    rendererParams: {
      icons: (item) => {
        if (item.alerts && item.alerts.length > 0) {
          const mapAlertsToObj: AlertChildModel[] = item.alerts.map((alert) => ({ name: alert }));
          return [
            {
              type: 'default',
              icon: mapAlertsToObj.some((alert) => alert.name === 'ALERTS.CHILD_REGISTER.MISSING_ADDRESS')
                ? 'exclamation-triangle'
                : 'info-circle',
              tooltip: (trans) => alertTooltip(mapAlertsToObj, trans),
            },
          ];
        }

        return [];
      },
    },
  },
  {
    field: 'birthYear',
    name: 'CHILD_REGISTER.BROWSER.COLUMNS.BIRTH_YEAR',
    format: (data: ChildModel) => {
      return new Date(data.birthday).getFullYear();
    },
    columnParams: { minWidth: 67 },
  },
  {
    field: 'firstName',
    name: 'CHILD_REGISTER.BROWSER.COLUMNS.FIRST_NAME',
    columnParams: { minWidth: 90 },
  },
  {
    field: 'lastName',
    name: 'CHILD_REGISTER.BROWSER.COLUMNS.LAST_NAME',
    columnParams: { minWidth: 90 },
  },
  {
    field: 'sex',
    name: 'CHILD_REGISTER.BROWSER.COLUMNS.SEX',
    format: (data, trans) => {
      if (data.sex) {
        return trans(`CHILD_REGISTER.BROWSER.VALUES.SEX.${data.sex.toUpperCase()}`);
      } else {
        return '';
      }
    },
    columnParams: { minWidth: 50 },
  },
  {
    field: 'identificationNumber',
    name: 'CHILD_REGISTER.BROWSER.COLUMNS.IDENTIFICATION_NUMBER',
    columnParams: { minWidth: 110 },
  },
  {
    field: 'usedAddress',
    name: 'CHILD_REGISTER.BROWSER.COLUMNS.USED_ADDRESS',
    format: (data, trans) => {
      return data.usedAddress && trans(`CHILD_REGISTER.BROWSER.VALUES.USED_ADDRESS.${data.usedAddress.toUpperCase()}`);
    },
    columnParams: { minWidth: 80 },
  },
  {
    field: 'cityName',
    name: 'CHILD_REGISTER.BROWSER.COLUMNS.CITY',
    columnParams: { minWidth: 80 },
  },
  {
    field: 'addressStatus',
    name: 'CHILD_REGISTER.BROWSER.COLUMNS.CATCHMENT_CHILD',
    format: (data, trans) => {
      return trans(`CHILD_REGISTER.BROWSER.VALUES.CATCHMENT_CHILD.${data.isCatchmentChild ? 'YES' : 'NO'}`);
    },
    columnParams: { minWidth: 85 },
  },
  {
    field: 'modificationDate',
    name: 'CHILD_REGISTER.BROWSER.COLUMNS.MODIFICATION_DATE',
    columnParams: { minWidth: 90 },
    format: (data, trans, formatter) => {
      return formatter.formatDate(data.modificationDate);
    },
  },
  {
    name: 'INSTITUTIONS.BROWSER.COLUMNS.ACTIONS',
    field: 'id',
    cellTooltip: () => '',
    renderer: 'buttonsCell',
    rendererParams: {
      buttons: (): ButtonDefinition[] => {
        return [
          {
            type: 'default',
            size: 'small',
            variant: 'outlined',
            canShow: {
              only: false,
              resourcesGroup: OfficialResourceGroup.childRegister,
              accessType: OfficialPermissionLevel.fullAccess,
            },
            icon: 'pencil-alt',
            label: 'COMMON.EDIT',
            tooltip: 'COMMON.EDIT',
            action: parameters.edit,
          },
          {
            type: 'default',
            size: 'small',
            variant: 'outlined',
            canShow: {
              only: true,
              resourcesGroup: OfficialResourceGroup.childRegister,
              accessType: OfficialPermissionLevel.readOnly,
            },
            icon: 'eye',
            label: 'COMMON.SHOW',
            tooltip: 'COMMON.SHOW',
            action: parameters.edit,
          },
          {
            type: 'default',
            size: 'small',
            variant: 'outlined',
            canShow: {
              only: false,
              resourcesGroup: OfficialResourceGroup.childRegister,
              accessType: OfficialPermissionLevel.fullAccess,
            },
            icon: 'trash',
            label: 'COMMON.DELETE',
            tooltip: 'COMMON.DELETE',
            action: parameters.delete,
          },
        ];
      },
    },
    columnParams: { minWidth: 200 },
  },
];

import { Theme } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { AppDispatch } from '@typings/redux';
import { useStyles } from 'components/institution/Browser/Toolbar/Toolbar.styles';
import { ToolbarProps } from 'components/institution/Browser/Toolbar/Toolbar.types';
import { Form, SelectBox, DatePicker } from 'components/shared/form';
import { FocusTrap } from 'components/shared/form/FocusTrap/FocusTrap';
import { SearchInput } from 'components/shared/form/SearchInput/SearchInput';
import { useTranslator } from 'components/shared/hooks/useTranslator/useTranslator';
import React, { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { filtersThemeFactory } from 'styles/themes/filtersTheme';
import { minLength } from 'utils/formValidators/minLength/minLength';
import { AutoSave } from 'components/shared/form/AutoSave/AutoSave';
import { setSynchronizationLogFilters } from 'store/reports/reports.action';
import { synchronizationLogListFiltersSelector } from 'store/reports/reports.selector';
import {
  SynchronizationLogFilters,
  SynchronizationLogLevel,
  SynchronizationLogSource,
} from 'store/reports/reports.types';
import { combineValidators, maxLength } from 'utils/formValidators';

const ToolbarComponent = (props: ToolbarProps, ref) => {
  const classes = useStyles();
  const dispatch = useDispatch() as AppDispatch;
  const trans = useTranslator();

  const filters = useSelector(synchronizationLogListFiltersSelector);

  const valuesChanged = (values: SynchronizationLogFilters) => {
    dispatch(setSynchronizationLogFilters(values));
  };

  return (
    <section ref={ref}>
      <Form<SynchronizationLogFilters> initialValues={filters} onSubmit={valuesChanged} className={classes.form}>
        {() => (
          <div className={classes.filters}>
            <ThemeProvider theme={(parentTheme: Theme) => filtersThemeFactory(parentTheme)}>
              <DatePicker
                name="from"
                labelText={trans('REPORTS.SYNCHRONIZATIONS.FILTERS.DATE_FROM.LABEL')}
                required
                clearable={false}
                disableKeyboard
              />
              <DatePicker
                name="to"
                labelText={trans('REPORTS.SYNCHRONIZATIONS.FILTERS.DATE_TO.LABEL')}
                required
                clearable={false}
                disableKeyboard
              />
              <SelectBox
                name="source"
                placeholderText={trans('REPORTS.SYNCHRONIZATIONS.FILTERS.ALL')}
                labelText={trans('REPORTS.SYNCHRONIZATIONS.FILTERS.SOURCE.LABEL')}
                displayEmpty
                options={[
                  { value: SynchronizationLogSource.BEL, label: trans('REPORTS.SYNCHRONIZATIONS.PROCESS.BEL') },
                  {
                    value: SynchronizationLogSource.GRANT_SYSTEM,
                    label: trans('REPORTS.SYNCHRONIZATIONS.PROCESS.GRANTSYSTEM'),
                  },
                  {
                    value: SynchronizationLogSource.KU_GRANT_SYSTEM,
                    label: trans('REPORTS.SYNCHRONIZATIONS.PROCESS.KU_GRANTSYSTEM'),
                  },
                  {
                    value: SynchronizationLogSource.KU_IMPORT,
                    label: trans('REPORTS.SYNCHRONIZATIONS.PROCESS.KU_IMPORT'),
                  },
                  { value: SynchronizationLogSource.KU_ZSE, label: trans('REPORTS.SYNCHRONIZATIONS.PROCESS.KU_ZSE') },
                  { value: SynchronizationLogSource.ZSE, label: trans('REPORTS.SYNCHRONIZATIONS.PROCESS.ZSE') },
                  {
                    value: SynchronizationLogSource.KU_DO_KED,
                    label: trans('REPORTS.SYNCHRONIZATIONS.PROCESS.KU_DO_KED'),
                  },
                ]}
              />
              <SelectBox
                name="level"
                placeholderText={trans('REPORTS.SYNCHRONIZATIONS.FILTERS.ALL')}
                labelText={trans('REPORTS.SYNCHRONIZATIONS.FILTERS.LEVEL.LABEL')}
                options={[
                  { value: SynchronizationLogLevel.INFO, label: trans('REPORTS.SYNCHRONIZATIONS.FILTERS.LEVEL.INFO') },
                  {
                    value: SynchronizationLogLevel.WARNING,
                    label: trans('REPORTS.SYNCHRONIZATIONS.FILTERS.LEVEL.WARNING'),
                  },
                  {
                    value: SynchronizationLogLevel.ERROR,
                    label: trans('REPORTS.SYNCHRONIZATIONS.FILTERS.LEVEL.ERROR'),
                  },
                ]}
              />
              <SearchInput
                name="search"
                labelText={trans('REPORTS.SYNCHRONIZATIONS.FILTERS.SEARCH.LABEL')}
                icon={<InfoOutlined fontSize="small" />}
                validate={combineValidators(
                  minLength(trans('COMMON.FORM.TOO_SHORT', { length: 3 }), 3),
                  maxLength(trans('COMMON.FORM.TOO_LONG', { length: 255 }), 255),
                )}
                hasIcon
                resettable
                tooltipText={trans('REPORTS.SYNCHRONIZATIONS.TOOLTIPS.SEARCH')}
              />
              <FocusTrap handler={() => props.onGridFocus()} />
              <AutoSave debounced={['search']} />
            </ThemeProvider>
          </div>
        )}
      </Form>
    </section>
  );
};

ToolbarComponent.displayName = 'Toolbar';
export const Toolbar = forwardRef(ToolbarComponent);

import { NumberFormatter } from '@typings/extra-react-intl';

export interface UseFormatterHook {
  (): Formatter;
}

export interface Formatter {
  formatApiErrors: (errors: object) => object;
  formatDate: (value: string | Date) => string;
  formatDateWithTime: (value: string | Date) => string;
  formatDateWithTemplate: (template: DateFormat, date?: Date | string) => string;
  formatNumber: NumberFormatter;
  formatTime: (value: string | Date) => string;
}

export enum DateFormat {
  EUROPEAN_FILE_SAFE = 'dd-MM-yyyy',
  AMERICAN_DATE = 'yyyy-MM-dd',
}

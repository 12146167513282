import { createContext } from 'react';

import { LocaleContextType } from './LocaleContext.types';

export const initialContext: LocaleContextType = {
  formatMessage: () => '',
  formatDate: () => '',
  formatNumber: () => '',
  trans: () => '',
  locale: 'pl',
  updateLocale: () => null,
};

export const LocaleContext = createContext(initialContext);

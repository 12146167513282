import { SchoolYearModel } from '@typings/common';
import { AppDispatch } from '@typings/redux';
import { ReportsBrowser } from 'components/schoolObligation/ReportsBrowser/ReportsBrowser';
import { useNotification } from 'components/shared/hooks';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uniq } from 'lodash';
import {
  fetchSchoolObligationsReports,
  getBrowserFilter,
  getReportsSelector,
  removeSchoolObligationsReport,
  ReportBrowserFilter,
  ReportStatus,
  setReportsFilters,
} from 'store/schoolObligations';

export const ReportsBrowserContainer: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const { items, loading } = useSelector(getReportsSelector);
  const [schoolYears, setSchoolYears] = useState<SchoolYearModel[]>([]);
  const initialFilters = useSelector(getBrowserFilter);
  const { success } = useNotification();

  useEffect(() => {
    dispatch(fetchSchoolObligationsReports());
  }, [dispatch]);

  useEffect(() => {
    const schoolYearOptions = items
      .filter((report) => report.status === ReportStatus.PUBLISHED)
      .map((report) => report.schoolYear)
      .sort();
    setSchoolYears(uniq(schoolYearOptions));
  }, [dispatch, items, items.length]);

  const fetchReportsList = () => {
    return dispatch(fetchSchoolObligationsReports());
  };

  const onDelete = async (id: string) => {
    await dispatch(removeSchoolObligationsReport(id));
    success('SCHOOL_OBLIGATION.REPORTS.DELETE.SUCCESS');
  };

  const handleFilterSubmit = (filters: ReportBrowserFilter) => {
    return dispatch(setReportsFilters(filters));
  };

  return (
    <ReportsBrowser
      isLoading={loading}
      reports={items}
      fetchReportsList={fetchReportsList}
      onDelete={onDelete}
      filters={initialFilters}
      schoolYears={schoolYears}
      handleFilterSubmit={handleFilterSubmit}
    />
  );
};

import { FocusStrategy } from 'utils/form/focusField/focusField.types';

const strategies: FocusStrategy[] = [
  {
    // Support for Material UI Selects
    test: (control) => (control.previousSibling as HTMLElement).className.startsWith('MuiSelect'),
    focus: (control) => (control.previousSibling as HTMLElement).focus(),
  },
];

export const focusField = (container: Element, name: string) => {
  const control = container.querySelector(`[name="${name}"]`) as Element;
  if (!control) {
    throw Error(`No field with name "${name}" found inside given container`);
  }

  for (const strategy of strategies) {
    if (strategy.test(control)) {
      strategy.focus(control);
      break;
    }
  }
};

import { matchPath } from 'react-router';

export const matchPathWithRoutes = (path: string, routes: string[]) => {
  return routes.some((route) => {
    return matchPath(path, {
      path: route,
      exact: true,
    });
  });
};

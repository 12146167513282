import React, { FC, useState, useContext, useEffect, Fragment } from 'react';
import { FormikHelpers } from 'formik';
import { SynchronizationTypes } from 'store/institutions';
import { required, combineValidators, maxLength } from 'utils/formValidators';
import { Form, InputText } from 'components/shared/form';
import { Button } from 'components/shared/buttons/Button/Button';
import { LocaleContext } from 'context/locale';
import { TabPanel } from 'components/shared/surfaces/TabPanel/TabPanel';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import { Heading } from 'components/shared/typography/Heading/Heading';
import { InfoOutlined } from '@material-ui/icons';
import { EditProps } from './Edit.types';
import { Prompt } from 'react-router';
import { TextLoader } from 'components/shared/loaders/TextLoader/TextLoader';
import { useNotification } from 'components/shared/hooks/useNotification/useNotification';
import { useFormatter } from 'components/shared/hooks/useFormatter/useFormatter';
import Access from 'components/shared/access/access';
import { useCheckAccessSameType } from 'components/shared/hooks';
import { OfficialPermissionLevel, OfficialResourceGroup } from 'store/officials';

export const Edit: FC<EditProps> = ({ fetchInstitution, ...props }) => {
  const [editSuccess, setEditSuccess] = useState(false);
  const { success } = useNotification();
  const { formatApiErrors } = useFormatter();
  const { trans } = useContext(LocaleContext);

  const renderLoader = () => <TextLoader name="spinner" text={trans('INSTITUTIONS.EDIT.LOADING')} />;
  const userReadOnly = useCheckAccessSameType(OfficialResourceGroup.childRegister, OfficialPermissionLevel.readOnly);

  useEffect(() => {
    if (!userReadOnly) return;
    setEditSuccess(true);
  }, [userReadOnly]);

  useEffect(() => {
    fetchInstitution();
  }, [fetchInstitution]);

  const handleOnSubmit = (values: { name: string }, formik: FormikHelpers<{ name: string }>) => {
    props.changeInstitutionName(values).then((result) => {
      if (result.error) {
        formik.setErrors(formatApiErrors(result.payload.response.errors));
        formik.setSubmitting(false);
      } else {
        success('INSTITUTIONS.EDIT.SUCCESS');
        setEditSuccess(true);
        props.goBack();
      }
    });
  };

  const renderForm = () => (
    <Form<{ name: string }> initialValues={{ name: props.institution.name }} onSubmit={handleOnSubmit}>
      {(formProps) => {
        return (
          <Fragment>
            <Paper innerSpacing={[4, 2]} square={true}>
              <Prompt when={formProps.dirty && !editSuccess} message={trans('COMMON.CONFIRM_EXIT')} />
              <div className="container-fluid pb-3">
                <Heading type="h5">{trans('INSTITUTIONS.EDIT.FORM.TITLE')}</Heading>
                <div className="row mt-4">
                  <div className="col-3">
                    <InputText
                      name="name"
                      labelText={trans('INSTITUTIONS.EDIT.FORM.PLACEHOLDER')}
                      tooltipText={props.institution.originalName}
                      hasIcon={
                        props.institution.synchronization &&
                        props.institution.synchronization === SynchronizationTypes.SYNERGIA
                      }
                      icon={<InfoOutlined fontSize="small" />}
                      validate={combineValidators(
                        required(trans('COMMON.FORM.REQUIRED')),
                        maxLength(trans('COMMON.FORM.TOO_LONG', { length: 200 }), 200),
                      )}
                      required
                    />
                  </div>
                </div>
              </div>
            </Paper>
            <div className="row my-4">
              <div className="col">
                <Button onClick={props.goBack} disabled={formProps.isSubmitting} color="secondary" variant="outlined">
                  {trans('COMMON.BACK')}
                </Button>
                <Access
                  resourcesGroup={OfficialResourceGroup.childRegister}
                  accessType={OfficialPermissionLevel.fullAccess}
                >
                  <Button
                    onClick={() => formProps.handleSubmit()}
                    disabled={formProps.isSubmitting}
                    color="secondary"
                    variant="contained"
                    className="ml-3"
                    data-testid="save"
                  >
                    {trans('COMMON.SAVE')}
                  </Button>
                </Access>
              </div>
            </div>
          </Fragment>
        );
      }}
    </Form>
  );

  const renderContent = () => (
    <TabPanel
      tabs={[
        {
          title: trans('INSTITUTIONS.EDIT.TITLE'),
          key: 'edit',
          content: renderForm(),
        },
      ]}
      defaultTab="edit"
    />
  );

  return (
    <div className="container-fluid">{props.loading || !props.institution ? renderLoader() : renderContent()}</div>
  );
};

import { Action } from 'redux';
import { fork, ForkEffect, put, PutEffect, takeEvery } from 'redux-saga/effects';
import { FSA } from '@typings/redux';
import { ChildRegisterActionType, fetchObligationActions, mapImportFields } from 'store/childRegister';

const findValue = (fields: string[], value: string): string => fields.find((item) => item === value) || '';

function* refreshObligationActionsList(action): IterableIterator<PutEffect> {
  yield put(fetchObligationActions(action.meta.childId) as unknown as Action);
}

function* mappingImportFields(action: FSA<any, { fields: string[] }>): IterableIterator<PutEffect> {
  const fields = action.payload.fields;
  yield put(
    mapImportFields({
      firstName: findValue(fields, 'Imie'),
      secondName: findValue(fields, 'Drugie imie'),
      lastName: findValue(fields, 'Nazwisko'),
      sex: findValue(fields, 'Plec'),
      birthday: findValue(fields, 'Data urodzenia'),
      identificationNumber: findValue(fields, 'Nr identyfikacyjny'),
      addressStatus: findValue(fields, 'Status zameldowania'),
      birthCity: findValue(fields, 'Miejsce urodzenia'),
      fatherFirstName: findValue(fields, 'Imie ojca'),
      fatherLastName: findValue(fields, 'Nazwisko ojca'),
      motherFirstName: findValue(fields, 'Imie matki'),
      motherLastName: findValue(fields, 'Nazwisko matki'),
      permanentResidenceVoivodeship: findValue(fields, 'Wojewodztwo zameldowania'),
      permanentResidenceCity: findValue(fields, 'Miejscowosc zameldowania'),
      permanentResidenceCityTeryt: findValue(fields, 'Teryt miejscowosci zameldowania'),
      permanentResidenceStreet: findValue(fields, 'Ulica zameldowania'),
      permanentResidenceBuildingNumber: findValue(fields, 'Nr budynku zameldowania'),
      permanentResidenceFlatNumber: findValue(fields, 'Nr lokalu zameldowania'),
      permanentResidencePostalCode: findValue(fields, 'Kod pocztowy zameldowania'),
      residenceVoivodeship: findValue(fields, 'Wojewodztwo zamieszkania'),
      residenceCity: findValue(fields, 'Miejscowosc zamieszkania'),
      residenceCityTeryt: findValue(fields, 'Teryt miejscowosci zamieszkania'),
      residenceStreet: findValue(fields, 'Ulica zamieszkania'),
      residenceBuildingNumber: findValue(fields, 'Nr budynku zamieszkania'),
      residenceFlatNumber: findValue(fields, 'Nr lokalu zamieszkania'),
      residencePostalCode: findValue(fields, 'Kod pocztowy zamieszkania'),
    }) as unknown as Action,
  );
}

export function* childRegisterSaga(): Iterator<ForkEffect> {
  yield fork(function* () {
    yield takeEvery(ChildRegisterActionType.POST_OBLIGATION_ACTION_SUCCESS, refreshObligationActionsList);
    yield takeEvery(ChildRegisterActionType.PUT_OBLIGATION_ACTION_SUCCESS, refreshObligationActionsList);
    yield takeEvery(ChildRegisterActionType.DELETE_OBLIGATION_ACTION_SUCCESS, refreshObligationActionsList);
    yield takeEvery(ChildRegisterActionType.FETCH_IMPORT_COLUMNS_SUCCESS, mappingImportFields);
  });
}

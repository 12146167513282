import { useStyles } from 'components/polls/shared/Question/Question.styles';
import { CheckBoxList } from 'components/shared/form/CheckBox/CheckBoxList';
import { useTranslator } from 'components/shared/hooks';
import { Heading } from 'components/shared/typography/Heading/Heading';
import { toOptions } from 'utils/api';
import { QuestionProps } from 'components/polls/Questionnaire/Questionnaire.types';
import React, { FC } from 'react';

export const MultipleChoice: FC<QuestionProps> = ({ field, question, disabled }) => {
  const classes = useStyles();
  const trans = useTranslator();
  const options = toOptions(question.options, 'value', 'value');

  return (
    <div className={classes.question}>
      <Heading type="subtitle1" className={classes.title}>
        {question.title}&nbsp;
        {question.required && !disabled && trans('POLLS.QUESTIONNAIRE.ANSWER_REQUIRED')}
      </Heading>
      <CheckBoxList
        name={`${field}.result`}
        options={options}
        disabled={disabled}
        containerClassName={classes.optionsList}
      />
    </div>
  );
};

import { OfficialPostModel } from 'store/officials/officials.types';

export interface OfficialsState {
  details: {
    official: OfficialPostModel;
    loading: boolean;
    error: any;
  };
}

export const defaultState: OfficialsState = {
  details: {
    official: null,
    loading: false,
    error: null,
  },
};

import React from 'react';
import NumberFormat from 'react-number-format';

export interface PercentFormatterProps {
  inputRef: (instance: NumberFormat<{}> | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  value: string;
}

export function PercentFormatter(props: PercentFormatterProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        if (values.value === '-') {
          return;
        }
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      decimalSeparator={','}
      allowedDecimalSeparators={[',', '.']}
      prefix={Number(props.value) > 0 ? '+' : ''}
      suffix={'%'}
    />
  );
}

/*eslint complexity: ["error", 18]*/
import { NO_TERYT_ITEM_ID } from 'components/shared/teryt/TownPicker/TownPickerContainer';

export const calculateSelectMode = (townValue, townFieldName, disabled, osinLocked) => {
  const townId = townValue && townValue.id;
  const townName = townValue && townValue.name;
  const townCountry = townValue && townValue.country;

  const idOutOfTeryt = townId === NO_TERYT_ITEM_ID;
  const cityOutOfTeryt = idOutOfTeryt && !!townName;
  const noCity = idOutOfTeryt && !townName;

  const customStreet = (townFieldName === '' && !osinLocked) || idOutOfTeryt;
  const addressPickerActive =
    (!disabled &&
      !cityOutOfTeryt &&
      !noCity &&
      ((!!townId && !!townName) || customStreet || !!townName || osinLocked)) ||
    (townCountry && townName && !disabled);

  return { addressPickerActive };
};

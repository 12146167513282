import React, { FC, useState, Fragment } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import { useStyles } from 'components/shared/surfaces/TabPanel/TabPanel.styles';
import { TabPanelProps } from 'components/shared/surfaces/TabPanel/TabPabel.types';

export const TabPanel: FC<TabPanelProps> = ({ tabs, defaultTab, change = () => {} }) => {
  const [value, setValue] = useState(defaultTab);
  const classes = useStyles();

  function handleChange(event: React.ChangeEvent<{}>, newValue: string) {
    setValue(newValue);
    change(newValue);
  }

  return (
    <Fragment>
      <Tabs value={value} onChange={handleChange} classes={classes}>
        {tabs.map((tab) => (
          <Tab label={tab.title} value={tab.key} key={tab.key} disabled={tab.disabled} />
        ))}
      </Tabs>

      {tabs.map((tab) => value === tab.key && <Fragment key={tab.key}>{tab.content}</Fragment>)}
    </Fragment>
  );
};

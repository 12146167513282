import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ProgressModalProps } from 'components/shared/dialogs/ProgressModal/ProgressModal.types';
import { ProgressBar } from 'components/shared/loaders/ProgressBar/ProgressBar';
import React, { FC } from 'react';
import classNames from 'classnames';

export const ProgressModal: FC<ProgressModalProps> = (props) => {
  return (
    <Dialog fullWidth maxWidth={'md'} disableEscapeKeyDown aria-labelledby="import-status-title" open={props.open}>
      <DialogTitle id="confirm-title">{props.title}</DialogTitle>
      <DialogContent>
        <ProgressBar value={props.progress} error={props.error} />
        <div className="mt-3">
          <DialogContentText className={classNames({ 'text-danger': props.error })}>
            {props.description}
          </DialogContentText>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.button.action}
          color="primary"
          variant="outlined"
          data-testid="confirm-yes"
          disabled={props.button.disabled}
        >
          {props.button.label}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export enum OfficialsActionType {
  FETCH_OFFICIALS_REQUEST = 'officials/FETCH_OFFICIALS_REQUEST',
  FETCH_OFFICIALS_SUCCESS = 'officials/FETCH_OFFICIALS_SUCCESS',
  FETCH_OFFICIALS_FAILURE = 'officials/FETCH_OFFICIALS_FAILURE',

  FETCH_OFFICIALS_DETAILS_REQUEST = 'officials/FETCH_OFFICIALS_DETAILS_REQUEST',
  FETCH_OFFICIALS_DETAILS_SUCCESS = 'officials/FETCH_OFFICIALS_DETAILS_SUCCESS',
  FETCH_OFFICIALS_DETAILS_FAILURE = 'officials/FETCH_OFFICIALS_DETAILS_FAILURE',

  UPDATE_OFFICIALS_DETAILS_REQUEST = 'officials/UPDATE_OFFICIALS_DETAILS_REQUEST',
  UPDATE_OFFICIALS_DETAILS_SUCCESS = 'officials/UPDATE_OFFICIALS_DETAILS_SUCCESS',
  UPDATE_OFFICIALS_DETAILS_FAILURE = 'officials/UPDATE_OFFICIALS_DETAILS_FAILURE',

  DELETE_OFFICIALS_REQUEST = 'officials/DELETE_OFFICIALS_REQUEST',
  DELETE_OFFICIALS_SUCCESS = 'officials/DELETE_OFFICIALS_SUCCESS',
  DELETE_OFFICIALS_FAILURE = 'officials/DELETE_OFFICIALS_FAILURE',

  POST_OFFICIAL_REQUEST = 'officials/POST_OFFICIALS_REQUEST',
  POST_OFFICIAL_SUCCESS = 'officials/POST_OFFICIALS_SUCCESS',
  POST_OFFICIAL_FAILURE = 'officials/POST_OFFICIALS_FAILURE',

  RESET_PASSWORD_REQUEST = 'officials/RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS = 'officials/RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE = 'officials/RESET_PASSWORD_FAILURE',
}

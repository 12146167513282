import { AppDispatch } from '@typings/redux';
import { DocumentModal } from 'components/shared/dialogs/DocumentModal/DocumentModal';
import {
  DocumentModalContainerProps,
  DocumentsCreator,
  DocumentModalForm,
} from 'components/shared/dialogs/DocumentModal/DocumentModal.types';
import { DocumentsProgress } from 'components/shared/dialogs/DocumentModal/DocumentsProgress';
import { GroupedOptionType } from 'components/shared/form/Autocomplete/Autocomplete.types';
import { useTranslator } from 'components/shared/hooks';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearDocumentRequest,
  clearDocumentsPreviewData,
  fetchTemplates,
  templatesListSelector,
} from 'store/templates';
import { mapFieldsToTranslations } from 'utils/i18n/templateFields';

export const DocumentModalContainer = (props: DocumentModalContainerProps): ReactElement => {
  const dispatch = useDispatch<AppDispatch>();
  const trans = useTranslator();
  const [progressModalOpen, setProgressModalOpen] = useState(false);
  const [isExport, setIsExport] = useState(false);

  useEffect(() => {
    if (props.open) {
      dispatch(clearDocumentsPreviewData(true));
      dispatch(clearDocumentRequest());
      dispatch(fetchTemplates());
    } else {
      setProgressModalOpen(false);
    }
  }, [dispatch, props.open]);

  const templates = useSelector(templatesListSelector);

  const templateOptions = templates.items
    .map((template): GroupedOptionType => {
      return {
        label: `${template.name} (${mapFieldsToTranslations(template.fields, trans).join(', ')})`,
        value: template.id,
        group: trans(`TEMPLATES.TARGET.${template.target.toUpperCase()}`),
        disabled: template.target !== props.target,
      };
    })
    .sort((a, b) => a.group.localeCompare(b.group));

  const onDocumentsCreate: DocumentsCreator = (...values) => {
    props.onDocumentsCreate(...values);
    setProgressModalOpen(true);
  };

  const onDataReset = useCallback(() => {
    dispatch(clearDocumentsPreviewData());
  }, [dispatch]);

  const onFormUpdate = (data: DocumentModalForm) => {
    props.onFormUpdate(data);
    setIsExport(data.dcsExport);
  };

  return (
    <>
      <DocumentsProgress open={props.open && progressModalOpen} onClose={props.onClose} isExport={isExport} />
      <DocumentModal
        open={props.open && !progressModalOpen}
        onClose={props.onClose}
        loading={templates.loading}
        fields={props.fields}
        alerts={props.alerts}
        templates={templateOptions}
        onFormUpdate={onFormUpdate}
        title={props.title}
        onDataFetch={props.onDataFetch}
        onDataProvide={props.onDataProvide}
        onDataReset={onDataReset}
        limit={props.limit}
        total={props.total}
        onDocumentsCreate={onDocumentsCreate}
      />
    </>
  );
};

import { muiStyles } from 'utils/design';

export const useStyles = muiStyles((theme) => ({
  container: {
    display: 'flex',
  },
  form: {
    flexGrow: 1,
  },
  sidebar: {
    width: '200px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pagination: {
    position: 'fixed',
  },
  summary: {
    margin: theme.spacing(2, 0),
  },
}));

import { useStyles } from 'components/shared/messages/Infobar/Infobar.styles';
import React, { FC } from 'react';
import classNames from 'classnames';

export const InfobarButton: FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({ children, className, ...props }) => {
  const classes = useStyles();

  return (
    <button {...props} className={classNames(className, classes.button)}>
      {children}
    </button>
  );
};

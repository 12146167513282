import { getStructure } from 'components/reports/SubventionForecast/rows.config';
import { SubventionForecast } from 'components/reports/SubventionForecast/SubventionForecast';
import { ForecastConfig } from 'components/reports/SubventionForecast/SubventionForecast.types';
import { ForecastAction } from 'components/shared/data/DataGrid/renderers/cell/ForecastModificator/ForecastManipulator.types';
import { useTranslator } from 'components/shared/hooks';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSubventionFactors } from 'store/reports/reports.action';
import { subventionFactorsSelector } from 'store/reports/reports.selector';

export const initialValues: ForecastConfig = {
  diFactor: { action: ForecastAction.COPY, actionValue: '' },
  financialStandardA: { action: ForecastAction.FORECAST, actionValue: '' },
  subventionA: { action: ForecastAction.COPY, actionValue: '' },
  subventionB: { action: ForecastAction.COPY, actionValue: '' },
  subventionC: { action: ForecastAction.COPY, actionValue: '' },
};

export const SubventionForecastContainer = () => {
  const dispatch = useDispatch();
  const trans = useTranslator();

  useEffect(() => {
    dispatch(fetchSubventionFactors());
  }, [dispatch]);

  const { year, factors, loading } = useSelector(subventionFactorsSelector);

  const structure = factors ? getStructure(trans, factors, initialValues) : [];

  return <SubventionForecast isLoading={loading} factors={factors} year={year} structure={structure} />;
};

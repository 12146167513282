import { muiStyles } from 'utils/design';

export const useStyles = muiStyles((theme) => ({
  warning: {
    color: theme.palette.error.main,
  },
  list: {
    overflow: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: 0,
  },
}));

import { ColumnsConfig } from 'components/shared/data/DataGrid/DataGrid.types';
import { PreviewRecordRow } from 'store/institutions';
import { compact } from 'lodash';

export const previewColumns: ColumnsConfig<PreviewRecordRow> = (parameters) =>
  compact([
    {
      field: 'firstName',
      name: 'INSTITUTIONS.STUDENTS_REGISTER.IMPORT_STEPS.PREVIEW.FIRST_NAME',
    },
    {
      field: 'lastName',
      name: 'INSTITUTIONS.STUDENTS_REGISTER.IMPORT_STEPS.PREVIEW.LAST_NAME',
    },
    {
      field: 'identificationNumber',
      name: 'INSTITUTIONS.STUDENTS_REGISTER.IMPORT_STEPS.PREVIEW.IDENTIFICATION_NUMBER',
      cellClass: (params) => {
        if (params.data.identificationNumberType === 'OTHER') {
          return 'importWarning';
        }
        return '';
      },
    },
    {
      field: 'identificationNumberType',
      name: 'INSTITUTIONS.STUDENTS_REGISTER.IMPORT_STEPS.PREVIEW.IDENTIFICATION_NUMBER_TYPE',
      format: (data, trans) => {
        return trans(
          `INSTITUTIONS.STUDENTS_REGISTER.IMPORT_STEPS.PREVIEW.IDENTIFICATION_NUMBER_TYPE_${data.identificationNumberType.toUpperCase()}`,
        );
      },
      cellClass: (params) => {
        if (params.data.identificationNumberType === 'OTHER') {
          return 'importWarning';
        }
        return '';
      },
    },
    {
      field: 'level',
      name: 'INSTITUTIONS.STUDENTS_REGISTER.IMPORT_STEPS.PREVIEW.LEVEL',
      columnParams: { hide: !parameters.level },
    },
    {
      field: 'voivodeship',
      name: 'INSTITUTIONS.STUDENTS_REGISTER.IMPORT_STEPS.PREVIEW.VOIVODESHIP',
      columnParams: {
        hide: !parameters.voivodeship,
      },
    },
    {
      field: 'city',
      name: 'INSTITUTIONS.STUDENTS_REGISTER.IMPORT_STEPS.PREVIEW.CITY',
      columnParams: {
        hide: !parameters.city,
      },
    },
    {
      field: 'cityId',
      name: 'INSTITUTIONS.STUDENTS_REGISTER.IMPORT_STEPS.PREVIEW.CITY_TERYT',
      columnParams: {
        hide: !parameters.cityId,
      },
    },
    {
      field: 'street',
      name: 'INSTITUTIONS.STUDENTS_REGISTER.IMPORT_STEPS.PREVIEW.STREET',
      columnParams: {
        hide: !parameters.street,
      },
    },
    {
      field: 'buildingNumber',
      name: 'INSTITUTIONS.STUDENTS_REGISTER.IMPORT_STEPS.PREVIEW.BUILDING_NUMBER',
      columnParams: {
        hide: !parameters.buildingNumber,
      },
    },
    {
      field: 'flatNumber',
      name: 'INSTITUTIONS.STUDENTS_REGISTER.IMPORT_STEPS.PREVIEW.FLAT_NUMBER',
      columnParams: {
        hide: !parameters.flatNumber,
      },
    },
  ]);

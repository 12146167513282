import { Theme } from '@material-ui/core';
import { useStyles } from 'components/polls/Results/Responses/Responses.styles';
import { ResponsesProps } from 'components/polls/Results/Results.types';
import { questionComponentMapping } from 'components/polls/shared/Question/mapping';
import { Form } from 'components/shared/form';
import { useFormatter, useTranslator } from 'components/shared/hooks';
import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon';
import { TextLoader } from 'components/shared/loaders/TextLoader/TextLoader';
import { Switcher } from 'components/shared/navigation/Switcher/Switcher';
import { Splash } from 'components/shared/surfaces/Splash/Splash';
import { Heading } from 'components/shared/typography/Heading/Heading';
import React, { FC } from 'react';
import { formPreviewThemeFactory } from 'styles/themes/formPreviewTheme';
import { recipientGroupSlug } from 'utils/i18n/recipientGroup';
import { ThemeProvider } from '@material-ui/styles';

export const Responses: FC<ResponsesProps> = (props) => {
  const trans = useTranslator();
  const { formatDateWithTime } = useFormatter();
  const classes = useStyles();

  const formRender = () => {
    const formattedResponseDate = formatDateWithTime(props.response.responseDate);

    return (
      <>
        <div className={classes.summary}>
          <Heading type={'h4'} className={'mb-2'}>
            {trans('POLLS.RESULTS.RESPONSES.AUTHOR', {
              group: trans(recipientGroupSlug(props.response.recipientGroup)),
              institution: props.response.institutionName,
            })}
          </Heading>
          <strong className="d-block">
            {trans('POLLS.RESULTS.RESPONSES.RESPONSE_DATE', { date: formattedResponseDate })}
          </strong>
        </div>

        <Form initialValues={props.formValues} onSubmit={() => {}}>
          {() => (
            <ThemeProvider theme={(parentTheme: Theme) => formPreviewThemeFactory(parentTheme)}>
              {props.questions.map((question, index) => {
                const Component = questionComponentMapping[question.type];
                return <Component field={`answers.${index}`} question={question} key={index} disabled />;
              })}
            </ThemeProvider>
          )}
        </Form>
      </>
    );
  };

  if (props.pageCount === 0) {
    return (
      <Splash>
        <span>
          <FontAwesomeIcon name={'exclamation-triangle'} className={'mr-2'} />
          {trans('POLLS.RESULTS.RESPONSES.NO_RESPONSES')}
        </span>
      </Splash>
    );
  }

  const isLoading = props.loading || !props.response;

  return (
    <>
      <div className={classes.container}>
        <div className={classes.form}>{!isLoading && formRender()}</div>

        <div className={classes.sidebar}>
          <Switcher
            page={props.page}
            total={props.pageCount}
            onChange={props.onPageUpdate}
            className={classes.pagination}
          />
        </div>
      </div>
      {isLoading && <TextLoader text={trans('POLLS.RESULTS.RESPONSES.LOADING')} />}
    </>
  );
};

import React, { forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import { Button } from 'components/shared/buttons/Button/Button';
import { useTranslator } from 'components/shared/hooks';
import { SingleAnswer } from 'components/polls/Results/Combined/SingleAnswer/SingleAnswer';
import { CombinedProps } from 'components/polls/Results/Results.types';
import { downloadPoll } from 'store/polls';

const CombinedComponent = (props: CombinedProps, ref) => {
  const trans = useTranslator();
  const dispatch = useDispatch();

  const handleDownloadPoll = () => {
    dispatch(downloadPoll(props.pollId, props.pollName));
  };

  return (
    <>
      <Box marginBottom={4} display="flex" alignItems="flex-start">
        <Box flexGrow={1}></Box>
        <Box display="flex">
          <Button variant="outlined" color="primary" onClick={handleDownloadPoll}>
            {trans('POLLS.RESULTS.DOWNLOAD')}
          </Button>
          <Box marginLeft={1}>
            <Button variant="outlined" color="primary" onClick={props.print}>
              {trans('POLLS.RESULTS.PRINT')}
            </Button>
          </Box>
        </Box>
      </Box>
      <div ref={ref}>
        {props.results.map((result, index) => {
          return <SingleAnswer result={result} index={index + 1} key={index} printMode={props.printMode} />;
        })}
      </div>
    </>
  );
};

export const Combined = forwardRef(CombinedComponent);

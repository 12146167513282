import React, { FC, useRef } from 'react';
import { Field, FieldProps } from 'formik';
import { get } from 'lodash';
import { FormControl, InputLabel, FormHelperText, OutlinedInput } from '@material-ui/core';
import { useStyles } from './UploadFile.styles';
import { Button } from 'components/shared/buttons/Button/Button';
import { UploadFileProps } from './UploadFile.types';
import classNames from 'classnames';

export const UploadFile: FC<UploadFileProps> = ({
  label,
  name,
  required,
  defaultInputValue = '',
  defaultLabelWidth = 0,
}) => {
  const classes = useStyles();
  const inputEl = useRef(null);
  const labelRef = React.useRef(null);

  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        const isError = !!get(form.errors, field.name);

        const handleFileUpload = (event) => {
          const file = event.target.files[0];

          if (file) {
            form.setFieldValue(field.name, file);
          }
        };

        return (
          <FormControl
            error={isError}
            variant="outlined"
            margin="dense"
            required={required}
            className={classNames({ hasValidationError: isError })}
          >
            <div className={classes.container}>
              <InputLabel ref={labelRef} classes={{ root: classes.label }} error={false}>
                {label}
              </InputLabel>
              <input
                accept=".csv"
                className={classes.fileInput}
                multiple
                type="file"
                ref={inputEl}
                onChange={handleFileUpload}
                data-testid={`file-upload-${field.name}-hidden`}
              />
              <OutlinedInput
                labelWidth={
                  labelRef && labelRef.current && labelRef.current.offsetWidth
                    ? labelRef.current.offsetWidth
                    : defaultLabelWidth
                }
                value={field.value ? field.value.name : defaultInputValue}
                name={field.name}
                onClick={() => inputEl.current.click()}
                margin="dense"
                disabled
                classes={{ root: classes.input }}
                inputProps={{
                  'data-testid': `file-upload-${field.name}`,
                }}
              />
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                onClick={() => inputEl.current.click()}
              >
                Przeglądaj
              </Button>
            </div>
            <FormHelperText error>{isError && get(form.errors, field.name)}</FormHelperText>
          </FormControl>
        );
      }}
    </Field>
  );
};

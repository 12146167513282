import pl from 'translations/pl_PL.json';
import flatten from 'flat';

interface MessagesType {
  [id: string]: string;
}

export function getMessages(locale: string): MessagesType | never {
  if (locale === 'pl') {
    return flatten(pl);
  }

  if (locale === 'en') {
    return {};
  }

  throw Error('Incorrect locale');
}

import { muiStyles } from 'utils/design';

export const useStyles = muiStyles((theme) => ({
  container: {
    minHeight: 400,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'flex-start',

    '& .ag-cell.importWarning': {
      color: theme.palette.error.main,
    },

    '& .ag-body-viewport .ag-cell .ag-cell-wrapper': {
      justifyContent: 'center',
    },
  },
  grid: {
    flexGrow: 1,
  },
  gridFooter: {
    padding: theme.spacing(2, 0),
  },
  importWarning: {
    color: theme.palette.error.main,
    paddingTop: theme.spacing(2),
  },
}));

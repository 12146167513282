import { ButtonBaseActions } from '@material-ui/core/ButtonBase';
import { useCallback, useLayoutEffect, useState } from 'react';

export const useFocusManager = (container: HTMLElement, onFocusLeft?: () => void) => {
  const [nodes, setNodes] = useState([]);
  const [actions, setActions] = useState([]);
  const [focusPointer, setFocusPointer] = useState(null);

  const resetFocusIndex = useCallback(() => {
    setFocusPointer(null);
  }, []);

  const movePointer = useCallback(
    (pointer: number | null, reverse: boolean): number => {
      if (pointer === null && reverse) {
        return nodes.length - 1;
      } else if (pointer === null) {
        return 0;
      } else if (reverse) {
        return pointer - 1;
      } else {
        return pointer + 1;
      }
    },
    [nodes.length],
  );

  const focusButton = useCallback(
    (index: number) => {
      if (nodes[index] && actions[index]) {
        nodes[index].focus();
        actions[index].focusVisible();
      }
    },
    [actions, nodes],
  );

  const focusNextButton = useCallback(
    (reverse = false): boolean => {
      const focusIndex = movePointer(focusPointer, reverse);

      if (actions[focusIndex] && nodes[focusIndex]) {
        focusButton(focusIndex);
        setFocusPointer(focusIndex);
        return true;
      } else {
        resetFocusIndex();
        onFocusLeft && onFocusLeft();
        return false;
      }
    },
    [actions, focusButton, focusPointer, movePointer, nodes, resetFocusIndex, onFocusLeft],
  );

  const collectNode = (node: HTMLElement, index: number) => {
    nodes[index] = node;
    setNodes(nodes);
  };

  const collectActions = (newActions: ButtonBaseActions, index: number) => {
    actions[index] = newActions;
    setActions(actions);
  };

  const keyDownHandler = useCallback(
    (event) => {
      if (event.key === 'Tab' && focusNextButton(event.shiftKey)) {
        event.preventDefault();
        event.stopPropagation();
      }
    },
    [focusNextButton],
  );

  useLayoutEffect(() => {
    if (container) {
      container.addEventListener('keydown', keyDownHandler);
      container.addEventListener('focus', resetFocusIndex);

      return () => {
        container.removeEventListener('keydown', keyDownHandler);
        container.removeEventListener('focus', resetFocusIndex);
      };
    }
  }, [keyDownHandler, resetFocusIndex, container]);

  return { collectNode, collectActions };
};

import { BoxedCell } from 'components/shared/data/DataGrid/renderers/cell/BoxedCell/BoxedCell';
import { ButtonsCellRenderer } from 'components/shared/data/DataGrid/renderers/cell/Buttons/Buttons';
import { ForecastManipulator } from 'components/shared/data/DataGrid/renderers/cell/ForecastModificator/ForecastManipulator';
import { IconsCellRenderer } from 'components/shared/data/DataGrid/renderers/cell/Icons/Icons';
import { IndentCell } from 'components/shared/data/DataGrid/renderers/cell/IndentCell/IndentCell';
import { LoadingCell } from 'components/shared/data/DataGrid/renderers/cell/LoadingCell/LoadingCell';
import { LoadingRecordsRenderer } from 'components/shared/data/DataGrid/renderers/cell/LoadingRecords/LoadingRecords';
import { TextWithIconsCellRenderer } from 'components/shared/data/DataGrid/renderers/cell/TextWithIcons/TextWithIcons';
import { SelectCell } from 'components/shared/data/DataGrid/renderers/cell/SelectCell/SelectCell';
import { TranslateCell } from 'components/shared/data/DataGrid/renderers/cell/TranslateCell/TranslateCell';
import { SearchDetailsRenderer } from 'components/shared/data/DataGrid/renderers/cell/SearchDetails/SearchDetails';

export const cellRenderers = {
  buttonsCell: ButtonsCellRenderer,
  iconsCell: IconsCellRenderer,
  indentCell: IndentCell,
  textWithIcons: TextWithIconsCellRenderer,
  loadingRecords: LoadingRecordsRenderer,
  loadingCell: LoadingCell,
  forecastManipulator: ForecastManipulator,
  boxCell: BoxedCell,
  selectCell: SelectCell,
  translateCell: TranslateCell,
  searchDetailsCell: SearchDetailsRenderer,
};

export type CellRenderers = typeof cellRenderers;

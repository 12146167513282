import { Typography } from '@material-ui/core';
import { OptionType } from 'components/shared/form/Autocomplete/Autocomplete.types';
import React from 'react';
import { SingleValueProps } from 'react-select';

export function SingleValue(props: SingleValueProps<OptionType>) {
  const labelToDisplay = props.data[props.selectProps.selectedLabel];
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {labelToDisplay ? labelToDisplay : props.children}
    </Typography>
  );
}

import { muiStyles } from 'utils/design';
import { colors } from 'styles/colors';

export const useStyles = muiStyles({
  grid: {
    flexGrow: 1,
  },
  databaseRow: {
    '&.ag-row-focus': {
      zIndex: 1000,
    },
    '.ag-theme-material & > .ag-cell.actions-cell': {
      overflow: 'visible',
    },
  },
  headerRow: {
    background: `${colors.neutralVeryLight} !important`,
    fontWeight: 'bolder',
    '.ag-theme-material &.ag-row-hover': {
      background: colors.neutralLight,
    },
  },
});

import { Page } from 'components/layout/panel/Page/Page';
import { AddContainer } from 'components/officials/Editor/AddContainer';
import { useTranslator } from 'components/shared/hooks';
import { Breadcrumb } from 'components/shared/navigation/Breadcrumbs/Breadcrumb';
import { Box } from 'components/shared/surfaces/Box/Box';
import { ROUTE_OFFICIALS_LIST } from 'config/routes';
import React, { FC, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { useStyles } from 'views/Panel/Officials/OfficialsEditor/OfficialsEditor.styles';

export const AddOfficialsView: FC = () => {
  const trans = useTranslator();
  const classes = useStyles();
  const dispatch = useDispatch();

  const goBack = () => dispatch(push(ROUTE_OFFICIALS_LIST));

  return (
    <Fragment>
      <Breadcrumb label={trans('BREADCRUMB.OFFICIALS.LIST')} to={ROUTE_OFFICIALS_LIST} />
      <Breadcrumb label={trans('BREADCRUMB.OFFICIALS.ADD')} static />

      <Page title={trans('OFFICIALS.EDITOR.EDITOR_ADD')}>
        <Box margin={[0, 2]} className={classes.box}>
          <AddContainer goBack={goBack} />
        </Box>
      </Page>
    </Fragment>
  );
};

import { Page } from 'components/layout/panel/Page/Page';
import { AddContainer } from 'components/polls/Editor/AddContainer';
import { useTranslator } from 'components/shared/hooks';
import { Breadcrumb } from 'components/shared/navigation/Breadcrumbs/Breadcrumb';
import { Box } from 'components/shared/surfaces/Box/Box';
import { ROUTE_POLLS_LIST } from 'config/routes';
import React, { FC, Fragment } from 'react';
import { useStyles } from 'views/Panel/Polls/PollsEditor/PollEditor.styles';

export const AddPollView: FC = () => {
  const trans = useTranslator();
  const classes = useStyles();

  return (
    <Fragment>
      <Breadcrumb label={trans('BREADCRUMB.POLLS.POLLS_LIST')} to={ROUTE_POLLS_LIST} />
      <Breadcrumb label={trans('BREADCRUMB.POLLS.POLL_ADD')} static />

      <Page title={trans('POLLS.EDITOR.EDITOR_ADD')}>
        <Box margin={[0, 2]} className={classes.box}>
          <AddContainer />
        </Box>
      </Page>
    </Fragment>
  );
};

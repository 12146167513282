import { AppDispatch } from '@typings/redux';
import { useFormatter, useNotification } from 'components/shared/hooks';
import { useEnvironment } from 'components/shared/hooks/useEnvironment/useEnvironment';
import { NewPasswordModal } from 'components/shared/dialogs/NewPasswordModal/NewPasswordModal';
import { OfficialForm, OfficialAccountType } from 'store/officials/officials.types';
import { postOfficial } from 'store/officials/officials.action';
import { postApplicant } from 'store/applicants/applicants.action';
import { accessRulesSelector } from 'store/accessRules';
import { Editor } from './Editor';
import { mapFormValueToPayload, mapFormApplicantValueToPayload } from './Editor.helpers';
import { AddContainerProps } from './Editor.types';
import { fromApiFormat } from 'utils/api/converters';
import { FormikHelpers } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInstitutionsList, institutionsListSelector } from 'store/institutions';

export const AddContainer: React.FC<AddContainerProps> = ({ goBack }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { formatApiErrors } = useFormatter();
  const { success } = useNotification();
  const accessRules = useSelector(accessRulesSelector);
  const [isSubmitted, setSubmitted] = React.useState<boolean>(false);
  const [showAccessData, setShowAccessData] = React.useState<boolean>(false);
  const [login, setLogin] = React.useState<string>('');
  const [startPassword, setStartPassword] = React.useState<string>('');
  const [initialValues, setInitialValues] = React.useState<OfficialForm>({
    firstName: '',
    lastName: '',
    email: '',
    role: '',
    permissions: {},
    units: [],
  });
  const { origin } = useEnvironment();

  const institutions = useSelector(institutionsListSelector);

  const handleSubmit = React.useCallback(
    (values: OfficialForm) => {
      if (values.role === OfficialAccountType.APPLICANT) {
        return dispatch(postApplicant(mapFormApplicantValueToPayload(values), origin));
      } else {
        return dispatch(postOfficial(mapFormValueToPayload(values), origin));
      }
    },
    [dispatch, origin],
  );

  const handleOnSubmit = (values: OfficialForm, formik: FormikHelpers<OfficialForm>) => {
    setSubmitted(true);
    handleSubmit(values).then((response) => {
      const result = fromApiFormat(response);
      if (result.error) {
        if (result.payload.response.errors) {
          formik.setErrors(formatApiErrors(result.payload.response.errors));
        }
        formik.setSubmitting(false);
      } else {
        setLogin(result.payload.login);
        setStartPassword(result.payload.startPassword);
        success('OFFICIALS.EDITOR.CREATE.SUCCESS');
        setSubmitted(true);
        setShowAccessData(true);
      }
    });
  };

  React.useEffect(() => {
    const permissions = accessRules.items.reduce((prev, current) => {
      prev[current.name] = 'no_access';
      return prev;
    }, {});
    setInitialValues({
      ...initialValues,
      permissions,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, accessRules.items]);

  React.useEffect(() => {
    dispatch(fetchInstitutionsList({ search: '' }));
  }, [dispatch]);

  return (
    <>
      <Editor
        initial={initialValues}
        accessRules={accessRules.items}
        units={institutions.items}
        submitted={isSubmitted}
        onSubmit={handleOnSubmit}
        onCancel={goBack}
      />
      <NewPasswordModal
        title={'OFFICIALS.EDITOR.ACCESS_DATA.TITLE'}
        message={'OFFICIALS.EDITOR.ACCESS_DATA.MESSAGE'}
        login={login}
        startPassword={startPassword}
        open={showAccessData}
        confirm={goBack}
      />
    </>
  );
};

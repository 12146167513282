import { useStyles } from 'components/shared/surfaces/Box/Box.styles';
import { BoxParams } from 'components/shared/surfaces/Box/Box.types';
import React, { FC } from 'react';
import classNames from 'classnames';

export const Box: FC<BoxParams> = (props) => {
  const classes = useStyles(props);

  return (
    <div className={classNames(classes.box, props.className, { [classes.boxGrow]: props.grow })}>{props.children}</div>
  );
};

import { ConfirmProps } from 'components/shared/dialogs/Confirm/Confirm.types';
import { useTranslator } from 'components/shared/hooks/useTranslator/useTranslator';
import React, { FC } from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

export const Confirm: FC<ConfirmProps> = (props) => {
  const trans = useTranslator();

  return (
    <Dialog disableEscapeKeyDown aria-labelledby="confirm-title" open={props.open}>
      {props.title && <DialogTitle id="confirm-title">{trans(props.title)}</DialogTitle>}
      <DialogContent>
        <DialogContentText>
          {props.children ? props.children : trans(props.message, props.messageValues)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {props.cancel ? (
          <Button onClick={props.cancel} color="secondary" variant="outlined" data-testid="confirm-no">
            {trans('COMMON.NO')}
          </Button>
        ) : null}
        <Button onClick={props.confirm} color="secondary" variant="contained" data-testid="confirm-yes">
          {props.confirmLabel ? props.confirmLabel : trans('COMMON.YES')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import { RSAA, RSAACreator } from 'redux-api-middleware';
import { OfficialsActionType } from 'store/officials/officials.actionTypes';
import { OfficialForm, OfficialPostModel } from './officials.types';
import { toApiFormat } from 'utils/api';

export const fetchOfficialDetails: RSAACreator<OfficialsActionType> = (officialId: string) => {
  return {
    [RSAA]: {
      endpoint: `/officials/${officialId}`,
      method: 'GET',
      types: [
        OfficialsActionType.FETCH_OFFICIALS_DETAILS_REQUEST,
        OfficialsActionType.FETCH_OFFICIALS_DETAILS_SUCCESS,
        {
          type: OfficialsActionType.FETCH_OFFICIALS_DETAILS_FAILURE,
          meta: {
            ignoreNotFoundError: true,
          },
        },
      ],
    },
  };
};

export const updateOfficial: RSAACreator<OfficialsActionType> = (
  officialId: string,
  data: OfficialForm,
  baseUrl: string,
) => {
  return {
    [RSAA]: {
      endpoint: `/officials/${officialId}`,
      method: 'PUT',
      params: toApiFormat({ baseUrl }),
      types: [
        {
          type: OfficialsActionType.UPDATE_OFFICIALS_DETAILS_REQUEST,
          meta: { lockScreen: true },
        },
        OfficialsActionType.UPDATE_OFFICIALS_DETAILS_SUCCESS,
        {
          type: OfficialsActionType.UPDATE_OFFICIALS_DETAILS_FAILURE,
          meta: {
            ignoreNotFoundError: true,
          },
        },
      ],
      body: toApiFormat(data),
    },
  };
};

export const deleteOfficial: RSAACreator<OfficialsActionType> = (officialId: string, baseUrl: string) => {
  return {
    [RSAA]: {
      endpoint: `/officials/${officialId}`,
      method: 'DELETE',
      params: toApiFormat({ baseUrl }),
      types: [
        {
          type: OfficialsActionType.DELETE_OFFICIALS_REQUEST,
          meta: { lockScreen: true },
        },
        OfficialsActionType.DELETE_OFFICIALS_SUCCESS,
        {
          type: OfficialsActionType.DELETE_OFFICIALS_FAILURE,
          meta: {
            ignoreNotFoundError: true,
          },
        },
      ],
    },
  };
};

export const postOfficial: RSAACreator<OfficialsActionType> = (payload: OfficialPostModel, baseUrl: string) => {
  return {
    [RSAA]: {
      endpoint: `/officials`,
      method: 'POST',
      types: [
        {
          type: OfficialsActionType.POST_OFFICIAL_REQUEST,
          meta: { lockScreen: true },
        },
        OfficialsActionType.POST_OFFICIAL_SUCCESS,
        OfficialsActionType.POST_OFFICIAL_FAILURE,
      ],
      params: toApiFormat({ baseUrl }),
      body: payload,
    },
  };
};

export const resetPasswordOfficial: RSAACreator<OfficialsActionType> = (officialId: string, baseUrl: string) => {
  return {
    [RSAA]: {
      endpoint: `/officials/reset-password/${officialId}`,
      method: 'PUT',
      params: toApiFormat({ baseUrl }),
      types: [
        {
          type: OfficialsActionType.RESET_PASSWORD_REQUEST,
          meta: { lockScreen: true },
        },
        OfficialsActionType.RESET_PASSWORD_SUCCESS,
        {
          type: OfficialsActionType.RESET_PASSWORD_FAILURE,
          meta: {
            ignoreNotFoundError: true,
          },
        },
      ],
    },
  };
};

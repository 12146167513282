import { PieChartProps } from 'components/polls/Results/Combined/charts/charts.types';
import { MaterialTooltip } from 'components/shared/charts/MaterialTooltip/MaterialTooltip';
import React from 'react';
import { colors } from 'styles/colors';
import { VictoryBar, VictoryChart, VictoryAxis } from 'victory';

export const ColumnChart = (props: PieChartProps) => {
  return (
    <VictoryChart domainPadding={20} padding={{ left: 100, right: 50, top: 50, bottom: 50 }} width={600}>
      <VictoryAxis
        dependentAxis
        tickFormat={(t) => `${t}%`}
        tickValues={[0, 20, 40, 60, 80, 100]}
        style={{
          grid: { stroke: colors.neutralLight },
        }}
      />
      <VictoryAxis
        style={{ tickLabels: { fontSize: 12 } }}
        tickFormat={(t: string) => (t.length > 15 ? `${t.slice(0, 12)}...` : t)}
      />
      <VictoryBar
        horizontal
        data={props.data}
        domain={{ y: [0, 100] }}
        domainPadding={{ y: 30 }}
        labels={({ datum }) => {
          return `${datum.y}% (${datum.count})`;
        }}
        labelComponent={<MaterialTooltip text={(params) => params.datum.x} />}
        style={{
          data: {
            fill: 'rgb(15,72,127)',
          },
          labels: {
            fontFamily: 'Roboto',
            fontSize: 12,
          },
        }}
      />
    </VictoryChart>
  );
};

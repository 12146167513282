import { ColumnsConfig, GroupingColumnConfig } from 'components/shared/data/DataGrid/DataGrid.types';
import { Recipient } from 'store/polls';
import { recipientGroupSlug } from 'utils/i18n/recipientGroup';

export const recipientsGroupingColumn: GroupingColumnConfig<any> = () => ({
  rendererParams: {
    suppressCount: true,
  },
});

export const recipientsColumns: ColumnsConfig<Recipient> = () => {
  return [
    {
      field: 'institutionName',
      name: 'POLLS.RESULTS.RECIPIENTS.COLUMNS.INSTITUTION',
      columnParams: {
        rowGroup: true,
        hide: true,
      },
    },
    {
      field: 'recipientGroup',
      name: 'POLLS.RESULTS.RECIPIENTS.COLUMNS.RECIPIENTS_GROUP',
      format: (data, trans) => {
        return trans(recipientGroupSlug(data.recipientGroup));
      },
      columnParams: {
        rowGroup: true,
        hide: true,
      },
    },
    {
      field: 'answeredAt',
      name: 'POLLS.RESULTS.RECIPIENTS.COLUMNS.ANSWERS',
      format: (data) => {
        return data.answeredAt === null ? null : 1;
      },
      aggregation: 'sum',
    },
    {
      field: 'answeredAt',
      name: 'POLLS.RESULTS.RECIPIENTS.COLUMNS.ANSWERED_AT',
      format: (data, trans, format) => {
        return data.answeredAt ? format.formatDateWithTime(data.answeredAt) : '';
      },
    },
  ];
};

import { Typography } from '@material-ui/core';
import { OptionType } from 'components/shared/form/Autocomplete/Autocomplete.types';
import React from 'react';
import { NoticeProps } from 'react-select/src/components/Menu';

export function NoOptionsMessage(props: NoticeProps<OptionType, boolean>) {
  return (
    <Typography color="textSecondary" className={props.selectProps.classes.noOptionsMessage} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

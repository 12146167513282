import { isYear } from '@typings/common';
import { yearsRegisterColumns } from 'components/schoolDistricts/AssignmentPreview/YearsRegister/columns.config';
import { useStyles } from 'components/schoolDistricts/AssignmentPreview/YearsRegister/YearRegister.styles';
import { DataGrid } from 'components/shared/data/DataGrid/DataGrid';
import { DataGridApi } from 'components/shared/data/DataGrid/DataGrid.types';
import { AutoSave } from 'components/shared/form/AutoSave/AutoSave';
import { TreeItem } from 'components/shared/form/TreeSelectBox/TreeSelectBox.types';
import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon';
import { Splash } from 'components/shared/surfaces/Splash/Splash';
import React, { FC, ReactNode, useState, useContext } from 'react';
import {
  ChildrenYearsGroup,
  YearRegisterTreeRow,
  YearsRegisterFilter,
  YearsRegisterProps,
} from 'components/schoolDistricts/AssignmentPreview/YearsRegister/YearsRegister.types';
import { Form } from 'components/shared/form';
import { TreeSelectBox } from 'components/shared/form/TreeSelectBox/TreeSelectBox';
import { toOptions } from 'utils/api';
import classNames from 'classnames';
import { Events } from 'ag-grid-community';
import { useGridEvent } from 'components/shared/data/DataGrid/hooks';
import { generateAddressRuleInYearRegisterDescription } from 'utils/addressRule/addressRule';
import { ChildrenAlertsTypes } from 'store/schoolDistricts';
import { LocaleContext } from 'context/locale';

export const YearsRegister: FC<YearsRegisterProps> = (props) => {
  const classes = useStyles();
  const [columns, setColumns] = useState<string[]>([]);
  const [gridApi, setGridApi] = useState(null);
  const { trans } = useContext(LocaleContext);

  const handleSubmit = (filters: YearsRegisterFilter) => {
    setColumns(filters.years);
  };

  const filterOptions: TreeItem[] = [
    {
      label: trans('SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.YEARS_REGISTER.YEARS_GROUP.SCHOOL'),
      value: ChildrenYearsGroup.SCHOOL,
      children: toOptions(props.schoolBirthYears).map((year) => ({ ...year, label: `${year.label} *` })),
    },
    {
      label: trans('SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.YEARS_REGISTER.YEARS_GROUP.YOUNG'),
      value: ChildrenYearsGroup.YOUNG,
      children: toOptions(props.youngBirthYears).map((year, index) =>
        index === 0 ? { ...year, label: `${year.label} *` } : { ...year },
      ),
    },
  ];

  const initialFilters = {
    years: [ChildrenYearsGroup.SCHOOL, ...props.schoolBirthYears],
  };

  const filterValueRender = (values: string[]): ReactNode => {
    return values.filter((item) => isYear(item)).join(', ');
  };

  const columnDefinitions = yearsRegisterColumns({
    columns,
    markedColumns: props.youngBirthYears,
    markedCellClass: classes.markedCell,
  });

  const onGridReady = ({ agGridApi }: DataGridApi) => {
    setGridApi(agGridApi);
  };

  useGridEvent(gridApi, Events.EVENT_GRID_COLUMNS_CHANGED, (event) => {
    event.api.columnController.columnApi.columnController.autoSizeColumn();
  });

  const createSchoolRulesList = () => {
    const allSchoolsRulesList = [];

    props.data.forEach((school) => {
      const fieldName = school.isAlert ? trans(getAlertName(school.name)) : school.name;

      allSchoolsRulesList.push({
        id: school.id,
        path: [fieldName],
        years: school.years,
        isAlert: school.isAlert,
      });

      if (!school.isAlert) {
        school.addressRules.forEach((rule) => {
          allSchoolsRulesList.push({
            id: rule.id,
            path: [fieldName, generateAddressRuleInYearRegisterDescription(rule, trans)],
            years: rule.years,
            isAlert: school.isAlert,
          });
        });
      }
    });

    return allSchoolsRulesList;
  };

  const getAlertName = (alert: string): string => {
    return {
      [ChildrenAlertsTypes.MISSING_ASSIGNMENT]: 'SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.VALUES.MISSING_ASSIGNMENT',
      [ChildrenAlertsTypes.MISSING_TERYT]: 'SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.VALUES.MISSING_TERYT',
    }[alert];
  };

  return (
    <>
      <div className="d-flex w-100 justify-content-between">
        <Form<YearsRegisterFilter> initialValues={initialFilters} onSubmit={handleSubmit} className={classes.form}>
          {() => (
            <>
              <TreeSelectBox
                options={filterOptions}
                name="years"
                placeholderText={trans('SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.YEARS_REGISTER.FILTER.YEARS')}
                labelText={trans('SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.YEARS_REGISTER.FILTER.YEARS')}
                renderValue={(value) => filterValueRender(value as string[])}
              />
              <AutoSave />
            </>
          )}
        </Form>
      </div>
      {columns.length === 0 && (
        <Splash>
          <span>
            <FontAwesomeIcon name="info-circle" className="mr-2" />
            {trans('SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.YEARS_REGISTER.NO_COLUMNS')}
          </span>
        </Splash>
      )}
      {columns.length > 0 && (
        <>
          <DataGrid<YearRegisterTreeRow>
            onGridReady={onGridReady}
            data={createSchoolRulesList()}
            columns={columnDefinitions}
            getRowClass={(data) => classNames({ [classes.alertRow]: data.data.isAlert })}
            treeHierarchy={(data) => data.path}
            groupingColumn={{
              name: 'SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.YEARS_REGISTER.SCHOOL_NAME',
              columnParams: { minWidth: 300, width: 300 },
            }}
            defaultExpand={0}
          />
          <div className="pt-3 text-muted text-right">
            {trans('SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.YEARS_REGISTER.INSTRUCTION')}
          </div>
        </>
      )}
    </>
  );
};

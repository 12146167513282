import { useEffect, useRef } from 'react';

export const useTimeout = (callback: Function, delay: number, deps = []) => {
  const cancel = useRef(() => {});

  useEffect(() => {
    const counterId = setTimeout(callback, delay);
    cancel.current = () => clearTimeout(counterId);

    return cancel.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback, delay, ...deps]);

  return cancel.current;
};

import { AppDispatch } from '@typings/redux';
import { ReportDetails } from 'components/schoolObligation/ReportDetails/ReportDetails';
import { ReportsDetailsContainerProps } from 'components/schoolObligation/ReportDetails/ReportsDetailsContainer.types';
import { useNotification, useTranslator } from 'components/shared/hooks';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router';
import {
  fetchSchoolObligationsReportDetails,
  getReportDetails,
  isReportDetailsLoading,
  publishSchoolObligationsReport,
  ReportStatus,
} from 'store/schoolObligations';

export const ReportsDetailsContainer: FC<ReportsDetailsContainerProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();
  const report = useSelector(getReportDetails);
  const isLoading = useSelector(isReportDetailsLoading);
  const { success } = useNotification();
  const trans = useTranslator();

  useEffect(() => {
    dispatch(fetchSchoolObligationsReportDetails(props.reportId));
  }, [dispatch, props.reportId]);

  const handleSave = async () => {
    await dispatch(publishSchoolObligationsReport(props.reportId));
    success('SCHOOL_OBLIGATION.REPORT.PUBLISHED');
    await dispatch(fetchSchoolObligationsReportDetails(props.reportId));
  };
  const handleBack = () => props.onBack();

  return (
    <>
      <Prompt when={report && report.status === ReportStatus.READY} message={trans('COMMON.CONFIRM_EXIT')} />
      <ReportDetails report={report} loading={isLoading} onSave={handleSave} onBack={handleBack}>
        {props.children}
      </ReportDetails>
    </>
  );
};

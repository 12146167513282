import { useTranslator } from 'components/shared/hooks';
import { Breadcrumb } from 'components/shared/navigation/Breadcrumbs/Breadcrumb';
import { ROUTE_STATISTIC } from 'config/routes';
import React, { Fragment } from 'react';
import { SchoolStatisticDetailsRoute } from 'config/routes.types';
import { RFC } from '@typings/extra-react-router';
import { SchoolStatDetailsContainer } from 'components/reports/SchoolStatDetails/SchoolStatDetailsContainer';

export const SchoolStatDetailsView: RFC<SchoolStatisticDetailsRoute> = (props) => {
  const trans = useTranslator();

  const statId = props.match.params.id;

  return (
    <Fragment>
      <Breadcrumb label={trans('BREADCRUMB.REPORTS.SCHOOL_STAT')} to={ROUTE_STATISTIC} />

      <SchoolStatDetailsContainer id={statId} />
    </Fragment>
  );
};

import React, { FC } from 'react';
import { Button } from 'components/shared/buttons/Button/Button';

export const NextButton: FC<{ onClick: () => void; disabled?: boolean }> = ({ children, onClick, disabled }) => {
  return (
    <Button onClick={onClick} color="secondary" variant="contained" disabled={disabled}>
      {children}
    </Button>
  );
};

import { RSAA, RSAACreator } from 'redux-api-middleware';
import { UserActionType } from 'store/user/user.actionTypes';
import { addEnvironmentOrigin } from 'utils/string/addPathParameter';

export const fetchUserInfo: RSAACreator<UserActionType> = () => {
  return {
    [RSAA]: {
      endpoint: 'me',
      method: 'GET',
      types: [
        {
          type: UserActionType.FETCH_USER_INFO_REQUEST,
          meta: {
            takeLatest: true,
          },
        },
        UserActionType.FETCH_USER_INFO_SUCCESS,
        UserActionType.FETCH_USER_INFO_SUCCESS,
      ],
      params: {},
    },
  };
};

export const fetchMessagesLink: RSAACreator<UserActionType> = (environmentOrigin: string) => {
  return {
    [RSAA]: {
      endpoint: addEnvironmentOrigin('/messages/link', environmentOrigin),
      method: 'GET',
      types: [
        {
          type: UserActionType.FETCH_MESSAGES_LINK_REQUEST,
          meta: {
            takeLatest: true,
            lockScreen: true,
          },
        },
        UserActionType.FETCH_MESSAGES_LINK_SUCCESS,
        UserActionType.FETCH_MESSAGES_LINK_FAILURE,
      ],
      params: {},
    },
  };
};

export const fetchUserPermission: RSAACreator<UserActionType> = (userId: string) => {
  return {
    [RSAA]: {
      endpoint: `/officials/${userId}`,
      method: 'GET',
      types: [
        {
          type: UserActionType.FETCH_USER_INFO_REQUEST,
          meta: {
            takeLatest: true,
          },
        },
        UserActionType.FETCH_USER_PERMISSION_SUCCESS,
        UserActionType.FETCH_USER_PERMISSION_FAILURE,
      ],
      params: {},
    },
  };
};

import { useStyles } from 'components/shared/form/AutoSave/AutoSave.styles';
import { useDifference } from 'components/shared/hooks/useDifference/useDifference';
import React, { ComponentType, useEffect } from 'react';
import { debounce, intersection } from 'lodash';
import { connect } from 'formik';

interface AutoSaveProps {
  debounced?: string[];
  debounceTime?: number;
}

export const AutoSave: ComponentType<AutoSaveProps> = connect((props) => {
  const { formik, debounced = [], debounceTime = 600 } = props;

  const classes = useStyles();
  const { changesKeys } = useDifference(formik.values);

  const shouldDebounceSubmit = () => {
    return debounced.length && intersection(debounced, changesKeys).length > 0;
  };

  const debouncedSubmit = debounce(() => {
    return formik.submitForm();
  }, debounceTime);

  useEffect(() => {
    if (changesKeys.length === 0) {
      return;
    } else if (shouldDebounceSubmit()) {
      debouncedSubmit();
      return () => {
        debouncedSubmit.cancel();
      };
    } else {
      formik.submitForm();
    }
    // eslint-disable-next-line
  }, [formik.values]);

  return <span className={classes.autoSave} />;
});

import React, { FC, useEffect, useRef } from 'react';
import { BackButton, NextButton, StepActions, StepContent } from 'components/shared/dialogs/StepModal';
import { useNotification, useTranslator } from 'components/shared/hooks';
import { PreviewForm, PreviewProps } from './Preview.types';
import { DataGrid } from 'components/shared/data/DataGrid/DataGrid';
import { previewColumns } from './columns.config';
import { useStyles } from './Preview.styles';
import classNames from 'classnames';
import { CheckBox, Form } from 'components/shared/form';
import { FormikHelpers } from 'formik';
import { PreviewRecordRow } from 'store/institutions';

export const Preview: FC<PreviewProps> = ({
  goBack,
  previewRecords,
  loading,
  identificationNumberNotPesel,
  totalNumber,
  fetchStudentRegisterPreview,
  postStudentRegisterConfirm,
  redirectToList,
  columns,
}) => {
  const form = useRef(null);
  const trans = useTranslator();
  const classes = useStyles();
  const { success } = useNotification();

  useEffect(() => {
    fetchStudentRegisterPreview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (values: PreviewForm, formik: FormikHelpers<PreviewForm>) => {
    if (!values.confirmUpload) {
      formik.setFieldError('confirmUpload', trans('COMMON.FORM.REQUIRED'));
      formik.setSubmitting(false);
    } else {
      postStudentRegisterConfirm().then(() => {
        success('INSTITUTIONS.STUDENTS_REGISTER.IMPORT.SUCCESS');
        redirectToList();
      });
    }
  };

  return (
    <>
      <StepContent>
        <div className={classNames('container pb-1 pt-1 text-center', classes.container)}>
          <DataGrid<PreviewRecordRow>
            className={classes.grid}
            data={previewRecords}
            isLoading={loading}
            columns={previewColumns(columns)}
            loadingMessage={'INSTITUTIONS.STUDENTS_REGISTER.IMPORT.RESULTS-LOADING'}
            noResultsMessage={'INSTITUTIONS.STUDENTS_REGISTER.IMPORT.NOTHING-FOUND'}
          />
          <div className={classes.gridFooter} data-testid={'grid-footer'}>
            {trans('INSTITUTIONS.STUDENTS_REGISTER.IMPORT_STEPS.PREVIEW.IMPORT_ROWS', { number: totalNumber })}
          </div>
          {previewRecords.length > 0 && (
            <Form<PreviewForm>
              initialValues={{ confirmUpload: !identificationNumberNotPesel }}
              onSubmit={handleSubmit}
              innerRef={form}
            >
              {() => {
                return (
                  !!identificationNumberNotPesel && (
                    <div className={classes.importWarning} data-testid="import-warning">
                      {trans('INSTITUTIONS.STUDENTS_REGISTER.IMPORT_STEPS.PREVIEW.ROWS_WITHOUT_PESEL', {
                        number: identificationNumberNotPesel,
                      })}
                      <CheckBox
                        name="confirmUpload"
                        labelText={trans('INSTITUTIONS.STUDENTS_REGISTER.IMPORT_STEPS.PREVIEW.CONFIRM_IMPORT')}
                        required
                      />
                    </div>
                  )
                );
              }}
            </Form>
          )}
        </div>
      </StepContent>
      <StepActions>
        <BackButton onClick={goBack}>{trans('COMMON.PREVIOUS')}</BackButton>
        <NextButton
          onClick={() => !form.current.isSubmitting && form.current.handleSubmit()}
          disabled={previewRecords.length === 0}
        >
          {trans('COMMON.NEXT')}
        </NextButton>
      </StepActions>
    </>
  );
};

import { Behaviours, FSAReducer } from '@typings/redux';
import { RESET_STATE } from 'store/store';
import { fromApiFormat } from 'utils/api';
import { InstitutionsActionType as ActionType } from './institutions.actionTypes';
import { defaultState, InstitutionsState as State } from './institutions.state';

export const institutionsReducer: FSAReducer<State, ActionType> = (state = defaultState, action) => {
  const behaviours: Behaviours<State, ActionType> = {
    [RESET_STATE]: () => ({
      ...defaultState,
    }),
    [ActionType.FETCH_INSTITUTIONS_LIST_REQUEST]: () => {
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      };
    },
    [ActionType.FETCH_INSTITUTIONS_LIST_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        list: {
          ...state.list,
          items: fromApiFormat(payload.items),
          loading: false,
          ready: true,
        },
      };
    },
    [ActionType.FETCH_INSTITUTIONS_LIST_FAILURE]: ({ error }) => {
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: error,
        },
      };
    },
    [ActionType.SET_INSTITUTION_FILTERS]: ({ payload }) => {
      return {
        ...state,
        list: {
          ...state.list,
          filters: payload,
        },
      };
    },
    [ActionType.FETCH_INSTITUTIONS_DICTIONARY_REQUEST]: () => {
      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          loading: true,
        },
      };
    },
    [ActionType.FETCH_INSTITUTIONS_DICTIONARY_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          list: fromApiFormat(payload),
          loading: false,
          ready: true,
        },
      };
    },
    [ActionType.FETCH_INSTITUTIONS_DICTIONARY_FAILURE]: ({ error }) => {
      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          loading: false,
          error: error,
        },
      };
    },
    [ActionType.FETCH_STUDENT_REGISTER_SCHOOL_YEARS_REQUEST]: () => {
      return {
        ...state,
        studentRegisterSchoolYears: {
          ...state.studentRegisterSchoolYears,
          loading: true,
        },
      };
    },
    [ActionType.FETCH_STUDENT_REGISTER_SCHOOL_YEARS_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        studentRegisterSchoolYears: {
          ...state.studentRegisterSchoolYears,
          list: fromApiFormat(payload),
          ready: true,
          loading: false,
        },
      };
    },
    [ActionType.FETCH_STUDENT_REGISTER_SCHOOL_YEARS_FAILURE]: ({ error }) => {
      return {
        ...state,
        studentRegisterSchoolYears: {
          ...state.studentRegisterSchoolYears,
          loading: false,
          ready: false,
          error: error,
        },
      };
    },
    [ActionType.FETCH_INSTITUTION_REQUEST]: () => {
      return {
        ...state,
        institution: {
          ...state.institution,
          loading: true,
        },
      };
    },
    [ActionType.FETCH_INSTITUTION_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        institution: {
          ...state.institution,
          data: fromApiFormat(payload),
          loading: false,
        },
      };
    },
    [ActionType.FETCH_INSTITUTION_FAILURE]: ({ error }) => {
      return {
        ...state,
        institution: {
          ...state.institution,
          loading: false,
          error: error,
        },
      };
    },
    [ActionType.FETCH_UNIT_REQUEST]: () => {
      return {
        ...state,
        unit: {
          ...state.unit,
          loading: true,
        },
      };
    },
    [ActionType.FETCH_UNIT_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        unit: {
          ...state.unit,
          data: fromApiFormat(payload),
          loading: false,
        },
      };
    },
    [ActionType.FETCH_UNIT_FAILURE]: ({ error }) => {
      return {
        ...state,
        unit: {
          ...state.unit,
          loading: false,
          error: error,
        },
      };
    },
    [ActionType.CLEAR_UNIT]: () => {
      return {
        ...state,
        unit: {
          ...state.unit,
          data: null,
        },
      };
    },
    [ActionType.FETCH_STUDENT_REGISTER_LIST_REQUEST]: () => {
      return {
        ...state,
        studentRegister: {
          ...state.studentRegister,
          list: {
            ...state.studentRegister.list,
            loading: true,
          },
        },
      };
    },
    [ActionType.FETCH_STUDENT_REGISTER_LIST_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        studentRegister: {
          ...state.studentRegister,
          list: {
            items: fromApiFormat(payload.items),
            loading: false,
            ready: true,
          },
        },
      };
    },
    [ActionType.FETCH_STUDENT_REGISTER_LIST_FAILURE]: ({ error }) => {
      return {
        ...state,
        studentRegister: {
          ...state.studentRegister,
          list: {
            ...state.studentRegister.list,
            loading: false,
            error: error,
          },
        },
      };
    },
    [ActionType.CLEAR_STUDENT_REGISTER_LIST]: () => {
      return {
        ...state,
        studentRegister: {
          ...state.studentRegister,
          list: { ...defaultState.studentRegister.list },
        },
      };
    },
    [ActionType.FETCH_STUDENT_REGISTER_REQUEST]: () => {
      return {
        ...state,
        studentRegister: {
          ...state.studentRegister,
          register: {
            ...state.studentRegister.register,
            loading: true,
          },
        },
      };
    },
    [ActionType.FETCH_STUDENT_REGISTER_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        studentRegister: {
          ...state.studentRegister,
          register: {
            item: fromApiFormat(payload),
            loading: false,
            ready: true,
          },
        },
      };
    },
    [ActionType.FETCH_STUDENT_REGISTER_FAILURE]: ({ error }) => {
      return {
        ...state,
        studentRegister: {
          ...state.studentRegister,
          register: {
            ...state.studentRegister.register,
            loading: false,
            error: error,
          },
        },
      };
    },
    [ActionType.CLEAR_STUDENT_REGISTER]: () => {
      return {
        ...state,
        studentRegister: {
          ...state.studentRegister,
          register: { ...defaultState.studentRegister.register },
        },
      };
    },
    [ActionType.POST_STUDENT_REGISTER_IMPORT_REQUEST]: () => {
      return {
        ...state,
        studentRegister: {
          ...state.studentRegister,
          import: defaultState.studentRegister.import,
        },
      };
    },
    [ActionType.POST_STUDENT_REGISTER_IMPORT_SUCCESS]: ({ payload, meta }) => {
      return {
        ...state,
        studentRegister: {
          ...state.studentRegister,
          import: {
            ...state.studentRegister.import,
            columns: [],
            loading: false,
            token: payload.token,
            fileName: meta.fileName,
          },
        },
      };
    },
    [ActionType.POST_STUDENT_REGISTER_IMPORT_FAILURE]: () => {
      return {
        ...state,
        studentRegister: {
          ...state.studentRegister,
          import: {
            ...state.studentRegister.import,
            columns: [],
            loading: false,
            token: null,
          },
        },
      };
    },
    [ActionType.DELETE_STUDENT_REGISTER_IMPORT_SUCCESS]: () => {
      return {
        ...state,
        studentRegister: {
          ...state.studentRegister,
          import: defaultState.studentRegister.import,
        },
      };
    },
    [ActionType.FETCH_STUDENT_REGISTER_IMPORT_COLUMNS_REQUEST]: () => {
      return {
        ...state,
        studentRegister: {
          ...state.studentRegister,
          import: {
            ...state.studentRegister.import,
            loading: true,
          },
        },
      };
    },
    [ActionType.FETCH_STUDENT_REGISTER_IMPORT_COLUMNS_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        studentRegister: {
          ...state.studentRegister,
          import: {
            ...state.studentRegister.import,
            columns: payload.fields,
            loading: false,
          },
        },
      };
    },
    [ActionType.FETCH_STUDENT_REGISTER_IMPORT_COLUMNS_FAILURE]: ({ payload }) => {
      return {
        ...state,
        studentRegister: {
          ...state.studentRegister,
          import: {
            ...state.studentRegister.import,
            loading: false,
            error: payload.response.error ? payload.response.error : null,
          },
        },
      };
    },
    [ActionType.POST_STUDENT_REGISTER_IMPORT_CONTEXT_SUCCESS]: ({ meta }) => {
      return {
        ...state,
        studentRegister: {
          ...state.studentRegister,
          import: {
            ...state.studentRegister.import,
            context: meta.context,
          },
        },
      };
    },
    [ActionType.FETCH_STUDENT_REGISTER_PREVIEW_REQUEST]: () => {
      return {
        ...state,
        studentRegister: {
          ...state.studentRegister,
          import: {
            ...state.studentRegister.import,
            loading: true,
          },
        },
      };
    },
    [ActionType.FETCH_STUDENT_REGISTER_PREVIEW_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        studentRegister: {
          ...state.studentRegister,
          import: {
            ...state.studentRegister.import,
            preview: fromApiFormat(payload),
            loading: false,
          },
        },
      };
    },
    [ActionType.FETCH_STUDENT_REGISTER_PREVIEW_FAILURE]: () => {
      return {
        ...state,
        studentRegister: {
          ...state.studentRegister,
          import: {
            ...state.studentRegister.import,
            loading: false,
          },
        },
      };
    },
    [ActionType.CLEAR_STUDENT_REGISTER_INFORMATION]: () => {
      return {
        ...state,
        studentRegister: {
          ...state.studentRegister,
          import: defaultState.studentRegister.import,
        },
      };
    },
    [ActionType.MAP_IMPORT_FIELDS]: ({ payload }) => {
      return {
        ...state,
        studentRegister: {
          ...state.studentRegister,
          import: {
            ...state.studentRegister.import,
            context: {
              ...state.studentRegister.import.context,
              map: payload,
            },
          },
        },
      };
    },
  };

  return behaviours[action.type] ? (behaviours[action.type](action) as State) : state;
};

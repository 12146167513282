import { Checkbox, IconButton, Radio } from '@material-ui/core';
import { useStyles } from 'components/polls/Editor/Question/Question.styles';
import { QuestionProps } from 'components/polls/Editor/Editor.types';
import { questionTypeIcon } from 'components/polls/Editor/Question/icons';
import { OptionsList } from 'components/polls/Editor/Question/OptionsList/OptionsList';
import { ActionButton } from 'components/shared/buttons/ActionButton/ActionButton';
import { Confirm } from 'components/shared/dialogs/Confirm/Confirm';
import { CheckBox, InputText } from 'components/shared/form';
import { useTranslator } from 'components/shared/hooks';
import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon';
import { ExpandablePanel } from 'components/shared/surfaces/ExpandablePanel/ExpandablePanel';
import React, { FC, useState } from 'react';
import { QuestionType } from 'store/polls';
import { isEqual } from 'lodash';

const QuestionComponent: FC<QuestionProps> = ({ question, field, onRemove, index: questionIndex }) => {
  const classes = useStyles();
  const trans = useTranslator();
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);

  const panelTitle = (
    <>
      <FontAwesomeIcon name={questionTypeIcon[question.type]} className="mr-2" />
      {trans('POLLS.EDITOR.FIELDS.QUESTION_HEADING', { index: questionIndex + 1 })}
    </>
  );

  return (
    <div className={classes.question} data-testid="question">
      <ExpandablePanel title={panelTitle} defaultExpanded={true} className={classes.panel}>
        <InputText
          name={`${field}.title`}
          labelText={trans('POLLS.EDITOR.FIELDS.QUESTION_TITLE')}
          data-testid="title"
        />

        {question.type === QuestionType.TEXT && (
          <InputText disabled value={trans('POLLS.EDITOR.SINGLE_LINE.PLACEHOLDER')} />
        )}

        {question.type === QuestionType.LONG_TEXT && (
          <InputText disabled multiline value={trans('POLLS.EDITOR.MULTIPLE_LINE.PLACEHOLDER').repeat(12)} />
        )}

        {question.type === QuestionType.SINGLE_CHOICE && (
          <OptionsList field={field} index={questionIndex} inputPreview={<Radio disabled className={'p-0'} />} />
        )}

        {question.type === QuestionType.MULTIPLE_CHOICE && (
          <OptionsList field={field} index={questionIndex} inputPreview={<Checkbox disabled className={'p-0'} />} />
        )}

        <div className={classes.footer}>
          <CheckBox
            name={`${field}.required`}
            color="primary"
            labelText={trans('POLLS.EDITOR.FIELDS.QUESTION_ANSWER_REQUIRED')}
            data-testid="questionRequired"
          />

          <ActionButton
            component={IconButton}
            variant="contained"
            disableCaret={true}
            color="default"
            label={() => <FontAwesomeIcon name={'ellipsis-v'} className={'mx-2'} />}
            actionList={[
              {
                action: () => setRemoveDialogOpen(true),
                label: 'POLLS.EDITOR.ACTIONS.REMOVE_QUESTION.BUTTON',
                icon: 'trash',
              },
            ]}
          />

          <Confirm
            open={removeDialogOpen}
            confirm={() => {
              setRemoveDialogOpen(false);
              onRemove();
            }}
            cancel={() => setRemoveDialogOpen(false)}
            title={'POLLS.EDITOR.ACTIONS.REMOVE_QUESTION.DIALOG_TITLE'}
            message={'POLLS.EDITOR.ACTIONS.REMOVE_QUESTION.DIALOG_MESSAGE'}
          />
        </div>
      </ExpandablePanel>
    </div>
  );
};

export const Question = React.memo(QuestionComponent, (prevProps, nextProps) => {
  return isEqual(prevProps.question, nextProps.question) && prevProps.field === nextProps.field;
});

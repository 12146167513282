import { AppDispatch } from '@typings/redux';
import { Browser } from 'components/institution/Browser/Browser';
import { useNotification } from 'components/shared/hooks/useNotification/useNotification';
import { ROUTE_INSTITUTION_EDIT, ROUTE_INSTITUTION_UNIT_EDIT } from 'config/routes';
import { push } from 'connected-react-router';
import { formatRoute } from 'react-router-named-routes';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchInstitutionsDictionary,
  fetchInstitutionsList,
  fetchStudentRegisterSchoolYears,
  InstitutionModel,
  institutionsDictionarySelector,
  institutionsListFiltersSelector,
  institutionsListSelector,
  isInstitution,
  removeInstitution,
  removeUnit,
  UnitModel,
} from 'store/institutions';

export const BrowserContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { success, error } = useNotification();

  const filters = useSelector(institutionsListFiltersSelector);
  const institutions = useSelector(institutionsListSelector);
  const institutionsDictionary = useSelector(institutionsDictionarySelector);

  useEffect(() => {
    dispatch(fetchInstitutionsDictionary());
    dispatch(fetchStudentRegisterSchoolYears());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchInstitutionsList(filters));
  }, [dispatch, filters]);

  const editItem = (data) => {
    if (isInstitution(data)) {
      dispatch(push(formatRoute(ROUTE_INSTITUTION_EDIT, { id: data.id })));
    } else {
      dispatch(push(formatRoute(ROUTE_INSTITUTION_UNIT_EDIT, { id: data.id })));
    }
  };

  const deleteItemActionFactory = (item: InstitutionModel | UnitModel) => {
    return isInstitution(item) ? removeInstitution(item.id) : removeUnit(item.id);
  };

  const deleteItem = (item: InstitutionModel | UnitModel) => {
    return dispatch(deleteItemActionFactory(item)).then(
      (result) => {
        if (
          result.payload &&
          result.payload.response.errors &&
          result.payload.response.errors.error === 'UNIT.PARTICIPATED_IN_OPEN_FINANCIAL_YEAR'
        ) {
          error('INSTITUTIONS.STUDENTS_REGISTER.DELETE_REGISTER.FAILURE_ICG');
        } else if (
          (result.payload &&
            result.payload.response.errors &&
            result.payload.response.errors.error === 'UNIT.PARTICIPATED_IN_DRAFT_FINANCIAL_YEAR') ||
          (result.payload &&
            result.payload.response.errors &&
            result.payload.response.errors.error === 'UNIT.PARTICIPATED_IN_OPEN_FINANCIAL_YEAR')
        ) {
          error('INSTITUTIONS.STUDENTS_REGISTER.DELETE_REGISTER.FAILURE_DRAFT');
        } else {
          success('INSTITUTIONS.BROWSER.REMOVE.SUCCESS');
        }
      },
      () => error('INSTITUTIONS.BROWSER.REMOVE.FAIL'),
    );
  };

  return (
    <Browser
      resultsLoading={!institutions.ready || institutions.loading}
      dictionariesLoading={!institutionsDictionary.ready || institutionsDictionary.loading}
      filters={filters}
      institutions={institutions.items}
      editItem={editItem}
      deleteItem={deleteItem}
    />
  );
};

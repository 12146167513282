import { BirthYearModel } from '@typings/common';

export interface ReportModalContainerProps {
  open: boolean;
  onClose: () => void;
  onSubmitSuccess: () => void;
}

export interface ReportModalProps extends ReportModalContainerProps {
  initialValues: CreateReportForm;
  birthYears: BirthYearModel[];
  onSave: (values: CreateReportForm) => Promise<any>;
  onReferenceDateUpdate?: (referenceDate: string) => void;
}

export interface CreateReportForm {
  referenceDate: string;
  birthYears: string[];
}

export const ALL_YEARS = 'ALL_YEARS';

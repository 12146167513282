import { AppDispatch } from '@typings/redux';
import { Browser } from 'components/templates/Browser/Browser';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTemplates, templatesListSelector } from 'store/templates';

export const BrowserContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(fetchTemplates());
  }, [dispatch]);

  const templates = useSelector(templatesListSelector);

  return <Browser isLoading={templates.loading} templates={templates.items} />;
};

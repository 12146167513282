import { SelectBox } from 'components/shared/form';
import { OptionType } from 'components/shared/form/Autocomplete/Autocomplete.types';
import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';

export interface SelectCellProps extends ICellRendererParams {
  name: string;
  placeholderName: string;
  itemComponent?: <T extends SelectBoxItem>(values: T) => React.ReactNode;
}

export type SelectBoxItem<T = any> = OptionType<T>;

export const SelectCell = (props: SelectCellProps) => {
  return (
    <div>
      <SelectBox
        name={`${props.name}.${props.data.name}`}
        required
        placeholderText={props.placeholderName}
        options={props.value}
        onChange={() => {}}
        onValueReset={() => {}}
        itemComponent={props.itemComponent}
      />
    </div>
  );
};

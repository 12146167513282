import { DataGridStyleProps } from 'components/shared/data/DataGrid/DataGrid.types';
import { colors } from 'styles/colors';
import { muiStyles } from 'utils/design';
import { important } from 'utils/design/important';

export const useStyles = muiStyles<DataGridStyleProps>((theme) => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    overflow: 'hidden',
    flexGrow: 1,

    '& > *': {
      flexGrow: ({ domLayout }) => (domLayout === 'print' ? 0 : 1),
      flexShrink: 1,
      width: ({ domLayout }) => (domLayout !== 'print' ? 0 : 'initial'),
      height: important('auto'),
    },
    '& .ag-root-wrapper': {
      border: `1px solid ${colors.gridBorder}`,
      borderRadius: '4px',
    },
    /**
     * There is a but in AG Grid that makes two No Rows overlays
     * visible at the same time. We fix that problem here with CSS.
     */

    '& .ag-set-filter-body-wrapper .ag-set-filter-select-all': {
      padding: '16px 0',
      margin: 0,

      '& .ag-set-filter-item-checkbox': {
        marginLeft: '12px',
      },
    },

    '& .ag-overlay-wrapper': {
      alignItems: 'flex-start',
      paddingTop: '150px',
    },
    '& .ag-status-bar': {
      padding: 0,
      borderWidth: ({ hasStatusPanel }) => (hasStatusPanel ? '1px 0 0' : '0'),
      '& .ag-name-value': {
        padding: theme.spacing(1),
        lineHeight: '26px',
      },
    },

    '& .ag-checkbox .ag-checked:after, .ag-header-cell .ag-header-select-all .ag-checked:after': {
      color: colors.primary,
    },

    '& .ag-center-cols-viewport': {
      overflowX: 'hidden',

      '& .ag-cell .ag-cell-wrapper': {
        justifyContent: 'flex-start',
      },

      '& .checkboxGrid .ag-selection-checkbox': {
        margin: 0,
      },

      '& .checkboxGrid .ag-cell-wrapper': {
        justifyContent: 'center',
      },

      '& .ag-row-group': {
        alignItems: 'center',
      },

      '& .ag-row-group-leaf-indent': {
        marginLeft: '42px',
      },
    },

    '& .ag-root-wrapper-body .ag-row-loading': {
      display: 'flex',
      justifyContent: 'center',
    },

    '& .ag-header-row': {
      visibility: ({ visible }) => (visible ? 'visible' : 'hidden'),
    },

    '& .ag-row': {
      visibility: ({ visible }) => (visible ? 'visible' : 'hidden'),
      '&.footer': {
        borderTopWidth: '2px',
        fontWeight: 'bolder',
        '& .ag-row-group-leaf-indent': {
          marginLeft: 0,
        },
      },
      '&.marked-row': {
        background: colors.neutralVeryLight,
        fontWeight: 'bolder',
        '.ag-theme-material &.ag-row-hover': {
          background: colors.neutralLight,
        },
      },
      '&.bolder-row': {
        fontWeight: 'bolder',
      },
    },
    '& .ag-header-cell, & .ag-header-group-cell': {
      padding: '13px 10px',
      borderRight: `1px solid ${theme.palette.grey['200']}`,

      '&:last-child': {
        borderRight: 'none',
      },

      '&:hover': {
        background: important('inherit'),
      },

      /* Fix double tooltips in Safari. See LIBRUS-353 */
      '& .ag-header-cell-text::after': {
        content: "''",
        display: 'block',
      },
    },

    '& .ag-center-cols-viewport .subventionDataSource .ag-react-container > div': {
      position: 'absolute',
      width: 'calc(100% - 20px)',
      margin: '-14px 0',
    },

    '& .ag-cell': {
      lineHeight: '1.6em',
      padding: '13px 10px',

      /* Fix double tooltips in Safari. See LIBRUS-353 */
      '&::after': {
        content: "''",
        display: 'block',
      },

      '& .ag-icon-checkbox-checked': {
        color: colors.primary,
      },

      '&.no-leaf-indent .ag-row-group-leaf-indent': {
        marginLeft: 0,
      },

      '&.form-cell': {
        paddingTop: '8px',
        paddingBottom: '8px',
        display: 'flex',
        justifyContent: 'center',

        '& .ag-cell-wrapper, .ag-cell-wrapper > span': {
          width: '100%',
        },

        '& .ag-cell-wrapper form > div': {
          alignItems: 'center',
        },
      },

      '&.dim-cell': {
        opacity: 0.6,
      },

      '&.calculations-result': {
        textAlign: 'right',
        fontSize: '1.1em',
        paddingRight: theme.spacing(2),

        '& .ag-cell-wrapper': {
          justifyContent: 'flex-end !important',
        },
      },
    },

    '& .ag-body-horizontal-scroll': {
      '& ::-webkit-scrollbar': {
        width: 8,
        height: 8,
      },
      '& ::-webkit-scrollbar-thumb': {
        backgroundColor: '#c2c2c2',
        borderRadius: 10,
        backgroundClip: 'contentBox',
      },
      '& ::-webkit-scrollbar-track': {
        backgroundColor: '#fafafa',
      },
      '& ::-webkit-scrollbar-track:horizontal': {
        borderTop: '1px solid #e8e8e8',
      },
      '& .ag-body-horizontal-scroll-container': {
        overflowX: 'scroll',
        scrollbarColor: '#c2c2c2 #fafafa',
        scrollbarWidth: 'thin',
      },
    },
    '& .ag-details-row': {
      padding: ({ detailsSpacing }) => `${detailsSpacing}px`,
      paddingLeft: theme.spacing(5),
      background: colors.gridDetailsBackground,
    },

    '& .ag-icon-checkbox-checked': {
      color: colors.primary,
    },
  },
  overlay: {
    transition: 'opacity 0.5s',
    opacity: 0,
  },
  overlayVisible: {
    opacity: 100,
  },
}));

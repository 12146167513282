import {
  onDetailsSelectionChanged,
  onMasterSelectionChange,
} from 'components/shared/data/DataGrid/handlers/selectionChanged';
import { usePagination } from 'components/shared/data/DataGrid/hooks/usePagination';
import { headerRenderers } from 'components/shared/data/DataGrid/renderers/header';
import { statusPanels } from 'components/shared/data/DataGrid/StatusPanel';
import { getHeaderMaxLevel } from 'components/shared/data/DataGrid/utils/getHeaderMaxLevel';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { AgGridReact } from 'ag-grid-react';
import { useSelector } from 'react-redux';
import { routerSelector } from 'store/router/router.selectors';
import { AgGridReactFixedProp, DataGridProps } from './DataGrid.types';
import { GridReadyEvent, GridApi, ColumnApi } from 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import 'ag-grid-enterprise';
import { useSidebarOpenEffect } from 'components/layout/panel/Sidebar/useSidebarOpenEffect';
import { useResolutionChange, useTranslator, useUnmountableCallback } from 'components/shared/hooks';
import { useFocusCell, useGridState, useColumnConfigurator, useGridComposer } from './hooks';
import { useStyles } from './DataGrid.styles';
import { LoadingOverlay, NoRowsOverlay } from './Overlay';
import { Tooltip } from './Tooltip/Tooltip';
import { getGridSize, getTabKeyHandler } from './utils';
import { cellRenderers } from 'components/shared/data/DataGrid/renderers/cell';
import { useGridEvent } from 'components/shared/data/DataGrid/hooks';
import { Events } from 'ag-grid-community';
import { fillGridBlankSpace } from 'components/shared/data/DataGrid/utils/columnSizeHelpers';
import { isUndefined } from 'lodash';

export enum GridSize {
  detailsGridSpacing = 10,
  headerHeight = 58,
  rowHeight = 48,
}

// eslint-disable-next-line complexity
export const DataGrid = <T extends object, D extends object = never>(props: DataGridProps<T, D>): ReactElement => {
  const { onGridReady, onFocusBeforeGrid, onFocusAfterGrid, disableAutoSize = false, domLayout = 'normal' } = props;
  const trans = useTranslator();

  const mounted = useRef(false);
  const [gridApi, setGridApi] = useState(null);
  const [columnApi, setColumnApi] = useState(null);
  const { key: locationKey } = useSelector(routerSelector);
  const { composeGrid, gridVisible } = useGridComposer(gridApi, columnApi, disableAutoSize);

  const classes = useStyles({
    visible: gridVisible,
    hasStatusPanel: !!props.statusPanels,
    detailsSpacing: GridSize.detailsGridSpacing,
    domLayout: domLayout,
  });

  const { multiColumnConfigurator, singleColumnConfigurator } = useColumnConfigurator(props.defaultColumn);

  const frameworkComponents = {
    gridTooltip: Tooltip,
    loadingOverlay: LoadingOverlay,
    noRowsOverlay: NoRowsOverlay,
    ...cellRenderers,
    ...headerRenderers,
    ...statusPanels,
  };

  useResolutionChange(composeGrid);
  useSidebarOpenEffect(composeGrid);
  useGridState(gridApi, props.isLoading, gridVisible);
  const { resetPagination } = usePagination(
    gridApi,
    props.onDataFetch,
    props.onDataProvide,
    {
      limit: props.limit,
      total: props.total,
    },
    props.fetchedItemsCount,
  );

  const gridSize = getGridSize(gridApi);
  const tabKeyHandler = getTabKeyHandler(gridSize, onFocusBeforeGrid, onFocusAfterGrid);
  const focusCell = useFocusCell(gridApi);

  const gridReadyHandler = useUnmountableCallback((event: GridReadyEvent) => {
    if (mounted.current) {
      setGridApi(event.api);
      setColumnApi(event.columnApi);
    }
  }, []);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (onGridReady && gridApi) {
      composeGrid();
      onGridReady({
        focusCell,
        agGridApi: gridApi,
        fitCellsToContent: composeGrid,
        agColumnApi: columnApi,
        resetPagination,
      });
    }
  }, [composeGrid, gridApi, focusCell, onGridReady, locationKey, props.columns.length, columnApi, resetPagination]);

  useGridEvent(gridApi, Events.EVENT_DRAG_STOPPED, ({ api, columnApi }: { api: GridApi; columnApi: ColumnApi }) => {
    fillGridBlankSpace(api, columnApi);
  });

  const gridCommonOptions: AgGridReactFixedProp = {
    reactNext: true,
    enableCellTextSelection: true,
    suppressContextMenu: !props.contextMenu,
    groupDefaultExpanded: undefined,
    rowClass: [props.rowClassName],
    getRowClass: props.getRowClass,
    rowClassRules: props.rowClassRules,
    rowSelection: props.rowSelection || undefined,
    rowMultiSelectWithClick: true,
    suppressRowClickSelection: true,
    groupMultiAutoColumn: props.groupMultiAutoColumn || false,
    groupSuppressAutoColumn: props.groupSuppressAutoColumn,
    frameworkComponents: frameworkComponents,
    loadingCellRenderer: 'loadingRecords',
    loadingOverlayComponent: 'loadingOverlay',
    noRowsOverlayComponent: 'noRowsOverlay',
    keepDetailRows: true,
    keepDetailRowsCount: Infinity,
    tabToNextCell: tabKeyHandler,
    getRowHeight: props.getRowHeight
      ? props.getRowHeight
      : (params) => {
          if (params.node.detail && !props.masterDetail) {
            const headerLevels = getHeaderMaxLevel(props.detailsColumns);
            const rowsCount = props.detailsMapping(params.data).length;

            return (
              headerLevels * GridSize.headerHeight + 2 * GridSize.detailsGridSpacing + rowsCount * GridSize.rowHeight
            );
          } else if (params.node.detail && props.masterDetail) {
            return props.detailRowHeight;
          } else {
            return GridSize.rowHeight;
          }
        },
    loadingOverlayComponentParams: {
      loadingMessage: trans(props.loadingMessage || 'COMMON.LOADING_DATA'),
    },
    noRowsOverlayComponentParams: {
      noResultsMessage: trans(props.noResultsMessage || 'COMMON.NOTHING-FOUND'),
    },
    headerHeight: props.headerHeight,
    scrollbarWidth: 8,
    localeText: {
      selectAll: trans('COMMON.GRID.SELECT_ALL'),
      searchOoo: trans('COMMON.GRID.SEARCH'),
      blanks: trans('COMMON.GRID.BLANK'),
    },
    getRowNodeId: props.getRowNodeId,
  };

  const getGroupDefaultExpanded = () => {
    if (props.onDataProvide) return undefined;
    return isUndefined(props.defaultExpand) ? -1 : props.defaultExpand;
  };

  return (
    <div
      className={classNames('ag-theme-material', classes.container, props.className)}
      data-testid="dataGrid-container"
    >
      <AgGridReact
        {...gridCommonOptions}
        tooltipShowDelay={500}
        onGridReady={gridReadyHandler}
        onFirstDataRendered={props.onFirstDataRendered}
        treeData={!!props.treeHierarchy}
        getDataPath={props.treeHierarchy}
        columnDefs={multiColumnConfigurator(props.columns)}
        autoGroupColumnDef={singleColumnConfigurator(props.groupingColumn)}
        groupDefaultExpanded={getGroupDefaultExpanded()}
        suppressScrollOnNewData={props.suppressScrollOnNewData}
        suppressColumnVirtualisation={props.suppressColumnVirtualisation}
        statusBar={{ statusPanels: props.statusPanels || [] }}
        rowData={props.data}
        pinnedBottomRowData={props.pinnedBottomData}
        rowModelType={props.onDataProvide ? 'serverSide' : 'clientSide'}
        masterDetail={!!props.detailsColumns || props.masterDetail}
        isRowMaster={props.isRowMaster}
        cacheBlockSize={props.limit || 100}
        isRowSelectable={props.isRowSelectable ? (node) => props.isRowSelectable(node.data, node) : undefined}
        onSelectionChanged={onMasterSelectionChange(props)}
        detailCellRenderer={props.detailCellRenderer}
        detailRowHeight={props.detailRowHeight}
        detailCellRendererParams={
          props.detailsColumns && {
            detailGridOptions: {
              ...gridCommonOptions,
              columnDefs: multiColumnConfigurator(props.detailsColumns),
              onFirstDataRendered: (params) => {
                params.api.sizeColumnsToFit();
              },
              onSelectionChanged: onDetailsSelectionChanged(props, gridApi),
              isRowSelectable: props.isDetailsRowSelectable
                ? (node) => props.isDetailsRowSelectable(node.data, node)
                : undefined,
            },
            getDetailRowData: (params) => {
              params.successCallback(props.detailsMapping(params.data));
            },
          }
        }
        suppressAggFuncInHeader={props.suppressAggFuncInHeader}
        context={{
          ...props.context,
          detailsMapping: props.detailsMapping,
        }}
      />
    </div>
  );
};

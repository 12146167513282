import { useStyles } from 'components/shared/form/RadioBox/RadioList.styles';
import React, { Fragment } from 'react';
import { Field, FieldProps } from 'formik';
import { FormControlLabel, FormControl, FormHelperText, Radio, Tooltip } from '@material-ui/core';
import { RadioListProps } from './RadioBox.types';
import { get } from 'lodash';
import classNames from 'classnames';

export const RadioList = ({
  name,
  validate,
  options,
  hasIcon,
  icon,
  tooltipText,
  containerClassName,
  singleRow = false,
  ...rest
}: RadioListProps) => {
  const classes = useStyles({ singleRow });

  return (
    <Field name={name} validate={validate}>
      {({ field, form }: FieldProps) => {
        const isError = !!(get(form.errors, field.name) && get(form.touched, field.name));

        const getLabel = (labelText: string) => {
          if (hasIcon) {
            return (
              <Fragment>
                {labelText}
                <Tooltip title={tooltipText} placement="right" className={classes.tooltipIcon}>
                  {icon}
                </Tooltip>
              </Fragment>
            );
          } else {
            return labelText;
          }
        };

        return (
          <FormControl
            error={isError}
            className={classNames({ hasValidationError: isError }, classes.list, containerClassName)}
          >
            {options.map((option, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Radio
                    onChange={form.handleChange}
                    onBlur={field.onBlur}
                    name={field.name}
                    data-testid={`${name}_${option.value}`}
                    checked={field.value === option.value}
                    value={option.value}
                    color="default"
                    {...rest}
                  />
                }
                label={getLabel(option.label)}
              />
            ))}
            {isError && <FormHelperText>{get(form.errors, field.name)}</FormHelperText>}
          </FormControl>
        );
      }}
    </Field>
  );
};

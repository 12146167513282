import { ActionHandlers, TemplatesBrowserRow } from 'components/templates/Browser/Browser.types';
import { ColumnsConfig } from 'components/shared/data/DataGrid/DataGrid.types';
import { translateTemplateField } from 'utils/i18n/templateFields';

export const templatesBrowserColumns: ColumnsConfig<TemplatesBrowserRow, ActionHandlers> = (handlers) => [
  {
    field: 'name',
    name: 'TEMPLATES.BROWSER.COLUMNS.NAME',
    columnParams: { minWidth: 300 },
  },
  {
    field: 'target',
    name: 'TEMPLATES.BROWSER.COLUMNS.TARGET',
    format: (data, trans) => {
      return trans(`TEMPLATES.BROWSER.VALUES.TARGET.${data.target.toUpperCase()}`);
    },
  },
  {
    field: 'fields',
    name: 'TEMPLATES.BROWSER.COLUMNS.FIELDS',
    format: ({ fields }, trans) => {
      return fields.map((field) => translateTemplateField(field, trans)).join(', ');
    },
  },
  {
    field: 'url',
    name: 'TEMPLATES.BROWSER.COLUMNS.ACTIONS',
    renderer: 'buttonsCell',
    rendererParams: {
      buttons: () => {
        return [
          {
            type: 'default',
            size: 'small',
            variant: 'outlined',
            icon: 'eye',
            label: 'COMMON.DISPLAY',
            tooltip: 'COMMON.DISPLAY',
            action: (data: TemplatesBrowserRow) => handlers.preview(data.id),
          },
        ];
      },
    },
  },
];

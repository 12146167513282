import { useTimeout } from 'components/shared/hooks';
import { useRef } from 'react';

export const useGridState = (gridApi: any, isLoading: boolean, isVisible: boolean) => {
  const cancelOverlay = useRef(() => {});

  if (gridApi) {
    gridApi.hideOverlay();
    cancelOverlay.current();
  }

  cancelOverlay.current = useTimeout(
    () => {
      if (!gridApi) return;

      const rowsCount = gridApi.getDisplayedRowCount();

      gridApi.dispatchEvent({
        type: isLoading || !isVisible ? 'gridLoading' : 'gridNotLoading',
      });

      if (isLoading || !isVisible) {
        gridApi.showLoadingOverlay();
      } else if (rowsCount === 0) {
        gridApi.showNoRowsOverlay();
      } else {
        gridApi.hideOverlay();
      }
    },
    50,
    [isLoading, isVisible],
  );
};

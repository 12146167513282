import { ProgressModal } from 'components/shared/dialogs/ProgressModal/ProgressModal';
import { useTranslator } from 'components/shared/hooks/useTranslator/useTranslator';
import React, { FC, useEffect, useRef, useState } from 'react';
import { ImportStatusProps } from 'components/childRegister/Import/ImportStatus/ImportStatus.types';
import { ImportStatusTypes, importTokenSelector } from 'store/childRegister';
import { useDispatch, useSelector } from 'react-redux';
import { fetchImportStatus } from 'store/childRegister/childRegister.actions';

export const ImportStatus: FC<ImportStatusProps> = (props) => {
  const dispatch: (...params: any) => Promise<any> = useDispatch();
  const importToken = useSelector(importTokenSelector);
  const [importStatus, setImportStatus] = useState(ImportStatusTypes.PROCESSING);
  const [importProgress, setImportProgress] = useState(0);
  const trans = useTranslator();
  const [isError, setError] = useState(false);

  const importRef = useRef(importStatus);
  importRef.current = importStatus;

  const importStatusMessages = {
    [ImportStatusTypes.DRAFT]: 'CHILD_REGISTER.IMPORT_STATUS.PROCESSING',
    [ImportStatusTypes.MAP_READY]: 'CHILD_REGISTER.IMPORT_STATUS.PROCESSING',
    [ImportStatusTypes.PROCESSING]: 'CHILD_REGISTER.IMPORT_STATUS.PROCESSING',
    [ImportStatusTypes.SUCCESS]: 'CHILD_REGISTER.IMPORT_STATUS.SUCCESS',
    [ImportStatusTypes.PREVIEW_READY]: 'CHILD_REGISTER.IMPORT_STATUS.SUCCESS',
    [ImportStatusTypes.ERROR]: 'CHILD_REGISTER.IMPORT_STATUS.ERROR',
  };

  const getMessage = () => {
    return isError ? importStatusMessages[ImportStatusTypes.ERROR] : importStatusMessages[importStatus];
  };

  const isProcessing = (importStatus) => {
    return (
      importStatus === ImportStatusTypes.PROCESSING ||
      importStatus === ImportStatusTypes.MAP_READY ||
      importStatus === ImportStatusTypes.DRAFT
    );
  };

  useEffect(() => {
    let timerId = null;

    async function ping() {
      if (isProcessing(importRef.current)) {
        const result = await dispatch(fetchImportStatus(importToken));

        if (result.error) {
          setError(true);
        } else {
          setImportStatus(result.payload.status);
          setImportProgress(result.payload.progress);
          timerId = setTimeout(ping, 1000);
        }
      }
    }

    setError(false);
    ping();

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProgressModal
      title={trans('CHILD_REGISTER.IMPORT_STATUS.TITLE')}
      open={props.open}
      description={trans(getMessage())}
      progress={importProgress}
      error={isError}
      button={{
        action: props.confirm,
        label: trans('COMMON.CLOSE'),
        disabled: isProcessing(importStatus) && !isError,
      }}
    />
  );
};

import { format, subDays } from 'date-fns';
import { PageCollection } from '@typings/common';
import {
  AuditLog,
  OtherCommuneStat,
  OtherCommuneStudentsStat,
  SchoolStat,
  StatDetails,
  SubventionFactors,
  SynchronizationLog,
  SynchronizationLogFilters,
  SynchronizationLogLevel,
} from 'store/reports/reports.types';

export interface ReportsState {
  auditLog: {
    list: PageCollection<AuditLog>;
    total: number;
    loading: boolean;
    error: any;
  };
  schoolStat: {
    list: SchoolStat[];
    loading: boolean;
    error: any;
    details: StatDetails | null;
  };
  otherCommune: {
    list: OtherCommuneStat[];
    loading: boolean;
    error: any;
    students: {
      list: OtherCommuneStudentsStat[];
      loading: boolean;
      error: any;
    };
  };
  subventionFactors: {
    factors: SubventionFactors;
    year: number;
    loading: boolean;
    error: boolean;
  };
  syncLog: {
    list: PageCollection<SynchronizationLog>;
    loading: boolean;
    error: any;
    filters: SynchronizationLogFilters;
    lastFetchedItem?: SynchronizationLog;
    total: number;
  };
}

export const defaultState: ReportsState = {
  auditLog: {
    list: [],
    total: null,
    loading: false,
    error: null,
  },
  schoolStat: {
    list: [],
    loading: false,
    error: null,
    details: null,
  },
  otherCommune: {
    list: [],
    loading: false,
    error: null,
    students: {
      list: [],
      error: null,
      loading: false,
    },
  },
  subventionFactors: {
    factors: null,
    year: null,
    loading: false,
    error: false,
  },
  syncLog: {
    list: [],
    loading: false,
    error: null,
    filters: {
      level: SynchronizationLogLevel.WARNING,
      from: format(subDays(new Date(), 1), 'yyyy-MM-dd'),
      to: format(new Date(), 'yyyy-MM-dd'),
    },
    lastFetchedItem: undefined,
    total: null,
  },
};

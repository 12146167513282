import { Translator } from 'context/locale/LocaleContext/LocaleContext.types';
import { SchoolAddressModel } from 'store/schoolDistricts';
import { generateAddressRuleDescription } from 'utils/addressRule/addressRule';

export const generateConflictDescription = (address: SchoolAddressModel, trans: Translator): string[] => {
  return address.alerts
    .find((alert) => alert.name === 'CATCHMENT_SCHOOLS.ADDRESS_RULES_CONFLICT')
    .context.map((alert) => {
      return trans('SCHOOL_DISTRICTS.DISTRICTS-LIST.ADDRESS-LIST.ACTIONS.CONFLICT_DETAILS', {
        school: alert.schoolName,
        street: address.streetName,
        city: address.cityName,
        rule: generateAddressRuleDescription(alert, trans).toLowerCase(),
      });
    });
};

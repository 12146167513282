import { muiStyles } from 'utils/design';

interface TreeSelectBoxStylesProps {
  markLevelZero: boolean;
}

export const useStyles = muiStyles<TreeSelectBoxStylesProps>((theme) => ({
  item: {
    display: 'flex',
    '&.level-0': {
      fontWeight: ({ markLevelZero }) => (markLevelZero ? 'bolder' : 'normal'),
    },
    '&.level-1 ': {
      marginLeft: theme.spacing(3),
    },
    '&.level-2 ': {
      marginLeft: theme.spacing(6),
    },
    '&.level-3 ': {
      marginLeft: theme.spacing(9),
    },
  },
  itemWrapper: {
    '&.Mui-selected': {
      background: 'none',
    },
  },
  checkbox: {
    color: theme.palette.primary.dark,
    marginRight: theme.spacing(1),
    lineHeight: '0.8em',
  },
}));

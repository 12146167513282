import React, { FC } from 'react';
import { SchoolStat as SchoolStatModel } from 'store/reports/reports.types';
import { limits } from 'config/pagination';
import { DataGrid } from 'components/shared/data/DataGrid/DataGrid';
import { SchoolStatHandlers, SchoolStatProps } from 'components/reports/SchoolStat/SchoolStat.types';
import { schoolStatColumns } from 'components/reports/SchoolStat/columns';
import { push } from 'connected-react-router';
import { makePath } from 'utils/navigation/makePath';
import { ROUTE_STATISTIC_DETAILS } from 'config/routes';
import { useDispatch } from 'react-redux';

export const SchoolStat: FC<SchoolStatProps> = ({ schoolStats, isLoading }) => {
  const dispatch = useDispatch();

  const actionHandlers: SchoolStatHandlers = {
    show: (id) => {
      dispatch(push(makePath(ROUTE_STATISTIC_DETAILS, { id })));
    },
  };

  return (
    <DataGrid<SchoolStatModel>
      columns={schoolStatColumns(actionHandlers)}
      data={schoolStats}
      limit={limits.auditLog}
      isLoading={isLoading}
    />
  );
};

import { CountSelectedInDetails } from 'components/shared/data/DataGrid/StatusPanel/CountSelected/CountSelectedInDetails';
import { CountSelected } from './CountSelected/CountSelected';
import { Count } from './Count/Count';
import { DeleteSelected } from './DeleteSelected/DeleteSelected';

export const statusPanels = {
  count: Count,
  deleteSelected: DeleteSelected,
  countSelected: CountSelected,
  countSelectedInDetails: CountSelectedInDetails,
};

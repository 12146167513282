import { muiStyles } from 'utils/design';

export const useStyles = muiStyles((theme) => ({
  question: {
    marginBottom: theme.spacing(2),
  },
  panel: {
    flexDirection: 'column',
    paddingBottom: '8px !important',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

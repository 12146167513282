import { muiStyles } from 'utils/design';

export const useStyles = muiStyles((theme) => ({
  content: {
    overflowX: 'visible',
    flex: 1,
  },
  actions: {
    justifyContent: 'space-between',
    order: 10,
  },
  stepper: {
    order: 9,
    overflowY: 'unset',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

import React from 'react';
import { FlyoutProps } from 'victory';

export const MaterialFlyout = (props: FlyoutProps) => {
  const { x, y, center } = props;

  const width = props.width;
  const height = props.height;

  const yOffset = 0;
  const radius = 4;

  const startX = center.x - width / 2;
  const startY = center.y - height / 2 + yOffset;

  return (
    <>
      <defs>
        <filter id="shadow" x="-40%" y="-40%" width="180%" height="180%">
          <feDropShadow dx="0" dy="2" stdDeviation="1.5" floodColor="#000" floodOpacity={0.2} />
          <feDropShadow dx="0" dy="4" stdDeviation="2.5" floodColor="#000" floodOpacity={0.14} />
          <feDropShadow dx="0" dy="1" stdDeviation="5" floodColor="#000" floodOpacity={0.12} />
        </filter>
      </defs>
      <g>
        <path
          dx={x}
          dy={y}
          d={`
            M ${startX + radius} ${startY} 
            
            L ${startX + width - radius} ${startY}
            A ${radius} ${radius} 0 0 1 ${startX + width} ${startY + radius}
            
            L ${startX + width} ${startY + height - radius} 
            A ${radius} ${radius} 0 0 1 ${startX + width - radius} ${startY + height}
            
            L ${startX + radius} ${startY + height} 
            A ${radius} ${radius} 0 0 1 ${startX} ${startY + height - radius}
  
            L ${startX} ${startY + radius}
            A ${radius} ${radius} 0 0 1 ${startX + radius} ${startY}
          `}
          fill="#ffffff"
          strokeWidth="0"
          stroke="#000"
          style={{ filter: 'url(#shadow)' }}
        />
      </g>
    </>
  );
};

import { ROUTE_SCHOOL_DISTRICTS_DATABASE } from 'config/routes';
import { push } from 'connected-react-router';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@typings/redux';

import { DistrictsBrowser } from './DistrictsBrowser';
import { DistrictsBrowserContainerProps } from './DistrictsBrowserContainer.types';

import {
  fetchSchoolDistrictsDatabases,
  getDatabaseListSelector,
  getDistrictsSchoolListSelector,
  getDistrictsAddressesListSelector,
  SchoolModel,
  fetchSchoolList,
  fetchAddressList,
  isDatabaseListLoadingSelector,
  isSchoolListLoadingSelector,
  isAddressListLoadingSelector,
  getCurrentDatabaseIdSelector,
  clearSchoolListFilters,
  getDistrictsSchoolFiltersSelector,
  clearAddressList,
  AddressRuleFormModel,
  addAddressRule,
  editAddressRule,
  deleteAddressRule,
  setSchoolListFilters,
} from 'store/schoolDistricts';

export const DistrictsBrowserContainer: FC<DistrictsBrowserContainerProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();
  const [selectedSchool, setSelectedSchool] = useState<SchoolModel>(null);
  const [selectedDatabaseId, setSelectedDatabaseId] = useState(props.initialDatabaseId);

  const databasesList = useSelector(getDatabaseListSelector);
  const currentDatabaseId = useSelector(getCurrentDatabaseIdSelector);
  const filters = useSelector(getDistrictsSchoolFiltersSelector);

  const schoolsList = useSelector(getDistrictsSchoolListSelector);
  const addressesList = useSelector(getDistrictsAddressesListSelector);
  const databasesLoading = useSelector(isDatabaseListLoadingSelector);
  const schoolsLoading = useSelector(isSchoolListLoadingSelector);
  const addressesLoading = useSelector(isAddressListLoadingSelector);

  const databaseIdExists = useCallback(
    (databaseId: string): boolean => {
      return databasesList.some((database) => database.id === databaseId);
    },
    [databasesList],
  );

  useEffect(() => {
    dispatch(clearSchoolListFilters());
    dispatch(fetchSchoolDistrictsDatabases());
  }, [dispatch]);

  useEffect(() => {
    if (selectedDatabaseId && databaseIdExists(selectedDatabaseId)) {
      return;
    } else if (currentDatabaseId) {
      setSelectedDatabaseId(currentDatabaseId);
    } else if (databasesList.length) {
      setSelectedDatabaseId(databasesList[0].id);
    }
  }, [currentDatabaseId, databaseIdExists, databasesList, selectedDatabaseId]);

  useEffect(() => {
    if (selectedDatabaseId) {
      setSelectedSchool(null);
      dispatch(clearAddressList());
      dispatch(fetchSchoolList(selectedDatabaseId));
    }
  }, [dispatch, selectedDatabaseId]);

  useEffect(() => {
    if (selectedSchool) {
      dispatch(fetchAddressList(selectedDatabaseId, selectedSchool.id));
    }
  }, [dispatch, selectedDatabaseId, selectedSchool]);

  useEffect(() => {
    setSelectedSchool(null);
    dispatch(clearAddressList());
  }, [dispatch, filters]);

  useEffect(() => {
    if (filters.alert !== null && schoolsList.length > 0 && schoolsList.every((school) => school.alerts.length === 0)) {
      dispatch(setSchoolListFilters({ ...filters, alert: null }));
    }
  }, [dispatch, filters, schoolsList]);

  const handleSelectDatabase = (databaseId: string) => {
    setSelectedDatabaseId(databaseId);
  };
  const handleAddItem = (values: AddressRuleFormModel) => {
    return dispatch(addAddressRule(selectedDatabaseId, selectedSchool.id, values));
  };
  const handleEditItem = (id: string, values: AddressRuleFormModel) => {
    return dispatch(editAddressRule(selectedDatabaseId, selectedSchool.id, id, values));
  };
  const handleSubmitSuccess = () => {
    dispatch(fetchAddressList(selectedDatabaseId, selectedSchool.id));
    dispatch(fetchSchoolList(selectedDatabaseId));
  };
  const handleDeleteItem = (id: string) => {
    return dispatch(deleteAddressRule(selectedDatabaseId, selectedSchool.id, id)).then(() => {
      dispatch(fetchAddressList(selectedDatabaseId, selectedSchool.id));
      dispatch(fetchSchoolList(selectedDatabaseId));
    });
  };
  const handleSelectSchool = (school: SchoolModel) => setSelectedSchool(school);

  const handleAddDatabase = () => {
    return dispatch(push(ROUTE_SCHOOL_DISTRICTS_DATABASE, { action: 'add' }));
  };

  const getSelectedDatabase = useMemo(() => {
    return databasesList ? databasesList.find((database) => database.id === selectedDatabaseId) : null;
  }, [databasesList, selectedDatabaseId]);

  return (
    <DistrictsBrowser
      addressesLoading={addressesLoading}
      databasesLoading={databasesLoading}
      schoolsLoading={schoolsLoading}
      selectedDatabaseId={selectedDatabaseId}
      databasesList={databasesList}
      school={selectedSchool}
      schoolsList={schoolsList}
      addressesList={addressesList}
      onSelectDatabase={handleSelectDatabase}
      onSelectSchool={handleSelectSchool}
      onAddItem={handleAddItem}
      onEditItem={handleEditItem}
      onSubmitSuccess={handleSubmitSuccess}
      onDeleteItem={handleDeleteItem}
      onAddDatabase={handleAddDatabase}
      selectedDatabase={getSelectedDatabase}
    />
  );
};

import React, { FC } from 'react';
import { useStyles } from 'components/schoolObligation/ReportDetails/ReportDetails.styles';
import { ReportsDetailsProps } from 'components/schoolObligation/ReportDetails/ReportsDetailsContainer.types';
import { Button } from 'components/shared/buttons/Button/Button';
import { Summary, SummaryItem } from 'components/shared/data/Summary/Summary';
import { useFormatter, useTranslator } from 'components/shared/hooks';
import { TextLoader } from 'components/shared/loaders/TextLoader/TextLoader';
import { RoutedTabPanel } from 'components/shared/navigation/RoutedTabPanel/RoutedTabPanel';
import { TabConfig } from 'components/shared/navigation/RoutedTabPanel/RoutedTabPanel.types';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import {
  ROUTE_SCHOOL_OBLIGATION_REPORT_DETAILS_CHILDREN_LIST,
  ROUTE_SCHOOL_OBLIGATION_REPORT_DETAILS_OVERVIEW,
} from 'config/routes';
import { ReportStatus } from 'store/schoolObligations';

export const ReportDetails: FC<ReportsDetailsProps> = ({ loading, report, children, onSave, onBack }) => {
  const trans = useTranslator();
  const { formatDate, formatDateWithTime } = useFormatter();
  const classes = useStyles();

  const getTabsConfig = (): TabConfig[] => [
    {
      title: trans('SCHOOL_OBLIGATION.REPORT_OVERVIEW.TITLE'),
      route: ROUTE_SCHOOL_OBLIGATION_REPORT_DETAILS_OVERVIEW,
      routeParams: { id: report.id },
    },
    {
      title: trans('SCHOOL_OBLIGATION.REPORT_CHILDREN_LIST.TITLE'),
      route: ROUTE_SCHOOL_OBLIGATION_REPORT_DETAILS_CHILDREN_LIST,
      routeParams: { id: report.id },
    },
  ];

  if (loading || report === null) {
    return <TextLoader text={trans('SCHOOL_OBLIGATION.REPORT.LOADING')} name={'spinner'} />;
  }

  return (
    <>
      <Summary>
        <SummaryItem title={trans('SCHOOL_OBLIGATION.REPORT.SUMMARY.REFERENCE_DATE')}>
          {formatDate(report.referenceDate)} ({report.schoolYear})
        </SummaryItem>
        <SummaryItem title={trans('SCHOOL_OBLIGATION.REPORT.SUMMARY.BIRTH_YEARS')}>
          {report.birthYears.join(', ')}
        </SummaryItem>
        <SummaryItem title={trans('SCHOOL_OBLIGATION.REPORT.SUMMARY.CREATION_DATE')}>
          {formatDateWithTime(report.createdAt)}
        </SummaryItem>
      </Summary>
      <RoutedTabPanel tabs={getTabsConfig()} />
      <Paper innerSpacing={2} square className={classes.grid}>
        {children}
      </Paper>
      <div className="row mt-4 mb-2">
        <div className="col">
          <Button onClick={onBack} color="secondary" variant="outlined">
            {trans('COMMON.BACK')}
          </Button>
          {report.status === ReportStatus.READY && (
            <Button onClick={onSave} color="secondary" variant="contained" className="ml-3">
              {trans('COMMON.SAVE')}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

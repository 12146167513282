import { Page } from 'components/layout/panel/Page/Page';
import { BrowserContainer } from 'components/polls/Browser/BrowserContainer';
import { useTranslator } from 'components/shared/hooks';
import { Breadcrumb } from 'components/shared/navigation/Breadcrumbs/Breadcrumb';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import React, { FC, Fragment } from 'react';
import { useStyles } from './PollsList.styles';

export const PollsListView: FC = () => {
  const trans = useTranslator();
  const classes = useStyles();

  return (
    <Fragment>
      <Breadcrumb label={trans('BREADCRUMB.POLLS.POLLS_LIST')} static />

      <Page title={trans('POLLS.BROWSER.TITLE')}>
        <Paper innerSpacing={2} outerSpacing={[0, 2, 2]} className={classes.paper}>
          <BrowserContainer />
        </Paper>
      </Page>
    </Fragment>
  );
};

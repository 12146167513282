import { ColumnsConfig } from 'components/shared/data/DataGrid/DataGrid.types';
import { Search } from 'store/search/search.types';

export const searchColumns: ColumnsConfig<Search> = () => [
  {
    field: 'firstName',
    renderer: 'agGroupCellRenderer',
    name: 'SEARCH.COLUMNS.FIRST_NAME',
  },
  {
    field: 'lastName',
    name: 'SEARCH.COLUMNS.LAST_NAME',
  },
  {
    field: 'identificationNumber',
    name: 'SEARCH.COLUMNS.IDENTIFICATION_NUMBER',
  },
];

import { Point } from 'components/shared/charts/charts.types';
import { MaterialTooltip, MaterialTooltipProps } from 'components/shared/charts/MaterialTooltip/MaterialTooltip';
import React from 'react';
import { VictoryLabel, VictoryLabelProps, VictoryTooltip } from 'victory';

interface PieChartDoubleLabelProps extends VictoryLabelProps {
  datum?: Point;
}

const PieChartDoubleLabelComponent = (props: PieChartDoubleLabelProps) => {
  const label = `${props.datum.y}% (${props.datum.count})`;

  return (
    <g>
      <VictoryLabel {...props} text={label} />
      <MaterialTooltip {...(props as unknown as MaterialTooltipProps)} />
    </g>
  );
};

PieChartDoubleLabelComponent.defaultEvents = VictoryTooltip['defaultEvents'];

export const PieChartDoubleLabel = PieChartDoubleLabelComponent;

import { SplashProps } from 'components/shared/surfaces/Splash/Splash.types';
import { muiStyles } from 'utils/design';

export const useStyles = muiStyles<SplashProps>({
  splash: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: ({ height }) => height,
    width: '100%',
  },
});

export enum ApplicantsActionType {
  POST_APPLICANT_REQUEST = 'applicants/POST_APPLICANT_REQUEST',
  POST_APPLICANT_SUCCESS = 'applicants/POST_APPLICANT_SUCCESS',
  POST_APPLICANT_FAILURE = 'applicants/POST_APPLICANT_FAILURE',

  FETCH_APPLICANT_REQUEST = 'applicants/FETCH_APPLICANT_REQUEST',
  FETCH_APPLICANT_SUCCESS = 'applicants/FETCH_APPLICANT_SUCCESS',
  FETCH_APLICANT_FAILURE = 'applicants/FETCH_APPLICANT_FAILURE',

  UPDATE_APPLICANT_REQUEST = 'applicants/UPDATE_APPLICANT_REQUEST',
  UPDATE_APPLICANT_SUCCESS = 'applicants/UPDATE_APPLICANT_SUCCESS',
  UPDATE_APPLICANT_FAILURE = 'applicants/UPDATE_APPLICANT_FAILURE',

  DELETE_APPLICANT_REQUEST = 'applicants/DELETE_APPLICANT_REQUEST',
  DELETE_APPLICANT_SUCCESS = 'applicants/DELETE_APPLICANT_SUCCESS',
  DELETE_APPLICANT_FAILURE = 'applicants/DELETE_APPLICANT_FAILURE',

  RESET_PASSWORD_REQUEST = 'applicants/RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS = 'applicants/RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE = 'applicants/RESET_PASSWORD_FAILURE',
}

import { AppDispatch } from '@typings/redux';
import { Confirm } from 'components/shared/dialogs/Confirm/Confirm';
import { ProgressModal } from 'components/shared/dialogs/ProgressModal/ProgressModal';
import { useTranslator } from 'components/shared/hooks';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { transferChildrenList } from 'store/schoolDistricts';

interface TransferModalProps {
  open: boolean;
  assignmentId: string;
  schoolIds: string[];
  onTransferEnd: () => void;
  onClose: () => void;
}

enum TransferModalState {
  CLOSED = 'closed',
  CONFIRM = 'confirm',
  WORKING = 'working',
  DONE = 'done',
  ERROR = 'error',
}

export const TransferModal = (props: TransferModalProps): ReactElement => {
  const trans = useTranslator();
  const dispatch = useDispatch<AppDispatch>();
  const [modalState, setModalState] = useState<TransferModalState>(TransferModalState.CLOSED);
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    if (props.open) {
      setModalState(TransferModalState.CONFIRM);
    } else {
      setTimeout(() => setModalState(TransferModalState.CLOSED), 300);
    }
  }, [props.open]);

  const startTransfer = async () => {
    setModalState(TransferModalState.WORKING);

    for (const [index, schoolId] of props.schoolIds.entries()) {
      const result = await dispatch(transferChildrenList(props.assignmentId, schoolId, false));

      if (result.error) {
        setModalState(TransferModalState.ERROR);
      } else {
        setProgress(((index + 1) / props.schoolIds.length) * 100);
      }
    }

    setProgress(100);
    setModalState(TransferModalState.DONE);
  };

  const getDescription = () => {
    if (modalState === TransferModalState.WORKING) {
      return trans('SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.TRANSFER_MODAL.PROGRESS.IN_PROGRESS');
    }
    if (modalState === TransferModalState.ERROR) {
      return trans('SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.TRANSFER_MODAL.PROGRESS.ERROR');
    }
    return trans('SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.TRANSFER_MODAL.PROGRESS.DONE');
  };

  return (
    <>
      <Confirm
        open={modalState === TransferModalState.CONFIRM}
        title={'SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.TRANSFER_MODAL.CONFIRM.TITLE'}
        message={'SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.TRANSFER_MODAL.CONFIRM.MESSAGE'}
        confirm={startTransfer}
        cancel={props.onClose}
      />
      <ProgressModal
        open={[TransferModalState.WORKING, TransferModalState.DONE, TransferModalState.ERROR].includes(modalState)}
        title={trans('SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.TRANSFER_MODAL.PROGRESS.TITLE')}
        description={getDescription()}
        progress={progress}
        error={modalState === TransferModalState.ERROR}
        button={{
          label: trans('COMMON.CLOSE'),
          action: props.onTransferEnd,
          disabled: modalState === TransferModalState.WORKING,
        }}
      />
    </>
  );
};

import { muiStyles } from 'utils/design';
import { important } from 'utils/design/important';

export const useStyles = muiStyles({
  autoSave: {
    width: 0,
    height: 0,
    margin: important(0),
    padding: 0,
  },
});

import { Page } from 'components/layout/panel/Page/Page';
import { SubventionForecastContainer } from 'components/reports/SubventionForecast/SubventionForecastContainer';
import { useTranslator } from 'components/shared/hooks';
import { Breadcrumb } from 'components/shared/navigation/Breadcrumbs/Breadcrumb';
import React, { Fragment } from 'react';
import { useLocation } from 'react-router';

export const SubventionForecastView = () => {
  const trans = useTranslator();
  const key = useLocation().key;

  return (
    <Fragment>
      <Breadcrumb label={trans('BREADCRUMB.REPORTS.SUBVENTION_FORECAST')} static />

      <Page title={trans('REPORTS.SUBVENTION_FORECAST.TITLE')}>
        <SubventionForecastContainer key={key} />
      </Page>
    </Fragment>
  );
};

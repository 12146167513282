import { getAlertTranslationSlug } from 'components/schoolDistricts/AssignmentPreview/alerts';
import { useNotification, useTranslator } from 'components/shared/hooks';
import React, { FC, useEffect } from 'react';
import { AssignmentPreview } from 'components/schoolDistricts/AssignmentPreview/AssignmentPreview';
import { AppDispatch } from '@typings/redux';
import { useDispatch, useSelector } from 'react-redux';
import {
  AssignmentSchoolModel,
  AssignmentStatus,
  ChildrenAlertsTypes,
  downloadChildrenAlertList as downloadChildrenAlertListCsv,
  downloadChildrenList as downloadChildrenListCsv,
  fetchAssignmentDetails,
  getAssignmentDetailsSelector,
  publishAssignment,
  transferChildrenList,
} from 'store/schoolDistricts';
import { AssignmentPreviewContainerProps } from 'components/schoolDistricts/AssignmentPreview/AssignmentPreview.types';
import { push } from 'connected-react-router';
import { formatRoute } from 'react-router-named-routes';
import {
  ROUTE_SCHOOL_CHILDREN_ALERT_LIST,
  ROUTE_SCHOOL_CHILDREN_LIST,
  ROUTE_SCHOOL_DISTRICTS_CATCHMENT_ASSIGNMENT_LIST,
  ROUTE_SCHOOL_DISTRICTS_DATABASE,
} from 'config/routes';

export const AssignmentPreviewContainer: FC<AssignmentPreviewContainerProps> = ({ id, withRules }) => {
  const dispatch: AppDispatch = useDispatch();
  const trans = useTranslator();
  const { loading, data } = useSelector(getAssignmentDetailsSelector);
  const { success, error } = useNotification();

  useEffect(() => {
    dispatch(fetchAssignmentDetails(id, withRules));
  }, [dispatch, id, withRules]);

  const redirectToChildrenList = (schoolId) =>
    dispatch(push(formatRoute(ROUTE_SCHOOL_CHILDREN_LIST, { id, schoolId })));
  const redirectToChildrenAlertList = (alert) =>
    dispatch(push(formatRoute(ROUTE_SCHOOL_CHILDREN_ALERT_LIST, { id, alert })));
  const redirectToDatabaseList = () => {
    if (data.status === AssignmentStatus.DRAFT) {
      return dispatch(push(ROUTE_SCHOOL_DISTRICTS_DATABASE));
    }
    return dispatch(push(ROUTE_SCHOOL_DISTRICTS_CATCHMENT_ASSIGNMENT_LIST));
  };

  const downloadChildrenList = (school: AssignmentSchoolModel) => {
    return dispatch(
      downloadChildrenListCsv(
        `${trans('SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.SCHOOL_REGISTER.CATCHMENT_CHILDREN')}-${school.name}`,
        id,
        school.id,
      ),
    );
  };
  const downloadChildrenAlertList = (alert: ChildrenAlertsTypes) => {
    const fileName = trans(getAlertTranslationSlug(alert));
    return dispatch(downloadChildrenAlertListCsv(fileName, id, alert));
  };

  const saveAssignment = () =>
    dispatch(publishAssignment(id))
      .then((payload) => {
        if (payload.error) {
          error('SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.SAVE.FAILURE');
        } else {
          success('SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.SAVE.SUCCESS');
        }
      })
      .finally(() => dispatch(fetchAssignmentDetails(id)));

  const transferChildrenListHandler = async (school: AssignmentSchoolModel) => {
    const result = await dispatch(transferChildrenList(data.id, school.id));

    if (result.error) {
      error(trans('SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.SCHOOL_REGISTER.TRANSFER.ERROR'));
    } else {
      success(trans('SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.SCHOOL_REGISTER.TRANSFER.SUCCESS'));
      dispatch(fetchAssignmentDetails(id));
    }
  };

  const refreshAssignment = () => {
    dispatch(fetchAssignmentDetails(id));
  };

  return (
    <AssignmentPreview
      isLoading={loading}
      data={data}
      redirectToChildrenList={redirectToChildrenList}
      redirectToChildrenAlertList={redirectToChildrenAlertList}
      downloadChildrenList={downloadChildrenList}
      downloadChildrenAlertList={downloadChildrenAlertList}
      transferChildrenList={transferChildrenListHandler}
      refreshAssignment={refreshAssignment}
      redirectToDatabaseList={redirectToDatabaseList}
      saveAssignment={saveAssignment}
    />
  );
};

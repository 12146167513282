import { ROUTE_AUTH_AUTHORIZE, ROUTE_AUTH_LOGIN, ROUTE_AUTH_SSO } from 'config/routes';
import React from 'react';
import { Route, Switch } from 'react-router';
import { AuthorizeView } from 'views/Auth/Authorize/Authorize.view';
import { SsoView } from 'views/Auth/Sso/Sso.view';
import { SignInView } from 'views/Auth/SignIn/SignIn.view';
import { NotFoundView } from 'views/Errors/NotFound.view';

export const AuthView: React.FC = () => {
  return (
    <Switch>
      <Route path={ROUTE_AUTH_LOGIN} exact component={SignInView} />
      <Route path={ROUTE_AUTH_AUTHORIZE} exact component={AuthorizeView} />
      <Route path={ROUTE_AUTH_SSO} exact component={SsoView} />
      <Route component={NotFoundView} />
    </Switch>
  );
};

import React, { FC, useEffect } from 'react';
import { SchoolStatDetailsContainerProps } from 'components/reports/SchoolStatDetails/SchoolStatDetails.types';
import { SchoolStatDetails } from 'components/reports/SchoolStatDetails/SchoolStatDetails';
import { useDispatch, useSelector } from 'react-redux';
import { schoolStatSelector } from 'store/reports/reports.selector';
import { AppDispatch } from '@typings/redux';
import { fetchSchoolStatDetails } from 'store/reports/reports.action';
import { TextLoader } from 'components/shared/loaders/TextLoader/TextLoader';
import { useTranslator } from 'components/shared/hooks';

export const SchoolStatDetailsContainer: FC<SchoolStatDetailsContainerProps> = ({ id }) => {
  const { details, loading } = useSelector(schoolStatSelector);
  const dispatch = useDispatch<AppDispatch>();
  const trans = useTranslator();

  useEffect(() => {
    dispatch(fetchSchoolStatDetails(id));
  }, [dispatch, id]);

  if (!details) return <TextLoader text={trans('COMMON.LOADING_DATA')} />;

  const { name, table, updateDate, type } = details;

  return <SchoolStatDetails name={name} updateDate={updateDate} table={table} isLoading={loading} type={type} />;
};

import { recipientsColumns, recipientsGroupingColumn } from 'components/polls/Results/Recipients/columns';
import { RecipientsProps } from 'components/polls/Results/Results.types';
import { DataGrid } from 'components/shared/data/DataGrid/DataGrid';
import React from 'react';

export const Recipients = (props: RecipientsProps) => {
  return (
    <DataGrid
      columns={recipientsColumns()}
      groupingColumn={recipientsGroupingColumn()}
      data={props.recipients}
      isLoading={false}
      groupMultiAutoColumn={true}
      suppressAggFuncInHeader={true}
      getRowClass={(params) => {
        return params.node.group ? 'font-weight-bold' : '';
      }}
    />
  );
};

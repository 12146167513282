export const getDecimalsCount = (value: number): number => {
  if (value % 1 !== 0) {
    return value.toString().split('.')[1].length;
  } else {
    return 0;
  }
};

export const getSumDecimalsCount = (componentA: number, componentB: number): number => {
  const countA = getDecimalsCount(componentA);
  const countB = getDecimalsCount(componentB);

  return Math.max(countA, countB);
};

export const roundDecimals = (value, decimalsCount) => {
  return Math.round(value * 10 ** decimalsCount) / 10 ** decimalsCount;
};

import React from 'react';

export const createList = (title: string, list: string[]) => {
  return (
    <div className={'mt-2 ml-2'}>
      {title}
      <ul>
        {list.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>
    </div>
  );
};

export enum PollStatus {
  DRAFT = 'draft',
  PUBLISHING = 'publishing',
  PUBLISHED = 'published',
  CLOSED = 'closed',
}

export interface PollFilters {
  status?: PollStatus;
}

export enum QuestionType {
  TEXT = 'text',
  LONG_TEXT = 'long_text',
  SINGLE_CHOICE = 'single_choice',
  MULTIPLE_CHOICE = 'multiple_choice',
}

export const hasOptions = (type: QuestionType) => {
  return [QuestionType.SINGLE_CHOICE, QuestionType.MULTIPLE_CHOICE].includes(type);
};

export interface QuestionOption {
  value: string;
}

export interface Question {
  type: QuestionType;
  title: string;
  required: boolean;
  options: QuestionOption[];
}

export interface Poll {
  id: string;
  name: string;
  status: PollStatus;
  expectedResponsesCount: number;
  responsesCount: number;
  publishDate: string | null;
  expirationDate: string | null;
}

export interface PollForm {
  name: string;
  description: string;
  questions: Question[];
}

export enum PollResponseMode {
  INSTITUTION = 'institution',
  RECIPIENT = 'recipient',
}

export interface PollPublishForm {
  expirationDate: string;
  institutions: string[];
  recipientGroups: string[];
  pollResponseMode: PollResponseMode;
}

export interface PollDetails {
  id: string;
  name: string;
  description: string;
  publishDate: string;
  expirationDate: string;
  questions: Question[];
  status: PollStatus;
}

export interface SynchronizedInstitution {
  id: string;
  name: string;
}

export interface RecipientGroup {
  name: string;
}

export interface RecipientCounts {
  [groupName: string]: number;
}

export interface QuestionnaireQuestion extends Question {
  index: string;
}

export interface Questionnaire {
  id: string;
  name: string;
  description: string;
  questions: QuestionnaireQuestion[];
  departmentName: string;
}

export interface FormAnswer {
  index: string;
  result: string | string[];
}

export interface ResponseForm {
  answers: FormAnswer[];
}

export interface CombinedAnswer {
  option: string;
  count?: number;
  percentage?: number;
}

export interface ResponseAnswer {
  question: QuestionnaireQuestion;
  answers: string[];
}

export interface Response {
  answers: ResponseAnswer[];
  responseDate: string;
  institutionName: string;
  recipientGroup: string;
}

export interface Result {
  title: string;
  questionType: QuestionType;
  combinedAnswers: CombinedAnswer[];
}

export interface PollResults {
  name: string;
  description: string;
  expectedResponsesCount: number;
  responses: string[];
  results: Result[];
}

export interface Recipient {
  institutionName: string;
  recipientGroup: string;
  answeredAt: string;
}

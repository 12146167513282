import React, { FC } from 'react';

import { DatabaseForm } from 'components/schoolDistricts/DatabaseForm/DatabaseForm';
import { DatabaseAddContainerProps } from 'components/schoolDistricts/DatabaseForm/DatabaseAddContainer.types';
import { useTranslator } from 'components/shared/hooks/useTranslator/useTranslator';

export const DatabaseAdd: FC<DatabaseAddContainerProps> = (props) => {
  const trans = useTranslator();

  return (
    <DatabaseForm
      title={trans('SCHOOL_DISTRICTS.DATABASE-ADD.TITLE')}
      successMessage="SCHOOL_DISTRICTS.DATABASE-ADD.SUCCESS"
      errorMessage="SCHOOL_DISTRICTS.DATABASE-ADD.FAIL"
      open={props.open}
      databases={props.databases}
      hideDatabaseSelector={false}
      onConfirm={props.onConfirm}
      onClose={props.onClose}
    />
  );
};

import { DatePicker } from 'components/shared/form/DatePicker/DatePicker';
import React, { FC, useRef } from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { useStyles } from 'components/schoolObligation/ReportModal/ReportModal.styles';
import { useFormatter, useTranslator } from 'components/shared/hooks';
import { CreateReportForm, ReportModalProps } from 'components/schoolObligation/ReportModal/ReportModal.types';
import { Form } from 'components/shared/form';
import { Button } from 'components/shared/buttons/Button/Button';
import { DialogActions } from '@material-ui/core';
import { FormikHelpers } from 'formik';
import { TreeSelectRef } from 'components/shared/form/TreeSelectBox/TreeSelectBox.types';
import BirthYearsSelect from 'components/schoolObligation/ReportModal/BirthYearsSelect';

export const ReportModal: FC<ReportModalProps> = ({
  open,
  onClose,
  birthYears,
  onSave,
  onSubmitSuccess,
  onReferenceDateUpdate,
  initialValues,
}) => {
  const classes = useStyles();
  const trans = useTranslator();
  const { formatApiErrors } = useFormatter();
  const birthYearsRef = useRef<TreeSelectRef>();

  const handleSubmit = (values: CreateReportForm, formik: FormikHelpers<CreateReportForm>) => {
    onSave(values).then((result) => {
      if (result.error) {
        if (result.payload.response.errors) {
          formik.setErrors(formatApiErrors(result.payload.response.errors));
        }
      } else {
        onSubmitSuccess();
      }
      formik.setSubmitting(false);
    });
  };

  return (
    <Form<CreateReportForm> initialValues={initialValues} onSubmit={handleSubmit}>
      {({ handleSubmit, setFieldValue, errors }) => (
        <>
          <Dialog disableEscapeKeyDown open={open} maxWidth={'md'} scroll="paper">
            <DialogTitle id="confirm-title">{trans('SCHOOL_OBLIGATION.REPORT_MODAL.TITLE')}</DialogTitle>
            <DialogContent className={classes.content}>
              <DatePicker
                name="referenceDate"
                labelText={trans('SCHOOL_OBLIGATION.REPORT_MODAL.REFERENCE_DATE.LABEL')}
                outputFormat="YYYY-MM-DD"
                onChange={(date: string, isValid: boolean) => {
                  isValid && onReferenceDateUpdate(date);

                  if (birthYearsRef && birthYearsRef.current) {
                    birthYearsRef.current.resetTree();
                  }
                }}
              />
              <Box marginTop={2}>
                <BirthYearsSelect
                  value={birthYears}
                  onChange={(value) => {
                    setFieldValue('birthYears', value);
                  }}
                  error={errors.birthYears}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="secondary" variant="outlined">
                {trans('COMMON.CANCEL')}
              </Button>
              <Button onClick={() => handleSubmit()} color="secondary" variant="contained">
                {trans('SCHOOL_OBLIGATION.REPORT_MODAL.SAVE')}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Form>
  );
};

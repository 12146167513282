import { Action } from 'react-fetching-library';
import { ActionCreator } from 'redux';

export interface TemplateActionParams {
  templateId: string;
}

export const getTemplateFile: ActionCreator<Action> = ({ templateId }: TemplateActionParams) => ({
  method: 'GET',
  endpoint: `/templates/${templateId}`,
  responseType: 'blob',
});

import { AuthData } from '@typings/common';
import { authRedirectUri } from 'config/auth';
import queryString from 'query-string';
import { toApiFormat } from 'utils/api';
import { getAppConfig } from 'utils/config/appConfig';
import { addEnvironmentOrigin } from 'utils/string/addPathParameter';

export interface FetchAccessTokenResponse {
  result?: AuthData;
  error?: string;
}

export interface FetchAccessToken {
  (code: string, environmentOrigin: string): Promise<FetchAccessTokenResponse>;
}

const getAuthorizationUrl = (params: object, environmentOrigin: string) => {
  const apiUrl = getAppConfig('OSIN20_API_URL');
  const url = `${apiUrl}/auth?${queryString.stringify(params)}`;

  return addEnvironmentOrigin(url, environmentOrigin);
};

export const fetchAccessToken: FetchAccessToken = async (code: string, environmentOrigin: string) => {
  const params = toApiFormat({ code, redirectUri: authRedirectUri });
  const response = await fetch(getAuthorizationUrl(params, environmentOrigin));

  if (response.status === 200) {
    const data = await response.json();
    return { result: { access_token: data.token, refresh_token: data.refresh_token } };
  }

  if ([401, 403].includes(response.status)) {
    const data = await response.json();
    return { error: data.error };
  }

  return { error: 'AUTH.FAILED' };
};

import React, { useState } from 'react';
import { addLocaleData, injectIntl, IntlProvider } from 'react-intl';
import pl from 'react-intl/locale-data/pl';
import { LocaleContextProvider } from '../LocaleContextProvider/LocaleContextProvider';
import { getMessages } from 'utils/i18n';
import { LocaleProviderProps } from './LocaleProvider.types';
import countries from 'i18n-iso-countries';

countries.registerLocale(require('i18n-iso-countries/langs/pl.json'));
addLocaleData([...pl]);

const messages = getMessages('pl');
const WrappedLocaleContext = injectIntl(LocaleContextProvider);

export const LocaleProvider = (props: LocaleProviderProps) => {
  const [locale, setLocale] = useState(props.locale);
  const updateLocale = (newLocale: string) => {
    setLocale(newLocale);
  };

  return (
    <IntlProvider locale={locale} messages={messages}>
      <WrappedLocaleContext updateLocale={updateLocale} locale={locale}>
        {props.children}
      </WrappedLocaleContext>
    </IntlProvider>
  );
};

import * as React from 'react';
import classNames from 'classnames';
import 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon.scss';
import { useStyles } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon.styles';
import { FontAwesomeIconProps } from './FontAwesomeIcon.types';

// Import proper font in `FontAwesomeIcon.scss` after changing this
const FONT_AWESOME_STYLE = 'fas';

const getIconClass = (name) => {
  return `fa-${name}`;
};

const mapSizeToClass = (size: string) => {
  return size === 'normal' ? '' : `fa-${size}`;
};

const mapAnimationToClass = (animation: string) => {
  return animation === 'none' ? '' : `fa-${animation}`;
};

const mapFixedWidthToClass = (fixedWidth: boolean) => {
  return fixedWidth ? 'fa-fw' : '';
};

const mapFixedHeightToClass = (fixedHeight: boolean, className: string) => {
  return fixedHeight ? className : '';
};

const FontAwesomeIconComponent = (props: FontAwesomeIconProps, ref) => {
  const { animation = 'none', size = 'normal', fixedWidth = false, fixedHeight = false, className = '' } = props;
  const classes = useStyles({ color: 'default', ...props });

  return (
    <span
      ref={ref}
      onClick={(event) => props.onClick && props.onClick(event)}
      onMouseDown={(event) => props.onMouseDown && props.onMouseDown(event)}
      className={classNames(
        FONT_AWESOME_STYLE,
        getIconClass(props.name),
        mapSizeToClass(size),
        mapAnimationToClass(animation),
        mapFixedWidthToClass(fixedWidth),
        mapFixedHeightToClass(fixedHeight, classes.fixedHeight),
        classes.icon,
        className,
      )}
      data-testid={props.dataTestId}
    />
  );
};

FontAwesomeIconComponent.displayName = 'FontAwesomeIconComponent';

export const FontAwesomeIcon = React.forwardRef(FontAwesomeIconComponent);

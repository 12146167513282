import { AppDispatch } from '@typings/redux';
import { RecipientsStatistic } from 'components/polls/Publication/Publication.types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRecipientCounts, PollResponseMode, RecipientCounts, recipientsCountsSelector } from 'store/polls';

interface RecipientsCountHook {
  (responseMode: PollResponseMode, institutions: string[], groups: string[]): RecipientsStatistic;
}

export const useRecipientsCount: RecipientsCountHook = (responseMode, institutions, groups) => {
  const dispatch = useDispatch<AppDispatch>();
  const recipients = useSelector(recipientsCountsSelector);

  useEffect(() => {
    institutions.forEach((institutionId) => {
      if (!recipients[institutionId]) {
        dispatch(fetchRecipientCounts(institutionId));
      }
    });
  }, [dispatch, institutions, recipients]);

  const isCountUpdating = (institutionId) => {
    return recipients[institutionId] && !recipients[institutionId].loading;
  };

  const sumRecipientsForSelectedGroups = (counts: RecipientCounts) => {
    return groups.reduce((sum, groupName) => {
      return sum + (counts[groupName] || 0);
    }, 0);
  };

  const recipientsCount = institutions
    .filter((institutionId) => isCountUpdating(institutionId))
    .reduce((sum, institutionId) => {
      return sum + sumRecipientsForSelectedGroups(recipients[institutionId].counts);
    }, 0);

  const institutionsWithRecipientsCount = institutions
    .filter((institutionId) => isCountUpdating(institutionId))
    .filter((institutionId) => {
      return sumRecipientsForSelectedGroups(recipients[institutionId].counts) > 0;
    }).length;

  const isAnyCountLoading = Object.values(recipients).some((count) => count.loading);

  const responsesCount =
    responseMode === PollResponseMode.INSTITUTION ? institutionsWithRecipientsCount : recipientsCount;

  return {
    responsesCount,
    recipientsCount,
    updating: isAnyCountLoading,
  };
};

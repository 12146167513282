import { Alert } from 'components/childRegister/Browser/Alerts/Alerts.types';
import { ChildRegisterActionType as ActionType } from 'store/childRegister/childRegister.actionTypes';
import { ChildRegisterState as State, defaultState } from 'store/childRegister/childRegister.state';
import { FSAReducer, Behaviours } from '@typings/redux';
import { RESET_STATE } from 'store/store';
import { fromApiFormat } from 'utils/api';
import { insertPage } from 'utils/pagination/insertPage';
import { ImportPreviewStatus } from 'store/childRegister/childRegister.types';

export const childRegisterReducer: FSAReducer<State, ActionType> = (state = defaultState, action) => {
  const behaviours: Behaviours<State, ActionType> = {
    [RESET_STATE]: () => ({
      ...defaultState,
    }),
    [ActionType.SET_CHILD_REGISTER_FILTERS]: ({ payload }) => {
      return {
        ...state,
        list: {
          ...state.list,
          filters: payload,
        },
      };
    },
    [ActionType.CLEAR_CHILD_REGISTER_DATA]: () => {
      return {
        ...state,
        list: {
          ...state.list,
          items: [],
          alerts: [],
          ready: false,
        },
      };
    },
    [ActionType.FETCH_CHILD_REGISTER_REQUEST]: () => {
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      };
    },
    [ActionType.FETCH_CHILD_REGISTER_SUCCESS]: ({ payload, meta }) => {
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          items: insertPage(state.list.items, fromApiFormat(payload.items), meta.page),
          total: payload.metadata.total,
          alerts: Object.keys(payload.alerts).map((alertKey) => ({
            id: alertKey as Alert,
            count: payload.alerts[alertKey],
          })),
        },
      };
    },
    [ActionType.FETCH_CHILD_REGISTER_FAILURE]: ({ error }) => {
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error,
        },
      };
    },
    [ActionType.FETCH_BIRTH_YEARS_REQUEST]: () => {
      return {
        ...state,
        birthYears: {
          ...state.birthYears,
          loading: true,
        },
      };
    },
    [ActionType.FETCH_BIRTH_YEARS_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        list: {
          ...state.list,
          filters: {
            ...state.list.filters,
            birthYear:
              state.list.filters.birthYear && payload.includes(state.list.filters.birthYear)
                ? state.list.filters.birthYear
                : null,
          },
        },
        birthYears: {
          ...state.birthYears,
          loading: false,
          list: payload,
        },
      };
    },
    [ActionType.FETCH_ALERT_TYPES_REQUEST]: () => {
      return {
        ...state,
        alertTypes: {
          ...state.alertTypes,
          loading: true,
        },
      };
    },
    [ActionType.FETCH_ALERT_TYPES_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        alertTypes: {
          ...state.alertTypes,
          loading: false,
          list: fromApiFormat(payload),
        },
      };
    },
    [ActionType.FETCH_CHILD_DETAILS_REQUEST]: () => {
      return {
        ...state,
        child: {
          ...state.child,
          loading: true,
        },
      };
    },
    [ActionType.FETCH_CHILD_DETAILS_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        child: {
          loading: false,
          data: fromApiFormat(payload),
        },
      };
    },
    [ActionType.CLEAR_CHILD_DETAILS]: () => {
      return {
        ...state,
        child: {
          ...state.child,
          data: null,
        },
      };
    },
    [ActionType.FETCH_GUARDIAN_TYPES_REQUEST]: () => {
      return {
        ...state,
        guardianTypes: {
          ...state.guardianTypes,
          loading: true,
        },
      };
    },
    [ActionType.FETCH_GUARDIAN_TYPES_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        guardianTypes: {
          ...state.guardianTypes,
          list: payload,
          loading: false,
        },
      };
    },
    [ActionType.FETCH_GUARDIAN_TYPES_FAILURE]: () => {
      return {
        ...state,
        guardianTypes: {
          ...state.guardianTypes,
          loading: false,
          error: true,
        },
      };
    },
    [ActionType.SET_CUSTOM_GUARDIAN_TYPES]: ({ payload }) => {
      return {
        ...state,
        guardianTypes: {
          ...state.guardianTypes,
          customType: [payload],
        },
      };
    },
    [ActionType.FETCH_ADDRESS_HISTORY_REQUEST]: () => {
      return {
        ...state,
        addressHistory: {
          loading: true,
          list: [],
        },
      };
    },
    [ActionType.FETCH_ADDRESS_HISTORY_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        addressHistory: {
          loading: false,
          list: fromApiFormat(payload),
        },
      };
    },
    [ActionType.FETCH_OBLIGATION_REPORTS_REQUEST]: () => {
      return {
        ...state,
        obligationReports: {
          loading: true,
          list: [],
        },
      };
    },
    [ActionType.FETCH_OBLIGATION_REPORTS_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        obligationReports: {
          loading: false,
          list: fromApiFormat(payload).items,
        },
      };
    },
    [ActionType.FETCH_ADDRESS_HISTORY_FAILURE]: () => {
      return {
        ...state,
        addressHistory: {
          loading: false,
          list: [],
        },
      };
    },
    [ActionType.FETCH_OBLIGATION_ACTIONS_REQUEST]: () => {
      return {
        ...state,
        obligationActions: {
          loading: true,
          list: [],
        },
      };
    },
    [ActionType.FETCH_OBLIGATION_ACTIONS_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        obligationActions: {
          loading: false,
          list: fromApiFormat(payload).items,
        },
      };
    },
    [ActionType.CLEAR_OBLIGATION_ACTIONS]: () => {
      return {
        ...state,
        obligationActions: {
          ...state.obligationActions,
          list: [],
        },
      };
    },
    [ActionType.CLEAR_EDUCATION_PATH]: () => {
      return {
        ...state,
        educationPath: {
          ...state.educationPath,
          list: [],
        },
      };
    },
    [ActionType.FETCH_EDUCATION_PATH_REQUEST]: () => {
      return {
        ...state,
        educationPath: {
          loading: true,
          list: [],
        },
      };
    },
    [ActionType.FETCH_EDUCATION_PATH_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        educationPath: {
          loading: false,
          list: fromApiFormat(payload),
        },
      };
    },
    [ActionType.FETCH_EDUCATION_PATH_FAILURE]: () => {
      return {
        ...state,
        educationPath: {
          loading: false,
          list: [],
        },
      };
    },
    [ActionType.POST_IMPORT_FILE_REQUEST]: () => {
      return {
        ...state,
        import: {
          ...defaultState.import,
          loading: true,
        },
      };
    },
    [ActionType.POST_IMPORT_FILE_SUCCESS]: ({ payload, meta }) => {
      return {
        ...state,
        import: {
          ...state.import,
          loading: false,
          token: fromApiFormat(payload).data.importToken,
          file: meta.file,
          importType: meta.importType,
        },
      };
    },
    [ActionType.DELETE_IMPORT_SUCCESS]: () => {
      return {
        ...state,
        import: {
          ...state.import,
          token: null,
        },
      };
    },
    [ActionType.DELETE_IMPORT_FAILURE]: () => {
      return {
        ...state,
        import: defaultState.import,
      };
    },
    [ActionType.FETCH_IMPORT_COLUMNS_REQUEST]: () => {
      return {
        ...state,
        import: {
          ...state.import,
          loading: true,
          error: null,
        },
      };
    },
    [ActionType.FETCH_IMPORT_COLUMNS_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        import: {
          ...state.import,
          columns: payload.fields,
          loading: false,
          error: null,
        },
      };
    },
    [ActionType.FETCH_IMPORT_COLUMNS_FAILURE]: ({ payload }) => {
      return {
        ...state,
        import: {
          ...state.import,
          loading: false,
          error: payload.response.error || null,
        },
      };
    },
    [ActionType.POST_IMPORT_CONTEXT_SUCCESS]: ({ meta }) => {
      return {
        ...state,
        import: {
          ...state.import,
          context: meta.context,
          preview: defaultState.import.preview,
        },
      };
    },
    [ActionType.FETCH_IMPORT_PREVIEW_START_REQUEST]: () => {
      return {
        ...state,
        import: {
          ...state.import,
          loading: true,
        },
      };
    },
    [ActionType.FETCH_IMPORT_PREVIEW_START_SUCCESS]: () => {
      return {
        ...state,
        import: {
          ...state.import,
          previewStatus: {
            progress: 0,
            status: ImportPreviewStatus.preparingPreview,
          },
        },
      };
    },
    [ActionType.FETCH_IMPORT_PREVIEW_START_FAILURE]: () => {
      return {
        ...state,
        import: {
          ...state.import,
          loading: false,
        },
      };
    },
    [ActionType.FETCH_IMPORT_PREVIEW_REQUEST]: () => {
      return {
        ...state,
        import: {
          ...state.import,
          loading: true,
        },
      };
    },
    [ActionType.FETCH_IMPORT_PREVIEW_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        import: {
          ...state.import,
          preview: fromApiFormat(payload),
          loading: false,
        },
      };
    },
    [ActionType.FETCH_IMPORT_PREVIEW_FAILURE]: () => {
      return {
        ...state,
        import: {
          ...state.import,
          loading: false,
        },
      };
    },
    [ActionType.FETCH_IMPORT_PREVIEW_STATUS_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        import: {
          ...state.import,
          previewStatus: fromApiFormat(payload),
        },
      };
    },
    [ActionType.CLEAR_IMPORT_INFORMATION]: () => {
      return {
        ...state,
        import: defaultState.import,
      };
    },
    [ActionType.MAP_IMPORT_FIELDS]: ({ payload }) => {
      return {
        ...state,
        import: {
          ...state.import,
          context: {
            ...state.import.context,
            map: payload,
          },
        },
      };
    },
  };

  return behaviours[action.type] ? behaviours[action.type](action) : state;
};

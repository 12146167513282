import { isOptionGrouped } from 'components/shared/form/Autocomplete/Autocomplete.types';
import { useTranslator } from 'components/shared/hooks';
import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon';
import React, { Fragment } from 'react';
import { Field, FieldProps } from 'formik';
import {
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  FormHelperText,
  InputAdornment,
  Tooltip,
} from '@material-ui/core';
import { useStyles } from './SelectBox.styles';
import { SelectBoxItem, SelectBoxProps } from './SelectBox.types';
import classNames from 'classnames';
import { get, flatMap, isEqual } from 'lodash';

const SelectLabel = ({ labelText, displayEmpty }) => {
  const classes = useStyles();

  return labelText ? (
    <InputLabel shrink={displayEmpty} error={false} classes={{ root: classes.label }}>
      {labelText}
    </InputLabel>
  ) : null;
};

export const SelectBox = <T extends SelectBoxItem>({
  name,
  required,
  labelText,
  options,
  placeholderText,
  validate,
  onChange,
  displayEmpty,
  emptyValue = '',
  resettable,
  onValueReset,
  icon,
  hasIcon = false,
  tooltipText = '',
  headerComponent,
  itemComponent,
  itemClassName = '',
  valueComponent,
  ...rest
}: SelectBoxProps<T>) => {
  const classes = useStyles();
  let currentGroup = null;
  const trans = useTranslator();

  const shouldShowResetButton = (value) => {
    return resettable && value !== undefined && value !== null && !isEqual(value, emptyValue) && !rest.disabled;
  };

  const renderGroupHeader = (label: string) => (
    <MenuItem disabled key={`group-${label}`}>
      {headerComponent ? headerComponent({ label }) : label}
    </MenuItem>
  );

  const renderItem = (option: T) => (
    <MenuItem
      value={option.value}
      key={option.value}
      className={classNames(itemClassName, { [classes.groupedItem]: currentGroup !== null })}
      disabled={option.disabled === true}
    >
      {itemComponent ? itemComponent(option) : option.label}
    </MenuItem>
  );

  const renderEmptyOption = () => {
    if (!displayEmpty) {
      return null;
    }

    return (
      <MenuItem value={emptyValue} key="-2">
        {placeholderText}
      </MenuItem>
    );
  };

  const renderOptions = () => {
    if (!options || options.length === 0) {
      return null;
    }

    return flatMap(options, (option) => {
      if (isOptionGrouped(option) && currentGroup !== option.group) {
        currentGroup = option.group;
        return [renderGroupHeader(option.group), renderItem(option)];
      } else {
        return renderItem(option);
      }
    });
  };

  const renderNoOptionsOption = () => {
    if (displayEmpty || !options || options.length > 0) {
      return null;
    }

    return (
      <MenuItem value={null} key="-1" disabled>
        {trans('COMMON.NO_OPTIONS')}
      </MenuItem>
    );
  };

  return (
    <Field name={name} validate={validate}>
      {({ field, form }: FieldProps) => {
        const isError = !!(get(form.errors, field.name) && get(form.touched, field.name));
        const handleOnChange = (event) => {
          field.onChange(event);
          if (onChange) {
            onChange(event);
          }
        };

        return (
          <FormControl
            error={isError}
            classes={{ root: classes.formControl }}
            data-testid={`selectBox`}
            required={required}
            className={classNames({ hasValidationError: isError })}
          >
            <SelectLabel labelText={labelText} displayEmpty={displayEmpty} />
            <Select
              value={field.value ? field.value : ''}
              onChange={handleOnChange}
              onBlur={field.onBlur}
              name={field.name}
              input={<Input name={placeholderText} placeholder={placeholderText} />}
              className={classNames({
                [classes.displayEmpty]: displayEmpty && labelText,
              })}
              data-testid={`${field.name}`}
              placeholder={placeholderText}
              displayEmpty={displayEmpty}
              renderValue={valueComponent}
              endAdornment={
                <Fragment>
                  {hasIcon && (
                    <InputAdornment position="end" className={classes.tooltipIcon}>
                      <Tooltip title={tooltipText} placement="right">
                        {icon}
                      </Tooltip>
                    </InputAdornment>
                  )}
                  {shouldShowResetButton(field.value) && (
                    <FontAwesomeIcon
                      name={'times'}
                      className={classes.reset}
                      onClick={() => {
                        form.setFieldValue(field.name, emptyValue);
                        onValueReset && onValueReset();
                      }}
                    />
                  )}
                </Fragment>
              }
              {...rest}
            >
              {renderEmptyOption()}
              {renderOptions()}
              {renderNoOptionsOption()}
            </Select>
            {isError ? <FormHelperText error>{isError && get(form.errors, field.name)}</FormHelperText> : null}
          </FormControl>
        );
      }}
    </Field>
  );
};

import React, { FC, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@typings/redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Grid,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Tooltip,
  IconButton,
  FormHelperText,
  Backdrop,
} from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import { useTranslator } from 'components/shared/hooks/useTranslator/useTranslator';
import { AssignmentDialogProps, createAssignmentSelectedDatabaseDefault } from './AssignmentDialog.types';
import { birthYearsGenerator } from 'utils/date/birthYearsGenerator';
import {
  DatabaseStatus,
  createAssignment,
  fetchAssignmentStatus,
  AssignmentRequestStatusInfo,
  getAssignmentRequest,
  AssignmentStatus,
  clearAssignmentRequest,
  fetchSchoolList,
} from 'store/schoolDistricts';
import { ProgressModal } from 'components/shared/dialogs/ProgressModal/ProgressModal';
import { ADDRESS_RULE_CONFLICT_ALERT } from '../DistrictsBrowser/DistrictsBrowser.types';
import { useStyles } from './AssignmentDialog.styles';
import { Loader } from 'components/shared/loaders/Loader/Loader';
import { compareWithThreshold } from 'utils/date/compareWithThreshold';
import { useDepartmentSettings } from 'components/shared/hooks/useInstanceConfig/useDepartmentSettings';

export const AssignmentDialog: FC<AssignmentDialogProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();
  const trans = useTranslator();
  const timer = useRef<number>();
  const classes = useStyles();
  const { schoolyearThreshold } = useDepartmentSettings();

  const isStatusDone = (status: AssignmentStatus) => {
    return [AssignmentStatus.DRAFT, AssignmentStatus.SAVED].includes(status);
  };

  const createdBeforeNewSchoolYear = compareWithThreshold(new Date(), schoolyearThreshold) === -1;

  const youngBirthYears = createdBeforeNewSchoolYear ? birthYearsGenerator(0, 7) : birthYearsGenerator(0, 6);
  const schoolBirthYears = createdBeforeNewSchoolYear ? birthYearsGenerator(8, 19) : birthYearsGenerator(7, 18);
  const schoolYears = youngBirthYears.concat(schoolBirthYears);

  const createDefaultDatabaseList = () => {
    const selectedDatabaseList = {};
    schoolYears.forEach((year) => {
      selectedDatabaseList[year] = createAssignmentSelectedDatabaseDefault;
    });
    return selectedDatabaseList;
  };

  const [selectedDatabase, setSelectedDatabase] = React.useState(createDefaultDatabaseList());
  const [databaseError, setDatabaseError] = React.useState({});
  const [isOfficialDisabled, setIsOfficialDisabled] = React.useState(true);
  const [isOfficial, setIsOfficial] = React.useState(false);
  const [progressModalOpen, setProgressModalOpen] = React.useState(false);
  const [shouldCreateAssignment, setShouldCreateAssignment] = React.useState(false);
  const [canCreateAssignment, setCanCreateAssignment] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    const prepareCatchmentAreMap = () => {
      const catchmentAreaMap = {};
      schoolYears.forEach((year) => {
        if (selectedDatabase[year] === createAssignmentSelectedDatabaseDefault) {
          selectedDatabase[year] = null;
        }
        catchmentAreaMap[year] = selectedDatabase[year];
      });
      return catchmentAreaMap;
    };

    if (shouldCreateAssignment) {
      const catchmentAreaMap = prepareCatchmentAreMap();
      dispatch(createAssignment(catchmentAreaMap, isOfficial));
      setShouldCreateAssignment(false);
    }
  }, [dispatch, progressModalOpen, schoolYears, isOfficial, selectedDatabase, shouldCreateAssignment]);

  const { assignmentId, statusInfo, error } = useSelector(getAssignmentRequest);

  useEffect(() => {
    const updateStatus = async () => {
      const action = await dispatch(fetchAssignmentStatus(assignmentId));
      const payload: AssignmentRequestStatusInfo = action.payload;
      if (!isStatusDone(payload.status) && !action.error) {
        timer.current = window.setTimeout(() => updateStatus(), 1000);
      }
    };

    if (assignmentId && progressModalOpen) {
      updateStatus();
    }

    return () => clearTimeout(timer.current);
  }, [assignmentId, dispatch, progressModalOpen]);

  const handleChange = (event) => {
    const selectedDatabaseId = event.target.value;
    const selectedDatabaseYear = event.target.name;
    const databaseList = {
      ...selectedDatabase,
      [selectedDatabaseYear]: selectedDatabaseId,
    };
    setSelectedDatabase(databaseList);
    checkConflictAndValidate(selectedDatabaseId, selectedDatabaseYear, databaseList);
  };

  const validateSelectedDatabases = (databaseList, databaseErrorList) => {
    const isOfficialDisabled =
      !hasEverySchoolBirthYearDatabase(databaseList) || hasArchiveOrPlannedDatabase(databaseList);
    const anyConflict = schoolYears.some((schoolYear) => databaseErrorList[schoolYear] === true);
    const anyDatabaseSelected = schoolYears.some(
      (schoolYear) => databaseList[schoolYear] !== createAssignmentSelectedDatabaseDefault,
    );
    setIsOfficialDisabled(isOfficialDisabled);
    setCanCreateAssignment(anyDatabaseSelected && !anyConflict);
  };

  const checkConflictAndValidate = (databaseId, databaseYear, databaseList) => {
    if (databaseId !== createAssignmentSelectedDatabaseDefault) {
      setIsLoading(true);
      dispatch(fetchSchoolList(databaseId)).then((res) => {
        const haveSchoolsWithWarning = res.payload.items.some((school) => {
          return school.alerts.some((alert) => alert.name === ADDRESS_RULE_CONFLICT_ALERT);
        });
        const databaseErrorList = {
          ...databaseError,
          [databaseYear]: haveSchoolsWithWarning,
        };
        setDatabaseError(databaseErrorList);
        validateSelectedDatabases(databaseList, databaseErrorList);
        setIsLoading(false);
      });
    } else {
      const databaseErrorList = {
        ...databaseError,
        [databaseYear]: false,
      };
      setDatabaseError(databaseErrorList);
      validateSelectedDatabases(databaseList, databaseErrorList);
    }
  };

  const hasEverySchoolBirthYearDatabase = (databaseList) => {
    const schoolBirthYearDatabaseList = [];
    const schoolBirthYearsWithOldestYoungBirthYear = createdBeforeNewSchoolYear
      ? birthYearsGenerator(7, 18)
      : birthYearsGenerator(6, 18);
    schoolBirthYearsWithOldestYoungBirthYear.forEach((schoolYear) => {
      schoolBirthYearDatabaseList.push(databaseList[schoolYear]);
    });
    return Object.values(schoolBirthYearDatabaseList).includes(createAssignmentSelectedDatabaseDefault) ? false : true;
  };

  const hasArchiveOrPlannedDatabase = (databaseList) => {
    const invalidDatabases = [];
    schoolYears.forEach((year) => {
      const databaseId = databaseList[year];
      if (databaseId !== createAssignmentSelectedDatabaseDefault) {
        const selectedDatabase = getDatabaseById(databaseId);
        if (selectedDatabase.status === DatabaseStatus.ARCHIVAL || selectedDatabase.status === DatabaseStatus.PLANNED) {
          invalidDatabases.push(selectedDatabase.id);
        }
      }
    });
    return invalidDatabases.length > 0;
  };

  const getDatabaseById = (databaseId) => {
    const selectedDatabase = props.databases.find((database) => {
      return database.id === databaseId;
    });
    return selectedDatabase;
  };

  const handleCheckboxChange = () => {
    setIsOfficial(!isOfficial);
  };

  const getProgressModalMessage = () => {
    if (error) {
      return trans('SCHOOL_DISTRICTS.ASSIGNMENT_MODAL.STEPS.ERROR');
    } else if (!assignmentId) {
      return trans('SCHOOL_DISTRICTS.ASSIGNMENT_MODAL.STEPS.INITIALIZATION');
    } else if (statusInfo.status === AssignmentStatus.NEW) {
      return trans('SCHOOL_DISTRICTS.ASSIGNMENT_MODAL.STEPS.AWAITING');
    } else if (statusInfo.status === AssignmentStatus.ASSIGNING) {
      return trans('SCHOOL_DISTRICTS.ASSIGNMENT_MODAL.STEPS.PROCESSING');
    } else {
      return trans('SCHOOL_DISTRICTS.ASSIGNMENT_MODAL.STEPS.READY');
    }
  };

  const handleProgressModalClose = () => {
    props.onClose();
    clearTimeout(timer.current);
    window.setTimeout(() => dispatch(clearAssignmentRequest()), 300);
    setProgressModalOpen(false);
  };

  const handleOpenAssignment = () => {
    props.onOpenAssignment(assignmentId);
    handleProgressModalClose();
  };

  const openProgressModal = () => {
    setProgressModalOpen(true);
    setShouldCreateAssignment(true);
  };

  return (
    <Dialog fullWidth maxWidth={'md'} disableEscapeKeyDown aria-labelledby="import-status-title" open={props.open}>
      <Backdrop style={{ color: '#fff', zIndex: 1 }} open={isLoading}>
        <Loader name={'spinner'} animation={'pulse'} size={'5x'} color={'inherit'} />
      </Backdrop>
      <DialogTitle id="confirm-title">{trans('SCHOOL_DISTRICTS.ASSIGNMENT_DIALOG.TITLE')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{trans('SCHOOL_DISTRICTS.ASSIGNMENT_DIALOG.BODY.DESCRIPTION')}</DialogContentText>
        <DialogContentText style={{ marginTop: '20px', marginLeft: '20px', fontWeight: 'bold' }}>
          {trans('SCHOOL_DISTRICTS.ASSIGNMENT_DIALOG.BODY.SCHOOL_YEARS')}
        </DialogContentText>
        <Grid container spacing={2}>
          {schoolBirthYears.map((birthYear) => {
            const errorClass = databaseError[birthYear] ? classes.hasError : '';
            return (
              <React.Fragment key={`fragment-${birthYear}`}>
                <Grid item xs={1} />
                <Grid item xs={3}>
                  <DialogContentText className={errorClass}>{birthYear}</DialogContentText>
                </Grid>
                <Grid item xs={4}>
                  <Select
                    labelId="demo-simple-select-label"
                    name={birthYear}
                    value={
                      selectedDatabase[birthYear]
                        ? selectedDatabase[birthYear]
                        : createAssignmentSelectedDatabaseDefault
                    }
                    label="Database"
                    onChange={handleChange}
                    defaultValue={createAssignmentSelectedDatabaseDefault}
                    className={errorClass}
                  >
                    {props.databases.map((database) => (
                      <MenuItem value={database.id} key={database.id}>
                        {database.name}
                      </MenuItem>
                    ))}
                    <MenuItem
                      value={createAssignmentSelectedDatabaseDefault}
                      key={createAssignmentSelectedDatabaseDefault}
                    >
                      {trans('SCHOOL_DISTRICTS.ASSIGNMENT_DIALOG.BODY.SELECT.DEFAULT_VALUE')}
                    </MenuItem>
                  </Select>
                  {databaseError[birthYear] && (
                    <FormHelperText className={errorClass}>
                      {trans('SCHOOL_DISTRICTS.ASSIGNMENT_DIALOG.BODY.SELECT.ERROR')}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={4} />
              </React.Fragment>
            );
          })}
        </Grid>
        <DialogContentText style={{ marginTop: '20px', marginLeft: '20px', fontWeight: 'bold' }}>
          {trans('SCHOOL_DISTRICTS.ASSIGNMENT_DIALOG.BODY.PRESCHOOL_YEARS')}
        </DialogContentText>
        <Grid container spacing={2}>
          {youngBirthYears.map((birthYear) => {
            const errorClass = databaseError[birthYear] ? classes.hasError : '';
            return (
              <React.Fragment key={`fragment-${birthYear}`}>
                <Grid item xs={1} />
                <Grid item xs={3}>
                  <DialogContentText className={errorClass}>{birthYear}</DialogContentText>
                </Grid>
                <Grid item xs={4}>
                  <Select
                    labelId="demo-simple-select-label"
                    name={birthYear}
                    value={
                      selectedDatabase[birthYear]
                        ? selectedDatabase[birthYear]
                        : createAssignmentSelectedDatabaseDefault
                    }
                    label="Database"
                    onChange={handleChange}
                    defaultValue={createAssignmentSelectedDatabaseDefault}
                    className={errorClass}
                  >
                    {props.databases.map((database) => (
                      <MenuItem value={database.id} key={database.id}>
                        {database.name}
                      </MenuItem>
                    ))}
                    <MenuItem
                      value={createAssignmentSelectedDatabaseDefault}
                      key={createAssignmentSelectedDatabaseDefault}
                    >
                      {trans('SCHOOL_DISTRICTS.ASSIGNMENT_DIALOG.BODY.SELECT.DEFAULT_VALUE')}
                    </MenuItem>
                  </Select>
                  {databaseError[birthYear] && (
                    <FormHelperText className={errorClass}>
                      {trans('SCHOOL_DISTRICTS.ASSIGNMENT_DIALOG.BODY.SELECT.ERROR')}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={4} />
              </React.Fragment>
            );
          })}
        </Grid>
        <FormControlLabel
          control={<Checkbox />}
          label={trans('SCHOOL_DISTRICTS.ASSIGNMENT_DIALOG.BODY.OFFICIAL_ASSIGNMENT_CHECKBOX.LABEL')}
          disabled={isOfficialDisabled}
          onChange={handleCheckboxChange}
          checked={isOfficial && !isOfficialDisabled}
        />
        <Tooltip title={trans('SCHOOL_DISTRICTS.ASSIGNMENT_DIALOG.BODY.OFFICIAL_ASSIGNMENT_CHECKBOX.TOOLTIP')}>
          <IconButton>
            <HelpOutline />
          </IconButton>
        </Tooltip>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose()} color="secondary" variant="outlined">
          {trans('SCHOOL_DISTRICTS.ASSIGNMENT_DIALOG.BUTTON.CANCEL')}
        </Button>
        <Button onClick={() => openProgressModal()} color="primary" variant="contained" disabled={!canCreateAssignment}>
          {trans('SCHOOL_DISTRICTS.ASSIGNMENT_DIALOG.BUTTON.CREATE')}
        </Button>
      </DialogActions>
      <ProgressModal
        title={trans('SCHOOL_DISTRICTS.ASSIGNMENT_MODAL.TITLE')}
        open={progressModalOpen}
        description={getProgressModalMessage()}
        progress={statusInfo.progress}
        error={error}
        button={
          error
            ? {
                action: handleProgressModalClose,
                label: trans('COMMON.CLOSE'),
              }
            : {
                action: handleOpenAssignment,
                label: trans('SCHOOL_DISTRICTS.ASSIGNMENT_MODAL.BUTTON'),
                disabled: !isStatusDone(statusInfo.status),
              }
        }
      />
    </Dialog>
  );
};

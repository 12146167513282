import { InstanceAddressType, OfficialSettings } from 'store/user/user.types';

export interface UserState {
  current: object;
  departmentSettings: {
    additionalCities: string[];
    additionalCommunes: string[];
    leadingAddress: InstanceAddressType;
    osinCommune: string;
    schoolyearThreshold: string;
    belSynchronizationEnabled: boolean;
    documentsCirculationSystem: string;
    catchmentAssignmentTransferEnabled: boolean;
    studentAddressSynchronizationEnabled: boolean;
    institutionalCoreGrantEnabled: boolean;
  };
  userSettings: OfficialSettings;
}

export const defaultState: UserState = {
  current: {},
  departmentSettings: {
    additionalCities: [],
    additionalCommunes: [],
    leadingAddress: InstanceAddressType.RESIDENCE,
    osinCommune: null,
    schoolyearThreshold: '0815',
    belSynchronizationEnabled: false,
    documentsCirculationSystem: null,
    catchmentAssignmentTransferEnabled: true,
    studentAddressSynchronizationEnabled: true,
    institutionalCoreGrantEnabled: false,
  },
  userSettings: {
    id: '',
    dcsLogin: null,
    role: null,
    permissions: {},
    permissionsReady: false,
    isExternalUser: true,
  },
};

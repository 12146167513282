import { GroupedOptionType } from 'components/shared/form/Autocomplete/Autocomplete.types';
import React, { FC, useRef } from 'react';
import { Form, SelectBox } from 'components/shared/form';
import { useTranslator } from 'components/shared/hooks/useTranslator/useTranslator';
import { toGroupedOptions } from 'utils/api';
import { useStyles } from './Toolbar.styles';
import { ToolbarProps, ToolbarFormModel } from './Toolbar.types';
import { FormikHelpers } from 'formik';

export const Toolbar: FC<ToolbarProps> = (props) => {
  const trans = useTranslator();
  const form = useRef(null);
  const classes = useStyles();

  const databaseGroupHeader = ({ label: databaseStatus }) => {
    return trans(`SCHOOL_DISTRICTS.DISTRICTS-LIST.DATABASES-TYPES.${databaseStatus.toUpperCase()}`);
  };

  const items = toGroupedOptions(props.databaseList, 'id', 'name', 'status');

  const handleDatabaseChange = (value: ToolbarFormModel, formik: FormikHelpers<ToolbarFormModel>) => {
    formik.setSubmitting(false);
    props.onSelectDatabase(value.database);
  };

  return (
    <div className={classes.toolbar}>
      <Form<ToolbarFormModel>
        initialValues={{ database: props.initialDatabaseId }}
        enableReinitialize={true}
        onSubmit={handleDatabaseChange}
        innerRef={form}
      >
        {({ isSubmitting, handleSubmit }) => (
          <SelectBox<GroupedOptionType>
            name="database"
            labelText={trans('SCHOOL_DISTRICTS.DISTRICTS-LIST.DATABASE')}
            placeholderText=""
            options={items}
            onChange={() => !isSubmitting && handleSubmit()}
            headerComponent={databaseGroupHeader}
          />
        )}
      </Form>
    </div>
  );
};

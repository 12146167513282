import { muiStyles } from 'utils/design';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { colors } from 'styles/colors';

interface StylesParams {
  ready: boolean;
  error: boolean;
}

export const useStyles = muiStyles<StylesParams>((theme) => ({
  '@keyframes loading': {
    from: { backgroundPosition: '0 0' },
    to: { backgroundPosition: '50px 50px' },
  },
  root: {
    height: '10px',
  },
  colorPrimary: {
    '&::after': {
      animation: '$loading 2s linear infinite',
      content: "' '",
      position: 'absolute',
      zIndex: 0,
      borderRadius: `10px`,
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundSize: '50px 50px',
      backgroundImage: `linear-gradient(
        -45deg,
        ${lighten(theme.palette.primary.main, 0.6)} 25%,
        ${lighten(theme.palette.primary.main, 0.8)} 25%,
        ${lighten(theme.palette.primary.main, 0.8)} 50%,
        ${lighten(theme.palette.primary.main, 0.6)} 50%,
        ${lighten(theme.palette.primary.main, 0.6)} 75%,
        ${lighten(theme.palette.primary.main, 0.8)} 75%,
        ${lighten(theme.palette.primary.main, 0.8)}
      )`,
    },
  },
  bar: {
    zIndex: 2,
    transition: 'transform .4s linear, background .4s',
  },
  barColorPrimary: {
    background: (params) => {
      if (params.error) {
        return colors.red;
      }

      if (params.ready) {
        return colors.green;
      }

      return theme.palette.primary.main;
    },
  },
}));

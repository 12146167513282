import { ForkEffect, fork } from 'redux-saga/effects';
import { authSaga } from 'store/auth';
import { institutionSaga } from './institutions';
import { reportSaga } from './reports/reports.saga';
import { childRegisterSaga } from 'store/childRegister/childRegister.saga';

export function* rootSaga(): IterableIterator<ForkEffect> {
  yield fork(institutionSaga);
  yield fork(authSaga);
  yield fork(reportSaga);
  yield fork(childRegisterSaga);
}

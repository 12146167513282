import React, { FC, useEffect, useState } from 'react';
import {
  ALL_YEARS,
  CreateReportForm,
  ReportModalContainerProps,
} from 'components/schoolObligation/ReportModal/ReportModal.types';
import { ReportModal } from 'components/schoolObligation/ReportModal/ReportModal';
import { AppDispatch } from '@typings/redux';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBirthYearsSelector,
  fetchBirthYears,
  createSchoolObligationReport,
  ObligationReportPostModel,
} from 'store/schoolObligations';
import moment from 'moment';
import { Majority } from '@typings/common';

const mapFormValueToPayload = (formValues: CreateReportForm): ObligationReportPostModel => {
  return {
    ...formValues,
    birthYears: formValues.birthYears.filter((value) => value !== ALL_YEARS),
  };
};

export const ReportModalContainer: FC<ReportModalContainerProps> = (props) => {
  const initialValues: CreateReportForm = {
    referenceDate: moment().format('YYYY-MM-DD'),
    birthYears: [],
  };

  const dispatch: AppDispatch = useDispatch();
  const { list } = useSelector(getBirthYearsSelector);
  const [referenceDate, setReferenceDate] = useState<string>(initialValues.referenceDate);

  useEffect(() => {
    if (referenceDate) {
      dispatch(fetchBirthYears(Majority.MINOR, referenceDate));
    }
  }, [dispatch, referenceDate]);

  const onSave = (formValues: CreateReportForm) =>
    dispatch(createSchoolObligationReport(mapFormValueToPayload(formValues)));

  return (
    <ReportModal
      {...props}
      birthYears={list.map((year) => year.toString())}
      onSave={onSave}
      initialValues={initialValues}
      onReferenceDateUpdate={setReferenceDate}
    />
  );
};

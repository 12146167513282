import { connectRouter } from 'connected-react-router';
import { AnyAction, combineReducers, Reducer } from 'redux';
import { childRegisterReducer } from 'store/childRegister/childRegister.reducer';
import { pollsReducer } from 'store/polls/polls.reducers';
import { reportsReducer } from 'store/reports/reports.reducer';
import { templatesReducer } from 'store/templates';
import { userReducer } from 'store/user/user.reducer';
import { userListReducer } from 'store/userList/userList.reducer';
import { schoolDistrictsReducer } from 'store/schoolDistricts/schoolDistricts.reducer';
import { authReducer } from './auth';
import { institutionsReducer } from './institutions';
import { generalReducer } from './general';
import { AppState } from './store';
import { History } from 'history';
import { terytReducer } from './teryt';
import { schoolObligationReducer } from 'store/schoolObligations';
import { settingsReducer } from 'store/systemSettings';
import { officialsReducer } from 'store/officials';
import { accessRulesReducer } from 'store/accessRules';
import { searchReducer } from 'store/search/search.reducer';
import { applicantsReducer } from './applicants/applicants.reducer';

export const rootReducer: (history: History) => Reducer<AppState> = (history) => {
  const reducer = combineReducers({
    auth: authReducer,
    general: generalReducer,
    router: connectRouter(history),
    institutions: institutionsReducer,
    childRegister: childRegisterReducer,
    schoolDistricts: schoolDistrictsReducer,
    templates: templatesReducer,
    polls: pollsReducer,
    teryt: terytReducer,
    user: userReducer,
    userList: userListReducer,
    schoolObligations: schoolObligationReducer,
    reports: reportsReducer,
    settings: settingsReducer,
    officials: officialsReducer,
    accessRules: accessRulesReducer,
    search: searchReducer,
    applicants: applicantsReducer,
  });

  if (window.Cypress) {
    return (store: AppState, action: AnyAction) => {
      if (action.type === 'CYPRESS_STORE_MOCK') {
        return action.payload;
      } else {
        return reducer(store, action);
      }
    };
  } else {
    return reducer;
  }
};

import { useContext } from 'react';
import { LocaleContext } from 'context/locale';
import { getCurrentDate } from 'utils/date/currentDate';
import { DateFormat, UseFormatterHook } from './useFormatter.types';
import { camelCase } from 'change-case';
import { format as fnsFormat } from 'date-fns';
import { pl } from 'date-fns/locale';

const fnsLocales = { pl };

export const formatErrorKey = (key: string) =>
  key
    .replace(/\[/g, '.')
    .replace(/]/g, '')
    .split('.')
    .map((keyPart) => camelCase(keyPart))
    .join('.');

export const useFormatter: UseFormatterHook = () => {
  const { trans, formatDate: intlFormatDate, locale, formatNumber } = useContext(LocaleContext);

  const formatApiErrors = (errors: Record<string, any>) =>
    Object.keys(errors).reduce((mappedValues: Record<string, any>, key: string) => {
      mappedValues[formatErrorKey(key)] = trans(errors[key]);
      return mappedValues;
    }, {});

  const formatDate = (value: string | Date) => {
    return intlFormatDate(value, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  };

  const formatDateWithTime = (value: string | Date) => {
    return `${formatDate(value)} ${intlFormatDate(value, {
      hour: '2-digit',
      minute: '2-digit',
    })}`;
  };

  const formatTime = (value: string | Date) => {
    return `${intlFormatDate(value, {
      hour: '2-digit',
      minute: '2-digit',
    })}`;
  };

  const formatDateWithTemplate = (template: DateFormat, date: Date | string = getCurrentDate()): string => {
    const dateToFormat = date instanceof Date ? date : new Date(date);
    return fnsFormat(dateToFormat, template, { locale: fnsLocales[locale] });
  };

  return {
    formatApiErrors,
    formatDate,
    formatDateWithTime,
    formatDateWithTemplate,
    formatNumber,
    formatTime,
  };
};

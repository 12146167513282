import { AppDispatch } from '@typings/redux';
import { useNotification } from 'components/shared/hooks/useNotification/useNotification';
import { limits } from 'config/pagination';
import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ChildModel } from 'store/childRegister';
import {
  fetchAlertTypes,
  fetchBirthYears,
  clearChildRegisterData,
  deleteChildren,
  fetchChildRegister,
} from 'store/childRegister/childRegister.actions';
import {
  alertTypesSelector,
  childRegisterSelector,
  birthYearsSelector,
} from 'store/childRegister/childRegister.selectors';
import { Browser } from './Browser';
import { push } from 'connected-react-router';
import { makePath } from 'utils/navigation/makePath';
import { ROUTE_CHILD_REGISTER_EDIT } from 'config/routes';

export const BrowserContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { success, error } = useNotification();

  const { items, filters, total, alerts } = useSelector(childRegisterSelector);
  const { list: alertTypes } = useSelector(alertTypesSelector);

  useEffect(() => {
    dispatch(fetchAlertTypes());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchBirthYears(filters.majority));
  }, [dispatch, filters.majority]);

  useEffect(() => {
    dispatch(clearChildRegisterData());
  }, [dispatch, filters]);

  const { loading: alertTypesLoading } = useSelector(alertTypesSelector);
  const { loading: birthYearsLoading } = useSelector(birthYearsSelector);

  const editItem = (data) => {
    dispatch(push(makePath(ROUTE_CHILD_REGISTER_EDIT, { id: data.id })));
  };

  const deleteItems = (items: ChildModel[]) => {
    return dispatch(deleteChildren(items.map((child) => child.id)))
      .then(
        () => {
          dispatch(fetchBirthYears(filters.majority));
          success('CHILD_REGISTER.BROWSER.DELETE.SUCCESS');
        },
        () => {
          error('CHILD_REGISTER.BROWSER.DELETE.FAIL');
        },
      )
      .then(() => {
        dispatch(clearChildRegisterData());
      });
  };

  const fetchChildrenHandler = useCallback(
    (page) => {
      return dispatch(fetchChildRegister(filters, page));
    },
    [dispatch, filters],
  );

  return (
    <Browser
      alertTypes={alertTypes}
      alerts={alerts}
      filtersLoading={alertTypesLoading || birthYearsLoading}
      filters={filters}
      editItem={editItem}
      deleteItems={deleteItems}
      onChildrenProvide={useCallback((page) => items[page], [items])}
      onChildrenFetch={fetchChildrenHandler}
      limit={limits.childrenRegisterBrowser}
      total={total}
    />
  );
};

import { useStyles } from 'components/shared/form/Autocomplete/Autocomplete.styles';
import { OptionType } from 'components/shared/form/Autocomplete/Autocomplete.types';
import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon';
import React, { FC } from 'react';
import { IndicatorProps } from 'react-select';

export const ClearIndicator: FC<IndicatorProps<OptionType, boolean>> = (props) => {
  const classes = useStyles();

  return (
    <FontAwesomeIcon
      name={'times'}
      className={classes.clearIndicator}
      onClick={() => {
        props.clearValue();
      }}
      onMouseDown={(event) => {
        event.stopPropagation();
      }}
    />
  );
};

import React, { FC } from 'react';
import { SettingsRowProps } from './Settings.types';
import { useTranslator } from 'components/shared/hooks';

export const SettingsRow: FC<SettingsRowProps> = ({ children, label }) => {
  const trans = useTranslator();
  return (
    <div className="row pb-1">
      <div className="d-flex col-5 align-items-center">{trans(label)}</div>
      <div className="d-flex col-7 align-items-center">{children}</div>
    </div>
  );
};

import React, { FC, useEffect, useState } from 'react';
import { Dialog } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { isScreenLockSelector } from 'store/general';
import { Loader } from 'components/shared/loaders/Loader/Loader';
import { useStyles } from 'components/shared/loaders/InterfaceBlocker/InterfaceBlocker.styles';

export const InterfaceBlocker: FC = () => {
  const isScreenLock = useSelector(isScreenLockSelector);
  const classes = useStyles();
  const [showBlocker, setShowBlock] = useState<boolean>(isScreenLock);

  useEffect(() => {
    if (isScreenLock) {
      setShowBlock(true);
    } else {
      setTimeout(() => setShowBlock(false), 300);
    }
  }, [showBlocker, isScreenLock]);

  return (
    <Dialog open={showBlocker} classes={classes}>
      <Loader name={'spinner'} animation={'pulse'} size={'5x'} color={'white'} />
    </Dialog>
  );
};

import { createMuiTheme } from '@material-ui/core';
import { colors } from 'styles/colors';

export const osinTheme = createMuiTheme({
  typography: {
    htmlFontSize: 17,
  },
  palette: {
    background: {
      default: colors.neutralVeryLight,
    },
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    error: {
      main: colors.danger,
    },
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: colors.primary,
      },
    },
    MuiButton: {
      root: {
        color: colors.defaultButton,
      },
      outlined: {
        borderColor: colors.defaultOutlinedButtonBorder,
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '24px 24px 16px',
      },
    },
    MuiDialogContentText: {
      root: {
        color: colors.neutralVeryDark,
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'space-between',
      },
      spacing: {
        padding: '8px 24px 16px',
      },
    },
    MuiInput: {
      input: {
        height: 'auto',
      },
      underline: {
        '&.Mui-disabled:before': {
          borderBottom: `1px solid ${colors.neutral}`,
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '1em',
      },
    },
    MuiFormHelperText: {
      root: {
        '&.Mui-error': {
          marginTop: '8px',
          minHeight: '1em',
        },
      },
    },
    MuiMenu: {
      list: {
        '& > li': {
          minHeight: '3em',
        },
      },
    },
  },
});

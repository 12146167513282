import { TitleBar } from 'components/layout/questionnaire/TitleBar/TitleBar';
import { Main } from 'components/shared/layout/Main/Main';
import { ROUTE_QUESTIONNAIRES_FORM } from 'config/routes';
import React, { FC } from 'react';
import { Route, Switch } from 'react-router';
import { NotFoundView } from 'views/Errors/NotFound.view';
import { FormView } from 'views/Questionnaires/Form/Form.view';
import { useStyles } from 'views/Questionnaires/Questionnaires.styles';

export const QuestionnairesView: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TitleBar />
      <div className={classes.content}>
        <Main>
          <Switch>
            <Route path={ROUTE_QUESTIONNAIRES_FORM} component={FormView} />
            <Route component={NotFoundView} />
          </Switch>
        </Main>
      </div>
    </div>
  );
};

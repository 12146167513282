import { MessageValues } from '@typings/extra-react-intl';
import { AppDispatch } from '@typings/redux';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { OptionsObject } from 'notistack';
import { errorNotification, successNotification, infoNotification } from 'store/general';
import { UseNotification } from './useNotification.types';

export const useNotification: () => UseNotification = () => {
  const dispatch = useDispatch<AppDispatch>();

  const success = useCallback(
    (message: string, params: MessageValues = {}, options: OptionsObject = {}) =>
      dispatch(successNotification(message, params, options)),
    [dispatch],
  );
  const error = useCallback(
    (message: string, params: MessageValues = {}, options: OptionsObject = {}) =>
      dispatch(errorNotification(message, params, options)),
    [dispatch],
  );
  const info = useCallback(
    (message: string, params: MessageValues = {}, options: OptionsObject = {}) =>
      dispatch(infoNotification(message, params, options)),
    [dispatch],
  );

  return {
    success,
    error,
    info,
  };
};

import classNames from 'classnames';
import { useTranslator } from 'components/shared/hooks';
import React from 'react';
import { Page } from 'components/layout/panel/Page/Page';
import { Breadcrumb } from 'components/shared/navigation/Breadcrumbs/Breadcrumb';
import { RFC } from '@typings/extra-react-router';
import { ROUTE_CHILD_REGISTER, ROUTE_CHILD_REGISTER_EDIT } from 'config/routes';
import { formatRoute } from 'react-router-named-routes';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { EditorEditContainer } from 'components/childRegister/Editor/EditorEditContainer';
import { useStyles } from 'views/Panel/ChildRegister/ChildRegisterEdit/ChildRegisterEdit.styles';
import { ChildRegisterEditRoute } from 'config/routes.types';

export const ChildRegisterEdit: RFC<ChildRegisterEditRoute> = (props) => {
  const trans = useTranslator();
  const dispatch = useDispatch();
  const id = props.match.params.id;
  const classes = useStyles();

  const goBack = () => dispatch(push(ROUTE_CHILD_REGISTER));

  return (
    <Page title={trans('CHILD_REGISTER.BROWSER.TITLE')}>
      <Breadcrumb label={trans('BREADCRUMB.CHILD-REGISTER.EDIT')} to={formatRoute(ROUTE_CHILD_REGISTER_EDIT, { id })} />

      <div className={classNames('container-fluid', classes.container)}>
        <EditorEditContainer id={id} goBack={goBack} />
      </div>
    </Page>
  );
};

import { SchoolModel, SchoolAddressModel, DatabaseModel, AddressRuleFormModel } from 'store/schoolDistricts';

export const ADDRESS_RULE_CONFLICT_ALERT = 'CATCHMENT_SCHOOLS.ADDRESS_RULES_CONFLICT';

export interface DistrictsBrowserProps {
  addressesLoading: boolean;
  databasesLoading: boolean;
  schoolsLoading: boolean;
  addressesList: SchoolAddressModel[];
  databasesList: DatabaseModel[];
  schoolsList: SchoolModel[];
  school: SchoolModel;
  selectedDatabaseId: string;
  onSelectDatabase: (databaseId: string) => void;
  onAddItem: (values: AddressRuleFormModel) => Promise<any>;
  onSubmitSuccess: () => void;
  onEditItem: (id: string, values: AddressRuleFormModel) => Promise<any>;
  onDeleteItem: (id: string) => Promise<any>;
  onSelectSchool: (school: SchoolModel) => void;
  onAddDatabase: () => void;
  selectedDatabase: DatabaseModel;
}

export interface AlertsProps {
  counts: AlertCount[];
}

export interface AlertCount {
  id: Alert;
  count: number;
}

export enum Alert {
  MISSING_TERYT = 'ALERTS.CHILD_REGISTER.MISSING_TERYT',
  MISSING_ADDRESS = 'ALERTS.CHILD_REGISTER.MISSING_ADDRESS',
  INCOMPLETE_PERMANENT_ADDRESS = 'ALERTS.CHILD_REGISTER.INCOMPLETE_PERMANENT_ADDRESS',
  INCOMPLETE_RESIDENCE_ADDRESS = 'ALERTS.CHILD_REGISTER.INCOMPLETE_RESIDENCE_ADDRESS',
}

import React, { FC, useContext, Fragment } from 'react';
import { Route, Switch, Redirect } from 'react-router';

import { NotFoundView } from 'views/Errors/NotFound.view';
import { Breadcrumb } from 'components/shared/navigation/Breadcrumbs/Breadcrumb';
import { DatabaseListView } from 'views/Panel/SchoolDistricts/DatabaseList/DatabaseList.view';
import { DistrictsListView } from 'views/Panel/SchoolDistricts/DistrictsList/DistrictsList.view';

import {
  ROUTE_SCHOOL_CATCHMENT_ASSIGNMENT_PREVIEW,
  ROUTE_SCHOOL_DISTRICTS,
  ROUTE_SCHOOL_DISTRICTS_DATABASE,
  ROUTE_SCHOOL_DISTRICTS_LIST,
  ROUTE_SCHOOL_DISTRICTS_CATCHMENT_ASSIGNMENT_LIST,
} from 'config/routes';
import { LocaleContext } from 'context/locale';
import { CatchmentAssignmentPreview } from 'views/Panel/SchoolDistricts/CatchmentAssignmentPreview/CatchmentAssigmentPreview.view';
import { CatchmentAssignmentListView } from 'views/Panel/SchoolDistricts/CatchmentAssignmentList/CatchmentAssignmentListView.view';

export const SchoolDistrictsView: FC = () => {
  const { trans } = useContext(LocaleContext);

  return (
    <Fragment>
      <Breadcrumb label={trans('BREADCRUMB.SCHOOL-DISTRICTS.DISTRICTS')} static />

      <Switch>
        <Redirect path={ROUTE_SCHOOL_DISTRICTS} exact to={ROUTE_SCHOOL_DISTRICTS_DATABASE} />
        <Route exact path={ROUTE_SCHOOL_DISTRICTS_DATABASE} component={DatabaseListView} />
        <Route exact path={ROUTE_SCHOOL_DISTRICTS_LIST} component={DistrictsListView} />
        <Route exact path={ROUTE_SCHOOL_DISTRICTS_CATCHMENT_ASSIGNMENT_LIST} component={CatchmentAssignmentListView} />
        <Route path={ROUTE_SCHOOL_CATCHMENT_ASSIGNMENT_PREVIEW} component={CatchmentAssignmentPreview} />
        <Route component={NotFoundView} />
      </Switch>
    </Fragment>
  );
};

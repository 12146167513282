export enum ReportsActionType {
  FETCH_AUDIT_LOG_REQUEST = 'reports/FETCH_AUDIT_LOG_REQUEST',
  FETCH_AUDIT_LOG_SUCCESS = 'reports/FETCH_AUDIT_LOG_SUCCESS',
  FETCH_AUDIT_LOG_FAILURE = 'reports/FETCH_AUDIT_LOG_FAILURE',
  CLEAR_AUDIT_LOG_DATA = 'reports/CLEAR_AUDIT_LOG_DATA',
  FETCH_SCHOOL_STAT_REQUEST = 'reports/FETCH_SCHOOL_STAT_REQUEST',
  FETCH_SCHOOL_STAT_SUCCESS = 'reports/FETCH_SCHOOL_STAT_SUCCESS',
  FETCH_SCHOOL_STAT_FAILURE = 'reports/FETCH_SCHOOL_STAT_FAILURE',
  FETCH_SCHOOL_STAT_DETAILS_REQUEST = 'reports/FETCH_SCHOOL_STAT_DETAILS_REQUEST',
  FETCH_SCHOOL_STAT_DETAILS_SUCCESS = 'reports/FETCH_SCHOOL_STAT_DETAILS_SUCCESS',
  FETCH_SCHOOL_STAT_DETAILS_FAILURE = 'reports/FETCH_SCHOOL_STAT_DETAILS_FAILURE',
  FETCH_OTHER_COMMUNES_STATS_REQUEST = 'reports/FETCH_OTHER_COMMUNES_STATS_REQUEST',
  FETCH_OTHER_COMMUNES_STATS_SUCCESS = 'reports/FETCH_OTHER_COMMUNES_STATS_SUCCESS',
  FETCH_OTHER_COMMUNES_STATS_FAILURE = 'reports/FETCH_OTHER_COMMUNES_STATS_FAILURE',
  FETCH_OTHER_COMMUNES_STUDENTS_REQUEST = 'reports/FETCH_OTHER_COMMUNES_STUDENTS_REQUEST',
  FETCH_OTHER_COMMUNES_STUDENTS_SUCCESS = 'reports/FETCH_OTHER_COMMUNES_STUDENTS_SUCCESS',
  FETCH_OTHER_COMMUNES_STUDENTS_FAILURE = 'reports/FETCH_OTHER_COMMUNES_STUDENTS_FAILURE',
  CLEAR_OTHER_COMMUNES_STUDENTS = 'reports/CLEAR_OTHER_COMMUNES_STUDENTS',
  FETCH_SUBVENTION_FACTORS_REQUEST = 'reports/FETCH_SUBVENTION_FACTORS_REQUEST',
  FETCH_SUBVENTION_FACTORS_SUCCESS = 'reports/FETCH_SUBVENTION_FACTORS_SUCCESS',
  FETCH_SUBVENTION_FACTORS_FAILURE = 'reports/FETCH_SUBVENTION_FACTORS_FAILURE',
  FETCH_SYNCHRONIZATION_LOG_REQUEST = 'reports/FETCH_SYNCHRONIZATION_LOG_REQUEST',
  FETCH_SYNCHRONIZATION_LOG_SUCCESS = 'reports/FETCH_SYNCHRONIZATION_LOG_SUCCESS',
  FETCH_SYNCHRONIZATION_LOG_FAILURE = 'reports/FETCH_SYNCHRONIZATION_LOG_FAILURE',
  FETCH_SYNCHRONIZATION_LOG_DATA = 'reports/FETCH_SYNCHRONIZATION_LOG_DATA',
  SET_SYNCHRONIZATION_LOG_FILTERS = 'reports/SET_SYNCHRONIZATION_LOG_FILTERS',
  GET_SYNCHRONIZATION_LOG = 'reports/GET_SYNCHRONIZATION_LOG',
  SET_SYNCHRONIZATION_LOG_LAST_ITEM = 'reports/SET_SYNCHRONIZATION_LOG_LAST_ITEM',
  CLEAR_SYNCHRONIZATION_LOG_DATA = 'reports/CLEAR_SYNCHRONIZATION_LOG_DATA',
}

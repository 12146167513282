import { useStyles } from 'components/reports/SchoolStatDetails/SchoolStatDetails.styles';
import React from 'react';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import { StatDetailsTable } from 'store/reports/reports.types';
import { BarChartContainer } from '../BarChart/BarChartContainer';

interface StatChartProps {
  isLoading: boolean;
  table: StatDetailsTable | null;
  statName: string;
}

export const StatChart = ({ isLoading, table, statName }: StatChartProps) => {
  const classes = useStyles();

  return (
    <Paper innerSpacing={2} square={true} className={classes.paper}>
      <BarChartContainer isLoading={isLoading} table={table} statName={statName} />
    </Paper>
  );
};

import { resolveText } from 'components/shared/charts/charts.helpers';
import { MaterialFlyout } from 'components/shared/charts/MaterialFlyout/MaterialFlyout';
import { WrappedLabel } from 'components/shared/charts/WrappedLabel/WrappedLabel';
import React from 'react';
import { measureText } from 'utils/string/measureText';
import { VictoryTooltip, VictoryTooltipProps } from 'victory';

export interface MaterialTooltipProps extends VictoryTooltipProps {
  dx?: number;
  dy?: number;
  textWidth?: number;
}

export const MaterialTooltipComponent = (props: MaterialTooltipProps) => {
  const text = resolveText(props.text, props);
  const { width, height } = measureText(text, props.textWidth);

  const tooltipPadding = 20;

  return (
    <VictoryTooltip
      {...props}
      text={text}
      flyoutWidth={width + tooltipPadding}
      flyoutHeight={height + tooltipPadding}
      flyoutComponent={<MaterialFlyout />}
      labelComponent={<WrappedLabel width={width} height={height} />}
      renderInPortal={true}
      constrainToVisibleArea
    />
  );
};

MaterialTooltipComponent.defaultEvents = VictoryTooltip['defaultEvents'];

export const MaterialTooltip = MaterialTooltipComponent;

import { FSA } from '@typings/redux';
import { limits } from 'config/pagination';
import { RSAA, RSAACreator } from 'redux-api-middleware';
import {
  ChildPostModel,
  ChildRegisterActionType,
  ChildRegisterActionType as ActionType,
  ChildRegisterFilters,
  childRegisterInitialFilters,
  FileImportTypes,
  ImportContextModel,
  ObligationAction,
  ObligationActionModel,
  ChildImportModel,
} from 'store/childRegister';
import { omit } from 'lodash';
import { toApiFormat } from 'utils/api';
import { Majority } from '@typings/common';
import { clean } from 'utils/object/clean';

export const fetchAlertTypes: RSAACreator<ActionType> = () => {
  return {
    [RSAA]: {
      endpoint: '/child-register/alerts',
      method: 'GET',
      types: [
        {
          type: ActionType.FETCH_ALERT_TYPES_REQUEST,
          meta: {
            takeLatest: true,
          },
        },
        ActionType.FETCH_ALERT_TYPES_SUCCESS,
        ActionType.FETCH_ALERT_TYPES_FAILURE,
      ],
      params: {},
    },
  };
};

export const fetchBirthYears: RSAACreator<ActionType> = (majority: Majority, referenceDate: string) => {
  return {
    [RSAA]: {
      endpoint: '/child-register/birth-years',
      method: 'GET',
      types: [
        {
          type: ActionType.FETCH_BIRTH_YEARS_REQUEST,
          meta: {
            takeLatest: true,
          },
        },
        ActionType.FETCH_BIRTH_YEARS_SUCCESS,
        ActionType.FETCH_BIRTH_YEARS_FAILURE,
      ],
      params: {
        referenceDate,
        filter: clean({ majority }),
      },
    },
  };
};

export const clearChildRegisterData = (): FSA<ActionType> => {
  return {
    type: ActionType.CLEAR_CHILD_REGISTER_DATA,
  };
};

export const fetchChildRegister: RSAACreator<ActionType> = (filters: ChildRegisterFilters, page: number) => {
  return {
    [RSAA]: {
      endpoint: '/child-register',
      method: 'GET',
      types: [
        {
          type: ActionType.FETCH_CHILD_REGISTER_REQUEST,
          meta: {
            takeLatest: true,
          },
        },
        {
          type: ActionType.FETCH_CHILD_REGISTER_SUCCESS,
          meta: { page },
        },
        ActionType.FETCH_CHILD_REGISTER_FAILURE,
      ],
      params: {
        search: filters.search,
        filter: {
          ...clean(omit(filters, ['search'])),
        },
        page,
        limit: limits.childrenRegisterBrowser,
      },
    },
  };
};

export const deleteChildren: RSAACreator<ActionType> = (ids: string[]) => {
  return {
    [RSAA]: {
      endpoint: `/child-register`,
      method: 'DELETE',
      types: [
        {
          type: ActionType.DELETE_CHILDREN_REQUEST,
          meta: {
            lockScreen: true,
          },
        },
        ActionType.DELETE_CHILDREN_SUCCESS,
        ActionType.DELETE_CHILDREN_FAILURE,
      ],
      body: { ids },
      params: {},
    },
  };
};

export const setChildRegisterFilters = (filters: ChildRegisterFilters): FSA<ActionType> => {
  return {
    type: ActionType.SET_CHILD_REGISTER_FILTERS,
    payload: filters,
  };
};

export const clearChildRegisterFilters = (): FSA<ActionType> => {
  return {
    type: ActionType.SET_CHILD_REGISTER_FILTERS,
    payload: childRegisterInitialFilters,
  };
};

export const postChild = (body: ChildPostModel) => {
  return {
    [RSAA]: {
      endpoint: '/child-register',
      method: 'POST',
      types: [ActionType.POST_CHILD_REQUEST, ActionType.POST_CHILD_SUCCESS, ActionType.FETCH_ALERT_TYPES_FAILURE],
      body,
      params: {},
    },
  };
};

export const fetchChildDetails: RSAACreator<ChildRegisterActionType> = (childId: string) => {
  return {
    [RSAA]: {
      endpoint: `/child-register/${childId}`,
      method: 'GET',
      types: [
        ActionType.FETCH_CHILD_DETAILS_REQUEST,
        ActionType.FETCH_CHILD_DETAILS_SUCCESS,
        ActionType.FETCH_CHILD_DETAILS_FAILURE,
      ],
      params: {},
    },
  };
};

export const clearChildDetails = () => ({
  type: ActionType.CLEAR_CHILD_DETAILS,
});

export const putChild: RSAACreator<ChildRegisterActionType> = (body: ChildPostModel, childId: string) => {
  return {
    [RSAA]: {
      endpoint: `/child-register/${childId}`,
      method: 'PUT',
      types: [
        {
          type: ActionType.PUT_CHILD_REQUEST,
          meta: {
            lockScreen: true,
          },
        },
        ActionType.PUT_CHILD_SUCCESS,
        ActionType.PUT_CHILD_FAILURE,
      ],
      body,
      params: {},
    },
  };
};

export const fetchGuardianTypes: RSAACreator<ChildRegisterActionType> = () => {
  return {
    [RSAA]: {
      endpoint: `/child-register/guardian-types`,
      method: 'GET',
      types: [
        ActionType.FETCH_GUARDIAN_TYPES_REQUEST,
        ActionType.FETCH_GUARDIAN_TYPES_SUCCESS,
        {
          type: ActionType.FETCH_GUARDIAN_TYPES_FAILURE,
          meta: {
            ignoreNotFoundError: true,
          },
        },
      ],
      params: {},
    },
  };
};

export const setCustomGuardianTypes = (selection: any): FSA<ChildRegisterActionType> => {
  return {
    type: ActionType.SET_CUSTOM_GUARDIAN_TYPES,
    payload: selection,
  };
};

export const fetchAddressHistory = (id) => {
  return {
    [RSAA]: {
      endpoint: `/child-register/${id}/address-history`,
      method: 'GET',
      types: [
        ActionType.FETCH_ADDRESS_HISTORY_REQUEST,
        ActionType.FETCH_ADDRESS_HISTORY_SUCCESS,
        ActionType.FETCH_ADDRESS_HISTORY_FAILURE,
      ],
      params: {},
    },
  };
};

export const fetchObligationReports = (id) => {
  return {
    [RSAA]: {
      endpoint: `/child-register/${id}/obligation-reports`,
      method: 'GET',
      types: [
        ActionType.FETCH_OBLIGATION_REPORTS_REQUEST,
        ActionType.FETCH_OBLIGATION_REPORTS_SUCCESS,
        ActionType.FETCH_OBLIGATION_REPORTS_FAILURE,
      ],
      params: {},
    },
  };
};

export const fetchObligationActions: RSAACreator<ActionType> = (id) => {
  return {
    [RSAA]: {
      endpoint: `/child-register/${id}/obligation-actions`,
      method: 'GET',
      types: [
        ActionType.FETCH_OBLIGATION_ACTIONS_REQUEST,
        ActionType.FETCH_OBLIGATION_ACTIONS_SUCCESS,
        ActionType.FETCH_OBLIGATION_ACTIONS_FAILURE,
      ],
      params: {},
    },
  };
};

export const clearObligationActions = (): FSA<ActionType> => {
  return {
    type: ActionType.CLEAR_OBLIGATION_ACTIONS,
  };
};

export const postObligationAction = (body: ObligationActionModel, id: string) => {
  return {
    [RSAA]: {
      endpoint: `/child-register/${id}/obligation-actions`,
      method: 'POST',
      types: [
        ActionType.POST_OBLIGATION_ACTION_REQUEST,
        {
          type: ActionType.POST_OBLIGATION_ACTION_SUCCESS,
          meta: {
            childId: id,
          },
        },
        ActionType.POST_OBLIGATION_ACTION_FAILURE,
      ],
      body,
      params: {},
    },
  };
};

export const putObligationAction = (body: ObligationAction, id: string) => {
  return {
    [RSAA]: {
      endpoint: `/child-register/${id}/obligation-actions/${body.id}`,
      method: 'PUT',
      types: [
        ActionType.PUT_OBLIGATION_ACTION_REQUEST,
        {
          type: ActionType.PUT_OBLIGATION_ACTION_SUCCESS,
          meta: {
            childId: id,
          },
        },
        ActionType.PUT_OBLIGATION_ACTION_FAILURE,
      ],
      body,
      params: {},
    },
  };
};

export const deleteObligationAction = (childId: string, id: string) => {
  return {
    [RSAA]: {
      endpoint: `/child-register/${childId}/obligation-actions/${id}`,
      method: 'DELETE',
      types: [
        ActionType.DELETE_OBLIGATION_ACTION_REQUEST,
        {
          type: ActionType.DELETE_OBLIGATION_ACTION_SUCCESS,
          meta: {
            childId,
          },
        },
        ActionType.DELETE_OBLIGATION_ACTION_FAILURE,
      ],
      params: {},
    },
  };
};

export const clearEducationPath = (): FSA<ActionType> => {
  return {
    type: ActionType.CLEAR_EDUCATION_PATH,
  };
};

export const fetchEducationPath: RSAACreator<ActionType> = (identificationNumber, identificationType) => {
  return {
    [RSAA]: {
      endpoint: `/child-register/${identificationNumber}/${identificationType}/education-path`,
      method: 'GET',
      types: [
        ActionType.FETCH_EDUCATION_PATH_REQUEST,
        ActionType.FETCH_EDUCATION_PATH_SUCCESS,
        ActionType.FETCH_EDUCATION_PATH_FAILURE,
      ],
      params: {},
    },
  };
};

export const postImportFile = (file: string | File, type: FileImportTypes) => {
  const formData = new FormData();
  formData.append('import_file', file);
  formData.append('type', type);

  return {
    [RSAA]: {
      endpoint: `/child-register/import`,
      method: 'POST',
      types: [
        {
          type: ActionType.POST_IMPORT_FILE_REQUEST,
          meta: {
            lockScreen: true,
          },
        },
        {
          type: ActionType.POST_IMPORT_FILE_SUCCESS,
          meta: {
            file: file,
            importType: type,
          },
        },
        ActionType.POST_IMPORT_FILE_FAILURE,
      ],
      body: formData,
      params: {},
    },
  };
};

export const deleteImport = (token: string) => {
  return {
    [RSAA]: {
      endpoint: `/child-register/import/${token}`,
      method: 'DELETE',
      types: [
        {
          type: ActionType.DELETE_IMPORT_REQUEST,
          meta: {
            lockScreen: true,
          },
        },
        ActionType.DELETE_IMPORT_SUCCESS,
        {
          type: ActionType.DELETE_IMPORT_FAILURE,
        },
      ],
      params: {},
    },
  };
};

export const fetchImportColumns = (token: string) => {
  return {
    [RSAA]: {
      endpoint: `/child-register/import/${token}/header`,
      method: 'GET',
      types: [
        ActionType.FETCH_IMPORT_COLUMNS_REQUEST,
        ActionType.FETCH_IMPORT_COLUMNS_SUCCESS,
        {
          type: ActionType.FETCH_IMPORT_COLUMNS_FAILURE,
          meta: {
            ignoreNotFoundError: true,
          },
        },
      ],
      params: {},
    },
  };
};

export const postImportContext = (token: string, form: ImportContextModel) => {
  return {
    [RSAA]: {
      endpoint: `/child-register/import/${token}/context`,
      method: 'POST',
      types: [
        {
          type: ActionType.POST_IMPORT_CONTEXT_REQUEST,
          meta: {
            lockScreen: true,
          },
        },
        {
          type: ActionType.POST_IMPORT_CONTEXT_SUCCESS,
          meta: {
            context: form,
          },
        },
        ActionType.POST_IMPORT_CONTEXT_FAILURE,
      ],
      body: toApiFormat(form),
      params: {},
    },
  };
};

export const startImportPreview = (token: string) => {
  return {
    [RSAA]: {
      endpoint: `/child-register/import/${token}/preview`,
      method: 'GET',
      types: [
        ActionType.FETCH_IMPORT_PREVIEW_START_REQUEST,
        ActionType.FETCH_IMPORT_PREVIEW_START_SUCCESS,
        ActionType.FETCH_IMPORT_PREVIEW_START_FAILURE,
      ],
      params: {},
    },
  };
};

export const fetchImportPreview = (token: string) => {
  return {
    [RSAA]: {
      endpoint: `/child-register/import/${token}/preview`,
      method: 'GET',
      types: [
        ActionType.FETCH_IMPORT_PREVIEW_REQUEST,
        ActionType.FETCH_IMPORT_PREVIEW_SUCCESS,
        {
          type: ActionType.FETCH_IMPORT_PREVIEW_FAILURE,
          meta: {
            ignoreNotFoundError: true,
          },
        },
      ],
      params: {},
    },
  };
};

export const fetchImportPreviewStatus = (token: string) => {
  return {
    [RSAA]: {
      endpoint: `/child-register/import/${token}/status`,
      method: 'GET',
      types: [
        ActionType.FETCH_IMPORT_PREVIEW_STATUS_REQUEST,
        ActionType.FETCH_IMPORT_PREVIEW_STATUS_SUCCESS,
        {
          type: ActionType.FETCH_IMPORT_PREVIEW_STATUS_FAILURE,
          meta: {
            ignoreNotFoundError: true,
          },
        },
      ],
      params: {},
    },
  };
};

export const postImportConfirm = (token: string) => {
  return {
    [RSAA]: {
      endpoint: `/child-register/import/${token}/confirm`,
      method: 'POST',
      types: [
        {
          type: ActionType.POST_IMPORT_CONFIRM_REQUEST,
          meta: {
            lockScreen: true,
          },
        },
        ActionType.POST_IMPORT_CONFIRM_SUCCESS,
        {
          type: ActionType.POST_IMPORT_CONFIRM_FAILURE,
          meta: {
            ignoreNotFoundError: true,
          },
        },
      ],
      params: {},
    },
  };
};

export const fetchImportStatus = (token: string) => {
  return {
    [RSAA]: {
      endpoint: `/child-register/import/${token}/status`,
      method: 'GET',
      types: [
        ActionType.FETCH_IMPORT_STATUS_REQUEST,
        ActionType.FETCH_IMPORT_STATUS_SUCCESS,
        {
          type: ActionType.FETCH_IMPORT_STATUS_FAILURE,
          meta: {
            ignoreNotFoundError: true,
          },
        },
      ],
      params: {},
    },
  };
};

export const clearImportInformation = () => ({
  type: ActionType.CLEAR_IMPORT_INFORMATION,
});

export const mapImportFields = (map: ChildImportModel) => ({
  type: ActionType.MAP_IMPORT_FIELDS,
  payload: map,
});

import { YearsStatistic } from '@typings/common';
import { ReportOverviewRow } from 'components/schoolObligation/ReportDetails/ReportOverview/ReportOverview.types';
import { ObligationReportDetails, ReportCounter } from 'store/schoolObligations';

export const getOverviewData = (report: ObligationReportDetails): ReportOverviewRow[] => {
  const sumStatistic = (statistic: YearsStatistic): number => {
    return report.birthYears.reduce((sum, year) => {
      return sum + statistic[year];
    }, 0);
  };

  const getReportRow = ({ name, years }: ReportCounter, parentPath: string[] = []): ReportOverviewRow => {
    return { name, years, sum: sumStatistic(years), level: parentPath.length, path: [...parentPath, name] };
  };

  const flattenNodes = (nodes: ReportCounter[], parentPath: string[] = []) => {
    return nodes.reduce((result, item) => {
      const row = getReportRow(item, parentPath);
      return [...result, row, ...flattenNodes(item.nodes, row.path)];
    }, []);
  };

  return [...flattenNodes(report.counters.nodes), getReportRow(report.counters)];
};

import { Box, Tooltip } from '@material-ui/core';
import { useFormatter, useTranslator } from 'components/shared/hooks';
import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon';
import React, { FC } from 'react';
import { isFunction } from 'lodash';
import { IconDefinition } from 'components/shared/data/DataGrid/renderers/cell/Icons/Icons.types';
import { TextWithIconsProps } from 'components/shared/data/DataGrid/renderers/cell/TextWithIcons/TextWithIcons.types';

export const TextWithIconsCellRenderer: FC<TextWithIconsProps> = ({
  data,
  value,
  text,
  icons,
  alignIcon = 'right',
  disableTranslation = false,
}) => {
  const trans = useTranslator();
  const formatter = useFormatter();

  const formatTooltip = (tooltip) => {
    if (isFunction(tooltip)) {
      return tooltip(trans, formatter);
    } else if (tooltip) {
      return trans(tooltip);
    } else {
      return '';
    }
  };

  const alignLeft = alignIcon === 'left' && icons(data).length > 0;
  const alignRight = alignIcon === 'left' && icons(data).length > 0;

  const renderIcon = (iconDefinition: IconDefinition, index: number) => {
    return (
      <Tooltip title={formatTooltip(iconDefinition.tooltip)} placement="top" key={index}>
        <Box component="span" marginLeft={alignRight ? 0.5 : 0} marginRight={alignLeft ? 0.5 : 0}>
          <FontAwesomeIcon name={iconDefinition.icon} fixedWidth={true} fixedHeight={true} />
        </Box>
      </Tooltip>
    );
  };

  return (
    <Box
      display="flex"
      flexDirection={alignLeft ? 'row-reverse' : 'row'}
      justifyContent={alignLeft ? 'flex-end' : undefined}
    >
      {text ? (disableTranslation ? text(data) : trans(text(data))) : value}{' '}
      {icons(data).map((icon, index) => renderIcon(icon, index))}
    </Box>
  );
};

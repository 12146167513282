import React, { FC, useState } from 'react';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import { emptyManualStudentRegisterEntry } from 'components/childRegister/Editor/Obligation/parts/ManualEntries/ManualEntries.helpers';
import { useStyles } from 'components/childRegister/Editor/Obligation/parts/ManualEntries/ManualEntries.styles';
import { ManualEntriesProps } from 'components/childRegister/Editor/Obligation/parts/ManualEntries/ManualEntries.types';
import { Button } from 'components/shared/buttons/Button/Button';
import { DatePicker, SelectBox } from 'components/shared/form';
import { Autocomplete } from 'components/shared/form/Autocomplete/Autocomplete';
import { FieldArray } from 'components/shared/form/FieldArray/FieldArray';
import { useTranslator } from 'components/shared/hooks';
import { Heading } from 'components/shared/typography/Heading/Heading';

export const ManualEntries: FC<ManualEntriesProps> = ({
  institutions,
  collapsed,
  createInitialManualRegisterEntry,
}) => {
  const trans = useTranslator();
  const classes = useStyles();
  const [showManualEntries, setShowManualEntries] = useState(collapsed);

  return (
    <Box>
      <Box paddingBottom={2} display="flex" alignItems="center" justifyContent="space-between">
        <Heading type="h5">{trans('CHILD_REGISTER.OBLIGATION.MANUAL_ENTRIES.TITLE')}</Heading>
        <Button
          onClick={() => {
            createInitialManualRegisterEntry();
            setShowManualEntries((prevState) => !prevState);
          }}
          color="primary"
          variant="contained"
          className="mt-2"
        >
          {trans('CHILD_REGISTER.OBLIGATION.MANUAL_ENTRIES.ADD_MANUAL_ENTRY')}
        </Button>
      </Box>
      <Collapse in={showManualEntries}>
        <FieldArray name="manualStudentRegisterEntries">
          {({ form, push, remove }) => {
            return (
              <>
                <div>
                  {form.values.manualStudentRegisterEntries.map((manualEntry: any, index) => {
                    return (
                      <div key={`manualEntry_${index}`} className={classes.row}>
                        <div className={classes.rowYear}>
                          <Autocomplete
                            name={`manualStudentRegisterEntries.${index}.unitId`}
                            labelText={trans('CHILD_REGISTER.OBLIGATION.MANUAL_ENTRIES.FIELDS.UNIT')}
                            placeholder={trans('CHILD_REGISTER.OBLIGATION.MANUAL_ENTRIES.FIELDS.UNIT_PLACEHOLDER')}
                            options={institutions.map((institution) => ({
                              value: institution.id,
                              label: institution.name,
                              options: institution.units.map((unit) => ({ label: unit.name, value: unit.id })),
                            }))}
                            findOption={(option, value) => {
                              return !!option.options.find((item: any) => item.value === value);
                            }}
                          />
                        </div>
                        <div className={classes.rowYear}>
                          <DatePicker
                            name={`manualStudentRegisterEntries.${index}.date`}
                            labelText={trans('CHILD_REGISTER.OBLIGATION.MANUAL_ENTRIES.FIELDS.DATE')}
                          />
                        </div>
                        <div className={classes.rowDescription}>
                          <SelectBox
                            name={`manualStudentRegisterEntries.${index}.level`}
                            labelText={trans('CHILD_REGISTER.OBLIGATION.MANUAL_ENTRIES.FIELDS.LEVEL')}
                            placeholderText={trans('CHILD_REGISTER.OBLIGATION.MANUAL_ENTRIES.FIELDS.LEVEL')}
                            options={[...Array(9).keys()].map((item) => ({ label: String(item), value: String(item) }))}
                          />
                        </div>
                        <div className={classes.rowButton}>
                          <Button onClick={() => remove(index)} color="primary" variant="outlined">
                            {trans('CHILD_REGISTER.OBLIGATION.MANUAL_ENTRIES.REMOVE')}
                          </Button>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div>
                  <Button
                    onClick={() => push(emptyManualStudentRegisterEntry())}
                    color="primary"
                    variant="outlined"
                    className="mt-2"
                  >
                    {trans('CHILD_REGISTER.OBLIGATION.MANUAL_ENTRIES.ADD_ANOTHER')}
                  </Button>
                </div>
              </>
            );
          }}
        </FieldArray>
      </Collapse>
    </Box>
  );
};

import { FormHelperText } from '@material-ui/core';
import { useStyles } from 'components/polls/Editor/Question/OptionsList/OptionsList.styles';
import { OptionsListProps } from 'components/polls/Editor/Editor.types';
import { QuestionOption } from 'components/polls/Editor/Question/QuestionOption/QuestionOption';
import { Button } from 'components/shared/buttons/Button/Button';
import { Confirm } from 'components/shared/dialogs/Confirm/Confirm';
import { FieldArray } from 'components/shared/form/FieldArray/FieldArray';
import { useTranslator } from 'components/shared/hooks';
import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon';
import React, { FC, useState } from 'react';
import { getError } from 'utils/form/getErrors/getError';
import classNames from 'classnames';

export const OptionsList: FC<OptionsListProps> = ({ field, index: questionIndex, inputPreview }) => {
  const classes = useStyles();
  const trans = useTranslator();
  const [itemToRemove, setItemToRemove] = useState<number>(null);

  return (
    <div className={classes.list} data-testid="options">
      <FieldArray name={`${field}.options`}>
        {({ form, push, remove }) => {
          const optionsListError = getError(form.errors, `${field}.options`);

          return (
            <div className={classNames({ hasValidationError: !!optionsListError })}>
              {form.values.questions[questionIndex].options.map((option, index) => (
                <QuestionOption
                  key={index}
                  field={`${field}.options.${index}`}
                  inputPreview={inputPreview}
                  onRemove={() => setItemToRemove(index)}
                />
              ))}

              <Button
                onClick={() => push({ value: '' })}
                color="default"
                variant="outlined"
                className={classes.addButton}
                size="small"
                data-testid="addOption"
              >
                <FontAwesomeIcon name={'plus-circle'} className={'mr-2'} />
                {trans('POLLS.EDITOR.ACTIONS.ADD_OPTION')}
              </Button>

              <div className={classes.error}>
                <FormHelperText error>{optionsListError}</FormHelperText>
              </div>

              <Confirm
                open={itemToRemove !== null}
                confirm={() => {
                  setItemToRemove(null);
                  remove(itemToRemove);
                }}
                cancel={() => setItemToRemove(null)}
                title={'POLLS.EDITOR.ACTIONS.REMOVE_OPTION.DIALOG_TITLE'}
                message={'POLLS.EDITOR.ACTIONS.REMOVE_OPTION.DIALOG_MESSAGE'}
              />
            </div>
          );
        }}
      </FieldArray>
    </div>
  );
};

import { AppAction, AppMiddleware } from '@typings/redux';
import { isRSAA } from 'redux-api-middleware';
import { isFunction } from 'lodash';
import { downloadContent } from 'utils/files/download';

export const fileDownloadMiddleware: AppMiddleware = () => (next) => (action: AppAction) => {
  if (!isRSAA(action) && !isFunction(action) && action.meta && action.meta.downloadResponse) {
    downloadContent(action.payload.fileName, action.payload.content, action.payload.contentType);
  }

  return next(action);
};

import { ColumnsConfig } from 'components/shared/data/DataGrid/DataGrid.types';
import { BrowserColumnParams, Official, OfficialAccountType } from 'store/officials';
import { compact } from 'lodash';

type Config = ColumnsConfig<Official, BrowserColumnParams>;

const canDelete = (item: Official, officialId) => {
  return item.id !== officialId;
};

const accessModifyApplicant = (parameters: BrowserColumnParams, item: Official) => {
  return !(item.role === OfficialAccountType.APPLICANT && !parameters.institutionalCoreGrantEnabled);
};

export const officialsBrowserColumns: Config = (parameters) => [
  {
    field: 'firstName',
    name: 'OFFICIALS.BROWSER.COLUMNS.FIRSTNAME',
    columnParams: { minWidth: 150 },
  },
  {
    field: 'lastName',
    name: 'OFFICIALS.BROWSER.COLUMNS.LASTNAME',
    columnParams: { minWidth: 150 },
  },
  {
    field: 'email',
    name: 'OFFICIALS.BROWSER.COLUMNS.EMAIL',
    columnParams: { minWidth: 150 },
  },
  {
    field: 'formatPermissions',
    name: 'OFFICIALS.BROWSER.COLUMNS.PERMISSIONS',
    columnParams: { minWidth: 255 },
  },
  {
    field: 'login',
    name: 'OFFICIALS.BROWSER.COLUMNS.LOGIN',
    columnParams: { minWidth: 90 },
  },
  {
    field: 'id',
    name: 'OFFICIALS.BROWSER.COLUMNS.ACTIONS',
    cellTooltip: () => '',
    renderer: 'buttonsCell',
    rendererParams: {
      buttons: (item: Official) =>
        compact([
          accessModifyApplicant(parameters, item) && {
            type: 'default',
            size: 'small',
            variant: 'outlined',
            icon: 'pencil-alt',
            label: 'COMMON.EDIT',
            tooltip: 'COMMON.EDIT',
            action: parameters.edit,
          },
          {
            type: 'default',
            size: 'small',
            variant: 'outlined',
            label: 'OFFICIALS.BROWSER.CHANGE_PASSWORD',
            tooltip: 'OFFICIALS.BROWSER.CHANGE_PASSWORD',
            action: parameters.resetPassword,
          },
          canDelete(item, parameters.officialId) && {
            type: 'default',
            size: 'small',
            variant: 'outlined',
            icon: 'trash',
            label: 'COMMON.DELETE',
            tooltip: 'COMMON.DELETE',
            action: parameters.delete,
          },
        ]),
    },
    columnParams: { minWidth: 325 },
  },
];

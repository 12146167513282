import 'whatwg-fetch';
import React, { ComponentType } from 'react';
import ReactDOM from 'react-dom';
import { loadAppConfiguration } from 'utils/config/appConfig';
import App from './App';
// import * as serviceWorker from './serviceWorker';

function render(AppComponent: ComponentType) {
  ReactDOM.render(<AppComponent />, document.getElementById('root'));
}

loadAppConfiguration('/config.json').then(() => {
  render(App);
});

if ((module as any).hot) {
  (module as any).hot.accept('./App', () => {
    const NextApp = require('./App').default;
    render(NextApp);
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

import { useStyles } from 'components/shared/surfaces/Paper/Paper.styles';
import { PaperProps } from 'components/shared/surfaces/Paper/Paper.types';
import React from 'react';
import { Paper as MuiPaper } from '@material-ui/core';
import classNames from 'classnames';

const PaperComponent = (props: PaperProps, ref) => {
  const classes = useStyles(props);

  return (
    <MuiPaper
      className={classNames(classes.paper, props.className)}
      elevation={props.shadow}
      square={props.square}
      ref={ref}
    >
      {props.children}
    </MuiPaper>
  );
};

PaperComponent.displayName = 'Paper';
export const Paper = React.forwardRef(PaperComponent);

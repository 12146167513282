import useTheme from '@material-ui/core/styles/useTheme';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isSidebarOpenSelector } from 'store/general';

export const useSidebarOpenEffect = (effect: (isSidebarOpen: boolean) => void) => {
  const theme = useTheme();
  const fittingDelay = theme.transitions.duration.leavingScreen;
  const isSidebarOpen = useSelector(isSidebarOpenSelector);

  useEffect(() => {
    setTimeout(() => effect(isSidebarOpen), fittingDelay);
  }, [isSidebarOpen, effect, fittingDelay]);
};

import { AppState } from 'store/store';

export const pollsSelector = (state: AppState) => state.polls.list;
export const pollDetailsSelector = (state: AppState) => state.polls.details;
export const syncInstitutionsSelector = (state: AppState) => state.polls.synchronizedInstitutions;
export const recipientsGroupsSelector = (state: AppState) => state.polls.recipientsGroups;
export const recipientsCountsSelector = (state: AppState) => state.polls.recipientsCounts;
export const pollResultsSelector = (state: AppState) => state.polls.results;
export const pollResponsesSelector = (state: AppState) => state.polls.responses;
export const pollRecipientsSelector = (state: AppState) => state.polls.recipients;
export const questionnaireSelector = (state: AppState) => state.polls.questionnaire;

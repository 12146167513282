import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { setSidebarOpening } from 'store/general/general.action';
import { AppState } from 'store/store';
import { TitleBar } from 'components/layout/panel/TitleBar/TitleBar';
import { TitleBarDispatchProps, TitleBarStateProps } from 'components/layout/panel/TitleBar/TitleBar.types';

const mapStateToProps = ({ general }: AppState): TitleBarStateProps => ({
  sidebarOpening: general.sidebarOpening,
});

const mapStateToDispatch = (dispatch): TitleBarDispatchProps => ({
  setSidebarStatus: (open) => dispatch(setSidebarOpening(open)),
});

export const TitleBarContainer = compose<React.ComponentClass>(connect(mapStateToProps, mapStateToDispatch))(TitleBar);

import React, { FC, useCallback, useState, useRef } from 'react';
import { Box, Container } from '@material-ui/core';
import { Search as SearchModel, SearchParams } from 'store/search/search.types';
import { limits } from 'config/pagination';
import { DataGrid, GridSize } from 'components/shared/data/DataGrid/DataGrid';
import { Toolbar } from 'components/search/Toolbar/Toolbar';
import { SearchProps } from 'components/search/Search.types';
import { DataGridApi } from 'components/shared/data/DataGrid/DataGrid.types';
import { searchColumns } from 'components/search/columns';
import { ReactComponent as EmptyStateIllustration } from 'resources/illustrations/search_child.svg';

export const Search: FC<SearchProps> = ({ searchList, isLoading, onSubmit }) => {
  const [gridApi, setGridApi] = useState<DataGridApi>(null);
  const [submitCounter, setSubmitCounter] = useState<number>(0);
  const toolbarRef = useRef(null);

  const handleSubmit = (values: SearchParams) => {
    setSubmitCounter((value) => ++value);
    onSubmit(values);
  };

  const onGridReadyHandler = useCallback(
    (grid: DataGridApi) => {
      setGridApi(grid);
    },
    [setGridApi],
  );

  const gridFocusHandler = () => {
    gridApi && gridApi.focusCell(0, 0);
  };
  return (
    <>
      <Toolbar
        onGridFocus={gridFocusHandler}
        ref={toolbarRef}
        onSubmit={handleSubmit}
        onFilterChange={() => setSubmitCounter(0)}
      />
      {submitCounter > 0 ? (
        <DataGrid<SearchModel>
          columns={searchColumns()}
          data={searchList}
          limit={limits.auditLog}
          isLoading={isLoading}
          masterDetail
          defaultExpand={0}
          detailCellRenderer={'searchDetailsCell'}
          onGridReady={onGridReadyHandler}
          getRowHeight={(params) => {
            if (params.node.detail) {
              return params.data.inChildRegister && params.data.inStudentRegister ? 520 : 268;
            }
            return GridSize.rowHeight;
          }}
        />
      ) : (
        <Container maxWidth="sm" style={{ flexGrow: 1 }}>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
            <Box display="flex" width={300} marginTop={4}>
              <EmptyStateIllustration />
            </Box>
          </Box>
        </Container>
      )}
    </>
  );
};

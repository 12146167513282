import { AccessRulesActionType as ActionType } from 'store/accessRules';

import { RSAA, RSAACreator } from 'redux-api-middleware';

export const fetchAccessRules: RSAACreator<ActionType> = () => {
  return {
    [RSAA]: {
      endpoint: '/access-rules',
      method: 'GET',
      types: [
        {
          type: ActionType.FETCH_ACCESS_RULES_REQUEST,
        },
        ActionType.FETCH_ACCESS_RULES_SUCCESS,
        ActionType.FETCH_ACCESS_RULES_FAILURE,
      ],
      params: {},
    },
  };
};

import { FontAwesomeIconProps } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon.types';
import { muiStyles } from 'utils/design';

export const useStyles = muiStyles<FontAwesomeIconProps>((theme) => ({
  icon: {
    color: ({ color }) => (theme.palette[color] ? theme.palette[color].main : color),
  },
  fixedHeight: {
    height: '1.25rem',
    lineHeight: '1.25rem',
  },
}));

import { limits } from 'config/pagination';
import React, { FC, useCallback, useEffect } from 'react';
import { ChildrenList } from 'components/schoolDistricts/AssignmentPreview/ChildrenList/ChildrenList';
import { ChildrenListContainerProps } from 'components/schoolDistricts/AssignmentPreview/ChildrenList/ChildrenList.types';
import {
  clearChildrenList,
  fetchAssignmentDetails,
  fetchChildrenList,
  getAssignmentDetailsSelector,
  getChildrenSelector,
} from 'store/schoolDistricts';
import { AppDispatch } from '@typings/redux';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { formatRoute } from 'react-router-named-routes';
import { ROUTE_SCHOOL_CATCHMENT_ASSIGNMENT_PREVIEW } from 'config/routes';

export const ChildrenListContainer: FC<ChildrenListContainerProps> = ({ id, schoolId }) => {
  const dispatch: AppDispatch = useDispatch();
  const { loading: assignmentLoading, data: assignmentData } = useSelector(getAssignmentDetailsSelector);
  const { items: childrenData, total } = useSelector(getChildrenSelector);

  useEffect(() => {
    dispatch(clearChildrenList());
    dispatch(fetchAssignmentDetails(id));
  }, [dispatch, id, schoolId]);

  const redirectToPreview = () => dispatch(push(formatRoute(ROUTE_SCHOOL_CATCHMENT_ASSIGNMENT_PREVIEW, { id: id })));

  const childrenFetchHandler = useCallback(
    (page) => {
      dispatch(fetchChildrenList(id, schoolId, page));
    },
    [schoolId, dispatch, id],
  );

  const childrenDataProvider = useCallback(
    (page) => {
      return childrenData[page];
    },
    [childrenData],
  );

  return (
    <ChildrenList
      assignmentData={assignmentData}
      onChildrenFetch={childrenFetchHandler}
      onChildrenProvide={childrenDataProvider}
      total={total}
      limit={limits.schoolDistrictsChildrenAssignmentList}
      isAssignmentLoading={assignmentLoading}
      schoolData={assignmentData && assignmentData.schools.find((school) => school.id === schoolId)}
      redirectToPreview={redirectToPreview}
      countStatusTitle="SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.CHILDREN_LIST.GRID_COUNT"
    />
  );
};

import { ChildRegisterFormValues } from 'components/childRegister/Editor/General/General.types';
import { CheckBox, InputText, SelectBox } from 'components/shared/form';
import { DatePicker } from 'components/shared/form/DatePicker/DatePicker';
import { useTranslator } from 'components/shared/hooks';
import { Heading } from 'components/shared/typography/Heading/Heading';
import { FormikProps } from 'formik';
import React, { FC } from 'react';
import { IdentificationNumberType, Sex } from 'store/childRegister';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@typings/redux';
import { clearEducationPath } from 'store/childRegister/childRegister.actions';

interface PersonalDataProps {
  form: FormikProps<ChildRegisterFormValues>;
}

export const PersonalData: FC<PersonalDataProps> = (props) => {
  const trans = useTranslator();
  const dispatch = useDispatch<AppDispatch>();

  return (
    <div className="container-fluid mt-4 pb-3">
      <Heading type="h5">{trans('CHILD_REGISTER.FORM.TITLE')}</Heading>
      <div className="row mt-4">
        <div className="col-3">
          <InputText name="firstName" labelText={trans('CHILD_REGISTER.FORM.FIRST_NAME.LABEL')} required />
        </div>
        <div className="col-3">
          <InputText name="secondName" labelText={trans('CHILD_REGISTER.FORM.SECOND_NAME.LABEL')} />
        </div>
        <div className="col-3">
          <InputText name="lastName" labelText={trans('CHILD_REGISTER.FORM.LAST_NAME.LABEL')} required />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-3">
          <SelectBox
            name="identificationNumberType"
            displayEmpty
            emptyValue="empty"
            resettable
            required
            labelText={trans('CHILD_REGISTER.FORM.IDENTIFICATION_NUMBER_TYPE.LABEL')}
            placeholderText={trans('CHILD_REGISTER.FORM.IDENTIFICATION_NUMBER_TYPE.VALUES.EMPTY')}
            options={[
              {
                value: IdentificationNumberType.PESEL,
                label: trans('CHILD_REGISTER.FORM.IDENTIFICATION_NUMBER_TYPE.VALUES.PESEL'),
              },
              {
                value: IdentificationNumberType.OTHER,
                label: trans('CHILD_REGISTER.FORM.IDENTIFICATION_NUMBER_TYPE.VALUES.OTHER'),
              },
            ]}
            onChange={(event) => {
              if (event.target.value === IdentificationNumberType.EMPTY) {
                props.form.setFieldValue('identificationNumber', null);
                dispatch(clearEducationPath());
              }
            }}
            onValueReset={() => {
              props.form.setFieldValue('identificationNumber', null);
              dispatch(clearEducationPath());
            }}
          />
        </div>
        <div className="col-3">
          <InputText
            name="identificationNumber"
            labelText={trans('CHILD_REGISTER.FORM.IDENTIFICATION_NUMBER.LABEL')}
            required={
              props.form.values && props.form.values.identificationNumberType !== IdentificationNumberType.EMPTY
            }
            disabled={
              props.form.values && props.form.values.identificationNumberType === IdentificationNumberType.EMPTY
            }
          />
        </div>
        <div className="col-3">
          <SelectBox
            name="sex"
            displayEmpty
            resettable
            placeholderText={trans('CHILD_REGISTER.FORM.SEX.VALUES.EMPTY')}
            labelText={trans('CHILD_REGISTER.FORM.SEX.LABEL')}
            options={[
              { value: Sex.MALE, label: trans('CHILD_REGISTER.FORM.SEX.VALUES.MALE') },
              { value: Sex.FEMALE, label: trans('CHILD_REGISTER.FORM.SEX.VALUES.FEMALE') },
            ]}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-3">
          <DatePicker name="birthday" labelText={trans('CHILD_REGISTER.FORM.BIRTHDAY.LABEL')} required />
        </div>
        <div className="col-3">
          <InputText name="birthCity" labelText={trans('CHILD_REGISTER.FORM.BIRTH_CITY.LABEL')} />
        </div>
        <div className="col-3">
          <CheckBox
            name={`isCatchmentChild`}
            disabled={true}
            labelText={trans('CHILD_REGISTER.FORM.CATCHMENT_CHILD.LABEL')}
            tooltip={trans('CHILD_REGISTER.FORM.CATCHMENT_CHILD.TOOLTIP')}
          />
        </div>
      </div>
    </div>
  );
};

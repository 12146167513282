import { requestAuth } from 'fetch/interceptors/requestAuth';
import { requestHost } from 'fetch/interceptors/requestHost';
import { createClient } from 'react-fetching-library';
import { Store } from 'redux';
import { AppState } from 'store/store';
import { getAppConfig } from 'utils/config/appConfig';
import { requestTokenRefresh } from 'fetch/interceptors/requestTokenRefresh';

export const getFetchClient = (store: Store<AppState>) => {
  const apiUrl = getAppConfig('OSIN20_API_URL');

  return createClient({
    requestInterceptors: [requestHost(apiUrl), requestTokenRefresh(apiUrl, store), requestAuth(store)],
    responseInterceptors: [],
  });
};

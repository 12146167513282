import { pause } from 'utils/function/pause';

const PANEL_SELECTOR = 'div[class*="MuiExpansionPanel-root"]';
const EXPANDER_SELECTOR = 'div[class*="MuiExpansionPanelSummary-expandIcon"]';

const expandContainingPanel = (node: HTMLElement): boolean => {
  const expansionPanel = node.closest(PANEL_SELECTOR) as HTMLElement;

  if (expansionPanel) {
    const isPanelExpanded = expansionPanel.classList.contains('Mui-expanded');
    const expandButton = expansionPanel.querySelector<HTMLElement>(EXPANDER_SELECTOR);

    if (!isPanelExpanded) {
      expandButton.click();
      return true;
    }
  }

  return false;
};

export const makeErrorsVisible = async () => {
  const errorNodes = document.getElementsByClassName('hasValidationError');

  const expansionResults = Array.from(errorNodes).map((node) => {
    return expandContainingPanel(node as HTMLElement);
  });

  if (expansionResults.some((result) => result)) {
    return pause(500);
  }
};

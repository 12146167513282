export const downloadUrl = (url: string, fileName: string) => {
  const link = document.createElement('a');

  if (link.download !== undefined) {
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    window.location.href = url;
  }
};

export const downloadContent = (fileName: string, content: string, contentType: string) => {
  const blob = new Blob([content], { type: contentType });

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, fileName);
    return;
  }

  const url = URL.createObjectURL(blob);
  downloadUrl(url, fileName);
};

import { PaperProps } from 'components/shared/surfaces/Paper/Paper.types';
import { getSpacing, muiStyles } from 'utils/design';

export const useStyles = muiStyles<PaperProps>((theme) => ({
  paper: {
    padding: ({ innerSpacing = 0 }) => getSpacing(theme, innerSpacing),
    margin: ({ outerSpacing = 0 }) => getSpacing(theme, outerSpacing),
    background: ({ background }) => (background ? background : 'white'),
  },
}));

import { AppDispatch } from '@typings/redux';
import { Page } from 'components/layout/panel/Page/Page';
import { ReportChildrenListContainer } from 'components/schoolObligation/ReportDetails/ReportChildrenList/ReportChildrenListContainer';
import { ReportOverviewContainer } from 'components/schoolObligation/ReportDetails/ReportOverview/ReportOverviewContainer';
import { ReportsDetailsContainer } from 'components/schoolObligation/ReportDetails/ReportsDetailsContainer';
import { useTranslator } from 'components/shared/hooks';
import { Breadcrumb } from 'components/shared/navigation/Breadcrumbs/Breadcrumb';
import { Box } from 'components/shared/surfaces/Box/Box';
import {
  ROUTE_SCHOOL_OBLIGATION_REPORT_DETAILS,
  ROUTE_SCHOOL_OBLIGATION_REPORT_DETAILS_OVERVIEW,
  ROUTE_SCHOOL_OBLIGATION_REPORT_DETAILS_CHILDREN_LIST,
  ROUTE_SCHOOL_OBLIGATION_REPORTS,
} from 'config/routes';
import { SchoolObligationReportDetailsRoute } from 'config/routes.types';
import { push } from 'connected-react-router';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { RFC } from '@typings/extra-react-router';
import { NotFoundView } from 'views/Errors/NotFound.view';

export const ReportDetailsView: RFC<SchoolObligationReportDetailsRoute> = (props) => {
  const trans = useTranslator();
  const reportId = props.match.params.id;
  const dispatch = useDispatch<AppDispatch>();

  const handleBack = () => dispatch(push(ROUTE_SCHOOL_OBLIGATION_REPORTS));

  return (
    <>
      <Breadcrumb label={trans('BREADCRUMB.SCHOOL_OBLIGATION.REPORTS')} to={ROUTE_SCHOOL_OBLIGATION_REPORTS} />

      <Page title={trans('SCHOOL_OBLIGATION.REPORT.TITLE')}>
        <Box margin={[0, 2, 2]} grow>
          <ReportsDetailsContainer reportId={reportId} onBack={handleBack}>
            <Switch>
              <Redirect
                path={ROUTE_SCHOOL_OBLIGATION_REPORT_DETAILS}
                to={ROUTE_SCHOOL_OBLIGATION_REPORT_DETAILS_OVERVIEW}
                exact
              />
              <Route
                exact
                path={ROUTE_SCHOOL_OBLIGATION_REPORT_DETAILS_OVERVIEW}
                component={(props) => (
                  <>
                    <Breadcrumb label={trans('BREADCRUMB.SCHOOL_OBLIGATION.REPORT_DETAILS')} static />
                    <ReportOverviewContainer {...props} />
                  </>
                )}
              />
              <Route
                exact
                path={ROUTE_SCHOOL_OBLIGATION_REPORT_DETAILS_CHILDREN_LIST}
                component={(props) => (
                  <>
                    <Breadcrumb label={trans('BREADCRUMB.SCHOOL_OBLIGATION.REPORT_DETAILS')} static />
                    <ReportChildrenListContainer {...props} />
                  </>
                )}
              />
              <Route component={NotFoundView} />
            </Switch>
          </ReportsDetailsContainer>
        </Box>
      </Page>
    </>
  );
};

import { useTimeout } from 'components/shared/hooks/useTimeout/useTimeout';
import { useState } from 'react';

export const useTimeoutSwitch = (delay: number) => {
  const [done, setDone] = useState(false);

  useTimeout(() => setDone(true), delay);

  return done;
};

import { BoxStylesParams } from 'components/shared/surfaces/Box/Box.types';
import { getSpacing, muiStyles } from 'utils/design';

export const useStyles = muiStyles<BoxStylesParams>((theme) => ({
  box: {
    margin: ({ margin }) => (margin ? getSpacing(theme, margin) : 0),
    padding: ({ padding }) => (padding ? getSpacing(theme, padding) : 0),
  },
  boxGrow: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
  },
}));

import { subventionForecastColumns } from 'components/reports/SubventionForecast/columns.config';
import { configEquals, getSubventionForecast } from 'components/reports/SubventionForecast/SubventionForecast.helpers';
import { useStyles } from 'components/reports/SubventionForecast/SubventionForecast.styles';
import {
  ForecastConfig,
  ForecastValues,
  SubventionForecastFormRow,
  SubventionForecastProps,
  SubventionForecastRow,
  SubventionForecastRowType,
} from 'components/reports/SubventionForecast/SubventionForecast.types';
import { initialValues } from 'components/reports/SubventionForecast/SubventionForecastContainer';
import { ActionButton } from 'components/shared/buttons/ActionButton/ActionButton';
import { DataGrid } from 'components/shared/data/DataGrid/DataGrid';
import { ManipulatorConfig } from 'components/shared/data/DataGrid/renderers/cell/ForecastModificator/ForecastManipulator.types';
import { useTranslator } from 'components/shared/hooks';
import { Box } from 'components/shared/surfaces/Box/Box';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import React, { useCallback, useEffect, useState } from 'react';

export const SubventionForecast = (props: SubventionForecastProps) => {
  const classes = useStyles();
  const [config, setConfig] = useState<ForecastConfig>(initialValues);
  const [values, setValues] = useState<ForecastValues>(null);
  const [gridApi, setGridApi] = useState(null);
  const trans = useTranslator();

  const onManipulatorChange = (data: SubventionForecastFormRow, value: ManipulatorConfig) => {
    setConfig((config) => {
      if (configEquals(config[data.configField], value)) {
        return config;
      } else {
        return { ...config, [data.configField]: value };
      }
    });
  };

  useEffect(() => {
    if (props.factors) {
      setValues(getSubventionForecast(props.factors, config));
    }
  }, [config, props.factors]);

  useEffect(() => {
    if (gridApi && values && props.structure) {
      Object.keys(values).forEach((valueField) => {
        const row = gridApi.getRowNode(valueField);
        row && row.setDataValue('forecast', values[valueField]);
      });

      if (gridApi.getPinnedBottomRowCount() === 2) {
        gridApi.getPinnedBottomRow(1).setDataValue('forecast', values.subvention);
      }
    }
  }, [gridApi, values, props.structure]);

  const onGridReady = useCallback(({ agGridApi }) => {
    setGridApi(agGridApi);
  }, []);

  const exportParams = {
    fileName: trans('REPORTS.SUBVENTION_FORECAST.EXPORT.FILENAME'),
    columnKeys: ['factor', 'sourceValue', 'forecast'],
  };

  const columns = subventionForecastColumns({ onManipulatorChange, sourceYear: props.year });

  const normalRows = props.structure.slice(0, props.structure.length - 2);
  const bottomRows = props.structure.slice(-2);

  return (
    <Box margin={[0, 2, 2]} grow>
      <div className="my-2 d-flex justify-content-end">
        <ActionButton
          color="default"
          label="REPORTS.SCHOOL_STAT_DETAILS.EXPORT.TITLE"
          disabled={props.isLoading || props.factors === null}
          actionList={[
            {
              action: () => gridApi && gridApi.exportDataAsCsv(exportParams),
              label: 'COMMON.EXPORT.FORMATS.CSV',
              icon: 'file-csv',
            },
            {
              action: () =>
                gridApi &&
                gridApi.exportDataAsExcel({
                  ...exportParams,
                  sheetName: trans('REPORTS.SUBVENTION_FORECAST.EXPORT.SHEET'),
                }),
              label: 'COMMON.EXPORT.FORMATS.EXCEL',
              icon: 'file-excel',
            },
          ]}
        />
      </div>
      <Paper innerSpacing={2} className={classes.paper}>
        <DataGrid<SubventionForecastRow>
          columns={columns}
          data={normalRows}
          pinnedBottomData={bottomRows}
          isLoading={props.isLoading}
          noResultsMessage={'REPORTS.SUBVENTION_FORECAST.NO_FACTORS'}
          getRowClass={({ data }) => {
            if (data.rowType === SubventionForecastRowType.HEADER) {
              return 'marked-row';
            }
            if (data.rowType === SubventionForecastRowType.RESULT && data.valueField === 'subvention') {
              return 'bolder-row';
            }
            return '';
          }}
          onGridReady={onGridReady}
          getRowNodeId={(data) => {
            if (data.rowType === SubventionForecastRowType.FORM) {
              return data.valueField;
            }
            if (data.rowType === SubventionForecastRowType.RESULT) {
              return data.valueField;
            }
            return data.factor;
          }}
        />
      </Paper>
    </Box>
  );
};

import { useTranslator } from 'components/shared/hooks';
import { useEnvironment } from 'components/shared/hooks/useEnvironment/useEnvironment';
import React from 'react';
import { useStyles } from 'components/shared/layout/Footer/Footer.styles';
import classNames from 'classnames';

export const Footer = () => {
  const { banner } = useEnvironment();

  const classes = useStyles({ bannerHeight: banner.imageHeight });
  const trans = useTranslator();

  return (
    <div className={classNames('container-fluid', classes.container)}>
      {banner.imageUrl && !banner.link && (
        <img src={banner.imageUrl} alt={trans('FOOTER.BANNER')} className={classes.image} />
      )}

      {banner.imageUrl && banner.link && (
        <a href={banner.link} target="_blank" rel="noopener noreferrer">
          <img src={banner.imageUrl} alt={trans('FOOTER.BANNER')} className={classes.image} />
        </a>
      )}
    </div>
  );
};

import { compose } from 'redux';
import { connect } from 'react-redux';
import { SettingsPart } from 'components/institution/Unit/UnitForm/parts/SettingsPart/SettingsPart';
import { DictionaryItem, SchoolDictionaryItem } from 'store/general/general.types';
import { SelectBoxItem } from 'components/shared/form/SelectBox/SelectBox.types';

const mapToSelectFormat = (dictionaries) =>
  dictionaries.map(
    (dictonary: DictionaryItem): SelectBoxItem<string> => ({
      label: dictonary.name,
      value: dictonary.id,
    }),
  );

const mapStateToProps = ({ general }, ownProps) => {
  const currentSchool = ownProps.props.values && ownProps.props.values.schoolType;
  const schoolTypesList = general.dictionaries.list.schoolType as SchoolDictionaryItem[];
  const schoolSubtypes = currentSchool
    ? schoolTypesList.find((school: SchoolDictionaryItem) => school.id === currentSchool).schoolSubtype
    : [];

  return {
    ...ownProps,
    schoolType: mapToSelectFormat(general.dictionaries.list.schoolType),
    schoolSubtype: mapToSelectFormat(schoolSubtypes),
    educationStage: mapToSelectFormat(general.dictionaries.list.educationStage),
    accreditation: mapToSelectFormat(general.dictionaries.list.accreditation),
    schoolCharacter: mapToSelectFormat(general.dictionaries.list.schoolCharacter),
    studentCategory: mapToSelectFormat(general.dictionaries.list.studentCategory),
    obligationType: mapToSelectFormat(general.dictionaries.list.obligationType),
    educationForm: mapToSelectFormat(general.dictionaries.list.educationForm),
    organizationalStructure: mapToSelectFormat(general.dictionaries.list.organizationalStructure),
  };
};

export const SettingsPartContainer = compose(connect(mapStateToProps, null))(SettingsPart);

import { Behaviours, FSAReducer } from '@typings/redux';
import { DocumentModel } from 'components/shared/dialogs/DocumentModal/DocumentModal.types';
import { RESET_STATE } from 'store/store';
import { fromApiFormat } from 'utils/api';
import { insertPage } from 'utils/pagination/insertPage';

import { TemplatesActionType as ActionType } from './templates.actionTypes';
import { defaultState, TemplatesState as State } from './templates.state';

export const templatesReducer: FSAReducer<State, ActionType> = (state = defaultState, action) => {
  const behaviours: Behaviours<State, ActionType> = {
    [RESET_STATE]: () => ({
      ...defaultState,
    }),
    [ActionType.FETCH_TEMPLATES_LIST_REQUEST]: () => ({
      ...state,
      list: {
        ...state.list,
        loading: true,
      },
    }),
    [ActionType.FETCH_TEMPLATES_LIST_SUCCESS]: ({ payload }) => ({
      ...state,
      list: {
        ...state.list,
        loading: false,
        items: fromApiFormat(payload.items),
      },
    }),
    [ActionType.FETCH_TEMPLATES_LIST_FAILURE]: () => ({
      ...state,
      list: {
        ...state.list,
        loading: false,
        error: true,
      },
    }),
    [ActionType.FETCH_DOCUMENTS_PREVIEW_REQUEST]: () => ({
      ...state,
      preview: {
        ...state.preview,
        loading: true,
      },
    }),
    [ActionType.FETCH_DOCUMENTS_PREVIEW_SUCCESS]: ({ payload, meta }) => {
      const items = fromApiFormat(payload.items);

      items.forEach((item: DocumentModel) => {
        item.recipients.forEach((recipient) => {
          recipient.childId = item.child.id;
        });
      });

      return {
        ...state,
        preview: {
          ...state.preview,
          loading: false,
          items: insertPage(state.preview.items, items, meta.page),
          total: payload.metadata.total,
          fields: payload.fields,
          alerts: fromApiFormat(payload.alerts),
        },
      };
    },
    [ActionType.FETCH_DOCUMENTS_PREVIEW_FAILURE]: () => ({
      ...state,
      preview: {
        ...state.preview,
        loading: false,
        error: true,
      },
    }),
    [ActionType.CLEAR_DOCUMENTS_PREVIEW_DATA]: ({ payload }) => ({
      ...state,
      preview: {
        ...state.preview,
        ...defaultState.preview,
        alerts: payload.clearAlerts ? defaultState.preview.alerts : state.preview.alerts,
      },
    }),
    [ActionType.CREATE_DOCUMENTS_SUCCESS]: ({ payload }) => ({
      ...state,
      documentRequest: {
        ...state.documentRequest,
        documentId: fromApiFormat(payload).documentId,
      },
    }),
    [ActionType.FETCH_DOCUMENTS_STATUS_SUCCESS]: ({ payload }) => ({
      ...state,
      documentRequest: {
        ...state.documentRequest,
        requestStatus: {
          ...state.documentRequest.requestStatus,
          status: payload.status,
          progress: payload.progress,
        },
      },
    }),
    [ActionType.FETCH_DOCUMENTS_STATUS_FAILURE]: ({ error }) => ({
      ...state,
      documentRequest: {
        ...state.documentRequest,
        error,
      },
    }),
    [ActionType.CLEAR_DOCUMENT_REQUEST]: () => ({
      ...state,
      documentRequest: {
        ...state.documentRequest,
        ...defaultState.documentRequest,
        requestStatus: {
          ...defaultState.documentRequest.requestStatus,
        },
      },
    }),
  };

  return behaviours[action.type] ? behaviours[action.type](action) : state;
};

import {
  SubventionForecastFormRow,
  SubventionForecastRow,
  SubventionForecastRowType,
} from 'components/reports/SubventionForecast/SubventionForecast.types';
import { ColumnsConfig } from 'components/shared/data/DataGrid/DataGrid.types';
import { ManipulatorConfig } from 'components/shared/data/DataGrid/renderers/cell/ForecastModificator/ForecastManipulator.types';
import { colors } from 'styles/colors';

export interface ColumnsParams {
  sourceYear: number;
  onManipulatorChange: (data: SubventionForecastFormRow, value: ManipulatorConfig, gridApi) => void;
}

export const subventionForecastColumns: ColumnsConfig<SubventionForecastRow, ColumnsParams> = (params) => {
  return [
    {
      id: 'factor',
      field: 'factor',
      name: 'REPORTS.SUBVENTION_FORECAST.COLUMNS.FACTOR',
      cellTooltip: (data) => (data.factorTooltip ? '' : data.factor),
      renderer: 'textWithIcons',
      rendererParams: {
        icons: (data: SubventionForecastRow) => {
          if (data.factorTooltip) {
            return [{ icon: 'info-circle', tooltip: data.factorTooltip }];
          } else {
            return [];
          }
        },
      },
      columnSpan: (data) => (data.rowType === SubventionForecastRowType.HEADER ? 4 : 1),
    },
    {
      id: 'sourceValue',
      field: 'sourceValue',
      name: () => params.sourceYear?.toString(),
      cellClass: 'subventionDataSource',
      renderer: 'boxCell',
      rendererParams: {
        border: () => `1px solid ${colors.gridBorder}`,
        background: () => colors.neutralVeryLight,
      },
      format: (data, trans, formatter) => {
        if ('sourceValue' in data) {
          return formatter.formatNumber(data.sourceValue, { maximumSignificantDigits: 21 });
        }
        return '';
      },
    },
    {
      id: 'action',
      field: 'action',
      name: 'REPORTS.SUBVENTION_FORECAST.COLUMNS.ACTION',
      cellTooltip: () => '',
      columnParams: {
        minWidth: 380,
        suppressKeyboardEvent: (params) => {
          return params.event.target.tagName === 'INPUT';
        },
        valueSetter: ({ data, newValue }: { data: SubventionForecastFormRow; newValue: ManipulatorConfig }) => {
          data.action = newValue.action;
          data.actionValue = newValue.actionValue;
          return true;
        },
      },
      cellClass: 'form-cell',
      renderer: 'forecastManipulator',
      rendererParams: {
        hide: (data: SubventionForecastRow) => data.rowType !== SubventionForecastRowType.FORM,
        onChange: (data: SubventionForecastRow, value: ManipulatorConfig, gridApi) => {
          if (data.rowType === SubventionForecastRowType.FORM) {
            params.onManipulatorChange(data, value, gridApi);
          }
        },
      },
    },
    {
      id: 'forecast',
      field: 'forecast',
      name: 'REPORTS.SUBVENTION_FORECAST.COLUMNS.FORECAST',
      cellClass: 'calculations-result',
      columnParams: {
        colId: 'forecast',
        valueSetter: ({ data, newValue }) => {
          data.forecast = newValue;
          return true;
        },
      },
      format: (data, trans, formatter) => {
        if ('forecast' in data) {
          return formatter.formatNumber(data.forecast, { maximumSignificantDigits: 21 });
        }
        return '';
      },
    },
  ];
};

import { IconButton } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { useStyles } from 'components/schoolObligation/ReportDetails/Toolbar/Toolbar.styles';
import { ToolbarForm, ToolbarProps } from 'components/schoolObligation/ReportDetails/Toolbar/Toolbar.types';
import { ActionButton } from 'components/shared/buttons/ActionButton/ActionButton';
import { Form, SelectBox } from 'components/shared/form';
import { AutoSave } from 'components/shared/form/AutoSave/AutoSave';
import { SearchInput } from 'components/shared/form/SearchInput/SearchInput';
import { TreeSelectBox } from 'components/shared/form/TreeSelectBox/TreeSelectBox';
import { TreeSingleSelectBox } from 'components/shared/form/TreeSelectBox/TreeSingleSelectBox';
import { useTranslator } from 'components/shared/hooks';
import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon';
import React, { FC } from 'react';
import { ReportStatus } from 'store/schoolObligations';
import { toOptions } from 'utils/api';
import { combineValidators, maxLength } from 'utils/formValidators';
import { minLength } from 'utils/formValidators/minLength/minLength';
import { useCheckAccess } from 'components/shared/hooks';
import { OfficialPermissionLevel, OfficialResourceGroup } from 'store/officials';

export const Toolbar: FC<ToolbarProps> = ({
  initialValues,
  birthYearsOptions,
  unitOptions,
  fulfillmentOptions,
  onFiltersUpdate,
  onDocumentsCreate,
  onCsvDownload,
  reportStatus,
}) => {
  const trans = useTranslator();
  const classes = useStyles();

  const minFullAccessChildRegister = useCheckAccess(
    OfficialResourceGroup.childRegister,
    OfficialPermissionLevel.fullAccess,
  );

  const handleSubmit = (values) => {
    onFiltersUpdate(values);
  };
  const showActionButton = minFullAccessChildRegister && (
    <ActionButton
      component={IconButton}
      disableCaret={true}
      variant="contained"
      color="primary"
      label={() => <FontAwesomeIcon name={'ellipsis-v'} className={'mx-2'} />}
      actionList={[
        {
          action: onDocumentsCreate,
          label: 'SCHOOL_OBLIGATION.REPORT_CHILDREN_LIST.SEND_DOCUMENT',
          icon: 'envelope-open-text',
          tooltip: {
            title: trans('SCHOOL_OBLIGATION.REPORT_CHILDREN_LIST.SAVE_TO_SEND_DOCUMENTS'),
            disabled: reportStatus === ReportStatus.PUBLISHED,
          },
          props: {
            disabled: reportStatus !== ReportStatus.PUBLISHED,
          },
        },
        {
          action: onCsvDownload,
          label: 'SCHOOL_OBLIGATION.REPORT_CHILDREN_LIST.DOWNLOAD_CSV',
          icon: 'download',
        },
      ]}
    />
  );
  return (
    <div className="mt-2 d-flex">
      <Form<ToolbarForm> initialValues={initialValues} onSubmit={handleSubmit} className={classes.form}>
        {() => {
          return (
            <div className={classes.filters}>
              <SearchInput
                name="search"
                labelText={trans('SCHOOL_OBLIGATION.REPORT_CHILDREN_LIST.FILTERS.SEARCH.LABEL')}
                icon={<InfoOutlined fontSize="small" />}
                validate={combineValidators(
                  minLength(trans('COMMON.FORM.TOO_SHORT', { length: 3 }), 3),
                  maxLength(trans('COMMON.FORM.TOO_LONG', { length: 255 }), 255),
                )}
                hasIcon
                resettable
                tooltipText={trans('SCHOOL_OBLIGATION.REPORT_CHILDREN_LIST.FILTERS.SEARCH.TOOLTIP')}
              />
              <SelectBox
                name="birthYear"
                displayEmpty
                resettable
                placeholderText={trans('SCHOOL_OBLIGATION.REPORT_CHILDREN_LIST.FILTERS.BIRTH_YEARS.PLACEHOLDER')}
                labelText={trans('SCHOOL_OBLIGATION.REPORT_CHILDREN_LIST.FILTERS.BIRTH_YEARS.LABEL')}
                options={toOptions(birthYearsOptions)}
              />
              <TreeSingleSelectBox
                name="obligation"
                displayEmpty
                resettable
                placeholderText={trans('SCHOOL_OBLIGATION.REPORT_CHILDREN_LIST.FILTERS.OBLIGATED.PLACEHOLDER')}
                labelText={trans('SCHOOL_OBLIGATION.REPORT_CHILDREN_LIST.FILTERS.OBLIGATED.LABEL')}
                options={fulfillmentOptions}
                formatValue={(value) => {
                  return trans(`SCHOOL_OBLIGATION.REPORT_CHILDREN_LIST.VALUES.OBLIGATION.${value.toUpperCase()}`);
                }}
              />
              <TreeSelectBox
                options={unitOptions}
                name="units"
                displayEmpty
                resettable
                placeholderText={trans('SCHOOL_OBLIGATION.REPORT_CHILDREN_LIST.FILTERS.UNITS.PLACEHOLDER')}
                labelText={trans('SCHOOL_OBLIGATION.REPORT_CHILDREN_LIST.FILTERS.UNITS.LABEL')}
              />
              <AutoSave debounced={['search']} />
            </div>
          );
        }}
      </Form>
      {showActionButton}
    </div>
  );
};

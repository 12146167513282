import { muiStyles } from 'utils/design';

export const useStyles = muiStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
  },
});

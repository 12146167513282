import { ColumnsConfig, DefaultColumnConfig } from 'components/shared/data/DataGrid/DataGrid.types';
import { OtherCommuneStat } from 'store/reports/reports.types';
import { OtherCommuneHandlers } from 'components/reports/OtherCommune/OtherCommune.types';

export const otherCommuneColumns: ColumnsConfig<OtherCommuneStat, OtherCommuneHandlers> = (handlers) => [
  {
    id: 'commune',
    field: 'commune.name',
    name: 'REPORTS.OTHER_COMMUNE.COLUMNS.COMMUNE',
  },
  {
    id: 'institution',
    field: 'institution.name',
    name: 'REPORTS.OTHER_COMMUNE.COLUMNS.INSTITUTION',
  },
  {
    id: 'unit',
    field: 'unit.name',
    name: 'REPORTS.OTHER_COMMUNE.COLUMNS.UNIT',
  },
  {
    id: 'educationStage',
    field: 'educationStage.name',
    name: 'REPORTS.OTHER_COMMUNE.COLUMNS.STAGE',
  },
  {
    id: 'accreditation',
    field: 'accreditation.name',
    name: 'REPORTS.OTHER_COMMUNE.COLUMNS.PUBLICITY',
  },
  {
    id: 'studentsCount',
    field: 'studentsCount',
    name: 'REPORTS.OTHER_COMMUNE.COLUMNS.NUMBER',
  },
  {
    name: 'REPORTS.OTHER_COMMUNE.COLUMNS.ACTION',
    cellClass: 'actions-cell',
    field: '',
    cellTooltip: () => '',
    renderer: 'buttonsCell',
    columnParams: {
      suppressMenu: true,
    },
    rendererParams: {
      buttons: (stat: OtherCommuneStat) => {
        return [
          {
            type: 'default',
            size: 'small',
            variant: 'outlined',
            icon: 'eye',
            label: 'REPORTS.SCHOOL_STAT.ACTIONS.SHOW',
            tooltip: 'REPORTS.SCHOOL_STAT.ACTIONS.SHOW',
            action: () => handlers.show(stat.unit.id, stat.commune.id, stat.commune.name),
          },
        ];
      },
    },
  },
];

export const defaultColumn: DefaultColumnConfig<any> = () => ({
  sortable: true,
  filter: true,
  suppressMenu: false,
  menuTabs: ['filterMenuTab'],
});

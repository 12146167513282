import { InstitutionModel, SynchronizationTypes, UnitModel } from 'store/institutions';

type DataCollection = (InstitutionModel | UnitModel)[];

export const institutionsTransformer = (institutions: InstitutionModel[]): DataCollection => {
  return institutions.reduce((data, institution) => {
    if (institution.units.length === 0) {
      return data;
    }

    const institutionRow: InstitutionModel = {
      ...institution,
      synchronization: institution.synchronization || SynchronizationTypes.NONE,
    };

    const unitsRows: UnitModel[] = institution.units.map((unit) => {
      return {
        ...unit,
        institution: institutionRow,
      };
    });

    return [...data, institutionRow, ...unitsRows];
  }, []);
};

import { colors } from 'styles/colors';
import { muiStyles } from 'utils/design';

export const useStyles = muiStyles({
  grid: {
    flexGrow: 1,

    '@media (max-width: 1500px)': {
      '& .ag-header, & .ag-header-container, & .ag-header-cell-text': {
        whiteSpace: 'unset',
      },
    },
  },
  institutionRow: {
    background: `${colors.neutralVeryLight} !important`,
    fontWeight: 'bolder',
    '.ag-theme-material &.ag-row-hover': {
      background: colors.neutralLight,
    },
  },
  unitRow: {
    '.ag-theme-material & > .ag-cell.indent-cell': {
      paddingLeft: '20px',
      paddingRight: 0,
    },
  },
});

import { isUndefined, isNull } from 'lodash';

export const provideString = (value: string | null | undefined): string => {
  return isUndefined(value) || isNull(value) ? '' : value;
};

export const stringifyEmptyFields = <T>(item: T): T => {
  const copy = { ...item };

  Object.keys(copy).forEach((field) => {
    copy[field] = provideString(copy[field]);
  });

  return copy;
};

export const nullableEmptyString = (value: string): string | null => (value && value.length > 0 ? value : null);

import { System } from 'components/settings/System/System';
import { SystemFormValues, SystemContainerProps } from 'components/settings/System/System.types';
import { connect } from 'formik';
import React, { ComponentType } from 'react';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import { useStyles } from 'components/settings/System/System.styles';

export const SystemContainer: ComponentType<SystemContainerProps> = connect<SystemContainerProps, SystemFormValues>(
  ({ formik }) => {
    const classes = useStyles();

    return (
      <Paper innerSpacing={[3, 2]} square={true} className={classes.paper}>
        <System form={formik} />
      </Paper>
    );
  },
);

import { initialValues } from 'components/shared/dialogs/DocumentModal/DocumentModal';
import { DocumentsProps } from './Documents.types';
import { DocumentModalForm, DocumentsCreator } from 'components/shared/dialogs/DocumentModal/DocumentModal.types';
import { DocumentModalContainer } from 'components/shared/dialogs/DocumentModal/DocumentModalContainer';
import { useNotification, useTranslator } from 'components/shared/hooks';
import { limits } from 'config/pagination';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearDocumentsPreviewData,
  createChildRegisterDocuments,
  TemplateOptions,
  fetchObligationReportPreview,
  previewSelector,
} from 'store/templates';
import { AppDispatch } from '@typings/redux';

export const Documents: FC<DocumentsProps> = (props) => {
  const { items: data, fields, alerts, total } = useSelector(previewSelector);
  const trans = useTranslator();
  const dispatch: AppDispatch = useDispatch();
  const [formValues, setFormValues] = useState<DocumentModalForm>(initialValues);
  const { error } = useNotification();

  useEffect(() => {
    dispatch(clearDocumentsPreviewData());
  }, [dispatch, formValues.templateId, formValues.showInvalidOnly]);

  const onDataFetch = useCallback(
    async (page) => {
      const alert = formValues.showInvalidOnly ? true : null;
      const response = await dispatch(
        fetchObligationReportPreview(formValues.templateId, alert, props.filters, props.reportId, page),
      );

      if (response.error) {
        error(response.payload.response.error);
      }
    },
    [formValues.showInvalidOnly, formValues.templateId, dispatch, props.filters, props.reportId, error],
  );

  const onDataProvide = useCallback((page) => data[page], [data]);

  const onDocumentsCreate: DocumentsCreator = (templateId: string, options: TemplateOptions) => {
    dispatch(createChildRegisterDocuments(templateId, options));
  };

  return (
    <>
      <DocumentModalContainer
        open={props.open}
        onClose={props.onClose}
        title={trans('SCHOOL_OBLIGATION.DOCUMENTS.DOCUMENT_CREATION')}
        target={'obligation_report'}
        fields={fields}
        alerts={alerts}
        onDataFetch={onDataFetch}
        onDataProvide={onDataProvide}
        onFormUpdate={(values) => setFormValues(values)}
        onDocumentsCreate={onDocumentsCreate}
        limit={limits.childRegisterDocumentsPreview}
        total={total}
      />
    </>
  );
};

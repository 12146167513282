import { ColumnsConfig } from 'components/shared/data/DataGrid/DataGrid.types';
import { AddressHistoryModel } from 'store/childRegister';

export const addressHistoryColumns: ColumnsConfig<AddressHistoryModel> = () => [
  {
    field: 'addressType',
    name: 'CHILD_REGISTER.ADDRESS_HISTORY.COLUMNS.TYPE',
    format: (data, trans) => {
      return trans(`CHILD_REGISTER.ADDRESS_HISTORY.VALUES.${data.addressType.toUpperCase()}`);
    },
  },
  {
    field: 'address.cityName',
    name: 'CHILD_REGISTER.ADDRESS_HISTORY.COLUMNS.CITY_NAME',
  },
  {
    field: 'address.streetName',
    name: 'CHILD_REGISTER.ADDRESS_HISTORY.COLUMNS.STREET_NAME',
  },
  {
    field: 'address.postalCode',
    name: 'CHILD_REGISTER.ADDRESS_HISTORY.COLUMNS.POSTAL_CODE',
  },
  {
    field: 'address.buildingNumber',
    name: 'CHILD_REGISTER.ADDRESS_HISTORY.COLUMNS.BUILDING_NUMBER',
  },
  {
    field: 'address.apartmentNumber',
    name: 'CHILD_REGISTER.ADDRESS_HISTORY.COLUMNS.APARTMENT_NUMBER',
  },
  {
    field: 'modificationDate',
    name: 'CHILD_REGISTER.ADDRESS_HISTORY.COLUMNS.MODIFICATION_DATE',
    format: (data, trans, formatter) => {
      return data.modificationDate ? formatter.formatDateWithTime(data.modificationDate) : '';
    },
  },
  {
    field: 'modificationType',
    name: 'CHILD_REGISTER.ADDRESS_HISTORY.COLUMNS.MODIFICATION_TYPE',
    format: (data, trans) => {
      return trans(`CHILD_REGISTER.ADDRESS_HISTORY.VALUES.${data.modificationType.toUpperCase()}`);
    },
  },
  {
    field: 'modificationSource.type',
    name: 'CHILD_REGISTER.ADDRESS_HISTORY.COLUMNS.MODIFICATION_SOURCE_TYPE',
    format: (data, trans) => {
      return trans(`CHILD_REGISTER.ADDRESS_HISTORY.VALUES.${data.modificationSource.type.toUpperCase()}`);
    },
  },
];

import { Column, isGroupingHeader, TableHeader } from 'components/shared/data/DataGrid/DataGrid.types';
import { useFormatter, useTranslator } from 'components/shared/hooks';
import { Formatter } from 'components/shared/hooks/useFormatter/useFormatter.types';
import { Translator } from 'context/locale/LocaleContext/LocaleContext.types';
import { get, isFunction, isArray, isString, pickBy } from 'lodash';
import { ColDef, ColGroupDef, ColSpanParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { provideString } from 'utils/string';

const resolveCellClass = <T>(cellClass: Column<T>['cellClass'], params: any): string[] => {
  if (isFunction(cellClass)) {
    return resolveCellClass(cellClass(params), params);
  } else if (isArray(cellClass)) {
    return cellClass;
  } else if (isString(cellClass)) {
    return [cellClass];
  }

  return [];
};

const commonParams = {
  resizable: true,
  tooltipComponent: 'gridTooltip',
  suppressMenu: true,
  suppressMovable: true,
  sortable: false,
};

const valueGetterFactory =
  <T>(column: Partial<Column<T>>, trans: Translator, formatter: Formatter) =>
  (params) => {
    if (!params.data) {
      return '';
    } else if (column.format) {
      return provideString(column.format(params.data, trans, formatter, column.field));
    } else if (column.field) {
      return provideString(get(params.data, column.field, ''));
    }

    return null;
  };

export const formatColumn = <T>(
  column: Partial<Column<T>>,
  trans: Translator,
  formatter: Formatter,
  defaultColumn: ColDef,
): ColDef => {
  const resolveName = (name) => {
    if (isFunction(column.name)) {
      return column.name(trans, formatter);
    } else if (name) {
      return trans(column.name);
    }

    return null;
  };

  const getCellRenderer = () => {
    if (column.renderer) {
      if (isFunction(column.renderer)) {
        return (params) => isFunction(column.renderer) && column.renderer(params, trans);
      }
      return column.renderer;
    }
    return null;
  };

  const definition: ColDef = {
    colId: column.id,
    headerName: resolveName(column.name),
    valueGetter: column.field ? valueGetterFactory(column, trans, formatter) : null,
    headerTooltip: column.headerTooltip ? trans(column.headerTooltip) : resolveName(column.name),
    tooltipValueGetter: (params) => {
      return column.cellTooltip ? column.cellTooltip(params.data, trans, formatter, params) : params.value;
    },
    cellClass: (params) => resolveCellClass(column.cellClass, params),
    colSpan: (params: ColSpanParams) => {
      return column.columnSpan ? column.columnSpan(params.data as T, params) : 1;
    },
    headerClass: (params) => resolveCellClass(column.headerClass, params),
    aggFunc: isFunction(column.aggregation) ? column.aggregation(trans) : column.aggregation,
    cellRenderer: getCellRenderer(),
    cellRendererParams: column.rendererParams,
    headerComponent: column.headerRenderer || null,
    headerComponentParams: column.headerRendererParams,
    ...commonParams,
    ...defaultColumn,
    ...column.columnParams,
  };
  return pickBy(definition, (value) => value !== undefined && value !== null);
};

export const useColumnConfigurator = (defaultColumn: ColDef) => {
  const trans = useTranslator();
  const formatter = useFormatter();

  const multiColumnConfigurator = <T>(columns: TableHeader<T>[]): ColDef[] | ColGroupDef[] => {
    if (!columns) {
      return null;
    }

    return columns.map((column) => {
      if (isGroupingHeader(column)) {
        return {
          headerName: isFunction(column.name) ? column.name(trans, formatter) : trans(column.name),
          children: multiColumnConfigurator(column.children),
        };
      } else {
        return formatColumn(column, trans, formatter, defaultColumn);
      }
    });
  };

  const singleColumnConfigurator = <T>(column: Partial<Column<T>>): ColDef => {
    return column ? formatColumn(column, trans, formatter, defaultColumn) : null;
  };

  return { multiColumnConfigurator, singleColumnConfigurator };
};

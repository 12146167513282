import { TabConfig } from 'components/shared/navigation/RoutedTabPanel/RoutedTabPanel.types';
import { formatRoute } from 'react-router-named-routes';

export const pathResolver = (pathname: string, tabs: TabConfig[]) => {
  const routes = tabs
    .map((singleTab) => {
      return formatRoute(singleTab.route, singleTab.routeParams);
    })
    .reverse();

  const exactPath = routes.find((route) => pathname === route);

  if (exactPath) {
    return exactPath;
  }

  const partialPath = routes.find((route) => pathname.startsWith(route));

  if (partialPath) {
    return partialPath;
  }

  return null;
};

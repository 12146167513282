import { AppDispatch } from '@typings/redux';
import { Publication } from 'components/polls/Publication/Publication';
import { PublicationContainerProps } from 'components/polls/Publication/Publication.types';
import { useRecipientsCount } from 'components/polls/Publication/useRecipientsCount';
import { useFormatter, useNotification } from 'components/shared/hooks';
import { useEnvironment } from 'components/shared/hooks/useEnvironment/useEnvironment';
import { ROUTE_POLLS_LIST } from 'config/routes';
import { push, replace } from 'connected-react-router';
import { FormikHelpers } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearRecipientsCounts,
  fetchPollDetails,
  fetchRecipientsGroups,
  fetchSyncInstitutions,
  pollDetailsSelector,
  PollPublishForm,
  PollResponseMode,
  PollStatus,
  publishPoll,
  recipientsGroupsSelector,
  syncInstitutionsSelector,
} from 'store/polls';

const initialForm: PollPublishForm = {
  expirationDate: null,
  institutions: [],
  recipientGroups: [],
  pollResponseMode: PollResponseMode.INSTITUTION,
};

export const PublicationContainer: FC<PublicationContainerProps> = ({ pollId }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { formatApiErrors } = useFormatter();
  const { success, error } = useNotification();
  const { origin } = useEnvironment();

  const pollDetails = useSelector(pollDetailsSelector);
  const institutions = useSelector(syncInstitutionsSelector);
  const groups = useSelector(recipientsGroupsSelector);

  const [formValues, setFormValues] = useState<PollPublishForm>(initialForm);
  const [submitted, setSubmitted] = useState(false);

  const recipients = useRecipientsCount(
    formValues.pollResponseMode,
    formValues.institutions,
    formValues.recipientGroups,
  );

  useEffect(() => {
    dispatch(clearRecipientsCounts());
    dispatch(fetchPollDetails(pollId));
    dispatch(fetchSyncInstitutions());
    dispatch(fetchRecipientsGroups());
  }, [pollId, dispatch]);

  const onFormUpdateHandler = (values: PollPublishForm) => {
    setFormValues(values);
  };

  const onFormSubmit = async (values: PollPublishForm, formik: FormikHelpers<PollPublishForm>) => {
    const result = await dispatch(publishPoll(pollId, values, origin));

    if (result.error) {
      if (result.payload.response.errors) {
        error('POLLS.PUBLICATION.MESSAGE.PUBLICATION_ERROR');
        formik.setErrors(formatApiErrors(result.payload.response.errors));
      }
      formik.setSubmitting(false);
    } else {
      success('POLLS.PUBLICATION.MESSAGE.PUBLICATION_SUCCESS');
      setSubmitted(true);
      dispatch(push(ROUTE_POLLS_LIST));
    }
  };

  const onCancel = () => {
    dispatch(push(ROUTE_POLLS_LIST));
  };

  if (pollDetails.poll && pollDetails.poll.status !== PollStatus.DRAFT) {
    dispatch(replace(ROUTE_POLLS_LIST));
    error('POLLS.PUBLICATION.MESSAGE.PUBLISHED');
  }

  return (
    <Publication
      poll={pollDetails.poll}
      institutions={institutions.list}
      groups={groups.list}
      loading={pollDetails.loading || institutions.loading || groups.loading}
      initialForm={initialForm}
      onFormUpdate={onFormUpdateHandler}
      onFormSubmit={onFormSubmit}
      onCancel={onCancel}
      recipients={recipients}
      submitted={submitted}
    />
  );
};

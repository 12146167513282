import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { ColumnChart } from 'components/polls/Results/Combined/charts/ColumnChart';
import { PieChart } from 'components/polls/Results/Combined/charts/PieChart';
import { SingleAnswerProps } from 'components/polls/Results/Results.types';
import { Point } from 'components/shared/charts/charts.types';
import { DataGrid, GridSize } from 'components/shared/data/DataGrid/DataGrid';
import { useTranslator } from 'components/shared/hooks';
import { Box } from 'components/shared/surfaces/Box/Box';
import { Heading } from 'components/shared/typography/Heading/Heading';
import { CombinedAnswer, QuestionType } from 'store/polls';
import { combinedAnswerColumns } from './columns';
import { useStyles } from './SingleAnswer.styles';
import { DataGridApi } from 'components/shared/data/DataGrid/DataGrid.types';

// method doesn't support nested grids!
export const getGridHeight = (rowsCount) => {
  return Math.max(GridSize.headerHeight + GridSize.rowHeight * rowsCount, 400);
};

export const SingleAnswer = (props: SingleAnswerProps) => {
  const { printMode } = props;
  const [gridApi, setGridApi] = useState<DataGridApi>(null);

  useEffect(() => {
    if (gridApi) {
      gridApi.agGridApi.setDomLayout(printMode ? 'print' : 'normal');
    }
  }, [printMode, gridApi]);

  const gridHeight = getGridHeight(props.result.combinedAnswers.length);
  const onGridReadyHandler = useCallback(
    (grid: DataGridApi) => {
      setGridApi(grid);
    },
    [setGridApi],
  );

  const hasChart = props.result.questionType !== QuestionType.LONG_TEXT;
  const canPlotChart = props.result.combinedAnswers.some((answer) => answer.count > 0);

  const classes = useStyles({ gridHeight, withChart: hasChart });
  const trans = useTranslator();

  const columnsConfig = combinedAnswerColumns({ showValues: hasChart, printMode });

  const renderResultsChart = (questionType: QuestionType, chartData: Point[]) => {
    if (questionType === QuestionType.MULTIPLE_CHOICE) {
      return <ColumnChart data={chartData} />;
    }
    return <PieChart data={chartData} />;
  };

  const chartData = props.result.combinedAnswers
    .map((answer) => {
      return {
        x: answer.option,
        y: answer.percentage,
        count: answer.count,
      };
    })
    .filter((item) => item.y > 0);

  return (
    <Box margin={[3, 0, 0]}>
      <Heading type={'h5'} className={classes.question}>
        {trans('POLLS.RESULTS.COMBINED.QUESTION', {
          index: props.index,
          text: props.result.title,
        })}
      </Heading>
      <div className={classNames({ [classes.row]: !printMode })}>
        <DataGrid<CombinedAnswer>
          columns={columnsConfig}
          data={props.result.combinedAnswers}
          className={classNames({ [classes.grid]: !printMode })}
          noResultsMessage={'POLLS.RESULTS.COMBINED.NO_ANSWERS'}
          onGridReady={onGridReadyHandler}
          getRowHeight={(params) => {
            const height =
              params.api.getSizesForCurrentTheme().rowHeight * ((Math.floor(params.data.option.length / 80) + 1) / 2);
            return height > 48 && printMode ? height : 48;
          }}
          domLayout={printMode ? 'print' : 'normal'}
        />
        {hasChart && !printMode && (
          <div className={classes.chart}>
            {canPlotChart && renderResultsChart(props.result.questionType, chartData)}
          </div>
        )}
      </div>
    </Box>
  );
};

import { Page } from 'components/layout/panel/Page/Page';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import { useTranslator } from 'components/shared/hooks';
import React from 'react';

export const MessagesView: React.FC = () => {
  const trans = useTranslator();
  return (
    <Page title={trans('MESSAGES.BROWSER.TITLE')}>
      <Paper innerSpacing={2} outerSpacing={[0, 2, 2]}>
        {trans('MESSAGES.BROWSER.DESCRIPTION')}
      </Paper>
    </Page>
  );
};

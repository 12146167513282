import { Fetcher, Provider } from 'components/shared/data/DataGrid/DataGrid.types';
import { GroupedOptionType } from 'components/shared/form/Autocomplete/Autocomplete.types';
import { TemplateOptions, Target, AlertsInfo } from 'store/templates';

export interface DocumentModalProps {
  open: boolean;
  onClose: () => void;
  loading: boolean;
  fields: string[];
  alerts: AlertsInfo;
  onDataProvide: Provider<DocumentModel>;
  onDataFetch: Fetcher<DocumentModel>;
  onDataReset: () => void;
  templates: GroupedOptionType[];
  onFormUpdate: (data: DocumentModalForm) => void;
  title: string;
  limit: number;
  total: number;
  onDocumentsCreate: DocumentsCreator;
}

export interface DocumentModalContainerProps {
  open: boolean;
  onClose: () => void;
  fields: string[];
  alerts: AlertsInfo;
  onDataProvide: Provider<DocumentModel>;
  onDataFetch: Fetcher<DocumentModel>;
  target: Target;
  onFormUpdate: (data: DocumentModalForm) => void;
  title: string;
  limit: number;
  total: number;
  onDocumentsCreate: DocumentsCreator;
}

export interface DocumentModalForm {
  templateId?: string;
  showInvalidOnly: boolean;
  dcsExport: boolean;
  config: DocumentsConfig;
}

export interface DocumentsCreator {
  (templateId: string, options: TemplateOptions): void;
}

export interface DocumentsConfig {
  mode: DocumentsDispatchMode;
  format: DocumentsFormat;
}

export enum DocumentsDispatchMode {
  SINGLE_RECIPIENT = 'single',
  MULTIPLE_RECIPIENTS = 'multi',
}

export enum DocumentsFormat {
  PDF = 'pdf',
  DOCX = 'docx',
}

export interface RecipientColumnsParams {
  fields: string[];
  fieldColumnHeaderClass: string;
  edit: (item: DocumentRecipientModel) => void;
}

export interface DocumentGuardianModel {
  id: string;
  firstName: string;
  lastName: string;
}

export interface DocumentRecipientModel {
  childId?: string;
  guardian: DocumentGuardianModel;
  data: {
    [field: string]: string;
  };
  alerts: string[];
}

export interface DocumentChildModel {
  id: string;
  firstName: string;
  lastName: string;
  identificationNumber: string;
}

export interface DocumentModel {
  child: DocumentChildModel;
  recipients: DocumentRecipientModel[];
}

export const isMasterRow = (row: DocumentModel | DocumentRecipientModel): row is DocumentModel => {
  return 'child' in row;
};

export interface InvalidRecordsProps {
  count: number;
  onUpdate: (checked: boolean) => void;
}

export interface ChildHandlers {
  edit: (item: DocumentModel) => void;
}

export interface EditInfo {
  childId: string;
  guardianId?: string;
}

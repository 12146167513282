import React, { FC, useState } from 'react';
import { AssignmentModel } from 'store/schoolDistricts';
import { DataGrid } from 'components/shared/data/DataGrid/DataGrid';
import {
  AssignmentBrowserHandlers,
  AssignmentBrowserProps,
} from 'components/schoolDistricts/AssignmentBrowser/AssignmentBrowser.types';
import { useStyles } from 'components/schoolDistricts/AssignmentBrowser/AssignmentBrowser.styles';
import { databaseColumns } from 'components/schoolDistricts/AssignmentBrowser/columns.config';
import { Splash } from 'components/shared/surfaces/Splash/Splash';
import { useTranslator } from 'components/shared/hooks';
import { Confirm } from 'components/shared/dialogs/Confirm/Confirm';
import {
  AssignmentModelBrowserRow,
  isHeader,
} from 'components/schoolDistricts/AssignmentBrowser/AssignmentBrowser.types';

export const AssignmentBrowser: FC<AssignmentBrowserProps> = ({
  assignments,
  dataLoading,
  onDelete,
  fetchList,
  redirectToPreview,
}) => {
  const classes = useStyles();
  const trans = useTranslator();
  const [assignmentToDelete, setAssignmentToDelete] = useState(null);

  const actionHandlers: AssignmentBrowserHandlers = {
    show: (item: AssignmentModel) => redirectToPreview(item.id),
    delete: (item: AssignmentModel) => setAssignmentToDelete(item.id),
  };

  const handleConfirmDelete = () => {
    onDelete(assignmentToDelete).then(() => {
      setAssignmentToDelete(null);
      fetchList();
    });
  };

  if (!dataLoading && assignments.length === 0) {
    return (
      <Splash>
        <div className="mb-3">{trans('SCHOOL_DISTRICTS.ASSIGMENT_BROWSER.NO-DATA')}</div>
      </Splash>
    );
  }

  return (
    <>
      <DataGrid<AssignmentModelBrowserRow>
        className={classes.grid}
        data={assignments}
        columns={databaseColumns(actionHandlers)}
        isLoading={dataLoading}
        loadingMessage={'SCHOOL_DISTRICTS.ASSIGMENT_BROWSER.RESULTS-LOADING'}
        noResultsMessage={'SCHOOL_DISTRICTS.ASSIGMENT_BROWSER.NOTHING-FOUND'}
        getRowClass={(params) => {
          return isHeader(params.data) ? classes.headerRow : '';
        }}
      />
      <Confirm
        open={!!assignmentToDelete}
        title={'SCHOOL_DISTRICTS.ASSIGMENT_BROWSER.DELETE.CONFIRM'}
        message={'SCHOOL_DISTRICTS.ASSIGMENT_BROWSER.DELETE.CONFIRM-MESSAGE'}
        confirm={handleConfirmDelete}
        cancel={() => setAssignmentToDelete(null)}
      />
    </>
  );
};

import { BaseActionButton } from 'components/shared/buttons/ActionButton/BaseActionButton';
import { ActionButtonProps, ComponentActions } from 'components/shared/buttons/ActionButton/ActionButton.types';
import { useFocusManager } from 'components/shared/data/DataGrid/renderers/cell/Buttons/useFocusManager';
import React, { FC, useRef, useState } from 'react';

export const ActionButton: FC<ActionButtonProps> = (props) => {
  const [actions, setActions] = useState<ComponentActions>();

  const onFocusLeft = () => {
    actions.close();
  };

  const wrapper = useRef();
  const { collectNode, collectActions } = useFocusManager(wrapper.current, onFocusLeft);

  return (
    <span ref={wrapper} data-testid="actionButton">
      <BaseActionButton
        {...props}
        collectNode={collectNode}
        collectActions={collectActions}
        componentActions={setActions}
      />
    </span>
  );
};

import { Typography } from '@material-ui/core';
import { OptionType } from 'components/shared/form/Autocomplete/Autocomplete.types';
import React from 'react';
import { PlaceholderProps } from 'react-select';

export function Placeholder(props: PlaceholderProps<OptionType, boolean>) {
  return (
    <Typography className={props.selectProps.classes.placeholder} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

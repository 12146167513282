import { Page } from 'components/layout/panel/Page/Page';
import { BrowserContainer } from 'components/officials/Browser/BrowserContainer';
import { useTranslator } from 'components/shared/hooks';
import { Breadcrumb } from 'components/shared/navigation/Breadcrumbs/Breadcrumb';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import React from 'react';
import { useStyles } from './OfficialsList.styles';

export const OfficialsListView: React.FC = () => {
  const trans = useTranslator();
  const classes = useStyles();

  return (
    <React.Fragment>
      <Breadcrumb label={trans('BREADCRUMB.OFFICIALS.LIST')} static />

      <Page title={trans('OFFICIALS.BROWSER.TITLE')}>
        <Paper innerSpacing={2} outerSpacing={[0, 2, 2]} className={classes.paper}>
          <BrowserContainer />
        </Paper>
      </Page>
    </React.Fragment>
  );
};

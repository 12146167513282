import { FSA } from '@typings/redux';
import { limits } from 'config/pagination';
import { RSAA, RSAACreator } from 'redux-api-middleware';
import {
  ObligationReportChildrenListFilters,
  ObligationReportPostModel,
  ReportBrowserFilter,
  SchoolObligationsActionType as ActionType,
  SchoolYear,
} from 'store/schoolObligations';
import { fileToObjectFetch } from 'utils/redux/fileToObjectFetch';
import { omit, pickBy, identity } from 'lodash';
import { Majority } from '@typings/common';

export const fetchBirthYears: RSAACreator<ActionType> = (majority: Majority, referenceDate: string) => {
  return {
    [RSAA]: {
      endpoint: '/obligation-report/birth-years',
      method: 'GET',
      types: [
        {
          type: ActionType.FETCH_BIRTH_YEARS_REQUEST,
          meta: {
            takeLatest: true,
          },
        },
        ActionType.FETCH_BIRTH_YEARS_SUCCESS,
        ActionType.FETCH_BIRTH_YEARS_FAILURE,
      ],
      params: {
        referenceDate,
        filter: { majority },
      },
    },
  };
};

export const fetchSchoolObligationsReports: RSAACreator<ActionType> = (schoolYear?: SchoolYear) => {
  return {
    [RSAA]: {
      endpoint: `/obligation-report`,
      method: 'GET',
      types: [
        ActionType.FETCH_SCHOOL_OBLIGATIONS_REPORTS_REQUEST,
        ActionType.FETCH_SCHOOL_OBLIGATIONS_REPORTS_SUCCESS,
        ActionType.FETCH_SCHOOL_OBLIGATIONS_REPORTS_FAILURE,
      ],
      params: schoolYear
        ? {
            schoolYear,
          }
        : {},
    },
  };
};

export const removeSchoolObligationsReport: RSAACreator<ActionType> = (id: string) => {
  return {
    [RSAA]: {
      endpoint: `/obligation-report/${id}`,
      method: 'DELETE',
      types: [
        {
          type: ActionType.REMOVE_SCHOOL_OBLIGATION_REPORT_REQUEST,
          meta: {
            lockScreen: true,
          },
        },
        ActionType.REMOVE_SCHOOL_OBLIGATION_REPORT_SUCCESS,
        ActionType.REMOVE_SCHOOL_OBLIGATION_REPORT_FAILURE,
      ],
      params: {},
    },
  };
};

export const createSchoolObligationReport: RSAACreator<ActionType> = (body: ObligationReportPostModel) => {
  return {
    [RSAA]: {
      endpoint: `/obligation-report`,
      method: 'POST',
      types: [
        {
          type: ActionType.CREATE_SCHOOL_OBLIGATION_REPORT_REQUEST,
          meta: { lockScreen: true },
        },
        ActionType.CREATE_SCHOOL_OBLIGATION_REPORT_SUCCESS,
        ActionType.CREATE_SCHOOL_OBLIGATION_REPORT_FAILURE,
      ],
      body,
      params: {},
    },
  };
};

export const clearReportStatus = () => ({
  type: ActionType.CLEAR_REPORT_STATUS,
});

export const fetchReportStatus: RSAACreator<ActionType> = (id: string) => {
  return {
    [RSAA]: {
      endpoint: `/obligation-report/${id}/status`,
      method: 'GET',
      types: [
        ActionType.FETCH_REPORT_STATUS_REQUEST,
        ActionType.FETCH_REPORT_STATUS_SUCCESS,
        ActionType.FETCH_REPORT_STATUS_FAILURE,
      ],
      params: {},
    },
  };
};

export const fetchSchoolObligationsReportDetails: RSAACreator<ActionType> = (reportId: string) => {
  return {
    [RSAA]: {
      endpoint: `/obligation-report/${reportId}`,
      method: 'GET',
      types: [
        ActionType.FETCH_SCHOOL_OBLIGATIONS_REPORT_DETAILS_REQUEST,
        ActionType.FETCH_SCHOOL_OBLIGATIONS_REPORT_DETAILS_SUCCESS,
        ActionType.FETCH_SCHOOL_OBLIGATIONS_REPORT_DETAILS_FAILURE,
      ],
      params: {},
    },
  };
};

export const publishSchoolObligationsReport: RSAACreator<ActionType> = (reportId: string) => {
  return {
    [RSAA]: {
      endpoint: `/obligation-report/${reportId}/publish`,
      method: 'PUT',
      types: [
        {
          type: ActionType.PUBLISH_SCHOOL_OBLIGATIONS_REPORT_DETAILS_REQUEST,
          meta: {
            lockScreen: true,
          },
        },
        ActionType.PUBLISH_SCHOOL_OBLIGATIONS_REPORT_DETAILS_SUCCESS,
        ActionType.PUBLISH_SCHOOL_OBLIGATIONS_REPORT_DETAILS_FAILURE,
      ],
      params: {},
    },
  };
};

export const setReportChildrenListFilters = (filters: ObligationReportChildrenListFilters): FSA<ActionType> => {
  return {
    type: ActionType.SET_SCHOOL_OBLIGATIONS_REPORT_CHILDREN_LIST_FILTERS,
    payload: filters,
  };
};

export const clearReportChildrenList = (): FSA<ActionType> => {
  return {
    type: ActionType.CLEAR_SCHOOL_OBLIGATIONS_REPORT_CHILDREN_LIST,
  };
};

export const fetchSchoolObligationsReportChildrenList: RSAACreator<ActionType> = (
  reportId: string,
  params: ObligationReportChildrenListFilters,
  page: number,
) => {
  return {
    [RSAA]: {
      endpoint: `/obligation-report/${reportId}/children`,
      method: 'GET',
      types: [
        {
          type: ActionType.FETCH_SCHOOL_OBLIGATIONS_REPORT_CHILDREN_LIST_REQUEST,
          meta: {
            takeLatest: true,
          },
        },
        {
          type: ActionType.FETCH_SCHOOL_OBLIGATIONS_REPORT_CHILDREN_LIST_SUCCESS,
          meta: { page },
        },
        {
          type: ActionType.FETCH_SCHOOL_OBLIGATIONS_REPORT_CHILDREN_LIST_FAILURE,
          meta: { page },
        },
      ],
      params: {
        search: params.search,
        filter: pickBy(omit(params, 'search'), identity),
        page,
        limit: limits.obligationReportChildrenList,
      },
    },
  };
};

export const downloadCsvSchoolObligationsReportChildrenList: RSAACreator<ActionType> = (
  fileName: string,
  reportId: string,
  params: ObligationReportChildrenListFilters,
) => {
  return {
    [RSAA]: {
      endpoint: `/obligation-report/${reportId}/children`,
      method: 'GET',
      fetch: fileToObjectFetch('text/csv', `${fileName}.csv`),
      types: [
        ActionType.DOWNLOAD_CSV_SCHOOL_OBLIGATIONS_REPORT_CHILDREN_LIST_REQUEST,
        {
          type: ActionType.DOWNLOAD_CSV_SCHOOL_OBLIGATIONS_REPORT_CHILDREN_LIST_SUCCESS,
          // meta: { downloadResponse: true },
        },
        ActionType.DOWNLOAD_CSV_SCHOOL_OBLIGATIONS_REPORT_CHILDREN_LIST_FAILURE,
      ],
      params: {
        search: params.search,
        filter: pickBy(omit(params, 'search'), identity),
      },
    },
  };
};

export const fetchFulfillmentOptions: RSAACreator<ActionType> = () => {
  return {
    [RSAA]: {
      endpoint: `/obligation-report/fulfillment-filter`,
      method: 'GET',
      types: [
        ActionType.FETCH_FULFILLMENT_OPTIONS_REQUEST,
        ActionType.FETCH_FULFILLMENT_OPTIONS_SUCCESS,
        ActionType.FETCH_FULFILLMENT_OPTIONS_FAILURE,
      ],
      params: {},
    },
  };
};

export const fetchSchoolObligationsReportInstitutionList: RSAACreator<ActionType> = (reportId: string) => {
  return {
    [RSAA]: {
      endpoint: `/obligation-report/${reportId}/institutions`,
      method: 'GET',
      types: [
        ActionType.FETCH_SCHOOL_OBLIGATIONS_REPORT_INSTITUTIONS_LIST_REQUEST,
        ActionType.FETCH_SCHOOL_OBLIGATIONS_REPORT_INSTITUTIONS_LIST_SUCCESS,
        ActionType.FETCH_SCHOOL_OBLIGATIONS_REPORT_INSTITUTIONS_LIST_FAILURE,
      ],
      params: {},
    },
  };
};

export const setReportsFilters = (filters: ReportBrowserFilter): FSA<ActionType> => {
  return {
    type: ActionType.SET_REPORTS_FILTERS,
    payload: filters,
  };
};

export const clearReportsFilters = (): FSA<ActionType> => {
  return {
    type: ActionType.CLEAR_REPORTS_FILTERS,
  };
};

import { colors } from 'styles/colors';
import { muiStyles } from 'utils/design';

export const useStyles = muiStyles((theme) => ({
  container: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(4),
    background: colors.neutralVeryLight,
    '& > form': {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
    },
  },
}));

import React, { FC, useCallback } from 'react';
import { Settings } from './Settings';
import { StepProps } from 'components/shared/dialogs/StepModal/StepModal.types';

import { useDispatch, useSelector } from 'react-redux';
import { fetchImportColumns, postImportContext } from 'store/childRegister/childRegister.actions';
import {
  importColumnsSelector,
  importContextSelector,
  importErrorSelector,
  importLoadingSelector,
  importTokenSelector,
} from 'store/childRegister';

export const SettingsContainer: FC<StepProps> = (props) => {
  const dispatch: (...params: any) => Promise<any> = useDispatch();
  const importToken = useSelector(importTokenSelector);
  const isLoading = useSelector(importLoadingSelector);
  const formInitialValues = useSelector(importContextSelector);

  const fetchColumns = useCallback(() => dispatch(fetchImportColumns(importToken)), [dispatch, importToken]);
  const saveContext = useCallback((body) => dispatch(postImportContext(importToken, body)), [dispatch, importToken]);

  const columns = useSelector(importColumnsSelector).map((value) => ({ label: value, value: value }));
  const error = useSelector(importErrorSelector);

  if (error) {
    props.onFailure(error);
  }

  return (
    <Settings
      {...props}
      columns={columns}
      initialValues={formInitialValues}
      fetchColumns={fetchColumns}
      saveContext={saveContext}
      isLoading={isLoading}
    />
  );
};

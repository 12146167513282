import {
  ReportOverviewParams,
  ReportOverviewRow,
} from 'components/schoolObligation/ReportDetails/ReportOverview/ReportOverview.types';

import { Column, ColumnsConfig, GroupingColumnConfig } from 'components/shared/data/DataGrid/DataGrid.types';

type ReportColumn = Column<ReportOverviewRow>;

const groupingColumnLevelClasses = {
  0: 'font-weight-bolder',
  1: '',
  2: 'text-secondary',
};

export const reportOverviewGroupingColumn: GroupingColumnConfig<ReportOverviewRow> = () => ({
  field: 'name',
  name: 'SCHOOL_OBLIGATION.REPORT_OVERVIEW.COLUMNS.STATUS',
  format: (data, trans) => {
    return trans(`SCHOOL_OBLIGATION.REPORT_OVERVIEW.COUNTER.${data.name.toUpperCase()}`);
  },
  columnParams: { minWidth: 350 },
  rendererParams: { suppressCount: true },
  cellClass: ({ data }) => {
    return groupingColumnLevelClasses[data.path.length - 1];
  },
});

export const reportOverviewColumns: ColumnsConfig<ReportOverviewRow, ReportOverviewParams> = (params) => {
  const sum: ReportColumn = {
    field: 'sum',
    name: 'SCHOOL_OBLIGATION.REPORT_OVERVIEW.COLUMNS.SUM',
    columnParams: { minWidth: 70 },
  };

  const years: ReportColumn[] = params.years.map((year) => ({
    field: `years.${year}`,
    name: () => year,
    columnParams: { minWidth: 50 },
    format: (data) => data.years[year].toString(),
  }));

  return [sum, ...years];
};

import { Translator } from 'context/locale/LocaleContext/LocaleContext.types';
import { compact } from 'lodash';

import { DatabaseStatus, SchoolAddressModel } from 'store/schoolDistricts';
import { GridParams } from './AddressesList.types';
import { ColumnsConfig } from 'components/shared/data/DataGrid/DataGrid.types';
import { generateAddressRuleDescription } from 'utils/addressRule/addressRule';
import { generateConflictDescription } from 'utils/addressRule/conflicts';
import { createList } from 'utils/tooltip/createList';
import { colors } from 'styles/colors';
import { OfficialPermissionLevel, OfficialResourceGroup } from 'store/officials';

const hasWarning = (item: SchoolAddressModel) => item.alerts && item.alerts.length > 0;
const isArchival = (params: GridParams) => params.databaseStatus === DatabaseStatus.ARCHIVAL;

export const databaseColumns: ColumnsConfig<SchoolAddressModel, GridParams> = (params) => [
  {
    field: 'cityName',
    name: 'SCHOOL_DISTRICTS.DISTRICTS-LIST.ADDRESS-LIST.COLUMNS.CITY',
    cellClass: 'indent-cell',
    columnParams: { minWidth: 120 },
  },
  {
    field: 'streetName',
    name: 'SCHOOL_DISTRICTS.DISTRICTS-LIST.ADDRESS-LIST.COLUMNS.STREET',
    cellClass: 'indent-cell',
    columnParams: { minWidth: 120 },
  },
  {
    field: 'range',
    name: 'SCHOOL_DISTRICTS.DISTRICTS-LIST.ADDRESS-LIST.COLUMNS.NUMBER-RANGE',
    cellClass: 'indent-cell',
    columnParams: { minWidth: 200 },
    format: (data: SchoolAddressModel, trans) => {
      return generateAddressRuleDescription(data, trans);
    },
  },
  {
    field: 'alerts',
    name: 'COMMON.ALERT',
    columnParams: { minWidth: 40, maxWidth: 70 },
    cellTooltip: () => '',
    renderer: 'iconsCell',
    rendererParams: {
      icons: (item: SchoolAddressModel) =>
        hasWarning(item)
          ? [
              {
                type: 'default',
                icon: 'exclamation-triangle',
                tooltip: (trans: Translator) => {
                  const list = generateConflictDescription(item, trans);
                  const title = trans('SCHOOL_DISTRICTS.DISTRICTS-LIST.ADDRESS-LIST.ACTIONS.CONFLICTS');

                  return createList(title, list);
                },
                color: colors.defaultIcon,
              },
            ]
          : [],
    },
  },
  {
    name: 'SCHOOL_DISTRICTS.DATABASE.COLUMNS.ACTIONS',
    cellClass: 'actions-cell',
    field: 'id',
    cellTooltip: () => '',
    renderer: 'buttonsCell',
    rendererParams: {
      buttons: (item: SchoolAddressModel) =>
        compact([
          {
            type: 'default',
            size: 'small',
            variant: 'outlined',
            canShow: {
              only: false,
              resourcesGroup: OfficialResourceGroup.childRegister,
              accessType: OfficialPermissionLevel.fullAccess,
            },
            icon: 'pencil-alt',
            label: 'COMMON.EDIT',
            tooltip: isArchival(params)
              ? 'SCHOOL_DISTRICTS.DISTRICTS-LIST.ADDRESS-LIST.ACTIONS.DISABLED'
              : 'COMMON.EDIT',
            action: params.edit,
            disabled: isArchival(params),
          },
          {
            type: 'default',
            size: 'small',
            variant: 'outlined',
            canShow: {
              only: false,
              resourcesGroup: OfficialResourceGroup.childRegister,
              accessType: OfficialPermissionLevel.fullAccess,
            },
            icon: 'trash',
            label: 'COMMON.DELETE',
            tooltip: isArchival(params)
              ? 'SCHOOL_DISTRICTS.DISTRICTS-LIST.ADDRESS-LIST.ACTIONS.DISABLED'
              : 'COMMON.DELETE',
            action: () => params.delete(item),
            disabled: isArchival(params),
          },
        ]),
    },
    columnParams: { minWidth: 200 },
  },
];

import { ColumnsConfig } from 'components/shared/data/DataGrid/DataGrid.types';
import { ObligationReportRow } from './Reports.types';

export const obligationReportsColumns: ColumnsConfig<ObligationReportRow> = () => [
  {
    field: 'referenceDate',
    name: 'CHILD_REGISTER.OBLIGATION.REPORTS.COLUMNS.DATE',
    format: (data, trans, formatter) => {
      return formatter.formatDate(data.referenceDate);
    },
  },
  {
    field: 'schoolYear',
    name: 'CHILD_REGISTER.OBLIGATION.REPORTS.COLUMNS.SCHOOL_YEAR',
  },
  {
    field: 'obligation',
    name: 'CHILD_REGISTER.OBLIGATION.REPORTS.COLUMNS.OBLIGATION',
    format: (data, trans) => {
      return trans(`SCHOOL_OBLIGATION.REPORT_CHILDREN_LIST.VALUES.OBLIGATION.${data.obligation.toUpperCase()}`);
    },
  },
  {
    field: 'educationStage.name',
    name: 'CHILD_REGISTER.OBLIGATION.REPORTS.COLUMNS.EDUCATION_STAGE',
  },
  {
    field: 'institutionName',
    name: 'CHILD_REGISTER.OBLIGATION.REPORTS.COLUMNS.INSTITUTION_NAME',
  },
  {
    field: 'unitName',
    name: 'CHILD_REGISTER.OBLIGATION.REPORTS.COLUMNS.UNIT_NAME',
  },
  {
    field: 'level',
    name: 'CHILD_REGISTER.OBLIGATION.REPORTS.COLUMNS.LEVEL',
  },
];

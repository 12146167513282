import { LinearProgress } from '@material-ui/core';
import { useStyles } from 'components/shared/loaders/ProgressBar/ProgressBar.styles';
import { ProgressBarProps } from 'components/shared/loaders/ProgressBar/ProgressBar.types';
import React, { FC } from 'react';

export const ProgressBar: FC<ProgressBarProps> = ({ value, error }) => {
  const classes = useStyles({ ready: value === 100, error });
  const progressValue = error ? 100 : value;

  return <LinearProgress color="primary" variant="determinate" value={progressValue} classes={classes} />;
};

import { AppDispatch } from '@typings/redux';
import { Alert, AlertsProps } from 'components/childRegister/Browser/Alerts/Alerts.types';
import { useTranslator } from 'components/shared/hooks';
import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon';
import { Infobar, InfobarButton, InfobarWrapper } from 'components/shared/messages/Infobar';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { childRegisterSelector } from 'store/childRegister';
import { setChildRegisterFilters } from 'store/childRegister/childRegister.actions';
import { useStyles } from './Alerts.styles';
import classNames from 'classnames';

export const Alerts: FC<AlertsProps> = ({ counts }) => {
  const trans = useTranslator();
  const dispatch = useDispatch<AppDispatch>();
  const classes = useStyles();

  const { filters } = useSelector(childRegisterSelector);

  const selectAlert = (alertId: string) => {
    dispatch(setChildRegisterFilters({ ...filters, alert: alertId }));
  };

  const messages = {
    [Alert.MISSING_TERYT]: 'CHILD_REGISTER.BROWSER.ALERT.MISSING-TERYT',
    [Alert.MISSING_ADDRESS]: 'CHILD_REGISTER.BROWSER.ALERT.MISSING-ADDRESS',
    [Alert.INCOMPLETE_PERMANENT_ADDRESS]: 'CHILD_REGISTER.BROWSER.ALERT.INCOMPLETE_PERMANENT_ADDRESS',
    [Alert.INCOMPLETE_RESIDENCE_ADDRESS]: 'CHILD_REGISTER.BROWSER.ALERT.INCOMPLETE_RESIDENCE_ADDRESS',
  };

  const isError = (alert: Alert) => {
    return alert === Alert.MISSING_ADDRESS;
  };

  const reversedAlerts = [...counts].reverse();

  return (
    <InfobarWrapper className={classes.infobarWrapper}>
      {reversedAlerts.map((alert, index) => {
        return (
          alert.count > 0 && (
            <Infobar key={index} className={isError(alert.id) ? classes.error : classes.warning}>
              <span className={classes.alertContent}>
                <FontAwesomeIcon
                  name={isError(alert.id) ? 'exclamation-triangle' : 'info-circle'}
                  className={classNames('mr-2', classes.alertIcon)}
                />
                {trans(messages[alert.id], { count: alert.count })}
              </span>
              <InfobarButton onClick={() => selectAlert(alert.id)}>{trans('COMMON.SHOW')}</InfobarButton>
            </Infobar>
          )
        );
      })}
    </InfobarWrapper>
  );
};

import { values, omit } from 'lodash';
import * as routes from '../../../../config/routes';
import { matchPathWithRoutes } from '../../../../utils/navigation/matchPathWithRoutes';
import { ROUTE_QUESTIONNAIRES_FORM } from '../../../../config/routes';

export const isPossibleOrigin = (path: string) => {
  const notOriginRoutes = omit(routes, 'ROUTE_ROOT');
  return !matchPathWithRoutes(path, values(notOriginRoutes));
};

export const shouldSkipEnvironmentFetch = (path: string) => {
  const routesWithoutEnvironment = [ROUTE_QUESTIONNAIRES_FORM];
  return matchPathWithRoutes(path, routesWithoutEnvironment);
};

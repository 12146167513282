import { ContextColumnsConfig } from 'components/shared/import/settings/settings.types';

export const getContextColumnsConfig = (): ContextColumnsConfig => {
  return [
    {
      title: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.PERSONAL_DATA',
      fields: [
        {
          field: 'firstName',
          required: true,
          label: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.FIRST_NAME',
        },
        {
          field: 'secondName',
          required: false,
          label: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.SECOND_NAME',
        },
        {
          field: 'lastName',
          required: true,
          label: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.LAST_NAME',
        },
        {
          field: 'sex',
          required: false,
          label: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.SEX',
        },
        {
          field: 'birthday',
          required: true,
          label: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.BIRTHDAY',
        },
        {
          field: 'identificationNumber',
          required: true,
          label: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.IDENTIFICATION_NUMBER',
        },
        {
          field: 'addressStatus',
          required: false,
          label: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.ADDRESS_STATUS',
        },
        {
          field: 'birthCity',
          required: false,
          label: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.BIRTH_CITY',
        },
      ],
    },
    {
      title: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.GUARDIANS_DATA',
      fields: [
        {
          field: 'fatherFirstName',
          required: false,
          label: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.FATHER_FIRST_NAME',
        },
        {
          field: 'fatherLastName',
          required: false,
          label: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.FATHER_LAST_NAME',
        },
        {
          field: 'motherFirstName',
          required: false,
          label: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.MOTHER_FIRST_NAME',
        },
        {
          field: 'motherLastName',
          required: false,
          label: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.MOTHER_LAST_NAME',
        },
      ],
    },
    {
      title: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.PERMANENT_RESIDENCE',
      fields: [
        {
          field: 'permanentResidenceVoivodeship',
          required: false,
          label: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.PERMANENT_RESIDENCE_VOIVODESHIP',
        },
        {
          field: 'permanentResidenceCity',
          required: false,
          label: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.PERMANENT_RESIDENCE_CITY',
        },
        {
          field: 'permanentResidenceCityTeryt',
          required: false,
          label: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.PERMANENT_RESIDENCE_CITY_TERYT',
        },
        {
          field: 'permanentResidenceStreet',
          required: false,
          label: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.PERMANENT_RESIDENCE_STREET',
        },
        {
          field: 'permanentResidenceBuildingNumber',
          required: false,
          label: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.PERMANENT_RESIDENCE_BUILDING_NUMBER',
        },
        {
          field: 'permanentResidenceFlatNumber',
          required: false,
          label: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.PERMANENT_RESIDENCE_FLAT_NUMBER',
        },
        {
          field: 'permanentResidencePostalCode',
          required: false,
          label: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.PERMANENT_RESIDENCE_POSTAL_CODE',
        },
      ],
    },
    {
      title: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.RESIDENCE',
      fields: [
        {
          field: 'residenceVoivodeship',
          required: false,
          label: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.RESIDENCE_VOIVODESHIP',
        },
        {
          field: 'residenceCity',
          required: false,
          label: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.RESIDENCE_CITY',
        },
        {
          field: 'residenceCityTeryt',
          required: false,
          label: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.RESIDENCE_CITY_TERYT',
        },
        {
          field: 'residenceStreet',
          required: false,
          label: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.RESIDENCE_STREET',
        },
        {
          field: 'residenceBuildingNumber',
          required: false,
          label: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.RESIDENCE_BUILDING_NUMBER',
        },
        {
          field: 'residenceFlatNumber',
          required: false,
          label: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.RESIDENCE_FLAT_NUMBER',
        },
        {
          field: 'residencePostalCode',
          required: false,
          label: 'CHILD_REGISTER.IMPORT_STEPS.SETTINGS.RESIDENCE_POSTAL_CODE',
        },
      ],
    },
  ];
};

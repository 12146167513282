import { ICellRendererParams } from 'ag-grid-community';
import { useStyles } from 'components/shared/data/DataGrid/renderers/cell/BoxedCell/BoxedCell.style';
import React from 'react';

export interface BoxedCellProps extends ICellRendererParams {
  background: (data: any) => string;
  border: (data: any) => string;
}

export const BoxedCell = (props: BoxedCellProps) => {
  const background = props.background ? props.background(props.data) : null;
  const border = props.border ? props.border(props.data) : null;

  const classes = useStyles({ background, border });

  return <div className={classes.box}>{props.value}</div>;
};

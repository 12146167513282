import { colors } from 'styles/colors';
import { muiStyles } from 'utils/design';

export const useStyles = muiStyles((theme) => ({
  grid: {
    height: '400px !important',
    margin: theme.spacing(1, 0),

    '& .ag-header .ag-header-cell[col-id="0"]': {
      justifyContent: 'center',
    },
  },
  splash: {
    border: `1px solid ${colors.gridBorder}`,
    margin: theme.spacing(1, 0),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
  },
  templateSelect: {
    flexGrow: 1,
    marginRight: theme.spacing(2),
  },
  fieldColumnCell: {
    background: colors.gridMarkedCell,
  },
  icon: {
    fontSize: '0.9rem',
  },
}));

import { useSelector } from 'react-redux';
import { OfficialAccountType, OfficialResourceGroup } from 'store/officials';
import { userOfficialRole, userOfficialPermissions } from 'store/user';
import { OfficialPermissionLevel } from 'store/officials';

const mapedAccessType = new Map([
  [OfficialPermissionLevel.noAccess, 0],
  [OfficialPermissionLevel.readOnly, 1],
  [OfficialPermissionLevel.fullAccess, 2],
]);

export const helpersCheckAccess = (
  resourcesGroup: OfficialResourceGroup,
  accessType: OfficialPermissionLevel,
  role: OfficialAccountType,
  permissions: Record<OfficialResourceGroup, OfficialPermissionLevel> | {},
) => {
  if (role === OfficialAccountType.ADMIN) return true;
  const permissionForResourcesGroup = permissions[resourcesGroup] || OfficialPermissionLevel.noAccess;
  if (permissionForResourcesGroup === OfficialPermissionLevel.noAccess) return false;
  const formatAccessType = mapedAccessType.get(accessType);
  const formatPermissionAcessType = mapedAccessType.get(permissionForResourcesGroup as OfficialPermissionLevel);
  return formatAccessType <= formatPermissionAcessType ? true : false;
};

export const helpersMultipeCheckAccess = (
  resourcesGroups: OfficialResourceGroup[],
  accessType: OfficialPermissionLevel,
  role: OfficialAccountType,
  permissions: Record<OfficialResourceGroup, OfficialPermissionLevel> | {},
) => {
  const filterResoureGroup = resourcesGroups.filter((resourcesGroup) =>
    helpersCheckAccess(resourcesGroup, accessType, role, permissions),
  );
  return filterResoureGroup.length > 0;
};

export const helperCheckAccessSameType = (
  resourcesGroup: OfficialResourceGroup,
  accessType: OfficialPermissionLevel,
  permissions: Record<OfficialResourceGroup, OfficialPermissionLevel> | {},
) => {
  return permissions[resourcesGroup] === accessType;
};

export const useCheckAccess = (resourcesGroup: OfficialResourceGroup, accessType: OfficialPermissionLevel): boolean => {
  const role = useSelector(userOfficialRole);
  const permissions = useSelector(userOfficialPermissions);
  return helpersCheckAccess(resourcesGroup, accessType, role, permissions);
};

export const useMultipeCheckAccess = (
  resourcesGroups: OfficialResourceGroup[],
  accessType: OfficialPermissionLevel,
): boolean => {
  const role = useSelector(userOfficialRole);
  const permissions = useSelector(userOfficialPermissions);
  return helpersMultipeCheckAccess(resourcesGroups, accessType, role, permissions);
};

export const useCheckAccessSameType = (
  resourcesGroup: OfficialResourceGroup,
  accessType: OfficialPermissionLevel,
): boolean => {
  const permissions = useSelector(userOfficialPermissions);
  return helperCheckAccessSameType(resourcesGroup, accessType, permissions);
};

import { InfoOutlined } from '@material-ui/icons';
import React, { useContext } from 'react';
import { InputText, CheckBox } from 'components/shared/form';
import { SelectBox } from 'components/shared/form/SelectBox/SelectBox';
import { Heading } from 'components/shared/typography/Heading/Heading';
import { LocaleContext } from 'context/locale';
import { SettingsPartComponentProps } from 'components/institution/Unit/UnitForm/parts/SettingsPart/SettingsPart.types';

export const SettingsPart = ({
  disableInputs,
  schoolType,
  schoolSubtype,
  educationStage,
  accreditation,
  schoolCharacter,
  studentCategory,
  obligationType,
  educationForm,
  organizationalStructure,
  props,
}: SettingsPartComponentProps) => {
  const { trans } = useContext(LocaleContext);

  return (
    <div className="container-fluid mt-4 pb-3">
      <Heading type="h5">{trans('INSTITUTIONS.UNIT_DATA.SETTINGS.TITLE')}</Heading>
      <div className="row mt-4">
        <div className="col-3">
          <InputText name="rspo" labelText={trans('INSTITUTIONS.UNIT_DATA.SETTINGS.RSPO')} />
        </div>
        <div className="col-3">
          <InputText name="oke" labelText={trans('INSTITUTIONS.UNIT_DATA.SETTINGS.OKE')} />
        </div>
        <div className="col-3">
          <InputText
            name="regon"
            labelText={trans('INSTITUTIONS.UNIT_DATA.SETTINGS.REGON')}
            disabled={disableInputs}
            hasIcon={disableInputs}
            icon={<InfoOutlined fontSize="small" />}
            tooltipText={trans('INSTITUTIONS.UNIT_DATA.GENERAL_TOOLTIPS.IMPORTED_FROM_SYNERGIA')}
          />
        </div>
        <div className="col-3">
          <InputText
            name="nip"
            labelText={trans('INSTITUTIONS.UNIT_DATA.SETTINGS.NIP')}
            disabled={disableInputs}
            hasIcon={disableInputs}
            icon={<InfoOutlined fontSize="small" />}
            tooltipText={trans('INSTITUTIONS.UNIT_DATA.GENERAL_TOOLTIPS.IMPORTED_FROM_SYNERGIA')}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-3">
          <SelectBox
            name="schoolType"
            labelText={trans('INSTITUTIONS.UNIT_DATA.SETTINGS.SCHOOL_TYPE')}
            placeholderText={trans('INSTITUTIONS.UNIT_DATA.SETTINGS.CHOOSE')}
            options={schoolType}
            disabled={disableInputs}
            hasIcon={disableInputs}
            icon={<InfoOutlined fontSize="small" />}
            tooltipText={trans('INSTITUTIONS.UNIT_DATA.GENERAL_TOOLTIPS.IMPORTED_FROM_SYNERGIA')}
            onChange={() => props.setFieldValue('schoolSubtype', null)}
            required
          />
        </div>
        <div className="col-3">
          <SelectBox
            name="schoolSubtype"
            labelText={trans('INSTITUTIONS.UNIT_DATA.SETTINGS.SCHOOL_SUBTYPE.LABEL')}
            placeholderText={trans('INSTITUTIONS.UNIT_DATA.SETTINGS.CHOOSE')}
            options={schoolSubtype}
            disabled={disableInputs || schoolSubtype.length === 0}
            hasIcon={true}
            icon={<InfoOutlined fontSize="small" />}
            tooltipText={
              disableInputs
                ? trans('INSTITUTIONS.UNIT_DATA.GENERAL_TOOLTIPS.IMPORTED_FROM_SYNERGIA')
                : trans('INSTITUTIONS.UNIT_DATA.SETTINGS.SCHOOL_SUBTYPE.TOOLTIP')
            }
            required
          />
        </div>
        <div className="col-3">
          <SelectBox
            name="educationStage"
            labelText={trans('INSTITUTIONS.UNIT_DATA.SETTINGS.EDUCATION_STAGE')}
            placeholderText={trans('INSTITUTIONS.UNIT_DATA.SETTINGS.CHOOSE')}
            options={educationStage}
            disabled={disableInputs}
            hasIcon={disableInputs}
            icon={<InfoOutlined fontSize="small" />}
            tooltipText={trans('INSTITUTIONS.UNIT_DATA.GENERAL_TOOLTIPS.IMPORTED_FROM_SYNERGIA')}
            required
          />
        </div>
        <div className="col-3">
          <SelectBox
            name="obligationType"
            labelText={trans('INSTITUTIONS.UNIT_DATA.SETTINGS.OBLIGATION_TYPE')}
            placeholderText={trans('INSTITUTIONS.UNIT_DATA.SETTINGS.CHOOSE')}
            options={obligationType}
            disabled={disableInputs}
            hasIcon={disableInputs}
            icon={<InfoOutlined fontSize="small" />}
            tooltipText={trans('INSTITUTIONS.UNIT_DATA.GENERAL_TOOLTIPS.IMPORTED_FROM_SYNERGIA')}
            required
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-3">
          <SelectBox
            name="accreditation"
            resettable={true}
            emptyValue={null}
            labelText={trans('INSTITUTIONS.UNIT_DATA.SETTINGS.ACCREDITATION')}
            placeholderText={trans('INSTITUTIONS.UNIT_DATA.SETTINGS.CHOOSE')}
            options={accreditation}
            disabled={disableInputs}
            hasIcon={disableInputs}
            icon={<InfoOutlined fontSize="small" />}
            tooltipText={trans('INSTITUTIONS.UNIT_DATA.GENERAL_TOOLTIPS.IMPORTED_FROM_SYNERGIA')}
          />
        </div>
        <div className="col-3">
          <SelectBox
            name="schoolCharacter"
            resettable={true}
            emptyValue={null}
            labelText={trans('INSTITUTIONS.UNIT_DATA.SETTINGS.SCHOOL_CHARACTER')}
            placeholderText={trans('INSTITUTIONS.UNIT_DATA.SETTINGS.CHOOSE')}
            options={schoolCharacter}
            disabled={disableInputs}
            hasIcon={disableInputs}
            icon={<InfoOutlined fontSize="small" />}
            tooltipText={trans('INSTITUTIONS.UNIT_DATA.GENERAL_TOOLTIPS.IMPORTED_FROM_SYNERGIA')}
          />
        </div>
        <div className="col-3">
          <SelectBox
            name="studentCategory"
            resettable={true}
            emptyValue={null}
            labelText={trans('INSTITUTIONS.UNIT_DATA.SETTINGS.STUDENT_CATEGORY')}
            placeholderText={trans('INSTITUTIONS.UNIT_DATA.SETTINGS.CHOOSE')}
            options={studentCategory}
            disabled={disableInputs}
            hasIcon={disableInputs}
            icon={<InfoOutlined fontSize="small" />}
            tooltipText={trans('INSTITUTIONS.UNIT_DATA.GENERAL_TOOLTIPS.IMPORTED_FROM_SYNERGIA')}
          />
        </div>
        <div className="col-3">
          <CheckBox name="catchmentSchool" labelText={trans('INSTITUTIONS.UNIT_DATA.SETTINGS.CATCHMENT_SCHOOL')} />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-3">
          <SelectBox
            name="educationForm"
            resettable={true}
            emptyValue={null}
            labelText={trans('INSTITUTIONS.UNIT_DATA.SETTINGS.EDUCATION_FORM')}
            placeholderText={trans('INSTITUTIONS.UNIT_DATA.SETTINGS.EDUCATION_FORM')}
            options={educationForm}
            icon={<InfoOutlined fontSize="small" />}
          />
        </div>
        <div className="col-3">
          <SelectBox
            name="organizationalStructure"
            resettable={true}
            emptyValue={null}
            labelText={trans('INSTITUTIONS.UNIT_DATA.SETTINGS.ORGANIZATIONAL_STRUCTURE')}
            placeholderText={trans('INSTITUTIONS.UNIT_DATA.SETTINGS.ORGANIZATIONAL_STRUCTURE')}
            options={organizationalStructure}
            icon={<InfoOutlined fontSize="small" />}
          />
        </div>
      </div>
    </div>
  );
};

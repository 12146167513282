import { ColumnsConfig } from 'components/shared/data/DataGrid/DataGrid.types';
import { AuditLog } from 'store/reports/reports.types';

export const auditLogColumns: ColumnsConfig<AuditLog, void> = () => [
  {
    field: 'createdAt',
    name: 'REPORTS.AUDIT_LOG.COLUMNS.DATE',
    columnParams: { minWidth: 300 },
    format: (data, trans, formatter) => {
      return formatter.formatDateWithTime(data.createdAt);
    },
  },
  {
    field: 'userName',
    name: 'REPORTS.AUDIT_LOG.COLUMNS.OFFICIAL',
    columnParams: { minWidth: 300 },
  },
  {
    field: 'commandName',
    name: 'REPORTS.AUDIT_LOG.COLUMNS.ACTION',
    format: (data, trans) => {
      return trans(data.commandName);
    },
  },
];

import { FormHelperText } from '@material-ui/core';
import { Buttons } from 'components/polls/Editor/Buttons';
import { useStyles } from 'components/polls/Editor/Editor.styles';
import { EditorProps } from 'components/polls/Editor/Editor.types';
import { Question } from 'components/polls/Editor/Question/Question';
import { Button } from 'components/shared/buttons/Button/Button';
import { Form, InputText } from 'components/shared/form';
import { FieldArray } from 'components/shared/form/FieldArray/FieldArray';
import { useTranslator } from 'components/shared/hooks';
import { TextLoader } from 'components/shared/loaders/TextLoader/TextLoader';
import { Box } from 'components/shared/surfaces/Box/Box';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import { Splash } from 'components/shared/surfaces/Splash/Splash';
import { Heading } from 'components/shared/typography/Heading/Heading';
import React, { FC } from 'react';
import { Prompt } from 'react-router';
import { PollForm } from 'store/polls';
import { getError } from 'utils/form/getErrors/getError';
import { FastField } from 'formik';

export const Editor: FC<EditorProps> = (props) => {
  const trans = useTranslator();
  const classes = useStyles();

  if (props.loading || !props.initial) {
    return <TextLoader text={trans('POLLS.EDITOR.LOADING_POLL')} />;
  }

  return (
    <Form<PollForm> initialValues={props.initial} onSubmit={props.onSave} className={classes.form}>
      {({ submitForm, errors, dirty }) => (
        <>
          <Prompt when={dirty && !props.submitted} message={trans('COMMON.CONFIRM_EXIT')} />
          <Paper innerSpacing={[3, 2]} className={classes.paper}>
            <div className={classes.editor}>
              <InputText name="name" labelText={trans('POLLS.EDITOR.FIELDS.NAME')} />
              <InputText
                name="description"
                labelText={trans('POLLS.EDITOR.FIELDS.DESCRIPTION')}
                multiline={true}
                rowsMax={6}
                fieldComponent={FastField}
              />
              <Box margin={[2, 0]}>
                <FieldArray name="questions">
                  {({ form, push, remove }) => {
                    return (
                      <>
                        <Heading type={'h5'} className="mb-3">
                          {trans('POLLS.EDITOR.SECTIONS.QUESTIONS_LIST')}
                        </Heading>

                        {form.values.questions.map((question, index) => (
                          <Question
                            index={index}
                            question={question}
                            field={`questions.${index}`}
                            onRemove={() => remove(index)}
                            key={index}
                          />
                        ))}

                        {form.values.questions.length === 0 && (
                          <Splash height={200}>
                            {trans('POLLS.EDITOR.NO_QUESTIONS')}
                            <FormHelperText error>{getError(errors, 'questions', trans)}</FormHelperText>
                          </Splash>
                        )}

                        <Heading type={'h5'} className="mt-4 mb-3 ">
                          {trans('POLLS.EDITOR.SECTIONS.ADD_QUESTION')}
                        </Heading>

                        <Buttons onAdd={push} />
                      </>
                    );
                  }}
                </FieldArray>
              </Box>
            </div>
          </Paper>
          <div className="row my-4">
            <div className="col">
              <Button color="secondary" variant="outlined" onClick={() => props.onCancel()}>
                {trans('COMMON.BACK')}
              </Button>
              <Button
                color="secondary"
                variant="contained"
                className="ml-3"
                onClick={() => submitForm()}
                data-testid="saveButton"
              >
                {trans('COMMON.SAVE')}
              </Button>
            </div>
          </div>
        </>
      )}
    </Form>
  );
};

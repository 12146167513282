import { muiStyles } from 'utils/design';

export const useStyles = muiStyles({
  allowOverflow: {
    overflow: 'visible',
  },
  paperContainer: {
    overflow: 'visible',
    minWidth: 650,
  },
  label: {
    width: 200,
  },
});

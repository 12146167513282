import { useStyles } from 'components/shared/surfaces/ErrorPage/ErrorPage.styles';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import React, { FC } from 'react';

export const ErrorPage: FC = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Paper innerSpacing={[4, 8]} outerSpacing={8}>
        {children}
      </Paper>
    </div>
  );
};

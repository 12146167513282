import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FileImport } from './FileImport';
import { StepProps } from 'components/shared/dialogs/StepModal/StepModal.types';
import { importFileSelector, importTokenSelector, importTypeSelector } from 'store/childRegister';
import { deleteImport, postImportFile } from 'store/childRegister/childRegister.actions';

export const FileImportContainer: FC<StepProps> = (props) => {
  const dispatch: (...params: any) => Promise<any> = useDispatch();
  const importToken = useSelector(importTokenSelector);
  const file = useSelector(importFileSelector);
  const importType = useSelector(importTypeSelector);

  const deleteChildRegisterImportCallback = useCallback(() => {
    return dispatch(deleteImport(importToken));
  }, [dispatch, importToken]);

  const postChildRegisterImportCallback = useCallback(
    (file, type) => {
      return dispatch(postImportFile(file, type));
    },
    [dispatch],
  );

  return (
    <FileImport
      {...props}
      deleteChildRegisterImport={deleteChildRegisterImportCallback}
      postChildRegisterImport={postChildRegisterImportCallback}
      token={importToken}
      file={file}
      importType={importType}
    />
  );
};

import { InputText } from 'components/shared/form';
import { InputTextProps } from 'components/shared/form/InputText/InputText.types';
import { useStyles } from 'components/shared/form/SearchInput/SearchInput.styles';
import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon';
import React, { FC } from 'react';

export const SearchInput: FC<InputTextProps> = (props) => {
  const classes = useStyles();

  const searchIcon = <FontAwesomeIcon name={'search'} className={classes.searchIcon} />;

  return <InputText startAdornment={searchIcon} {...props} />;
};

import { important, muiStyles } from 'utils/design';

export const useStyles = muiStyles((theme) => ({
  root: {
    width: '100%',

    '& svg': {
      fontSize: '1.5rem',
    },
  },
  formControl: {
    width: 300,
    minWidth: '100%',
    maxWidth: '100%',
  },
  labelError: {
    color: important(theme.palette.grey[700]),
  },
}));

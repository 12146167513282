import { AppState } from 'store/store';

export const getTown = (state: AppState) => state.teryt.town.data;
export const isTownLoading = (state: AppState) => state.teryt.town.loading;

export const getTownById = (id: string) => (state: AppState) =>
  id && state.teryt.towns.items.find((town) => town.id === id);

export const getCommune = (state: AppState) => state.teryt.commune.data;
export const isCommuneLoading = (state: AppState) => state.teryt.commune.loading;

export const getCommuneById = (id: string) => (state: AppState) =>
  id && state.teryt.communes.items.find((commune) => commune.id === id);

export const getVoivodeshipsList = (state: AppState) => state.teryt.voivodeships.items;
export const isVoivodeshipsListLoadingSelector = (state: AppState) => state.teryt.voivodeships.loading;

export const getCommunesList = (state: AppState) => state.teryt.communes.items;
export const isCommunesListLoadingSelector = (state: AppState) => state.teryt.communes.loading;

export const getCountiesList = (state: AppState) => state.teryt.counties.items;
export const isCountiesListLoadingSelector = (state: AppState) => state.teryt.counties.loading;

export const getTownsList = (state: AppState) => state.teryt.towns.items;
export const isTownsListLoadingSelector = (state: AppState) => state.teryt.towns.loading;

export const getStreetsList = (state: AppState) => state.teryt.streets.items;
export const isStreetsListLoadingSelector = (state: AppState) => state.teryt.streets.loading;

import { muiStyles } from 'utils/design';

export const useStyles = muiStyles(() => ({
  headingContainer: {
    minHeight: '45px',
    height: '45px',
  },
  bar: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  xAxisLabel: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

import { ActionsProps } from 'components/polls/Editor/Editor.types';
import { Button } from 'components/shared/buttons/Button/Button';
import { useTranslator } from 'components/shared/hooks';
import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon';
import React, { FC } from 'react';
import { hasOptions, Question, QuestionType } from 'store/polls';

const emptyQuestionFactory = (type: QuestionType): Question => {
  return {
    type,
    title: '',
    options: hasOptions(type) ? [{ value: '' }] : [],
    required: false,
  };
};

export const Buttons: FC<ActionsProps> = ({ onAdd }) => {
  const trans = useTranslator();

  return (
    <>
      <Button
        color="default"
        variant="outlined"
        className="mr-3"
        onClick={() => onAdd(emptyQuestionFactory(QuestionType.TEXT))}
        data-testid="addSingleLineQuestion"
      >
        <FontAwesomeIcon name={'font'} className="mr-2" />
        {trans('POLLS.EDITOR.ACTIONS.ADD_SINGLE_LINE')}
      </Button>

      <Button
        color="default"
        variant="outlined"
        className="mr-3"
        onClick={() => onAdd(emptyQuestionFactory(QuestionType.LONG_TEXT))}
        data-testid="addMultipleLineQuestion"
      >
        <FontAwesomeIcon name={'paragraph'} className="mr-2" />
        {trans('POLLS.EDITOR.ACTIONS.ADD_MULTIPLE_LINE')}
      </Button>

      <Button
        color="default"
        variant="outlined"
        className="mr-3"
        onClick={() => onAdd(emptyQuestionFactory(QuestionType.SINGLE_CHOICE))}
        data-testid="addSingleChoiceQuestion"
      >
        <FontAwesomeIcon name={'dot-circle'} className="mr-2" />
        {trans('POLLS.EDITOR.ACTIONS.ADD_SINGLE_CHOICE')}
      </Button>

      <Button
        color="default"
        variant="outlined"
        className="mr-3"
        onClick={() => onAdd(emptyQuestionFactory(QuestionType.MULTIPLE_CHOICE))}
        data-testid="addMultipleChoiceQuestion"
      >
        <FontAwesomeIcon name={'check-square'} className="mr-2" />
        {trans('POLLS.EDITOR.ACTIONS.ADD_MULTIPLE_CHOICE')}
      </Button>
    </>
  );
};

import { GuardianPostModel } from 'components/childRegister/Editor/General/General.types';
import { FulfillmentState } from 'store/schoolObligations';
import { AddressPostModel, AddressModel } from 'store/teryt';
import { Majority } from '@typings/common';

export interface GuardianModel {
  id?: string;
  firstName: string;
  lastName: string;
  type: string;
  contact: {
    phone: string;
    email: string;
  };
  address: AddressModel;
  sameAddressAsChild: boolean;
  correspondenceGuardian: boolean;
}

export enum IdentificationNumberType {
  PESEL = 'pesel',
  OTHER = 'other',
  EMPTY = 'empty',
}

export enum Sex {
  MALE = 'male',
  FEMALE = 'female',
}

export enum AddressStatus {
  ARCHIVAL = 'archival',
  ACTIVE = 'active',
}

export enum DefaultGuardianTypes {
  FATHER = 'Ojciec',
  MOTHER = 'Matka',
  DEFAULT = 'opiekun prawny',
}

export enum AddressTypes {
  RESIDENCE = 'residence',
  PERMANENT_RESIDENCE = 'permanent_residence',
}

export enum AddressInUse {
  RESIDENCE = 'residence',
  PERMANENT_RESIDENCE = 'permanent_residence',
  NONE = 'none',
}

export enum ModificationTypes {
  SYNCHRONIZATION = 'synchronization',
  IMPORT = 'import',
  EDIT = 'edit',
}

export enum ModificationSourceTypes {
  OFFICIAL = 'official',
}

export enum FileImportTypes {
  FULL = 'full',
  PARTIAL = 'partial',
}

export enum ImportStatusTypes {
  DRAFT = 'draft',
  MAP_READY = 'map_ready',
  PROCESSING = 'processing',
  SUCCESS = 'success',
  ERROR = 'error',
  PREVIEW_READY = 'preview_ready',
}

export interface ChildRegisterFilters {
  search: string;
  majority?: Majority;
  birthYear?: number;
  sex?: Sex;
  alert?: string;
  isCatchmentChild?: string;
  usedAddress?: AddressInUse;
}

export interface AlertChildModel {
  name: string;
}

export interface ChildModel {
  id: string;
  firstName: string;
  secondName: string | null;
  lastName: string;
  identificationNumberType: IdentificationNumberType | null;
  identificationNumber: string | null;
  sex: Sex | null;
  birthday: string;
  birthCity: string;
  isResident: boolean;
  isPermanentResident: boolean;
  residence: AddressModel;
  permanentResidence: AddressModel;
  guardians: GuardianModel[];
  exemptions: ExemptionModel[];
  modificationDate: string;
  alerts: AlertChildModel[];
  usedAddress: AddressTypes;
  cityName: string;
  isCatchmentChild: boolean;
}

export interface ExemptionModel {
  id?: string;
  schoolYear: string;
  description: string;
}

export interface ManualStudentRegisterEntriesModel {
  unitId: string;
  level: number;
  date: string;
}

export interface ChildPostModel {
  firstName: string;
  secondName: string;
  lastName: string;
  identificationNumberType: string | null;
  identificationNumber: string | null;
  sex: string | null;
  birthday: string;
  birthCity: string;
  isResident: boolean;
  isPermanentResident: boolean;
  residence: AddressPostModel;
  permanentResidence: AddressPostModel;
  guardians: GuardianPostModel[];
  exemptions: ExemptionModel[];
  isCatchmentChild: boolean;
  manualStudentRegisterEntries?: ManualStudentRegisterEntriesModel[];
}

export interface AlertTypeModel {
  id: string;
  name: string;
}

export interface AddressHistoryModel {
  id: string;
  addressType: AddressTypes;
  address: AddressModel;
  modificationDate: string;
  modificationType: ModificationTypes;
  modificationSource: {
    type: ModificationSourceTypes;
    userId: string | null;
  };
}

export interface EducationPathRecordModel {
  from: string;
  to: string | null;
  educationStage: string;
  institutionName: string;
  unitName: string;
  level: string;
  schoolYear: string;
}

export interface ObligationReportRecordModel {
  id: string;
  createdAt: string;
  referenceDate: string;
  schoolYear: string;
  obligation: FulfillmentState;
  educationStage: {
    id: string;
    name: string;
  };
  institutionName: string;
  unitName: string;
  level: string;
}

export interface ChildImportModel {
  firstName: string;
  secondName: string | null;
  lastName: string;
  sex: string | null;
  birthday: string;
  identificationNumber: string;
  addressStatus: string | null;
  birthCity: string | null;
  fatherFirstName: string | null;
  fatherLastName: string | null;
  motherFirstName: string | null;
  motherLastName: string | null;
  permanentResidenceVoivodeship: string | null;
  permanentResidenceCity: string | null;
  permanentResidenceCityTeryt: string | null;
  permanentResidenceStreet: string | null;
  permanentResidenceBuildingNumber: string | null;
  permanentResidenceFlatNumber: string | null;
  permanentResidencePostalCode: string | null;
  residenceVoivodeship: string | null;
  residenceCity: string | null;
  residenceCityTeryt: string | null;
  residenceStreet: string | null;
  residenceBuildingNumber: string | null;
  residenceFlatNumber: string | null;
  residencePostalCode: string | null;
}
export interface ImportContextModel {
  dataSource: string;
  map: ChildImportModel;
}

export interface ImportPreviewModel {
  recordsPreview: ChildImportModel[];
  recordsToImport: number;
  recordsToCreate: number;
  recordsToUpdate: number;
  recordsToArchive: number;
  currentRegisterSize: number;
}

export enum ImportPreviewStatus {
  preparingPreview = 'preparing_preview',
  previewReady = 'preview_ready',
}

export interface ImportPreviewStatusModel {
  progress: number;
  status: ImportPreviewStatus;
}

export interface ObligationAction {
  id: string;
  createdAt: string;
  modifiedAt: string;
  actionAt: string;
  officialId: string;
  officialFirstName: string;
  officialLastName: string;
  actionType: 'manual' | 'automatic';
  actionTitle: string;
  actionDescription: string;
}

export interface ObligationActionModel {
  actionAt: string;
  actionType: 'manual';
  actionTitle: string;
  actionDescription: string;
}

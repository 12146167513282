import { muiStyles } from 'utils/design';
import { colors } from 'styles/colors';

export const useStyles = muiStyles((theme) => ({
  container: {
    height: 300,

    '& .ag-center-cols-viewport .ag-cell .ag-cell-wrapper': {
      justifyContent: 'center',
    },
  },
  summaryContainer: {
    height: 200,
    '& .ag-center-cols-viewport': {
      overflowX: 'hidden',
    },
    '& .ag-overlay-wrapper': {
      paddingTop: 70,
    },
    '& .ag-center-cols-viewport .ag-cell .ag-cell-wrapper': {
      justifyContent: 'center',
    },
  },
  grid: {
    flexGrow: 1,
  },
  warning: {
    color: theme.palette.error.main,
  },
  distinguishRow: {
    background: `${colors.neutralVeryLight} !important`,
    fontWeight: 'bolder',
    '.ag-theme-material &.ag-row-hover': {
      background: colors.neutralLight,
    },
  },
}));

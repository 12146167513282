import { AppDispatch } from '@typings/redux';
import { DetailsContainerProps } from 'components/institution/Unit/StudentsRegister/Details/Details.types';
import { useDispatch, useSelector } from 'react-redux';
import { Details } from './Details';
import React, { FC, useEffect } from 'react';
import {
  clearStudentsRegisterDetails,
  fetchStudentRegisterDetails,
  fetchUnit,
  getUnitData,
  isStudentRegisterDetailsLoadingSelector,
  isStudentRegisterDetailsReadySelector,
  isUnitLoading,
  studentRegisterDetailsSelector,
} from 'store/institutions';

export const DetailsContainer: FC<DetailsContainerProps> = ({ unitId, registerId, rollbackDate, onGoBack }) => {
  const dispatch = useDispatch<AppDispatch>();

  const unit = useSelector(getUnitData);
  const unitLoading = useSelector(isUnitLoading);

  const register = useSelector(studentRegisterDetailsSelector);
  const isRegisterLoading = useSelector(isStudentRegisterDetailsLoadingSelector);
  const isRegisterReady = useSelector(isStudentRegisterDetailsReadySelector);

  useEffect(() => {
    if (!unit) {
      dispatch(fetchUnit(unitId));
    }
    dispatch(clearStudentsRegisterDetails());
    dispatch(fetchStudentRegisterDetails(unitId, registerId, rollbackDate));
  }, [unit, dispatch, unitId, registerId, rollbackDate]);

  return (
    <Details
      loading={unitLoading || !unit || isRegisterLoading || !isRegisterReady}
      register={register}
      unit={unit}
      onGoBack={onGoBack}
    />
  );
};

export enum InstitutionsActionType {
  FETCH_INSTITUTIONS_LIST_REQUEST = 'institution/FETCH_INSTITUTIONS_LIST_REQUEST',
  FETCH_INSTITUTIONS_LIST_SUCCESS = 'institution/FETCH_INSTITUTIONS_LIST_SUCCESS',
  FETCH_INSTITUTIONS_LIST_FAILURE = 'institution/FETCH_INSTITUTIONS_LIST_FAILURE',
  SET_INSTITUTION_FILTERS = 'institution/SET_INSTITUTION_FILTERS',
  FETCH_INSTITUTIONS_DICTIONARY_REQUEST = 'institution/FETCH_INSTITUTIONS_DICTIONARY_REQUEST',
  FETCH_INSTITUTIONS_DICTIONARY_SUCCESS = 'institution/FETCH_INSTITUTIONS_DICTIONARY_SUCCESS',
  FETCH_INSTITUTIONS_DICTIONARY_FAILURE = 'institution/FETCH_INSTITUTIONS_DICTIONARY_FAILURE',
  FETCH_INSTITUTION_REQUEST = 'institution/FETCH_INSTITUTION_REQUEST',
  FETCH_INSTITUTION_SUCCESS = 'institution/FETCH_INSTITUTION_SUCCESS',
  FETCH_INSTITUTION_FAILURE = 'institution/FETCH_INSTITUTION_FAILURE',
  PUT_INSTITUTION_LIST_REQUEST = 'institution/PUT_INSTITUTION_REQUEST',
  PUT_INSTITUTION_LIST_SUCCESS = 'institution/PUT_INSTITUTION_SUCCESS',
  PUT_INSTITUTION_LIST_FAILURE = 'institution/PUT_INSTITUTION_FAILURE',
  DELETE_INSTITUTION_REQUEST = 'institution/DELETE_INSTITUTION_REQUEST',
  DELETE_INSTITUTION_SUCCESS = 'institution/DELETE_INSTITUTION_SUCCESS',
  DELETE_INSTITUTION_FAILURE = 'institution/DELETE_INSTITUTION_FAILURE',
  DELETE_UNIT_REQUEST = 'institution/DELETE_UNIT_REQUEST',
  DELETE_UNIT_SUCCESS = 'institution/DELETE_UNIT_SUCCESS',
  DELETE_UNIT_FAILURE = 'institution/DELETE_UNIT_FAILURE',
  POST_UNIT_REQUEST = 'institution/POST_UNIT_REQUEST',
  POST_UNIT_SUCCESS = 'institution/POST_UNIT_SUCCESS',
  POST_UNIT_FAILURE = 'institution/POST_UNIT_FAILURE',
  FETCH_UNIT_REQUEST = 'institution/FETCH_UNIT_REQUEST',
  FETCH_UNIT_SUCCESS = 'institution/FETCH_UNIT_SUCCESS',
  FETCH_UNIT_FAILURE = 'institution/FETCH_UNIT_FAILURE',
  CLEAR_UNIT = 'institution/CLEAR_UNIT',
  PUT_UNIT_REQUEST = 'institution/PUT_UNIT_REQUEST',
  PUT_UNIT_SUCCESS = 'institution/PUT_UNIT_SUCCESS',
  PUT_UNIT_FAILURE = 'institution/PUT_UNIT_FAILURE',
  FETCH_STUDENT_REGISTER_SCHOOL_YEARS_REQUEST = 'institution/FETCH_STUDENT_REGISTER_SCHOOL_YEARS_REQUEST',
  FETCH_STUDENT_REGISTER_SCHOOL_YEARS_SUCCESS = 'institution/FETCH_STUDENT_REGISTER_SCHOOL_YEARS_SUCCESS',
  FETCH_STUDENT_REGISTER_SCHOOL_YEARS_FAILURE = 'institution/FETCH_STUDENT_REGISTER_SCHOOL_YEARS_FAILURE',
  FETCH_STUDENT_REGISTER_LIST_REQUEST = 'institution/FETCH_STUDENT_REGISTER_LIST_REQUEST',
  FETCH_STUDENT_REGISTER_LIST_SUCCESS = 'institution/FETCH_STUDENT_REGISTER_LIST_SUCCESS',
  FETCH_STUDENT_REGISTER_LIST_FAILURE = 'institution/FETCH_STUDENT_REGISTER_LIST_FAILURE',
  CLEAR_STUDENT_REGISTER_LIST = 'institution/CLEAR_STUDENT_REGISTER_LIST',
  FETCH_STUDENT_REGISTER_REQUEST = 'institution/FETCH_STUDENT_REGISTER_REQUEST',
  FETCH_STUDENT_REGISTER_SUCCESS = 'institution/FETCH_STUDENT_REGISTER_SUCCESS',
  FETCH_STUDENT_REGISTER_FAILURE = 'institution/FETCH_STUDENT_REGISTER_FAILURE',
  DOWNLOAD_STUDENT_REGISTER_REQUEST = 'institution/DOWNLOAD_STUDENT_REGISTER_REQUEST',
  DOWNLOAD_STUDENT_REGISTER_SUCCESS = 'institution/DOWNLOAD_STUDENT_REGISTER_SUCCESS',
  DOWNLOAD_STUDENT_REGISTER_FAILURE = 'institution/DOWNLOAD_STUDENT_REGISTER_FAILURE',
  DELETE_STUDENT_REGISTER_REQUEST = 'institution/DELETE_STUDENT_REGISTER_REQUEST',
  DELETE_STUDENT_REGISTER_SUCCESS = 'institution/DELETE_STUDENT_REGISTER_SUCCESS',
  DELETE_STUDENT_REGISTER_FAILURE = 'institution/DELETE_STUDENT_REGISTER_FAILURE',
  CLEAR_STUDENT_REGISTER = 'institution/CLEAR_STUDENT_REGISTER',
  POST_STUDENT_REGISTER_IMPORT_REQUEST = 'institution/POST_STUDENT_REGISTER_IMPORT_REQUEST',
  POST_STUDENT_REGISTER_IMPORT_SUCCESS = 'institution/POST_STUDENT_REGISTER_IMPORT_SUCCESS',
  POST_STUDENT_REGISTER_IMPORT_FAILURE = 'institution/POST_STUDENT_REGISTER_IMPORT_FAILURE',
  DELETE_STUDENT_REGISTER_IMPORT_REQUEST = 'institution/DELETE_STUDENT_REGISTER_IMPORT_REQUEST',
  DELETE_STUDENT_REGISTER_IMPORT_SUCCESS = 'institution/DELETE_STUDENT_REGISTER_IMPORT_SUCCESS',
  DELETE_STUDENT_REGISTER_IMPORT_FAILURE = 'institution/DELETE_STUDENT_REGISTER_IMPORT_FAILURE',
  FETCH_STUDENT_REGISTER_IMPORT_COLUMNS_REQUEST = 'institution/FETCH_STUDENT_REGISTER_IMPORT_COLUMNS_REQUEST',
  FETCH_STUDENT_REGISTER_IMPORT_COLUMNS_SUCCESS = 'institution/FETCH_STUDENT_REGISTER_IMPORT_COLUMNS_SUCCESS',
  FETCH_STUDENT_REGISTER_IMPORT_COLUMNS_FAILURE = 'institution/FETCH_STUDENT_REGISTER_IMPORT_COLUMNS_FAILURE',
  POST_STUDENT_REGISTER_IMPORT_CONTEXT_REQUEST = 'institution/POST_STUDENT_REGISTER_IMPORT_CONTEXT_REQUEST',
  POST_STUDENT_REGISTER_IMPORT_CONTEXT_SUCCESS = 'institution/POST_STUDENT_REGISTER_IMPORT_CONTEXT_SUCCESS',
  POST_STUDENT_REGISTER_IMPORT_CONTEXT_FAILURE = 'institution/POST_STUDENT_REGISTER_IMPORT_CONTEXT_FAILURE',
  FETCH_STUDENT_REGISTER_PREVIEW_REQUEST = 'institution/FETCH_STUDENT_REGISTER_PREVIEW_REQUEST',
  FETCH_STUDENT_REGISTER_PREVIEW_SUCCESS = 'institution/FETCH_STUDENT_REGISTER_PREVIEW_SUCCESS',
  FETCH_STUDENT_REGISTER_PREVIEW_FAILURE = 'institution/FETCH_STUDENT_REGISTER_PREVIEW_FAILURE',
  POST_STUDENT_REGISTER_CONFIRM_REQUEST = 'institution/POST_STUDENT_REGISTER_CONFIRM_REQUEST',
  POST_STUDENT_REGISTER_CONFIRM_SUCCESS = 'institution/POST_STUDENT_REGISTER_CONFIRM_SUCCESS',
  POST_STUDENT_REGISTER_CONFIRM_FAILURE = 'institution/POST_STUDENT_REGISTER_CONFIRM_FAILURE',
  CLEAR_STUDENT_REGISTER_INFORMATION = 'institution/CLEAR_STUDENT_REGISTER_INFORMATION',
  MAP_IMPORT_FIELDS = 'institution/MAP_IMPORT_FIELDS',
}

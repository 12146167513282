import { AssignmentModel } from 'store/schoolDistricts';

export interface AssignmentBrowserProps {
  assignments: AssignmentModelBrowserRow[];
  dataLoading: boolean;
  onDelete: (id: string) => Promise<any>;
  fetchList: () => Promise<any>;
  redirectToPreview: (id: string) => void;
}

export interface AssignmentBrowserHandlers {
  show: (item: AssignmentModel) => void;
  delete: (item: AssignmentModel) => void;
}

export interface AssignmentModelHeaderModel {
  title: string;
}

export type AssignmentModelBrowserRow = AssignmentModel | AssignmentModelHeaderModel;

export function isHeader(item: AssignmentModelBrowserRow): item is AssignmentModelHeaderModel {
  return (item as AssignmentModelHeaderModel).title !== undefined;
}

import React from 'react';
import { SignIn } from 'components/auth/SignIn/SignIn';
import { useStyles } from 'views/Auth/SignIn/SignIn.styles';

export const SignInView: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <SignIn />
    </div>
  );
};

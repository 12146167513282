import { muiStyles } from 'utils/design';

interface FooterStylesProps {
  bannerHeight: string;
}

export const useStyles = muiStyles<FooterStylesProps>((theme) => ({
  container: {
    height: ({ bannerHeight }) => `${bannerHeight}px` || 'initial',
    backgroundColor: theme.palette.primary.main,
    minHeight: '16px',
    zIndex: theme.zIndex.drawer + 1,
    lineHeight: 0,
    paddingTop: '1px',
  },
  image: {
    display: 'block',
    margin: 'auto',
    height: '100%',
    objectPosition: 'center center',
  },
}));

import { RSAA, RSAACreator } from 'redux-api-middleware';
import { SearchActionType } from 'store/search/search.actionTypes';
import { SearchParams } from 'store/search/search.types';

export const fetchSearch: RSAACreator<SearchActionType> = (filters: SearchParams) => {
  return {
    [RSAA]: {
      endpoint: `/search-osin`,
      method: 'GET',
      types: [
        SearchActionType.FETCH_SEARCH_REQUEST,
        SearchActionType.FETCH_SEARCH_SUCCESS,
        SearchActionType.FETCH_SEARCH_FAILURE,
      ],
      params: {
        ...filters,
      },
    },
  };
};

import React, { FC } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { BreadcrumbProps } from 'components/shared/navigation/Breadcrumbs/Breadcrumb.types';

export const Breadcrumb: FC<BreadcrumbProps> = (props) => {
  // We always have to provide the `to` parameter because of the library limitations.
  // We add the `static` prop with spread operator to workaround bad typings.
  return (
    <BreadcrumbsItem to={props.to || props.label} {...{ static: !props.to }}>
      {props.label}
    </BreadcrumbsItem>
  );
};

import React, { FC, Fragment } from 'react';
import { SynchronizationsContainer } from 'components/reports/Synchronizations/SynchronizationsContainer';
import { Page } from 'components/layout/panel/Page/Page';
import { useTranslator } from 'components/shared/hooks';
import { Breadcrumb } from 'components/shared/navigation/Breadcrumbs/Breadcrumb';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import { useStyles } from './Synchronizations.styles';

export const SynchronizationsReportView: FC = () => {
  const trans = useTranslator();
  const classes = useStyles();

  return (
    <Fragment>
      <Breadcrumb label={trans('BREADCRUMB.REPORTS.SYNCHRONIZATIONS')} static />

      <Page title={trans('REPORTS.SYNCHRONIZATIONS.TITLE')}>
        <Paper innerSpacing={2} outerSpacing={[0, 2, 2]} className={classes.paper}>
          <SynchronizationsContainer />
        </Paper>
      </Page>
    </Fragment>
  );
};

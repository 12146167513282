import { toSnake } from 'change-object-case';
import qs from 'query-string';

export const addPathParameter = (path: string, extraParams: { [key: string]: string | string[] }) => {
  const parsedUrl = qs.parseUrl(path);

  const newQuery = {
    ...parsedUrl.query,
    ...extraParams,
  };

  return qs.stringifyUrl({ ...parsedUrl, query: newQuery });
};

export const addEnvironmentOrigin = (path: string, origin: string) => {
  return addPathParameter(path, toSnake({ baseUrl: origin }));
};

import { RFC } from '@typings/extra-react-router';
import { Page } from 'components/layout/panel/Page/Page';
import { PublicationContainer } from 'components/polls/Publication/PublicationContainer';
import { useTranslator } from 'components/shared/hooks';
import { Breadcrumb } from 'components/shared/navigation/Breadcrumbs/Breadcrumb';
import { ROUTE_POLLS_LIST } from 'config/routes';
import { PollActionRoute } from 'config/routes.types';
import React, { Fragment } from 'react';

export const PublishView: RFC<PollActionRoute> = ({ match }) => {
  const trans = useTranslator();
  const pollId = match.params.id;

  return (
    <Fragment>
      <Breadcrumb label={trans('BREADCRUMB.POLLS.POLLS_LIST')} to={ROUTE_POLLS_LIST} />
      <Breadcrumb label={trans('BREADCRUMB.POLLS.POLL_PUBLISH')} static />

      <Page title={trans('POLLS.PUBLICATION.TITLE')}>
        <PublicationContainer pollId={pollId} />
      </Page>
    </Fragment>
  );
};

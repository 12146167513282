import { muiStyles } from 'utils/design';

export const useStyles = muiStyles((theme) => ({
  container: {
    width: '100%',
  },
  box: {
    display: 'flex',
    margin: theme.spacing(0, 1),
  },
  action: {
    width: '40%',
    flexShrink: 1,
    marginRight: '8px',
    '& button': {
      width: '100%',
    },
  },
  actionValue: {
    width: 'calc(60% - 8px)',
    flexGrow: 1,
    '& input': { textAlign: 'right' },
  },
}));

export enum TerytActionType {
  FETCH_VOIVODESHIPS_REQUEST = 'teryt/FETCH_VOIVODESHIPS_REQUEST',
  FETCH_VOIVODESHIPS_SUCCESS = 'teryt/FETCH_VOIVODESHIPS_SUCCESS',
  FETCH_VOIVODESHIPS_FAILURE = 'teryt/FETCH_VOIVODESHIPS_FAILURE',
  FETCH_COUNTIES_REQUEST = 'teryt/FETCH_COUNTIES_REQUEST',
  FETCH_COUNTIES_SUCCESS = 'teryt/FETCH_COUNTIES_SUCCESS',
  FETCH_COUNTIES_FAILURE = 'teryt/FETCH_COUNTIES_FAILURE',
  FETCH_COMMUNES_REQUEST = 'teryt/FETCH_COMMUNES_REQUEST',
  FETCH_COMMUNES_SUCCESS = 'teryt/FETCH_COMMUNES_SUCCESS',
  FETCH_COMMUNES_FAILURE = 'teryt/FETCH_COMMUNES_FAILURE',
  FETCH_TOWNS_REQUEST = 'teryt/FETCH_TOWNS_REQUEST',
  FETCH_TOWNS_SUCCESS = 'teryt/FETCH_TOWNS_SUCCESS',
  FETCH_TOWNS_FAILURE = 'teryt/FETCH_TOWNS_FAILURE',
  CLEAR_STREETS = 'teryt/CLEAR_STREETS',
  CLEAR_TOWNS = 'teryt/CLEAR_TOWNS',
  FETCH_STREETS_REQUEST = 'teryt/FETCH_STREETS_REQUEST',
  FETCH_STREETS_SUCCESS = 'teryt/FETCH_STREETS_SUCCESS',
  FETCH_STREETS_FAILURE = 'teryt/FETCH_STREETS_FAILURE',
  FETCH_TOWN_REQUEST = 'teryt/FETCH_TOWN_REQUEST',
  FETCH_TOWN_SUCCESS = 'teryt/FETCH_TOWN_SUCCESS',
  FETCH_TOWN_FAILURE = 'teryt/FETCH_TOWN_FAILURE',
  FETCH_STREET_REQUEST = 'teryt/FETCH_STREET_REQUEST',
  FETCH_STREET_SUCCESS = 'teryt/FETCH_STREET_SUCCESS',
  FETCH_STREET_FAILURE = 'teryt/FETCH_STREET_FAILURE',
  FETCH_COMMUNE_REQUEST = 'teryt/FETCH_COMMUNE_REQUEST',
  FETCH_COMMUNE_SUCCESS = 'teryt/FETCH_COMMUNE_SUCCESS',
  FETCH_COMMUNE_FAILURE = 'teryt/FETCH_COMMUNE_FAILURE',
}

import { Handlers } from 'components/polls/Browser/Browser.types';
import { ButtonDefinition } from 'components/shared/data/DataGrid/renderers/cell/Buttons/Buttons.types';
import { ColumnsConfig } from 'components/shared/data/DataGrid/DataGrid.types';
import { Poll, PollStatus } from 'store/polls/polls.types';
import { OfficialPermissionLevel, OfficialResourceGroup } from 'store/officials';

export const pollsBrowserColumns: ColumnsConfig<Poll, Handlers> = (handlers) => [
  {
    field: 'name',
    name: 'POLLS.BROWSER.COLUMNS.NAME',
    columnParams: { minWidth: 150 },
  },
  {
    field: 'status',
    name: 'POLLS.BROWSER.COLUMNS.STATUS',
    columnParams: { minWidth: 100 },
    format: (poll, trans) => {
      return trans(`POLLS.STATUS.${poll.status.toUpperCase()}`);
    },
    renderer: 'loadingCell',
    rendererParams: {
      isLoading: (data: Poll) => data.status === PollStatus.PUBLISHING,
    },
  },
  {
    field: 'expectedResponsesCount',
    name: 'POLLS.BROWSER.COLUMNS.EXPECTED_RESPONSES_COUNT',
    columnParams: { minWidth: 110 },
  },
  {
    field: 'responsesCount',
    name: 'POLLS.BROWSER.COLUMNS.RESPONSES_COUNT',
    columnParams: { minWidth: 90 },
  },
  {
    field: 'publishDate',
    name: 'POLLS.BROWSER.COLUMNS.PUBLISH_DATE',
    columnParams: { minWidth: 105 },
    format: ({ publishDate }, trans, formatter) => {
      return publishDate ? formatter.formatDateWithTime(publishDate) : '';
    },
  },
  {
    field: 'expirationDate',
    name: 'POLLS.BROWSER.COLUMNS.EXPIRATION_DATE',
    columnParams: { minWidth: 100 },
    format: ({ expirationDate }, trans, formatter) => {
      return expirationDate ? formatter.formatDate(expirationDate) : '';
    },
  },
  {
    field: 'id',
    name: 'POLLS.BROWSER.COLUMNS.ACTIONS',
    cellTooltip: () => '',
    columnParams: { minWidth: 330 },
    renderer: 'buttonsCell',
    rendererParams: {
      className: 'row',
      buttons: (poll: Poll) => {
        const buttons: ButtonDefinition[] = [];

        if (poll.status === PollStatus.DRAFT) {
          buttons.push({
            type: 'default',
            size: 'small',
            variant: 'outlined',
            canShow: {
              only: false,
              resourcesGroup: OfficialResourceGroup.polls,
              accessType: OfficialPermissionLevel.fullAccess,
            },
            icon: 'pencil-alt',
            label: 'COMMON.EDIT',
            tooltip: 'COMMON.EDIT',
            action: () => handlers.edit(poll),
            className: 'col-3',
          });
          buttons.push({
            type: 'default',
            size: 'small',
            variant: 'outlined',
            canShow: {
              only: false,
              resourcesGroup: OfficialResourceGroup.polls,
              accessType: OfficialPermissionLevel.fullAccess,
            },
            icon: 'paper-plane',
            label: 'COMMON.PUBLISH',
            tooltip: 'COMMON.PUBLISH',
            action: () => handlers.publish(poll),
            className: 'col-4',
          });
        }

        if (poll.status === PollStatus.PUBLISHED) {
          buttons.push({
            type: 'default',
            size: 'small',
            variant: 'outlined',
            icon: 'chart-pie',
            label: 'POLLS.BROWSER.RESULTS',
            tooltip: 'POLLS.BROWSER.RESULTS',
            action: () => handlers.results(poll),
            className: 'col-3',
          });
          buttons.push({
            type: 'default',
            size: 'small',
            variant: 'outlined',
            canShow: {
              only: false,
              resourcesGroup: OfficialResourceGroup.polls,
              accessType: OfficialPermissionLevel.fullAccess,
            },
            icon: 'stop',
            label: 'COMMON.FINISH',
            tooltip: 'COMMON.FINISH',
            action: () => handlers.close(poll),
            className: 'col-4',
          });
        }

        if (poll.status === PollStatus.CLOSED) {
          buttons.push({
            type: 'default',
            size: 'small',
            variant: 'outlined',
            icon: 'chart-pie',
            label: 'POLLS.BROWSER.RESULTS',
            tooltip: 'POLLS.BROWSER.RESULTS',
            action: () => handlers.results(poll),
            className: 'col-3',
          });
        }

        buttons.push({
          type: 'default',
          size: 'small',
          variant: 'outlined',
          canShow: {
            only: false,
            resourcesGroup: OfficialResourceGroup.polls,
            accessType: OfficialPermissionLevel.fullAccess,
          },
          label: 'COMMON.MORE',
          tooltip: 'COMMON.MORE',
          action: handlers.clone,
          actionList: [
            { label: 'COMMON.COPY', icon: 'copy', action: () => handlers.clone(poll) },
            { label: 'COMMON.DELETE', icon: 'trash', action: () => handlers.delete(poll) },
          ],
          className: 'col-4 pl-0 pr-0',
        });

        return buttons;
      },
    },
  },
];

import {
  assignedChildrenColumns,
  childrenWithAlertsColumns,
} from 'components/schoolDistricts/AssignmentPreview/SchoolRegister/columns.config';
import { IconButton } from '@material-ui/core';
import { TransferModal } from 'components/schoolDistricts/AssignmentPreview/SchoolRegister/TransferModal/TransferModal';
import { ActionButton } from 'components/shared/buttons/ActionButton/ActionButton';
import { useStyles } from 'components/schoolDistricts/AssignmentPreview/SchoolRegister/SchoolRegister.styles';
import {
  AlertsChildrenParams,
  AssignedChildrenParams,
  SchoolRegisterProps,
} from 'components/schoolDistricts/AssignmentPreview/SchoolRegister/SchoolRegister.types';
import { DataGrid } from 'components/shared/data/DataGrid/DataGrid';
import { Confirm } from 'components/shared/dialogs/Confirm/Confirm';
import { useTranslator } from 'components/shared/hooks';
import { useDepartmentSettings } from 'components/shared/hooks/useInstanceConfig/useDepartmentSettings';
import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon';
import { Heading } from 'components/shared/typography/Heading/Heading';
import React, { FC, useMemo, useState } from 'react';
import { AssignmentAlert, AssignmentSchoolModel, AssignmentStatus, DatabaseStatus } from 'store/schoolDistricts';

export const SchoolRegister: FC<SchoolRegisterProps> = ({
  data,
  redirectToChildrenList,
  redirectToChildrenAlertList,
  downloadChildrenList,
  downloadChildrenAlertList,
  transferChildrenList,
  refreshAssignment,
}) => {
  const trans = useTranslator();
  const classes = useStyles();
  const settings = useDepartmentSettings();
  const [listToTransfer, setListToTransfer] = useState<AssignmentSchoolModel>(null);
  const [transferModalOpen, setTransferModalOpen] = useState(false);

  const transferColumnEnabled =
    data.status === AssignmentStatus.OFFICIAL && settings.catchmentAssignmentTransferEnabled;

  const transferAllowed = data.status === AssignmentStatus.OFFICIAL && settings.catchmentAssignmentTransferEnabled;

  const actionParams: AssignedChildrenParams = {
    show: (school: AssignmentSchoolModel) => redirectToChildrenList(school.id),
    download: (school: AssignmentSchoolModel) => downloadChildrenList(school),
    transfer: (school: AssignmentSchoolModel) => setListToTransfer(school),
    transferColumnEnabled: transferColumnEnabled,
  };

  const alertsActionParams: AlertsChildrenParams = {
    show: (item: AssignmentAlert) => redirectToChildrenAlertList(item.name),
    download: (item: AssignmentAlert) => downloadChildrenAlertList(item.name),
  };

  const schoolsIdsForTransfer = useMemo((): string[] => {
    return data.schools.filter((school) => school.canTransfer).map((school) => school.id);
  }, [data]);

  return (
    <>
      <div className={classes.toolbar}>
        <Heading type="h6" className="mt-1">
          {trans('SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.SCHOOL_REGISTER.ASSIGNED_CHILDREN')}
        </Heading>
        {transferAllowed && (
          <ActionButton
            component={IconButton}
            disableCaret={true}
            variant="contained"
            color="primary"
            label={() => <FontAwesomeIcon name={'ellipsis-v'} className={'mx-2'} />}
            actionList={[
              {
                action: () => setTransferModalOpen(true),
                label: 'SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.SCHOOL_REGISTER.ACTIONS.TRANSFER_ALL',
                icon: 'file-export',
                tooltip: {
                  title: trans('SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.SCHOOL_REGISTER.TOOLTIPS.NOTHING_TO_TRANSFER'),
                  disabled: schoolsIdsForTransfer.length > 0,
                },
                props: {
                  disabled: schoolsIdsForTransfer.length === 0,
                },
              },
            ]}
          />
        )}
      </div>
      <Confirm
        open={listToTransfer != null}
        title={'SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.SCHOOL_REGISTER.TRANSFER.TITLE'}
        message={'SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.SCHOOL_REGISTER.TRANSFER.MESSAGE'}
        confirm={() => {
          transferChildrenList(listToTransfer);
          setListToTransfer(null);
        }}
        cancel={() => {
          setListToTransfer(null);
        }}
      />
      <TransferModal
        open={transferModalOpen}
        assignmentId={data.id}
        schoolIds={schoolsIdsForTransfer}
        onTransferEnd={() => {
          refreshAssignment();
          setTransferModalOpen(false);
        }}
        onClose={() => setTransferModalOpen(false)}
      />
      <DataGrid<AssignmentSchoolModel>
        className={classes.grid}
        data={data.schools}
        columns={assignedChildrenColumns(actionParams)}
        isLoading={false}
        loadingMessage={'SCHOOL_DISTRICTS.ASSIGMENT_BROWSER.RESULTS-LOADING'}
        noResultsMessage={'SCHOOL_DISTRICTS.ASSIGMENT_BROWSER.NOTHING-FOUND'}
      />
      {data.alerts && data.alerts.length > 0 && (
        <>
          <Heading type="h6" className="mt-4">
            {trans('SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.SCHOOL_REGISTER.ALERTS_CHILDREN')}
            <FontAwesomeIcon name={'exclamation-triangle'} className="ml-2" />
          </Heading>
          <DataGrid<AssignmentAlert>
            className={classes.grid}
            data={data.alerts}
            columns={childrenWithAlertsColumns(alertsActionParams)}
            isLoading={false}
            loadingMessage={'SCHOOL_DISTRICTS.ASSIGMENT_BROWSER.RESULTS-LOADING'}
            noResultsMessage={'SCHOOL_DISTRICTS.ASSIGMENT_BROWSER.NOTHING-FOUND'}
          />
        </>
      )}
    </>
  );
};

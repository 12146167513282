import React, { FC, useEffect, useState } from 'react';

import { Toolbar } from './Toolbar/Toolbar';

import { DataGrid } from 'components/shared/data/DataGrid/DataGrid';
import { DatabaseAdd } from 'components/schoolDistricts/DatabaseForm/DatabaseAddContainer';
import { DatabaseEdit } from 'components/schoolDistricts/DatabaseForm/DatabaseEditContainer';
import { Confirm } from 'components/shared/dialogs/Confirm/Confirm';
import { AssignmentDialog } from '../AssignmentDialog/AssignmentDialog';

import { databaseColumns } from './columns.config';
import { DatabaseProps, ActionHandlers } from './DatabaseBrowser.types';
import { DatabaseModelBrowserRow, DatabasePostModel, isDatabaseHeaderModel } from 'store/schoolDistricts';
import { useNotification } from 'components/shared/hooks/useNotification/useNotification';
import { useTranslator } from 'components/shared/hooks/useTranslator/useTranslator';

import { useStyles } from './DatabaseBrowser.styles';

export const DatabaseBrowser: FC<DatabaseProps> = (props) => {
  const trans = useTranslator();
  const classes = useStyles();
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isCurrentModalOpen, setCurrentModalOpen] = useState(false);
  const [isArchiveModalOpen, setArchiveModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState({ id: null, name: null });
  const [assignmentModalOpen, setAssignmentModalOpen] = useState(false);
  const { success, error } = useNotification();

  useEffect(() => {
    if (props.withOpenAddModal) {
      setAddModalOpen(true);
    }
  }, [props.withOpenAddModal]);

  const actionHandlers: ActionHandlers = {
    edit: (item) => {
      setEditModalOpen(true);
      setSelectedItem(item);
    },
    delete: (item) => {
      setDeleteModalOpen(true);
      setSelectedItem(item);
    },
    select: ({ id }) => props.selectItem(id),
    setCurrent: (item) => {
      setCurrentModalOpen(true);
      setSelectedItem(item);
    },
    setArchive: (item) => {
      setArchiveModalOpen(true);
      setSelectedItem(item);
    },
  };

  const handleAddItem = () => {
    setAddModalOpen(true);
  };

  const handleCloseAddModal = () => {
    props.fetchSchoolDistrictDatabase();
    setAddModalOpen(false);
  };

  const handleConfirmAddModal = (payload: DatabasePostModel) => {
    return props.addItem(payload);
  };

  const handleCloseEditModal = () => {
    props.fetchSchoolDistrictDatabase();
    setEditModalOpen(false);
    setSelectedItem({ id: null, name: null });
  };

  const handleConfirmEditModal = (payload: DatabasePostModel) => {
    return props.editItem(selectedItem.id, payload.name);
  };

  const handleConfirmSetCurrentModal = (id: string) => {
    return props.setCurrentItem(id).then((result) => {
      if (result.error) {
        error('SCHOOL_DISTRICTS.DATABASE-SET-CURRENT.FAIL');
      } else {
        props.fetchSchoolDistrictDatabase();
        success('SCHOOL_DISTRICTS.DATABASE-SET-CURRENT.SUCCESS');
        setCurrentModalOpen(false);
      }
    });
  };

  const handleConfirmSetArchiveModal = (id: string) => {
    return props.setArchiveItem(id).then((result) => {
      if (result.error) {
        error('SCHOOL_DISTRICTS.DATABASE-SET-ARCHIVE.FAIL');
      } else {
        props.fetchSchoolDistrictDatabase();
        success('SCHOOL_DISTRICTS.DATABASE-SET-ARCHIVE.SUCCESS');
        setArchiveModalOpen(false);
      }
    });
  };

  const handleConfirmDeleteModal = (id: string) => {
    const groupWithAssignments = 'CATCHMENT_AREA_GROUPS.CANT_DELETE_GROUP_WITH_ASSIGNMENTS';
    return props.deleteItem(id).then((result) => {
      if (result.error) {
        const errorMessage =
          result.payload.response.error === groupWithAssignments
            ? `SCHOOL_DISTRICTS.DATABASE-DELETE.${groupWithAssignments}`
            : 'SCHOOL_DISTRICTS.DATABASE-DELETE.FAIL';
        error(errorMessage);
      } else {
        props.fetchSchoolDistrictDatabase();
        success('SCHOOL_DISTRICTS.DATABASE-DELETE.SUCCESS');
        setDeleteModalOpen(false);
      }
    });
  };

  const handleAssignment = () => {
    setAssignmentModalOpen(true);
  };

  const handleAssignmentModalClose = () => {
    setAssignmentModalOpen(false);
  };

  const handleAssignmentOpen = (assignmentId: string) => {
    handleAssignmentModalClose();
    props.onOpenAssignment(assignmentId);
  };

  return (
    <>
      <Toolbar
        addItem={handleAddItem}
        onDivideChildren={handleAssignment}
        emptyDatabaseList={props.databases.length === 0}
      />
      <AssignmentDialog
        open={assignmentModalOpen}
        onClose={handleAssignmentModalClose}
        databases={props.databases}
        onOpenAssignment={handleAssignmentOpen}
      />
      <DataGrid<DatabaseModelBrowserRow>
        className={classes.grid}
        data={props.databasesGrid}
        columns={databaseColumns(actionHandlers)}
        isLoading={props.resultsLoading}
        loadingMessage={'SCHOOL_DISTRICTS.DATABASE.RESULTS-LOADING'}
        noResultsMessage={'SCHOOL_DISTRICTS.DATABASE.NOTHING-FOUND'}
        key={classes.databaseRow}
        getRowClass={(params) => {
          return isDatabaseHeaderModel(params.data) ? classes.headerRow : classes.databaseRow;
        }}
      />
      {isAddModalOpen && (
        <DatabaseAdd
          databases={props.databases}
          open={isAddModalOpen}
          onClose={handleCloseAddModal}
          onConfirm={handleConfirmAddModal}
        />
      )}
      {isEditModalOpen && (
        <DatabaseEdit
          id={selectedItem.id}
          name={selectedItem.name}
          open={isEditModalOpen}
          onClose={handleCloseEditModal}
          onConfirm={handleConfirmEditModal}
        />
      )}
      {props.databases.length > 0 && (
        <Confirm
          open={isCurrentModalOpen}
          title={'SCHOOL_DISTRICTS.DATABASE-SET-CURRENT.TITLE'}
          confirm={() => handleConfirmSetCurrentModal(selectedItem.id)}
          cancel={() => setCurrentModalOpen(false)}
        >
          <span className="text-center d-block mb-3">
            {trans('SCHOOL_DISTRICTS.DATABASE-SET-CURRENT.MESSAGE.CURRENT', { name: selectedItem.name })}
          </span>
          <span className="text-center d-block">{trans('SCHOOL_DISTRICTS.DATABASE-SET-CURRENT.MESSAGE.CONFIRM')}</span>
        </Confirm>
      )}
      {props.databases.length > 0 && (
        <Confirm
          open={isArchiveModalOpen}
          title={'SCHOOL_DISTRICTS.DATABASE-SET-ARCHIVE.TITLE'}
          confirm={() => handleConfirmSetArchiveModal(selectedItem.id)}
          cancel={() => setArchiveModalOpen(false)}
        >
          <span className="text-center d-block mb-3">
            {trans('SCHOOL_DISTRICTS.DATABASE-SET-ARCHIVE.MESSAGE.CURRENT', { name: selectedItem.name })}
          </span>
          <span className="text-center d-block">{trans('SCHOOL_DISTRICTS.DATABASE-SET-ARCHIVE.MESSAGE.CONFIRM')}</span>
        </Confirm>
      )}
      {props.databases.length > 0 && (
        <Confirm
          open={isDeleteModalOpen}
          title={'SCHOOL_DISTRICTS.DATABASE-DELETE.TITLE'}
          confirm={() => handleConfirmDeleteModal(selectedItem.id)}
          cancel={() => setDeleteModalOpen(false)}
        >
          <span className="text-center d-block mb-3">
            {trans('SCHOOL_DISTRICTS.DATABASE-DELETE.MESSAGE.DATABASE', { name: selectedItem.name })}
          </span>
          <span className="text-center d-block">{trans('SCHOOL_DISTRICTS.DATABASE-DELETE.MESSAGE.CONFIRM')}</span>
        </Confirm>
      )}
    </>
  );
};

import { AppDispatch } from '@typings/redux';
import { DocumentsProgressProps } from 'components/schoolObligation/ReportDetails/Documents/Documents.types';
import { ProgressModal } from 'components/shared/dialogs/ProgressModal/ProgressModal';
import { useTranslator } from 'components/shared/hooks';
import { useDownloader } from 'components/shared/hooks/useDownloader';
import { DocumentActionParams, getDocumentFile } from 'fetch/query/getDocumentFile';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearAssignmentRequest } from 'store/schoolDistricts';
import { documentRequestSelector, DocumentRequestStatus, DocumentStatus, fetchDocumentsStatus } from 'store/templates';

export const DocumentsProgress: FC<DocumentsProgressProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();
  const trans = useTranslator();
  const timer = useRef<number>();
  const downloader = useDownloader<DocumentActionParams>(getDocumentFile);
  const [isError, setError] = useState(false);

  const isStatusDone = (status: DocumentStatus) => {
    return [DocumentStatus.READY, DocumentStatus.BROKEN].includes(status);
  };

  const request = useSelector(documentRequestSelector);

  useEffect(() => {
    const updateStatus = async () => {
      const action = await dispatch(fetchDocumentsStatus(request.documentId));

      const requestStatus: DocumentRequestStatus = action.payload;

      if (requestStatus && !isStatusDone(requestStatus.status) && !action.error) {
        timer.current = window.setTimeout(() => updateStatus(), 1000);
      }

      if (requestStatus.status === DocumentStatus.BROKEN || action.error) {
        setError(true);
      }
    };

    if (request.documentId && props.open) {
      updateStatus();
    }

    return () => clearTimeout(timer.current);
  }, [request.documentId, dispatch, props.open]);

  const getMessage = () => {
    if (isError) {
      return trans('TEMPLATES.PROGRESS_MODAL.STEPS.ERROR');
    } else if (!request.documentId) {
      return trans('TEMPLATES.PROGRESS_MODAL.STEPS.INITIALIZATION');
    } else if (request.requestStatus.status === DocumentStatus.PENDING) {
      return trans('TEMPLATES.PROGRESS_MODAL.STEPS.PENDING');
    } else if (request.requestStatus.status === DocumentStatus.GENERATING) {
      return trans('TEMPLATES.PROGRESS_MODAL.STEPS.GENERATING');
    } else if (request.requestStatus.status === DocumentStatus.BROKEN) {
      return trans('TEMPLATES.PROGRESS_MODAL.STEPS.BROKEN');
    } else {
      if (props.isExport) {
        return trans('TEMPLATES.PROGRESS_MODAL.STEPS.EXPORTED_TO_DCS');
      } else {
        return trans('TEMPLATES.PROGRESS_MODAL.STEPS.READY');
      }
    }
  };

  const handleDownload = () => {
    clearTimeout(timer.current);
    downloader({ documentId: request.documentId });
    props.onClose();
    window.setTimeout(() => dispatch(clearAssignmentRequest()), 300);
  };

  const handleClose = () => {
    clearTimeout(timer.current);
    props.onClose();
    window.setTimeout(() => {
      setError(false);
      dispatch(clearAssignmentRequest());
    }, 300);
  };

  const status = request.requestStatus.status;

  return (
    <ProgressModal
      title={trans('TEMPLATES.PROGRESS_MODAL.TITLE')}
      open={props.open}
      description={getMessage()}
      progress={request.requestStatus.progress}
      error={isError}
      button={{
        action: isError || props.isExport ? handleClose : handleDownload,
        label:
          isError || props.isExport
            ? trans('TEMPLATES.PROGRESS_MODAL.CANCEL')
            : trans('TEMPLATES.PROGRESS_MODAL.DOWNLOAD'),
        disabled: !isError && !isStatusDone(status),
      }}
    />
  );
};

import { ICellRendererParams } from 'ag-grid-community';
import { Loader } from 'components/shared/loaders/Loader/Loader';
import { LoadingIcon } from 'components/shared/loaders/Loader/Loader.types';
import React, { FC } from 'react';

export interface LoadingCellProps extends ICellRendererParams {
  isLoading: (data: any) => boolean;
  icon: LoadingIcon;
}

export const LoadingCell: FC<LoadingCellProps> = ({ isLoading, icon = 'spinner', value, data }) => {
  return (
    <span>
      {isLoading(data) && <Loader name={icon} className="mr-2" />}
      {value}
    </span>
  );
};

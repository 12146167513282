import { resolveText } from 'components/shared/charts/charts.helpers';
import React from 'react';
import { measureText } from 'utils/string/measureText';
import { VictoryLabelProps, VictoryLabel } from 'victory';
import { useStyles } from './AxisLabel.styles';

export interface AxisLabelProps extends VictoryLabelProps {
  textWidth?: number;
  rotate?: number;
  setLabelState: any;
}

export const AxisLabelComponent = (props: AxisLabelProps) => {
  const classes = useStyles();

  const text = resolveText(props.text, props);
  const { width, height } = measureText(text, props.textWidth);
  const posX = props.x - width / 2;

  return (
    <foreignObject
      textAnchor="start"
      onMouseOver={() => props.setLabelState(props)}
      onMouseLeave={() => props.setLabelState(null)}
      x={props.rotate === 90 ? posX + width / 2 - 5 : posX}
      y={props.rotate === 90 ? props.y - 7 : props.y - 8}
      width={width}
      height={props.rotate === 90 ? 15 : height}
      className={classes.labelContainer}
      style={{
        textAlign: props.rotate === 90 ? 'left' : 'center',
        lineHeight: '0.75',
        textOverflow: 'ellipsis',
        transform: `rotate(${props.rotate}deg)`,
        transformOrigin: props.rotate > 0 ? `${props.x}px ${props.y}px` : 'initial',
      }}
    >
      <VictoryLabel {...props} style={{ padding: 0, fontSize: 6, fontFamily: 'Roboto', textOverflow: 'ellipsis' }} />
    </foreignObject>
  );
};

AxisLabelComponent.defaultEvents = VictoryLabel['defaultEvents'];

export const AxisLabel = AxisLabelComponent;

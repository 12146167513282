import React, { FC, useState } from 'react';
import { BackButton, NextButton, StepActions, StepContent } from 'components/shared/dialogs/StepModal';
import { useTranslator } from 'components/shared/hooks';
import { PreviewProps, PreviewSummary } from './Preview.types';
import { DataGrid } from 'components/shared/data/DataGrid/DataGrid';
import { previewColumns, summaryColumns } from './columns.config';
import { useStyles } from './Preview.styles';
import classNames from 'classnames';
import { Confirm } from 'components/shared/dialogs/Confirm/Confirm';
import { ChildImportModel, FileImportTypes } from 'store/childRegister';
import { TextLoader } from 'components/shared/loaders/TextLoader/TextLoader';
import { ProgressBar } from 'components/shared/loaders/ProgressBar/ProgressBar';

export const Preview: FC<PreviewProps> = ({
  goBack,
  previewRecords,
  isPreviewLoading,
  isPreviewGenerating,
  generationProgress,
  summaryRecords,
  onConfirmImport,
  postImportConfirm,
  archiveRecords,
  activeRecords,
  importType,
  contextMapping,
}) => {
  const trans = useTranslator();
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const classes = useStyles();

  const confirmImport = () => {
    postImportConfirm();
    onConfirmImport();
  };

  if (isPreviewGenerating) {
    return (
      <StepContent>
        <div className="my-5">
          <ProgressBar value={generationProgress} />
          <div className="mt-3">{trans('CHILD_REGISTER.IMPORT_STEPS.PREVIEW.PREVIEW-GENERATION')}</div>
        </div>
      </StepContent>
    );
  }

  if (isPreviewLoading) {
    return <TextLoader text={trans('CHILD_REGISTER.IMPORT_STEPS.PREVIEW.RESULTS-LOADING')} />;
  }

  return (
    <>
      <StepContent>
        <div className={classNames('container pb-1 pt-1 text-center', classes.container)}>
          <DataGrid<ChildImportModel>
            className={classes.grid}
            data={previewRecords}
            columns={previewColumns(contextMapping)}
            noResultsMessage={'CHILD_REGISTER.IMPORT_STEPS.PREVIEW.NOTHING-FOUND'}
            disableAutoSize
          />
        </div>
        <div className={classNames('container pb-1 mt-3 text-center', classes.summaryContainer)}>
          <DataGrid<PreviewSummary>
            className={classes.grid}
            data={summaryRecords}
            columns={summaryColumns()}
            noResultsMessage={'CHILD_REGISTER.IMPORT_STEPS.PREVIEW.NOTHING-FOUND'}
            headerHeight={0}
            getRowClass={(params) => {
              if (params.data.distinguishRow) {
                return classes.distinguishRow;
              }
              return '';
            }}
          />
        </div>
        {importType === FileImportTypes.FULL && archiveRecords > 0 && (
          <div className={classNames('container pb-1 mt-3 text-center', classes.warning)}>
            {trans('CHILD_REGISTER.IMPORT_STEPS.PREVIEW.ARCHIVE', { archive: archiveRecords, active: activeRecords })}
          </div>
        )}
        <Confirm
          open={isConfirmOpen}
          title={'CHILD_REGISTER.IMPORT_STEPS.PREVIEW.CONFIRM'}
          message={'CHILD_REGISTER.IMPORT_STEPS.PREVIEW.CONFIRM_MESSAGE'}
          confirm={confirmImport}
          cancel={() => setConfirmOpen(false)}
        />
      </StepContent>
      <StepActions>
        <BackButton onClick={goBack}>{trans('COMMON.PREVIOUS')}</BackButton>
        <NextButton onClick={() => setConfirmOpen(true)} disabled={isPreviewLoading || isPreviewGenerating}>
          {trans('CHILD_REGISTER.IMPORT_STEPS.PREVIEW.IMPORT')}
        </NextButton>
      </StepActions>
    </>
  );
};

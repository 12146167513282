/**
 * Define routers below:
 *
 *   export const ROUTE_INSTITUTIONS = '/panel/units/institutions',
 *   export const ROUTE_INSTITUTIONS_EDIT = '/panel/units/institutions/edit/:id',
 *
 * Add type for routes with parameters in routes.types.ts:
 *
 *   export interface InstitutionEditRoute {
 *     id: string;
 *   }
 *
 * Add it to the mapping in the same file:
 *
 *   export interface Params {
 *     ...
 *     [ROUTE_INSTITUTIONS_EDIT]: InstitutionEditRoute;
 *   }
 *
 * Then use them like this:
 *
 *   import { makePath } from 'utils/navigation/makePath';
 *   dispatch(push(ROUTE_INSTITUTIONS))
 *   dispatch(push(makePath(ROUTE_INSTITUTIONS_EDIT, {id: 1})))
 */

export const ROUTE_ROOT = '/';

export const ROUTE_PANEL = '/panel';

export const ROUTE_PANEL_EMPTY_PAGE = '/panel/no-access';
export const ROUTE_PANEL_MESSAGES = '/panel/messages';

export const ROUTE_INSTITUTIONS = '/panel/units/institutions';
export const ROUTE_INSTITUTION_EDIT = '/panel/units/institutions/:id';

export const ROUTE_INSTITUTION_UNIT = '/panel/units/institutions/unit';
export const ROUTE_INSTITUTION_UNIT_ADD = '/panel/units/institutions/unit/add';
export const ROUTE_INSTITUTION_UNIT_EDIT = '/panel/units/institutions/unit/:id';

export const ROUTE_STUDENTS_REGISTER_DETAILS = '/panel/units/institutions/unit/:id/students-register/:registerId';
export const ROUTE_STUDENTS_REGISTER_ROLLBACK =
  '/panel/units/institutions/unit/:id/students-register/:registerId/:date';
export const ROUTE_UNIT_LIST_IN_INSTITUTIONAL_CORE_GRANT = `${window.location.origin}/institutional-core-grant/select-units-for-financial-year`;

export const ROUTE_KINDERGARTENS_BROWSER = '/panel/units/kindergartens';

export const ROUTE_CHILD_REGISTER = '/panel/child-register';
export const ROUTE_CHILD_REGISTER_ADD = '/panel/child-register/add';
export const ROUTE_CHILD_REGISTER_EDIT = '/panel/child-register/:id';
export const ROUTE_CHILD_REGISTER_EDIT_EDUCATION = '/panel/child-register/:id/education';

export const ROUTE_SCHOOL_DISTRICTS = '/panel/school-districts';
export const ROUTE_SCHOOL_DISTRICTS_DATABASE = '/panel/school-districts/database';
export const ROUTE_SCHOOL_DISTRICTS_LIST = '/panel/school-districts/list';
export const ROUTE_SCHOOL_DISTRICTS_CATCHMENT_ASSIGNMENT_LIST = '/panel/school-districts/catchment-assigment/list';

export const ROUTE_SCHOOL_CATCHMENT_ASSIGNMENT_PREVIEW = '/panel/school-districts/catchment-assigment/:id';
export const ROUTE_SCHOOL_CHILDREN_LIST = '/panel/school-districts/catchment-assigment/:id/school/:schoolId';
export const ROUTE_SCHOOL_CHILDREN_ALERT_LIST = '/panel/school-districts/catchment-assigment/:id/alert/:alert';

export const ROUTE_SCHOOL_OBLIGATION = '/panel/school-obligation';
export const ROUTE_SCHOOL_OBLIGATION_REPORTS = '/panel/school-obligation/reports';
export const ROUTE_SCHOOL_OBLIGATION_REPORT_DETAILS = '/panel/school-obligation/reports/:id';
export const ROUTE_SCHOOL_OBLIGATION_REPORT_DETAILS_OVERVIEW = '/panel/school-obligation/reports/:id/overview';
export const ROUTE_SCHOOL_OBLIGATION_REPORT_DETAILS_CHILDREN_LIST = '/panel/school-obligation/reports/:id/children';

export const ROUTE_TEMPLATES = '/panel/templates';
export const ROUTE_TEMPLATES_LIST = '/panel/templates/list';

export const ROUTE_POLLS = '/panel/polls';
export const ROUTE_POLLS_LIST = '/panel/polls/list';
export const ROUTE_POLLS_CREATE = '/panel/polls/create';
export const ROUTE_POLLS_EDIT = '/panel/polls/:id';
export const ROUTE_POLLS_PUBLISH = '/panel/polls/:id/publish';
export const ROUTE_POLLS_RESULTS = '/panel/polls/:id/results';
export const ROUTE_POLLS_RESULTS_RESPONSES = '/panel/polls/:id/results/responses';
export const ROUTE_POLLS_RESULTS_RECIPIENTS = '/panel/polls/:id/results/recipients';

export const ROUTE_SETTINGS = '/panel/settings';
export const ROUTE_SETTINGS_SYSTEM = '/panel/settings/system';

export const ROUTE_AUTH = '/auth';
export const ROUTE_AUTH_LOGIN = '/auth';
export const ROUTE_AUTH_AUTHORIZE = '/auth/authorize';
export const ROUTE_AUTH_SSO = '/auth/sso/:action';

export const ROUTE_QUESTIONNAIRES = '/questionnaires';
export const ROUTE_QUESTIONNAIRES_FORM = '/questionnaires/:pollId/:token';

export const ROUTE_REPORTS = '/panel/reports';
export const ROUTE_REPORTS_AUDIT_LOG = '/panel/reports/audit-log';

export const ROUTE_STATISTIC = '/panel/reports/schools';
export const ROUTE_STATISTIC_DETAILS = '/panel/reports/schools/:id';
export const ROUTE_OTHER_COMMUNE = '/panel/reports/other-commune';
export const ROUTE_SUBVENTION_FORECAST = '/panel/reports/subvention-forecast';
export const ROUTE_SYNCHRONIZATIONS_REPORT = '/panel/reports/synchronizations';

export const ROUTE_OFFICIALS = '/panel/officials';
export const ROUTE_OFFICIALS_LIST = '/panel/officials/list';
export const ROUTE_OFFICIALS_ADD = '/panel/officials/add';
export const ROUTE_OFFICIALS_EDIT = '/panel/officials/:id';
export const ROUTE_APPLICANTS_EDIT = '/panel/officials/applicants/:id';

export const ROUTE_SEARCH = '/panel/search';

export const ROUTE_NOT_FOUND = '/404';

import { getSpecialValueSlug } from 'components/reports/SchoolStatDetails/helpers';
import { DateFormat } from 'components/shared/hooks/useFormatter/useFormatter.types';
import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon';
import { Splash } from 'components/shared/surfaces/Splash/Splash';
import React, { FC, useState } from 'react';
import { Summary, SummaryItem } from 'components/shared/data/Summary/Summary';
import { useFormatter, useTranslator } from 'components/shared/hooks';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import {
  ReportSpecialValues,
  SchoolStatDetailsProps,
} from 'components/reports/SchoolStatDetails/SchoolStatDetails.types';
import { ActionButton } from 'components/shared/buttons/ActionButton/ActionButton';
import { Box } from 'components/shared/surfaces/Box/Box';
import { Page } from 'components/layout/panel/Page/Page';
import { Breadcrumb } from 'components/shared/navigation/Breadcrumbs/Breadcrumb';
import { paramCase } from 'change-case';
import { processCellFactory, generateSheetName } from 'components/shared/data/DataGrid/utils/exportHelpers';
import { TabPanel } from 'components/shared/surfaces/TabPanel/TabPanel';
import { StatGrid } from './tabs/StatGrid';
import { StatTree } from './tabs/StatTree';
import { StatChart } from './tabs/StatChart';
import { SchoolStatType } from 'store/reports/reports.types';

export const SchoolStatDetails: FC<SchoolStatDetailsProps> = (props) => {
  const { name, updateDate, table, type, isLoading } = props;
  const [gridApi, setGridApi] = useState(null);

  const trans = useTranslator();
  const { formatDateWithTime, formatDateWithTemplate } = useFormatter();

  const reportRootName = trans(getSpecialValueSlug(ReportSpecialValues.REPORT_ROOT));

  const exportParams = {
    fileName: trans('REPORTS.SCHOOL_STAT_DETAILS.EXPORT.FILE_NAME', {
      statistic: paramCase(name),
      date: formatDateWithTemplate(DateFormat.EUROPEAN_FILE_SAFE, new Date(updateDate)),
    }),
    processCellCallback: processCellFactory(reportRootName),
  };

  const getStatByType = (type: SchoolStatType): React.ReactNode => {
    switch (type) {
      case 'table':
        return <StatGrid isLoading={isLoading} table={table} setGridApi={setGridApi} />;
      case 'tree':
        return <StatTree name={name} table={table} setGridApi={setGridApi} />;
      case 'agg-tree':
        return <StatTree name={name} table={table} setGridApi={setGridApi} />;
      default:
        return <></>;
    }
  };

  if (table === null) {
    return (
      <>
        <Breadcrumb label={name} static />
        <Page title={name}>
          <Paper outerSpacing={[0, 2, 2]}>
            <Splash className={'flex-row'}>
              <FontAwesomeIcon name={'exclamation-triangle'} className={'mr-2'} />
              {trans('REPORTS.SCHOOL_STAT_DETAILS.NOT_SYNCHRONIZED')}
            </Splash>
          </Paper>
        </Page>
      </>
    );
  }

  const tabs = [
    {
      title: trans('REPORTS.SCHOOL_STAT_DETAILS.TABS.GRID'),
      key: 'grid',
      content: getStatByType(type),
    },
  ];

  if (type === 'table') {
    tabs.push({
      title: trans('REPORTS.SCHOOL_STAT_DETAILS.TABS.CHART'),
      key: 'chart',
      content: <StatChart isLoading={isLoading} table={table} statName={name} />,
    });
  }

  return (
    <>
      <Breadcrumb label={name} static />
      <Page title={name}>
        <Box margin={[0, 2, 2]} grow>
          <div className="mt-2 d-flex justify-content-between">
            <Summary linear={true}>
              <SummaryItem title={trans('REPORTS.SCHOOL_STAT_DETAILS.DATE')}>
                {formatDateWithTime(updateDate)}
              </SummaryItem>
            </Summary>
            <ActionButton
              color="default"
              label="REPORTS.SCHOOL_STAT_DETAILS.EXPORT.TITLE"
              actionList={[
                {
                  action: () => gridApi && gridApi.exportDataAsCsv(exportParams),
                  label: 'REPORTS.SCHOOL_STAT_DETAILS.EXPORT.CSV',
                  icon: 'file-csv',
                },
                {
                  action: () =>
                    gridApi &&
                    gridApi.exportDataAsExcel({
                      ...exportParams,
                      sheetName: generateSheetName(
                        name,
                        formatDateWithTemplate(DateFormat.EUROPEAN_FILE_SAFE, new Date(updateDate)),
                      ),
                    }),
                  label: 'REPORTS.SCHOOL_STAT_DETAILS.EXPORT.EXCEL',
                  icon: 'file-excel',
                },
              ]}
            />
          </div>
          <TabPanel tabs={tabs} defaultTab={'grid'} />
        </Box>
      </Page>
    </>
  );
};

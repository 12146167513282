import { colors } from 'styles/colors';
import { muiStyles } from 'utils/design';

export const useStyles = muiStyles((theme) => ({
  alert: {
    marginLeft: theme.spacing(1),
    color: theme.palette.error.main,
  },
  warning: {
    marginLeft: theme.spacing(1),
    color: colors.orange,
  },
  tooltip: {
    whiteSpace: 'pre-line',
  },
  address: {
    border: `1px solid ${colors.gridBorder}`,
    padding: theme.spacing(2, 3, 1),
    margin: theme.spacing(2, 0),
    borderRadius: '4px',
  },
}));

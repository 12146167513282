import { CheckBox } from 'components/shared/form';
import React, { useState } from 'react';
import { Heading } from 'components/shared/typography/Heading/Heading';
import { useTranslator } from 'components/shared/hooks';
import { TownPickerContainer } from 'components/shared/teryt/TownPicker/TownPickerContainer';
import { AddressPickerContainer } from 'components/shared/teryt/AddressPicker/AddressPickerContainer';
import { Button } from 'components/shared/buttons/Button/Button';
import { AddressHistoryContainer } from 'components/childRegister/Editor/AddressHistory/AddressHistoryContainer';
import { AlertChildModel } from 'store/childRegister';
import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon';
import { Tooltip } from 'components/shared/messages/Tooltip/Tooltip';
import { useStyles } from 'components/childRegister/Editor/General/parts/Address.styles';

interface AddressProps {
  id?: string;
  alerts: AlertChildModel[];
}

export const Address = ({ id, alerts }: AddressProps) => {
  const [isModalOpened, setModalOpen] = useState(false);
  const trans = useTranslator();
  const classes = useStyles();

  const renderAlertIcon = () => {
    const hasAlert = alerts.some((alert) => alert.name === 'ALERTS.CHILD_REGISTER.MISSING_ADDRESS');
    return (
      <Tooltip
        title={alerts
          .reverse()
          .map((alert) => trans(alert.name))
          .join('\n')}
        placement="right"
        disabled={false}
        classes={{ tooltip: classes.tooltip }}
      >
        <span>
          {hasAlert ? (
            <FontAwesomeIcon name={'exclamation-triangle'} className={classes.alert} />
          ) : (
            <FontAwesomeIcon name={'info-circle'} className={classes.warning} />
          )}
        </span>
      </Tooltip>
    );
  };

  return (
    <div className="container-fluid mt-4 pb-3">
      <Heading type="h5">
        {trans('CHILD_REGISTER.FORM.ADDRESS_TITLE')}
        {alerts.length > 0 && renderAlertIcon()}
      </Heading>
      <div className={`mt-4 ${classes.address}`} data-testid="residenceAddress">
        <Heading type="h6" className="mb-4">
          {trans('CHILD_REGISTER.FORM.RESIDENCE.TITLE')}
        </Heading>
        <div className="row mt-2">
          <TownPickerContainer
            fieldName="residence.city"
            required
            allowEmptyCountry
            afterRender={() => (
              <CheckBox name={`isResident`} labelText={trans('CHILD_REGISTER.FORM.IS_RESIDENT.LABEL')} />
            )}
          />
        </div>
        <div className="row mt-2">
          <AddressPickerContainer
            fieldName="residence.street"
            townFieldName="residence.city"
            addressFieldName="residence"
            fullAddress
          />
        </div>
      </div>
      <div className={`mt-4 ${classes.address}`} data-testid="permanentAddress">
        <Heading type="h6" className="mb-4">
          {trans('CHILD_REGISTER.FORM.PERMANENT_RESIDENCE.TITLE')}
        </Heading>
        <div className="row mt-2">
          <TownPickerContainer
            fieldName="permanentResidence.city"
            required
            afterRender={() => (
              <CheckBox
                name={`isPermanentResident`}
                labelText={trans('CHILD_REGISTER.FORM.IS_PERMANENT_RESIDENT.LABEL')}
              />
            )}
          />
        </div>
        <div className="row mt-2">
          <AddressPickerContainer
            fieldName="permanentResidence.street"
            townFieldName="permanentResidence.city"
            addressFieldName="permanentResidence"
            fullAddress
          />
        </div>
      </div>
      {id && (
        <div className="mt-4">
          <Button onClick={() => setModalOpen(true)} color="secondary" variant="outlined">
            {trans('CHILD_REGISTER.ADDRESS_HISTORY.BUTTON')}
          </Button>
          <AddressHistoryContainer open={isModalOpened} onClose={() => setModalOpen(false)} id={id} />
        </div>
      )}
    </div>
  );
};

import { General } from 'components/childRegister/Editor/General/General';
import {
  ChildRegisterFormValues,
  GeneralFormContainerProps,
} from 'components/childRegister/Editor/General/General.types';
import { connect } from 'formik';
import React, { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import { guardianTypesListSelector } from 'store/childRegister';

export const GeneralContainer: ComponentType<GeneralFormContainerProps> = connect<
  GeneralFormContainerProps,
  ChildRegisterFormValues
>((props) => {
  const guardianList = useSelector(guardianTypesListSelector);

  return (
    <General
      form={props.formik}
      initial={props.initial}
      loading={props.loading}
      successMessage="CHILD_REGISTER.FORM.SUCCESS"
      guardianTypes={guardianList.map((type) => ({ label: type, value: type }))}
      id={props.id}
      alerts={props.alerts}
      showGuardiansHelp={props.showGuardiansHelp}
    />
  );
});

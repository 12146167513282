export enum SchoolDistrictsActionType {
  FETCH_DATABASE_LIST_REQUEST = 'schoolDistricts/FETCH_DATABASE_LIST_REQUEST',
  FETCH_DATABASE_LIST_SUCCESS = 'schoolDistricts/FETCH_DATABASE_LIST_SUCCESS',
  FETCH_DATABASE_LIST_FAILURE = 'schoolDistricts/FETCH_DATABASE_LIST_FAILURE',

  CREATE_DATABASE_REQUEST = 'schoolDistricts/CREATE_DATABASE_REQUEST',
  CREATE_DATABASE_SUCCESS = 'schoolDistricts/CREATE_DATABASE_SUCCESS',
  CREATE_DATABASE_FAILURE = 'schoolDistricts/CREATE_DATABASE_FAILURE',

  EDIT_DATABASE_REQUEST = 'schoolDistricts/EDIT_DATABASE_REQUEST',
  EDIT_DATABASE_SUCCESS = 'schoolDistricts/EDIT_DATABASE_SUCCESS',
  EDIT_DATABASE_FAILURE = 'schoolDistricts/EDIT_DATABASE_FAILURE',

  SET_CURRENT_DATABASE_REQUEST = 'schoolDistricts/SET_CURRENT_DATABASE_REQUEST',
  SET_CURRENT_DATABASE_SUCCESS = 'schoolDistricts/SET_CURRENT_DATABASE_SUCCESS',
  SET_CURRENT_DATABASE_FAILURE = 'schoolDistricts/SET_CURRENT_DATABASE_FAILURE',

  SET_ARCHIVE_DATABASE_REQUEST = 'schoolDistricts/SET_ARCHIVE_DATABASE_REQUEST',
  SET_ARCHIVE_DATABASE_SUCCESS = 'schoolDistricts/SET_ARCHIVE_DATABASE_SUCCESS',
  SET_ARCHIVE_DATABASE_FAILURE = 'schoolDistricts/SET_ARCHIVE_DATABASE_FAILURE',

  DELETE_DATABASE_REQUEST = 'schoolDistricts/DELETE_DATABASE_REQUEST',
  DELETE_DATABASE_SUCCESS = 'schoolDistricts/DELETE_DATABASE_SUCCESS',
  DELETE_DATABASE_FAILURE = 'schoolDistricts/DELETE_DATABASE_FAILURE',

  FETCH_SCHOOL_LIST_REQUEST = 'schoolDistricts/FETCH_SCHOOL_LIST_REQUEST',
  FETCH_SCHOOL_LIST_SUCCESS = 'schoolDistricts/FETCH_SCHOOL_LIST_SUCCESS',
  FETCH_SCHOOL_LIST_FAILURE = 'schoolDistricts/FETCH_SCHOOL_LIST_FAILURE',

  SET_SCHOOL_LIST_FILTERS = 'schoolDistricts/SET_SCHOOL_LIST_FILTERS',

  FETCH_ADDRESS_LIST_REQUEST = 'schoolDistricts/FETCH_ADDRESS_LIST_REQUEST',
  FETCH_ADDRESS_LIST_SUCCESS = 'schoolDistricts/FETCH_ADDRESS_LIST_SUCCESS',
  FETCH_ADDRESS_LIST_FAILURE = 'schoolDistricts/FETCH_ADDRESS_LIST_FAILURE',

  CREATE_ADDRESS_RULE_REQUEST = 'schoolDistricts/CREATE_ADDRESS_RULE_REQUEST',
  CREATE_ADDRESS_RULE_SUCCESS = 'schoolDistricts/CREATE_ADDRESS_RULE_SUCCESS',
  CREATE_ADDRESS_RULE_FAILURE = 'schoolDistricts/CREATE_ADDRESS_RULE_FAILURE',

  EDIT_ADDRESS_RULE_REQUEST = 'schoolDistricts/EDIT_ADDRESS_RULE_REQUEST',
  EDIT_ADDRESS_RULE_SUCCESS = 'schoolDistricts/EDIT_ADDRESS_RULE_SUCCESS',
  EDIT_ADDRESS_RULE_FAILURE = 'schoolDistricts/EDIT_ADDRESS_RULE_FAILURE',

  FETCH_ADDRESS_RULE_REQUEST = 'schoolDistricts/FETCH_ADDRESS_RULE_REQUEST',
  FETCH_ADDRESS_RULE_SUCCESS = 'schoolDistricts/FETCH_ADDRESS_RULE_SUCCESS',
  FETCH_ADDRESS_RULE_FAILURE = 'schoolDistricts/FETCH_ADDRESS_RULE_FAILURE',

  CLEAR_ADDRESS_LIST = 'schoolDistricts/CLEAR_ADDRESS_LIST',

  DELETE_ADDRESS_RULE_REQUEST = 'schoolDistricts/DELETE_ADDRESS_RULE_REQUEST',
  DELETE_ADDRESS_RULE_SUCCESS = 'schoolDistricts/DELETE_ADDRESS_RULE_SUCCESS',
  DELETE_ADDRESS_RULE_FAILURE = 'schoolDistricts/DELETE_ADDRESS_RULE_FAILURE',

  CREATE_ASSIGNMENT_REQUEST = 'schoolDistricts/CREATE_ASSIGNMENT_REQUEST',
  CREATE_ASSIGNMENT_SUCCESS = 'schoolDistricts/CREATE_ASSIGNMENT_SUCCESS',
  CREATE_ASSIGNMENT_FAILURE = 'schoolDistricts/CREATE_ASSIGNMENT_FAILURE',

  CLEAR_ASSIGNMENT_REQUEST = 'schoolDistricts/CLEAR_ASSIGNMENT_REQUEST',

  FETCH_ASSIGNING_STATUS_REQUEST = 'schoolDistricts/FETCH_ASSIGNING_STATUS_REQUEST',
  FETCH_ASSIGNING_STATUS_SUCCESS = 'schoolDistricts/FETCH_ASSIGNING_STATUS_SUCCESS',
  FETCH_ASSIGNING_STATUS_FAILURE = 'schoolDistricts/FETCH_ASSIGNING_STATUS_FAILURE',

  FETCH_ASSIGNING_DETAILS_REQUEST = 'schoolDistricts/FETCH_ASSIGNING_DETAILS_REQUEST',
  FETCH_ASSIGNING_DETAILS_SUCCESS = 'schoolDistricts/FETCH_ASSIGNING_DETAILS_SUCCESS',
  FETCH_ASSIGNING_DETAILS_FAILURE = 'schoolDistricts/FETCH_ASSIGNING_DETAILS_FAILURE',

  FETCH_CATCHMENT_ASSIGNMENTS_REQUEST = 'schoolDistricts/FETCH_CATCHMENT_ASSIGNMENTS_REQUEST',
  FETCH_CATCHMENT_ASSIGNMENTS_SUCCESS = 'schoolDistricts/FETCH_CATCHMENT_ASSIGNMENTS_SUCCESS',
  FETCH_CATCHMENT_ASSIGNMENTS_FAILURE = 'schoolDistricts/FETCH_CATCHMENT_ASSIGNMENTS_FAILURE',

  DELETE_CATCHMENT_ASSIGNMENTS_REQUEST = 'schoolDistricts/DELETE_CATCHMENT_ASSIGNMENTS_REQUEST',
  DELETE_CATCHMENT_ASSIGNMENTS_SUCCESS = 'schoolDistricts/DELETE_CATCHMENT_ASSIGNMENTS_SUCCESS',
  DELETE_CATCHMENT_ASSIGNMENTS_FAILURE = 'schoolDistricts/DELETE_CATCHMENT_ASSIGNMENTS_FAILURE',

  PUBLISH_ASSIGNMENT_REQUEST = 'schoolDistricts/PUBLISH_ASSIGNMENT_REQUEST',
  PUBLISH_ASSIGNMENT_SUCCESS = 'schoolDistricts/PUBLISH_ASSIGNMENT_SUCCESS',
  PUBLISH_ASSIGNMENT_FAILURE = 'schoolDistricts/PUBLISH_ASSIGNMENT_FAILURE',

  FETCH_CHILDREN_LIST_REQUEST = 'schoolDistricts/FETCH_CHILDREN_LIST_REQUEST',
  FETCH_CHILDREN_LIST_SUCCESS = 'schoolDistricts/FETCH_CHILDREN_LIST_SUCCESS',
  FETCH_CHILDREN_LIST_FAILURE = 'schoolDistricts/FETCH_CHILDREN_LIST_FAILURE',

  DOWNLOAD_CHILDREN_LIST_REQUEST = 'schoolDistricts/DOWNLOAD_CHILDREN_LIST_REQUEST',
  DOWNLOAD_CHILDREN_LIST_SUCCESS = 'schoolDistricts/DOWNLOAD_CHILDREN_LIST_SUCCESS',
  DOWNLOAD_CHILDREN_LIST_FAILURE = 'schoolDistricts/DOWNLOAD_CHILDREN_LIST_FAILURE',

  TRANSFER_CHILDREN_LIST_REQUEST = 'schoolDistricts/TRANSFER_CHILDREN_LIST_REQUEST',
  TRANSFER_CHILDREN_LIST_SUCCESS = 'schoolDistricts/TRANSFER_CHILDREN_LIST_SUCCESS',
  TRANSFER_CHILDREN_LIST_FAILURE = 'schoolDistricts/TRANSFER_CHILDREN_LIST_FAILURE',

  FETCH_CHILDREN_ALERT_LIST_REQUEST = 'schoolDistricts/FETCH_CHILDREN_ALERT_LIST_REQUEST',
  FETCH_CHILDREN_ALERT_LIST_SUCCESS = 'schoolDistricts/FETCH_CHILDREN_ALERT_LIST_SUCCESS',
  FETCH_CHILDREN_ALERT_LIST_FAILURE = 'schoolDistricts/FETCH_CHILDREN_ALERT_LIST_FAILURE',

  CLEAR_CHILDREN_LIST = 'schoolDistricts/CLEAR_CHILDREN_LIST',
}

import {
  reportOverviewColumns,
  reportOverviewGroupingColumn,
} from 'components/schoolObligation/ReportDetails/ReportOverview/columns.config';
import { useStyles } from 'components/schoolObligation/ReportDetails/ReportOverview/ReportOverview.styles';
import {
  ReportOverviewProps,
  ReportOverviewRow,
} from 'components/schoolObligation/ReportDetails/ReportOverview/ReportOverview.types';
import { DataGrid } from 'components/shared/data/DataGrid/DataGrid';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { routerSelector } from 'store/router/router.selectors';
import { ReportCounterName } from 'store/schoolObligations';

export const ReportOverview: FC<ReportOverviewProps> = (props) => {
  const classes = useStyles();
  const locationKey = useSelector(routerSelector).key;

  return (
    <DataGrid<ReportOverviewRow>
      key={locationKey}
      className={classes.grid}
      data={props.data}
      columns={reportOverviewColumns({ years: props.years })}
      groupingColumn={reportOverviewGroupingColumn()}
      getRowClass={(params) => (params.data.name === ReportCounterName.TOTAL ? 'footer' : '')}
      treeHierarchy={(data) => data.path}
    />
  );
};

import useTheme from '@material-ui/core/styles/useTheme';
import {
  AutocompleteProps,
  isSingleSelection,
  OptionType,
} from 'components/shared/form/Autocomplete/Autocomplete.types';
import { Field, FieldProps } from 'formik';
import React, { useContext, useRef } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { selectStyles, useStyles } from './Autocomplete.styles';
import {
  ClearIndicator,
  Control,
  DropdownIndicator,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
} from './components';
import { LocaleContext } from 'context/locale';
import { get } from 'lodash';

// noinspection JSUnusedGlobalSymbols
const autocompleteComponents = {
  Control,
  DropdownIndicator,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  ClearIndicator,
};

export const Autocomplete = <T extends OptionType>({
  name,
  validate = () => undefined,
  options,
  displayedOptions,
  placeholder,
  onChange,
  disabled,
  selectedLabel = 'label',
  onInputChange,
  menuIsOpen,
  isLoading = false,
  alwaysShowPlaceholder = false,
  labelText = '',
  findOption = (option, value) => option.value === value,
  required,
  createable = false,
  onMenuOpen,
  onMenuClose,
  filterOption,
  newOptionMaxLength,
  noOptionsMessage = 'COMMON.AUTOCOMPLETE.NO_OPTIONS',
  isMulti = false,
  isNewOptionValid = () => true,
  showNoOptionsMessage = true,
  formatOptionLabel,
  preventScrollToSelectedOption = false,
  ...rest
}: AutocompleteProps<T>) => {
  const classes = useStyles();
  const theme = useTheme();
  const { trans } = useContext(LocaleContext);
  const autoselectRef = useRef<any>();

  const SelectComponent: React.ComponentType<any> = createable ? CreatableSelect : Select;

  return (
    <Field name={name} validate={validate}>
      {({ field, form }: FieldProps) => {
        const splittedName = name.split('.');
        const formFieldName = `${splittedName[0]}.${splittedName[1]}`;

        const isError = !!get(form.errors, formFieldName);

        const changeHandler = (selection: any) => {
          const fieldValue = get(form.values, formFieldName);
          if (get(form.errors, formFieldName) && fieldValue?.id !== selection?.value) {
            form.setFieldError(formFieldName, undefined);
          }

          if (!selection) {
            form.setFieldValue(field.name, null);
          } else if (isSingleSelection(selection)) {
            form.setFieldValue(field.name, selection.value);
          }

          if (onChange) {
            onChange(selection);
          }
        };

        const getValue = () => {
          if (options && field.value) {
            const selectedOption = options.find((option) => findOption(option, field.value));
            return selectedOption;
          }
          return null;
        };

        return (
          <SelectComponent
            options={displayedOptions || options}
            value={getValue()}
            onChange={(selection) => changeHandler(selection)}
            onInputChange={onInputChange}
            components={autocompleteComponents}
            classes={classes}
            onBlur={field.onBlur}
            styles={selectStyles(theme)}
            isClearable={true}
            controlParams={{
              InputLabelProps: {
                shrink: alwaysShowPlaceholder && !field.value ? undefined : true,
              },
              form,
              field,
              disabled,
              required,
              name,
              labelText: alwaysShowPlaceholder ? placeholder : labelText,
              error: isError,
              errorField: isError ? formFieldName : null,
              ...rest,
            }}
            placeholder={alwaysShowPlaceholder ? '' : placeholder}
            data-testid={`autocomplete-${name}`}
            isDisabled={disabled}
            required={required}
            selectedLabel={selectedLabel}
            isLoading={isLoading}
            loadingMessage={() => trans('COMMON.AUTOCOMPLETE.LOADING')}
            noOptionsMessage={({ inputValue }) => {
              return newOptionMaxLength && inputValue.length > newOptionMaxLength
                ? trans('COMMON.AUTOCOMPLETE.TOO_LONG', { value: newOptionMaxLength })
                : !showNoOptionsMessage
                ? null
                : trans(noOptionsMessage);
            }}
            className={classes.container}
            formatCreateLabel={(value) => trans('COMMON.AUTOCOMPLETE.CREATE', { value })}
            formatOptionLabel={formatOptionLabel && formatOptionLabel}
            menuIsOpen={menuIsOpen}
            onMenuOpen={() => {
              setTimeout(() => {
                const selectRef = autoselectRef.current.select;
                if (preventScrollToSelectedOption && selectRef) {
                  if (selectRef.select) {
                    selectRef.select.scrollToFocusedOptionOnUpdate = false;
                  } else {
                    selectRef.scrollToFocusedOptionOnUpdate = false;
                  }
                }

                onMenuOpen && onMenuOpen(autoselectRef);
              });
            }}
            onMenuClose={() => onMenuClose && onMenuClose(autoselectRef)}
            ref={autoselectRef}
            filterOption={filterOption}
            isValidNewOption={(newValue: string) => {
              if (newValue.length < 3) {
                return false;
              }
              if (!newOptionMaxLength || newValue.length > newOptionMaxLength) {
                return false;
              }
              return isNewOptionValid(newValue);
            }}
            isMulti={isMulti}
            allowCreateWhileLoading={false}
          />
        );
      }}
    </Field>
  );
};

import { useStyles } from 'components/polls/Browser/Browser.styles';
import { BrowserProps, Handlers } from 'components/polls/Browser/Browser.types';
import { pollsBrowserColumns } from 'components/polls/Browser/columns.config';
import { Toolbar } from 'components/polls/Browser/Toolbar/Toolbar';
import { DataGrid } from 'components/shared/data/DataGrid/DataGrid';
import { Confirm } from 'components/shared/dialogs/Confirm/Confirm';
import React, { FC, useState } from 'react';
import { Poll } from 'store/polls';

export const Browser: FC<BrowserProps> = (props) => {
  const { initialFilters, onFiltersUpdate, onPollCreate, polls, isLoading } = props;
  const classes = useStyles();
  const [pollToDelete, setPollToDelete] = useState<Poll>(null);
  const [pollToClone, setPollToClone] = useState<Poll>(null);
  const [pollToClose, setPollToClose] = useState<Poll>(null);

  const handlers: Handlers = {
    edit: (poll: Poll) => {
      props.onPollEdit(poll);
    },
    clone: (poll: Poll) => {
      setPollToClone(poll);
    },
    delete: (poll: Poll) => {
      setPollToDelete(poll);
    },
    publish: (poll: Poll) => {
      props.onPollPublication(poll);
    },
    close: (poll: Poll) => {
      setPollToClose(poll);
    },
    results: (poll: Poll) => {
      props.onPollResultsShow(poll);
    },
  };

  return (
    <div className={classes.container}>
      <Toolbar initial={initialFilters} onFiltersUpdate={onFiltersUpdate} onPollCreate={onPollCreate} />
      <DataGrid
        columns={pollsBrowserColumns(handlers)}
        data={polls}
        isLoading={isLoading}
        suppressScrollOnNewData={true}
        className={classes.grid}
      />

      <Confirm
        title={'POLLS.CLOSE.TITLE'}
        message={'POLLS.CLOSE.MESSAGE'}
        open={pollToClose !== null}
        confirm={() => {
          props.onPollClose(pollToClose);
          setPollToClose(null);
        }}
        cancel={() => setPollToClose(null)}
      />

      <Confirm
        title={'POLLS.CLONE.TITLE'}
        message={'POLLS.CLONE.MESSAGE'}
        open={pollToClone !== null}
        confirm={() => {
          props.onPollClone(pollToClone);
          setPollToClone(null);
        }}
        cancel={() => setPollToClone(null)}
      />

      <Confirm
        title={'POLLS.DELETE.TITLE'}
        message={'POLLS.DELETE.MESSAGE'}
        open={pollToDelete !== null}
        confirm={() => {
          props.onPollDelete(pollToDelete);
          setPollToDelete(null);
        }}
        cancel={() => setPollToDelete(null)}
      />
    </div>
  );
};

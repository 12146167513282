import { ToolbarProps } from 'components/polls/Browser/Browser.types';
import { useStyles } from 'components/polls/Browser/Toolbar/Toolbar.styles';
import Access from 'components/shared/access/access';
import { Button } from 'components/shared/buttons/Button/Button';
import { Form, SelectBox } from 'components/shared/form';
import { OptionType } from 'components/shared/form/Autocomplete/Autocomplete.types';
import { AutoSave } from 'components/shared/form/AutoSave/AutoSave';
import { useTranslator } from 'components/shared/hooks';
import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon';
import React, { FC } from 'react';
import { OfficialPermissionLevel, OfficialResourceGroup } from 'store/officials';
import { PollFilters, PollStatus } from 'store/polls/polls.types';

export const Toolbar: FC<ToolbarProps> = ({ initial, onFiltersUpdate, onPollCreate }) => {
  const trans = useTranslator();
  const classes = useStyles();

  const handleSubmit = (values) => {
    onFiltersUpdate(values);
  };

  const statusOptions: OptionType[] = [
    {
      label: trans('POLLS.STATUS.DRAFT'),
      value: PollStatus.DRAFT,
    },
    {
      label: trans('POLLS.STATUS.PUBLISHING'),
      value: PollStatus.PUBLISHING,
    },
    {
      label: trans('POLLS.STATUS.PUBLISHED'),
      value: PollStatus.PUBLISHED,
    },
    {
      label: trans('POLLS.STATUS.CLOSED'),
      value: PollStatus.CLOSED,
    },
  ];

  return (
    <div className={classes.toolbar}>
      <Form<PollFilters> initialValues={initial} onSubmit={handleSubmit} className={classes.form}>
        {() => {
          return (
            <div className={classes.filters}>
              <SelectBox
                name="status"
                displayEmpty
                resettable
                placeholderText={trans('POLLS.FILTERS.STATUS.PLACEHOLDER')}
                labelText={trans('POLLS.FILTERS.STATUS.LABEL')}
                options={statusOptions}
              />
              <AutoSave />
            </div>
          );
        }}
      </Form>
      <Access resourcesGroup={OfficialResourceGroup.polls} accessType={OfficialPermissionLevel.fullAccess}>
        <Button variant="contained" color="primary" onClick={() => onPollCreate()} tabIndex={0}>
          <FontAwesomeIcon name={'plus-circle'} className={'mr-2'} />
          {trans('POLLS.BROWSER.CREATE_POLL')}
        </Button>
      </Access>
    </div>
  );
};

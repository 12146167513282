import React, { FC, useRef } from 'react';

import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

import { Form, InputText, SelectBox, CheckBox } from 'components/shared/form';
import { FormikHelpers } from 'formik';
import { useNotification } from 'components/shared/hooks/useNotification/useNotification';
import { useFormatter } from 'components/shared/hooks/useFormatter/useFormatter';

import { DatabaseFormProps } from './DatabaseForm.types';
import { DatabaseFormModel } from 'store/schoolDistricts';

import { useTranslator } from 'components/shared/hooks/useTranslator/useTranslator';
import { required } from 'utils/formValidators';
import { GroupedOptionType } from 'components/shared/form/Autocomplete/Autocomplete.types';
import { toGroupedOptions } from 'utils/api';

export const DatabaseForm: FC<DatabaseFormProps> = (props) => {
  const trans = useTranslator();
  const form = useRef(null);
  const { formatApiErrors } = useFormatter();
  const { success, error } = useNotification();

  const handleOnSubmit = (values: DatabaseFormModel, formik: FormikHelpers<DatabaseFormModel>) => {
    props.onConfirm({ clone: values.clone, name: values.name }).then((result) => {
      if (result.error) {
        error(props.errorMessage);
        result.payload.response.errors && formik.setErrors(formatApiErrors(result.payload.response.errors));
        formik.setSubmitting(false);
      } else {
        success(props.successMessage);
        props.onClose();
      }
    });
  };

  const databaseGroupHeader = ({ label: databaseStatus }) => {
    return trans(`SCHOOL_DISTRICTS.DISTRICTS-LIST.DATABASES-TYPES.${databaseStatus.toUpperCase()}`);
  };

  const items = toGroupedOptions(props.databases, 'id', 'name', 'status');

  return (
    <Dialog fullWidth disableEscapeKeyDown aria-labelledby="confirm-title" maxWidth="sm" open={props.open}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <Form<DatabaseFormModel>
          initialValues={{ name: props.name || '', isCloned: false, clone: null }}
          onSubmit={handleOnSubmit}
          innerRef={form}
        >
          {({ values }) => (
            <>
              {!props.hideDatabaseSelector && (
                <>
                  <CheckBox
                    name="isCloned"
                    labelText={trans('SCHOOL_DISTRICTS.DATABASE-ADD.CLONE')}
                    disabled={items.length <= 0}
                  />
                  <SelectBox<GroupedOptionType>
                    name="clone"
                    placeholderText={trans('SCHOOL_DISTRICTS.DATABASE-ADD.SELECT-DATABASE')}
                    options={items}
                    disabled={!values.isCloned}
                    displayEmpty
                    validate={values.isCloned && required(trans('COMMON.FORM.REQUIRED'))}
                    headerComponent={databaseGroupHeader}
                    className="mt-2"
                  />
                </>
              )}
              <InputText name="name" labelText={trans('SCHOOL_DISTRICTS.DATABASE-ADD.NAME')} required />
            </>
          )}
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="secondary" variant="outlined" data-testid="confirm-no">
          {trans('COMMON.CANCEL')}
        </Button>
        <Button
          onClick={() => !form.current.isSubmitting && form.current.handleSubmit()}
          color="secondary"
          variant="contained"
          data-testid="confirm-yes"
        >
          {trans('COMMON.SAVE')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

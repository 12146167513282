import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormAnswer,
  fetchPollResponse,
  pollResponsesSelector,
  QuestionnaireQuestion,
  QuestionType,
  Response,
  ResponseAnswer,
  ResponseForm,
} from 'store/polls';

interface PollResponseHook {
  (pollId: string, responsesIds: string[], page: number): {
    loading: boolean;
    response: Response;
    questions: QuestionnaireQuestion[];
    formValues: ResponseForm;
  };
}

const formatAnswers = (answer: ResponseAnswer): FormAnswer => {
  if (answer.question.type === QuestionType.MULTIPLE_CHOICE) {
    return { index: answer.question.index, result: answer.answers };
  } else {
    return { index: answer.question.index, result: answer.answers[0] };
  }
};

const dataPlaceholder = { loading: true, response: null, questions: null, formValues: null };

export const usePollResponse: PollResponseHook = (pollId, responsesIds, page) => {
  const dispatch = useDispatch();
  const responsesData = useSelector(pollResponsesSelector);

  const currentResponseId = responsesIds[page - 1];

  useEffect(() => {
    if (currentResponseId) {
      dispatch(fetchPollResponse(pollId, currentResponseId));
    }
  }, [dispatch, pollId, currentResponseId, page]);

  if (!responsesData[currentResponseId] || responsesData[currentResponseId].loading) {
    return dataPlaceholder;
  }

  const { loading, response } = responsesData[currentResponseId];

  const questions = response.answers.map((answer) => answer.question);
  const responseValues = response.answers.map(formatAnswers);

  return { loading, response, questions, formValues: { answers: responseValues } };
};

import { useTranslator } from 'components/shared/hooks';
import { useStyles } from 'components/shared/navigation/Switcher/Switcher.styles';
import React, { FC } from 'react';
import { IconButton } from 'components/shared/buttons/Button/IconButton';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import classNames from 'classnames';

export interface SwitcherProps {
  page: number;
  total: number;
  onChange: (page: number) => void;
  className?: string;
}

export const Switcher: FC<SwitcherProps> = ({ page, total, onChange, className = '' }) => {
  const classes = useStyles();
  const trans = useTranslator();

  return (
    <div className={classNames(classes.switcher, className)}>
      <IconButton onClick={() => onChange(page - 1)} disabled={page === 1}>
        <ArrowLeftIcon />
      </IconButton>
      <div className={classes.counter}>{trans('COMMON.PAGE_FROM_TOTAL', { page, total })}</div>
      <IconButton onClick={() => onChange(page + 1)} disabled={page === total}>
        <ArrowRightIcon />
      </IconButton>
    </div>
  );
};

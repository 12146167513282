import { AppAction, AppMiddleware } from '@typings/redux';
import { isRSAA, RSAA } from 'redux-api-middleware';

export const fixParamsMiddleware: AppMiddleware = () => (next) => (action: AppAction) => {
  const newAction = { ...action };

  if (isRSAA(newAction)) {
    newAction[RSAA].params = newAction[RSAA].params ? newAction[RSAA].params : {};
  }

  return next(newAction);
};

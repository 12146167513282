import { Column, ColumnsConfig } from 'components/shared/data/DataGrid/DataGrid.types';
import { ButtonDefinition } from 'components/shared/data/DataGrid/renderers/cell/Buttons/Buttons.types';
import {
  DocumentModel,
  DocumentRecipientModel,
  ChildHandlers,
  RecipientColumnsParams,
} from 'components/shared/dialogs/DocumentModal/DocumentModal.types';
import { Translator } from 'context/locale/LocaleContext/LocaleContext.types';
import { translateTemplateField } from 'utils/i18n/templateFields';

export const hasChildAlert = (row: DocumentModel, forAllRecipients = false) => {
  if (forAllRecipients) {
    return row.recipients.every((recipient) => recipient.alerts.length > 0);
  } else {
    return row.recipients.some((recipient) => recipient.alerts.length > 0);
  }
};

export const hasMissingInRegisterAlert = (recipient: DocumentRecipientModel) => {
  return recipient && recipient.alerts.includes('ALERTS.TEMPLATES.MISSING_IN_REGISTER');
};

export const hasRecipientMissingInRegisterAlert = (child: DocumentModel) => {
  return child && child.recipients.some(hasMissingInRegisterAlert);
};

export const hasRecipientAlert = (row: DocumentRecipientModel) => {
  return row.alerts.length > 0;
};

export const childColumns: ColumnsConfig<DocumentModel, ChildHandlers> = (handlers) => {
  return [
    {
      field: 'id',
      name: 'COMMON.GRID.SELECT_ALL',
      columnParams: {
        checkboxSelection: true,
        minWidth: 50,
        maxWidth: 50,
      },
      format: () => '',
      cellTooltip: () => '',
      headerRenderer: 'selectAllHeader',
      cellClass: 'checkboxGrid',
    },
    {
      field: 'id',
      name: () => '',
      renderer: 'agGroupCellRenderer',
      columnParams: {
        minWidth: 50,
        maxWidth: 50,
      },
      format: () => '',
      cellTooltip: () => '',
    },
    {
      field: 'alerts',
      name: 'CHILD_REGISTER.BROWSER.COLUMNS.ALERTS',
      columnParams: { minWidth: 80, maxWidth: 80 },
      cellTooltip: () => '',
      renderer: 'iconsCell',
      rendererParams: {
        icons: (row: DocumentModel) => {
          if (hasChildAlert(row)) {
            return [
              {
                type: 'default',
                icon: 'exclamation-triangle',
                tooltip: (trans) => trans('ALERTS.TEMPLATES.RECIPIENT_PROBLEM'),
              },
            ];
          }

          return [];
        },
      },
    },
    {
      name: 'COMMON.ACTIONS',
      field: 'child.id',
      cellTooltip: () => '',
      renderer: 'buttonsCell',
      rendererParams: {
        buttons: (row): ButtonDefinition[] => {
          return [
            {
              type: 'default',
              icon: 'pencil-alt',
              tooltip: hasRecipientMissingInRegisterAlert(row) ? 'ALERTS.TEMPLATES.MISSING_IN_REGISTER' : 'COMMON.EDIT',
              action: handlers.edit,
              disabled: hasRecipientMissingInRegisterAlert(row),
            },
          ];
        },
      },
      columnParams: { maxWidth: 80 },
    },
    {
      field: 'child.firstName',
      name: 'CHILD_REGISTER.BROWSER.COLUMNS.FIRST_NAME',
    },
    {
      field: 'child.lastName',
      name: 'CHILD_REGISTER.BROWSER.COLUMNS.LAST_NAME',
    },
    {
      field: 'child.identificationNumber',
      name: 'CHILD_REGISTER.BROWSER.COLUMNS.IDENTIFICATION_NUMBER',
    },
  ];
};

export const recipientColumns: ColumnsConfig<DocumentRecipientModel, RecipientColumnsParams> = (parameters) => {
  return [
    {
      name: 'TEMPLATES.MODAL.RECIPIENT_DATA',
      children: [
        {
          field: 'id',
          name: () => '',
          columnParams: {
            checkboxSelection: true,
            maxWidth: 50,
            minWidth: 50,
          },
          format: () => '',
          cellTooltip: () => '',
          cellClass: 'checkboxGrid',
        },
        {
          field: 'alerts',
          name: 'CHILD_REGISTER.BROWSER.COLUMNS.ALERTS',
          cellTooltip: () => '',
          columnParams: {
            maxWidth: 80,
            width: 80,
          },
          renderer: 'iconsCell',
          rendererParams: {
            icons: (item: DocumentRecipientModel) => {
              if (hasRecipientAlert(item)) {
                return [
                  {
                    type: 'default',
                    icon: 'exclamation-triangle',
                    tooltip: (trans) => item.alerts.map(trans).join(', '),
                  },
                ];
              }

              return [];
            },
          },
        },
        {
          name: 'COMMON.ACTIONS',
          field: 'id',
          cellTooltip: () => '',
          renderer: 'buttonsCell',
          columnParams: { maxWidth: 80 },
          rendererParams: {
            buttons: (row): ButtonDefinition[] => {
              return [
                {
                  type: 'default',
                  icon: 'pencil-alt',
                  tooltip: hasMissingInRegisterAlert(row) ? 'ALERTS.TEMPLATES.MISSING_IN_REGISTER' : 'COMMON.EDIT',
                  action: parameters.edit,
                  disabled: hasMissingInRegisterAlert(row),
                },
              ];
            },
          },
        },
        {
          field: 'guardian.firstName',
          name: 'CHILD_REGISTER.BROWSER.COLUMNS.FIRST_NAME',
        },
        {
          field: 'guardian.lastName',
          name: 'CHILD_REGISTER.BROWSER.COLUMNS.LAST_NAME',
        },
      ],
    },
    {
      name: 'TEMPLATES.MODAL.TEMPLATE_DATA',
      children: parameters.fields.map((field): Column<DocumentRecipientModel> => {
        return {
          field: `data.${field}`,
          name: (trans: Translator) => translateTemplateField(field, trans),
          headerClass: parameters.fieldColumnHeaderClass,
        };
      }),
    },
  ];
};

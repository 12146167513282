import React, { FC, useEffect } from 'react';
import { DialogTitle, DialogContent, DialogActions, Dialog } from '@material-ui/core';
import { Button } from 'components/shared/buttons/Button/Button';
import { LocationModalProps, LocationForm, FormField } from './LocationModal.types';
import { InputText, QuietForm } from 'components/shared/form';
import { FormikProps } from 'formik';
import { Autocomplete } from 'components/shared/form/Autocomplete/Autocomplete';
import { isFieldEnabled, resetFieldsBelow } from './LocationModal.helpers';
import { required } from 'utils/formValidators';
import { useStyles } from './LocationModal.styles';
import classNames from 'classnames';
import { OptionType } from 'components/shared/form/Autocomplete/Autocomplete.types';
import { useTranslator } from 'components/shared/hooks';
import { InfoOutlined } from '@material-ui/icons';
import { nullableEmptyString } from 'utils/string/provideStrings';

export const LocationModal: FC<LocationModalProps> = ({
  open,
  onClose,
  initialValues,
  countriesList,
  onSubmit,
  voivodeshipsList,
  countiesList,
  communesList,
  townsList,
  fetchCountiesList,
  fetchVoivodeshipsList,
  fetchCommunesList,
  fetchTownsList,
  isVoivodeshipsListLoading,
  isCountiesListLoading,
  isCommunesListLoading,
  isTownsListLoading,
  allowEmptyCountry,
}) => {
  const trans = useTranslator();
  const classes = useStyles();

  useEffect(() => {
    fetchVoivodeshipsList({});
    if (initialValues.town) {
      fetchCountiesList({ voivodeship: initialValues.voivodeship });
      fetchCommunesList({ county: initialValues.county });
      fetchTownsList({ commune: initialValues.commune });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchVoivodeshipsList, fetchCountiesList, fetchCommunesList, fetchTownsList]);

  const handleSubmit = (values: LocationForm) => {
    onClose();
    if (values.country === 'PL') {
      onSubmit(values.town);
    } else {
      onSubmit({
        name: values.foreignTown,
        country: nullableEmptyString(values.country),
      });
    }
  };

  const formFields: FormField[] = [
    {
      name: 'voivodeship',
      options: voivodeshipsList,
      label: trans('TERYT.TOWN.LOCATION.VOIVODESHIP'),
      loading: isVoivodeshipsListLoading,
    },
    {
      name: 'county',
      options: countiesList,
      label: trans('TERYT.TOWN.LOCATION.COUNTY'),
      loading: isCountiesListLoading,
    },
    {
      name: 'commune',
      options: communesList,
      label: trans('TERYT.TOWN.LOCATION.COMMUNE'),
      loading: isCommunesListLoading,
    },
    {
      name: 'town',
      options: townsList,
      label: trans('TERYT.TOWN.LOCATION.TOWN'),
      loading: isTownsListLoading,
      findOption: (option, value) => option.value.id === value.id,
    },
  ];

  return (
    <QuietForm<LocationForm> initialValues={initialValues} onSubmit={handleSubmit}>
      {(formProps: FormikProps<LocationForm>) => {
        const mapFieldChangeToUpdate = (field: keyof LocationForm, selectedOption: OptionType) => {
          const updates = {
            country: false,
            voivodeship: () => fetchCountiesList({ voivodeship: selectedOption.value }),
            county: () => fetchCommunesList({ county: selectedOption.value }),
            commune: () => fetchTownsList({ commune: selectedOption.value }),
            town: false,
          };

          selectedOption && selectedOption.value && updates[field] && updates[field]();
        };

        const isFieldDisabled = (field: keyof LocationForm): boolean => {
          const result = !isFieldEnabled(field, formProps.values);
          return result;
        };

        const handleOnChange = (field: keyof LocationForm, value) => {
          resetFieldsBelow(field, formProps.setFieldValue);
          mapFieldChangeToUpdate(field, value);
        };

        return (
          <Dialog open={open} onClose={onClose} data-testid="locationModal" classes={{ paper: classes.paperContainer }}>
            <DialogTitle>{trans('TERYT.TOWN.LOCATION.TITLE')}</DialogTitle>
            <DialogContent classes={{ root: classes.allowOverflow }}>
              <div className="row pb-1">
                <div className={classNames('d-flex col-5 align-items-center', classes.label)}>
                  {trans('TERYT.TOWN.LOCATION.COUNTRY')}
                </div>
                <div className="d-flex col-7 align-items-center">
                  <Autocomplete
                    name="country"
                    options={countriesList}
                    placeholder={trans('COMMON.AUTOCOMPLETE.SELECT')}
                    labelText=""
                    onChange={(selection) => handleOnChange('country', selection)}
                    validate={!allowEmptyCountry ? required(trans('COMMON.FORM.REQUIRED')) : undefined}
                  />
                </div>
              </div>
              {formProps.values.country === 'PL' ? (
                formFields.map((field) => (
                  <div className="row pb-1" key={field.name}>
                    <div className={classNames('d-flex col-5 align-items-center', classes.label)}>{field.label}</div>
                    <div className="d-flex col-7 align-items-center">
                      <Autocomplete
                        name={field.name}
                        options={field.options}
                        labelText=""
                        placeholder={trans('COMMON.AUTOCOMPLETE.SELECT')}
                        onChange={(selection) => handleOnChange(field.name, selection)}
                        disabled={field.loading || isFieldDisabled(field.name)}
                        validate={required(trans('COMMON.FORM.REQUIRED'))}
                        hasIcon={field.loading || isFieldDisabled(field.name)}
                        icon={<InfoOutlined fontSize="small" />}
                        tooltipText={trans('INSTITUTIONS.UNIT_DATA.TOWN.FILL_PREVIOUS')}
                        findOption={field.findOption}
                      />
                    </div>
                  </div>
                ))
              ) : (
                <div className="row pb-1">
                  <div className={classNames('d-flex col-5 align-items-center', classes.label)}>
                    {trans('TERYT.TOWN.LOCATION.TOWN')}
                  </div>
                  <div className="d-flex col-7 align-items-center">
                    <InputText
                      name="foreignTown"
                      labelText=""
                      validate={!allowEmptyCountry && required(trans('COMMON.FORM.REQUIRED'))}
                      disabled={!formProps.values.country}
                    />
                  </div>
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="secondary" variant="outlined">
                {trans('COMMON.CANCEL')}
              </Button>
              <Button
                onClick={() => formProps.handleSubmit()}
                color="secondary"
                variant="contained"
                disabled={!formProps.isValid && formProps.dirty}
                data-testid="locationModal-save"
              >
                {trans('COMMON.SAVE')}
              </Button>
            </DialogActions>
          </Dialog>
        );
      }}
    </QuietForm>
  );
};

import { Theme } from '@material-ui/core';

export const panelThemeFactory = (masterTheme: Theme) => ({
  ...masterTheme,
  overrides: {
    ...masterTheme.overrides,
    MuiTypography: {
      h1: { fontSize: '2.0rem' },
      h2: { fontSize: '1.875rem' },
      h3: { fontSize: '1.6rem' },
      h4: { fontSize: '1.55rem' },
      h5: { fontSize: '1.3rem' },
      h6: { fontSize: '1.1rem' },
    },
  },
});

import { Action } from 'redux';
import { isRSAA, RSAA } from 'redux-api-middleware';
import { AppAction, AppDispatch, AppMiddleware } from '../../@typings/redux';
import { toApiFormat } from '../../utils/api/converters';
import { isPlainObject } from 'lodash';

const formatBody = (body: object) => {
  return isPlainObject(body) ? toApiFormat(body) : body;
};

export const camelCaseMiddleware: AppMiddleware = () => {
  return (next: AppDispatch) => (action: AppAction) => {
    if (!isRSAA(action)) {
      return next(action as Action);
    }

    const apiCall = action[RSAA];

    return next({
      [RSAA]: {
        ...apiCall,
        body: apiCall.body ? formatBody(apiCall.body as object) : null,
        params: apiCall.params ? toApiFormat(apiCall.params) : null,
      },
    } as unknown as Action); // TypeScript hack
  };
};

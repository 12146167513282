import { Behaviours, FSAReducer } from '@typings/redux';
import { RESET_STATE } from 'store/store';
import { insertPage } from 'utils/pagination/insertPage';

import { SchoolDistrictsActionType as ActionType } from './schoolDistricts.actionTypes';
import { databasesListOrder, defaultState, SchoolDistrictsState as State } from './schoolDistricts.state';

import { fromApiFormat } from 'utils/api';

export const schoolDistrictsReducer: FSAReducer<State, ActionType> = (state = defaultState, action) => {
  const behaviours: Behaviours<State, ActionType> = {
    [RESET_STATE]: () => ({
      ...defaultState,
    }),
    [ActionType.FETCH_DATABASE_LIST_REQUEST]: () => {
      return {
        ...state,
        databases: {
          ...state.databases,
          loading: true,
        },
      };
    },
    [ActionType.FETCH_DATABASE_LIST_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        databases: {
          ...state.databases,
          items: fromApiFormat(payload.items).sort(databasesListOrder),
          loading: false,
        },
      };
    },
    [ActionType.FETCH_DATABASE_LIST_FAILURE]: ({ error }) => {
      return {
        ...state,
        databases: {
          ...state.databases,
          loading: false,
          error: error,
        },
      };
    },
    [ActionType.FETCH_SCHOOL_LIST_REQUEST]: () => {
      return {
        ...state,
        schools: {
          ...state.schools,
          loading: true,
        },
      };
    },
    [ActionType.FETCH_SCHOOL_LIST_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        schools: {
          ...state.schools,
          items: fromApiFormat(payload.items),
          loading: false,
        },
      };
    },
    [ActionType.FETCH_SCHOOL_LIST_FAILURE]: ({ error }) => {
      return {
        ...state,
        schools: {
          ...state.schools,
          loading: false,
          error: error,
        },
      };
    },
    [ActionType.SET_SCHOOL_LIST_FILTERS]: ({ payload }) => {
      return {
        ...state,
        schools: {
          ...state.schools,
          filters: payload,
        },
      };
    },
    [ActionType.FETCH_ADDRESS_LIST_REQUEST]: () => {
      return {
        ...state,
        addresses: {
          ...state.addresses,
          loading: true,
        },
      };
    },
    [ActionType.FETCH_ADDRESS_LIST_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        addresses: {
          ...state.addresses,
          items: fromApiFormat(payload.items),
          loading: false,
        },
      };
    },
    [ActionType.FETCH_ADDRESS_LIST_FAILURE]: ({ error }) => {
      return {
        ...state,
        addresses: {
          ...state.addresses,
          loading: false,
          error: error,
        },
      };
    },
    [ActionType.FETCH_ADDRESS_RULE_REQUEST]: () => {
      return {
        ...state,
        address: {
          ...state.address,
          loading: true,
        },
      };
    },
    [ActionType.FETCH_ADDRESS_RULE_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        address: {
          ...state.address,
          item: fromApiFormat(payload),
          loading: false,
        },
      };
    },
    [ActionType.FETCH_ADDRESS_RULE_FAILURE]: ({ error }) => {
      return {
        ...state,
        address: {
          ...state.address,
          loading: false,
          error: error,
        },
      };
    },
    [ActionType.CLEAR_ADDRESS_LIST]: () => {
      return {
        ...state,
        addresses: {
          ...state.addresses,
          items: [],
          loading: false,
        },
      };
    },
    [ActionType.FETCH_CATCHMENT_ASSIGNMENTS_REQUEST]: () => {
      return {
        ...state,
        assignments: {
          items: [],
          loading: true,
        },
      };
    },
    [ActionType.FETCH_CATCHMENT_ASSIGNMENTS_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        assignments: {
          items: fromApiFormat(payload.items),
          loading: false,
        },
      };
    },
    [ActionType.FETCH_CATCHMENT_ASSIGNMENTS_FAILURE]: ({ error }) => {
      return {
        ...state,
        assignments: {
          ...state.assignments,
          loading: false,
          error,
        },
      };
    },
    [ActionType.CLEAR_ASSIGNMENT_REQUEST]: () => {
      return {
        ...state,
        assignmentRequest: {
          ...defaultState.assignmentRequest,
        },
      };
    },
    [ActionType.CREATE_ASSIGNMENT_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        assignmentRequest: {
          ...state.assignmentRequest,
          assignmentId: payload.id,
          loading: false,
        },
      };
    },
    [ActionType.CREATE_ASSIGNMENT_FAILURE]: ({ error }) => {
      return {
        ...state,
        assignmentRequest: {
          ...state.assignmentRequest,
          loading: false,
          error,
        },
      };
    },
    [ActionType.FETCH_ASSIGNING_STATUS_REQUEST]: () => {
      return {
        ...state,
        assignmentRequest: {
          ...state.assignmentRequest,
          statusInfo: {
            ...state.assignmentRequest.statusInfo,
          },
          loading: true,
        },
      };
    },
    [ActionType.FETCH_ASSIGNING_STATUS_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        assignmentRequest: {
          ...state.assignmentRequest,
          statusInfo: payload,
          loading: false,
        },
      };
    },
    [ActionType.FETCH_ASSIGNING_STATUS_FAILURE]: ({ error }) => {
      return {
        ...state,
        assignmentRequest: {
          ...state.assignmentRequest,
          error,
        },
      };
    },
    [ActionType.FETCH_ASSIGNING_DETAILS_REQUEST]: () => {
      return {
        ...state,
        assignmentDetails: {
          loading: true,
          data: null,
        },
      };
    },
    [ActionType.FETCH_ASSIGNING_DETAILS_SUCCESS]: ({ payload }) => {
      return {
        ...state,
        assignmentDetails: {
          loading: false,
          data: fromApiFormat(payload),
        },
      };
    },
    [ActionType.FETCH_ASSIGNING_DETAILS_FAILURE]: ({ error }) => {
      return {
        ...state,
        assignmentDetails: {
          ...state.assignmentDetails,
          loading: false,
          error,
        },
      };
    },
    [ActionType.FETCH_CHILDREN_LIST_REQUEST]: () => {
      return {
        ...state,
        children: {
          ...state.children,
          loading: true,
          items: [],
        },
      };
    },
    [ActionType.FETCH_CHILDREN_LIST_SUCCESS]: ({ payload, meta }) => {
      return {
        ...state,
        children: {
          loading: false,
          items: insertPage(state.children.items, fromApiFormat(payload.items), meta.page),
          total: payload.metadata.total,
        },
      };
    },
    [ActionType.FETCH_CHILDREN_LIST_FAILURE]: ({ error }) => {
      return {
        ...state,
        children: {
          ...state.children,
          loading: false,
          error,
        },
      };
    },
    [ActionType.FETCH_CHILDREN_ALERT_LIST_REQUEST]: () => {
      return {
        ...state,
        children: {
          ...state.children,
          loading: true,
          items: [],
        },
      };
    },
    [ActionType.FETCH_CHILDREN_ALERT_LIST_SUCCESS]: ({ payload, meta }) => {
      return {
        ...state,
        children: {
          loading: false,
          items: insertPage(state.children.items, fromApiFormat(payload.items), meta.page),
          total: payload.metadata.total,
        },
      };
    },
    [ActionType.FETCH_CHILDREN_ALERT_LIST_FAILURE]: ({ error }) => {
      return {
        ...state,
        children: {
          ...state.children,
          loading: false,
          error,
        },
      };
    },
    [ActionType.CLEAR_CHILDREN_LIST]: () => {
      return {
        ...state,
        children: {
          items: [],
          loading: false,
          total: 0,
        },
      };
    },
  };

  return behaviours[action.type] ? behaviours[action.type](action) : state;
};

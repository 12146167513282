import { DataGrid } from 'components/shared/data/DataGrid/DataGrid';
import { useDownloader } from 'components/shared/hooks/useDownloader';
import { ActionHandlers, BrowserProps } from 'components/templates/Browser/Browser.types';
import { templatesBrowserColumns } from 'components/templates/Browser/columns.config';
import { getTemplateFile, TemplateActionParams } from 'fetch/query/getTemplateFile';
import React, { FC } from 'react';

export const Browser: FC<BrowserProps> = (props) => {
  const downloader = useDownloader<TemplateActionParams>(getTemplateFile);

  const actionHandlers: ActionHandlers = {
    preview: (templateId: string) => downloader({ templateId }),
  };

  return (
    <DataGrid
      data={props.templates}
      isLoading={props.isLoading}
      columns={templatesBrowserColumns(actionHandlers)}
      loadingMessage={'TEMPLATES.BROWSER.LOADING'}
      noResultsMessage={'TEMPLATES.BROWSER.NO_RESULTS'}
    />
  );
};

import { useEffect } from 'react';
import { Action, useMutation } from 'react-fetching-library';
import { downloadContent } from 'utils/files/download';

export const useDownloader = <Params extends object>(creator: (params: Params) => Action) => {
  const { mutate, payload, loading, headers } = useMutation<string, {}, Params>(creator);

  useEffect(() => {
    if (payload && !loading) {
      const fileName = headers.get('content-disposition').split('"')[1];
      const contentType = headers.get('content-type');

      downloadContent(fileName, payload, contentType);
    }
  }, [loading, headers, payload]);

  return (params: Params) => mutate(params);
};

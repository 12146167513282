import { SystemFormValues } from 'components/settings/System/System.types';
import { CheckBoxList } from 'components/shared/form/CheckBox/CheckBoxList';
import { Heading } from 'components/shared/typography/Heading/Heading';

import { useTranslator } from 'components/shared/hooks';
import { FormikProps } from 'formik';
import React from 'react';

interface IntegrationsProps {
  form: FormikProps<SystemFormValues>;
}

export const Integrations = ({ form }: IntegrationsProps) => {
  const trans = useTranslator();

  return (
    <div className="container-fluid">
      <Heading type="h6" className="font-weight-normal">
        {trans('SETTINGS.SYSTEM.FIELDS.DEPARTMENT_INTEGRATIONS')}
      </Heading>
      <CheckBoxList
        disabled={form.initialValues.departmentIntegration ? true : false}
        name="departmentIntegration"
        options={[
          {
            value: 'selIntegration',
            label: trans('SETTINGS.SYSTEM.FIELDS.DEPARTMENT_INTEGRATIONS_TYPE.SEL_INTEGRATION'),
          },
          {
            value: 'sodIntegration',
            label: trans('SETTINGS.SYSTEM.FIELDS.DEPARTMENT_INTEGRATIONS_TYPE.SOD_INTEGRATION'),
          },
          {
            value: 'assignmentTransfer',
            label: trans('SETTINGS.SYSTEM.FIELDS.DEPARTMENT_INTEGRATIONS_TYPE.ASSIGNMENT_TRANSFER'),
          },
          {
            value: 'addressSynchronization',
            label: trans('SETTINGS.SYSTEM.FIELDS.DEPARTMENT_INTEGRATIONS_TYPE.ADDRESS_INTEGRATION'),
          },
        ]}
        containerClassName="pl-4"
      />
    </div>
  );
};

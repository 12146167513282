import { muiStyles } from 'utils/design';

export const useStyles = muiStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(3, 2),
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  header: {
    fontSize: '1.6em',
    fontWeight: 600,
    color: theme.palette.grey['600'],
  },
}));

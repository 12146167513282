import { DetailsProps } from 'components/institution/Unit/StudentsRegister/Details/Details.types';
import { Button } from 'components/shared/buttons/Button/Button';
import { useFormatter, useTranslator } from 'components/shared/hooks';
import { TextLoader } from 'components/shared/loaders/TextLoader/TextLoader';
import { Heading } from 'components/shared/typography/Heading/Heading';
import { browserColumns } from './columns.config';
import { useStyles } from 'components/institution/Unit/StudentsRegister/Details/Details.styles';
import { DataGrid } from 'components/shared/data/DataGrid/DataGrid';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import React, { FC } from 'react';
import { StudentRegisterEntryModel } from 'store/institutions';

export const Details: FC<DetailsProps> = (props) => {
  const classes = useStyles();
  const trans = useTranslator();
  const { formatDate } = useFormatter();

  if (props.loading) {
    return (
      <Paper innerSpacing={[4, 2, 2]} outerSpacing={[0, 0, 2]} square={true}>
        <TextLoader text={trans('COMMON.LOADING_DATA')} />
      </Paper>
    );
  }

  const getRevisionDateRange = () => {
    if (props.register.to) {
      return trans('COMMON.FROM_DATE_TO_DATE', {
        from: formatDate(props.register.from),
        to: formatDate(props.register.to),
      });
    } else {
      return trans('COMMON.FROM_DATE', {
        from: formatDate(props.register.from),
      });
    }
  };

  return (
    <Paper innerSpacing={[4, 2, 2]} outerSpacing={[0, 0, 2]} square={true} className={classes.paper}>
      <Heading type={'h5'} className="mb-2">
        {trans('INSTITUTIONS.STUDENTS_REGISTER_DETAILS.REGISTER_FOR', { unit: props.unit.name })}
        &nbsp;({getRevisionDateRange()})
      </Heading>
      <DataGrid<StudentRegisterEntryModel>
        className={classes.grid}
        data={props.register.entries}
        isLoading={false}
        columns={browserColumns()}
        loadingMessage={'INSTITUTIONS.BROWSER.RESULTS-LOADING'}
        noResultsMessage={'COMMON.NOTHING-FOUND'}
      />
      <Button variant="contained" color="primary" onClick={props.onGoBack} className="mt-3">
        {trans('COMMON.BACK')}
      </Button>
    </Paper>
  );
};

import { muiStyles } from 'utils/design';

export const useStyles = muiStyles({
  container: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    '& > form': {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
    },
  },
});

import { AppDispatch } from '@typings/redux';
import { AuditLog } from 'components/reports/AuditLog/AuditLog';
import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { auditLogsSelector } from 'store/reports/reports.selector';
import { clearAuditLogData, fetchAuditLog } from 'store/reports/reports.action';

export const AuditLogContainer: FC = () => {
  const { list, total } = useSelector(auditLogsSelector);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(clearAuditLogData());
  }, [dispatch]);

  const onLogsFetch = useCallback((page) => dispatch(fetchAuditLog(page)), [dispatch]);
  const onLogsProvide = useCallback((page) => list[page], [list]);

  return <AuditLog onLogsFetch={onLogsFetch} onLogsProvide={onLogsProvide} totalLogs={total} />;
};

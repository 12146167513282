import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/styles/withStyles';
import { isFunction } from 'lodash';

type ComponentStyleStatic<ClassKey extends string, Props extends {}> = StyleRules<Props, ClassKey>;

interface ComponentStylesThemed<ClassKey extends string, Props extends {}> {
  (theme: Theme): StyleRules<Props, ClassKey>;
}

type ComponentStyles<ClassKey extends string, Props extends {}> =
  | ComponentStyleStatic<ClassKey, Props>
  | ComponentStylesThemed<ClassKey, Props>;

const isThemed = <ClassKey extends string, Props extends {}>(
  styles: ComponentStyles<ClassKey, Props>,
): styles is ComponentStylesThemed<ClassKey, Props> => {
  return isFunction(styles);
};

/***
 * There is a bug in Material UI with TypeScript that results
 * in an error when there is not parameter passed to useStyles hook.
 * This wrapper fixes it by always passing at least empty object.
 * @see https://github.com/mui-org/material-ui/issues/14018
 */
const fixUseStylesHook =
  <Props extends object>(hook) =>
  (props?: Props | any) => {
    return hook(props || {});
  };

export const muiStyles = <Props extends {} = {}, ClassKey extends string = string>(
  styles: ComponentStyles<ClassKey, Props>,
) => {
  return fixUseStylesHook<Props>(
    makeStyles((theme) => {
      if (isThemed(styles)) {
        return createStyles(styles(theme));
      } else {
        return createStyles(styles);
      }
    }),
  );
};

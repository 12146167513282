import { Summary, SummaryItem } from 'components/shared/data/Summary/Summary';
import React, { FC } from 'react';
import { TextLoader } from 'components/shared/loaders/TextLoader/TextLoader';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import classNames from 'classnames';
import { Button } from 'components/shared/buttons/Button/Button';
import { useFormatter, useTranslator } from 'components/shared/hooks';
import { useStyles } from 'components/schoolDistricts/AssignmentPreview/ChildrenList/ChildrenList.styles';
import { ChildModel } from 'store/schoolDistricts';
import { DataGrid } from 'components/shared/data/DataGrid/DataGrid';
import { childrenColumns } from 'components/schoolDistricts/AssignmentPreview/ChildrenList/columns.config';
import { ChildrenListProps } from 'components/schoolDistricts/AssignmentPreview/ChildrenList/ChildrenList.types';
import { StatusPanelDefinition } from 'components/shared/data/DataGrid/DataGrid.types';

export const ChildrenList: FC<ChildrenListProps> = ({
  assignmentData,
  schoolData,
  redirectToPreview,
  isAssignmentLoading,
  countStatusTitle,
  onChildrenFetch,
  onChildrenProvide,
  limit,
  total,
}) => {
  const trans = useTranslator();
  const classes = useStyles();
  const { formatDate } = useFormatter();
  const renderLoader = () => (
    <TextLoader name="spinner" text={trans('SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.CHILDREN_LIST.RESULTS_LOADING')} />
  );

  const statusPanels: StatusPanelDefinition[] = [
    {
      statusPanel: 'count',
      statusPanelParams: { title: countStatusTitle },
      align: 'left',
    },
  ];

  const renderContent = () => (
    <div className={classNames('container-fluid', classes.container)}>
      <Summary linear>
        {schoolData && (
          <SummaryItem title={trans('SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.CHILDREN_LIST.SCHOOL')}>
            {schoolData.name}
          </SummaryItem>
        )}
        {assignmentData && (
          <>
            <SummaryItem title={trans('SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.CHILDREN_LIST.DATABASE')}>
              {assignmentData.catchmentAreaGroupName}
            </SummaryItem>
            <SummaryItem title={trans('SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.CHILDREN_LIST.DATE')}>
              {formatDate(assignmentData.createdAt)}
            </SummaryItem>
          </>
        )}
      </Summary>

      <Paper innerSpacing={2} className={classes.paper}>
        <DataGrid<ChildModel>
          className={classes.grid}
          columns={childrenColumns()}
          loadingMessage={'SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.CHILDREN_LIST.RESULTS_LOADING'}
          noResultsMessage={'SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.CHILDREN_LIST.NOTHING_FOUND'}
          statusPanels={statusPanels}
          onDataFetch={onChildrenFetch}
          onDataProvide={onChildrenProvide}
          limit={limit}
          total={total}
        />
      </Paper>
      <div className="row my-4">
        <div className="col">
          <Button onClick={redirectToPreview} color="secondary" variant="outlined">
            {trans('COMMON.BACK')}
          </Button>
        </div>
      </div>
    </div>
  );

  return isAssignmentLoading && !assignmentData ? renderLoader() : renderContent();
};

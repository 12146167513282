import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon';
import { Tooltip } from 'components/shared/messages/Tooltip/Tooltip';
import React, { useRef, useState, MouseEvent, useEffect } from 'react';

import Button from '@material-ui/core/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { isFunction } from 'lodash';
import classNames from 'classnames';

import { useTranslator } from 'components/shared/hooks';
import { BaseActionButtonProps, SingleAction } from 'components/shared/buttons/ActionButton/ActionButton.types';
import { useStyles } from 'components/shared/buttons/ActionButton/ActionButton.styles';

export const BaseActionButtonComponent = (props: BaseActionButtonProps, ref) => {
  const {
    label,
    actionList,
    startingIndex = -1,
    component,
    collectNode,
    collectActions,
    disableCaret,
    componentActions,
    wrapperClassName,
    className,
    ...buttonProps
  } = props;

  const trans = useTranslator();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const classes = useStyles({ hasCaret: !disableCaret });
  const ButtonComponent = component || Button;

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleAction = (event: MouseEvent, action: () => void) => {
    event.stopPropagation();
    action();
    handleClose();
  };

  useEffect(() => {
    componentActions &&
      componentActions({
        close: handleClose,
      });
  }, [componentActions]);

  const renderAction = (action: SingleAction, index: number) => {
    return (
      <Button
        key={index}
        onClick={(event) => handleAction(event, action.action)}
        buttonRef={(node) => props.collectNode(node, index + startingIndex + 1)}
        action={(actions) => props.collectActions(actions, index + startingIndex + 1)}
        className={classes.menuButton}
        {...action.props}
      >
        {action.icon && <FontAwesomeIcon name={action.icon} className={classNames(classes.menuButtonIcon, 'mr-2')} />}
        {trans(action.label)}
      </Button>
    );
  };

  const renderActionWithTooltip = (action: SingleAction, index: number) => {
    return (
      <Tooltip key={index} {...action.tooltip}>
        {renderAction(action, index)}
      </Tooltip>
    );
  };

  return (
    <span ref={ref} className={wrapperClassName}>
      <ButtonComponent
        aria-owns={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        action={(actions) => collectActions(actions, startingIndex)}
        buttonRef={(node) => collectNode(node as HTMLElement, startingIndex)}
        className={classNames(classes.button, className)}
        {...buttonProps}
        ref={anchorRef}
      >
        {isFunction(label) ? label(trans) : trans(label)}
        {!disableCaret && <ArrowDropDownIcon className={classes.caret} />}
      </ButtonComponent>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-end"
        modifiers={{
          preventOverflow: {
            enabled: false,
            boundariesElement: 'scrollParent',
          },
        }}
        className={classes.popper}
        popperOptions={{
          modifiers: {
            preventOverflow: {
              enabled: false,
            },
            hide: {
              enabled: false,
            },
          },
          onCreate: () => {
            setTimeout(() => {
              const inGridRow = !!anchorRef.current.closest('.ag-cell');
              const isRowFocus = !!anchorRef.current.closest('.ag-cell-focus');

              if (inGridRow && !isRowFocus) {
                handleClose();
              }
            }, 0);
          },
        }}
      >
        <Paper id="menu-list-grow">
          <ClickAwayListener onClickAway={handleClose}>
            <div className={classes.menuList}>
              {actionList.map((action, index) => {
                return action.tooltip ? renderActionWithTooltip(action, index) : renderAction(action, index);
              })}
            </div>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </span>
  );
};

BaseActionButtonComponent.displayName = 'BaseActionButtonComponent';
export const BaseActionButton = React.forwardRef(BaseActionButtonComponent);

import { CLEAR_TOKEN, clearTokenAction, REFRESH_TOKEN_SUCCESS, SET_TOKEN } from '@tshio/redux-api-auth-middleware';
import { FSA } from '@typings/redux';
import { Action } from 'redux';
import {
  select,
  call,
  CallEffect,
  fork,
  ForkEffect,
  put,
  putResolve,
  PutEffect,
  takeLatest,
  SimpleEffect,
  SelectEffect,
} from 'redux-saga/effects';
import { appLogout } from 'store/auth/auth.action';
import { AuthActionType } from 'store/auth/auth.actionType';
import { resetStateAction } from 'store/store';
import { fetchUserInfo, fetchUserPermission } from 'store/user/user.action';
import { fetchAccessRules } from 'store/accessRules';
import { deleteStoredToken, storeToken } from 'utils/auth/tokenStore';
import { userId } from 'store/user';
import { successNotification } from 'store/general';

let firstLogout = true;

export function* authorizationTokenSet(action: FSA): IterableIterator<CallEffect | PutEffect | SimpleEffect<'SELECT'>> {
  if (action.payload.access_token) {
    yield call(storeToken, action.payload);
    yield putResolve(fetchUserInfo() as unknown as Action);
    const fetchUserId = yield select(userId);
    yield put(fetchUserPermission(fetchUserId) as unknown as Action);
    yield put(fetchAccessRules() as unknown as Action);
    firstLogout = true;
  }
}

export function* authorizationTokenRemoved(): IterableIterator<CallEffect> {
  yield call(deleteStoredToken);
}

export function* userLoggedOut(): IterableIterator<PutEffect | SelectEffect> {
  yield put(clearTokenAction());
  yield put(resetStateAction());
  if (firstLogout) {
    yield put(successNotification('AUTHORIZATION.NOTIFICATIONS.SIGN_OUT_SUCCESS'));
    firstLogout = false;
  }
}

export function* sessionDestroyed(): IterableIterator<PutEffect> {
  yield put(appLogout() as unknown as Action);
}

export function* authSaga(): Iterator<ForkEffect> {
  yield fork(function* () {
    yield takeLatest(SET_TOKEN, authorizationTokenSet);
  });
  yield fork(function* () {
    yield takeLatest(REFRESH_TOKEN_SUCCESS, authorizationTokenSet);
  });
  yield fork(function* () {
    yield takeLatest(CLEAR_TOKEN, authorizationTokenRemoved);
  });
  yield fork(function* () {
    yield takeLatest(AuthActionType.APP_LOGOUT, userLoggedOut);
  });
}

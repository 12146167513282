import { TextLoader } from 'components/shared/loaders/TextLoader/TextLoader';
import React, { FC, useEffect, useRef } from 'react';
import { StepContent } from 'components/shared/dialogs/StepModal/components/StepContent';
import { Form, InputText } from 'components/shared/form';
import { BackButton, NextButton, StepActions } from 'components/shared/dialogs/StepModal';
import { SettingsProps } from './Settings.types';
import { useFormatter, useTranslator } from 'components/shared/hooks';
import { SettingsRow } from './SettingsRow';
import { FormikHelpers } from 'formik';
import { useStyles } from './Settings.styles';
import { ImportContextModel } from 'store/childRegister';
import { getContextColumnsConfig } from 'components/childRegister/Import/steps/Settings/context.config';
import { SettingsContent } from 'components/shared/import/settings/SettingsContent';

export const Settings: FC<SettingsProps> = ({
  initialValues,
  saveContext,
  fetchColumns,
  columns,
  goBack,
  goNext,
  isLoading,
}) => {
  const form = useRef(null);
  const trans = useTranslator();
  const { formatApiErrors } = useFormatter();
  const classes = useStyles();

  useEffect(() => {
    fetchColumns();
  }, [fetchColumns]);

  const handleSubmit = (values: ImportContextModel, formik: FormikHelpers<ImportContextModel>) => {
    saveContext({ ...values }).then((result) => {
      formik.setSubmitting(false);
      if (result.error) {
        if (result.payload && result.payload.response.errors) {
          formik.setErrors(formatApiErrors(result.payload.response.errors));
        }
      } else {
        goNext();
      }
    });
  };

  if (isLoading) {
    return <TextLoader text={trans('COMMON.LOADING_DATA')} />;
  }

  return (
    <>
      <StepContent>
        <div className="container pb-1 pt-1 text-center">
          <div className="row justify-content-center mt-3">
            <Form<ImportContextModel>
              initialValues={initialValues}
              onSubmit={handleSubmit}
              innerRef={form}
              className={classes.form}
              enableReinitialize={true}
            >
              {(props) => {
                const currentUsedColumns = Object.values(props.values.map);
                return (
                  <div className={'container-fluid'}>
                    <SettingsRow label="CHILD_REGISTER.IMPORT_STEPS.SETTINGS.SOURCE.LABEL" required={true}>
                      <InputText
                        name="dataSource"
                        labelText=""
                        placeholder={trans('CHILD_REGISTER.IMPORT_STEPS.SETTINGS.SOURCE.PLACEHOLDER')}
                      />
                    </SettingsRow>
                    <div className={classes.sectionHeader}>
                      {trans('CHILD_REGISTER.IMPORT_STEPS.SETTINGS.COLUMNS_IMPORT')}
                    </div>
                    <SettingsContent
                      columnsConfig={getContextColumnsConfig()}
                      columns={columns}
                      isLoading={isLoading}
                      currentUsedColumns={currentUsedColumns}
                    />
                  </div>
                );
              }}
            </Form>
          </div>
        </div>
      </StepContent>
      <StepActions>
        <BackButton onClick={goBack}>{trans('COMMON.PREVIOUS')}</BackButton>
        <NextButton onClick={() => !form.current.isSubmitting && form.current.handleSubmit()}>
          {trans('COMMON.NEXT')}
        </NextButton>
      </StepActions>
    </>
  );
};

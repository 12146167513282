import React, { FC, useEffect, useState } from 'react';
import { StepModal } from 'components/shared/dialogs/StepModal';
import { Step } from 'components/shared/dialogs/StepModal/StepModal.types';
import { useTranslator } from 'components/shared/hooks';
import { ImportProps } from './Import.types';
import { FileImportContainer } from 'components/childRegister/Import/steps/FileImport/FileImportContainer';
import { SettingsContainer } from 'components/childRegister/Import/steps/Settings/SettingsContainer';
import { PreviewContainer } from 'components/childRegister/Import/steps/Preview/PreviewContainer';
import { ImportStatus } from 'components/childRegister/Import/ImportStatus/ImportStatus';

export const Import: FC<ImportProps> = ({
  open,
  onClose,
  token,
  onRemoveToken,
  clearImportInformation,
  redirectToList,
}) => {
  const [isSettingsViewVisible, setSettingsViewVisible] = useState(false);
  const [isStatusViewVisible, setStatusViewVisible] = useState(false);
  const trans = useTranslator();

  const onConfirmImport = () => {
    setSettingsViewVisible(false);
    setStatusViewVisible(true);
  };

  const FileStep = (props) => <FileImportContainer {...props} />;
  const SettingsStep = (props) => <SettingsContainer {...props} />;
  const PreviewStep = (props) => <PreviewContainer {...props} onConfirmImport={onConfirmImport} />;

  useEffect(() => {
    if (open) {
      clearImportInformation();
      setSettingsViewVisible(true);
    }
  }, [clearImportInformation, open]);

  const handleOnClose = () => {
    if (token) {
      onRemoveToken();
    }

    clearImportInformation();
    setSettingsViewVisible(false);
    onClose();
  };

  const handleImportStatusClose = () => {
    setStatusViewVisible(false);
    clearImportInformation();
    redirectToList();
  };

  const steps: Step[] = [
    {
      label: trans('CHILD_REGISTER.IMPORT_STEPS.FILE.TITLE'),
      content: FileStep,
    },
    {
      label: trans('CHILD_REGISTER.IMPORT_STEPS.SETTINGS.TITLE'),
      content: SettingsStep,
    },
    {
      label: trans('CHILD_REGISTER.IMPORT_STEPS.PREVIEW.TITLE'),
      content: PreviewStep,
    },
  ];

  return (
    <>
      {isSettingsViewVisible && (
        <StepModal
          onClose={handleOnClose}
          open={isSettingsViewVisible}
          title={trans('CHILD_REGISTER.IMPORT.TITLE')}
          steps={steps}
        />
      )}
      {isStatusViewVisible && <ImportStatus confirm={handleImportStatusClose} open={isStatusViewVisible} />}
    </>
  );
};

import { RESET_STATE } from 'store/store';
import { OfficialsActionType } from 'store/officials/officials.actionTypes';
import { defaultState, OfficialsState } from 'store/officials/officials.state';
import { FSAReducer, Behaviours } from '@typings/redux';
import { fromApiFormat } from 'utils/api';

export const officialsReducer: FSAReducer<OfficialsState, OfficialsActionType> = (state = defaultState, action) => {
  const behaviours: Behaviours<OfficialsState, OfficialsActionType> = {
    [RESET_STATE]: () => ({
      ...defaultState,
    }),
    [OfficialsActionType.FETCH_OFFICIALS_DETAILS_REQUEST]: () => ({
      ...state,
      details: {
        ...state.details,
        loading: true,
      },
    }),
    [OfficialsActionType.FETCH_OFFICIALS_DETAILS_SUCCESS]: ({ payload }) => ({
      ...state,
      details: {
        ...state.details,
        official: fromApiFormat(payload),
        loading: false,
      },
    }),
    [OfficialsActionType.FETCH_OFFICIALS_DETAILS_FAILURE]: ({ error }) => ({
      ...state,
      details: {
        ...state.details,
        loading: false,
        error,
      },
    }),
  };

  return behaviours[action.type] ? behaviours[action.type](action) : state;
};

import { AppBar, Toolbar, Typography } from '@material-ui/core';
import { useTranslator } from 'components/shared/hooks';
import React from 'react';
import { useStyles } from 'components/layout/questionnaire/TitleBar/TitleBar.styles';
import { useSelector } from 'react-redux';
import { questionnaireSelector } from 'store/polls';

export const TitleBar: React.FC = () => {
  const classes = useStyles();
  const trans = useTranslator();
  const { questionnaire } = useSelector(questionnaireSelector);

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <Typography variant="h6" className={classes.title}>
          {questionnaire
            ? trans('TOPBAR.POLLS_FOR_DEPARTMENT', { department: questionnaire.departmentName })
            : trans('TOPBAR.POLLS')}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

import { BrowserContainer } from 'components/institution/Browser/BrowserContainer';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import React, { FC, useContext } from 'react';
import { Page } from 'components/layout/panel/Page/Page';
import { LocaleContext } from 'context/locale';
import { useStyles } from 'views/Panel/Institutions/InstitutionsList/InstitutionsList.styles';
import { RouteProps } from 'react-router';

export const InstitutionsList: FC<RouteProps> = ({ location }) => {
  const { trans } = useContext(LocaleContext);
  const classes = useStyles();

  return (
    <Page title={trans('INSTITUTIONS.BROWSER.TITLE')}>
      <Paper innerSpacing={2} outerSpacing={[0, 2, 2]} className={classes.paper}>
        <BrowserContainer key={location.key} />
      </Paper>
    </Page>
  );
};

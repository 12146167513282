import { useStyles } from 'components/shared/form/RadioBox/RadioBox.styles';
import React, { Fragment } from 'react';
import { Field, FieldProps } from 'formik';
import { FormControlLabel, FormControl, FormHelperText, Radio, Tooltip } from '@material-ui/core';
import { RadioBoxProps } from './RadioBox.types';
import { get } from 'lodash';
import classNames from 'classnames';

export const RadioBox = ({ name, labelText, validate, value, hasIcon, icon, tooltipText, ...rest }: RadioBoxProps) => {
  const classes = useStyles();

  return (
    <Field name={name} validate={validate}>
      {({ field, form }: FieldProps) => {
        const isError = !!(get(form.errors, field.name) && get(form.touched, field.name));

        const getLabel = () => {
          if (hasIcon) {
            return (
              <Fragment>
                {labelText}
                <Tooltip title={tooltipText} placement="right" className={classes.tooltipIcon}>
                  {icon}
                </Tooltip>
              </Fragment>
            );
          } else {
            return labelText;
          }
        };

        return (
          <FormControl error={isError} className={classNames({ hasValidationError: isError })}>
            <FormControlLabel
              control={
                <Radio
                  onChange={form.handleChange}
                  onBlur={field.onBlur}
                  name={field.name}
                  data-testid={`${name}_${value}`}
                  checked={field.value === value}
                  value={value}
                  color="default"
                  {...rest}
                />
              }
              label={getLabel()}
            />
            <FormHelperText>{isError && get(form.touched, field.name)}</FormHelperText>
          </FormControl>
        );
      }}
    </Field>
  );
};

import { educationPathColumns } from 'components/childRegister/Editor/EducationPath/columns.config';
import { useStyles } from 'components/childRegister/Editor/EducationPath/EducationPath.styles';
import {
  EducationPathProps,
  EducationPathRow,
} from 'components/childRegister/Editor/EducationPath/EducationPath.types';
import { DataGrid } from 'components/shared/data/DataGrid/DataGrid';
import { useTranslator } from 'components/shared/hooks';
import { Box } from 'components/shared/surfaces/Box/Box';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import { Heading } from 'components/shared/typography/Heading/Heading';
import React, { FC } from 'react';

export const EducationPath: FC<EducationPathProps> = (props) => {
  const classes = useStyles();
  const trans = useTranslator();

  const noResultsMessage = props.show
    ? 'CHILD_REGISTER.EDUCATION_PATH.NOTHING_FOUND'
    : 'CHILD_REGISTER.EDUCATION_PATH.MISSING_ID_NUMBER';

  return (
    <Paper innerSpacing={2} square={true} className={classes.container}>
      <Box padding={[2, 0]}>
        <Heading type="h5">{trans('CHILD_REGISTER.EDUCATION_PATH.FOUND_RECORDS')}</Heading>
      </Box>
      <DataGrid<EducationPathRow>
        data={props.list}
        isLoading={props.loading}
        columns={educationPathColumns()}
        loadingMessage={'CHILD_REGISTER.EDUCATION_PATH.RESULTS_LOADING'}
        noResultsMessage={noResultsMessage}
      />
    </Paper>
  );
};

import { Page } from 'components/layout/panel/Page/Page';
import { useTranslator } from 'components/shared/hooks';
import { Breadcrumb } from 'components/shared/navigation/Breadcrumbs/Breadcrumb';
import React, { FC } from 'react';
import { RouteChildrenProps } from 'react-router';
import { SchoolChildrenAlertListRoute } from 'config/routes.types';
import { ChildrenAlertsTypes } from 'store/schoolDistricts';
import { ChildrenAlertListContainer } from 'components/schoolDistricts/AssignmentPreview/ChildrenList/ChildrenAlertListContainer';

export const ChildrenAlertList: FC<RouteChildrenProps<SchoolChildrenAlertListRoute>> = (props) => {
  const trans = useTranslator();
  const { id, alert } = props.match.params;

  const getPageTitle = () => {
    return {
      [ChildrenAlertsTypes.MISSING_ASSIGNMENT]:
        'SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.CHILDREN_ALERT_LIST.PAGE_TITLE.MISSING_ASSIGNMENT',
      [ChildrenAlertsTypes.MISSING_TERYT]:
        'SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.CHILDREN_ALERT_LIST.PAGE_TITLE.MISSING_TERYT',
    }[alert];
  };

  const getBreadcrumbTitle = () => {
    return {
      [ChildrenAlertsTypes.MISSING_ASSIGNMENT]: 'BREADCRUMB.SCHOOL-DISTRICTS.CHILDREN_ALERT_LIST.MISSING_ASSIGNMENT',
      [ChildrenAlertsTypes.MISSING_TERYT]: 'BREADCRUMB.SCHOOL-DISTRICTS.CHILDREN_ALERT_LIST.MISSING_TERYT',
    }[alert];
  };

  return (
    <>
      <Breadcrumb label={trans(getBreadcrumbTitle())} static />

      <Page title={trans(getPageTitle())}>
        <ChildrenAlertListContainer id={id} alert={alert} />
      </Page>
    </>
  );
};

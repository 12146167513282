import { AuthState } from '@tshio/redux-api-auth-middleware';
import { FSA } from '@typings/redux';
import { RouterState } from 'connected-react-router';
import { createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { ChildRegisterState } from 'store/childRegister/childRegister.state';
import { PollsState } from 'store/polls/polls.state';
import { ReportsState } from 'store/reports/reports.state';
import { SchoolDistrictsState } from 'store/schoolDistricts/schoolDistricts.state';
import { TemplatesState } from 'store/templates';
import { UserState } from 'store/user/user.state';
import { UserListState } from 'store/userList/userList.state';
import { GeneralState } from './general';
import { history } from './history';
import { InstitutionsState } from './institutions';
import { getMiddlewares, sagaMiddleware } from './middleware';
import { rootReducer } from './reducer';
import { rootSaga } from './saga';
import { TerytState } from 'store/teryt';
import { SchoolObligationsState } from 'store/schoolObligations';
import { SettingsState } from 'store/systemSettings';
import { OfficialsState } from 'store/officials';
import { AccessRulesState } from 'store/accessRules';
import { SearchState } from 'store/search/search.state';
import { ApplicantsState } from './applicants/applicants.state';

export interface AppState {
  auth: AuthState;
  general: GeneralState;
  router: RouterState;
  institutions: InstitutionsState;
  childRegister: ChildRegisterState;
  schoolDistricts: SchoolDistrictsState;
  templates: TemplatesState;
  polls: PollsState;
  teryt: TerytState;
  user: UserState;
  userList: UserListState;
  schoolObligations: SchoolObligationsState;
  reports: ReportsState;
  settings: SettingsState;
  officials: OfficialsState;
  accessRules: AccessRulesState;
  search: SearchState;
  applicants: ApplicantsState;
}

export const RESET_STATE = '@store/RESET_STATE';
export const resetStateAction = (): FSA => ({ type: RESET_STATE });

/**
 * After the app loads we fetch a config file from servers.
 * Because of that we can't build the store just when the
 * scripts are parsed but only after the config is loaded.
 * The `endpointMiddleware` will need the API url from it.
 */
let storeSingleton = null;

export const getConfiguredStore: () => Store<AppState> = () => {
  const reducers = rootReducer(history);
  const middlewares = composeWithDevTools(getMiddlewares());

  if (!storeSingleton) {
    storeSingleton = createStore(reducers, middlewares);
    sagaMiddleware.run(rootSaga);
  }

  if (process.env.NODE_ENV !== 'production') {
    if ((module as any).hot) {
      (module as any).hot.accept('./reducer', (reducer) => {
        storeSingleton.replaceReducer(reducer);
      });
    }
  }

  /**
   * Allows to access the store during Cypress tests.
   *
   *  cy.window().then((win) => {
   *    const state = win.getOsinStore().getState();
   *    win.getOsinStore().dispatch(setupStore({ ...state, foo: bar });
   *  });
   */
  if (window.Cypress) {
    window.getOsinStore = () => storeSingleton;
  }

  return storeSingleton;
};

import { paramCase } from 'change-case';

const filterGroupArrows = (value: string, rowIndex: number, reportRoot: string): string => {
  if (value.includes(`-> ${reportRoot}`)) {
    if (rowIndex === 0) {
      return reportRoot;
    } else {
      return '';
    }
  } else {
    return value.split(' -> ').join('');
  }
};

const removeFixedValueAsterisk = (value: string): string => {
  const matchData = value.match(/^(\d*,?\d*) \*$/);

  if (matchData?.length === 2) {
    return matchData[1];
  } else {
    return value;
  }
};

export const processCellFactory = (reportRoot: string) => (params) => {
  if (!params.value) return;

  let value = params.value.toString();
  value = filterGroupArrows(value, params.node.rowIndex, reportRoot);
  value = removeFixedValueAsterisk(value);

  return value;
};

export const generateSheetName = (fileName: string, updateDate: string) => `${paramCase(fileName)}-${updateDate}`;

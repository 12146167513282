import { NewPasswordModalProps } from 'components/shared/dialogs/NewPasswordModal/NewPasswordModal.types';
import { useTranslator } from 'components/shared/hooks/useTranslator/useTranslator';
import React, { FC } from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

export const NewPasswordModal: FC<NewPasswordModalProps> = (props) => {
  const trans = useTranslator();

  return (
    <Dialog disableEscapeKeyDown aria-labelledby="new-password-title" open={props.open}>
      {props.title && <DialogTitle id="new-password-title">{trans(props.title)}</DialogTitle>}
      <DialogContent>
        <DialogContentText>
          {trans(props.message, props.messageValues)} <br />
          {trans('OFFICIALS.NEW_PASSWORD.LOGIN')} <strong>{props.login}</strong> <br />
          {trans('OFFICIALS.NEW_PASSWORD.PASSWORD')} <strong>{props.startPassword}</strong>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div></div>
        <Button onClick={props.confirm} color="secondary" variant="contained" data-testid="new-password-close">
          {trans('COMMON.CLOSE')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import React, { ComponentType, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { connect } from 'formik';
import { AppDispatch } from '@typings/redux';
import { ChildRegisterFormValues } from 'components/childRegister/Editor/General/General.types';
import { ManualEntries } from 'components/childRegister/Editor/Obligation/parts/ManualEntries/ManualEntries';
import { emptyManualStudentRegisterEntry } from 'components/childRegister/Editor/Obligation/parts/ManualEntries/ManualEntries.helpers';
import { fetchInstitutionsList, institutionsListSelector } from 'store/institutions';

export const ManualEntriesContainer: ComponentType = connect<{}, ChildRegisterFormValues>((props) => {
  const dispatch = useDispatch<AppDispatch>();
  const institutions = useSelector(institutionsListSelector);
  const {
    setFieldValue,
    values: { manualStudentRegisterEntries },
  } = props.formik;

  const createInitialManualRegisterEntry = () => {
    if (manualStudentRegisterEntries.length === 0) {
      setFieldValue('manualStudentRegisterEntries', [emptyManualStudentRegisterEntry()]);
    }
  };

  const collapsed = manualStudentRegisterEntries.some((item) => item.unitId || item.date || item.level);

  useEffect(() => {
    dispatch(fetchInstitutionsList({ search: '' }));
  }, [dispatch]);

  return (
    <ManualEntries
      institutions={institutions.items}
      collapsed={collapsed}
      createInitialManualRegisterEntry={createInitialManualRegisterEntry}
    />
  );
});

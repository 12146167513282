import React, { FC, useState } from 'react';
import Box from '@material-ui/core/Box';
import { useStyles } from 'components/childRegister/Editor/EducationPath/EducationPath.styles';
import { ObligationActionsProps } from 'components/childRegister/Editor/ObligationActions/ObligationActions.types';
import { DataGrid } from 'components/shared/data/DataGrid/DataGrid';
import { useTranslator } from 'components/shared/hooks';
import { Button } from 'components/shared/buttons/Button/Button';
import { Paper } from 'components/shared/surfaces/Paper/Paper';
import { Heading } from 'components/shared/typography/Heading/Heading';
import Access from 'components/shared/access/access';
import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon';
import { AddObligationActions } from 'components/childRegister/Editor/ObligationActions/AddObligationActions';
import { EditObligationActions } from 'components/childRegister/Editor/ObligationActions/EditObligationActions';
import { DeleteObligationActions } from 'components/childRegister/Editor/ObligationActions/DeleteObligationActions';
import { obligationActionsColumns } from 'components/childRegister/Editor/ObligationActions/columns.config';
import { ObligationAction } from 'store/childRegister';
import { OfficialPermissionLevel, OfficialResourceGroup } from 'store/officials';

export const ObligationActions: FC<ObligationActionsProps> = (props) => {
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<ObligationAction>();
  const classes = useStyles();
  const trans = useTranslator();
  const actionHandlers = {
    edit: (data) => {
      setSelectedItem(data);
      setIsEditModalOpen(true);
    },
    delete: (data) => {
      setSelectedItem(data);
      setIsDeleteModalOpen(true);
    },
  };

  return (
    <Paper innerSpacing={2} square={true} className={classes.container}>
      <Box paddingBottom={2} display="flex" alignItems="center" justifyContent="space-between">
        <Heading type="h5">{trans('CHILD_REGISTER.OBLIGATION_ACTIONS.TITLE')}</Heading>
        <Access resourcesGroup={OfficialResourceGroup.childRegister} accessType={OfficialPermissionLevel.fullAccess}>
          {props.childId && (
            <Box marginLeft={2}>
              <Button variant="contained" color="primary" onClick={() => setIsAddModalOpen((value) => !value)}>
                <FontAwesomeIcon name={'plus-circle'} className={'mr-2'} />
                {trans('CHILD_REGISTER.OBLIGATION_ACTIONS.CREATE_BUTTON')}
              </Button>
            </Box>
          )}
        </Access>
      </Box>
      <DataGrid<ObligationAction>
        data={props.list}
        isLoading={props.loading}
        columns={obligationActionsColumns(actionHandlers)}
        loadingMessage={'CHILD_REGISTER.OBLIGATION_ACTIONS.RESULTS_LOADING'}
        noResultsMessage={'CHILD_REGISTER.OBLIGATION_ACTIONS.NOTHING_FOUND'}
      />
      <AddObligationActions open={isAddModalOpen} onClose={() => setIsAddModalOpen(false)} childId={props.childId} />
      {selectedItem && (
        <>
          <EditObligationActions
            open={isEditModalOpen}
            onClose={() => setIsEditModalOpen(false)}
            childId={props.childId}
            values={selectedItem}
          />
          <DeleteObligationActions
            open={isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(false)}
            childId={props.childId}
            id={selectedItem.id}
          />
        </>
      )}
    </Paper>
  );
};

import { Responses } from 'components/polls/Results/Responses/Responses';
import { usePollResponse } from 'components/polls/Results/Responses/usePollResponse';
import { ResponsesContainerProps } from 'components/polls/Results/Results.types';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { clearResponses } from 'store/polls';

export const ResponsesContainer: FC<ResponsesContainerProps> = (props) => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearResponses());
  }, [props.responsesIds, props.pollId, dispatch]);

  const { loading, formValues, questions, response } = usePollResponse(props.pollId, props.responsesIds, page);

  return (
    <Responses
      page={page}
      onPageUpdate={setPage}
      pageCount={props.responsesIds.length}
      loading={loading}
      response={response}
      questions={questions}
      formValues={formValues}
    />
  );
};

import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon';
import React, { Fragment } from 'react';
import { Field, FieldProps } from 'formik';
import { FormControl, InputLabel, Input, FormHelperText, Tooltip, InputAdornment } from '@material-ui/core';
import { useStyles } from './InputText.styles';
import { InputTextProps } from './InputText.types';
import { get } from 'lodash';
import classNames from 'classnames';

export const InputText = ({
  name,
  tooltipText = '',
  labelText = '',
  labelTooltipText = '',
  hasIcon = false,
  validate = () => undefined,
  resettable,
  required,
  icon,
  onChange,
  fieldComponent,
  endorsement,
  helpMessage,
  showErrorAfterTouched = true,
  shrink,
  ...rest
}: InputTextProps) => {
  const classes = useStyles();
  const FieldComponent = fieldComponent || Field;

  return (
    <FieldComponent name={name} validate={validate}>
      {({ field, form }: FieldProps) => {
        const isError = !!(
          get(form.errors, field.name) && (showErrorAfterTouched ? get(form.touched, field.name) : true)
        );
        return (
          <FormControl
            error={isError}
            classes={{ root: classes.formControl }}
            required={required}
            className={classNames({ hasValidationError: isError })}
          >
            {labelText && (
              <Tooltip title={labelTooltipText} placement="top-start">
                <InputLabel classes={{ root: classes.label }} error={false} shrink={shrink}>
                  {labelText}
                </InputLabel>
              </Tooltip>
            )}
            <Input
              value={field.value ? field.value : ''}
              onBlur={field.onBlur}
              onChange={(event) => {
                onChange && onChange(event);
                field.onChange(event);
              }}
              name={field.name}
              classes={{ root: classes.formControl }}
              endAdornment={
                <Fragment>
                  {hasIcon && (
                    <InputAdornment position="end">
                      <Tooltip title={tooltipText} placement="right">
                        {icon}
                      </Tooltip>
                    </InputAdornment>
                  )}
                  {resettable && field.value && (
                    <FontAwesomeIcon
                      name={'times'}
                      className={classes.reset}
                      onClick={() => form.setFieldValue(field.name, '')}
                    />
                  )}
                  {endorsement && <InputAdornment position="end">{endorsement}</InputAdornment>}
                </Fragment>
              }
              inputProps={{
                'data-testid': field.name,
              }}
              {...rest}
            />
            {isError && <FormHelperText error>{get(form.errors, field.name)}</FormHelperText>}
            {!isError && <FormHelperText data-testid={`${field.name}-control`}>{helpMessage}</FormHelperText>}
          </FormControl>
        );
      }}
    </FieldComponent>
  );
};

import { Behaviours, FSA, FSAReducer } from '@typings/redux';
import { RESET_STATE } from 'store/store';
import { fromApiFormat } from 'utils/api';
import { defaultState, GeneralState, Notification } from './general.state';
import { GeneralActionType } from './general.actionTypes';

export const generalReducer: FSAReducer<GeneralState, GeneralActionType> = (state = defaultState, action) => {
  const behaviours: Behaviours<GeneralState, GeneralActionType> = {
    [RESET_STATE]: () => ({
      ...state,
      notifications: [],
      dictionaries: { ...defaultState.dictionaries },
      screenLock: false,
    }),
    [GeneralActionType.SET_SIDEBAR_OPENING]: ({ payload = {} }) => ({
      ...state,
      sidebarOpening: payload,
    }),
    [GeneralActionType.UPDATE_WINDOW_INFO]: ({ payload = {} }) => ({
      ...state,
      window: payload,
    }),
    [GeneralActionType.SET_SCREEN_LOCK]: ({ payload }) => ({
      ...state,
      screenLock: payload,
    }),
    [GeneralActionType.SEND_NOTIFICATION]: ({ payload }: FSA<GeneralActionType, Notification>) => ({
      ...state,
      notifications: [...(state.notifications || []), payload],
    }),
    [GeneralActionType.REMOVE_NOTIFICATION]: ({ payload: id }: FSA<GeneralActionType, string>) => ({
      ...state,
      notifications: state.notifications.filter((notification) => notification.id !== id),
    }),
    [GeneralActionType.FETCH_DICTIONARIES_REQUEST]: () => ({
      ...state,
      dictionaries: {
        ...state.dictionaries,
        loading: true,
      },
    }),
    [GeneralActionType.FETCH_DICTIONARIES_SUCCESS]: ({ payload }) => ({
      ...state,
      dictionaries: {
        ...state.dictionaries,
        list: fromApiFormat(payload),
        loading: false,
        ready: true,
      },
    }),
    [GeneralActionType.FETCH_DICTIONARIES_FAILURE]: ({ error }) => ({
      ...state,
      dictionaries: {
        ...state.dictionaries,
        loading: false,
        error: error,
      },
    }),
    [GeneralActionType.FETCH_ENVIRONMENT_REQUEST]: () => ({
      ...state,
      environment: {
        ...state.environment,
        loading: true,
        error: false,
      },
    }),
    [GeneralActionType.FETCH_ENVIRONMENT_SUCCESS]: ({ payload, meta }) => ({
      ...state,
      environment: {
        ...state.environment,
        data: {
          origin: meta.origin,
          banner: {
            imageHeight: payload.OSIN20_BANNER_HEIGHT,
            imageUrl: payload.OSIN20_BANNER_IMG,
            link: payload.OSIN20_BANNER_LINK,
          },
          oAuth: {
            clientId: payload.OSIN20_OAUTH_CLIENT_ID,
            loginUrl: payload.OSIN20_OAUTH_URL,
            logoutUrl: payload.OSIN20_OAUTH_LOGOUT_URL,
          },
        },
        loading: false,
      },
    }),
    [GeneralActionType.FETCH_ENVIRONMENT_FAILURE]: () => ({
      ...state,
      environment: {
        ...state.environment,
        loading: false,
        error: true,
      },
    }),
  };

  return behaviours[action.type] ? behaviours[action.type](action) : state;
};

import React from 'react';
import union from 'lodash/union';
import xor from 'lodash/xor';
import without from 'lodash/without';
import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel } from '@material-ui/core';
import { useTranslator } from 'components/shared/hooks';

type BirthYearsSelectProps = {
  value: string[];
  error?: string | string[];
  onChange: (value: string[]) => void;
};

export default function BirthYearsSelect({ value, error, onChange }: BirthYearsSelectProps) {
  const [checked, setChecked] = React.useState<string[]>([]);
  const trans = useTranslator();

  const handleChange = (year: string, isChecked: boolean) => {
    if (isChecked) {
      setChecked(union(checked, [year]));
    } else {
      setChecked(without(checked, year));
    }
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setChecked(value);
    } else {
      setChecked([]);
    }
  };

  const isSelectedAll = xor(value, checked).length === 0;

  React.useEffect(() => {
    onChange(checked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  return (
    <div>
      <FormControl required error={!!error} component="fieldset">
        <FormLabel component="legend">{trans('SCHOOL_OBLIGATION.REPORT_MODAL.BIRTH_YEARS.LABEL')}</FormLabel>
        <FormControlLabel
          label={trans('SCHOOL_OBLIGATION.REPORT_MODAL.BIRTH_YEARS.ALL_YEARS')}
          control={
            <Checkbox
              checked={isSelectedAll}
              indeterminate={!isSelectedAll && checked.length > 0}
              onChange={handleSelectAll}
            />
          }
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
          {value.map((item, index) => (
            <FormControlLabel
              label={item}
              control={
                <Checkbox
                  checked={checked.includes(item)}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(item, event.target.checked)}
                />
              }
              key={index}
            />
          ))}
        </Box>
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    </div>
  );
}

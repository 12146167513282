import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { useTranslator } from 'components/shared/hooks';
import { Breadcrumb } from 'components/shared/navigation/Breadcrumbs/Breadcrumb';
import {
  ROUTE_OFFICIALS,
  ROUTE_OFFICIALS_LIST,
  ROUTE_OFFICIALS_ADD,
  ROUTE_OFFICIALS_EDIT,
  ROUTE_APPLICANTS_EDIT,
} from 'config/routes';
import { OfficialsListView } from './OfficialsList/OfficialsList.view';
import { AddOfficialsView } from './OfficialsEditor/AddOfficials.view';
import { EditOfficialsView } from './OfficialsEditor/EditOfficials.view';
import { EditApplicantsView } from './OfficialsEditor/EditApplicants.view';
import { NotFoundView } from 'views/Errors/NotFound.view';

export const OfficialsView: React.FC = () => {
  const trans = useTranslator();

  return (
    <React.Fragment>
      <Breadcrumb label={trans('BREADCRUMB.OFFICIALS.TITLE')} static />
      <Switch>
        <Redirect path={ROUTE_OFFICIALS} exact to={ROUTE_OFFICIALS_LIST} />
        <Route exact path={ROUTE_OFFICIALS_LIST} component={OfficialsListView} />
        <Route exact path={ROUTE_OFFICIALS_ADD} component={AddOfficialsView} />
        <Route exact path={ROUTE_OFFICIALS_EDIT} component={EditOfficialsView} />
        <Route exact path={ROUTE_APPLICANTS_EDIT} component={EditApplicantsView} />
        <Route component={NotFoundView} />
      </Switch>
    </React.Fragment>
  );
};

import { muiStyles } from 'utils/design';

export const useStyles = muiStyles((theme) => ({
  question: {
    marginBottom: theme.spacing(2),
  },
  title: {
    fontWeight: 'bolder',
    marginBottom: theme.spacing(2),
  },
  optionsList: {
    margin: theme.spacing(0, 0, 1, 2),
  },
}));

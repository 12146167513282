import { AppAction, AppMiddleware } from '@typings/redux';
import { isFSA } from 'utils/redux';

export const normalizeAuthResponse: AppMiddleware = () => (next) => (action: AppAction) => {
  const newAction = { ...action };

  if (isFSA(newAction)) {
    if (newAction.payload && newAction.payload.token && newAction.payload.refresh_token) {
      newAction.payload = {
        access_token: newAction.payload.token,
        refresh_token: newAction.payload.refresh_token,
      };
    }
  }

  return next(newAction);
};

import {
  ForecastConfig,
  SubventionForecastRow,
  SubventionForecastRowType,
} from 'components/reports/SubventionForecast/SubventionForecast.types';
import { Translator } from 'context/locale/LocaleContext/LocaleContext.types';
import { SubventionFactors } from 'store/reports/reports.types';

export const getStructure = (
  trans: Translator,
  factors: SubventionFactors,
  config: ForecastConfig,
): SubventionForecastRow[] => {
  const soaValue = factors.subventionA.studentsTimesWeight * factors.diFactor * factors.financialStandardA;
  const sobValue = factors.subventionB.studentsTimesWeight * factors.diFactor * factors.financialStandardA;
  const socValue = factors.subventionC.studentsTimesWeight * factors.diFactor * factors.financialStandardA;

  return [
    {
      rowType: SubventionForecastRowType.FORM,
      factor: trans('REPORTS.SUBVENTION_FORECAST.FACTORS.STANDARD_A'),
      factorTooltip: 'REPORTS.SUBVENTION_FORECAST.FACTORS.STANDARD_A_TOOLTIP',
      sourceValue: factors.financialStandardA,
      configField: 'financialStandardA',
      valueField: 'financialStandardA',
      action: config.financialStandardA.action,
      actionValue: config.financialStandardA.actionValue,
      forecast: factors.financialStandardA,
    },
    {
      rowType: SubventionForecastRowType.FORM,
      factor: trans('REPORTS.SUBVENTION_FORECAST.FACTORS.DI_FACTOR'),
      factorTooltip: 'REPORTS.SUBVENTION_FORECAST.FACTORS.DI_FACTOR_TOOLTIP',
      sourceValue: factors.diFactor,
      configField: 'diFactor',
      valueField: 'diFactor',
      action: config.diFactor.action,
      actionValue: config.diFactor.actionValue,
      forecast: factors.diFactor,
    },
    // SOA
    {
      rowType: SubventionForecastRowType.HEADER,
      factor: trans('REPORTS.SUBVENTION_FORECAST.FACTORS.SOA'),
    },
    {
      rowType: SubventionForecastRowType.FORM,
      factor: trans('REPORTS.SUBVENTION_FORECAST.FACTORS.STUDENTS_TIMES_WEIGHT'),
      factorTooltip: 'REPORTS.SUBVENTION_FORECAST.FACTORS.SOA_STW_TOOLTIP',
      sourceValue: factors.subventionA.studentsTimesWeight,
      configField: 'subventionA',
      valueField: 'subventionA.studentsTimesWeight',
      action: config.subventionA.action,
      actionValue: config.subventionA.actionValue,
      forecast: factors.subventionA.studentsTimesWeight,
    },
    {
      rowType: SubventionForecastRowType.RESULT,
      factor: trans('REPORTS.SUBVENTION_FORECAST.FACTORS.SOA_VALUE'),
      sourceValue: factors.subventionA.studentsTimesWeight * factors.diFactor * factors.financialStandardA,
      valueField: 'subventionA.value',
      forecast: factors.subventionA.studentsTimesWeight,
    },
    // SOB
    {
      rowType: SubventionForecastRowType.HEADER,
      factor: trans('REPORTS.SUBVENTION_FORECAST.FACTORS.SOB'),
    },
    {
      rowType: SubventionForecastRowType.FORM,
      factor: trans('REPORTS.SUBVENTION_FORECAST.FACTORS.STUDENTS_TIMES_WEIGHT'),
      factorTooltip: 'REPORTS.SUBVENTION_FORECAST.FACTORS.SOB_STW_TOOLTIP',
      sourceValue: factors.subventionB.studentsTimesWeight,
      configField: 'subventionB',
      valueField: 'subventionB.studentsTimesWeight',
      action: config.subventionB.action,
      actionValue: config.subventionB.actionValue,
      forecast: factors.subventionB.studentsTimesWeight,
    },
    {
      rowType: SubventionForecastRowType.RESULT,
      factor: trans('REPORTS.SUBVENTION_FORECAST.FACTORS.SOB_VALUE'),
      sourceValue: factors.subventionB.studentsTimesWeight * factors.diFactor * factors.financialStandardA,
      valueField: 'subventionB.value',
      forecast: factors.subventionB.studentsTimesWeight,
    },
    // SOC
    {
      rowType: SubventionForecastRowType.HEADER,
      factor: trans('REPORTS.SUBVENTION_FORECAST.FACTORS.SOC'),
    },
    {
      rowType: SubventionForecastRowType.FORM,
      factor: trans('REPORTS.SUBVENTION_FORECAST.FACTORS.STUDENTS_TIMES_WEIGHT'),
      factorTooltip: 'REPORTS.SUBVENTION_FORECAST.FACTORS.SOC_STW_TOOLTIP',
      sourceValue: factors.subventionC.studentsTimesWeight,
      configField: 'subventionC',
      valueField: 'subventionC.studentsTimesWeight',
      action: config.subventionC.action,
      actionValue: config.subventionC.actionValue,
      forecast: factors.subventionC.studentsTimesWeight,
    },
    {
      rowType: SubventionForecastRowType.RESULT,
      factor: trans('REPORTS.SUBVENTION_FORECAST.FACTORS.SOC_VALUE'),
      sourceValue: factors.subventionC.studentsTimesWeight * factors.diFactor * factors.financialStandardA,
      valueField: 'subventionC.value',
      forecast: factors.subventionC.studentsTimesWeight,
    },
    // SO
    {
      rowType: SubventionForecastRowType.HEADER,
      factor: trans('REPORTS.SUBVENTION_FORECAST.FACTORS.SO'),
    },
    {
      rowType: SubventionForecastRowType.RESULT,
      factor: trans('REPORTS.SUBVENTION_FORECAST.FACTORS.SO_VALUE'),
      sourceValue: soaValue + sobValue + socValue,
      valueField: 'subvention',
      forecast: soaValue + sobValue + socValue,
    },
  ];
};

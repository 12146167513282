import { getAlertTranslationSlug } from 'components/schoolDistricts/AssignmentPreview/alerts';
import { Formatter } from 'components/shared/hooks/useFormatter/useFormatter.types';
import { compact } from 'lodash';

import { ColumnsConfig } from 'components/shared/data/DataGrid/DataGrid.types';
import { AssignmentAlert, AssignmentSchoolModel } from 'store/schoolDistricts';
import {
  AlertsChildrenParams,
  AssignedChildrenParams,
} from 'components/schoolDistricts/AssignmentPreview/SchoolRegister/SchoolRegister.types';

export const assignedChildrenColumns: ColumnsConfig<AssignmentSchoolModel, AssignedChildrenParams> = (params) => {
  const detailsColumn = [
    {
      field: 'name',
      name: 'SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.SCHOOL_REGISTER.COLUMNS.NAME',
      columnParams: { minWidth: 100 },
    },
    {
      field: 'address',
      name: 'SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.SCHOOL_REGISTER.COLUMNS.ADDRESS',
      columnParams: { minWidth: 100 },
    },
    {
      field: 'years',
      name: 'SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.SCHOOL_REGISTER.COLUMNS.ASSIGNMENT_CHILDREN',
      format: (data: AssignmentSchoolModel) => {
        const { years } = data;
        return Object.values(years).reduce((previous, current) => previous + current, 0);
      },
      columnParams: { minWidth: 80 },
    },
    {
      field: 'districtChildren',
      name: 'SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.SCHOOL_REGISTER.COLUMNS.DISTRICT_CHILDREN',
      columnParams: { minWidth: 80 },
      format: (data: AssignmentSchoolModel) => data.catchmentChildren,
      headerRenderer: 'textWithIcons',
      headerRendererParams: {
        text: () => 'SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.SCHOOL_REGISTER.COLUMNS.DISTRICT_CHILDREN',
        icons: () => {
          return [
            {
              type: 'default',
              icon: 'info-circle',
              tooltip: (trans) =>
                trans(`SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.SCHOOL_REGISTER.TOOLTIPS.DISTRICT_CHILDREN`),
            },
          ];
        },
      },
    },
  ];

  const transferColumn = {
    field: 'years',
    name: 'SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.SCHOOL_REGISTER.COLUMNS.TRANSFER',
    cellTooltip: (data: AssignmentSchoolModel, trans) => {
      if (data.canTransfer) {
        return trans('SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.SCHOOL_REGISTER.TOOLTIPS.TRANSFER_REGISTER');
      } else if (data.transferredAt) {
        return trans('SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.SCHOOL_REGISTER.TOOLTIPS.TRANSFER_DATE');
      }
    },
    renderer: 'buttonsCell',
    cellClass: 'actions-cell',
    rendererParams: {
      buttons: (item: AssignmentSchoolModel) => {
        if (item.canTransfer) {
          return [
            {
              type: 'default',
              size: 'small',
              variant: 'outlined',
              icon: 'file-export',
              label: 'COMMON.SEND',
              action: params.transfer,
            },
          ];
        } else {
          return [];
        }
      },
      description: (item: AssignmentSchoolModel, trans, formatter: Formatter) => {
        return item.transferredAt ? formatter.formatDateWithTime(item.transferredAt) : '';
      },
    },
  };

  const actionsColumn = {
    name: 'SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.SCHOOL_REGISTER.COLUMNS.ACTIONS',
    cellClass: 'actions-cell',
    field: 'id',
    cellTooltip: () => '',
    renderer: 'buttonsCell',
    rendererParams: {
      buttons: (item: AssignmentSchoolModel) => {
        return compact([
          {
            type: 'default',
            size: 'small',
            variant: 'outlined',
            label: 'COMMON.DISPLAY',
            icon: 'eye',
            tooltip: 'SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.SCHOOL_REGISTER.ACTIONS.SELECT',
            action: () => params.show(item),
          },
          {
            type: 'default',
            size: 'small',
            variant: 'outlined',
            label: 'COMMON.DOWNLOAD',
            icon: 'download',
            tooltip: 'SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.SCHOOL_REGISTER.ACTIONS.DOWNLOAD_CSV',
            action: () => params.download(item),
          },
        ]);
      },
    },
    columnParams: { minWidth: 200 },
  };

  if (params.transferColumnEnabled) {
    return [...detailsColumn, transferColumn, actionsColumn];
  } else {
    return [...detailsColumn, actionsColumn];
  }
};

export const childrenWithAlertsColumns: ColumnsConfig<AssignmentAlert, AlertsChildrenParams> = (params) => [
  {
    field: 'id',
    name: 'SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.SCHOOL_REGISTER.COLUMNS.ALERTS',
    cellClass: 'error',
    columnParams: { minWidth: 100 },
    format: (data, trans) => {
      return trans(getAlertTranslationSlug(data.name));
    },
  },
  {
    field: 'years',
    name: 'SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.SCHOOL_REGISTER.COLUMNS.YEARS',
    cellClass: 'error',
    columnParams: { minWidth: 100 },
    format: (data) => {
      const { years } = data;
      return Object.values(years).reduce((previous, current) => previous + current, 0);
    },
  },
  {
    name: 'SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.SCHOOL_REGISTER.COLUMNS.ACTIONS',
    cellClass: 'actions-cell',
    field: 'id',
    cellTooltip: () => '',
    renderer: 'buttonsCell',
    rendererParams: {
      buttons: (alert: AssignmentAlert) => {
        return compact([
          {
            type: 'default',
            size: 'small',
            variant: 'outlined',
            icon: 'eye',
            label: 'COMMON.DISPLAY',
            tooltip: 'SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.SCHOOL_REGISTER.ACTIONS.SELECT',
            action: () => params.show(alert),
          },
          {
            type: 'default',
            size: 'small',
            variant: 'outlined',
            icon: 'download',
            label: 'COMMON.DOWNLOAD',
            tooltip: 'SCHOOL_DISTRICTS.ASSIGMENT_PREVIEW.SCHOOL_REGISTER.ACTIONS.DOWNLOAD_CSV',
            action: () => params.download(alert),
          },
        ]);
      },
    },
    columnParams: { minWidth: 150 },
  },
];

import { BirthYearModel, SchoolYearModel } from '@typings/common';
import { emptyExemption } from 'components/childRegister/Editor/Obligation/parts/Exemptions/Exemptions.helpers';
import { usePrevious } from 'components/shared/hooks';
import { useEffect } from 'react';
import { ExemptionModel } from 'store/childRegister';
import { isEqual } from 'lodash';

interface UseExemptionBirthYearsValidation {
  (
    exemptions: ExemptionModel[],
    birthday: BirthYearModel,
    schoolYears: SchoolYearModel[],
    onExemptionsChange: (exemptions: ExemptionModel[]) => void,
  ): void;
}

export const useExemptionBirthYearsValidation: UseExemptionBirthYearsValidation = (
  exemptions,
  birthday,
  schoolYears,
  onExemptionsChange,
) => {
  const previousBirthday = usePrevious(birthday);

  useEffect(() => {
    const nextExemptions = [...exemptions];

    const clearExemptionYear = (index) => {
      nextExemptions[index].schoolYear = null;
    };

    const deleteExemption = (index) => {
      nextExemptions[index] = null;
    };

    const validateExemption = (exemption: ExemptionModel, index: number) => {
      if (schoolYears.length === 0 && !exemption.description) {
        deleteExemption(index);
      } else if (exemption.schoolYear && !schoolYears.includes(exemption.schoolYear)) {
        if (exemption.description) {
          clearExemptionYear(index);
        } else {
          deleteExemption(index);
        }
      }
    };

    if (birthday === previousBirthday) {
      return;
    }

    exemptions.forEach((exemption, index) => {
      validateExemption(exemption, index);
    });

    if (schoolYears.length > 0 && nextExemptions.length === 0) {
      nextExemptions.push(emptyExemption());
    }

    const nextExemptionsFiltered = nextExemptions.filter((exemption) => !!exemption);

    if (!isEqual(exemptions, nextExemptionsFiltered)) {
      onExemptionsChange(nextExemptionsFiltered);
    }
  }, [birthday, exemptions, onExemptionsChange, previousBirthday, schoolYears]);
};

import { AddressesListTitle } from 'components/schoolDistricts/DistrictsBrowser/AddressesList/AddressListTitle';
import { AddAddressRuleModalContainer } from 'components/schoolDistricts/DistrictsBrowser/AddressRuleModal/AddAddressRuleModalContainer';
import { EditAddressRuleModalContainer } from 'components/schoolDistricts/DistrictsBrowser/AddressRuleModal/EditAddressRuleModalContainer';
import { ADDRESS_RULE_CONFLICT_ALERT } from 'components/schoolDistricts/DistrictsBrowser/DistrictsBrowser.types';

import { DataGrid } from 'components/shared/data/DataGrid/DataGrid';
import { Confirm } from 'components/shared/dialogs/Confirm/Confirm';
import { useTranslator } from 'components/shared/hooks/useTranslator/useTranslator';
import { Splash } from 'components/shared/surfaces/Splash/Splash';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { getDistrictsSchoolFiltersSelector, SchoolAddressModel } from 'store/schoolDistricts';
import { useStyles } from './AddressesList.styles';
import { GridParams, AddressesListProps } from './AddressesList.types';
import { databaseColumns } from './columns.config';
import { useNotification } from 'components/shared/hooks';
import { routerSelector } from 'store/router/router.selectors';

export const AddressesList: FC<AddressesListProps> = (props) => {
  const trans = useTranslator();
  const classes = useStyles();
  const [newAddressModalOpen, setNewAddressModalOpen] = useState(false);
  const [editedAddress, setEditedAddress] = useState<SchoolAddressModel>(null);
  const [addressToDelete, setAddressToDelete] = useState(null);
  const filters = useSelector(getDistrictsSchoolFiltersSelector);
  const { success } = useNotification();
  const { key: locationKey } = useSelector(routerSelector);

  const actionParams: GridParams = {
    edit: (item: SchoolAddressModel) => setEditedAddress(item),
    delete: (item) => setAddressToDelete(item.id),
  };

  const hasWarning = (item: SchoolAddressModel) => item.alerts && item.alerts.length > 0;

  const openNewAddressModal = () => {
    setNewAddressModalOpen(true);
  };

  const handleNewRuleModalClose = () => {
    setNewAddressModalOpen(false);
  };

  const handleEditAddressModalClose = () => {
    setEditedAddress(null);
  };

  const getFilteredAddressList = () => {
    return props.list.filter((item) => {
      return filters.alert ? item.alerts.some((alert) => alert.name === ADDRESS_RULE_CONFLICT_ALERT) : true;
    });
  };

  const handleDeleteConfirm = () => {
    props.onDelete(addressToDelete).then(() => {
      success('SCHOOL_DISTRICTS.DISTRICTS-LIST.ADDRESS-LIST.DELETE.SUCCESS');
      setAddressToDelete(null);
    });
  };

  return (
    <>
      {!props.school ? (
        <>
          <AddressesListTitle title={trans('SCHOOL_DISTRICTS.DISTRICTS-LIST.ADDRESS-LIST.EMPTY-TITLE')} />
          <Splash>{trans('SCHOOL_DISTRICTS.DISTRICTS-LIST.ADDRESS-LIST.EMPTY-LIST')}</Splash>
        </>
      ) : (
        <>
          <AddAddressRuleModalContainer
            open={newAddressModalOpen}
            onSubmit={(values) => props.onAdd(values)}
            onSubmitSuccess={props.onSubmitSuccess}
            onClose={handleNewRuleModalClose}
          />
          {editedAddress && (
            <EditAddressRuleModalContainer
              open={!!editedAddress}
              onSubmit={(values) => props.onEdit(editedAddress.id, values)}
              onSubmitSuccess={props.onSubmitSuccess}
              onClose={handleEditAddressModalClose}
              groupId={props.databaseId}
              schoolId={props.school.id}
              ruleId={editedAddress && editedAddress.id}
            />
          )}
          <AddressesListTitle
            title={trans('SCHOOL_DISTRICTS.DISTRICTS-LIST.ADDRESS-LIST.TITLE', { name: props.school.name })}
            onAdd={openNewAddressModal}
            databaseStatus={props.selectedDatabase.status}
          />
          <DataGrid<SchoolAddressModel>
            key={locationKey}
            className={classes.grid}
            data={getFilteredAddressList()}
            columns={databaseColumns({ ...actionParams, databaseStatus: props.selectedDatabase.status })}
            isLoading={props.loading}
            loadingMessage={'SCHOOL_DISTRICTS.DISTRICTS-LIST.ADDRESS-LIST.RESULTS-LOADING'}
            noResultsMessage={'SCHOOL_DISTRICTS.DISTRICTS-LIST.ADDRESS-LIST.NOTHING-FOUND'}
            rowClassName={classes.row}
            getRowClass={(params) => {
              return hasWarning(params.data) && classes.warning;
            }}
          />
          <Confirm
            open={!!addressToDelete}
            title={'SCHOOL_DISTRICTS.DISTRICTS-LIST.ADDRESS-LIST.DELETE.CONFIRM'}
            message={'SCHOOL_DISTRICTS.DISTRICTS-LIST.ADDRESS-LIST.DELETE.CONFIRM-MESSAGE'}
            confirm={handleDeleteConfirm}
            cancel={() => setAddressToDelete(null)}
          />
        </>
      )}
    </>
  );
};

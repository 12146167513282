import { ADDRESS_RULE_CONFLICT_ALERT } from 'components/schoolDistricts/DistrictsBrowser/DistrictsBrowser.types';
import { FontAwesomeIcon } from 'components/shared/icons/FontAwesomeIcon/FontAwesomeIcon';
import { Infobar, InfobarWrapper } from 'components/shared/messages/Infobar';
import { InfobarCheckBox } from 'components/shared/messages/Infobar/InfobarCheckBox';
import React, { FC, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch } from '@typings/redux';

import { Form } from 'components/shared/form';

import { useTranslator } from 'components/shared/hooks/useTranslator/useTranslator';
import { SchoolListFilters, getDistrictsSchoolFiltersSelector, setSchoolListFilters } from 'store/schoolDistricts';

export const Alert: FC = () => {
  const trans = useTranslator();
  const form = useRef();
  const dispatch = useDispatch<AppDispatch>();
  const filters = useSelector(getDistrictsSchoolFiltersSelector);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const alert = event.target.checked ? ADDRESS_RULE_CONFLICT_ALERT : null;
    dispatch(setSchoolListFilters({ ...filters, alert }));
  };

  return (
    <InfobarWrapper>
      <Infobar>
        <span>
          <FontAwesomeIcon name={'exclamation-triangle'} className="mr-2" />
          {trans('SCHOOL_DISTRICTS.DISTRICTS-LIST.ADDRESS-CONFLICTS')}
        </span>
        <Form<SchoolListFilters> initialValues={filters} onSubmit={() => {}} innerRef={form}>
          {() => (
            <InfobarCheckBox
              name="alert"
              labelText={trans('SCHOOL_DISTRICTS.DISTRICTS-LIST.SHOW-CONFLICTS')}
              checked={!!filters.alert}
              onChange={handleChange}
            />
          )}
        </Form>
      </Infobar>
    </InfobarWrapper>
  );
};
